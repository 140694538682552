import {NotificationManager} from "common/notifications";
import {AxiosRequestConfig} from "axios";
import {
  Button,
  Drawer,
  Form,
  Icon,
  InputGroup,
  Text,
  WithPermission,
  Toggler,
  FileUploader,
  LoadingSvg,
} from "components";
import {FormEvent, useEffect, useRef, useState} from "react";
import {rules} from "constant";
import {ServiceApi, URLS} from "services";
import {useDataState, useToggleState} from "hooks";
import {ItemsType} from "../../../../types/template";

export default function EditDocumentTemplateDrawer(props: {
  isOpen: boolean;
  toggle: () => void;
  getData: () => void;
  selected: ItemsType;
  type: {type: string; icon: string; accept: string};
}) {
  const [loading, setLoading] = useToggleState();
  const formRef = useRef<any>();
  const [data, setData, setBaseData, isChanged] = useDataState<any>({});
  const [file, setFile] = useState<any>();
  const [loadingFile, toggleLoadingFile] = useToggleState();

  useEffect(() => {
    if (props.selected) {
      setBaseData(props.selected);
    }
  }, [props.selected]);

  const reset = (formData: any) => {
    const url = URLS.UPDATE_DOCUMENT_TEMPLATE_URL(formData.id);
    ServiceApi.delete(url)
      .then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
        props.toggle();
        props.getData();
      })
      .finally(() => setLoading());
  };

  const onSubmitForm = (formData: any) => {
    setLoading();
    const body = formData;
    if (formData.defaultDocumentTemplate == null) {
      const url = URLS.DOCUMENT_TEMPLATE_URL;
      ServiceApi.post(url, body)
        .then(() => {
          NotificationManager.success(
            "global.toast.add-msg",
            "global.toast.add-title",
          );
          props.toggle();
          props.getData();
        })
        .finally(() => setLoading());
    } else {
      const url = URLS.UPDATE_DOCUMENT_TEMPLATE_URL(formData.id);
      ServiceApi.put(url, body)
        .then(() => {
          NotificationManager.success(
            "global.toast.edit-msg",
            "global.toast.edit-title",
          );
          props.toggle();
          props.getData();
        })
        .finally(() => setLoading());
    }
  };

  const onChangeHandler = (key: any) => {
    return (value: any) => setData((p: any) => ({...p, [key]: value}));
  };

  const submit = (e?: FormEvent) => {
    let payLoad = data;
    if (!file && !data?.defaultDocumentTemplate) {
      payLoad = {
        ...data,
        uploadedFile: null,
      };
    }
    formRef.current.submitHandler(e, payLoad);
  };

  const handleDeleteTemplate = () => {
    setData((p: any) => ({...p, uploadedFile: null}));
    setFile("");
  };

  const handleSetFile = (file: any) => {
    setFile(file);
    setData((p: any) => ({
      ...p,
      uploadedFile: {
        type: file?.type,
        url: file?.url,
        thumbnailUrl: file?.thumbnailUrl,
      },
    }));
  };

  const downloadFileHandler = async (url: string) => {
    let dataUrl = url;
    toggleLoadingFile();
    if (dataUrl?.includes("/get-file")) {
      const config: AxiosRequestConfig = {
        responseType: "blob",
        params: {withoutSubscriptionKey: true},
      };
      await ServiceApi.get(dataUrl, config).then(({data}) => {
        dataUrl = URL.createObjectURL(data);
      });
    }
    toggleLoadingFile();
    return dataUrl;
  };

  return (
    <Drawer isOpen={props.isOpen} toggle={props.toggle}>
      {data && (
        <Drawer.Menu className="pr-0">
          <Drawer.Header className="pr-4 pt-6">
            <div className="flex items-center justify-between">
              <h2 className="text-heading-2 text-gray-800">
                <Text>configurations.globalSettings.templates.edit</Text>
              </h2>
              <div className="flex items-center justify-between">
                <Button
                  type="button"
                  variant="primary"
                  light
                  className="ml-2 p-4"
                  onClick={async () => {
                    const url = data?.defaultDocumentTemplate
                      ? data.defaultDocumentTemplate?.uploadedFile?.url
                      : data?.uploadedFile?.url;
                    window.open(url, "_blank");
                  }}
                >
                  <Icon className="mr-2" icon={"download"} />
                  <span className="text-body-2">
                    <Text>
                      configurations.globalSettings.templates.downloadDefaultTemplate
                    </Text>
                  </span>
                </Button>
                <Button
                  type="button"
                  variant="light"
                  className="ml-2"
                  onClick={() => props.toggle()}
                >
                  <Icon className="mr-2" icon={"times"} />
                  <span className="text-body-2">
                    {" "}
                    <Text>global.buttons.close</Text>
                  </span>
                </Button>
              </div>
            </div>
          </Drawer.Header>
          <Drawer.Body>
            <Form
              ref={formRef}
              onSubmit={onSubmitForm}
              className="mt-8 space-y-8 pr-8"
              id={"create-drawer"}
            >
              <div className="flex gap-3">
                <Icon icon="circle-info" className="text-warning" size="1x" />
                <p className="text-body-base font-light leading-5 text-gray-800">
                  <Text>
                    configurations.globalSettings.templates.uploadWarning
                  </Text>
                </p>
              </div>
              {(!data.defaultDocumentTemplate || !data.uploadedFile) &&
                !file && (
                  <FileUploader
                    value={file}
                    setValue={setFile}
                    getResponse={handleSetFile}
                    isHorizontal
                    type={2}
                    accept={props.type.accept}
                  />
                )}
              {((!!data.defaultDocumentTemplate && data?.uploadedFile) ||
                file) && (
                <div className="flex gap-4 rounded-xl bg-gray-100 p-4">
                  <Icon
                    icon={props.type?.icon || "file-arrow-up"}
                    className="text-primary"
                    size="3x"
                  />
                  <div className="flex flex-1 flex-col justify-center gap-3">
                    <h5 className="text-heading-5 ">{data.name}</h5>
                    <p className="text-body-base text-gray-600">
                      {props.type?.type}
                    </p>
                  </div>
                  <div className="flex items-center gap-4">
                    <Icon
                      icon="trash"
                      className="cursor-pointer text-danger hover:text-danger-active"
                      size="lg"
                      onClick={handleDeleteTemplate}
                    />
                    {loadingFile ? (
                      <LoadingSvg size="sm" />
                    ) : (
                      <Icon
                        icon="download"
                        className="cursor-pointer text-primary hover:text-primary-active"
                        size="lg"
                        onClick={async () => {
                          const url = await downloadFileHandler(
                            data.uploadedFile.url,
                          );
                          window.open(url, "_blank");
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
              <InputGroup
                label="configurations.globalSettings.templates.templateName"
                value={data.name}
                setValue={onChangeHandler("name")}
                rules={rules.required}
              />
              <hr className="bg-gray-200" />
              <div className=" space-y-3">
                <div className="rounded-xl p-3 shadow-[0px_4px_20px_0px_#3F42541A]">
                  <Toggler
                    label={
                      "configurations.globalSettings.templates.requiredToBeSigned"
                    }
                    value={data.signMandatory}
                    setValue={onChangeHandler("signMandatory")}
                  />
                </div>
                <div className="rounded-xl p-3 shadow-[0px_4px_20px_0px_#3F42541A]">
                  <Toggler
                    label={"configurations.globalSettings.templates.erp"}
                    value={data.needSendToErp}
                    setValue={onChangeHandler("needSendToErp")}
                  />
                </div>
                <div className="rounded-xl p-3 shadow-[0px_4px_20px_0px_#3F42541A]">
                  <Toggler
                    label={"configurations.globalSettings.templates.crm"}
                    value={data.needSendToCrm}
                    setValue={onChangeHandler("needSendToCrm")}
                  />
                </div>
              </div>
            </Form>
          </Drawer.Body>
          <WithPermission
            permissions={["SC_UpdateSaleServiceDocumentTemplate"]}
          >
            <Drawer.Footer className="flex justify-between">
              <Button
                variant="white"
                type="button"
                onClick={() => props.toggle()}
              >
                <Text>global.buttons.cancel</Text>
              </Button>
              <Button
                type="button"
                variant="primary"
                onClick={submit}
                loading={loading}
                disabled={!isChanged}
              >
                <Text>global.buttons.submit</Text>
              </Button>
            </Drawer.Footer>
          </WithPermission>
        </Drawer.Menu>
      )}
    </Drawer>
  );
}
