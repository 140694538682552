export const stores = {
  "storeList": "Filialliste",
  "addStore": "Filiale hinzufügen",
  "basicInfo": "Grundlegende Informationen",
  "basicInfoDesc": "Name, Beschreibung",
  "location": "Standort",
  "locationDesc": "Filialstandort",
  "image": "Bild",
  "imageDesc": "Bild für die Filiale",
  "review": "Überprüfung",
  "reviewDesc": "Informationen abschließen",
  "general": "Allgemein",
  "setting": "Einstellungen",
  "locationOnMap": "Standort auf Karte",
  "openingHours": "Öffnungszeiten",
  "addDay": "Tag hinzufügen",
  "generalSetting": "Allgemeine Einstellungen",
  "salesSetting": "Verkaufseinstellungen",
  "storeName": "Filialname",
  "storeCode": "Filialcode",
  "code": "Code",
  "legalEntity": "Rechtseinheit",
  "shortDescription": "Kurzbeschreibung",
  "description": "Beschreibung",
  "day": "Tag",
  "active": "Aktiv",
  "deActive": "Inaktiv",
  "iln": "ILN (Internationale Standortnummer)",
  "iwofurnSalesCalculationFactor": "iwofurn-Verkaufsberechnungsfaktor",
  "iwofurnRoundingMethod": "iwofurn-Rundungsmethode",
  "storeAdded": "Filiale erfolgreich hinzugefügt.",
  "storeUpdated": "Filiale erfolgreich aktualisiert.",
  "Departments": "Abteilungen",
  "addDepartment": "Abteilung hinzufügen",
  "details": "Details",
  "titel": "Titel",
  "emailAddress": "E-Mail-Adresse",
  "teamLead": "Teamleiter",
  "assignTeamLead": "Mitarbeiter als Teamleiter zuweisen",
  "selectTEamLead": "Teamleiter auswählen",
  "search": "Suche",
  "searchPlaceHolder": "Suche nach Name, Mitarbeiternummer ....",
  "employees": "Mitarbeiter",
  "editGeneralInfo": "Allgemeine Informationen bearbeiten",
  "leadInfo": "Leitungsinformationen",
  "addEmployee": "Mitarbeiter hinzufügen",
  "employeeNumber": "Mitarbeiternummer"
};