import {NotificationManager} from "common/notifications";
import {NoItems, Skeleton, Table, Text, Toggler} from "components";
import {Fragment, useContext} from "react";
import {ServiceApi, URLS} from "services";
import {UserContext} from "..";

export default function AuthenticationSetting() {
  const {userData, loading, getData, UPDATE_PERMISSIONS} =
    useContext<any>(UserContext);

  const updateProviders = (item: any) => {
    const url = URLS.UPDATE_PROVIDERS_USER_URL(userData.userId);
    const body = {
      authenticationProviderId: item.id,
      isActive: item.isActive,
    };
    ServiceApi.patch(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
      })
      .finally(() => {
        getData();
      });
  };

  return (
    <Fragment>
      {loading ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {userData?.authenticationProviders?.length !== 0 ? (
            <>
              <Table>
                {userData?.authenticationProviders?.map((item: any) => {
                  return (
                    <tr>
                      <td>
                        <div className="space-y-2">
                          <div className="flex gap-4">
                            <h6 className="text-heading-6 font-semibold text-gray-800">
                              <Text>
                                configurations.userManagment.userList.providerName
                              </Text>
                              :
                            </h6>
                            <span className="text-heading-6 font-normal text-gray-800">
                              {item.signInType === "emailaddress" ? (
                                <Text>
                                  configurations.userManagment.userList.local
                                </Text>
                              ) : (
                                <Text>
                                  configurations.userManagment.userList.add
                                </Text>
                              )}
                            </span>
                          </div>
                          <div className="flex gap-4">
                            <h6 className="text-heading-6 font-semibold text-gray-800">
                              <Text>
                                configurations.userManagment.userList.userName
                              </Text>
                              :
                            </h6>
                            <span className="text-heading-6 font-normal text-gray-800">
                              {userData?.username}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <Toggler
                          label={
                            "configurations.userManagment.userList.activeLogin"
                          }
                          value={item.isActive}
                          size="sm"
                          setValue={value => {
                            item.isActive = value;
                            updateProviders(item);
                          }}
                          disabled={!UPDATE_PERMISSIONS}
                        />
                      </td>
                    </tr>
                  );
                })}
              </Table>
            </>
          ) : (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
            </div>
          )}
        </>
      )}
    </Fragment>
  );
}
