import {useState, useEffect} from "react";
import {Icon, Text, Skeleton, Table, Button, Pagination} from "components";
import {useParams} from "react-router-dom";
import {useToggleState} from "hooks";
import {ServiceApi, URLS} from "services";
import {Departments as DepartmentsTypes} from "types";
import DetailsDepartment from "./details-deparment";
import ChooseDepartment from "./choose-department";

export default function Department() {
  const {employeeId} = useParams();

  const [loading, setLoading] = useToggleState();
  const [isOpen, toggle] = useToggleState();
  const [departmentId, setDepartmentId] = useState<string>();
  const [isOpenDetails, toggleDetails] = useToggleState();
  const [ids, setIds] = useState<string[]>([]);

  const [params, setParams] = useState({
    keyword: "",
    pageNumber: 1,
    pageSize: 10,
    employeeId,
  });

  const [data, setData] = useState<DepartmentsTypes.DepartmentDataType>();

  const getData = () => {
    setLoading();
    const url = URLS.GET_DEPARTMENTS_URL;
    const config = {params};
    ServiceApi.get(url, config)
      .then(({data}) => {
        setData(data);
        const ids = data?.items.map((item: any) => item.id);
        setIds(ids);
        setLoading();
      })
      .catch(() => setLoading());
  };

  useEffect(getData, [params]);

  if (loading) return [1, 2, 3, 4].map(() => <Skeleton.List />);

  return (
    <section className="space-y-6">
      <div
        onClick={toggle}
        className="flex cursor-pointer justify-center rounded-lg border border-dashed border-primary bg-primary-light p-4"
      >
        <span className="flex items-center gap-2 text-heading-5 font-semibold text-primary">
          <Icon icon="square-plus" />
          <Text>company.employees.addDepartment</Text>
        </span>
      </div>
      <Table>
        {data?.items?.map(department => {
          return (
            <tr>
              <td>
                <div className="flex flex-col gap-4">
                  <span
                    className="cursor-pointer text-heading-6 font-semibold"
                    onClick={() => {
                      setDepartmentId(department?.id);
                      toggleDetails();
                    }}
                  >
                    {department.title}
                  </span>
                  <span className="text-body-2 text-gray-500">
                    <Icon icon="envelope" /> {department.emailAddress}
                  </span>
                </div>
              </td>
              <td className="text-wrap text-left leading-6">
                <span className="text-body-2 font-normal text-gray-500">
                  {department.shortDescription}
                </span>
              </td>
              <td>
                <Button
                  variant={"light"}
                  type="button"
                  onClick={() => {
                    setDepartmentId(department?.id);
                    toggleDetails();
                  }}
                >
                  <Text>company.stores.details</Text>
                </Button>
              </td>
            </tr>
          );
        })}
      </Table>
      <Pagination
        pageNumber={params.pageNumber}
        pageSize={params.pageSize}
        totalItems={data?.totalItems}
        totalPages={data?.totalPages}
        setActivePage={value => setParams(p => ({...p, pageNumber: +value}))}
      />
      {departmentId && (
        <>
          <DetailsDepartment
            isOpen={isOpenDetails}
            toggle={toggleDetails}
            departmentId={departmentId}
            getData={getData}
          />
        </>
      )}
      <ChooseDepartment
        isOpen={isOpen}
        toggle={toggle}
        userDepartments={ids}
        getData={getData}
      />
    </section>
  );
}
