import {
  FormEvent,
  ReactNode,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from "react";

type formProps = {
  children: ReactNode;
  onSubmit?: (formData?: any, condition?: boolean) => void;
  className?: string;
  id?: string;
};

const Form = forwardRef(
  ({onSubmit, className, children}: formProps, ref: any) => {
    const formId = useMemo(() => crypto.randomUUID(), []);
    const submit = (e: FormEvent, data?: any, condition?: boolean) => {
      e?.preventDefault();
      const nodeTarget = document.getElementById(formId);
      // @ts-ignore: Unreachable code error
      const formControls = nodeTarget?.querySelectorAll(".form-control");
      // @ts-ignore: Unreachable code error
      const validArr = [...formControls].map(
        // @ts-ignore: Unreachable code error
        item => item.onValid && item.onValid(),
      );
      if (!validArr.every(Boolean)) return;
      onSubmit && onSubmit(data, condition);
    };

    useImperativeHandle(ref, () => {
      return {
        submitHandler(e: FormEvent, data?: any, condition?: boolean) {
          submit(e as FormEvent, data, condition);
        },
      };
    }, []);

    return (
      <form className={className} onSubmit={submit} ref={ref} id={formId}>
        {children}
      </form>
    );
  },
);

export default Form;
