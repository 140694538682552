import {NotificationManager} from "common/notifications";
import {
  Breadcrumb,
  Button,
  DeleteModal,
  Footer,
  Form,
  HandleName,
  Icon,
  Skeleton,
  Tabs,
  Text,
  WithPermission,
  Wrapper,
} from "components";
import {
  useDataState,
  useSelector,
  useTitleTranslations,
  useToggleState,
} from "hooks";
import {
  Fragment,
  createContext,
  createElement,
  useEffect,
  useState,
} from "react";
import {useParams} from "react-router";
import {Link, useNavigate} from "react-router-dom";
import {ServiceApi, URLS} from "services";
import {ObjectUtils} from "utils";

//...tabs
import General from "./tabs/general";
import Units from "./tabs/units";

export const MeasurementContext = createContext({});

export default function Details() {
  const {measurementFamilyId} = useParams();
  const [loading, setLoading] = useState(true);
  const [langItems, setLangItems] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [data, setData, setBaseData, isChanged] = useDataState<any>({});
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const companySetting = useSelector(s => s.companySetting);
  const navigate = useNavigate();
  const [selected, setSelected] = useState<any>();
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [langSelected, setLangSelected] = useState("de");
  const {checkTranslations} = useTitleTranslations();

  const tabs = [
    {
      label: "productManagement.masterData.measurementUnits.general",
      component: General,
    },
    {
      label: "productManagement.masterData.measurementUnits.units",
      component: Units,
    },
  ];

  const getData = () => {
    setBaseData({});
    setData({});
    setLoading(true);
    let arr: any = [];
    companySetting?.contentLanguages?.forEach((item: any) => {
      arr.push({id: item?.toLocaleLowerCase(), name: item});
    });
    const url = URLS.GET_DETAIL_MEASUREMENT_FAMILY_URL(measurementFamilyId);
    let newData: any;
    ServiceApi.get(url)
      .then(({data}) => {
        newData = JSON.parse(JSON.stringify(data));
        newData.translates?.forEach((i: any, index: any) => {
          if (
            i?.language?.toLocaleLowerCase() ===
            companySetting?.defaultContentLanguage?.toLocaleLowerCase()
          ) {
            setName(i.labelTitle);
            setDescription(i.description);
          }
        });
        if (newData?.translates?.length !== arr?.length) {
          var props = ["id"];
          var result = arr
            ?.filter(function (i: any) {
              return !newData?.translates?.some(function (a: any) {
                return (
                  i.id?.toLocaleLowerCase() === a.language?.toLocaleLowerCase()
                );
              });
            })
            .map(function (o: any) {
              return props?.reduce(function (newo: any, language: any) {
                newo["id"] = o[language];
                return newo;
              }, {});
            });

          result?.forEach((item: any) => {
            newData?.translates?.push({
              language: item.id,
              labelTitle: "",
            });
          });
        }
        setBaseData(newData);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
    setLangSelected(companySetting.defaultContentLanguage?.toLocaleLowerCase());
    setLangItems(arr);
  };

  const submit = () => {
    const validTranslates = checkTranslations(data.translates);
    if (validTranslates) {
      setSubmitLoading(true);
      const body = {...data, translates: validTranslates};
      const url = URLS.PUT_DETAIL_MEASUREMENT_FAMILY_URL(measurementFamilyId);
      ServiceApi.put(url, body)
        .then(() => {
          NotificationManager.success(
            "global.toast.edit-msg",
            "global.toast.edit-title",
          );
          getData();
        })
        .catch(() => {})
        .finally(() => {
          setSubmitLoading(false);
        });
    }
  };

  useEffect(getData, [companySetting]); // eslint-disable-line react-hooks/exhaustive-deps

  const submitDelete = (id: any) => {
    setLoadingButton(true);
    const url = URLS.DELETE_DETAIL_MEASUREMENT_FAMILY_URL(id);
    ServiceApi.delete(url)
      .then(({data}) => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title",
        );
        toggleDeleteModal();
        navigate(-1);
        setLoadingButton(false);
      })
      .catch(() => setLoadingButton(false));
  };

  return (
    <Tabs activeKey={tabs[0].label}>
      <Form onSubmit={submit} className="relative space-y-6">
        <div className="flex items-center">
          <div className="flex-1">
            <Breadcrumb />
          </div>
          <WithPermission permissions={["PS_DeleteMeasurementFamily"]}>
            {loading ? (
              <Skeleton.Button />
            ) : (
              <Button
                type="button"
                variant="white"
                textColor="danger"
                onClick={() => {
                  setSelected(data);
                  toggleDeleteModal();
                }}
              >
                <Icon className="mr-2" icon={"trash"} />
                <Text>global.buttons.delete</Text>
              </Button>
            )}
          </WithPermission>
        </div>
        <Wrapper>
          <Wrapper.Body className="flex flex-col items-start gap-5 lg:flex-row">
            <div className="space-y-4 lg:flex-1">
              {loading ? (
                <Fragment>
                  <Skeleton.Input />
                  <Skeleton.Input />
                </Fragment>
              ) : (
                <Fragment>
                  <h5 className="text-heading-5 font-semibold text-gray-800">
                    {
                      <HandleName
                        translations={data?.translates}
                        code={data?.code}
                        keyName="labelTitle"
                      />
                    }
                  </h5>
                  <p className="text-body-2 font-medium text-gray-500">
                    {data.code}
                  </p>
                </Fragment>
              )}
            </div>
          </Wrapper.Body>
          <Wrapper.Footer className="!py-0">
            <Tabs.ButtonGroup>
              {tabs.map(e => (
                <Tabs.Button key={e.label} eventKey={e.label}>
                  <Text>{e.label}</Text>
                </Tabs.Button>
              ))}
            </Tabs.ButtonGroup>
          </Wrapper.Footer>
        </Wrapper>
        {loading && (
          <Wrapper>
            <Wrapper.Body className="grid grid-cols-1 gap-4 lg:grid-cols-2">
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Wrapper.Body>
          </Wrapper>
        )}
        {tabs.map(e => (
          <Tabs.Item key={e.label} eventKey={e.label}>
            <MeasurementContext.Provider
              value={{
                measurementFamilyId: measurementFamilyId,
                measurementData: data,
                name: name,
                description: description,
                langItems: langItems,
                loading: loading,
                setLangItems: setLangItems,
                setName: setName,
                setDescription: setDescription,
                setMeasurementData: setData,
                getData: getData,
                setLoading: setLoading,
                langSelected: langSelected,
                setLangSelected: setLangSelected,
              }}
            >
              {!ObjectUtils.isEmpty(data) && createElement(e.component)}
            </MeasurementContext.Provider>
          </Tabs.Item>
        ))}
        <WithPermission permissions={["PS_UpdateMeasurementFamily"]}>
          <Footer show={!isChanged}>
            <Button
              as={Link}
              to={"/productManagement/master-data/measurement-units"}
              variant="white"
            >
              <Text>global.buttons.discard</Text>
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={!isChanged}
              loading={submitLoading}
            >
              <Text>global.buttons.saveChanges</Text>
            </Button>
          </Footer>
        </WithPermission>
      </Form>
      <DeleteModal
        isOpen={isOpenDeleteModal}
        toggle={toggleDeleteModal}
        selected={{
          name: (
            <HandleName
              translations={selected?.translates}
              code={selected?.code}
              keyName="name"
            />
          ),
          id: selected?.measurementFamilyId,
        }}
        loading={loadingButton}
        onConfirm={submitDelete}
      />
    </Tabs>
  );
}
