import {useState, useEffect} from "react";
import {useParams} from "react-router";
import {
  Accordion,
  Wrapper,
  Skeleton,
  Icon,
  Text,
  Table,
  Button,
  DeleteModal,
} from "components";
import {usePermission, useToggleState} from "hooks";
import {AddNewCalculationUnit} from "compositions";
import {ServiceApi, URLS} from "services";
import {PriceEngin, PriceEngineService} from "types";
import {NotificationManager} from "common/notifications";
import {iwofurnSalesTemplatesSteps} from "constant";
import Dragula from "react-dragula";
import {sortBy} from "lodash";
import rowHandle from "assets/image/rowHandle.svg";

export default function CalculationSections() {
  const updatePermission = usePermission("PE_ModifySalePriceTemplateItem");
  const {salesTemplateId} = useParams();
  const [loading, setLoading] = useToggleState();
  const [isOpenDelete, toggleDelete] = useToggleState();
  const [removeLoading, toggleRemoveLoading] = useToggleState();
  const [reorderLoading, toggleReorderLoading] = useToggleState();
  const [getLoading, setGetLoading] = useToggleState();
  const [selectedUnit, setSelectedUnit] =
    useState<PriceEngin.SalesPriceTemplateItems>();
  const [params, setParams] = useState({
    keyword: "",
    templateId: salesTemplateId,
  });
  const [cloneSalesTemplates, setCloneTemplates] = useState<any>(
    iwofurnSalesTemplatesSteps,
  );
  const [step, setStep] = useState(0);
  const [isOpen, toggle] = useToggleState();
  const [data, setData] = useState<PriceEngin.SalesPriceTemplateItems[]>();

  useEffect(() => {
    getItemsList();
  }, []);

  const getItemsList = () => {
    setGetLoading();
    const url = URLS.GET_CATALOG_SALE_PRICE_TEMPLATES_ITEM_LIST;
    const config = {params};
    ServiceApi.get(url, config)
      .then(({data}) => {
        setGetLoading();
        const sortedData = sortBy(data, o => o.calculationOrder);
        const clone = cloneSalesTemplates?.map((template: any) => ({
          ...template,
          items: sortedData?.filter((unit: any) => unit.step === template.step),
        }));
        setCloneTemplates(clone);
        setData(data);
      })
      .catch(() => setGetLoading());
  };

  const onAddunitToItem = (list: PriceEngin.CalculationUnitItem) => {
    setLoading();
    const body: PriceEngineService.CreateCatalogSalePriceTemplateItemRequest = {
      catalogSalePriceTemplateId: salesTemplateId as string,
      calculationUnitId: list.id,
      title: list.title,
      step: step,
      calculationOrder: 0,
    };
    const url = URLS.UPDATE_CATALOG_SALE_PRICE_TEMPLATES_ITEM;
    ServiceApi.post(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title",
        );
        setLoading();
        toggle();
        getItemsList();
      })
      .catch(() => setLoading());
  };

  const onRemove = () => {
    toggleRemoveLoading();
    const url = URLS.DELETE_CATALOG_SALE_PRICE_TEMPLATES_ITEM(selectedUnit?.id);
    ServiceApi.delete(url)
      .then(() => {
        toggleRemoveLoading();
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title",
        );
        toggleDelete();
        getItemsList();
      })
      .catch(() => toggleRemoveLoading());
  };
  const reorderHandler = (sortedIds: string[]) => {
    toggleReorderLoading();
    const url = URLS.REORDER_CATALOG_SALE_PRICE_TEMPLATES_ITEM;
    const body = {sortedIds};
    ServiceApi.put(url, body)
      .then(() => {
        toggleReorderLoading();
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
        getItemsList();
      })
      .catch(() => toggleReorderLoading());
  };

  const dragulaDecorator = (componentBackingInstance: any) => {
    if (componentBackingInstance) {
      let options = {};
      let drake = Dragula([componentBackingInstance], options);
      drake.on("drop", function (el, target) {
        let clone: any = [];
        [...target?.children].map(child => {
          clone.push(child.getAttribute("data-id"));
        });
        reorderHandler(clone);
      });
    }
  };
  return (
    <>
      <div className="space-y-4">
        {getLoading ? (
          <Wrapper className="space-y-4 p-4">
            {[1, 2, 3, 4].map(() => (
              <Skeleton.TextArea />
            ))}
          </Wrapper>
        ) : (
          <>
            {cloneSalesTemplates.map((template: any) => {
              return (
                <Accordion>
                  <Accordion.Item
                    active
                    className={`rounded-2xl border bg-white px-4 shadow-nested`}
                  >
                    <Accordion.Toggle className="cursor-pointer">
                      <div>
                        <Icon
                          icon={"chevron-right"}
                          size="1x"
                          className="mr-4 transition-transform group-[.accordion-item.active]:rotate-90"
                        />
                        <span className="text-heading-3 font-semibold text-gray-800">
                          <Text>{template.label}</Text>
                        </span>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Body className="space-y-4 px-4">
                      <>
                        {updatePermission && (
                          <div
                            onClick={() => {
                              setStep(template.step);
                              toggle();
                            }}
                            className="flex-center flex h-[52px] cursor-pointer select-none gap-4 rounded-xl border border-dashed border-primary bg-gray-100 text-primary"
                          >
                            <Icon
                              icon="plus"
                              size="xs"
                              className="rounded-md bg-primary-light p-2"
                            />
                            <h5 className="text-primary">
                              <Text>
                                priceEngine.salesPrice.addNewCalculationUnit
                              </Text>
                            </h5>
                          </div>
                        )}

                        <div
                          ref={updatePermission ? dragulaDecorator : null}
                          className="mt-4 space-y-4"
                        >
                          {template?.items?.map(
                            (dto: PriceEngin.PurchasePriceTemplateItems) => {
                              return (
                                <>
                                  {reorderLoading ? (
                                    <Skeleton.SimpleList />
                                  ) : (
                                    <div
                                      className={`flex rounded border border-gray-50 shadow-card ${
                                        template?.items?.length > 1 &&
                                        updatePermission &&
                                        "cursor-move"
                                      }`}
                                      data-id={dto.id}
                                    >
                                      {template?.items?.length > 1 &&
                                        updatePermission && (
                                          <div className="handle flex-center w-[36px] cursor-move overflow-hidden rounded-l-[10px] border border-gray-200  bg-gray-200">
                                            <img
                                              className="handle w-full object-cover"
                                              src={rowHandle}
                                              alt="t"
                                            />
                                          </div>
                                        )}
                                      <Table>
                                        <tr key={"id"}>
                                          <td className="!py-0">
                                            <div className="space-y-2">
                                              <h6 className="text-heading-6 font-semibold text-gray-800">
                                                {dto?.title}
                                              </h6>
                                            </div>
                                          </td>
                                          <td className="space-x-2 !py-0">
                                            {updatePermission && (
                                              <Button
                                                variant="danger"
                                                light
                                                size="sm"
                                                type="button"
                                                onClick={() => {
                                                  setSelectedUnit(dto);
                                                  toggleDelete();
                                                }}
                                              >
                                                <Icon icon="trash" />
                                              </Button>
                                            )}
                                          </td>
                                        </tr>
                                      </Table>
                                    </div>
                                  )}
                                </>
                              );
                            },
                          )}
                        </div>
                      </>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              );
            })}
          </>
        )}
      </div>
      <AddNewCalculationUnit
        isOpen={isOpen}
        toggle={toggle}
        onAddUnits={(list: PriceEngin.CalculationUnitItem) =>
          onAddunitToItem(list)
        }
        loading={loading}
      />
      <DeleteModal
        isOpen={isOpenDelete}
        toggle={toggleDelete}
        selected={{name: selectedUnit?.title}}
        onConfirm={() => {
          onRemove();
        }}
        loading={removeLoading}
      />
    </>
  );
}
