import {NotificationManager} from "common/notifications";
import {
  Breadcrumb,
  Button,
  DeleteModal,
  HandleName,
  Icon,
  Tabs,
  Text,
  Wrapper,
} from "components";
import {useToggleState, usePermission} from "hooks";
import {
  createContext,
  createElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import {useTranslation} from "react-i18next";
import {ServiceApi, URLS} from "services";
import LeftSide from "./leftSide";
import Channels from "./tabs/channels";
import General from "./tabs/general";
import Media from "./tabs/media";
import SubCategories from "./tabs/sub-categories";
import {withPermission} from "hoc";

export const CategoryContext = createContext<any>({});

const ProductCategory = () => {
  const deletePermission = usePermission("PS_DeleteProductCategory");
  const controller = new AbortController();
  const {i18n} = useTranslation();
  const [roots, setRoots] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [activeId, setActiveId] = useState<string>("");
  const [deleteModal, setDeleteModal] = useToggleState();
  const [deleteLoading, setDeleteLoading] = useToggleState();
  const [loading, setLoading] = useToggleState();

  const tabs = [
    {
      label: "productManagement.masterData.productCategory.generalTab",
      component: General,
      ariaLabel: "general",
    },
    {
      label: "productManagement.masterData.productCategory.subCategoriesTab",
      component: SubCategories,
      ariaLabel: "subCategory",
    },
    {
      label: "productManagement.masterData.productCategory.mediaTab",
      component: Media,
      ariaLabel: "media",
    },
    {
      label: "productManagement.masterData.productCategory.channelsTab",
      component: Channels,
      ariaLabel: "channel",
    },
  ];

  const selectedId = activeId || roots?.[0]?.productCategoryId || "";
  const setSelectedId = (id: string) => {
    setActiveId(id);
  };

  const getCategories = () => {
    setLoading();
    const url = URLS.GET_CATEGORIES;
    ServiceApi.get(url, {signal: controller.signal})
      .then(({data}) => {
        const roots = data?.filter((category: any) => !category.parentId);
        setRoots(roots);
        setCategories(data);
        setLoading();
      })
      .catch(() => setLoading());
  };

  useEffect(() => {
    getCategories();
    return () => {
      controller.abort();
    };
  }, []);

  const selectedCategory = useMemo(() => {
    if (!roots?.length) return null;
    if (!selectedId) return roots[0];
    return roots?.find((e: any) => e.productCategoryId === selectedId);
  }, [roots, selectedId]);

  const onDeleteHandler = () => {
    setDeleteLoading();
    const url = URLS.DELETE_CATEGORY(selectedCategory?.productCategoryId);
    ServiceApi.delete(url)
      .then(() => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title",
        );
        setActiveId("");
        setDeleteLoading();
        setDeleteModal();
        getCategories();
      })
      .catch(() => setDeleteLoading());
  };

  return (
    <div className="space-y-4">
      <Breadcrumb />
      <div className="flex gap-8">
        <LeftSide
          loading={loading}
          roots={roots}
          setRoots={setRoots}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          getCategories={getCategories}
        />
        {!!selectedCategory && !loading && (
          <div className="w-full space-y-6">
            <Tabs activeKey={tabs[1].label}>
              <Wrapper>
                <Wrapper.Body className="flex items-center justify-between">
                  <div className="space-y-4">
                    <span className="text-heading-5 font-semibold text-gray-800">
                      {
                        <HandleName
                          translations={selectedCategory?.translates}
                          code={selectedCategory?.code}
                          keyName="labelTitle"
                        />
                      }
                    </span>
                    <p
                      aria-label="cy-category-code"
                      className="text-body-2 font-medium"
                    >
                      {selectedCategory?.code}
                    </p>
                  </div>
                  {deletePermission && (
                    <Button
                      aria-label="cy-delete-category"
                      variant={"danger"}
                      light
                      size="sm"
                      onClick={() => {
                        setDeleteModal();
                      }}
                    >
                      <Icon icon={"trash"} className="mr-2" />
                      <Text>
                        productManagement.masterData.productCategory.deleteButton
                      </Text>
                    </Button>
                  )}
                </Wrapper.Body>
                <Wrapper.Footer className="!py-0">
                  <Tabs.ButtonGroup>
                    {tabs.map(e => (
                      <Tabs.Button
                        key={e.label}
                        eventKey={e.label}
                        aria-label={`cy-product-category-${e.ariaLabel}-tab`}
                      >
                        <Text>{e.label}</Text>
                      </Tabs.Button>
                    ))}
                  </Tabs.ButtonGroup>
                </Wrapper.Footer>
              </Wrapper>
              {tabs.map(e => (
                <CategoryContext.Provider
                  value={{
                    selectedCategory,
                    setRoots,
                    selectedId,
                    roots,
                    getCategories,
                    categories,
                  }}
                >
                  <Tabs.Item key={e.label} eventKey={e.label}>
                    {createElement(e.component)}
                  </Tabs.Item>
                </CategoryContext.Provider>
              ))}
            </Tabs>
          </div>
        )}
      </div>
      <DeleteModal
        isOpen={deleteModal}
        loading={deleteLoading}
        toggle={setDeleteModal}
        onConfirm={onDeleteHandler}
        selected={{
          id: selectedCategory?.code,
          name: (
            <HandleName
              translations={selectedCategory?.translates}
              code={selectedCategory?.code}
              keyName="labelTitle"
            />
          ),
        }}
      />
    </div>
  );
};

export default withPermission(ProductCategory, ["PS_GetProductCategory"]);
