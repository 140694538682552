import {useContext, Fragment} from "react";
import {Text, Wrapper, Skeleton} from "components";
import {useConverter} from "hooks";
import {CustomerContext} from "..";

export default function TechnicalInfo() {
  const {userData, loading} = useContext<any>(CustomerContext);
  const {convertDate} = useConverter();

  return (
    <Wrapper className="grid grid-cols-1 p-8 lg:grid-cols-1">
      <Wrapper.Section className="space-y-4">
        <h3 className="mb-2 text-heading-3 font-semibold text-gray-600">
          <Text>company.employees.technicalInfo</Text>
        </h3>
        {loading ? (
          <Fragment>
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
          </Fragment>
        ) : (
          <Fragment>
            <div className="grid grid-cols-3 gap-8">
              <span className="text-body-base font-medium text-gray-800">
                <Text>company.employees.userPrincipalName</Text>
              </span>
              <h5 className="text-heading-5 font-normal text-gray-700">
                {userData?.userPrincipalName}
              </h5>
            </div>
            <div className="grid grid-cols-3 gap-8">
              <span className="text-body-base font-medium text-gray-800">
                <Text>company.employees.userObjectId</Text>
              </span>
              <h5 className="text-heading-5 font-normal text-gray-700">
                {userData?.userObjectId}
              </h5>
            </div>
            <div className="grid grid-cols-3 gap-8">
              <span className="text-body-base font-medium text-gray-800">
                <Text>company.employees.creationDate</Text>
              </span>
              <h5 className="text-heading-5 font-normal text-gray-700">
                {convertDate(userData?.userCreatedDateTime)}
              </h5>
            </div>
          </Fragment>
        )}
      </Wrapper.Section>
    </Wrapper>
  );
}
