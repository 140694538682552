import {useState, useEffect} from "react";
import {Button, Drawer, FileUploader, Text, Icon} from "components";
import {assetTypes} from "constant";
import {ServiceApi, URLS} from "services";
import {useToggleState} from "hooks";
import {NotificationManager} from "common/notifications";

type propsType = {
  isOpen: boolean;
  toggle: () => void;
};

export default function ImportFromExcel({isOpen, toggle}: propsType) {
  const [file, setFile] = useState<any>();
  const [loading, setLoading] = useToggleState();

  const excelTypes = [
    ".xlsx",
    ".xlsm",
    ".xls",
    ".xlsb",
    ".xltx",
    ".xltm",
    ".xlt",
    ".xml",
    ".xlam",
    ".xla",
    ".xlw",
    ".xlr",
  ];

  useEffect(() => {
    setFile(null);
  }, [isOpen]);

  const onUploadHandler = () => {
    setLoading();
    const url = URLS.IMPORT_FROM_EXCEL;
    const body = {fileUrl: file?.url};
    ServiceApi.post(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.import-msg",
          "global.toast.import-title",
        );
        setLoading();
        toggle();
      })
      .catch(() => setLoading());
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header className="flex items-center justify-between">
          <span className="text-heading-2 font-semibold text-gray-800">
            <Text>
              productManagement.products.allProducts.importProductFromExcel
            </Text>
          </span>
        </Drawer.Header>
        <Drawer.Body
          className={loading ? "flex items-center justify-center" : ""}
        >
          {!loading && (
            <FileUploader
              value={file}
              setValue={setFile}
              loading={loading}
              getResponse={(data: any) => setFile(data)}
              type={assetTypes.Temp}
              accept={excelTypes}
              hideAction
            />
          )}

          {loading && (
            <div className="flex-center flex h-[157px] w-[157px] flex-col space-y-3 rounded-full border-2 border-primary-light">
              <Icon icon="hourglass" className="text-primary" size="2x" />
              <span className="text-heading-6 text-gray-800">
                <Text>productManagement.products.allProducts.pleaseWait</Text>
              </span>
            </div>
          )}
        </Drawer.Body>
        <Drawer.Footer className="flex justify-between">
          <Button onClick={toggle} variant={"light"} size="sm">
            <Icon icon="times" className="mr-2" />
            <Text>global.buttons.close</Text>
          </Button>
          <Button
            size="sm"
            disabled={!file}
            loading={loading}
            onClick={onUploadHandler}
          >
            <Icon icon="file-import" className="mr-2" />
            <Text>productManagement.products.allProducts.import</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
