import {NotificationManager} from "common/notifications";
import {
  Button,
  Icon,
  Skeleton,
  Text,
  Wrapper,
  Channels as ChannelsComponent,
} from "components";
import {useToggleState, usePermission} from "hooks";
import {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ServiceApi, URLS} from "services";
import {CategoryContext} from "..";
import ChannelDrawer from "../drawers/channel-drawer";
import {useSelector} from "hooks";
import {convertDate} from "utils";
import ChannelDetails from "../drawers/channels-details";

const Channels = () => {
  const publishPermission = usePermission("PS_PublishProductCategoryToChannel");
  const {i18n} = useTranslation();
  const {selectedCategory} = useContext(CategoryContext);
  const companySetting = useSelector(s => s.companySetting);
  const [channels, setChannels] = useState([]);
  const [availableChannels, setAvailableChannels] = useState<any>([]);
  const [selectedChannel, setSelectedChannel] = useState<any>({});
  const [publishedList, setPublishedList] = useState<any>([]);

  const [loading, setLoading] = useToggleState();
  const [publishLoading, setPublishLoading] = useToggleState();
  const [isOpenDetails, toggleDetails] = useToggleState();
  const [isOpen, toggle] = useToggleState();
  const [updateCategory, setUpdateCategory] = useState<any>({});
  const [channelDetails, setChannelDetails] = useState<any>({});

  const getPublishedPimChannels = () => {
    const clone: any = [];
    const urls = companySetting?.channels?.map((channel: any) =>
      ServiceApi.get(
        URLS.GET_CHANNEL_IS_PUBLISHED(
          selectedCategory?.productCategoryId,
          channel.code,
        ),
      ),
    );
    Promise.all(urls)
      .then(([...responses]) => {
        responses?.map((res: any) => clone.push(res?.data));
      })
      .finally(() => {
        setAvailableChannels([...clone]);
      });
  };
  useEffect(() => {
    if (companySetting && publishPermission) {
      getPublishedPimChannels();
    }
  }, [companySetting, selectedCategory]);

  const getChannelsInfo = () => {
    const url = URLS.GET_CATEGORY_BY_ID(selectedCategory.productCategoryId);
    ServiceApi.get(url).then(({data}) => {
      setUpdateCategory(selectedCategory);
    });
  };

  useEffect(() => {
    setChannels(companySetting?.channels);
    setUpdateCategory(selectedCategory);
  }, [selectedCategory]);

  const removeUnpublishChannel = async (channel: any) => {
    const clone = availableChannels?.filter(
      (info: any) => info.channelCode !== channel?.code,
    );

    setAvailableChannels(clone);
  };

  const getPublishedChannelInfo = async (channel: any) => {
    const clone: any = [...availableChannels];
    const url = URLS.GET_CHANNEL_IS_PUBLISHED(
      selectedCategory.productCategoryId,
      channel.code,
    );
    try {
      const {data} = await ServiceApi.get(url);
      if (data) {
        const index = publishedList.findIndex(
          (item: any) => item.channelCode === channel.code,
        );
        if (index > -1) {
          clone[index] = data;
        } else {
          clone.push(data);
        }
      }
    } catch (error) {
      console.log(error);
    }
    setAvailableChannels(clone);
  };

  const publishHandler = (channel: any) => {
    setPublishLoading();
    const url = URLS.PUBLISH_CATEGORY(selectedCategory.productCategoryId);
    const body = {channelCode: channel.code};
    ServiceApi.post(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.publish-msg",
          "global.toast.publish-title",
        );
        setPublishLoading();
        getPublishedChannelInfo(channel);
        const list = [...publishedList];
        list.push(channel.code);
        setPublishedList(list);
      })
      .catch(() => setPublishLoading());
  };

  const unPublishHandler = (channel: any) => {
    setPublishLoading();
    const url = URLS.UNPUBLISH_CATEGORY(selectedCategory.productCategoryId);
    const body = {channelCode: channel.code};
    ServiceApi.post(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.unpublish-msg",
          "global.toast.unpublish-title",
        );
        setPublishLoading();
        removeUnpublishChannel(channel);
        // getPublishedList();
      })
      .catch(() => setPublishLoading());
  };

  const getInfo = () => {
    const info = updateCategory?.channelInfos?.filter(
      (channel: any) => channel.channelCode === selectedChannel.code,
    )[0];
    return info;
  };

  const info = getInfo();

  const getChannelDetails = (channel: any) => {
    setChannelDetails(channel);
  };

  return (
    <>
      <ChannelsComponent
        id={selectedCategory?.productCategoryId}
        publishUrl={URLS.PUBLISH_CATEGORY}
        unpublishUrl={URLS.UNPUBLISH_CATEGORY}
      />
      {/* <div className="space-y-4">
        {loading
          ? [1, 2, 3, 4, 5].map(l => <Skeleton.List key={l} />)
          : channels?.map((channel: any) => {
              return (
                <Wrapper>
                  <Wrapper.Body className="!p-4">
                    <div className="flex items-center justify-between">
                      <span className="text-heading-6 font-semibold">
                        {
                          channel?.channelNameTranslates?.filter(
                            (tr: any) =>
                              tr.language === i18n.language.toLowerCase(),
                          )[0]?.name
                        }
                      </span>
                      {availableChannels.find(
                        (pub: any) => pub.channelCode === channel?.code,
                      ) && (
                        <p className="text-body-base font-normal">
                          <span className="ml-3">
                            {convertDate(
                              availableChannels.find(
                                (info: any) =>
                                  channel?.code === info.channelCode,
                              )?.createdAt,
                            )}
                          </span>
                        </p>
                      )}
                      <div className="space-x-4">
                        {availableChannels.find(
                          (pub: any) => pub.channelCode === channel?.code,
                        ) && (
                          <>
                            {publishPermission && (
                              <>
                                <Button
                                  variant={"light"}
                                  size="sm"
                                  onClick={() => {
                                    toggleDetails();
                                    getChannelDetails(
                                      availableChannels.find(
                                        (pub: any) =>
                                          pub.channelCode === channel?.code,
                                      ),
                                    );
                                  }}
                                  disabled={publishLoading}
                                >
                                  <Text>
                                    productManagement.masterData.productCategory.details
                                  </Text>
                                </Button>

                                <Button
                                  light
                                  variant="danger"
                                  size="sm"
                                  onClick={() => unPublishHandler(channel)}
                                  disabled={publishLoading}
                                >
                                  <Text>
                                    productManagement.masterData.productCategory.unPublish
                                  </Text>
                                </Button>
                              </>
                            )}
                          </>
                        )}
                        {publishPermission && (
                          <>
                            <Button
                              light
                              size="sm"
                              onClick={() => publishHandler(channel)}
                              disabled={publishLoading}
                            >
                              <Text>
                                productManagement.masterData.productCategory.publish
                              </Text>
                            </Button>
                            <Button
                              size="sm"
                              variant={"light"}
                              disabled={publishLoading}
                              onClick={() => {
                                setSelectedChannel(channel);
                                toggle();
                              }}
                            >
                              <Icon icon="sliders" className="text-gray-600" />
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </Wrapper.Body>
                </Wrapper>
              );
            })}
      </div>
      <ChannelDrawer
        isOpen={isOpen}
        toggle={toggle}
        channelInfo={info}
        id={selectedCategory.productCategoryId}
        selectedChanel={selectedChannel}
        getChannelsInfo={getChannelsInfo}
      />
      <ChannelDetails
        isOpen={isOpenDetails}
        toggle={toggleDetails}
        details={channelDetails}
      /> */}
    </>
  );
};

export default Channels;
