import {cloneDeep} from 'lodash';
import {Dispatch, SetStateAction, useMemo, useState} from 'react';
import usePrompt from './usePrompt';
export default function useDataState<T>(
  initValue: T,
): [T, Dispatch<SetStateAction<T>>, Dispatch<SetStateAction<T>>, boolean, T] {
  const [base, setBase] = useState<T>(initValue);
  const [data, setData] = useState<T>(initValue);
  const isChanged = useMemo(() => {
    return JSON.stringify(base) != JSON.stringify(data);
  }, [base, data]);
  const setBaseData: Dispatch<SetStateAction<T>> = e => {
    setBase(cloneDeep(e));
    setData(cloneDeep(e));
  };
  const message = 'Are your sure you want discard your changes?';
  usePrompt(message, isChanged);
  return [data, setData, setBaseData, isChanged, base];
}
