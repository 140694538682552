export const salesChannels = {
  addSalesChannel: "Verkaufskanal hinzufügen",
  title: "TTitel",
  code: "Code",
  iln: "ILN (Internationale Standortnummer)",
  legalEntity: "Rechtseinheit",
  description: "Beschreibung",
  activeBusinessChannels: "Acktive Verkaufskanäle",
  NoOnlineShop: "NOP Online Schaufenster",
  general: "Allgemein",
  basicInfo: "Grundlegende Informationen",
  templates: "Templates",
};
