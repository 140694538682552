import {CountrySelectOption} from "types/country";
import {useEffect, useState} from "react";
import Select, {SingleValue} from "react-select";
import {config} from "constant";
import {Text} from "components";

type Props = {
  setValue: (v: CountrySelectOption) => void;
  value?: string;
  countryList?: CountrySelectOption[];
};

export default function SelectCountry({setValue, value, countryList}: Props) {
  const [selected, setSelected] = useState<CountrySelectOption>();

  const selectOptionByValue = () => {
    let defaultItem: CountrySelectOption[] | undefined;
    if (value)
      defaultItem = countryList?.filter(
        (item: CountrySelectOption) => item.value === value,
      );
    else
      defaultItem = countryList?.filter(
        (item: CountrySelectOption) => item.value === "DE",
      );

    if (defaultItem && defaultItem?.length > 0) {
      setSelected(defaultItem[0]);
      if (value) setValue(defaultItem[0]);
    }
  };

  const onCountryChangeHandler = (
    selected: SingleValue<CountrySelectOption>,
  ) => {
    if (selected) {
      setSelected?.(selected);
      setValue(selected);
    }
  };

  useEffect(() => {
    if (countryList) {
      selectOptionByValue();
    }
  }, [value, countryList]);

  return (
    <div className="w-full space-y-4">
      <label>
        <Text>global.locations.country</Text>
      </label>
      <Select
        styles={config.styleSelectReact}
        value={selected}
        isLoading={!countryList}
        isSearchable={true}
        name="global.locations.country"
        options={countryList}
        onChange={onCountryChangeHandler}
      />
    </div>
  );
}
