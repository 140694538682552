import {Button, Icon, Text} from "components";
import Chooser from "../common/chooser";
import {useToggleState} from "hooks";
import {useContext, useState} from "react";
import {ServiceApi, URLS} from "services";
import {config} from "constant";
import {SimulationContext} from "pages/price-engine/price-simulator";
import Info from "../common/info";

type dataType = {
  name: string;
  code?: string;
  id: string;
};

export default function SalesChannel() {
  const {setSimulateData, simulateData} = useContext(SimulationContext);
  const [isOpen, toggle] = useToggleState();
  const [salesChannels, setSalesChannels] = useState<dataType[]>([
    {
      name: "",
      code: "",
      id: "",
    },
  ]);
  const [loading, setLoading] = useToggleState();
  const [params, setParams] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  });

  const getSalesChannels = () => {
    setLoading();
    const url = URLS.GET_SALES_CHANNEL_LIST;
    const config = {params};
    ServiceApi.get(url, config)
      .then(({data}) => {
        const clone = data?.items?.map((channel: any) => ({
          id: channel.saleChannelId,
          name: channel.title,
          code: channel.code,
        }));
        setSalesChannels(clone);
        setLoading();
      })
      .catch(() => setLoading());
  };

  return (
    <>
      <div className="space-y-4">
        <div>
          <span className="text-body-base text-gray-800">
            <Text>priceEngine.simulator.salesChannel</Text> :
          </span>
        </div>
        {simulateData["salesChannel"] ? (
          <Info dataKey="salesChannel" />
        ) : (
          <>
            <Button light size="sm" onClick={toggle}>
              <Icon icon="plus" className="mr-2" />
              <Text>priceEngine.simulator.addSalesChannel</Text>
            </Button>
          </>
        )}
      </div>
      <Chooser
        isOpen={isOpen}
        toggle={toggle}
        title="priceEngine.simulator.salesChannels"
        data={salesChannels}
        getData={getSalesChannels}
        loading={loading}
        setParams={setParams}
        params={params}
        dataKey="salesChannel"
        setSimulateData={setSimulateData}
      />
    </>
  );
}
