import { Button, Footer, RadioButton, Skeleton, Text, Wrapper } from "components";
import { config } from "constant";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { ServiceApi, URLS } from "services";
import { UserContext } from "..";
import AsyncSelect from "react-select/async";

export default function Group() {
  const { userData, setUserData, UPDATE_PERMISSIONS, isChanged, submitLoading, loading } =
    useContext<any>(UserContext);
  const [selectedOptionsGroup, setSelectedOptionsGroup] = useState<any>([]);

  const handleSetData = (selected: any) => {
    if (!selected) {
      setSelectedOptionsGroup(null);
    } else {
      setSelectedOptionsGroup(selected);
    }
    setUserData((p: any) => ({ ...p, ["userGroupId"]: (selected?.id || '') }));
  };

  const handleSearchGroups = async (filter: string) => {
    const url = URLS.USER_GROUP_URL;
    const params = {
      pageNumber: 1,
      pageSize: 100,
      keyword: filter,
    };
    return ServiceApi.get(url, { params }).then(({ data }) => {
      const groups = data.items.map((group: any) => ({
        ...group,
        id: group.id,
        label: group.name,
        value: group.id,
        code: group.id,
      }));
      const selectedItem = groups.find(
        (item: any) => item.id === userData?.userGroupId,
      );
      setSelectedOptionsGroup(selectedItem);
      return groups;
    });
  };
  const promiseGroupOptions = (inputValue: string) =>
    new Promise<any[]>(resolve => {
      setTimeout(() => {
        resolve(handleSearchGroups(inputValue));
      }, 200);
    });

  return (
    <>
      <Wrapper>
        <Wrapper.Header>
          <h1 className="text-heading-2 font-semibold">
            <Text>configurations.userManagment.userList.usersGroup</Text>
          </h1>
        </Wrapper.Header>
        <Wrapper.Body className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
          <div className="space-y-8 ">
            {loading ? (
              <div className="space-y-3">
                <Skeleton.Input />
                <div className="space-y-3">
                  <Skeleton.Button />
                  <Skeleton.Button />
                </div>
              </div>
            ) : (
              <div className="space-y-6">
                <label
                  className="h6 block w-full truncate font-[400] text-gray-800 "
                >
                  <Text>configurations.userManagment.userList.userType</Text>
                </label>
                <div className="space-y-4">
                  <RadioButton
                    label="configurations.userManagment.userList.admin"
                    value={userData.userType === 4}
                    setValue={() => {
                      handleSetData(null);
                      setUserData((p: any) => ({
                        ...p,
                        userType: 4,
                      })
                      )
                    }
                    }
                  />
                  <RadioButton
                    label="configurations.userManagment.userList.user"
                    value={userData.userType === 8}
                    setValue={() => setUserData((p: any) => ({
                      ...p,
                      userType: 8,
                    })
                    )}
                  />
                </div>
              </div>
            )}
            <div className={`${userData.userType === 8 ? 'opacity-100' : 'opacity-0'}`}>
              {loading ? (
                <Skeleton.Input />
              ) : (
                <div className="space-y-4">
                  <label
                    className="h6 block w-full truncate font-[400] text-gray-800 "
                  >
                    <Text>configurations.userManagment.userList.userGroup</Text>
                  </label>
                  <AsyncSelect
                    value={selectedOptionsGroup}
                    cacheOptions
                    placeholder={
                      <Text>
                        {"configurations.userManagment.userList.usersGroup"}
                      </Text>
                    }
                    loadOptions={promiseGroupOptions}
                    defaultOptions
                    onChange={handleSetData}
                    styles={config.styleSelectReact}
                    isClearable
                    isDisabled={!UPDATE_PERMISSIONS || userData.userType === 4}
                    required={userData.userType === 8}
                  />
                </div>
              )}
            </div>
          </div>
        </Wrapper.Body>
      </Wrapper>
      {/* {UPDATE_PERMISSIONS && ( */}
      <Footer show={!isChanged}>
        <Button as={Link} to={-1} variant="white">
          <Text>global.buttons.discard</Text>
        </Button>
        <Button
          type="submit"
          variant="primary"
          disabled={!isChanged}
          loading={submitLoading}
        >
          <Text>global.buttons.saveChanges</Text>
        </Button>
      </Footer>
      {/* )} */}
    </>
  );
}
