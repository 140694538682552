import {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {ServiceApi, URLS} from "services";
import reverseArrayHelper from "./find-path";
import {useToggleState} from "hooks";
import {Icon, LoadingSvg} from "components";

type Props = {
  pimProductFamilyId: string;
  productData: any;
};

const ProductFamilyPath = ({pimProductFamilyId, productData}: Props) => {
  const {i18n} = useTranslation();
  const [productFamily, setProductFamily] = useState([]);
  const [loading, toggle] = useToggleState();
  const [productFamilyReverse, setProductFamilyReverse] = useState<any>([]);

  useEffect(() => {
    if (pimProductFamilyId) {
      getProductFamilyList();
    }
  }, [productData?.pimProductFamilyId]);

  useEffect(() => {
    setProductFamilyLocalization(productFamilyReverse);
  }, [i18n.language]);

  const TranslateLabel = (
    data: any,
    currentLang = "de",
    translateLabelObjectName = "name",
  ) => {
    let translatedLabel = data.translates.find(
      (item: any) => item.language?.toUpperCase() === currentLang.toUpperCase(),
    );
    translatedLabel = translatedLabel
      ? translatedLabel[translateLabelObjectName]
      : null;
    return translatedLabel;
  };

  const getProductFamilyList = () => {
    toggle();
    const url = URLS.GET_PIM_PRODUCT_FAMILIES_URL;
    ServiceApi.get(url)
      .then(({data}) => {
        toggle();
        const reverseArray = reverseArrayHelper(
          data,
          pimProductFamilyId,
          "pimProductFamilyId",
        );
        setProductFamilyLocalization(reverseArray);
        setProductFamilyReverse(reverseArray);
      })
      .catch(() => toggle());
  };

  const setProductFamilyLocalization = (productFamiliesList: any) => {
    let productFamilyList: any = [];
    productFamiliesList.map((item: any) => {
      const translatedLabel =
        TranslateLabel(item, i18n.language, "labelTitle") || item.code;
      productFamilyList.push(translatedLabel);
    });
    setProductFamily(productFamilyList);
  };

  return (
    <span>
      {loading ? (
        <LoadingSvg size="sm" />
      ) : (
        productFamily.map((item, index) => {
          return index + 1 === productFamily.length ? item : `${item} > `;
        })
      )}
    </span>
  );
};

export default ProductFamilyPath;
