import JsonView from "@uiw/react-json-view";
import {Button, Drawer, Icon, Skeleton, Tabs, Text, Status} from "components";
import {Fragment, useEffect, useState} from "react";
import {ServiceApi} from "services";
import {ChannelMessage, toggleProps} from "types";
import {convertDate} from "utils";
type ChannelMessageDetailsProps = toggleProps & {
  channelMessageId: string;
};
export default function ChannelMessageDetails({
  isOpen,
  toggle,
  channelMessageId,
}: ChannelMessageDetailsProps) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ChannelMessage.Details | null>(null);
  const [activeTab, setActiveTab] = useState(0);
  const hasOutData = !!data?.outData;
  const getData = () => {
    if (!isOpen) return;
    setLoading(true);
    const url = `/channelmanagerservice/api/channelmessage/${channelMessageId}`;
    ServiceApi.get(url)
      .then(({data}) => {
        setActiveTab(data?.step);
        setData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(getData, [isOpen, channelMessageId]);
  const tabs2 = [
    {
      label: "requirementGathering",
    },
    {
      label: "configurationValidation",
    },
    {
      label: "dataTransmission",
    },
    {
      label: "acknowledgeReceipt",
    },
  ];
  const isCompleted = (index: number) => {
    return index < activeTab;
  };
  return (
    <Drawer isOpen={isOpen} toggle={toggle} size="lg">
      <Tabs activeKey="data">
        <Drawer.Menu>
          <Drawer.Header className="flex items-center gap-4">
            <h2 className="flex-1 text-start text-heading-2">
              <Text>channelMessage.details.title</Text>
            </h2>
            <Button type="button" variant="light" size="sm" onClick={toggle}>
              <Icon icon="close" /> <Text>channelMessage.details.closeBtn</Text>
            </Button>
          </Drawer.Header>
          <Drawer.Header className="!py-0">
            <Tabs.ButtonGroup>
              <Tabs.Button eventKey="data">
                <Text>channelMessage.details.dataTab</Text>
              </Tabs.Button>
              <Tabs.Button eventKey="outData">
                <Text>channelMessage.details.outDataTab</Text>
              </Tabs.Button>
            </Tabs.ButtonGroup>
          </Drawer.Header>
          <Drawer.Body className="space-y-2 pt-9">
            {loading ? (
              <Skeleton.TextArea />
            ) : (
              <Fragment>
                <div className="mb-4 space-y-4 rounded border border-dashed border-gray-400 p-4 text-gray-800">
                  <div className="grid grid-cols-3 justify-between">
                    <span>
                      <Text>global.channelMessage.channel</Text>
                    </span>
                    <span className="col-span-2">{data?.channelCode}</span>
                  </div>
                  <div className="grid grid-cols-3 justify-between">
                    <span>
                      <Text>global.channelMessage.createdAt</Text>
                    </span>
                    <span className="col-span-2">
                      {convertDate(data?.createdAt)}
                    </span>
                  </div>
                  <div className="grid grid-cols-3 justify-between">
                    <span>
                      <Text>global.channelMessage.updatedAt</Text>
                    </span>
                    <span className="col-span-2">
                      {convertDate(data?.updatedAt)}
                    </span>
                  </div>

                  <div className="col-span-full space-y-2 px-2">
                    <div className="w-full py-4">
                      <div className="relative grid h-24 w-full place-items-center">
                        <div className="w-full px-6 pt-8">
                          <div className="relative flex w-full items-center justify-between">
                            <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 border-t-2 border-dashed border-primary-light"></div>
                            <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4"></div>

                            {tabs2.map((e, i) => {
                              return (
                                <div
                                  key={e.label}
                                  data-active={activeTab === i}
                                  className="group"
                                >
                                  <div
                                    className={`relative z-10 grid h-7 w-7 cursor-pointer place-items-center rounded-md ${
                                      activeTab > i
                                        ? "bg-primary-light  text-primary"
                                        : " bg-gray-300  text-gray-500"
                                    } group-data-active:bg-primary `}
                                  >
                                    <span
                                      className={` flex-center group-data-active:text-white`}
                                    >
                                      {isCompleted(i) ? (
                                        <Icon icon="check" size="1x" />
                                      ) : (
                                        i + 1
                                      )}
                                    </span>
                                    <div className="absolute -top-[3.3rem] w-max text-center text-xs">
                                      <h6
                                        className={`block w-20 text-clip font-sans text-sm font-normal leading-5 tracking-normal text-inherit antialiased group-data-active:text-primary`}
                                      >
                                        <Text>{`channelMessage.steps.${e.label}`}</Text>
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-3 items-center justify-between">
                    <span>
                      <Text>global.channelMessage.status</Text>
                    </span>
                    <div className="col-span-2 flex items-center space-x-3">
                      {<Status.PimChannelStatus id={data?.status!} />}
                      {/* <Status.ChannelMessage id={data?.status!} /> */}
                      <p className="text-sm text-warning">
                        {data?.errorCode && (
                          <>
                            <Icon icon="circle-info" className="mr-2" />
                            <Text>{`errors.${data?.errorCode}`}</Text>
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <Tabs.Item eventKey="data">
                  <h6 className="text-heading-6 text-gray-800">
                    <Text>channelMessage.details.json</Text>:
                  </h6>
                  <p className="w-full whitespace-pre-wrap break-words rounded bg-gray-100 px-4 py-3 leading-6 text-gray-700">
                    {data?.data && <JsonView value={JSON.parse(data.data)} />}
                  </p>
                </Tabs.Item>
                <Tabs.Item eventKey="outData">
                  <h6 className="text-heading-6 text-gray-800">
                    <Text>channelMessage.details.json</Text>:
                  </h6>
                  <p className="w-full whitespace-pre-wrap break-words rounded bg-gray-100 px-4 py-3 leading-6 text-gray-700">
                    {data?.outData ? (
                      <JsonView value={JSON.parse(data?.outData)} />
                    ) : (
                      <Text>channelMessage.details.noData</Text>
                    )}
                  </p>
                </Tabs.Item>
              </Fragment>
            )}
          </Drawer.Body>
        </Drawer.Menu>
      </Tabs>
    </Drawer>
  );
}
