import { useContext } from "react";
import PriceCalculationTab from "compositions/price-calculation-units/price-calculation-tab";
import { calculationLevelEnum } from "constant";
import { ProgramContext } from "../details";

export default function PriceCalculation() {
  const { data } = useContext(ProgramContext);

  return (
    <PriceCalculationTab
      refId={data?.id}
      level={calculationLevelEnum.program}
      refTitle={data?.title}
      refCode={data?.code}
    />
  );
}
