import {useState, ComponentProps, useId, useRef, useEffect} from "react";
import {useTranslate, useMountEffect, useUpdateEffect} from "hooks";
import ChipItems from "./ChipItems";
import Dropdown from "../Dropdown";
import Icon from "../Icon";
import Text from "../Text";

type itemType = {name: string; id: number | string};
type defaultMultiSelectProps = {
  label?: string;
  items?: itemType[];
  setValue?: (val: any) => void;
};

type multiSelectProps = defaultMultiSelectProps &
  Omit<
    ComponentProps<typeof ChipItems>,
    keyof "onClick" | "append" | "readOnly" | "type"
  >;

export default function MultiSelect({
  label,
  value = [],
  items = [],
  placeholder,
  rules = [],
  prepend,
  className,
  setValue = () => {},
}: multiSelectProps) {
  const translate = useTranslate();
  const [newItems, setNewItems] = useState<itemType[]>([]);
  const [values, setValues] = useState<any>(value);
  const inputGroupRef = useRef<HTMLDivElement>(null);
  const inputId = useId();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const res = getValues(items, value);
    setValues(res.selected);
    setNewItems(res.list);
  }, [items]);

  const getValues = (a: itemType[], b: string[]) => {
    setValues([]);
    setNewItems([]);
    if (b.length !== 0) {
      let list = a.filter(
        (e: any) => !b.find((a: any) => e.id === (a.id ? a.id : a)),
      );
      let selected = a.filter((e: any) =>
        b.find((a: any) => e.id === (a.id ? a.id : a)),
      );

      return {selected: selected, list: list};
    } else {
      return {selected: "", list: a};
    }
  };

  const onSelectRow = (e: any) => {
    let val = values ? values : [];
    val?.push(e);
    const res = getValues(items, val);
    setValues(res.selected);
    setNewItems(res.list);
    setValue(res.selected);
  };

  useEffect(() => {
    inputGroupRef.current?.classList.toggle("required", !!rules.length);
  }, [rules]);
  useEffect(() => {
    inputGroupRef.current?.classList.toggle("error", !!errorMessage);
  }, [errorMessage]);
  useEffect(() => {
    const formControl = inputGroupRef.current?.querySelector(".form-control");
    // @ts-ignore: Unreachable code error
    formControl.onValid = () => {
      return rules.every(rule => {
        const ruleValue = rule(value);
        const isValid = ruleValue === true;
        setErrorMessage(isValid ? "" : ruleValue);
        return isValid;
      });
    };
  }, [rules, value]);

  return (
    <div ref={inputGroupRef} className="input-group group w-full">
      {label && (
        <label
          htmlFor={inputId}
          className="h6 mb-4 block w-full truncate font-normal text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
        >
          <Text>{label}</Text>
        </label>
      )}
      <Dropdown
        className={`block w-full cursor-pointer ${className}`}
        onSelect={(e: any) => {
          onSelectRow(e);
        }}
      >
        <Dropdown.Toggle
          as={ChipItems}
          value={values}
          rules={rules}
          prepend={prepend}
          placeholder={placeholder}
          onClickDeleteItem={(e: any) => {
            let val = values.filter((obj: any) => {
              return obj.id !== e;
            });
            const res = getValues(items, val);
            setValues(res.selected);
            setNewItems(res.list);
            setValue(res.selected);
          }}
          onClickDeleteItems={() => {
            setValues([]);
            setNewItems(items);
            setValue([]);
          }}
          append={
            <Icon
              className="ml-2 cursor-pointer text-gray-700 transition-transform group-data-active:rotate-180"
              icon={"chevron-down"}
            />
          }
        />
        <Dropdown.Menu className="max-h-[15rem] overflow-auto">
          {newItems?.map((e: any) => (
            <Dropdown.Item
              key={e.id}
              as="button"
              type="button"
              eventKey={e}
              isActive={value === e}
            >
              {translate(e.name) || e.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      {errorMessage && (
        <p className="mt-1 text-xs text-danger">
          <i className="bi bi-info-circle mr-1" />
          <Text>{errorMessage}</Text>
        </p>
      )}
    </div>
  );
}
