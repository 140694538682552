
const assetTypes = {
    PimProduct: 0,
    Customer: 1,
    OfferDocs: 2,
    SellOfProduct: 3,
    Company: 4,
    User: 5,
    Applications: 6,
    Temp: 99
}

export default assetTypes