import {useState, useEffect} from "react";
import {Drawer, Button, Text, Icon, InputGroup, Skeleton} from "components";
import {useToggleState} from "hooks";
import {Departments} from "types";
import {ServiceApi, URLS} from "services";
import {useParams} from "react-router";
import {NotificationManager} from "common/notifications";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  userDepartments: string[];
  getData: () => void;
}

type Params = {
  keyword?: string | null;
  pageSize: number;
  pageNumber: number;
};

export default function ChooseDepartment({
  isOpen,
  toggle,
  userDepartments,
  getData,
}: Props) {
  const {employeeId} = useParams();
  const [data, setData] = useState<Departments.DepartmentDataType>();
  const [loading, setLoading] = useToggleState();
  const [loadingSubmit, toggleSubmit] = useToggleState();
  const [id, setId] = useState<string>();

  const [params, setParams] = useState<Params>({
    keyword: "",
    pageNumber: 1,
    pageSize: 10,
  });

  const getDepartments = () => {
    setLoading();
    const url = URLS.GET_DEPARTMENTS_URL;
    const config = {params};
    ServiceApi.get(url, config)
      .then(({data}: any) => {
        setData(data);
        setId("");
        setLoading();
      })
      .catch(() => setLoading());
  };

  useEffect(() => {
    if (isOpen) {
      const timeoutId = setTimeout(() => getDepartments(), 500);
      return () => clearTimeout(timeoutId);
    } else {
      setId("");
      setData(undefined);
    }
  }, [params, isOpen]);

  const onSubmitHandler = () => {
    toggleSubmit();
    const url = URLS.ADD_EMPLOYEE_TO_DEPARTMENT(id);
    const body = {
      employeeIds: [employeeId],
    };
    ServiceApi.post(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title",
        );
        toggleSubmit();
        getData();
        toggle();
      })
      .catch(() => toggleSubmit());
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header className="flex items-center justify-between">
          <Text>company.employees.chooseDepartment</Text>
          <Button size="sm" variant={"light"} onClick={toggle}>
            <Icon icon="close" /> <Text>global.buttons.close</Text>
          </Button>
        </Drawer.Header>
        <Drawer.Body className="space-y-6">
          {loading ? (
            [1, 2, 3, 4].map(() => <Skeleton.List />)
          ) : (
            <>
              <InputGroup
                value={params?.keyword}
                label={"company.stores.search"}
                setValue={keyword =>
                  setParams(p => ({...p, keyword, pageNumber: 1}))
                }
                placeholder="company.stores.searchPlaceHolder"
              />
              {data?.items?.map((dep: Departments.DepartmentItemType) => {
                const hasDepartment = userDepartments.includes(dep.id);
                return (
                  <>
                    {!hasDepartment && (
                      <div
                        onClick={() => {
                          if (!hasDepartment) setId(dep?.id);
                        }}
                        className={`${
                          id === dep?.id ? "bg-primary-light" : "bg-white "
                        } ${
                          hasDepartment
                            ? "cursor-not-allowed opacity-30"
                            : "cursor-pointer "
                        } ${
                          loadingSubmit && "pointer-events-none"
                        } flex  justify-between rounded-lg p-4 shadow-card hover:bg-primary-light`}
                      >
                        <div className="flex flex-col gap-2">
                          <span className="text-heading-6 font-semibold">
                            {dep?.title}
                          </span>
                          <span className="text-body-2 text-gray-500">
                            <Icon icon="envelope" /> {dep?.emailAddress}
                          </span>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </>
          )}
        </Drawer.Body>
        <Drawer.Footer className="flex justify-between">
          <Button size="sm" variant={"light"} onClick={toggle}>
            <Text>global.buttons.cancel</Text>
          </Button>
          <Button
            size="sm"
            disabled={!id}
            onClick={onSubmitHandler}
            loading={loadingSubmit}
          >
            <Text>global.buttons.submit</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
