import {Skeleton, Text} from "components";
import {useContext, useEffect, useState} from "react";
import {DetailsContext} from "./details";
import {ServiceApi, URLS} from "services";
import {Departments} from "types";
import {AxiosResponse} from "axios";

export default function GeneralTab() {
  const {details, loading} = useContext(DetailsContext);
  const [teamLead, setTEamLead] = useState<Departments.EmployeeDetail>();
  const getTeamLeadInfo = () => {
    if (details) {
      const url = URLS.GET_EMPLOYEE_DETAILS(details.leadEmployeeId);
      ServiceApi.get(url).then(({data}: AxiosResponse) => {
        setTEamLead(data);
      });
    }
  };

  useEffect(getTeamLeadInfo, [details]);

  return (
    <div className="space-y-6">
      {loading ? (
        [1, 2].map(() => <Skeleton.TextArea />)
      ) : (
        <>
          <div className="space-y-6 rounded-lg border border-dashed border-gray-400 p-8">
            <span className="text-heading-3 font-semibold text-gray-600">
              <Text>company.stores.basicInfo</Text>
            </span>
            <div className="grid grid-cols-3 gap-4 *:text-gray-800">
              <div className="col-span-1 flex flex-col gap-4">
                <span>
                  <Text>company.stores.titel</Text>
                </span>
                <span>
                  <Text>company.stores.emailAddress</Text>
                </span>
                <span>
                  <Text>company.stores.shortDescription</Text>
                </span>
              </div>
              <div className="col-span-2 flex flex-col gap-4">
                <span>{details?.title}</span>
                <span>{details?.emailAddress}</span>
                <span className="text-wrap leading-5">
                  {details?.shortDescription}
                </span>
              </div>
            </div>
          </div>
          <div className="space-y-6 rounded-lg border border-dashed border-gray-400 p-8">
            <span className="text-heading-3 font-semibold text-gray-600">
              <Text>company.stores.leadInfo</Text>
            </span>
            {teamLead ? (
              <div className="grid grid-cols-3 gap-4 *:text-gray-800 ">
                <div className="col-span-1 flex flex-col gap-4">
                  <span>
                    <Text>company.stores.teamLead</Text>
                  </span>
                  <span>
                    <Text>company.stores.employeeNumber</Text>
                  </span>
                  <span>
                    <Text>company.stores.emailAddress</Text>
                  </span>
                </div>
                <div className="col-span-2 flex flex-col gap-4">
                  <span>
                    {teamLead?.firstName} {teamLead?.lastName}
                  </span>
                  <span className="text-wrap leading-5">
                    {teamLead?.employeeNumber}
                  </span>
                  <span>{teamLead?.emailAddress}</span>
                </div>
              </div>
            ) : (
              <Skeleton.TextArea />
            )}
          </div>
        </>
      )}
    </div>
  );
}
