import {Button, Icon, Modal, Text} from "components";

type deleteModalType = {
  isOpen: boolean;
  loading: boolean;
  toggle: () => void;
  onConfirm: (id: string) => void;
  id?: string;
  selectedName?: string;
};

export default function DeleteModal(props: deleteModalType) {
  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggle}
      width="w-[400px]"
      modalClassName="z-40"
    >
      <Modal.Body className="max-h-100 overflow-auto">
        <div className="flex-center pb-4 text-center text-[80px]">
          <Icon
            className="h-20 w-20 text-danger"
            icon={"triangle-exclamation"}
          />
        </div>
        <div className="text-center text-body-base text-gray-700">
          <span>
            <Text>global.areYouSureYouWantToDelete</Text>
          </span>
        </div>
        <div className="py-4 text-center text-heading-6 text-gray-800">
          <h6>
            {props.selectedName}
            <span>?</span>
          </h6>
        </div>
        <div className="pb-2 text-center text-body-base text-danger">
          <span>
            <Text>global.youCantUndoThis</Text>!
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-between">
        <Button onClick={props.toggle} variant="white">
          <Icon className="mr-2" icon={"times"} />
          <Text>global.buttons.ImNotSure</Text>
        </Button>
        <Button
          type="submit"
          className=""
          variant="danger"
          onClick={() => props.onConfirm(props.id as string)}
          loading={props.loading}
        >
          <Icon className="mr-2" icon={"trash"} />
          <Text>global.buttons.delete</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
