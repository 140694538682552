import SalesChannels from "pages/sales-managment/sales-channels";
import Details from "pages/sales-managment/sales-channels/details";

const SalesChannelRoutes = [
  {
    path: "",
    element: <SalesChannels />,
  },
  {
    path: ":salesChannelId",
    element: <Details />,
  },
];

export default SalesChannelRoutes;
