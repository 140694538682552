import {AddressForm} from "components";
import {AddSupplierContext} from "..";
import {Fragment, useContext} from "react";

export default function Location() {
  const {data, setData, addressErrorMsg} = useContext<any>(AddSupplierContext);
  return (
    <Fragment>
      <AddressForm data={data} setData={setData} errorMsg={addressErrorMsg} />
    </Fragment>
  );
}
