import {NotificationManager} from "common/notifications";
import {
  Button,
  InputGroup,
  SelectLang,
  Skeleton,
  Text,
  Wrapper,
} from "components";
import {flagCountry} from "constant";
import {useDataState, useSelector, useToggleState} from "hooks";
import {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {ServiceApi, URLS} from "services";
import {ProductFamiliesContext} from ".";

const General = () => {
  const {root, getProductFamilies} = useContext(ProductFamiliesContext);
  const profile = useSelector(s => s.profile);
  const [lang, setLang] = useState<string>(profile?.companyDefaultLanguage);
  // const [data, setData] = useState<any>(root);
  const [data, setData, setBaseData, isChanged] = useDataState<any>(root);
  const [loading, setLoading] = useToggleState();
  const [loadingButton, setLoadingButton] = useState(false);
  const [langItems, setLangItems] = useState([]);
  const companySetting = useSelector(s => s.companySetting);

  useEffect(() => {
    setBaseData(root);
    setLang(companySetting?.defaultDashboardLanguage?.toLocaleLowerCase());
  }, []);

  useEffect(() => {
    let arr: any = [];
    companySetting?.dashboardLanguages?.forEach((item: any) => {
      arr.push({id: item, name: item});
    });
    setLangItems(arr);
  }, [companySetting]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeLanguage = (lang: any) => {
    setLang(lang);
  };

  const onChangeHandler = (value: any) => {
    const isAvailable = data?.translates?.filter(
      (tr: any) => tr.language.toLowerCase() === lang,
    );

    let clone = [...data?.translates];

    if (isAvailable.length === 0) {
      clone.push({
        labelTitle: value,
        language: lang,
        description: "",
      });
    } else {
      data?.translates?.map((tr: any, index: number) => {
        if (tr.language.toLowerCase() === lang) {
          clone[index].labelTitle = value;
        }
      });
    }
    setData({...data, translates: clone});
  };

  const getTranslatedName = () => {
    const translate = data?.translates.filter(
      (tr: any) => tr.language.toLowerCase() === lang,
    );
    return translate[0]?.labelTitle;
  };

  const submitHandler = () => {
    setLoading();
    setLoadingButton(true);
    const url = URLS.CREATE_GET_PUT_DELETE_PIM_PRODUCT_FAMILY_URL;
    const body = {
      pimProductFamilyId: root?.pimProductFamilyId,
      icon: root?.icon,
      translates: data?.translates,
    };
    ServiceApi.put(url, body)
      .then(({data}) => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.title-msg",
        );
        setLoading();
        getProductFamilies();
        setLoadingButton(false);
      })
      .catch(err => {
        setLoadingButton(false);
        setLoading();
      });
  };

  return (
    <>
      <Wrapper>
        <Wrapper.Header>
          <div className="flex items-center justify-between text-heading-2 font-semibold">
            <Text>productManagement.masterData.productFamilies.basicInfo</Text>
            <SelectLang
              value={lang}
              items={langItems}
              onChangeLanguage={onChangeLanguage}
            />
          </div>
        </Wrapper.Header>
        <Wrapper.Body className="grid grid-cols-4">
          <div className="col-span-2 space-y-4">
            {loading ? (
              <>
                <Skeleton.Input />
                <Skeleton.Input />
              </>
            ) : (
              <>
                <InputGroup
                  value={data?.translates && getTranslatedName()}
                  setValue={e => onChangeHandler(e)}
                  label="Family Name"
                  flag={flagCountry[lang]}
                />
                <InputGroup
                  value={data?.code}
                  label="Family Code"
                  disabled
                  readOnly
                />
              </>
            )}
          </div>
        </Wrapper.Body>
      </Wrapper>
      <Wrapper className="sticky bottom-0">
        <Wrapper.Body className="flex items-center justify-end gap-4">
          <Button as={Link} to={-1} variant="white">
            <Text>global.buttons.discard</Text>
          </Button>
          <Button
            type="button"
            variant="primary"
            onClick={submitHandler}
            disabled={!isChanged}
            loading={loadingButton}
          >
            <Text>global.buttons.saveChanges</Text>
          </Button>
        </Wrapper.Body>
      </Wrapper>
    </>
  );
};

export default General;
