import {
  Button,
  DatePicker,
  Drawer,
  Icon,
  InputGroup,
  RadioButton,
  Select,
  Text,
} from "components";
import { useTranslate } from "hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { HandleParamsUrl } from "utils";

type drawerProps = {
  isOpen: boolean;
  toggle: () => void;
  resetFilter: () => void;
  setParams: (key: any) => void;
  setKeyword: (key: any) => void;
  setThisDate: (key: any) => void;
  onChange: (key: any) => any;
  data: any;
  params: any;
  keyword: any;
  thisDate: any;
};

const FilterDrawer = ({
  isOpen,
  data,
  params,
  toggle,
  onChange,
  resetFilter,
  setParams,
  setKeyword,
  keyword,
  setThisDate,
  thisDate,
}: drawerProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const translate = useTranslate();

  const createItems = (key: string) => {
    const clone = data?.[key]?.map((item: any) => ({
      id: item.value,
      name: item.value + "(" + item.count + ")",
    }));
    clone?.unshift({ id: null, name: translate("global.all") });
    return clone;
  };

  const submit = (e: any) => {
    e.preventDefault();
    if (keyword !== "") {
      setParams((prev: any) => {
        prev.set("keyword", keyword);
        return prev;
      });
    } else {
      setParams((prev: any) => {
        prev.delete("keyword");
        return prev;
      });
    }
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pr-4 pt-6">
          <div className="flex items-center justify-between">
            <span className="text-heading-2 font-semibold">
              <Text>salesManagment.sellOffSales.sellOffProduct.filters</Text>
            </span>
            <div className="flex gap-x-2">
              {searchParams?.size !== 0 && (
                <Button
                  size="sm"
                  variant={"danger"}
                  light
                  onClick={() => resetFilter()}
                >
                  <Icon icon="times-circle" className="mr-1" />
                  <Text>
                    salesManagment.sellOffSales.sellOffProduct.resetFilters
                  </Text>
                </Button>
              )}
              <Button size="sm" variant={"light"} onClick={toggle}>
                <Icon icon="times" className="mr-1" />
                <Text>salesManagment.sellOffSales.sellOffProduct.close</Text>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-4 px-6 py-8">
          <div className="flex-center flex gap-2">
            <form onSubmit={submit} className="w-full">
              <InputGroup
                label="salesManagment.sellOffSales.sellOffCommissions.keyword"
                value={keyword}
                placeholder="global.placeholders.search"
                setValue={value => setKeyword(value)}
              />
            </form>
            <div
              className="mt-8 flex h-[46px] w-[46px] cursor-pointer items-center justify-center rounded-md bg-gray-100 p-3"
              onClick={() => {
                if (keyword !== "") {
                  setParams((prev: any) => {
                    prev.set("keyword", keyword);
                    return prev;
                  });
                } else {
                  setParams((prev: any) => {
                    prev.delete("keyword");
                    return prev;
                  });
                }
              }}
            >
              <Icon
                className="h-5 w-5 !leading-6 text-primary"
                icon={"search"}
              />
            </div>
          </div>
          <Select
            label="salesManagment.sellOffSales.sellOffCommissions.seller"
            placeholder="Select"
            value={params?.get("sellerName")}
            items={createItems("sellerName")}
            setValue={onChange("sellerName")}
          />
          <Select
            label="salesManagment.sellOffSales.sellOffCommissions.status"
            placeholder="Select"
            value={params?.get("status")}
            items={createItems("status")}
            setValue={onChange("status")}
          />
          <div className="space-y-6">
            <div>
              <h5 className="text-heading-5 font-semibold text-gray-800">
                <Text>
                  salesManagment.sellOffSales.sellOffCommissions.soldDateRange
                </Text>
              </h5>
            </div>
            <div className="flex items-center">
              <RadioButton
                label="salesManagment.sellOffSales.sellOffCommissions.thisWeek"
                value={thisDate === "thisWeek" ? true : false}
                setValue={() => {
                  let date = new Date();
                  let firstDay = date.getDate() - date.getDay();
                  let lastDay = firstDay + 6;
                  setParams((prev: any) => {
                    prev.set(
                      "createdDateFrom",
                      new Date(date.setDate(firstDay)).toUTCString(),
                    );
                    prev.set(
                      "createdDateTo",
                      new Date(date.setDate(lastDay)).toUTCString(),
                    );
                    return prev;
                  });
                  setThisDate("thisWeek");
                  const paramUrl = HandleParamsUrl(
                    searchParams,
                    "createdDateFrom",
                    new Date(date.setDate(firstDay)).toUTCString(),
                    "createdDateTo",
                    new Date(date.setDate(lastDay)).toUTCString(),
                  );
                  navigate(
                    `/salesmanagment/sell-off-sales/sell-off-commissions?${paramUrl}`,
                  );
                }}
              />
              <RadioButton
                label="salesManagment.sellOffSales.sellOffCommissions.thisMonth"
                value={thisDate === "thisMonth" ? true : false}
                setValue={() => {
                  let date = new Date();
                  let firstDay = new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    1,
                  );
                  let lastDay = new Date(
                    date.getFullYear(),
                    date.getMonth() + 1,
                    0,
                  );
                  setParams((prev: any) => {
                    prev.set("createdDateFrom", firstDay);
                    prev.set("createdDateTo", lastDay);
                    return prev;
                  });
                  setThisDate("thisMonth");
                  const paramUrl = HandleParamsUrl(
                    searchParams,
                    "createdDateFrom",
                    firstDay.toDateString(),
                    "createdDateTo",
                    lastDay.toDateString(),
                  );
                  navigate(
                    `/salesmanagment/sell-off-sales/sell-off-commissions?${paramUrl}`,
                  );
                }}
              />
              <RadioButton
                label="salesManagment.sellOffSales.sellOffCommissions.lastMonth"
                value={thisDate === "lastMonth" ? true : false}
                setValue={() => {
                  let date = new Date();
                  let firstDay = new Date(
                    date.getFullYear(),
                    date.getMonth() - 1,
                    1,
                  );
                  let lastDay = new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    0,
                  );
                  setParams((prev: any) => {
                    prev.set("createdDateFrom", firstDay);
                    prev.set("createdDateTo", lastDay);
                    return prev;
                  });
                  setThisDate("lastMonth");
                  const paramUrl = HandleParamsUrl(
                    searchParams,
                    "createdDateFrom",
                    firstDay.toDateString(),
                    "createdDateTo",
                    lastDay.toDateString(),
                  );
                  navigate(
                    `/salesmanagment/sell-off-sales/sell-off-commissions?${paramUrl}`,
                  );
                }}
              />
            </div>
            <div className="grid grid-cols-2 gap-2 gap-y-4">
              <DatePicker
                showYearDropdown
                showMonthDropdown
                label="salesManagment.sellOffSales.sellOffCommissions.from"
                value={
                  params?.get("createdDateFrom") !== null
                    ? new Date(params?.get("createdDateFrom"))
                    : null
                }
                onChange={onChange("createdDateFrom")}
                maxDate={
                  params?.get("createdDateTo") !== null
                    ? new Date(params?.get("createdDateTo"))
                    : null
                }
              />
              <DatePicker
                showYearDropdown
                showMonthDropdown
                label="salesManagment.sellOffSales.sellOffCommissions.to"
                minDate={
                  params?.get("createdDateFrom") !== null
                    ? new Date(params?.get("createdDateFrom"))
                    : null
                }
                value={
                  params?.get("createdDateTo") !== null
                    ? new Date(params?.get("createdDateTo"))
                    : null
                }
                onChange={onChange("createdDateTo")}
              />
            </div>
          </div>
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
};

export default FilterDrawer;
