import {useContext, useEffect, useState} from "react";
import {
  Wrapper,
  Text,
  Button,
  Icon,
  Table,
  Skeleton,
  LoadingSvg,
} from "components";
import {useConverter, useToggleState} from "hooks";
import AddItemDrawer from "../drawers/add-item-list";
import {PimContext} from "..";
import {Link, useNavigate} from "react-router-dom";
import EditItem from "../drawers/edit-item";
import {ServiceApi, URLS} from "services";
export default function ItemsTab() {
  const navigate = useNavigate();
  const [isOpen, toggle] = useToggleState();
  // const [status, toggleStatus] = useToggleState();
  const {
    productData,
    loading,
    setTotalVks,
    totalVks,
    ids,
    overwrittenPrices,
    status,
  } = useContext(PimContext);
  const [isOpenEdit, toggleEdit] = useToggleState();
  const [selectedProduct, setSelectedProduct] = useState<any>({});
  // const [ids, setIds] = useState<string[]>([]);
  // const [overwrittenPrices, setOverwrittenPrices] = useState<string[]>([]);
  const {convertAmount} = useConverter();

  // useEffect(() => {
  //   const ids = productData?.bundleItems?.map((bundle: any) => bundle.id);
  //   setIds(ids);
  //   getOverwrittenPrices();
  // }, [productData]);

  // const getOverwrittenPrices = () => {
  //   const clone: any = [];
  //   toggleStatus();
  //   const urls = productData?.bundleItems?.map((item: any) => {
  //     const body = {
  //       productId: productData?.id,
  //       supplierId: item.supplierId,
  //       programId: item.supplierProgramId,
  //       brandId: item.brandId,
  //       basePrice: item.purchasePrice?.amount,
  //       purchasePriceType: item.purchasePriceType,
  //     };
  //     return ServiceApi.post(URLS.CALC_PIM_SALE_PRICE, body);
  //   });
  //   Promise.all(urls)
  //     .then(([...responses]) => {
  //       responses.map((res: any) => clone.push(res?.data));
  //     })
  //     .finally(() => {
  //       setOverwrittenPrices([...clone]);
  //       const totalVks = productData?.bundleItems?.map(
  //         (item: any, index: number) => {
  //           return {
  //             vk1: clone[index]?.vk1 * item.quantity,
  //             vk2: clone[index]?.vk2 * item.quantity,
  //             linePrice: item.overwrittenSalePrice
  //               ? item.overwrittenSalePrice.amount * item.quantity
  //               : clone[index]?.vk1 * item.quantity,
  //           };
  //         },
  //       );
  //       setTotalVks(totalVks);
  //       toggleStatus();
  //     });
  // };

  //UI
  return (
    <>
      <Wrapper>
        <Wrapper.Header className="flex items-center justify-between">
          <span>
            <Text>productManagement.products.allProducts.items</Text>
          </span>
          <Button light className="flex items-center" onClick={toggle}>
            <span>
              <Text>productManagement.products.allProducts.addItem</Text>
            </span>
            <Icon icon="plus" className="ml-2" />
          </Button>
        </Wrapper.Header>
        <Wrapper.Body className="space-y-6">
          {loading ? (
            [1, 2, 3, 4].map(() => <Skeleton.ImageList />)
          ) : (
            <Table>
              {productData?.bundleItems?.map((item: any, index: number) => {
                const overwrittenPrice: any = overwrittenPrices[index];
                const linePrice: any = totalVks[index]?.linePrice;
                const hasOverWrittenPrice = !!item.overwrittenSalePrice?.amount;
                const hasPurchasePrice = !!item.purchasePrice?.amount;
                return (
                  <tr key={item.id} className="rounded-lg p-4 shadow-items">
                    <td className="space-y-2">
                      <div className="space-y-2 text-wrap py-2">
                        <h6 className="text-heading-6 font-semibold leading-6 text-gray-800">
                          {item.originalName}
                        </h6>
                      </div>
                      <div className="text-sm text-gray-500">
                        <Text>
                          productManagement.products.Details.productFamily
                        </Text>{" "}
                        :
                        <span className="ml-1">
                          {item.pimProductFamilyCode}
                        </span>
                      </div>
                      <div className="text-sm text-gray-500">
                        <Text>productManagement.products.Details.supplier</Text>{" "}
                        :<span className="ml-1">{item.supplierName} </span>
                      </div>
                      <div className="text-sm text-gray-500">
                        <Text>productManagement.products.Details.program</Text>{" "}
                        :
                        <span className="ml-1">
                          {item.supplierProgramTitle}{" "}
                        </span>
                      </div>
                    </td>
                    <td className="space-y-2 text-left">
                      <div className="text-sm text-gray-500">
                        <Text>
                          productManagement.products.Details.articleNumber
                        </Text>{" "}
                        :<span className="ml-1">#{item.articleNumber}</span>
                      </div>
                      <div className="text-sm text-gray-500">
                        <Text>productManagement.products.Details.supplier</Text>
                        # :
                        <span className="ml-1">
                          {item.supplierArticleNumber}
                        </span>
                      </div>
                      <div className="text-sm text-gray-500">
                        <Text>
                          productManagement.products.Details.refrenceKey
                        </Text>
                        # :
                        <span className="ml-1">
                          {item.referenceKey ? item.referenceKey : "---"}
                        </span>
                      </div>
                      {hasPurchasePrice && (
                        <>
                          <p className="text-sm">
                            <Text>
                              productManagement.products.allProducts.purchasePrice
                            </Text>{" "}
                            : {convertAmount(item.purchasePrice?.amount)}
                          </p>
                        </>
                      )}
                      <div className="text-sm text-gray-500">
                        <Text>
                          productManagement.products.allProducts.quantity
                        </Text>{" "}
                        :<span className="ml-1">{`x${item.quantity}`}</span>
                      </div>
                    </td>
                    <td className="space-y-2 text-left">
                      {status ? (
                        <LoadingSvg size="sm" />
                      ) : (
                        <div className="flex flex-col space-y-2 *:text-sm">
                          <p>vk2 : {convertAmount(overwrittenPrice?.vk2)}</p>
                          <p>vk1 : {convertAmount(overwrittenPrice?.vk1)}</p>
                        </div>
                      )}

                      {hasOverWrittenPrice && (
                        <p className="text-sm">
                          <Text>
                            productManagement.products.allProducts.overwrittenSalePrice
                          </Text>{" "}
                          : {convertAmount(item.overwrittenSalePrice?.amount)}
                        </p>
                      )}
                    </td>
                    <td className="space-y-2 *:text-sm">
                      <p>line Price</p>
                      <span>{convertAmount(linePrice)}</span>
                    </td>
                    <td className="space-x-2">
                      <Button
                        variant={"light"}
                        as={Link}
                        size="sm"
                        target="_blank"
                        to={`/productManagement/products/all-products/${item.id}`}
                      >
                        <Text>global.buttons.details</Text>
                      </Button>
                      <Button
                        variant={"light"}
                        size="sm"
                        onClick={() => {
                          setSelectedProduct(item);
                          toggleEdit();
                        }}
                      >
                        <Icon icon="sliders" />
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </Table>
          )}
        </Wrapper.Body>
      </Wrapper>
      <AddItemDrawer isOpen={isOpen} toggle={toggle} ids={ids} />
      <EditItem
        isOpen={isOpenEdit}
        toggle={toggleEdit}
        item={selectedProduct}
        setItem={setSelectedProduct}
      />
    </>
  );
}
