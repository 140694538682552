import { Badge, Text } from "components";
import { types } from "constant";

type Props = {
  id: number;
};
function Program({ id }: Props) {
  const activeType = types.program.find((e) => e.id === id);
  return (
    <Badge variant={activeType?.variant ?? "dark"}>
      <Text>{activeType?.name ?? ""}</Text>
    </Badge>
  );
}
const Types = { Program };
export default Types;
