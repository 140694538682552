import {Wrapper, Text} from "components";
import PriceSimulation from "compositions/price-simulation";
import {useContext} from "react";
import {PimContext} from "../..";

export default function PriceTemplates() {
  const {productData, brandId, baseData} = useContext(PimContext);

  return (
    <Wrapper>
      <Wrapper.Header>
        <Text>productManagement.products.Details.priceTemplates</Text>
      </Wrapper.Header>
      <Wrapper.Body className="grid grid-cols-1 gap-4 p-4 xl:grid-cols-2">
        <PriceSimulation
          supplierId={productData.supplierId}
          programId={productData.supplierProgramId}
          brandId={brandId}
          productId={productData.id}
          basePrice={productData?.purchasePrice?.amount}
          baseData={baseData}
          priceType={productData?.purchasePriceType}
          simulateFrom="pim"
        />
      </Wrapper.Body>
    </Wrapper>
  );
}
