import {Fragment, useContext, useState, useEffect} from "react";
import {
  Select,
  Skeleton,
  Wrapper,
  CheckBox,
  Text,
  RadioButton,
} from "components";
import {ServiceApi, URLS} from "services";
import {AddUserListContext} from "..";
import {config, rules} from "constant";

export default function UsersGroup() {
  const {data, setData} = useContext<any>(AddUserListContext);
  const [groupList, setGroupList] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [params, _setParams] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  });

  const getData = () => {
    const url = URLS.USER_GROUP_URL;
    const config = {params};
    setLoadingList(true);
    ServiceApi.get(url, config)
      .then(({data}) => {
        setGroupList(data.items);
      })
      .finally(() => {
        setLoadingList(false);
      });
  };
  useEffect(getData, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <span className="text-heading-2 font-semibold">
        <Text>configurations.userManagment.userList.userGroup</Text>
      </span>
      {loadingList ? (
        <div className="space-y-3">
          <Skeleton.Input />
          <div className="space-y-3">
            <Skeleton.Button />
            <Skeleton.Button />
          </div>
        </div>
      ) : (
        <div className="space-y-6">
          <label className="h6 block w-full truncate font-[400] text-gray-800 ">
            <Text>configurations.userManagment.userList.userType</Text>
          </label>
          <div className="space-y-4">
            <RadioButton
              label="configurations.userManagment.userList.admin"
              value={data.userType === 4}
              setValue={() =>
                setData((p: any) => ({
                  ...p,
                  userType: 4,
                  userGroupName: "",
                  userGroupId: "",
                }))
              }
            />
            <RadioButton
              label="configurations.userManagment.userList.user"
              value={data.userType === 8}
              setValue={() =>
                setData((p: any) => ({
                  ...p,
                  userType: 8,
                }))
              }
            />
          </div>
        </div>
      )}
      <div className={`${data.userType === 8 ? "opacity-100" : "opacity-0"}`}>
        {loadingList ? (
          <Skeleton.Input />
        ) : (
          <Select
            label="configurations.userManagment.userList.usersGroup"
            items={groupList}
            value={data.userGroupId}
            disabled={data.userType === 4 ? true : false}
            setValue={e => {
              groupList?.forEach((i: any) => {
                if (i.id === e) {
                  setData((p: any) => ({
                    ...p,
                    userGroupName: i.name,
                    userGroupId: i.id,
                  }));
                }
              });
            }}
            rules={data.userType === 4 ? [] : rules.required}
          />
        )}
      </div>
    </Fragment>
  );
}
