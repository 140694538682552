const SUPPLIERS = {
  SUPPLIER_URL: id => `productservice/api/suppliers/${id}`,
  GET_SUPPLIERS: "productservice/api/suppliers",
  CREATE_SUPPLIERS: "productservice/api/suppliers",
  ADD_SUPPLIER: "productservice/api/suppliers",
  UPLOAD_SUPPLIERS_IMAGES: id => `productservice/api/suppliers/${id}/logo`,
  GET_SUPPLIER_DETAILS: id => `productservice/api/suppliers/${id}`,
  EDIT_SUPPLIER: "productservice/api/suppliers/",
  DELETE_SUPPLIER_ID: "productservice/api/suppliers/",
  SET_SUPPLIER__PRICE_TEMPLATE: id =>
    `productservice/api/suppliers/${id}/set-price-template`,
  PUBLISH_SUPPLIER_TO_CHANNEL: id =>
    `productservice/api/suppliers/${id}/publish-to-channel`,
  UNPUBLISH_SUPPLIER_FROM_CHANNEL: id =>
    `productservice/api/suppliers/${id}/unpublish-from-channel`,
  DELETE_SUPPLIER: id => `productservice/api/suppliers/${id}`,
  //...... discount
  ADD_DISCOUNT_SUPPLIERS: id => `productservice/api/suppliers/${id}/discount`,
  DELETE_DISCOUNT_SUPPLIERS: (id, discountId) =>
    `productservice/api/suppliers/${id}/discount/${discountId}`,
  UPDATE_DISCOUNT_SUPPLIERS: (id, discountId) =>
    `productservice/api/suppliers/${id}/discount/${discountId}`,

  //...... programs
  GET_SUPPLIER_PROGRAMS: id =>
    `productservice/api/supplierprograms?supplierId=${id}`,
  GET_PROGRAM_DETAILS: id => `productservice/api/supplierprograms/${id}`,
  CREATE_NEW_PROGRAM: "productservice/api/supplierprograms",
  UPDATE_PROGRAM: id => `productservice/api/supplierprograms/${id}`,
  PUBLISH_PROGRAM_TO_CHANNEL: id =>
    `productservice/api/supplierprograms/${id}/publish-to-channel`,
  UNPUBLISH_PROGRAM_FROM_CHANNEL: id =>
    `productservice/api/supplierprograms/${id}/unpublish-from-channel`,

  //...... attributs - programs
  ADD_ATTRIBUTE: (id, supplierId) =>
    `productservice/api/supplierprograms/${id}/attribute/${supplierId}`,
  DELETE_ATTRIBUTE: (id, supplierId) =>
    `productservice/api/supplierprograms/${id}/attribute/${supplierId}`,

  //...... discount - programs
  ADD_DISCOUNT_PROGRAMS_SUPPLIERS: id =>
    `productservice/api/supplierprograms/${id}/discount`,
  DELETE_DISCOUNT_PROGRAMS_SUPPLIERS: (id, discountId) =>
    `productservice/api/supplierprograms/${id}/discount/${discountId}`,
  UPDATE_DISCOUNT_PROGRAMS_SUPPLIERS: (id, discountId) =>
    `productservice/api/supplierprograms/${id}/discount/${discountId}`,

  //....... Holidays
  GET_HOLIDAYS_SUPPLIERS: `productservice/api/supplierholidays`,
  POST_HOLIDAYS_SUPPLIERS: `productservice/api/supplierholidays`,
  PUT_HOLIDAYS_SUPPLIERS: id => `productservice/api/supplierholidays/${id}`,
  DELETE_HOLIDAYS_SUPPLIERS: id => `productservice/api/supplierholidays/${id}`,

  //........ Contact Person
  GET_CONTACT_PERSON: "productservice/api/suppliercontactpersons",
  ADD_CONTACT_PERSON: "productservice/api/suppliercontactpersons",
  EDIT_DELETE_CONTACT_PERSON: contactPersonId =>
    `productservice/api/suppliercontactpersons/${contactPersonId}`,
};

export default SUPPLIERS;
