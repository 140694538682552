import { variantType } from "types";
import { status } from "../constant";
import Badge from "./Badge";
import Icon from "./Icon";
import Text from "./Text";

type statusProps = {
  id: number | string | boolean;
};

function Store({ id }: statusProps) {
  const active: any = status.store.find((e: any) => e.id === id);
  return (
    <Badge variant={active?.variant}>
      <Text>{active?.name}</Text>
    </Badge>
  );
}

function Required({ id }: statusProps) {
  const required: any = status.required.find((e: any) => e.id === id);
  return (
    <Badge variant={required?.variant}>
      <Text>{required?.name}</Text>
    </Badge>
  );
}

function EmployeesType({ id }: any) {
  return (
    <Badge variant="primary">
      <Text>{id}</Text>
    </Badge>
  );
}

function EmployeesStatus({ id }: statusProps) {
  const currentStatus: any = status.employeeSatus.find((e: any) => e.id === id);
  return (
    <Badge variant={currentStatus?.variant}>
      <Text>{currentStatus?.name}</Text>
    </Badge>
  );
}

function SellOff({ id }: statusProps) {
  const selloff: any = status.sellOff.find((e: any) => e.name === id);
  return (
    <Badge variant={selloff?.variant}>
      <Text>{selloff?.name}</Text>
    </Badge>
  );
}
function ProductFamilies({ id }: statusProps) {
  const productFamilies: any = status.productFamilies.find(
    (e: any) => e.name === id,
  );
  return (
    <Badge variant={productFamilies?.variant}>
      <Text>{productFamilies?.name}</Text>
    </Badge>
  );
}
function Supplier({ id }: statusProps) {
  const active: any = status.supplier.find((e: any) => e.id === id);
  return (
    <Badge variant={active?.variant}>
      <Text>{active?.name}</Text>
    </Badge>
  );
}

function RequiredAttribute({ id }: statusProps) {
  const required: any = status.requiredAttribute.find((e: any) => e.id === id);
  return (
    <>
      {id !== false ? (
        <Badge variant={required?.variant}>
          <Text>{required?.name}</Text>
        </Badge>
      ) : (
        <></>
      )}
    </>
  );
}
function BuiltInAttribute({ id }: statusProps) {
  const required: any = status.builtInAttribute.find((e: any) => e.id === id);
  return (
    <>
      {id !== false ? (
        <Badge variant={required?.variant}>
          <Text>{required?.name}</Text>
        </Badge>
      ) : (
        <></>
      )}
    </>
  );
}
function PerChannelAttribute({ id }: statusProps) {
  const perChannel: any = status.perChannelAttribute.find(
    (e: any) => e.id === id,
  );
  return (
    <>
      {id === true ? (
        <Badge variant={perChannel?.variant}>
          <Text>{perChannel?.name}</Text>
        </Badge>
      ) : (
        <></>
      )}
    </>
  );
}
function PerLanguageAttribute({ id }: statusProps) {
  const perLanguage: any = status.perLanguageAttribute.find(
    (e: any) => e.id === id,
  );
  return (
    <>
      {id === true ? (
        <Badge variant={perLanguage?.variant}>
          <Text>{perLanguage?.name}</Text>
        </Badge>
      ) : (
        <></>
      )}
    </>
  );
}

function DefaultStatus({ id }: statusProps) {
  const defaultStatus: any = status.defaultLabel.find((e: any) => e.id === id);
  return (
    <>
      {id === true ? (
        <Badge variant={defaultStatus?.variant}>
          <Text>{defaultStatus?.name}</Text>
        </Badge>
      ) : (
        <></>
      )}
    </>
  );
}

function Standard({ id }: statusProps) {
  const standard: any = status.standard.find((e: any) => e.id === id);
  return (
    <>
      {id === true ? (
        <Badge variant={standard?.variant}>
          <Text>{standard?.name}</Text>
        </Badge>
      ) : (
        <></>
      )}
    </>
  );
}

function roles({ id }: statusProps) {
  const role: any = status.roles.find((e: any) => e.id === id);
  return (
    <Badge variant={role?.variant}>
      <Text>{role?.name}</Text>
    </Badge>
  );
}

function productActive({ id }: statusProps) {
  const role: any = status.productActive.find((e: any) => e.id === id);
  return (
    <Badge variant={role?.variant}>
      <Text>{role?.name}</Text>
    </Badge>
  );
}

function salesListStatus({ id }: statusProps) {
  const role: any = status.salesListStatus.find((e: any) => e.id === id);
  return (
    <Badge variant={role?.variant}>
      {id === 3 ? (
        <Icon icon="clock-five" className="mr-2" />
      ) : (
        <Icon icon="check" className="mr-2" />
      )}
      <Text>{role?.name}</Text>
    </Badge>
  );
}

function salesListIconStatus({ id }: statusProps) {
  const role: any = status.salesListStatus.find((e: any) => e.id === id);
  return (
    <Badge variant={role?.variant}>
      <div>
        <Icon icon={role?.icon} />
      </div>
    </Badge>
  );
}

function salesCommentsIconStatus({ id }: statusProps) {
  const role: any = status.salesListStatus.find((e: any) => e.id === id);
  return (
    <Badge variant={role?.variant} className="flex h-[46px] items-center">
      <div>
        <Icon icon={role?.icon} />
      </div>
    </Badge>
  );
}

function sellFactorHasWarning({ id }: statusProps) {
  const role: any = status.sellFactorHasWarning.find((e: any) => e.id === id);
  return (
    <Badge variant={role?.variant}>
      <Icon icon="triangle-exclamation" className="mr-2" />
      <Text>{role?.name}</Text>
    </Badge>
  );
}

function shippingCostHasWarning({ id }: statusProps) {
  const role: any = status.shippingCostHasWarning.find((e: any) => e.id === id);
  return (
    <Badge variant={role?.variant}>
      <Icon icon="triangle-exclamation" className="mr-2" />
      <Text>{role?.name}</Text>
    </Badge>
  );
}

function Active({ id }: statusProps) {
  const active: any = status.active.find((e: any) => e.id === id);
  return (
    <Badge variant={active?.variant}>
      {id === false ? (
        <Icon icon="close" className={active?.name && "mr-2"} />
      ) : (
        <Icon icon="check" className={active?.name && "mr-2"} />
      )}
      <Text>{active?.name}</Text>
    </Badge>
  );
}
function PimChannelStatus({ id }: statusProps) {
  const active: any = status.pimChannelStatus.find((e: any) => e.id === id);
  return (
    <Badge variant={active?.variant}>
      <Text>{active?.name}</Text>
    </Badge>
  );
}

function ChannelMessage({ id }: statusProps) {
  const active = status.channelMessage.find(e => e.id === id);
  return (
    <Badge variant={active?.variant as variantType}>
      <Text>{active?.name ?? "--"}</Text>
    </Badge>
  );
}

function PriceTemplates({ id }: statusProps) {
  const active = status.priceTemplates.find(e => e.active === id);
  return (
    <Badge variant={active?.variant as variantType}>
      <Text>{active?.name ?? "--"}</Text>
    </Badge>
  );
}

function GeneralDiscount({ id }: statusProps) {
  const active = status.generalDiscount.find(e => e.active === id);
  return (
    <Badge variant={active?.variant as variantType}>
      <Text>{active?.name ?? "--"}</Text>
    </Badge>
  );
}

function TemplateType({ id }: statusProps) {
  const role: any = status.templateType.find((e: any) => e.id === id);
  return (
    <Badge variant={role?.variant}>
      <Text>{role?.name}</Text>
    </Badge>
  );
}

function CalculationUnits({ id }: statusProps) {
  const active = status.calculationUnits.find(e => e.active === id);
  return (
    <Badge variant={active?.variant as variantType}>
      <Text>{active?.name ?? "--"}</Text>
    </Badge>
  );
}
function PurchasePrice({ id }: statusProps) {
  const active = status.calculationUnits.find(e => e.active === id);
  return (
    <Badge variant={active?.variant as variantType}>
      <Text>{active?.name ?? "--"}</Text>
    </Badge>
  );
}
function SupplierType({ id }: statusProps) {
  const active = status.supplierType.find(e => e.id === id);
  return (
    <Badge variant={active?.variant as variantType}>
      <Text>{active?.name ?? "--"}</Text>
    </Badge>
  );
}
function Availability({ id }: statusProps) {
  const active = status.availability.find(e => e.id === id);
  return (
    <Badge variant={active?.variant as variantType}>
      <Text>{active?.name ?? "--"}</Text>
    </Badge>
  );
}
function AvailabilityStatusWithTitle({ id }: statusProps) {
  const active = status.availability.find(e => e.id === id);
  return (
    <Badge variant={active?.variant as variantType}>
      <Text>productManagement.publishedProduct.Details.availabilityStatus</Text>: {' '}
      <Text>{active?.name ?? "--"}</Text>
    </Badge>
  );
}
function PimProductType({ id }: statusProps) {
  const active = status.pimProductType.find(e => e.id === id);
  return (
    <Badge variant={active?.variant as variantType}>
      <Text>{active?.name ?? "--"}</Text>
    </Badge>
  );
}

function ProductLabelTemplateActivation({ id }: statusProps) {
  const role: any = status.productLabelActivation.find((e: any) => e.id === id);
  return (
    <Badge variant={role?.variant}>
      <Text>{role?.name}</Text>
    </Badge>
  );
}

function PrintRequestStatus({ id }: statusProps) {
  const role: any = status.printRequestStatus.find((e: any) => e.id === id);
  return (
    <Badge variant={role?.variant}>
      <Text>{role?.name}</Text>
    </Badge>
  );
}

function pimCheckList({ id }: statusProps) {
  const active = status.pimCheckList.find(e => e.active === id);
  return (
    <Badge variant={active?.variant as variantType}>
      <Text>{active?.name ?? "--"}</Text>
    </Badge>
  );
}

function checkListTtypeStatus({ id }: statusProps) {
  const active = status.checkListTtypeStatus.find((e: any) => e.id === id);
  return (
    <Badge variant={active?.variant as variantType}>
      <Text>{active?.name ?? "--"}</Text>
    </Badge>
  );
}

const Status = {
  SupplierType,
  PriceTemplates,
  Store,
  Required,
  SellOff,
  Supplier,
  RequiredAttribute,
  PerChannelAttribute,
  PerLanguageAttribute,
  DefaultStatus,
  Standard,
  ProductFamilies,
  roles,
  productActive,
  salesListStatus,
  sellFactorHasWarning,
  shippingCostHasWarning,
  salesListIconStatus,
  salesCommentsIconStatus,
  Active,
  EmployeesType,
  PimChannelStatus,
  ChannelMessage,
  GeneralDiscount,
  BuiltInAttribute,
  TemplateType,
  CalculationUnits,
  PurchasePrice,
  EmployeesStatus,
  Availability,
  PimProductType,
  ProductLabelTemplateActivation,
  PrintRequestStatus,
  AvailabilityStatusWithTitle,
  pimCheckList,
  checkListTtypeStatus
};
export default Status;
