import {Wrapper, CheckBox} from "components";
import {useContext} from "react";
import {ContextTypes} from "..";
import {CalculationUnitContext} from "..";
import {usePermission} from "hooks";
import {PriceEngin} from "types";

export default function Configurations() {
  const {data, loading, setData} = useContext<ContextTypes>(
    CalculationUnitContext,
  );
  //Available Price
  const unitTypes = [
    {
      label: "priceEngine.calculationUnits.avilableOnlyInPurchase",
      key: "availableForPurchase",
    },
    {
      label: "priceEngine.calculationUnits.availableOnlyInSalesTemplate",
      key: "availableForSale",
    },
  ];
  const updatePermission = usePermission("PE_ModifyCalculationUnit");

  return (
    <Wrapper>
      <Wrapper.Body>
        <div className="flex flex-col gap-8">
          <CheckBox
            label="priceEngine.calculationLevel.activeForPim"
            value={data?.activeForPim}
            setValue={e => setData(p => ({...p, activeForPim: e}))}
            disabled={loading || !updatePermission}
          />
          <CheckBox
            label="priceEngine.calculationLevel.activeForIwofurn"
            value={data?.activeForIwofurn}
            setValue={e => setData(p => ({...p, activeForIwofurn: e}))}
            disabled={loading || !updatePermission}
          />
          <CheckBox
            label="priceEngine.calculationLevel.activeForManual"
            value={data?.activeForManual}
            setValue={e => setData(p => ({...p, activeForManual: e}))}
            disabled={loading || !updatePermission}
          />
          {unitTypes.map(unit => (
            <CheckBox
              label={unit.label}
              value={
                data[
                  unit.key as keyof PriceEngin.CalculationUnitItem
                ] as boolean
              }
              setValue={e =>
                setData((p: any) => ({
                  ...p,
                  [unit.key]: e,
                }))
              }
              disabled={loading || !updatePermission}
            />
          ))}
        </div>
      </Wrapper.Body>
    </Wrapper>
  );
}
