import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Wrapper,
  Text,
  Button,
  Table,
  Skeleton,
  NoItems,
  LoadingSvg,
  Icon,
} from "components";
import { useConverter, useToggleState } from "hooks";
import { PimContext } from "..";
import { PriceEngineService } from "types";
import { PurchasePriceType } from "enum";
import { ServiceApi, URLS } from "services";
import ChangedPriceDrawer from "../drawers/changed-price";
import Commission from "../drawers/commission";

export default function BundleItems() {
  const { details, loading } = useContext(PimContext);
  const { convertAmount } = useConverter();
  const [nowPriceList, setNowPriceList] = useState<
    PriceEngineService.CalculatePimSalePricesResult[]
  >([]);
  const [bundle, setBundel] = useState<any>();
  const [loadingPrice, toggle] = useToggleState();
  const [drawer, toggleDrawer] = useToggleState();
  const [isOpenCommission, toggleCommission] = useToggleState();

  const generateType = (item: any, data: any) => {
    if (
      data?.vk1 === item?.originalVk1Price?.amount &&
      data?.vk2 === item?.vk2Price?.amount
    )
      return "";
    if (!!item.hasOverwrittenPrice) return "danger";
    return "warning";
  };

  const backgroundColor = {
    warning: "bg-gradient-to-r from-warning-light",
    danger: "bg-gradient-to-r from-danger-light",
  };

  const getPriceNow = () => {
    toggle();
    const clone: any = [];
    const urls = details?.bundleItems.map((item: any) => {
      const body = {
        productId: item?.id,
        supplierId: item?.supplier?.supplierId,
        programId: item?.supplier?.program?.supplierProgramId,
        brandId: item?.supplier?.program?.brand?.brandId,
        saleChannelId: details?.saleChannelId,
        basePrice: item.grossPrice?.amount,
        purchasePriceType: PurchasePriceType.Gross,
      };
      return ServiceApi.post(URLS.CALC_PIM_SALE_PRICE, body);
    });
    Promise.all(urls)
      .then(([...responses]) => {
        responses.map((res: any) => clone.push(res?.data));
      })
      .finally(() => {
        setNowPriceList([...clone]);
        toggle();
      });
  };

  useEffect(() => {
    getPriceNow();
  }, []);

  return (
    <>
      {loadingPrice ? (
        [...Array(4)].map((index: number) => <Skeleton.List />)
      ) : (
        <Wrapper>
          <Wrapper.Header className="flex items-center justify-between">
            <span>
              <Text>productManagement.products.allProducts.items</Text>
            </span>
          </Wrapper.Header>
          <Wrapper.Body className="space-y-6">
            {loading ? (
              [1, 2, 3, 4].map(() => <Skeleton.ImageList />)
            ) : (
              <>
                {details?.bundleItems?.length ? (
                  <Table>
                    {details?.bundleItems?.map((item: any, index: number) => {
                      const activeType = generateType(
                        item,
                        nowPriceList[index],
                      );
                      //   const overwrittenPrice: any = overwrittenPrices[index];
                      //   const linePrice: any = totalVks[index]?.linePrice;
                      //   const hasOverWrittenPrice = !!item.overwrittenSalePrice?.amount;
                      //   const hasPurchasePrice = !!item.purchasePrice?.amount;
                      return (
                        <tr
                          key={item.id}
                          className={`rounded-lg p-4 shadow-items ${backgroundColor[activeType as keyof typeof backgroundColor]}`}
                        >
                          <td className="space-y-2">
                            <div className="space-y-2 text-wrap py-2">
                              <h6 className="text-heading-6 font-semibold leading-6 text-gray-800">
                                {item.originalName}
                              </h6>
                            </div>
                            {item?.productFamily && (
                              <div className="text-sm text-gray-500">
                                <Text>
                                  productManagement.products.Details.productFamily
                                </Text>{" "}
                                :
                                <span className="ml-1">
                                  {item.productFamily?.code}
                                </span>
                              </div>
                            )}
                            {item?.supplier && (
                              <>
                                <div className="text-sm text-gray-500">
                                  <Text>
                                    productManagement.products.Details.supplier
                                  </Text>{" "}
                                  :
                                  <span className="ml-1">
                                    {item.supplier?.name}{" "}
                                  </span>
                                </div>
                                {item?.supplier?.program && (
                                  <div className="text-sm text-gray-500">
                                    <Text>
                                      productManagement.products.Details.program
                                    </Text>{" "}
                                    :
                                    <span className="ml-1">
                                      {item?.supplier?.program?.name}{" "}
                                    </span>
                                  </div>
                                )}
                              </>
                            )}
                          </td>
                          <td className="space-y-2 text-left">
                            <div className="text-sm text-gray-500">
                              <Text>
                                productManagement.products.Details.articleNumber
                              </Text>{" "}
                              :
                              <span className="ml-1">
                                #{item.articleNumber}
                              </span>
                            </div>
                            <div className="text-sm text-gray-500">
                              <Text>
                                productManagement.products.Details.supplier
                              </Text>
                              # :
                              <span className="ml-1">
                                {item.supplierArticleNumber}
                              </span>
                            </div>
                            <div className="text-sm text-gray-500">
                              <Text>
                                productManagement.products.Details.refrenceKey
                              </Text>
                              # :
                              <span className="ml-1">
                                {item.referenceKey ? item.referenceKey : "---"}
                              </span>
                            </div>
                            <div className="text-sm text-gray-500">
                              <Text>
                                productManagement.products.allProducts.quantity
                              </Text>{" "}
                              :
                              <span className="ml-1">{`x${item.quantity}`}</span>
                            </div>
                          </td>
                          <td className="space-y-2 *:text-sm">
                            <p>
                              <Text>
                                productManagement.publishedProduct.Details.linePrice
                              </Text>
                            </p>
                            <span>{convertAmount(item.linePrice)}</span>
                          </td>
                          <td className="space-x-2">
                            <div className="flex justify-end gap-2">
                              {activeType && (
                                <Button
                                  variant={activeType}
                                  light
                                  size="sm"
                                  onClick={() => {
                                    setBundel({
                                      item,
                                      price: nowPriceList[index],
                                      type: activeType,
                                    });
                                    toggleDrawer();
                                  }}
                                >
                                  <Text>
                                    productManagement.publishedProduct.Details.changedPrice
                                  </Text>
                                </Button>
                              )}
                              <Button
                                as={Link}
                                variant={"light"}
                                to={`/productManagement/products/all-products/${item.id}`}
                                target="_blank"
                                size="sm"
                              >
                                <Text>global.buttons.details</Text>
                              </Button>
                              <Button
                                variant={"light"}
                                size="sm"
                                onClick={() => {
                                  setBundel({
                                    item
                                  });
                                  toggleCommission();
                                }}
                              >
                                <Icon icon="sliders" />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </Table>
                ) : (
                  <NoItems />
                )}
              </>
            )}
          </Wrapper.Body>
        </Wrapper>
      )}
      <ChangedPriceDrawer
        isOpen={drawer}
        toggle={toggleDrawer}
        bundle={bundle}
      />
      <Commission
        isOpen={isOpenCommission}
        toggle={toggleCommission}
        bundle={bundle}
      />
    </>
  );
}
