export const dataStorage = {
  cloudStorage: "Cloud Storage",
  pulsAzureBlobStorage: "Puls Azure Blob Storage",
  personalAzureBlobStorage: "Personal Azure Blob Storage",
  blobConfigId: "Blob Config Id",
  key: "Key",
  account: "Account",
  connectionString: "Connection String",
  localStorage: "Local Storage",
  eloFileManagement: "Elo File Management",
  url: "URL",
  username: "Username",
  password: "Password",
  cloudStorageUpdateMessage: "Cloud Storage updated successfully.",
  localStorageUpdateMessage: "Local Storage updated successfully.",
  cloudStorageRemoveErrorMessage:
    "It is not possible to delete before creation.",
  localStorageRemoveErrorMessage:
    "It is not possible to delete before creation.",
  pimProduct: "Pim Product",
  customerProfile: "Customer Profile",
  offerDocs: "Offer Docs",
  SellOfProduct: "Sell Of Products",
  Company: "Company",
  User: "user",
  Applications: "Applications",
  DocumentTemplates: "DocumentTemplates",
  generalBlob: "General Blob",
  personalBlob: "Personal Blob",
  personalElo: "Personal Elo",
  none: "None",
  storage: "Storage",
  access: "Access",
  public: "Public",
  customer: "Customer",
  thirdParty: "ThirdParty",
  dashboard: "Dashboard",
};
