import {NotificationManager} from "common/notifications";
import {
  Button,
  Drawer,
  Form,
  Icon,
  InputGroup,
  MultiSelect,
  Skeleton,
  Text,
} from "components";
import {config, rules} from "constant";
import {usePermission} from "hooks";
import {Fragment, useEffect, useRef, useState} from "react";
import {ServiceApi, URLS} from "services";

export default function Edit(props: any) {
  const updatePermission = usePermission("AS_UpdateUserGroup");
  const [loadingList, setLoadingList] = useState(true);
  const [data, setData] = useState<any>({});
  const [chooseRoleItems, setChooseRoleItems] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [params] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  });
  const formRef = useRef<any>();

  useEffect(() => {
    getRoleItems();
    if (props.isOpen) {
      setLoadingButton(false);
      getData();
    }
  }, [props.isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const getRoleItems = () => {
    const urlRoles = URLS.GET_ROLES_URL;
    const config = {params};
    ServiceApi.get(urlRoles, config)
      .then(({data}) => {
        setChooseRoleItems(data.items);
      })
      .finally(() => {
        setLoadingList(false);
      });
  };

  const getData = () => {
    setLoadingList(true);
    const url = URLS.GET_DETAILS_USER_GROUP_URL(props.selected);
    ServiceApi.get(url)
      .then(({data}) => {
        let result = JSON.parse(JSON.stringify(data));
        result.roles?.forEach((item: any, index: string) => {
          result.roles[index] = item.id;
        });
        setData(result);
      })
      .finally(() => {
        setLoadingList(false);
      });
  };

  const handleSetData = (key: any) => {
    return (value: any) => setData((p: any) => ({...p, [key]: value}));
  };

  const onSubmitForm = () => {
    setLoadingButton(true);
    const url = URLS.PUT_USER_GROUP_DETAIL_URL(data.id);
    data.roleIds = data.roles;
    ServiceApi.put(url, data)
      .then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
        props.toggle();
        props.updateList();
      })
      .catch(() => setLoadingButton(false));
  };

  return (
    <Drawer isOpen={props.isOpen} toggle={props.toggle}>
      <Form
        className="space-y-4 pr-4"
        onSubmit={onSubmitForm}
        id={"edit-modal"}
      >
        <Drawer.Menu className="pr-0">
          <Drawer.Header className="pr-4 pt-6">
            <div className="flex items-center justify-between">
              <h2 className="text-heading-2 text-gray-800">
                <Text>configurations.userManagment.userGroups.addGroup</Text>
              </h2>
              <div className="flex items-center justify-between">
                <Button
                  type="button"
                  variant="light"
                  size="sm"
                  className="ml-2"
                  onClick={() => props.toggle()}
                >
                  <Icon className="mr-2" icon={"times"} />
                  <Text>global.buttons.close</Text>
                </Button>
              </div>
            </div>
          </Drawer.Header>
          <Drawer.Body className="space-y-4 pr-4">
            {loadingList ? (
              <Fragment>
                <Skeleton.Input />
                <Skeleton.Input />
                <Skeleton.Input />
              </Fragment>
            ) : (
              <>
                <InputGroup
                  label="configurations.userManagment.userGroups.name"
                  value={data.name}
                  setValue={handleSetData("name")}
                  rules={rules.required}
                  disabled={!updatePermission}
                />
                <InputGroup
                  as="short-textarea"
                  label="configurations.userManagment.userGroups.description"
                  value={data.description}
                  setValue={handleSetData("description")}
                  rules={rules.required}
                  disabled={!updatePermission}
                />
                <MultiSelect
                  label="configurations.userManagment.userGroups.chooseRole"
                  items={chooseRoleItems}
                  value={data.roles}
                  setValue={value => {
                    return setData((p: any) => ({
                      ...p,
                      roles:
                        value.length !== 0
                          ? value?.map((val: any) => val.id)
                          : [],
                    }));
                  }}
                  rules={rules.required}
                />
              </>
            )}
          </Drawer.Body>
          {updatePermission && (
            <Drawer.Footer className="flex justify-between">
              <Button
                type="button"
                className="mr-2"
                onClick={() => props.toggle()}
                variant="light"
              >
                <Text>global.buttons.cancel</Text>
              </Button>
              <Button type="submit" loading={loadingButton}>
                <Text>global.buttons.submit</Text>
              </Button>
            </Drawer.Footer>
          )}
        </Drawer.Menu>
      </Form>
    </Drawer>
  );
}
