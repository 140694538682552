import IwofurnSalesPrice from "pages/price-engine/price-templates/iwofurn-sales-price";
import {IwofurnSalesTemplateDetails} from "pages/price-engine/price-templates/iwofurn-sales-price/details";
const IwofurnSalesPriceRoute = [
  {
    path: "",
    element: <IwofurnSalesPrice />,
  },
  {
    path: ":salesTemplateId",
    element: <IwofurnSalesTemplateDetails />,
  },
];

export default IwofurnSalesPriceRoute;
