export const sellOffProduct = {
  startProccess: "Prozess starten",
  changePhase: "Phasenwechsel",
  closeProccess: "Prozess beenden",
  deleteProduct: "Produkt löschen",
  articleNumber: "Artikelnummer",
  soldPrice: "Verkaufspreis",
  withdraw: "Zurückziehen",
  sold: "Verkauft",
  overview: "Übersicht",
  saleProccess: "Verkaufsprozess",
  gallery: "Galerie",
  addPhoto: "Foto hinzufügen",
  edit: "Bearbeiten",
  specification: "Spezifikation",
  description: "Beschreibung",
  subProducts: "Unterprodukte",
  addSubProduct: "Unterprodukt hinzufügen",
  currentPhase: "Aktuelle Phase",
  nextPhase: "Nächste Phase",
  lastDay: "Letzter Tag",
  days: "Tage",
  salesTimeline: "Verkaufstimeline",
  salesTemplate: "Verkaufsvorlage",
  submit: "Senden",
  price: "Preis",
  supplierPrice: "Lieferantenpreis",
  regularPrice: "Regulärer Preis",
  discount: "Rabatt",
  phasePrice: "Phasenpreis",
  salesStatus: "Verkaufsstatus",
  soldDate: "Verkaufsdatum",
  seller: "Verkäufer",
  sellerCommision: "Verkäuferprovision",
  soldBy: "Verkauft von",
  changePhaseproccess: "Phasenwechselprozess",
  checkList: "Checkliste",
  close: "Schließen",
  closePhase: "Phase schließen",
  cancel: "Abbrechen",
  areUSure: "Sind Sie sicher, dass Sie diese Phase schließen möchten?",
  afterClosing:
    "Nach dem Schließen der Phase können Sie sie nicht mehr aktivieren.",
  reorder: "Neu anordnen",
  editProduct: "Produkt bearbeiten",
  productTitle: "Produkttitel",
  subProductTitle: "Unterprodukt-Titel",
  productFamily: "Produktfamilie",
  brand: "Marke",
  suppliers: "Lieferanten",
  store: "Geschäft",
  location: "Standort",
  shortDescription: "Kurzbeschreibung",
  originalPrice: "Originalpreis",
  editSubProduct: "Unterprodukt bearbeiten",
  deleteSubProduct: "Unterprodukt löschen",
  count: "Anzahl",
  soldProduct: "Verkauftes Produkt",
  areUSureSold: "Sind Sie sich bei dieser Einreichung sicher?",
  contractDesc:
    "Wenn Sie eine Vertragsnummer haben, geben Sie bitte die Nummer im Feld ein. Andernfalls lassen Sie es einfach leer.",
  contractNumber: "Vertragsnummer",
  confirmSoldPrice: "Verkaufspreis bestätigen",
  startSelloffProccess: "Verkaufsprozess starten",
  preparation: "Vorbereitung",
  start: "Start",
  withdrawProduct: "Produkt zurückziehen",
  areUSureWithdraw:
    "Sind Sie sicher, dass Sie das verkaufte Produkt zurückziehen möchten?",
  filters: "Filter",
  resetFilters: "Filter zurücksetzen",
  status: "Status",
  startProccessDateRange: "Datum Bereich für Prozessstart",
  changePhaseDateRange: "Datum Bereich für Phasenwechsel",
  needPhaseChange: "Phasenwechsel erforderlich",
  needClosePhase: "Phasenabschluss erforderlich",
  hasProvision: "Provision vorhanden",
  active: "Aktiv",
  needClose: "Schließen erforderlich",
  closed: "Geschlossen",
  notSold: "Nicht verkauft",
  noAction: "Keine Aktion",
  createProduct: "Produkt erstellen",
  startPhase: "Phase starten",
  fastSaleCommission: "Schnellverkauf - Provision",
  comment: "Kommentar",
  program: "Programm",
  productFamily: "Produktfamile",
  attributes: "Attribute",
  productGroup: "Warengruppe",
  channelCode: "Channel Code",
  channelTitle: "Channel",
  productHasNoTemplate: "Dieses Produkt hat kein Template",
  WeCanNotCalculateAndStartThisProcess:
    "Sorry! Wir können das nicht kalkulieren und deshalb den Prozess nicht starten",
};
