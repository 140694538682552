import { NotificationManager } from "common/notifications";
import {
  Breadcrumb,
  Form,
  Icon,
  Skeleton,
  Tabs,
  Text,
  Wrapper,
} from "components";
import { withPermission } from "hoc";
import { useDataState } from "hooks";
import { ServiceApi, URLS } from "services";
import General from "./General";
import PriceCalculation from "./priceCalculation";
import { useParams } from "react-router";
import {
  Fragment,
  createContext,
  createElement,
  useEffect,
  useState,
} from "react";
import LEGAL_ENTITY from "services/urls/legal-entity";
import Templates from "./templates";

type dataType = {
  saleChannelId?: string;
  code?: string;
  title?: string;
  description?: string;
  ilnNumber?: string;
  businessChannels?: {
    crmChannel: boolean;
    erpChannel: boolean;
    onlineShopChannel: boolean;
  };
  legalEntity?: {
    legalEntityId: string;
    code: string;
  };
  legalEntityId?: string;
};

type LegalEntity = {
  id: string;
  code: string;
  name: string;
};

export const SalesChannelcontext = createContext({});

const Details = () => {
  const controller = new AbortController();
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [data, setData, setBaseData, isChanged, base] = useDataState<dataType>(
    {},
  );
  const { salesChannelId } = useParams();
  const [legalEntities, setLegalEntities] = useState<LegalEntity[]>([]);

  const tabs = [
    {
      label: "salesManagment.salesChannels.general",
      component: General,
    },
    {
      label: "priceEngine.calculationLevel.priceCalculationUnits",
      component: PriceCalculation,
    },
    {
      label: "salesManagment.salesChannels.templates",
      component: Templates,
    },
  ];

  const [tab, SetTab] = useState(tabs[0].label);

  const getChannelDetail = () => {
    const url = URLS.SALES_CHANNEL_DETAIL(salesChannelId);
    setLoading(true);
    ServiceApi.get(url, { signal: controller.signal })
      .then(async response => {
        if (response) {
          setBaseData(response.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getLegalEntities = () => {
    const url = LEGAL_ENTITY.GET_LEGALENTITY;
    const config = { params: { pageNumber: 1, pageSize: 50 } };
    ServiceApi.get(url, config).then(({ data }) => {
      const result = data.items.map((e: any) => ({
        name: e.name,
        id: e.code,
        code: e.code,
        legalId: e.id,
      }));
      setLegalEntities(result);
    });
  };

  useEffect(() => {
    getChannelDetail();
    getLegalEntities();
    return () => {
      controller.abort();
    };
  }, []);

  const onSubmit = () => {
    setSubmitLoading(true);
    const url = URLS.SALES_CHANNEL_DETAIL(data.saleChannelId);
    const selectedLegalEntity = legalEntities.filter(
      (i: LegalEntity) => i.id === data?.legalEntityId,
    )?.[0];
    const payLoad = selectedLegalEntity
      ? {
        ...data,
        legalEntity: {
          legalEntityId: selectedLegalEntity?.id,
          code: selectedLegalEntity.code,
        },
      }
      : { ...data };

    ServiceApi.put(url, payLoad)
      .then(async () => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
        getChannelDetail();
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  return (
    <Fragment>
      <Tabs
        activeKey={tabs[0].label}
        checkSave={isChanged}
        onSave={() => {
          if (isChanged) {
            setData(base);
          }
        }}
      >
        <Form className="relative space-y-6" onSubmit={onSubmit}>
          <Breadcrumb />
          <Wrapper>
            <Wrapper.Body className="flex flex-col items-start gap-5 lg:flex-row">
              {!loading && (
                <div className="space-y-4 lg:flex-1">
                  <h6 className="text-heading-6 text-gray-800">{data.title}</h6>
                  <p className="text-body-2 font-medium text-gray-500">
                    <Icon
                      className="mr-2 font-black text-gray-500"
                      icon="hashtag"
                    />
                    <span className="text-body-2 font-medium text-gray-500">
                      {data.code}
                    </span>
                  </p>
                </div>
              )}
              {loading && (
                <div className="space-y-4 lg:flex-1">
                  <Fragment>
                    <Skeleton.Input />
                    <Skeleton.Input />
                  </Fragment>
                </div>
              )}
            </Wrapper.Body>
            <Wrapper.Footer className="!py-0">
              <Tabs.ButtonGroup>
                {tabs.map(e => (
                  <Tabs.Button
                    key={e.label}
                    eventKey={e.label}
                    onClick={e => SetTab(e)}
                  >
                    <Text>{e.label}</Text>
                  </Tabs.Button>
                ))}
              </Tabs.ButtonGroup>
            </Wrapper.Footer>
          </Wrapper>
          {loading ? (
            <Wrapper>
              <Wrapper.Body className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <Skeleton.Input />
                <Skeleton.Input />
                <Skeleton.Input />
                <Skeleton.Input />
                <Skeleton.Input />
                <Skeleton.Input />
              </Wrapper.Body>
            </Wrapper>
          ) : (
            tabs.map(e => (
              <Tabs.Item key={e.label} eventKey={e.label}>
                <SalesChannelcontext.Provider
                  value={{
                    data: data,
                    setData: setData,
                    submitLoading,
                    isChanged,
                    legalEntities,
                  }}
                >
                  {createElement(e.component)}
                </SalesChannelcontext.Provider>
              </Tabs.Item>
            ))
          )}
        </Form>
      </Tabs>
    </Fragment>
  );
};

export default withPermission(Details, ["SC_GetSaleChannel"]);
