import {twMerge} from "tailwind-merge";
import {ClassName} from "types";
import {convertAddress} from "utils";
import Icon from "./Icon";
import {isEmpty, some, values} from "lodash";

type AddressViewerProps = {
  type?: "full" | "short";
  address?: object | null;
} & ClassName;

// type: full | short
export default function AddressViewer({
  type = "full",
  address = {},
  className,
}: AddressViewerProps) {
  if (values(address).every(isEmpty)) return null;
  return (
    <p className={twMerge("text-body-2  leading-5 text-gray-500", className)}>
      {address && (
        <>
          <Icon icon="location-dot" /> {convertAddress(address)}
        </>
      )}
    </p>
  );
}
