import { useEffect, useState } from "react";
import { Media } from "types";
import {
  Drawer,
  Text,
  Button,
  Icon,
  InputGroup,
  Wrapper,
  ImageUploader,
  Image,
  SelectLang,
  LazyImage,
} from "components";
import { useToggleState, useSelector } from "hooks";
import { ServiceApi, URLS } from "services";
import { NotificationManager } from "common/notifications";
import { assetTypes } from "constant";

type Props = {
  isOpen: boolean;
  toggle: any;
  channelInfo: any;
  id: string;
  selectedChanel: any;
  getChannelsInfo: any;
};

const ChannelDrawer = ({
  isOpen,
  toggle,
  channelInfo,
  id,
  selectedChanel,
  getChannelsInfo,
}: Props) => {
  const [lang, setLang] = useState<string>("de");
  const [imageKey, setImageKey] = useState<string>("");
  const [data, setData] = useState<any>({});
  const companySetting = useSelector(s => s.companySetting);
  const [imageOpen, setImageOpen] = useToggleState();
  const [submitLoading, setSubmitLoading] = useToggleState();
  const [langItems, setLangItems] = useState([]);
  const [action, setAction] = useState<string>("");

  useEffect(() => {
    setLang(companySetting.defaultContentLanguage);
  }, []);

  useEffect(() => {
    if (channelInfo) {
      setData(channelInfo);
      setAction("edit");
    } else {
      setData({
        channelCode: "",
        pictureThumbnailUrl: "",
        pictureUrl: "",
        translates: [
          { language: "de", labelTitle: "Laptop", description: "" },
          { language: "en", labelTitle: "Laptop", description: "" },
        ],
      });
      setAction("add");
    }
  }, [channelInfo, isOpen]);

  useEffect(() => {
    let arr: any = [];
    companySetting?.contentLanguages?.forEach((item: any) => {
      arr.push({ id: item, name: item });
    });
    setLangItems(arr);
  }, [companySetting]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeLanguage = (lang: any) => {
    setLang(lang);
  };

  const onUploadImage = (image: any) => {
    const channel = { ...data, [imageKey]: image.url };
    setData(channel);
  };

  const findCategoryByLang = () => {
    const obj = data?.translates?.filter(
      (item: any) => item.language.toLowerCase() === lang,
    )[0];
    return obj;
  };

  const onChangeHandler = (value: any, key: string) => {
    const isAvailable = data?.translates?.filter(
      (tr: any) => tr.language.toLowerCase() === lang,
    );
    let clone = [...data?.translates];
    if (isAvailable.length === 0) {
      clone.push({
        [key]: value,
        language: lang,
        description: "",
        labelTitle: "Laptop",
      });
    } else {
      data?.translates?.map((tr: any, index: number) => {
        if (tr.language.toLowerCase() === lang) {
          clone[index][key] = value;
        }
      });
    }
    setData({ ...data, translates: clone });
  };

  const submitHandler = () => {
    setSubmitLoading();
    const apiInfo: any = {
      add: {
        method: ServiceApi.post,
        url: URLS.ADD_CATEGORY_TO_CHANNEL(id),
        body: {
          channelCode: selectedChanel.code,
          pictureThumbnailUrl: data.thumbnailUrl,
          pictureUrl: data.pictureUrl,
          translates: data.translates,
        },
      },
      edit: {
        method: ServiceApi.put,
        url: URLS.EDIT_CATEGORY_TO_CHANNEL(id, data.id),
        body: {
          pictureThumbnailUrl: data.thumbnailUrl,
          pictureUrl: data.pictureUrl,
          translates: data.translates,
        },
      },
    };
    const info: any = apiInfo[action];
    // const url = URLS.ADD_CATEGORY_TO_CHANNEL(id);
    // const body = {
    //   channelCode: selectedChanel.code,
    //   pictureThumbnailUrl: data.thumbnailUrl,
    //   pictureUrl: data.pictureUrl,
    //   translates: data.translates,
    // };
    info
      .method(info.url, info.body)
      .then(() => {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title",
        );
        setSubmitLoading();
        getChannelsInfo();
        toggle();
      })
      .catch(() => setSubmitLoading());
  };
  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pr-4 pt-6">
          <div className="flex items-center justify-between">
            <span className="text-heading-2 font-semibold">
              <Text>productManagement.masterData.productCategory.channelSetting</Text>
            </span>
            <div className="flex gap-x-2">
              <Button size="sm" variant={"light"} onClick={toggle}>
                <Icon icon="times" className="mr-1" />
                <Text>global.buttons.close</Text>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body className="relative space-y-4 pr-4">
          <div className="!absolute right-4 top-[1.4rem]">
            <SelectLang
              value={lang}
              items={langItems}
              onChangeLanguage={onChangeLanguage}
            />
          </div>

          <InputGroup
            as={"short-textarea"}
            label="productManagement.masterData.productCategory.shortDescription"
            setValue={e => onChangeHandler(e, "description")}
            value={data?.translates && findCategoryByLang()?.description}
          />
          <Wrapper>
            <Wrapper.Header className="!pl-0 text-heading-5 font-semibold">
              <Text>productManagement.masterData.productCategory.images</Text>
            </Wrapper.Header>
            <Wrapper.Body className="flex space-x-6 !pl-0">
              <div className="space-y-4">
                <span className="text-heading-6 font-normal text-gray-800">
                  <Text>productManagement.masterData.productCategory.mainImage</Text>
                </span>
                <div
                  className="flex-center flex h-[220px] w-[176px] cursor-pointer rounded-xl bg-gray-200"
                  onClick={() => {
                    setImageOpen();
                    setImageKey("pictureUrl");
                  }}
                >
                  {data?.pictureUrl ? (
                    <LazyImage
                      isDynamic
                      src={data?.pictureUrl}
                      alt={""}
                      editor
                      imageClassName="h-full"
                      className="aspect-image h-[220px] w-[176px] cursor-pointer lg:aspect-auto"
                    />
                  ) : (
                    <div className="flex flex-col space-y-4">
                      <Icon
                        icon="file-arrow-up"
                        size="3x"
                        className="text-gray-500"
                      />
                      <span className="text-body-base font-medium text-gray-600">
                        <Text>productManagement.masterData.productCategory.uploadImage</Text>
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="space-y-4">
                <span className="text-heading-6 font-normal text-gray-800">
                  <Text>productManagement.masterData.productCategory.thumbnail</Text>
                </span>
                <div
                  className="flex-center flex h-[220px] w-[176px] cursor-pointer rounded-xl bg-gray-200"
                  onClick={() => {
                    setImageOpen();
                    setImageKey("pictureThumbnailUrl");
                  }}
                >
                  {data?.pictureThumbnailUrl ? (
                    <LazyImage
                      isDynamic
                      src={data?.pictureThumbnailUrl}
                      alt={""}
                      editor
                      imageClassName="h-full"
                      className="aspect-image h-[220px] w-[176px] cursor-pointer lg:aspect-auto"
                    />
                  ) : (
                    <div className="flex flex-col space-y-4">
                      <Icon
                        icon="file-arrow-up"
                        size="3x"
                        className="text-gray-500"
                      />
                      <span className="text-body-base font-medium text-gray-600">
                        <Text>productManagement.masterData.productCategory.uploadImage</Text>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </Wrapper.Body>
            <ImageUploader
              isOpen={imageOpen}
              toggle={setImageOpen}
              image={data?.[imageKey]}
              onUpload={(data: Media) => onUploadImage(data)}
              type={assetTypes.Company}
            />
          </Wrapper>
        </Drawer.Body>
        <Drawer.Footer className="flex justify-between">
          <Button size="sm" variant={"light"} onClick={toggle}>
            <Text>global.buttons.cancel</Text>
          </Button>
          <Button size="sm" onClick={submitHandler} loading={submitLoading}>
            <Text>global.buttons.submit</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
};

export default ChannelDrawer;
