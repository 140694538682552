import {
  Button,
  Drawer,
  Text,
  Icon,
  InputGroup,
  Toggler,
  DatePicker,
  Form,
  CheckBox,
} from "components";
import {rules} from "constant";
import {useState} from "react";
import {PriceEngin} from "types";
import {useToggleState} from "hooks";
import {ServiceApi, URLS} from "services";
import {NotificationManager} from "common/notifications";
type Props = {
  isOpen: boolean;
  toggle: () => void;
  getData: () => void;
};

export function CreatePurchaseTemplate({isOpen, toggle, getData}: Props) {
  const [loading, toggleLoading] = useToggleState();
  const [isChecked, toggleCheck] = useToggleState();
  const [data, setData] = useState<PriceEngin.CreatePurchasePriceTemplate>({
    title: "",
    code: "",
    description: "",
    isActive: true,
    validFromDate: null,
    validToDate: null,
  });

  const onSubmitHandler = () => {
    toggleLoading();
    const url = URLS.CREATE_PURCHASE_PRICE_TEMPLATES;
    const body = {
      ...data,
      validFromDate: isChecked ? data?.validFromDate : null,
      validToDate: isChecked ? data?.validToDate : null,
    };
    ServiceApi.post(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title",
        );
        toggleLoading();
        toggle();
        getData();
      })
      .catch(() => toggleLoading());
  };

  const onChangeHandler = (key: string) => {
    return (value: any) => setData((p: any) => ({...p, [key]: value}));
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Form onSubmit={onSubmitHandler}>
        <Drawer.Menu>
          <Drawer.Header className="flex items-center justify-between">
            <span className="text-heading-2 font-semibold">
              <Text>priceEngine.purchasePrice.addPurchaseTemplate</Text>
            </span>
            <Button variant={"light"} onClick={toggle} size="sm">
              <Icon icon="times" className="mr-2" />
              <Text>global.buttons.close</Text>
            </Button>
          </Drawer.Header>

          <Drawer.Body className="space-y-6">
            <InputGroup
              label={"priceEngine.purchasePrice.title"}
              value={data?.title}
              rules={rules.required}
              setValue={onChangeHandler("title")}
            />
            <InputGroup
              label={"priceEngine.purchasePrice.code"}
              value={data?.code}
              rules={rules.required}
              setValue={onChangeHandler("code")}
            />
            <InputGroup
              label={"priceEngine.purchasePrice.shortDescription"}
              value={data?.description}
              as={"short-textarea"}
              setValue={onChangeHandler("description")}
            />
            <CheckBox
              value={isChecked}
              setValue={toggleCheck}
              label="priceEngine.calculationLevel.addValidationPeriod"
            />
            <div className="grid grid-cols-2 gap-4">
              <DatePicker
                minDate={new Date()}
                maxDate={data?.validToDate ? data?.validToDate : null}
                placeholderText="priceEngine.purchasePrice.selectDate"
                label="priceEngine.purchasePrice.from"
                value={isChecked ? data?.validFromDate : null}
                onChange={onChangeHandler("validFromDate")}
                disabled={!isChecked}
              />
              <DatePicker
                minDate={data?.validFromDate ? data?.validFromDate : new Date()}
                placeholderText="priceEngine.purchasePrice.selectDate"
                label="priceEngine.purchasePrice.to"
                value={isChecked ? data?.validToDate : null}
                onChange={onChangeHandler("validToDate")}
                disabled={!isChecked}
              />
            </div>
            <Toggler
              className="!w-fit"
              label="priceEngine.calculationUnits.active"
              value={data?.isActive}
              setValue={onChangeHandler("active")}
            />
          </Drawer.Body>
          <Drawer.Footer className="flex justify-between">
            <Button variant={"light"} onClick={toggle} size="sm">
              <Text>global.cancel</Text>
            </Button>
            <Button type="submit" loading={loading} size="sm">
              <Text>priceEngine.purchasePrice.addTemplate</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Form>
    </Drawer>
  );
}
