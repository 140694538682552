import {Icon} from "components";
import {useMemo} from "react";

type percentPropsType = {
  value: number;
};
export default function Percent({value}: percentPropsType) {
  const percent = value ?? 0;
  const [className, icon] = useMemo<[string, string | null]>(() => {
    if (percent > 0) return ["text-success", "caret-up"];
    if (percent < 0) return ["text-danger", "caret-down"];
    return ["text-gray-700", null];
  }, [percent]);
  return (
    <span className={`text-body-3 ${className}`}>
      {percent?.toFixed(2)}%{icon && <Icon icon={icon} className="ms-1" />}
    </span>
  );
}
