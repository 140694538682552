export const measurementUnits = {
  addMeasurement: "Maßeinheit hinzufügen",
  general: "Allgemein",
  basicInfo: "Grundlegende Informationen",
  units: "Einheiten",
  name: "Name",
  description: "Beschreibung",
  code: "Code",
  symbol: "Symbol",
  addUnit: "Einheit hinzufügen",
  editUnit: "Einheit bearbeiten",
  unitsList: "Einheitenliste",
  default: "Standard",
  defaultUnit: "Standard-Einheits",
  defaultUnitCode: "Standard-Einheitscode",
  defaultUnitName: "Standard-Einheitsname",
  defaultUnitDescription: "Standard Einheit-Beschreibung",
  defaultUnitSymbol: "Standard-Einheitssymbol"
};