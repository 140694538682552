import {Status, Text} from "components";
import {SalesService} from "types";
import {classNames} from "utils";

type Props = {item: SalesService.SellOffBasicInfoDto};

const ActiveStatusTemplate = ({item}: Props) => {
  const phaseDayClassName = classNames("text-body-2 ", {
    "text-warning":
      item.currentPhaseDay === item.currentPhaseTotalDays && !item.isClosed,
    "text-success":
      item.currentPhaseDay !== item.currentPhaseTotalDays && !item.isClosed,
    "text-gray": item.isClosed,
  });
  const percentClassName = classNames(
    "h-full block rounded transition-[width] max-w-full",
    {
      "bg-warning":
        item.currentPhaseDay === item.currentPhaseTotalDays &&
        !item.closePhaseRequired &&
        !item.isClosed,
      "bg-success":
        item.currentPhaseDay !== item.currentPhaseTotalDays && !item.isClosed,
      "bg-danger": item.closePhaseRequired && !item.isClosed,
      "bg-gray-500": item.isClosed,
    },
  );
  let percent = 0;
  if (item.currentPhaseDay && item.currentPhaseTotalDays) {
    percent = (item.currentPhaseDay / item.currentPhaseTotalDays) * 100;
  }

  return (
    <div className="space-y-2 font-normal">
      {item.isClosed ? (
        <h6 className="text-heading-6">
          <Status.SellOff
            id={"salesManagment.sellOffSales.sellOffProduct.closed"}
          />
        </h6>
      ) : (
        <h6 className="text-heading-6">
          <Status.SellOff
            id={"salesManagment.sellOffSales.sellOffProduct.active"}
          />{" "}
          {item.changePhaseRequired && (
            <Status.SellOff
              id={"salesManagment.sellOffSales.sellOffProduct.needPhaseChange"}
            />
          )}
          {item.closePhaseRequired && (
            <Status.SellOff
              id={"salesManagment.sellOffSales.sellOffProduct.needClose"}
            />
          )}
        </h6>
      )}

      <p>{item.currentPhaseName}</p>
      <div className="flex flex-wrap items-center gap-1 lg:gap-x-2">
        {item.closePhaseRequired ? (
          <p className="text-danger">
            <Text>salesManagment.sellOffSales.sellOffProduct.lastDay</Text>
          </p>
        ) : (
          <p className={phaseDayClassName}>
            {"(" +
              item.currentPhaseDay +
              "/" +
              item.currentPhaseTotalDays +
              " "}
            <Text>salesManagment.sellOffSales.sellOffProduct.days</Text> {" )"}
          </p>
        )}
        <div className="block h-2 w-32 overflow-hidden rounded-full bg-gray-200">
          <span style={{width: `${percent}%`}} className={percentClassName} />
        </div>
      </div>
    </div>
  );
};

export default ActiveStatusTemplate;
