import {useContext, Fragment} from "react";
import {Text, Wrapper, Skeleton, AddressViewer} from "components";
import {CustomerContext} from "..";

export default function General() {
  const {userData, loading} = useContext<any>(CustomerContext);

  const handelDepartmentsLables = (departments: any) => {
    let label: any = [];
    departments?.forEach((department: any) => {
      label.push(department.name);
    });

    return label.length === 0 ? "" : label?.toString();
  };

  return (
    <Wrapper className="grid grid-cols-1 gap-8 p-8 lg:grid-cols-2">
      <Wrapper.Section className="space-y-4">
        <h3 className="mb-2 text-heading-3 font-semibold text-gray-600">
          <Text>company.employees.basicInfo</Text>
        </h3>
        {loading ? (
          <Fragment>
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
          </Fragment>
        ) : (
          <Fragment>
            <div className="grid grid-cols-2 gap-8">
              <span className="text-body-base font-medium text-gray-800">
                <Text>company.employees.displayName</Text>
              </span>
              <h5 className="text-heading-5 font-normal text-gray-700">
                {userData?.displayName}
              </h5>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <span className="text-body-base font-medium text-gray-800">
                <Text>company.employees.firstName</Text>
              </span>
              <h5 className="text-heading-5 font-normal text-gray-700">
                {userData?.firstName}
              </h5>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <span className="text-body-base font-medium text-gray-800">
                <Text>company.employees.lastName</Text>
              </span>
              <h5 className="text-heading-5 font-normal text-gray-700">
                {userData?.lastName}
              </h5>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <span className="text-body-base font-medium text-gray-800">
                <Text>company.employees.nickName</Text>
              </span>
              <h5 className="text-heading-5 font-normal text-gray-700">
                {userData?.nickname}
              </h5>
            </div>
          </Fragment>
        )}
      </Wrapper.Section>
      <Wrapper.Section className="space-y-4">
        <h3 className="mb-2 text-heading-3 font-semibold text-gray-600">
          <Text>company.employees.employmentInfo</Text>
        </h3>
        {loading ? (
          <Fragment>
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
          </Fragment>
        ) : (
          <>
            <div className="grid grid-cols-2 gap-8">
              <span className="text-body-base font-medium text-gray-800">
                <Text>company.employees.jobTitle</Text>
              </span>
              <h5 className="text-heading-5 font-normal text-gray-700">
                {userData?.jobTitle}
              </h5>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <span className="text-body-base font-medium text-gray-800">
                <Text>company.employees.store</Text>
              </span>
              <h5 className="text-heading-5 font-normal text-gray-700">
                {userData?.store?.title}
              </h5>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <span className="text-body-base font-medium text-gray-800">
                <Text>company.employees.departments</Text>
              </span>
              <h5 className="text-heading-5 font-normal text-gray-700">
                {handelDepartmentsLables(userData?.departments)}
              </h5>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <span className="text-body-base font-medium text-gray-800">
                <Text>company.employees.employeeNumber</Text>:
              </span>
              <h5 className="text-heading-5 font-normal text-gray-700">
                {userData?.employeeNumber}
              </h5>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <span className="text-body-base font-medium text-gray-800">
                <Text>company.employees.salesPersonNumber</Text>
              </span>
              <h5 className="text-heading-5 font-normal text-gray-700">
                {userData?.salesPersonNumber}
              </h5>
            </div>
          </>
        )}
      </Wrapper.Section>
      <Wrapper.Section className="space-y-4">
        <h3 className="mb-2 text-heading-3 font-semibold text-gray-600">
          <Text>company.employees.contactInfo</Text>
        </h3>
        {loading ? (
          <Fragment>
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
          </Fragment>
        ) : (
          <>
            <div className="grid grid-cols-2 items-center gap-8">
              <span className="text-body-base font-medium text-gray-800">
                <Text>company.employees.businessPhone</Text>
              </span>
              <div>
                <h5 className="text-heading-5 font-normal text-gray-700">
                  {userData?.businessPhone}
                </h5>
              </div>
            </div>
            <div className="grid grid-cols-2 items-center gap-8">
              <span className="text-body-base font-medium text-gray-800">
                <Text>company.employees.mobilePhone</Text>
              </span>
              <div>
                <h5 className="text-heading-5 font-normal text-gray-700">
                  {userData?.mobilePhone}
                </h5>
              </div>
            </div>
            <div className="grid grid-cols-2 items-center gap-8">
              <span className="text-body-base font-medium text-gray-800">
                <Text>company.employees.faxNumber</Text>
              </span>
              <div>
                <h5 className="text-heading-5 font-normal text-gray-700">
                  {userData?.faxNumber}
                </h5>
              </div>
            </div>
            <div className="grid grid-cols-2 items-center gap-8">
              <span className="text-body-base font-medium text-gray-800">
                <Text>company.employees.emailAddress</Text>
              </span>
              <div>
                <h5 className="text-heading-5 font-normal text-gray-700">
                  {userData?.emailAddress}
                </h5>
              </div>
            </div>
            <div className="grid grid-cols-2 items-center gap-8">
              <span className="text-body-base font-medium text-gray-800">
                <Text>company.employees.address</Text>
              </span>
              <div>
                <h5 className="text-heading-5 font-normal text-gray-700">
                  <AddressViewer address={userData?.address} />
                </h5>
              </div>
            </div>
          </>
        )}
      </Wrapper.Section>
    </Wrapper>
  );
}
