import {NotificationManager} from "common/notifications";
import {
  Breadcrumb,
  Button,
  DeleteModal,
  Footer,
  Form,
  Icon,
  InputGroup,
  Skeleton,
  Text,
  Wrapper,
  WithPermission,
} from "components";
import {rules} from "constant";
import {useDataState, useToggleState, usePermission} from "hooks";
import {isEmpty} from "lodash";
import {Fragment, useEffect, useState} from "react";
import {ServiceApi, URLS} from "services";
import {withPermission} from "hoc";

function Iwofurn() {
  const controller = new AbortController();
  const [loadingButton, setLoadingButton] = useState(false);
  const [data, setData, setBaseData, isChanged, base] = useDataState<any>({});
  const [loadingPage, setLoadingPage] = useState(true);
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState(false);

  const updatePermission = usePermission("PS_UpdateIwofurnConfiguration");

  useEffect(() => {
    getData();
    return () => {
      controller.abort();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onEditHandler = (active: boolean) => {
    setLoadingButton(true);
    const emptyData = isEmpty(base);
    const {iwofurnPassword, iwofurnUserName, iwofurnUrl} = data;

    let putData, url, axiosHandler;

    if (!emptyData) {
      putData = {
        iwofurnPassword,
        iwofurnUserName,
        iwofurnUrl,
        isActive: active,
      };
      url = URLS.PUT_IWOFURN_URL(data.iwofurnConfigurationId);
      axiosHandler = ServiceApi.put;
    } else {
      url = URLS.CREATE_GET_IWOFURN_URL;
      putData = {
        iwofurnPassword,
        iwofurnUserName,
        iwofurnUrl,
      };
      axiosHandler = ServiceApi.post;
    }

    axiosHandler(url, putData)
      .then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
      })
      .finally(() => {
        setLoadingButton(false);
        if (isOpenDeleteModal) {
          toggleDeleteModal();
        }
        getData();
      });
  };

  const getData = () => {
    setLoadingPage(true);
    ServiceApi.get(URLS.CREATE_GET_IWOFURN_URL, {signal: controller.signal})
      .then(({data}) => {
        setBaseData(data);
      })
      .catch(() => {
        setBaseData({});
      })
      .finally(() => {
        setLoadingPage(false);
      });
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <Breadcrumb />
        {updatePermission && (
          <Button
            size="sm"
            variant={"white"}
            textColor="danger"
            disabled={isEmpty(base)}
            onClick={e => {
              toggleDeleteModal();
            }}
          >
            <Icon icon="trash" className="mr-2" />
            <span className="mr-2">
              <Text>thirdParty.iwofurn.delete</Text>
            </span>
          </Button>
        )}
      </div>
      <Form onSubmit={() => onEditHandler(true)} className="relative space-y-6">
        <Wrapper>
          <Wrapper.Body className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-8">
            <div className="space-y-8">
              {loadingPage ? (
                <Fragment>
                  <Skeleton.Input />
                  <Skeleton.Input />
                  <Skeleton.Input />
                </Fragment>
              ) : (
                <Fragment>
                  <InputGroup
                    label="thirdParty.iwofurn.userName"
                    value={data.iwofurnUserName}
                    setValue={iwofurnUserName =>
                      setData((p: any) => ({...p, iwofurnUserName}))
                    }
                    rules={rules.required}
                    disabled={!updatePermission}
                  />
                  <InputGroup
                    label="thirdParty.iwofurn.password"
                    value={data.iwofurnPassword}
                    setValue={iwofurnPassword =>
                      setData((p: any) => ({...p, iwofurnPassword}))
                    }
                    rules={rules.required}
                    disabled={!updatePermission}
                  />
                  <InputGroup
                    label="thirdParty.iwofurn.url"
                    value={data.iwofurnUrl}
                    placeholder="https://www.hostname.com"
                    setValue={iwofurnUrl =>
                      setData((p: any) => ({...p, iwofurnUrl}))
                    }
                    rules={rules.iwofurn}
                    disabled={!updatePermission}
                  />
                </Fragment>
              )}
            </div>
          </Wrapper.Body>
        </Wrapper>

        {updatePermission && (
          <Footer show={!isChanged}>
            <Button type="button" onClick={getData} variant="white">
              <Text>global.buttons.discard</Text>
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={!isChanged}
              loading={loadingButton}
            >
              <Text>global.buttons.saveChanges</Text>
            </Button>
          </Footer>
        )}
      </Form>
      <DeleteModal
        isOpen={isOpenDeleteModal}
        toggle={toggleDeleteModal}
        selected={{name: data.iwofurnUserName}}
        loading={loadingButton}
        onConfirm={() => onEditHandler(false)}
      />
    </div>
  );
}

export default withPermission(Iwofurn, ["PS_GetIwofurnConfiguration"]);
