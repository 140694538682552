import {
  Breadcrumb,
  Button,
  Icon,
  Pagination,
  SearchBox,
  Skeleton,
  Status,
  Table,
  Text,
  NoItems,
  LazyImage,
} from "components";
import {useEffect, useState} from "react";
import {Link, useSearchParams} from "react-router-dom";
import {useConverter, useSelector, useToggleState} from "hooks";
import {ServiceApi, URLS} from "services";
import Filter from "./filter";

export default function PublishedProducts() {
  const [searchParams, setSearchParams] = useSearchParams();
  const channels = useSelector(s => s.channels);
  //initial
  const initialParams = {
    pageNumber: parseInt(searchParams.get("pageNumber") as string) || 1,
    pageSize: parseInt(searchParams.get("pageSize") as string) || 10,
    channelCode: null,
    keyword: null,
    searchFields: null,
    pimProductFamilyId: null,
    brandCode: null,
    pimProductCategoryCode: null,
    supplierId: null,
    groupId: null,
    programId: null,
    language: null,
    priceFrom: null,
    priceTo: null,
    salesToolsSortField: null,
    pimProductType: null,
  };

  const {convertAmount, convertDate} = useConverter();
  const [channelsList, setChannels] = useState<any>([]);
  const [isOpen, toggleFilter] = useToggleState();
  const [loading, toggle] = useToggleState();
  const [data, setData] = useState<any>([]);

  const [params, setParams] = useState<any>(initialParams);

  const isFilter = [
    "channelCode",
    "keyword",
    "searchFields",
    "pimProductFamilyId",
    "brandCode",
    "pimProductCategoryCode",
    "supplierId",
    "groupId",
    "programId",
    "language",
    "priceFrom",
    "priceTo",
    "salesToolsSortField",
    "pimProductType",
  ].some(item => params[item]);

  const handleSetFilterData = (key: string) => {
    const pageNumber =
      key === "pageNumber"
        ? parseInt(searchParams.get("pageNumber") as string)
        : 1;
    return (value: any) => {
      if (key === "pageNumber") {
        setSearchParams({
          pageNumber: value,
          pageSize: params.pageSize,
        });
      } else if (key === "pageSize") {
        setSearchParams({
          pageNumber: params.pageNumber,
          pageSize: value,
        });
      } else {
        setSearchParams({
          pageNumber: "1",
          pageSize: params.pageSize,
        });
      }
      setParams((p: any) => ({
        ...p,
        pageNumber: pageNumber,
        filter: true,
        [key]: value,
      }));
    };
  };

  const getChannels = () => {
    setChannels([{title: "All", code: null}, ...channels]);
  };

  useEffect(() => {
    getChannels();
  }, [channels]);

  useEffect(() => {
    // if (selectedChannel) {
    getData();
    // }
  }, [params]);

  const getData = () => {
    toggle();
    const url = URLS.GET_PUBLISHED_PRODUCT_LIST;
    const config = {...params};
    ServiceApi.post(url, config)
      .then(({data}) => {
        setData(data);
        toggle();
      })
      .catch(() => {
        toggle();
      });
  };

  const findChannelName = (channelCode: string) => {
    const channel: any = channelsList?.find(
      (channel: any) => channel.code === channelCode,
    );
    return channel ? channel.title : "";
  };

  const resetFilter = () => {
    setParams(initialParams);
  };

  return (
    <>
      <div className="relative space-y-4">
        <div className="flex items-center">
          <div className="flex-1">
            <h3 className="text-heading-3">
              <Breadcrumb />
            </h3>
          </div>
        </div>
        <div className="flex flex-wrap items-center gap-2">
          {!!channelsList?.length
            ? channelsList?.map((channel: any) => (
                <Button
                  size="sm"
                  variant={
                    params?.channelCode === channel.code ? "primary" : "white"
                  }
                  onClick={() =>
                    setParams((p: any) => ({
                      ...p,
                      filter: true,
                      pageNumber: 1,
                      channelCode: channel.code,
                    }))
                  }
                >
                  {channel?.title}
                </Button>
              ))
            : [...Array(8)].map(() => <Skeleton.ButtonWhite />)}
        </div>
        {!!channelsList?.length && (
          <div className="!mt-6 flex items-center justify-between">
            <div>
              <SearchBox
                value={params?.searchFields}
                onSubmit={handleSetFilterData("searchFields")}
                // disabled={loadingList}
                totalItems={data?.totalItems}
                // onFilter={() => setFilterModal()}
                // resetFilter={!params?.filter ? undefined : resetFilter}
                loading={loading}
                onReload={getData}
              />
            </div>
            <div className="flex items-center gap-2">
              {isFilter && (
                <Button
                  disabled={loading}
                  variant={"white"}
                  textColor="danger"
                  size="sm"
                  onClick={resetFilter}
                >
                  <Icon icon="circle-xmark"></Icon>
                  <span className="ml-1">Reset Filter</span>
                </Button>
              )}
              <Button
                disabled={loading}
                variant={"white"}
                size="sm"
                onClick={toggleFilter}
              >
                <span className="ml-1">Filters</span>
              </Button>
            </div>
          </div>
        )}
        {loading ? (
          [...Array(5)].map(() => <Skeleton.List />)
        ) : (
          <>
            {!!data?.searchResult?.length ? (
              <Table>
                {data?.searchResult?.map((item: any) => {
                  return (
                    <tr>
                      <td>
                        <Link
                          to={`${item.id}?channelCode=${item.channelCode}`}
                          className="flex w-fit gap-4"
                          // replace={!getDetailsPermission}
                        >
                          <div className="flex items-center">
                            <Table.Image
                              className="w-unset"
                              src={item.avatarUrl}
                              alt={item.avatarUrl}
                            />
                          </div>
                          <div className="ml-2 space-y-2 text-wrap py-2">
                            <h6 className="text-heading-6 font-semibold leading-6 text-gray-800">
                              {item.originalName}
                            </h6>
                            <Status.PimProductType id={item.pimProductType} />
                            <h6 className="text-heading-6  leading-6 text-gray-600">
                              Channel :{" "}
                              <span className="rounded bg-gray-100 px-2 py-1 text-body-3 text-primary">
                                {findChannelName(item.channelCode)}
                              </span>
                            </h6>
                            {item.brandName && (
                              <div className="flex items-center gap-2">
                                <h6 className="text-heading-6  leading-6 text-gray-800">
                                  {item.brandName}
                                </h6>
                                {item.brandLogo && (
                                  <LazyImage
                                    isDynamic
                                    className="h-[30px] w-[40px]"
                                    src={item.brandLogo}
                                    loadingSize="sm"
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </Link>
                      </td>
                      <td className="space-y-2 text-left text-body-2 font-medium text-gray-600">
                        {item.articleNumber && (
                          <div className="text-body-2 font-medium text-gray-500">
                            <p>Article Number#: {item.articleNumber}</p>
                          </div>
                        )}
                        {item.supplierArticleNumber && (
                          <div className="text-body-2 font-medium text-gray-500">
                            <p>
                              supplierArticleNumber#:{" "}
                              {item.supplierArticleNumber}
                            </p>
                          </div>
                        )}
                        {item.pimProductGroupName && (
                          <div className="text-body-2 font-medium text-gray-500">
                            <p>
                              pimProductGroupName: {item.pimProductGroupName}
                            </p>
                          </div>
                        )}
                        {item.lastPublishedDate && (
                          <div className="text-body-2 font-medium text-gray-500">
                            <p>
                              Last published date:{" "}
                              {convertDate(item.lastPublishedDate)}
                            </p>
                          </div>
                        )}
                      </td>
                      <td className="hidden space-y-4 xl:table-cell">
                        {item?.vk1Price?.amount !== item?.vk2Price?.amount && (
                          <h6 className="text-heading-6 font-normal text-gray-500 line-through">
                            {convertAmount(item?.vk2Price?.amount)}
                          </h6>
                        )}
                        <h6 className="text-heading-6 font-bold text-primary ">
                          {convertAmount(item?.vk1Price?.amount)}
                        </h6>
                      </td>
                      <td className="table-cell  gap-4 xl:table-cell">
                        <Button
                          as={Link}
                          to={`${item.id}?channelCode=${item.channelCode}`}
                          variant="light"
                          size="sm"
                        >
                          <Text>global.buttons.details</Text>
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </Table>
            ) : (
              <NoItems />
            )}
            <Pagination
              totalItems={data?.totalItems}
              totalPages={data?.totalPages}
              pageNumber={parseInt(params?.pageNumber) || 1}
              pageSize={params?.pageSize}
              setActivePage={handleSetFilterData("pageNumber")}
              onPageSizeChange={handleSetFilterData("pageSize")}
            />
          </>
        )}
      </div>
      <Filter
        isOpen={isOpen}
        toggle={toggleFilter}
        isFilter={isFilter}
        resetFilter={resetFilter}
        filterData={handleSetFilterData}
        params={params}
        data={data}
        loading={loading}
        channels={channelsList}
        setParams={setParams}
      />
    </>
  );
}
