import {useEffect, useState, useContext} from "react";
import {Button, Drawer, Icon, Text, DeleteModal, Skeleton} from "components";
import {ServiceApi, URLS} from "services";
import {Departments} from "types";
import {useToggleState} from "hooks";
import {NotificationManager} from "common/notifications";
import {AxiosResponse} from "axios";
import {CustomerContext} from "..";

interface DetailsDepartmentType {
  isOpen: boolean;
  toggle: () => void;
  departmentId?: string;
  getData: any;
}

export default function DetailsDepartment({
  isOpen,
  toggle,
  departmentId,
  getData,
}: DetailsDepartmentType) {
  const {userData} = useContext(CustomerContext);
  const [loading, setLoading] = useToggleState();
  const [deleteLoading, setDeleteLoading] = useToggleState();
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState();
  const [details, setDetails] = useState<Departments.DepartmentDetails>();
  const [teamLead, setTEamLead] = useState<Departments.EmployeeDetail>();

  const getDetails = () => {
    setLoading();
    const url = URLS.GET_DEPARTMENT_DETAILS_URL(departmentId);
    const params = {id: departmentId};
    const config = {params};
    ServiceApi.get(url, config)
      .then(({data}: any) => {
        setDetails(data);
        setLoading();
      })
      .catch(() => setLoading());
  };

  useEffect(() => {
    if (isOpen) {
      getDetails();
    } else {
      setTEamLead(undefined);
    }
  }, [isOpen]);

  const getTeamLeadInfo = () => {
    if (details) {
      const url = URLS.GET_EMPLOYEE_DETAILS(details.leadEmployeeId);
      ServiceApi.get(url).then(({data}: AxiosResponse) => {
        setTEamLead(data);
      });
    }
  };

  useEffect(getTeamLeadInfo, [details]);

  const onDeleteEmployee = () => {
    setDeleteLoading();
    const url = URLS.REMOVE_EMPLOYEE_FROM_DEPARTMENT(
      departmentId,
      userData?.employeeId,
    );
    ServiceApi.delete(url)
      .then(() => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title",
        );
        setDeleteLoading();
        toggleDeleteModal();
        toggle();
        getData();
      })
      .catch(() => setDeleteLoading());
  };

  return (
    <>
      <Drawer isOpen={isOpen} toggle={toggle} size={"lg"}>
        <Drawer.Menu>
          <Drawer.Header className="flex items-center justify-between">
            <span>
              <Text>company.stores.details</Text>
            </span>
            <div className="space-x-2">
              <Button
                size="sm"
                disabled={
                  loading || userData?.employeeId === details?.leadEmployeeId
                }
                variant={"danger"}
                light
                onClick={toggleDeleteModal}
              >
                <Icon icon="trash" className="mr-1" />{" "}
                <Text>Remove from department</Text>
              </Button>
              <Button size="sm" variant={"light"} onClick={toggle}>
                <Icon icon="close" className="mr-1" />
                <Text>global.buttons.close</Text>
              </Button>
            </div>
          </Drawer.Header>
          <Drawer.Body>
            <div className="space-y-6">
              {loading ? (
                [1, 2].map(() => <Skeleton.TextArea />)
              ) : (
                <>
                  <div className="space-y-6 rounded-lg border border-dashed border-gray-400 p-8">
                    <span className="text-heading-3 font-semibold text-gray-600">
                      <Text>company.stores.basicInfo</Text>
                    </span>
                    <div className="grid grid-cols-3 gap-4 *:text-gray-800">
                      <div className="col-span-1 flex flex-col gap-4">
                        <span>
                          <Text>company.stores.titel</Text>
                        </span>
                        <span>
                          <Text>company.stores.emailAddress</Text>
                        </span>
                        <span>
                          <Text>company.stores.shortDescription</Text>
                        </span>
                      </div>
                      <div className="col-span-2 flex flex-col gap-4">
                        <span>{details?.title}</span>
                        <span>{details?.emailAddress}</span>
                        <span className="text-wrap leading-5">
                          {details?.shortDescription}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="space-y-6 rounded-lg border border-dashed border-gray-400 p-8">
                    <span className="text-heading-3 font-semibold text-gray-600">
                      <Text>company.stores.leadInfo</Text>
                    </span>
                    {teamLead ? (
                      <div className="grid grid-cols-3 gap-4 *:text-gray-800 ">
                        <div className="col-span-1 flex flex-col gap-4">
                          <span>
                            <Text>company.stores.teamLead</Text>
                          </span>
                          <span>
                            <Text>company.stores.employeeNumber</Text>
                          </span>
                          <span>
                            <Text>company.stores.emailAddress</Text>
                          </span>
                        </div>
                        <div className="col-span-2 flex flex-col gap-4">
                          <span>
                            {teamLead?.firstName} {teamLead?.lastName}
                          </span>
                          <span className="text-wrap leading-5">
                            {teamLead?.employeeNumber}
                          </span>
                          <span>{teamLead?.emailAddress}</span>
                        </div>
                      </div>
                    ) : (
                      <Skeleton.TextArea />
                    )}
                  </div>
                </>
              )}
            </div>
          </Drawer.Body>
        </Drawer.Menu>
      </Drawer>
      <DeleteModal
        isOpen={isOpenDeleteModal}
        toggle={toggleDeleteModal}
        selected={{name: `${userData?.displayName} From ${details?.title}`}}
        loading={deleteLoading}
        onConfirm={onDeleteEmployee}
      />
    </>
  );
}
