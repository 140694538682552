import {variantType} from "types";
import activeBannerIcon from "../assets/icons/active banner position.svg";
import activeCarouselIcon from "../assets/icons/active carousel position.svg";
import bannerIcon from "../assets/icons/banner position.svg";
import carouselIcon from "../assets/icons/carousel position.svg";
import bannerThreeActiveIcon from "../assets/icons/info pages/banner C active.svg";
import bannerThreeIcon from "../assets/icons/info pages/banner C.svg";
import bannerOneActiveIcon from "../assets/icons/info pages/banner one active.svg";
import bannerOneIcon from "../assets/icons/info pages/banner one.svg";
import bannerTwoActiveIcon from "../assets/icons/info pages/banner two active.svg";
import bannerTwoIcon from "../assets/icons/info pages/banner two.svg";
import introductionActiveIcon from "../assets/icons/info pages/introduction active.svg";
import introductionIcon from "../assets/icons/info pages/introduction.svg";
import productsActiveIcon from "../assets/icons/info pages/products active.svg";
import productsIcon from "../assets/icons/info pages/products.svg";
import scrollBarActiveIcon from "../assets/icons/info pages/scrollbar active.svg";
import scrollBarIcon from "../assets/icons/info pages/scrollbar.svg";
import {useTranslate} from "hooks";

const position = [
  {
    name: "global.types.position.banner",
    id: 1,
    icon: bannerIcon,
    activeIcon: activeBannerIcon,
  },
  {
    name: "global.types.position.carousel",
    id: 0,
    icon: carouselIcon,
    activeIcon: activeCarouselIcon,
  },
];
const infoPageLayout = [
  {
    name: "global.types.infoPageLayout.bannerOne",
    id: 0,
    icon: bannerOneIcon,
    activeIcon: bannerOneActiveIcon,
  },
  {
    name: "global.types.infoPageLayout.bannerTwo",
    id: 1,
    icon: bannerTwoIcon,
    activeIcon: bannerTwoActiveIcon,
  },
  {
    name: "global.types.infoPageLayout.bannerThree",
    id: 5,
    icon: bannerThreeIcon,
    activeIcon: bannerThreeActiveIcon,
  },
  {
    name: "global.types.infoPageLayout.introduction",
    id: 2,
    icon: introductionIcon,
    activeIcon: introductionActiveIcon,
  },
  {
    name: "global.types.infoPageLayout.products",
    id: 3,
    icon: productsIcon,
    activeIcon: productsActiveIcon,
  },
  {
    name: "global.types.infoPageLayout.scrollbar",
    id: 4,
    icon: scrollBarIcon,
    activeIcon: scrollBarActiveIcon,
  },
];
const mobileAppLink = [
  {name: "global.types.mobileAppLink.none", id: 0},
  {name: "global.types.mobileAppLink.infoPage", id: 3},
  {name: "global.types.mobileAppLink.externalLink", id: 6},
  {name: "global.types.mobileAppLink.channelProduct", id: 9},
  {name: "global.types.mobileAppLink.button", id: 12},
];
const program = [
  {name: "global.types.program.none", id: 0, variant: "dark" as variantType},
  {
    name: "global.types.program.manual",
    id: 1,
    variant: "warning" as variantType,
  },
  {
    name: "global.types.program.configurable",
    id: 2,
    variant: "success" as variantType,
  },
];

// const amountCalculationType = [
//   {
//     name: "productManagement.products.Details.percent",
//     id: 0,
//   },
//   {
//     name: "productManagement.products.Details.amount",
//     id: 1,
//   },
// ];
const amountCalculationType = [
  {
    name: "%",
    id: 0,
  },
  {
    name: "€",
    id: 1,
  },
];

const checkListObjectType = [
  {
    name: "global.types.checkList.pimProduct",
    id: 0,
  },
  {
    name: "global.types.checkList.supplier",
    id: 1,
  },
  {
    name: "global.types.checkList.supplierProgram",
    id: 2,
  },
  {
    name: "global.types.checkList.order",
    id: 3,
  },
];

const types = {
  position,
  infoPageLayout,
  mobileAppLink,
  program,
  amountCalculationType,
  checkListObjectType,
};
export default types;
