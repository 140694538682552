import { NotificationManager } from "common/notifications";
import {
  Button,
  CardDraggable,
  DeleteModal,
  Dropdown,
  Icon,
  InputGroup,
  NoItems,
  Select,
  Skeleton,
  Status,
  Table,
  Text,
  WithPermission,
  Wrapper,
} from "components";
import { dateFormats } from "constant";
import { useConverter, usePermission, useToggleState, useTranslate } from "hooks";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ServiceApi, URLS } from "services";
import { SuppliersContext } from "..";

import cloneDeep from "lodash/cloneDeep";
import CreateModal from "./discount/create-modal";
import DetailsModal from "./discount/details-modal";
import CreateHolidayDrawer from "./holiday/create-drawer";
import DetailsHolidayDrawer from "./holiday/details-drawer";

export default function SaleSetting() {
  const translate = useTranslate();
  const { suppliersData, setSuppliersData, getData } =
    useContext<any>(SuppliersContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [titleItems, setTitleItems] = useState<any>([]);
  const [isOpenCreateModal, toggleCreateModal] = useToggleState(false);
  const [isOpenUpdateModal, toggleUpdateModal] = useToggleState(false);
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState(false);
  const [isOpenCreateHolidayDrawer, toggleCreateHolidayDrawer] =
    useToggleState(false);
  const [isOpenUpdateHolidayDrawer, toggleUpdateHolidayDrawer] =
    useToggleState(false);
  const [isOpenDeleteHolidayModal, toggleDeleteHolidayModal] =
    useToggleState(false);
  const { convertDate } = useConverter();
  const [selectedHoliday, setSelectedHoliday] = useState<any>();
  const [selected, setSelected] = useState<any>();
  const [totalItems, setTotalItems] = useState<any>(0);
  const [discounts, setDiscounts] = useState<any>([]);
  const [filter, setFilter] = useState<any>([]);
  const [holidays, setHolidays] = useState<any>([]);
  const [holidayList, setHolidayList] = useState<any>([]);
  const [selectedItem, setSelectedItem] = useState<any>("");
  const [filterItems, setFitreItems] = useState([]);
  const [items] = useState([
    {
      name: "WorkDay",
      id: 1,
    },
    {
      name: "CalendarDay",
      id: 2,
    },
  ]);

  useEffect(() => {
    setTotalItems(suppliersData?.discounts?.length);
    let list = suppliersData?.discounts
      ? suppliersData?.discounts?.sort((a: any, b: any) => a.order - b.order)
      : [];
    setDiscounts(list);
    setTitleItems([
      {
        id: "supplierDiscount",
        name: translate(
          "productManagement.masterData.suppliers.supplierDiscount",
        ),
      },
      {
        id: "programDiscount",
        name: translate(
          "productManagement.masterData.suppliers.programDiscount",
        ),
      },
      {
        id: "customText",
        name: translate("productManagement.masterData.suppliers.customText"),
      },
    ]);

    let date = new Date();
    let satrtDate = new Date(date.getFullYear() - 1, date.getMonth(), 0);
    let endDate = new Date();
    getHolidays({
      keyword: "",
      satrtDate: satrtDate,
      endDate: endDate,
      supplierId: suppliersData?.supplierId,
    });
  }, []);

  const updatePermission = usePermission("PS_UpdateSupplier");
  const detailsPermission = usePermission("PS_GetSupplier");

  // const getDiscounts = () => {
  //   setLoading(true);
  //   const url = URLS.GET_SUPPLIER_DETAILS(suppliersData?.supplierId);
  //   ServiceApi.get(url)
  //     .then(({data}) => {
  //       setTotalItems(data?.discounts?.length);
  //       let list = data?.discounts
  //         ? data?.discounts?.sort((a: any, b: any) => a.order - b.order)
  //         : [];
  //       setDiscounts(list);
  //       setTitleItems([
  //         {
  //           id: "supplierDiscount",
  //           name: translate(
  //             "productManagement.masterData.suppliers.supplierDiscount",
  //           ),
  //         },
  //         {
  //           id: "programDiscount",
  //           name: translate(
  //             "productManagement.masterData.suppliers.programDiscount",
  //           ),
  //         },
  //         {
  //           id: "customText",
  //           name: translate(
  //             "productManagement.masterData.suppliers.customText",
  //           ),
  //         },
  //       ]);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  const getHolidays = (params: any) => {
    setLoading(true);
    const url = URLS.GET_HOLIDAYS_SUPPLIERS;
    const config = { params };
    ServiceApi.get(url, config)
      .then(({ data }) => {
        let newItems: any = [{ id: "", name: translate("global.all") }];
        let years: any = [];
        data?.forEach((item: any) => {
          years.push(
            new Date(item.startDate).getFullYear(),
            new Date(item.endDate).getFullYear(),
            new Date(item.lastOrderDate).getFullYear(),
            new Date(item.earliestShipDate).getFullYear(),
          );
        });
        let yerasList = removeDuplicates(years);
        yerasList?.forEach((year: any) => {
          newItems.push({ id: year, name: year });
        });
        setHolidays(data);
        setHolidayList(data);
        setFitreItems(newItems);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function removeDuplicates(arr: any) {
    let unique = arr.reduce(function (acc: any, curr: any) {
      if (!acc.includes(curr)) acc.push(curr);
      return acc;
    }, []);
    return unique;
  }

  const handleSetValue = (key: any) => {
    return (value: any) =>
      setSuppliersData((p: any) => {
        const data = cloneDeep(p);
        if (data.shipping !== null) {
          data.shipping[key] = value;
        } else {
          data.shipping = {
            minimumShippingDuration: null,
            durationUnit: null,
          };
          data.shipping[key] = value;
        }
        return data;
      });
  };

  const onHandel = (e: any) => {
    // let discounts: any = [];
    // e.forEach((i: any) => {
    //   return suppliersData?.discounts?.map((item: any) => {
    //     if (item.id === i.id) {
    //       item.order = i.order;
    //       discounts.push(item)
    //     }
    //   })
    // })
    // if (discounts.length !== 0) {
    //   setSuppliersData((p: any) => {
    //     return {
    //       ...p,
    //       discounts: discounts
    //     }
    //   });
    // }
  };

  const submitDelete = (id: any) => {
    setLoadingButton(true);
    const url = URLS.DELETE_DISCOUNT_SUPPLIERS(suppliersData.supplierId, id);
    ServiceApi.delete(url)
      .then(({ }) => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title",
        );
        // getDiscounts();
        toggleDeleteModal();
        setLoadingButton(false);
      })
      .catch(() => setLoadingButton(false));
  };

  const submitHolidaysDelete = (id: any) => {
    setLoadingButton(true);
    const url = URLS.DELETE_HOLIDAYS_SUPPLIERS(selectedHoliday.holidayId);
    ServiceApi.delete(url)
      .then(({ }) => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title",
        );
        getHolidays({
          supplierId: suppliersData?.supplierId,
        });
        toggleDeleteHolidayModal();
        setLoadingButton(false);
      })
      .catch(() => setLoadingButton(false));
  };

  const selectedShippingDayType = (selected: any) => {
    return items?.find((item: any) => item.id === selected)?.name || items[0].name;
  };

  const onChangeHandler = (e: any) => {
    setSuppliersData((p: any) => {
      const data = cloneDeep(p);
      if (data.shipping !== null) {
        data.shipping.durationUnit = e;
      } else {
        data.shipping = { minimumShippingDuration: null, durationUnit: null };
        data.shipping.durationUnit = e;
      }
      return data;
    });
  };

  const onFilterYear = (filter: any) => {
    if (filter) {
      let newItems: any = [];
      holidays?.forEach((item: any) => {
        let years = [
          new Date(item.startDate).getFullYear(),
          new Date(item.endDate).getFullYear(),
          new Date(item.lastOrderDate).getFullYear(),
          new Date(item.earliestShipDate).getFullYear(),
        ];
        if (years.includes(parseInt(filter))) {
          newItems.push(item);
        }
      });
      setHolidayList(newItems);
      setFilter(filter);
    } else {
      setHolidayList(holidays);
      setFilter("");
    }
  };

  return (
    <div className="space-y-4">
      <Wrapper>
        {/* <Wrapper.Header>
          <div className="flex">
            <h2 className="text-gray-800">
              <Text>productManagement.masterData.suppliers.discounts</Text>
            </h2>
            <span className="ml-4 mt-1 text-body-base text-gray-700">
              <Text>
                productManagement.masterData.suppliers.dragChangeOrder
              </Text>
            </span>
          </div>
        </Wrapper.Header> */}
        {/* <Wrapper.Body>
          {loading ? (
            <>
              <Skeleton.List />
              <Skeleton.List />
              <Skeleton.List />
              <Skeleton.List />
            </>
          ) : (
            <>
              <WithPermission permissions={["PS_UpdateSupplier"]}>
                <Wrapper.Section className="courser-pointer mb-4 h-[52px] w-full cursor-pointer border-primary bg-primary-light py-[17px] text-center">
                  <div
                    className="w-full"
                    onClick={() => {
                      toggleCreateModal();
                    }}
                  >
                    <h5 className="text-primary">
                      <Icon
                        className="mr-2 text-primary"
                        icon={"square-plus"}
                      />
                      <Text>
                        productManagement.masterData.suppliers.adddiscount
                      </Text>
                    </h5>
                  </div>
                </Wrapper.Section>
              </WithPermission>
              {discounts?.length !== 0 ? (
                <CardDraggable setItems={(arr: any) => onHandel(arr)}>
                  {discounts?.map((item: any) => {
                    return (
                      <div
                        id={item.id}
                        key={item.id}
                        className="grid w-full grid-cols-6 gap-8 px-4 py-[18px]"
                      >
                        <button
                          type="button"
                          onClick={() => {
                            if (!updatePermission) return;
                            setSelected(item);
                            toggleUpdateModal();
                          }}
                          className="col-span-2 mt-2"
                        >
                          <h5 className="text-gray-800">{item.title}</h5>
                        </button>
                        <div className="col-span-2 mt-2 flex">
                          <span className="mr-2 text-body-base text-gray-700">
                            <Text>
                              productManagement.masterData.suppliers.amount
                            </Text>
                          </span>
                          <h5 className="text-gray-800">{item.amount}%</h5>
                        </div>
                        <div>
                          <Status.Supplier id={item.isActive} />
                        </div>
                        <div className="flex items-center justify-end">
                          <WithPermission permissions={["PS_UpdateSupplier"]}>
                            <Button
                              type="button"
                              size="sm"
                              variant="danger"
                              className="mr-2"
                              light
                              onClick={() => {
                                setSelected(item);
                                toggleDeleteModal();
                              }}
                            >
                              <Icon icon={"trash"} />
                            </Button>
                            <Button
                              type="button"
                              onClick={() => {
                                setSelected(item);
                                toggleUpdateModal();
                              }}
                              variant="light"
                              size="sm"
                            >
                              <Text>global.buttons.edit</Text>
                            </Button>
                          </WithPermission>
                        </div>
                      </div>
                    );
                  })}
                </CardDraggable>
              ) : (
                <div className="flex w-full flex-col items-center space-y-4">
                  <NoItems />
                </div>
              )}
            </>
          )}
        </Wrapper.Body> */}
        <DetailsModal
          titleItems={titleItems}
          selected={selected}
          isOpen={isOpenUpdateModal}
          toggle={toggleUpdateModal}
          // getData={getDiscounts}
          supplierId={suppliersData.supplierId}
        />
        <CreateModal
          titleItems={titleItems}
          totalItems={totalItems}
          isOpen={isOpenCreateModal}
          toggle={toggleCreateModal}
          // getData={getDiscounts}
          supplierId={suppliersData.supplierId}
        />
        <DeleteModal
          isOpen={isOpenDeleteModal}
          toggle={toggleDeleteModal}
          selected={{ name: selected?.title, id: selected?.id }}
          loading={loadingButton}
          onConfirm={submitDelete}
        />
      </Wrapper>
      <WithPermission permissions={["PS_GetSupplier"]}>
        <Wrapper>
          <Wrapper.Header>
            <div className="flex items-center justify-between">
              <h2 className="text-gray-800">
                <Text>productManagement.masterData.suppliers.holidays</Text>
              </h2>
              <Select
                className="!w-[92px]"
                placeholder={`${translate("global.select")}...`}
                size="sm"
                setValue={value => {
                  onFilterYear(value);
                }}
                items={filterItems}
                value={filter}
              />
            </div>
          </Wrapper.Header>
          <Wrapper.Body>
            {loading ? (
              <>
                <Skeleton.List />
                <Skeleton.List />
                <Skeleton.List />
                <Skeleton.List />
              </>
            ) : (
              <>
                <WithPermission permissions={["PS_CreateSupplier"]}>
                  <Wrapper.Section className="courser-pointer mb-4 h-[52px] w-full cursor-pointer border-primary bg-primary-light py-[17px] text-center">
                    <div
                      className="w-full"
                      onClick={() => {
                        toggleCreateHolidayDrawer();
                      }}
                    >
                      <h5 className="text-primary">
                        <Icon
                          className="mr-2 text-primary"
                          icon={"square-plus"}
                        />
                        <Text>
                          productManagement.masterData.suppliers.addHoliday
                        </Text>
                      </h5>
                    </div>
                  </Wrapper.Section>
                </WithPermission>
                {holidays?.length !== 0 ? (
                  <Table>
                    {holidayList?.map((item: any) => {
                      return (
                        <tr
                          key={item.holidayId}
                          style={{
                            boxShadow: "0px 4px 20px rgba(56, 71, 109, 0.09)",
                          }}
                        >
                          <td>
                            <Link
                              to=""
                              onClick={() => {
                                if (!detailsPermission) return;
                                setSelectedHoliday(item);
                                toggleUpdateHolidayDrawer();
                              }}
                              className="col-span-2 mt-2"
                            >
                              <h5 className="text-gray-800">{item.title}</h5>
                            </Link>
                          </td>
                          <td className="space-y-2">
                            <div className="grid grid-cols-2 gap-1">
                              <div className="text-right">
                                <span className="text-body-base font-normal text-gray-700">
                                  <Text>
                                    productManagement.masterData.suppliers.start
                                  </Text>
                                </span>
                              </div>
                              <div>
                                <h6 className="text-heading-6 font-semibold text-gray-800">
                                  {convertDate(
                                    item.startDate,
                                    dateFormats.onlyDate,
                                  )}
                                </h6>
                              </div>
                            </div>
                            <div className="grid grid-cols-2 gap-1">
                              <div className="text-right">
                                <span className="text-body-base font-normal text-gray-700">
                                  <Text>
                                    productManagement.masterData.suppliers.end
                                  </Text>
                                </span>
                              </div>
                              <div>
                                <h6 className="text-heading-6 font-semibold text-gray-800">
                                  {convertDate(
                                    item.endDate,
                                    dateFormats.onlyDate,
                                  )}
                                </h6>
                              </div>
                            </div>
                          </td>
                          <td className="space-y-2">
                            <div className="grid grid-cols-2 gap-1">
                              <div className="text-right">
                                <span className="text-body-base font-normal text-gray-700">
                                  <Text>
                                    productManagement.masterData.suppliers.lastOrder
                                  </Text>
                                </span>
                              </div>
                              <div>
                                <h6 className="text-heading-6 font-semibold text-gray-800">
                                  {convertDate(
                                    item.lastOrderDate,
                                    dateFormats.onlyDate,
                                  )}
                                </h6>
                              </div>
                            </div>
                            <div className="grid grid-cols-2 gap-1">
                              <div className="text-right">
                                <span className="text-body-base font-normal text-gray-700">
                                  <Text>
                                    productManagement.masterData.suppliers.earliestShip
                                  </Text>
                                </span>
                              </div>
                              <div>
                                <h6 className="text-heading-6 font-semibold text-gray-800">
                                  {convertDate(
                                    item.earliestShipDate,
                                    dateFormats.onlyDate,
                                  )}
                                </h6>
                              </div>
                            </div>
                          </td>

                          <td className="col-end-7 text-end">
                            <WithPermission permissions={["PS_UpdateSupplier"]}>
                              <Button
                                type="button"
                                size="sm"
                                variant="danger"
                                className="mr-2"
                                light
                                onClick={() => {
                                  setSelectedHoliday(item);
                                  toggleDeleteHolidayModal();
                                }}
                              >
                                <Icon icon={"trash"} />
                              </Button>
                            </WithPermission>
                            <WithPermission permissions={["PS_GetSupplier"]}>
                              <Button
                                type="button"
                                onClick={() => {
                                  setSelectedHoliday(item);
                                  toggleUpdateHolidayDrawer();
                                }}
                                variant="light"
                                size="sm"
                              >
                                <Text>global.buttons.edit</Text>
                              </Button>
                            </WithPermission>
                          </td>
                        </tr>
                      );
                    })}
                  </Table>
                ) : (
                  <div className="flex w-full flex-col items-center space-y-4">
                    <NoItems />
                  </div>
                )}
              </>
            )}
          </Wrapper.Body>

          <DetailsHolidayDrawer
            selected={selectedHoliday}
            isOpen={isOpenUpdateHolidayDrawer}
            toggle={toggleUpdateHolidayDrawer}
            getData={getHolidays}
            supplierId={suppliersData.supplierId}
          />
          <CreateHolidayDrawer
            isOpen={isOpenCreateHolidayDrawer}
            toggle={toggleCreateHolidayDrawer}
            getData={getHolidays}
            supplierId={suppliersData.supplierId}
          />
          <DeleteModal
            isOpen={isOpenDeleteHolidayModal}
            toggle={toggleDeleteHolidayModal}
            selected={{
              name: selectedHoliday?.title,
              id: selectedHoliday?.holidayId,
            }}
            loading={loadingButton}
            onConfirm={submitHolidaysDelete}
          />
        </Wrapper>
      </WithPermission>
      <Wrapper>
        <Wrapper.Header>
          <h2 className="text-heading-2 font-semibold text-gray-800">
            {" "}
            <Text>productManagement.masterData.suppliers.shipping</Text>
          </h2>
        </Wrapper.Header>
        <Wrapper.Body className="grid grid-cols-2 gap-4">
          <div>
            <div className="mb-2 flex items-center justify-between space-x-4">
              <h6 className="h6 block truncate font-normal text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']">
                <Text>
                  productManagement.masterData.suppliers.defaultMinimumShippingTime
                </Text>
              </h6>
            </div>
            <div className="mt-4" style={{ display: "flex" }}>
              <InputGroup
                type="number"
                value={suppliersData?.shipping?.minimumShippingDuration}
                setValue={handleSetValue("minimumShippingDuration")}
                className="rounded-r-none"
              />
              <Dropdown>
                <Dropdown.Toggle
                  as="button"
                  type="button"
                  className="flex h-full items-center rounded-md rounded-l-none bg-primary-light p-3 align-middle text-body-base font-medium text-primary"
                >
                  {selectedShippingDayType(
                    suppliersData?.shipping?.durationUnit,
                  )}
                </Dropdown.Toggle>
                <Dropdown.Menu className="mt-2 min-w-fit p-2">
                  <ul className="w-[104px] text-gray-700">
                    {items?.map((e: any) => (
                      <li
                        key={e.id}
                        onClick={() => {
                          setSelectedItem(e.id);
                          onChangeHandler(e.id);
                        }}
                        className={`${e.id === selectedItem &&
                          "bg-primary-light text-primary "
                          } h6 cursor-pointer rounded-lg px-4 py-2  text-body-base font-medium first:mb-1 hover:bg-primary-light hover:text-primary`}
                      >
                        {e?.name}
                      </li>
                    ))}
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div>
            <div className="mb-2 flex items-center justify-between space-x-4">
              <h6 className="h6 block truncate font-normal text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']">
                <Text>
                  productManagement.masterData.suppliers.minimumResponseTime
                </Text>
              </h6>
            </div>
            <InputGroup
              type="number"
              value={suppliersData?.shipping?.minimumResponseTime}
              setValue={handleSetValue("minimumResponseTime")}
              className="rounded-r-none mt-4"
              unit="global.days"
            />
          </div>
        </Wrapper.Body>
      </Wrapper>
    </div>
  );
}
