import {Accordion, Button, HandleName, Icon, Text} from "components";
import {useContext, useState} from "react";
import {SellOffProductDetailsContext} from ".";

export default function Attribute() {
  const {data} = useContext(SellOffProductDetailsContext);
  const [groupFilter, setGroupfilter] = useState<string>("all");

  return (
    <>
      <div className="flex items-center gap-2">
        <Button
          size="sm"
          variant={groupFilter === "all" ? "primary" : "white"}
          className="!rounded-full"
          onClick={() => setGroupfilter("all")}
        >
          <Text>productManagement.products.Details.allInGroupFilter</Text>
        </Button>

        {data?.pimProduct?.attributeGroups?.map((group: any) => (
          <Button
            size="sm"
            className="!rounded-full"
            variant={groupFilter === group.code ? "primary" : "white"}
            onClick={() => setGroupfilter(group.code)}
          >
            {
              <HandleName
                translations={group?.translates}
                code={group?.code}
                keyName="labelTitle"
              />
            }
          </Button>
        ))}
      </div>
      <div className="space-y-6">
        {data?.pimProduct?.attributeGroups?.map((attribute: any) => {
          return (
            <>
              {(groupFilter === "all" || groupFilter === attribute?.code) && (
                <Accordion key={"about-product"}>
                  <Accordion.Item
                    active
                    className={`mb-4 rounded-2xl border bg-white px-4 shadow-nested`}
                  >
                    <Accordion.Toggle className="flex items-center justify-between">
                      <div className="flex items-center gap-[10px]">
                        <div>
                          <Icon
                            icon={"chevron-down"}
                            size="sm"
                            className="transition-transform group-[.accordion-item.active]:rotate-180"
                          />
                        </div>
                        <div>
                          <h3 className="text-gray-600">
                            <HandleName
                              translations={attribute?.translates}
                              keyName="labelTitle"
                            />
                          </h3>
                        </div>
                      </div>
                    </Accordion.Toggle>
                    <>
                      <Accordion.Body className={`px-4`}>
                        <div className={`space-y-2`}>
                          {attribute?.attributes?.map((attr: any) => {
                            return (
                              <>
                                <div className="border-b border-gray-200 py-4 last:border-none">
                                  <div className=" grid grid-cols-3 gap-4">
                                    <span className="col-span-1 text-body-base font-medium text-gray-800">
                                      <HandleName
                                        translations={attr?.translates}
                                        keyName="labelTitle"
                                      />
                                    </span>
                                    <span className="text-heading-5 text-gray-700">
                                      {attr?.pimProductAttribute
                                        ?.pimProductAttributeValue?.length ? (
                                        <HandleName
                                          translations={
                                            attr?.pimProductAttribute
                                              ?.pimProductAttributeValue || []
                                          }
                                          code={attr?.code}
                                          keyName="value"
                                        />
                                      ) : (
                                        "------------"
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </Accordion.Body>
                    </>
                  </Accordion.Item>
                </Accordion>
              )}
            </>
          );
        })}
      </div>
    </>
  );
}
