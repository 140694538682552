import { useContext } from "react";
import PriceCalculationTab from "compositions/price-calculation-units/price-calculation-tab";
import { calculationLevelEnum } from "constant";
import { ProfileContext } from ".";

export default function PriceCalculation() {
  const { profileData } = useContext<any>(ProfileContext);

  return (
    <PriceCalculationTab
      refId={profileData?.companyId}
      level={calculationLevelEnum.tenant}
      refTitle={profileData?.name}
      refCode={''}
    />
  );
}
