const USERS = {
  B2B_USER_URL: "/accountservice/api/users/me",
  GET_B2B_USERS_URL: "accountservice/api/users",
  ADD_B2B_USERS_URL: "accountservice/api/users",
  B2B_USER_DETAIL_URL: id => `/accountservice/api/users/${id}`,
  UPDATE_B2B_USERS_URL: id => `/accountservice/api/users/${id}`,
  UPDATE_B2B_USER: "accountservice/api/users/user-profile",
  UPDATE_PROVIDERS_USER_URL: id => `/accountservice/api/users/${id}/provider`,
  UPDATE_SET_DEFAULT_FILTER_USER_URL: id =>
    `/accountservice/api/users/${id}/set-default-filter`,
  UPLOAD_USER_IMAGES: id => `/accountservice/api/users/${id}/avatar`,
  SEARCH_AD_USERS: keyword =>
    `/accountservice/api/users/search-sso-user?keyword=${keyword}`,
  GET_INVITE_AD_USERS: id =>
    `accountservice/api/users/user-ad-detail?userAdObjectId=${id}`,
  POST_INVITE_USER: "/accountservice/api/users/invite",
  POST_INVITE_ADMIN_USER: "/accountservice/api/users/invite-admin",
  PUT_EMPLOYEE_USER: id => `accountservice/api/users/${id}/employee`,

  SET_STORES_USER: id => `accountservice/api/users/${id}/stores`,

  B2B_ADMIN_URL: "/accountservice/api/users/b2b-admin",
  EDIT_B2B_ADMIN_URL: id => `/accountservice/api/users/${id}/b2b-admin`,
  SET_DEFAULT_FILTER_USER_URL: id =>
    `accountservice/api/users/${id}/set-default-filter`,
  EDIT_B2B_USER_URL: "/accountservice/api/users/",
  //  ADD_IMAGE_TO_USER_URL : '/accountservice/api/users/image',
  ADD_IMAGE_TO_USER_URL: "/accountservice/api/users",
  UPDATE_MY_PROFILE_URL: "/accountservice/api/users/user-profile",
  GET_MY_PROFILE_URL: "/accountservice/api/users/b2b-user-profile-detail",
  ADD_IMAGE_TO_MY_PROFILE_URL: "/accountservice/api/users/image-profile",

  B2B_ADMIN_UPDATE_URL: "/accountservice/api/users/",
  GET_MY_PROFILE_B2B_ADMIN_URL: "/accountservice/api/users/b2b-user-profile",
  GET_AD_USER_DETAIL: "/accountservice/api/users/user-ad-detail",

  SET_USER_SALESCHANNELS: id => `accountservice/api/users/${id}/sale-channels`,
};

export default USERS;
