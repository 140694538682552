import PurchasePrice from "./purchase-price";
import SalesPrice from "./sales-price";
import IwofurnSalesPriceRoute from "./iwofurn-sale-price";
import CatalogSalesPriceRoute from "./catalog-sale-price";

const PriceTemplates = [
  {
    path: "purchase-price",
    children: [...PurchasePrice],
  },
  {
    path: "sales-price",
    children: [...SalesPrice],
  },
  {
    path: "iwofurn-sales-price",
    children: [...IwofurnSalesPriceRoute],
  },
  {
    path: "catalog-sales-price",
    children: [...CatalogSalesPriceRoute],
  },
];

export default PriceTemplates;
