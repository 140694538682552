import {NotificationManager} from "common/notifications";
import {
  Button,
  DeleteModal,
  HandleName,
  Icon,
  InputGroup,
  NoItems,
  Skeleton,
  Status,
  Table,
  Text,
  WithPermission,
  Wrapper,
} from "components";
import {config} from "constant";
import {useToggleState, usePermission} from "hooks";
import {Fragment, useContext, useEffect, useState} from "react";
import AsyncSelect from "react-select/async";
import {ServiceApi, URLS} from "services";
import {AttributesTypes, onFilter} from "utils";
import {AttributesContext} from "..";

type AttributesItems = {
  attributeTemplateId: string;
  title: string;
  code: string;
  type: string;
  perChannel: any;
  perLanguage: any;
  isRequired: any;
};

export default function Attributes() {
  const updatePermission = usePermission("PS_UpdateAttributeGroupTemplate");
  const {attributesGroupData, attributeGroupTemplateId} =
    useContext<any>(AttributesContext);
  const [attributes, setAttributes] = useState<AttributesItems[]>();
  const [attributesList, setAttributesList] = useState<AttributesItems[]>();
  const [loadingList, setLoadingList] = useState(false);
  const [selected, setSelected] = useState("");
  const [selectedOptions, setSelectedOptions] = useState<any>();
  const [selectedName, setSelectedName] = useState<string>();
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [params] = useState({
    pageNumber: 1,
    pageSize: 1000,
  });

  useEffect(() => {
    getDataAttributes();
  }, [attributesGroupData]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDataAttributes = () => {
    setSelectedOptions(null);
    const url = URLS.GET_ADDED_ATTRIBUTES(attributeGroupTemplateId);
    const config = {params};
    setLoadingList(true);
    let newData: any;
    ServiceApi.get(url)
      .then(({data}) => {
        newData = JSON.parse(JSON.stringify(data));
        newData?.forEach((item: any) => {
          item?.translations?.forEach((i: any, index: number) => {
            if (index === 0) {
              item["title"] = i.displayName;
            }
          });
        });
        setAttributes(newData);
        setAttributesList(newData);
      })
      .finally(() => {
        setLoadingList(false);
      });
  };

  const handleSearchAttributes = async (filter: string) => {
    const urlSearch = URLS.GET_AVAILABLE_ATTRIBUTES;
    let newDataSearch: any;
    let arrSearch: any = [];
    return ServiceApi.get(urlSearch).then(({data}) => {
      newDataSearch = data;
      newDataSearch?.forEach((item: any) => {
        item?.translates?.forEach((i: any, index: number) => {
          if (index === 0) {
            arrSearch.push({
              value: item.attributeTemplateId,
              label: i.labelTitle,
            });
          }
        });
      });
      return arrSearch;
    });
  };

  const promiseOptionsAttributes = (inputValue: string) =>
    new Promise<any[]>(resolve => {
      setTimeout(() => {
        resolve(handleSearchAttributes(inputValue));
      }, 200);
    });

  const submitDelete = (id: any) => {
    setLoadingButton(true);
    const url = URLS.DELETE_ATTRIBUTE_GROUP_ATTRIBUTES_URL(
      attributeGroupTemplateId,
    );
    ServiceApi.patch(url, {attributeTemplateId: id})
      .then(() => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title",
        );
        setLoadingButton(false);
        getDataAttributes();
        toggleDeleteModal();
      })
      .catch(() => setLoadingButton(false));
  };

  const onSearchHandler = (e: string) => {
    let keys = ["code", "translates"];
    if (e !== "") {
      setAttributesList(onFilter(attributes, keys, e));
    } else {
      setAttributesList(attributes);
    }
    setSearchValue(e);
  };

  return (
    <Fragment>
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          <WithPermission permissions={["PS_UpdateAttributeGroupTemplate"]}>
            <Wrapper.Section className="flex h-[77px] w-full gap-4 border-primary bg-primary-light px-4 py-4">
              <div className="w-[443px]">
                <AsyncSelect
                  value={selectedOptions}
                  cacheOptions
                  placeholder={
                    <Text>
                      productManagement.masterData.attributesGroups.selectAttribute
                    </Text>
                  }
                  loadOptions={promiseOptionsAttributes}
                  defaultOptions
                  styles={config.styleSelectReact}
                  onChange={(e: any) => {
                    setSelectedOptions(e);
                  }}
                  isClearable
                />
              </div>
              <div>
                <Button
                  type="button"
                  variant="primary"
                  loading={loadingList}
                  disabled={selectedOptions === null ? true : false}
                  onClick={() => {
                    setLoadingList(true);
                    const url = URLS.ADD_ATTRIBUTE_GROUP_ATTRIBUTES_URL(
                      attributeGroupTemplateId,
                    );
                    ServiceApi.patch(url, {
                      attributeTemplateId: selectedOptions?.value,
                    })
                      .then(({data}) => {
                        NotificationManager.success(
                          "global.toast.add-msg",
                          "global.toast.add-title",
                        );
                        getDataAttributes();
                      })
                      .finally(() => {
                        setLoadingList(false);
                        setSelectedOptions(null);
                      });
                  }}
                >
                  <Text>
                    productManagement.masterData.attributesGroups.addToGroup
                  </Text>
                </Button>
              </div>
            </Wrapper.Section>
          </WithPermission>

          {attributes?.length !== 0 && (
            <div className="grid grid-cols-5 items-center gap-4">
              <InputGroup
                value={searchValue}
                setValue={onSearchHandler}
                placeholder="productManagement.masterData.attributesGroups.searchAttribute"
                disabled={loadingList}
              />
              <p className="text-body-2">
                {searchValue.length === 0
                  ? attributesList?.length
                  : attributesList?.length}{" "}
                <Text>global.items</Text>
              </p>
            </div>
          )}
          {attributesList?.length !== 0 ? (
            <Table>
              {attributesList?.map((item: any) => {
                return (
                  <tr key={Math.random()}>
                    <td className="w-[26rem]">
                      <div className="flex-center flex w-fit gap-2">
                        <div className="space-y-4">
                          <div>
                            <h6 className="text-heading-6 text-gray-800">
                              {
                                <HandleName
                                  translations={item?.translates}
                                  code={item?.code}
                                  keyName="labelTitle"
                                />
                              }
                            </h6>
                          </div>
                          <div>
                            <span className="pr-2 text-body-2 text-gray-500">
                              {item.code}
                            </span>
                            <span className="border-l-2 border-gray-300 px-2 text-body-2  text-gray-500">
                              {AttributesTypes(item.type)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="space-x-4">
                      <Status.RequiredAttribute id={item?.isRequired} />
                      <Status.PerChannelAttribute id={item?.perChannel} />
                      <Status.PerLanguageAttribute id={item?.perLanguage} />
                    </td>
                    {updatePermission && (
                      <td>
                        <Button
                          type="button"
                          size="sm"
                          variant="danger"
                          light
                          onClick={() => {
                            setSelected(item.attributeTemplateId);
                            setSelectedName(item.title);
                            toggleDeleteModal();
                          }}
                        >
                          <Icon icon={"trash"} />
                        </Button>
                      </td>
                    )}
                  </tr>
                );
              })}
            </Table>
          ) : (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
            </div>
          )}
        </>
      )}
      <DeleteModal
        isOpen={isOpenDeleteModal}
        toggle={toggleDeleteModal}
        selected={{id: selected, name: selectedName}}
        loading={loadingButton}
        onConfirm={submitDelete}
      />
    </Fragment>
  );
}
