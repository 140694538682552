import {NotificationManager} from "common/notifications";
import {Button, Drawer, Icon, LoadingSvg, Status, Text} from "components";
import {flagCountry} from "constant";
import {PrintRequestStatus} from "enum";
import {useConverter, useToggleState} from "hooks";
import {useSearchParams} from "react-router-dom";
import {ServiceApi, URLS} from "services";
import {PrintHistoryItem} from "types/pim-product";
import {convertDate} from "utils";

type PropsType = {
  isOpen: boolean;
  toggle: () => void;
  item: PrintHistoryItem | undefined;
  getData: () => void;
};

export default function PrintHistoryDetail({
  isOpen,
  toggle,
  item,
  getData,
}: PropsType) {
  const [loading, toggleLoading] = useToggleState();
  const [searchParams] = useSearchParams();
  const {convertAmount} = useConverter();

  const handleDeleteItem = () => {
    toggleLoading();
    const url = URLS.DELETE_PRINT_REQUEST(item?.pimProductId, item?.id);
    const params = {channelCode: searchParams.get("channelCode")};
    ServiceApi.delete(url, {params})
      .then(() => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title",
        );
        toggle();
        getData();
      })
      .finally(() => toggleLoading());
  };
  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header className="flex items-center justify-between text-heading-2 font-semibold">
          <Text>productManagement.publishedProduct.Details.printDetails</Text>
          <div className="flex gap-2">
            {item?.status !== PrintRequestStatus.OnProcess && (
              <Button
                type="button"
                variant="danger"
                light
                size="sm"
                onClick={handleDeleteItem}
              >
                {loading ? (
                  <LoadingSvg size="sm" />
                ) : (
                  <Icon icon={"trash"} className="" />
                )}
              </Button>
            )}
            <Button
              variant={"light"}
              size="sm"
              className="flex items-center"
              onClick={toggle}
            >
              <span>
                <Text>global.buttons.close</Text>
              </span>
              <Icon icon="close" className="ml-2" />
            </Button>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          <div className="rounded-xl border border-dashed border-gray-600 p-8">
            <table>
              <tr>
                <td className="w-1/3 py-4 pr-9 text-body-base font-medium text-gray-800">
                  <Text>productManagement.publishedProduct.Details.title</Text>
                </td>
                <td className="text-heading-5 text-gray-700">{item?.title}</td>
              </tr>
              <tr>
                <td className="w-1/3 py-4 pr-9 text-body-base font-medium text-gray-800">
                  <Text>
                    productManagement.publishedProduct.Details.requestDate
                  </Text>
                </td>
                <td className="text-heading-5 text-gray-700">
                  {convertDate(item?.createdAt)}
                </td>
              </tr>
              <tr>
                <td className="w-1/3 py-4 pr-9 text-body-base font-medium text-gray-800">
                  VK 2
                </td>
                <td className="text-heading-5 text-gray-700">
                  {convertAmount(item?.pimProductVk2Price.amount)}
                </td>
              </tr>
              <tr>
                <td className="w-1/3 py-4 pr-9 text-body-base font-medium text-gray-800">
                  VK 1
                </td>
                <td className="text-heading-5 text-gray-700">
                  {convertAmount(item?.pimProductVk1Price.amount)}
                </td>
              </tr>
              <tr>
                <td className="w-1/3 py-4 pr-9 text-body-base font-medium text-gray-800">
                  <Text>
                    productManagement.publishedProduct.Details.requestDate
                  </Text>
                </td>
                <td className="text-heading-5 text-gray-700">
                  <div className="flex items-center gap-2 text-heading-6 font-semibold text-gray-500">
                    {item?.language && (
                      <img src={flagCountry[item?.language]} />
                    )}
                    {item?.language.toUpperCase()}
                  </div>
                </td>
              </tr>
              <tr>
                <td className="w-1/3 py-4 pr-9 text-body-base font-medium text-gray-800">
                  <Text>productManagement.publishedProduct.Details.status</Text>
                </td>
                {!!item && (
                  <td className="text-heading-5 text-gray-700">
                    <Status.PrintRequestStatus id={item?.status} />
                  </td>
                )}
              </tr>
              {item?.status === PrintRequestStatus.Faild && (
                <tr>
                  <td className="w-1/3 py-3 text-body-base font-medium text-gray-800">
                    <Text>
                      productManagement.publishedProduct.Details.error
                    </Text>
                  </td>
                  <td className="text-heading-5 text-gray-700">
                    {" "}
                    {item?.statusDescription}
                  </td>
                </tr>
              )}
            </table>
          </div>
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
}
