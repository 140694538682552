import { useContext } from "react";
import {
  Wrapper,
  Text,
  InputGroup,
  RadioButton,
  Skeleton,
  CheckBox,
  Icon,
} from "components";
import { unitTypes, hasManualUnitTypes, unitOperationType } from "constant";
import { CalculationUnitContext, ContextTypes } from "..";
import { usePermission } from "hooks";
import { NotificationManager } from "common/notifications";

export default function General() {
  const { data, loading, setData } = useContext<ContextTypes>(
    CalculationUnitContext,
  );
  const updatePermission = usePermission("PE_ModifyCalculationUnit");
  return (
    <>
      <Wrapper>
        <Wrapper.Header>
          <Text>priceEngine.calculationUnits.basicInfo</Text>
        </Wrapper.Header>
        <Wrapper.Body className="grid grid-cols-2 gap-4">
          <div className="space-y-8">
            {loading ? (
              [1, 2].map(() => <Skeleton.Input />)
            ) : (
              <>
                <InputGroup
                  label={"priceEngine.calculationUnits.title"}
                  value={data?.title}
                  setValue={e => setData((p: any) => ({ ...p, title: e }))}
                  disabled={!updatePermission}
                />
                <InputGroup
                  label={"priceEngine.calculationUnits.code"}
                  value={data?.code}
                  disabled
                  append={
                    <span
                      className="input-group-text"
                      onClick={() => {
                        window.navigator.clipboard.writeText(data?.code);
                        NotificationManager.success(
                          "global.toast.copy-msg",
                          "global.toast.copy-title",
                        );
                      }}
                    >
                      <Icon
                        className="ml-6 cursor-pointer text-primary"
                        icon={"files"}
                      />
                    </span>
                  }
                />
              </>
            )}
          </div>
          {loading ? (
            <Skeleton.TextArea />
          ) : (
            <InputGroup
              label={"priceEngine.calculationUnits.shortDescription"}
              value={data?.description}
              as={"textarea"}
              setValue={e => setData((p: any) => ({ ...p, description: e }))}
              disabled={!updatePermission}
            />
          )}
        </Wrapper.Body>
      </Wrapper>
      <Wrapper>
        <Wrapper.Header>
          <Text>priceEngine.calculationUnits.typeAndValue</Text>
        </Wrapper.Header>
        <Wrapper.Body>
          {loading ? (
            <div className="animate-pulse space-y-4">
              <div className="col-span-1 h-2 rounded bg-slate-200"></div>
              <div className="col-span-2 h-2 rounded bg-slate-200"></div>
              <div className="col-span-1 h-2 rounded bg-slate-200"></div>
              <div className="col-span-2 h-2 rounded bg-slate-200"></div>
              <div className="col-span-1 h-2 rounded bg-slate-200"></div>
            </div>
          ) : (
            <div className="space-y-8">
              <div className="mb-8">
                <CheckBox
                  value={data?.hasManualValue}
                  label="has Manual Value"
                  setValue={e =>
                    setData((p: any) => ({ ...p, hasManualValue: e }))
                  }
                />
              </div>
              <span>
                <Text>priceEngine.calculationUnits.calculationUnitTypes</Text>
              </span>
              <>
                {data?.hasManualValue ? (
                  <div className="flex gap-2 w-full md:w-1/2">
                    {hasManualUnitTypes.map(unit => (
                      <RadioButton
                        label={unit.label}
                        value={data?.valueType === unit.value}
                        setValue={e =>
                          setData((p: any) => ({ ...p, valueType: unit.value, minValue: 0, maxValue: 0 }))
                        }
                        disabled={!updatePermission}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="flex gap-7 w-full flex-wrap">
                    {unitTypes.map(unit => (
                      <RadioButton
                        label={unit.label}
                        value={data?.valueType === unit.value}
                        setValue={e =>
                          setData((p: any) => ({ ...p, valueType: unit.value, itemValue: 0 }))
                        }
                        disabled={!updatePermission}
                        parentClassName="!w-auto"
                      />
                    ))}
                  </div>
                )}
              </>
              {[0, 1].includes(data?.valueType as number) && (
                <div className="space-y-4">
                  <span>+ / -</span>
                  <div className="flex gap-2 w-full md:w-1/2">
                    {unitOperationType.map(type => (
                      <RadioButton
                        label={type.label}
                        value={data?.operationType === type.value}
                        setValue={e =>
                          setData((p: any) => ({
                            ...p,
                            operationType: type.value,
                          }))
                        }
                        disabled={!updatePermission}
                      />
                    ))}
                  </div>
                </div>
              )}
              {data?.valueType !== 4 && !data?.hasManualValue && (
                <InputGroup
                  label={"priceEngine.calculationUnits.defaultValue"}
                  value={data?.itemValue}
                  setValue={(e) => {
                    if (data?.valueType !== 0 || (e >= 0 && e <= 100))
                      setData((p: any) => ({ ...p, itemValue: e }))
                  }
                  }
                  type="number"
                  disabled={!updatePermission}
                  className="w-full md:w-1/2"
                />
              )}
              {data?.hasManualValue && (
                <div className="flex gap-4">
                  <InputGroup
                    label={"priceEngine.calculationUnits.minValue"}
                    value={data?.minValue}
                    setValue={(e) => {
                      if (data?.valueType !== 0 || (e >= 0 && e <= 100))
                        setData((p: any) => ({ ...p, minValue: e }))
                    }
                    }
                    type="number"
                    append={data?.valueType === 0 ? "%" : "€"}
                  />
                  <InputGroup
                    label={"priceEngine.calculationUnits.maxValue"}
                    value={data?.maxValue}
                    setValue={(e) => {
                      if (data?.valueType !== 0 || (e >= 0 && e <= 100))
                        setData((p: any) => ({ ...p, maxValue: e }))
                    }
                    }
                    type="number"
                    append={data?.valueType === 0 ? "%" : "€"}
                  />
                </div>
              )}
            </div>
          )}
        </Wrapper.Body>
      </Wrapper >
    </>
  );
}
