import {NotificationManager} from "common/notifications";
import {
  Button,
  CustomizedFileUploader,
  Dropdown,
  FileUploader,
  Form,
  Icon,
  InputGroup,
  Text,
} from "components";
import {assetTypes, rules} from "constant";
import {useDataState, useToggleState} from "hooks";
import {ServiceApi, URLS} from "services";
import {twMerge} from "tailwind-merge";
import {CompletenessContext} from "../..";
import {useContext, useEffect, useRef, useState} from "react";
import {getFileProperties} from "utils";
import {ItemType, CheckListItem} from "types/conversation";
import {UserItem} from "types/user";
import {Mention, MentionsInput} from "react-mentions";

type Props = {
  type: "add" | "reply";
  updateConversations: () => void;
  itemToReply?: ItemType;
  removeItemToReply?: () => void;
};

type File = {
  url: string;
  type: string;
  thumbnailUrl: string;
  name: string;
};

export default function AddOrReply({
  updateConversations,
  type,
  itemToReply,
  removeItemToReply,
}: Props) {
  const [isOpen, toggle] = useToggleState(type === "reply");
  const [isOpenCheckList, toggleCheckList] = useToggleState(false);
  const [submitLoading, toggleSubmitLoading] = useToggleState();
  const [selectedCheckList, setSelectedCheckList] = useState<CheckListItem>();
  const {checkList, completionStateId, productId} =
    useContext(CompletenessContext);
  const [file, setFile] = useState<any>();
  const [attachments, setAttachments] = useState<File[]>([]);
  const initialData = {
    title: "",
    note: "",
    replyOfId: null,
    completionStateId: completionStateId,
    checkListId: "",
    assignedUsers: [],
    attachments: [],
  };
  const [data, setData] = useDataState(initialData);
  const descriptonRef = useRef<HTMLInputElement>(null);
  const [assignedUsers, setAssignedUsers] = useState<string[]>([]);
  const [usersToMention, setUsersToMention] =
    useState<{id: string; display: string}[]>();
  const [allUesrs, setAllUsers] = useState<UserItem[]>();

  const getUsers = () => {
    const params = {
      pageNumber: 1,
      pageSize: 1000,
      keyword: "",
    };
    const url = URLS.GET_B2B_USERS_URL;
    const config = {params};
    ServiceApi.get(url, config).then(({data}) => {
      setAllUsers(data.items);
      const list: {id: string; display: string}[] = [];
      data?.items?.map((user: UserItem) => {
        list.push({
          id: user.userId,
          display: `${user.firstName} ${user.lastName}`,
        });
      });
      setUsersToMention(list);
    });
  };

  const getAssignedUserList = () => {
    return allUesrs?.filter(
      user =>
        assignedUsers.includes(user.userId) &&
        data.note.includes(`@${user.firstName} ${user.lastName}`),
    );
  };

  const OnSubmitHandler = () => {
    toggleSubmitLoading();
    const url = URLS.PIM_CONVERSATIONS_URL;
    const payLoad = {
      ...data,
      objectId: productId,
      checkListId:
        type === "reply"
          ? itemToReply?.checkListId
          : selectedCheckList?.checkListId,
      attachments,
      replyOfId: type === "reply" ? itemToReply?.id : null,
      assignedUsers: getAssignedUserList(),
    };
    ServiceApi.post(url, payLoad)
      .then(res => {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title",
        );
        updateConversations();
      })
      .finally(() => {
        resetForm();
        toggle();
        toggleSubmitLoading();
      });
  };

  const resetForm = () => {
    setData(initialData);
    setSelectedCheckList(undefined);
    setAttachments([]);
    removeItemToReply?.();
  };

  const handleSetFile = (file: any) => {
    setFile(null);
    setAttachments([
      ...attachments,
      {
        type: file?.type,
        url: file?.url,
        thumbnailUrl: file?.thumbnailUrl,
        name: file?.metaData[1].value,
      } as File,
    ]);
  };

  const handleRemoveAttachment = (file: File) => {
    const filteredList = attachments.filter(i => i.url !== file.url);
    setAttachments(filteredList);
  };

  useEffect(getUsers, []);

  const handleInsertAtSymbol = () => {
    const inputElement = descriptonRef.current;

    if (inputElement) {
      const cursorPosition = inputElement.selectionStart || 0;

      const newValue =
        data?.note?.slice(0, cursorPosition) +
        "@" +
        data?.note?.slice(cursorPosition);

      setData(p => ({...p, note: newValue}));

      const newCursorPosition = cursorPosition + 1;

      setTimeout(() => {
        inputElement.setSelectionRange(newCursorPosition, newCursorPosition);
        inputElement.focus();

        const event = new KeyboardEvent("keydown", {
          key: "@",
          code: "Key2",
          keyCode: 50, // Key code for "@"
          bubbles: true,
        });
        inputElement.dispatchEvent(event);
      }, 0);
    }
  };

  return (
    <Form
      onSubmit={OnSubmitHandler}
      className="fixed bottom-0 left-0 right-0 z-10 w-full bg-white px-8  shadow-[0px_4px_20px_0px_#3F42541A]"
    >
      <div
        className="flex cursor-pointer items-center justify-between"
        onClick={() => {
          toggle();
          removeItemToReply?.();
        }}
      >
        <p className="py-8 text-heading-2 font-semibold text-primary">
          <Icon icon="plus" size="sm" className="mr-2" />
          {type === "add" ? (
            <Text>global.addNew</Text>
          ) : (
            <Text>productManagement.products.completeness.replyTo</Text>
          )}
        </p>
        <span className="font-black text-gray-600">
          <Icon
            icon="chevron-up"
            className={twMerge(
              "transition-transform duration-1000 [&.active]:rotate-180",
              isOpen && "active",
            )}
          />
        </span>
      </div>
      <div
        className={twMerge(
          "transition-[max-height 0.25s ease-in] pointer-events-none inset-0 z-[90] !m-0 max-h-0 space-y-6 bg-white duration-1000 [&.active]:pointer-events-auto [&.active]:max-h-fit",
          isOpen && "active",
        )}
      >
        {type === "reply" && (
          <div className="space-y-3 rounded-md bg-gray-200 px-2 py-4">
            <p className="text-body-2 text-gray-700">
              {itemToReply?.createdUser.fullName}
            </p>
            <p className="text-heading-6 font-semibold text-gray-800">
              {itemToReply?.title}
            </p>
            <p className="truncate text-body-2 text-gray-700">
              {itemToReply?.note}
            </p>
          </div>
        )}
        {type === "add" && (
          <InputGroup
            label="productManagement.products.completeness.subject"
            value={data.title}
            className="border-white"
            setValue={title => setData(p => ({...p, title}))}
          />
        )}
        <InputGroup
          as="short-textarea"
          label="productManagement.products.completeness.description"
          placeholder="productManagement.products.completeness.descriptionPlaceHolder"
          value={data.note}
          className="border-white [&_.form-control]:!min-h-36 [&_textarea]:!min-h-36"
          setValue={note => setData(p => ({...p, note}))}
          snippet
          trigger="@"
          suggestionData={usersToMention}
          rules={rules.required}
          onSelectMention={id => setAssignedUsers([...assignedUsers, id])}
          ref={descriptonRef}
        />
        <div className="flex flex-wrap gap-3">
          <Button
            variant="primary"
            className="!z-0"
            light
            type="button"
            onClick={handleInsertAtSymbol}
            // onClick={() => {
            //   // setData(p => ({...p, note: p.note + "@"}));
            //   descriptonRef?.current?.focus();
            // }}
          >
            @
          </Button>
          <CustomizedFileUploader
            value={file?.url ? file : null}
            setValue={setFile}
            getResponse={handleSetFile}
            type={assetTypes.PimProduct}
          >
            <i className="bi bi-paperclip rotate-45 text-xl text-primary"></i>
          </CustomizedFileUploader>
          {!selectedCheckList?.checkListId && type === "add" && (
            <Button
              variant={"primary"}
              light
              onClick={toggleCheckList}
              type="button"
            >
              <Icon icon="list" />
              <Text>productManagement.products.completeness.assignToItem</Text>
            </Button>
          )}
        </div>
        {isOpenCheckList && !!checkList && (
          <div className="max-h-64 divide-y-2 overflow-scroll rounded-lg p-2 shadow-[0px_4px_30px_0px_#0000001A]">
            {checkList?.map((i: CheckListItem) => (
              <p
                key={i.checkListId}
                className="cursor-pointer rounded-md px-2 py-3 text-body-2 text-gray-700 hover:bg-gray-100"
                onClick={() => {
                  setSelectedCheckList(i);
                  toggleCheckList();
                }}
              >
                {i.title}
              </p>
            ))}
          </div>
        )}
        <div className="space-y-6 divide-y-2">
          {selectedCheckList?.checkListId && (
            <div className="space-y-6">
              <p className="text-body-2 text-gray-600">
                <Text>
                  productManagement.products.completeness.assignedCheckListItem
                </Text>
              </p>
              <div className="flex flex-wrap justify-between gap-3">
                <p className="flex items-center gap-2">
                  <Icon icon="list" className="text-primary" />
                  <span className="text-body-2 font-medium text-gray-800">
                    {selectedCheckList.title}
                  </span>
                </p>
                <Icon
                  icon="trash"
                  className="cursor-pointer text-danger transition-transform hover:scale-110 hover:text-danger-active"
                  onClick={() => setSelectedCheckList(undefined)}
                />
              </div>
            </div>
          )}
          {!!attachments && attachments?.length !== 0 && (
            <div className="space-y-3 pt-6">
              <p className="text-body-2 text-gray-600">
                <Text>
                  productManagement.products.completeness.attachements
                </Text>
              </p>
              <div className="divide-y-2 divide-gray-100">
                {attachments.map(file => (
                  <div
                    className="flex flex-wrap justify-between gap-3 py-3"
                    key={file.url}
                  >
                    <p className="flex items-center gap-2">
                      <Icon icon="link" className="text-primary" />
                      <div className="space-y-2">
                        <p className="text-body-2 text-gray-800">{file.name}</p>
                        <p className="text-body-2 text-gray-600">
                          {getFileProperties(file.type).type}
                        </p>
                      </div>
                    </p>
                    <Icon
                      icon="trash"
                      className="cursor-pointer text-danger transition-transform hover:scale-110 hover:text-danger-active"
                      onClick={() => handleRemoveAttachment(file)}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-between border-t py-6">
          <Button size="sm" variant={"light"} onClick={toggle} type="button">
            <Text>global.cancel</Text>
          </Button>
          <Button
            size="sm"
            type="submit"
            loading={submitLoading}
            disabled={submitLoading}
          >
            <Text>global.buttons.saveChanges</Text>
          </Button>
        </div>
      </div>
    </Form>
  );
}
