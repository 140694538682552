import {
  Drawer,
  List,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Text,
} from "components";
import { config } from "constant";
import { Fragment, useContext, useEffect, useState } from "react";
import { ServiceApi } from "services";
import { Supplier, WithPaging } from "types";
import { ConfigProductContext } from ".";
type Data = Supplier.Item;
type Params = {
  keyword?: string | null;
  pageSize: number;
  pageNumber: number;
};
export default function Suppliers() {
  const { setSupplier } = useContext(ConfigProductContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<WithPaging<Data> | null>(null);
  const [params, setParams] = useState<Params>({
    pageNumber: 1,
    pageSize: config.pageSize,
  });
  const handleClick = (supplier: Data) => {
    return () => setSupplier(supplier);
  };
  const getData = () => {
    setLoading(true);
    const url = "/productservice/api/suppliers";
    const config = { params };
    ServiceApi.get(url, config)
      .then(({ data }) => {
        setData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(getData, [params]);
  return (
    <Fragment>
      <Drawer.Header className="border-b-0 space-y-4">
        <fieldset className="text-start">
          <legend>
            <Text>productManagement.products.allProducts.chooseSupplier</Text>
          </legend>
        </fieldset>
        <SearchBox
          value={params.keyword}
          onSubmit={(keyword) =>
            setParams((p) => ({ ...p, keyword, pageNumber: 1 }))
          }
          variant="gray"
          totalItems={data?.totalItems}
          onReload={getData}
        />
      </Drawer.Header>
      <Drawer.Body className="space-y-4">
        {loading ? (
          [...Array(10).keys()].map((key) => <Skeleton.Input key={key} />)
        ) : !data?.items?.length ? (
          <NoItems />
        ) : (
          <List>
            {data.items.map((e) => (
              <List.Item
                key={e.supplierId}
                className="space-y-2"
                onClick={handleClick(e)}
              >
                <h6 className="block w-full text-heading-6 text-gray-800 font-semibold">
                  {e.name}
                </h6>
                <p className="block w-full text-body-2 text-gray-500 font-medium">
                  #{e.iln}
                </p>
              </List.Item>
            ))}
          </List>
        )}
      </Drawer.Body>
      <Drawer.Footer>
        <Pagination
          setActivePage={(page) =>
            setParams((p) => ({ ...p, pageNumber: +page }))
          }
          onPageSizeChange={(size: any) =>
            setParams((p) => ({ ...p, pageSize: +size }))
          }
          pageNumber={params.pageNumber}
          pageSize={params.pageSize}
          totalItems={data?.totalItems}
          totalPages={data?.totalPages}
        />
      </Drawer.Footer>
    </Fragment>
  );
}
