import { Button, Icon, LazyImage, Skeleton, Status, Text } from "components";
import { PimContext } from ".";
import { useContext, useEffect, useState } from "react";
import ProductFamilyPath from "pages/product-managment/products/all-products/helpers/product-family-path";
import { useConverter, useToggleState } from "hooks";
import ChannelPrice from "./drawers/channel-price";
import { ServiceApi, URLS } from "services";
import { PurchasePriceType } from "enum";
import { useSearchParams } from "react-router-dom";
import EditPriceDrawer from "./drawers/edit-price";

type OverwrittenPrices = {
  vk1: number;
  vk2: number;
};

type BoxProperties = {
  backgroundColor: string;
  textBgColor?: string;
  textContent?: string;
};

export default function Info() {
  const { convertAmount } = useConverter();
  const { details, loading, getDetails } = useContext(PimContext);
  const [channelPriceDrawer, toggleChannelPriceDrawer] = useToggleState();
  const [editPriceDrawer, toggleCEditPriceDrawer] = useToggleState();
  let [searchParams] = useSearchParams();
  const [selectedPriceToEdit, setSelectedPriceToEdit] = useState<'uvp' | 'vk1'>('vk1');
  const [loadingCalculationPrice, toggleLoadingCalculationPrice] =
    useToggleState();
  const [overwrittenPrices, setOverwrittenPrices] =
    useState<OverwrittenPrices>();
  const primaryBox: BoxProperties = { backgroundColor: "bg-gray-100" };
  const warningBox: BoxProperties = {
    backgroundColor: "bg-warning-light",
    textBgColor: "warning",
    textContent:
      "productManagement.publishedProduct.Details.priceWarning.warningMessage",
  };
  const dangerBox: BoxProperties = {
    backgroundColor: "bg-danger-light",
    textBgColor: "danger",
    textContent:
      "productManagement.publishedProduct.Details.priceWarning.errorMessage",
  };

  const [priceBoxType, setPriceBoxType] = useState<BoxProperties>(primaryBox);

  const setBoxType = (data: OverwrittenPrices) => {
    if (
      data.vk1 === details?.originalVk1Price?.amount &&
      data.vk2 === details?.vk2Price?.amount
    )
      setPriceBoxType(primaryBox);
    else if (!!details.hasOverwrittenPrice) setPriceBoxType(dangerBox);
    else setPriceBoxType(warningBox);
  };

  const getOverwrittenPrices = () => {
    toggleLoadingCalculationPrice();
    const body = {
      productId: details?.id,
      supplierId: details?.supplier?.supplierId,
      programId: details?.supplier?.program?.supplierProgramId,
      brandId: details?.supplier?.program?.brand?.brandId,
      saleChannelId: details?.saleChannelId,
      basePrice: details.grossPrice?.amount,
      purchasePriceType: PurchasePriceType.Gross,
    };
    ServiceApi.post(URLS.CALC_PIM_SALE_PRICE, body)
      .then((res: any) => {
        setBoxType(res.data);
        setOverwrittenPrices(res.data);
      })
      .finally(() => {
        toggleLoadingCalculationPrice();
      });
  };

  useEffect(() => {
    if (!!details?.id && details?.pimProductType === 0) getOverwrittenPrices();
  }, [details]);

  return (
    <div className="space-y-4">
      <div className="flex justify-between space-x-4">
        <div className="flex space-x-4">
          <LazyImage
            isDynamic
            className="h-[191px] w-[349px] cursor-pointer bg-gray-100"
            src={details?.avatar?.url}
            imageClassName="h-[191px] w-[349px]"
          // onClick={toggle}
          />
          <section className="space-y-4">
            {loading ? (
              <div className="w-96 animate-pulse space-y-4">
                <div className="col-span-1 h-2 rounded bg-slate-200"></div>
                <div className="col-span-2 h-2 rounded bg-slate-200"></div>
                <div className="col-span-1 h-2 rounded bg-slate-200"></div>
                <div className="col-span-2 h-2 rounded bg-slate-200"></div>
                <div className="col-span-1 h-2 rounded bg-slate-200"></div>
              </div>
            ) : (
              <>
                <div className="flex flex-wrap items-center gap-3">
                  <h5 className="text-gray-800">{details?.originalName}</h5>
                  <Status.PimProductType id={details?.pimProductType} />
                  <Status.AvailabilityStatusWithTitle
                    id={details?.availabilityStatus?.availabilityStatus}
                  />
                </div>
                <div className="flex gap-x-10 gap-y-4 text-body-2">
                  <div className="space-y-4">
                    <p className="font-medium text-gray-500">
                      <Text>
                        productManagement.products.Details.articleNumber
                      </Text>
                      <span className="font-medium text-gray-700">
                        :{" "}
                        {details?.articleNumber ? (
                          <span aria-label="cy-article-number">{`#${details?.articleNumber}`}</span>
                        ) : (
                          "--"
                        )}
                      </span>
                    </p>
                    {/* {!details?.pimProductType && (
                      <p className="font-medium text-gray-500">
                        <Text>
                          productManagement.products.Details.supplierArticleNumber
                        </Text>
                        <span className="font-medium text-gray-700">
                          {" "}
                          :{" "}
                          {details?.supplierArticleNumber
                            ? details?.supplierArticleNumber
                            : "--"}
                        </span>
                      </p>
                    )} */}
                    {details?.supplier && (
                      <p className="font-medium text-gray-500">
                        <Text>productManagement.products.Details.supplier</Text>
                        <span className="font-medium text-gray-700">
                          {" "}
                          : {details?.supplier?.name}
                        </span>
                      </p>
                    )}
                    {details?.supplier?.program && (
                      <p className="font-medium text-gray-500">
                        <Text>productManagement.products.Details.program</Text>
                        <span className="font-medium text-gray-700">
                          {" "}
                          : {details?.supplier?.program?.name}
                        </span>
                      </p>
                    )}
                    {details?.productFamily?.pimProductFamilyId && (
                      <p className=" w-fit rounded bg-success-light p-2 font-medium leading-5">
                        <span className="font-medium text-gray-600 ">
                          <ProductFamilyPath
                            productData={details?.productFamily}
                            pimProductFamilyId={
                              details?.productFamily?.pimProductFamilyId
                            }
                          />
                        </span>
                      </p>
                    )}
                  </div>
                </div>
              </>
            )}
          </section>
        </div>
        {!loading && (
          <div className="flex min-w-72 flex-col gap-3 rounded bg-gray-100 p-6">
            <div className="rounded-[20px] bg-[#38476D17] bg-opacity-90 py-2 text-center text-body-2 font-medium text-primary">
              {details?.saleChannelTitle}
            </div>
            <div className="flex items-center justify-between text-gray-600">
              <span className="text-body-base text-heading-6">
                <Text>vk2</Text>{" "}
              </span>
              <span className={`text-heading-5 font-semibold ${!!details?.uvpPrice?.amount ? 'line-through text-gray-600' : "text-primary-active"}`}>
                {convertAmount(details?.vk2Price?.amount)}
              </span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-body-base text-heading-6 text-gray-600">
                <Text>productManagement.publishedProduct.Details.uvpPrice</Text>
                <Icon
                  icon="pen-to-square"
                  className="ms-2 cursor-pointer text-primary"
                  onClick={() => { toggleCEditPriceDrawer(); setSelectedPriceToEdit('uvp') }}
                />
              </span>
              <span className="text-heading-5 font-semibold text-primary-active">
                {!!details?.uvpPrice?.amount ? `${convertAmount(details?.uvpPrice?.amount)}` : '--'}
              </span>
            </div>
            <hr className="bg-gray-300" />
            <div className="flex items-center justify-between">
              <span className="text-body-base text-heading-6 text-gray-600">
                <Text>vk1</Text>
                {!details?.hasOverwrittenPrice && (
                  <Icon
                    icon="pen-to-square"
                    className="ms-2 cursor-pointer text-primary"
                    onClick={() => { toggleCEditPriceDrawer(); setSelectedPriceToEdit('vk1') }}
                  />
                )}
              </span>
              <span className={`text-heading-5 font-semibold ${!!details?.hasOverwrittenPrice ? 'line-through text-gray-600' : "text-primary-active"}`}>
                {convertAmount(details?.originalVk1Price?.amount)}
              </span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-body-base text-heading-6 text-gray-600">
                <Text>productManagement.publishedProduct.Details.uvpPrice</Text>
                <Icon
                  icon="pen-to-square"
                  className="ms-2 cursor-pointer text-primary"
                  onClick={() => { toggleCEditPriceDrawer(); setSelectedPriceToEdit('uvp') }}
                />
              </span>
              <span className="text-heading-5 font-semibold text-primary-active">
                {convertAmount(details?.uvpPrice?.amount)}
              </span>
            </div>
            {details?.hasOverwrittenPrice && (
              <div className="flex items-center justify-between">
                <span className="text-body-base text-heading-6 text-gray-600">
                  <Text>
                    productManagement.publishedProduct.Details.overWritedPrice
                  </Text>{" "}
                  <Icon
                    icon="pen-to-square"
                    className="ms-1 cursor-pointer text-primary"
                    onClick={() => { toggleCEditPriceDrawer(); setSelectedPriceToEdit('vk1') }}
                  />
                </span>
                <span className="text-heading-5 font-semibold text-primary-active">
                  {convertAmount(details?.vk1Price?.amount)}
                </span>
              </div>
            )}
            <Button
              type="button"
              variant="primary"
              light
              className="!h-auto !whitespace-normal"
              onClick={toggleChannelPriceDrawer}
            >
              <Text>
                productManagement.publishedProduct.Details.priceInOtherhannels
              </Text>
            </Button>
          </div>
        )}
      </div>
      {!loadingCalculationPrice && overwrittenPrices && (
        <div
          className={`flex flex-wrap justify-between gap-3 rounded p-6 ${priceBoxType.backgroundColor}`}
        >
          <div className="space-y-3">
            <h6 className="text-heading-6 font-semibold text-gray-700">
              <Text>productManagement.publishedProduct.Details.priceNow</Text>:
            </h6>
            <div className="flex items-center gap-28 text-gray-600">
              <span className="text-body-base text-heading-6">
                <Text>vk2</Text> :{" "}
              </span>
              <span className="text-heading-6 font-semibold line-through">
                {convertAmount(overwrittenPrices?.vk2)}
              </span>
            </div>
            <div className="flex items-center gap-28 text-gray-600">
              <span className="text-body-base text-heading-6 text-gray-600">
                <Text>vk1</Text> :{" "}
              </span>
              <span className="text-heading-6 font-semibold text-success">
                {convertAmount(overwrittenPrices?.vk1)}
              </span>
            </div>
          </div>
          {priceBoxType.textContent && (
            <div
              className={`max-w-[400px] bg-${priceBoxType.textBgColor} flex items-center gap-3 rounded px-4 py-2 text-heading-6 font-semibold text-white`}
            >
              <Icon icon="circle-info" />
              <Text>{priceBoxType.textContent}</Text>
            </div>
          )}
        </div>
      )}
      {loadingCalculationPrice && <Skeleton.Box />}
      <ChannelPrice
        isOpen={channelPriceDrawer}
        toggle={toggleChannelPriceDrawer}
      />
      <EditPriceDrawer
        isOpen={editPriceDrawer}
        toggle={toggleCEditPriceDrawer}
        onChangePrice={getDetails}
        type={selectedPriceToEdit}
      />
    </div>
  );
}
