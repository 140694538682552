import PurchasePrice from "./purchase-price";
import SalesPrice from "./sales-price";

export default function Templates() {
  return (
    <div className="grid grid-cols-1 gap-8 xl:grid-cols-2">
      <PurchasePrice />
      <SalesPrice />
    </div>
  );
}
