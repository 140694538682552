export const allProducts = {
  addProduct: "Neues Produkt",
  skuName: "Originalname",
  sku: "Produkt Originalname",
  article: "Artikel",
  productFamily: "Produktfamilie",
  created: "Erstellt",
  modified: "Geändert",
  completeness: "Vollständigkeit",
  keyword: "Stichwort",
  productCategory: "Produktkategorie",
  status: "Status",
  brand: "Marke",
  suppliers: "Lieferanten",
  program: "Programm",
  store: "Geschäft",
  priceRange: "Preisspanne",
  to: "Bis",
  from: "Von",
  useAsProductNameInThisDashboard:
    "Als Produktname in diesem Dashboard verwenden",
  filters: "Filter",
  resetFilters: "Filter zurücksetzen",
  close: "Schließen",

  // iwofurn
  importIwofurn: "Import IWOFURN",
  closeBtn: "Schließen",
  articleNumber: "Artikelnummer",
  supplier: "Lieferant",
  program: "Programm",
  store: "Filiale",
  chooseSupplier: "Lieferant auswählen",
  chooseProgram: "Programm auswählen",
  chooseStore: "Filiale auswählen",
  cancelBtn: "Abbrechen",
  addProductBtn: "Produkt hinzufügen",
  chooseProductFamily: "Produktfamilie auswählen",
  productFamily: "Produktfamilie",
  importBtn: "Importieren",
  successMessage: "Produkte erfolgreich importiert",
  // basket
  addBasketFromIwofurn: "Warenkorb IWOFURN importieren",
  changeBasketBtn: "Warenkorb ändern",
  chooseBasket: "Warenkorb auswählen",
  basketTitle: "Warenkorb Titel",
  price: "Gesamtpreis",
  purchasePrice: "Einkaufspreis",
  createdAt: "Erstellungsdatum",
  basketInfo: "Warenkorbinformation",
  basketInternalTag: "IWOFURN ID",
  basketTag: "IWOFURN Tag",
  senderName: "Ersteller",
  iwofurnBasketPrice: "Warenkorb Betrag",
  iwofurnBasketPurchasePrice: "Warenkorb Einkaufsbetrag",
  products: "Produkte",
  active: "Aktiv",
  removeBasketAfterImport: "Warenkorb nach Import löschen",
  importBasket: "Warenkorb importieren",
  backBtn: "Zurück",
  createNewProduct: "Neues Produkt",
  createWithIwofurn: "Neues Produkt konfigurieren (IWOFURN)",
  selectFromIwofurn: "IWOFURN Warenkorb importieren",
  importFromPool: "Import aus Produktpool (PULS)",
  supplier: "Lieferant",
  referenceKey: "Referenz #",
  ThereIsNoIwofurnCodeAvailable: "Kein IWOFURN Code vorhanden",
  importFromExcel: "Import aus EXCEL",
  importProductFromExcel: "Produktimport aus EXCEL",
  import: "IMPORT",
  pleaseWait: "Bitte warten Sie..",
  updateAt: "Update am",
  updateBy: "Update durch",
  supplierILN: "Lieferanten ILN",
  selectPimProduct: "PIM Produkt auswählen",
  quantity: "Anzahl",
  addItem: "Artikel hinzufügen",
  editBundleItem: "Set Produkt bearbeiten",
  bundleLabel: "Set Bezeichnung",
  items: "Artikel",
  createBundleProduct: "Set Produkt erstellen",
  standard: "Standard",
  bundle: "Set",
  all: "Alle",
  overwrittenSalePrice: "Verkaufspreis überschreiben",
  addOverwrittenSalePrice: "überschriebenen VKP hinzufügen",
  completeness: "komplett",
  needToWork: "bitte bearbeiten",
  done: "Abschliessen",
  note: "Notiz",
  descriptionPlaceHolder: "Hier ist Raum für Ihre Notizen...",
  doneItems: "fertige Items",
  needToWorkItems: "Items bearbeiten",
  completed: "Komplett",
  completenessFilterComment:
    "Sie könnne Produkte mit dem Status [Done completeness] der [Need to work completeness with range percent] wahlen",
  done: "It's done !",
  needToWork: "Bitte bearbeiten",
  completenessRange: "Fertigstellungsgrad",
  incompleteChecklist: "Checkliste nicht komplett",
  checklistTab: "Checkliste",
  conversationTab: "Konversation",
  duplicate: "Duplicate product DE",
};
