import {
  Button,
  DeleteModal,
  HandleName,
  Icon,
  InputGroup,
  NoItems,
  Select,
  SelectLang,
  Skeleton,
  Status,
  Table,
  Text,
  Wrapper,
} from "components";
import {Fragment, useContext, useEffect, useState} from "react";

import {NotificationManager} from "common/notifications";
import {useSelector, useToggleState, usePermission} from "hooks";
import {ServiceApi, URLS} from "services";
import {AttributesTypes} from "utils";
import {AttributesContext} from "..";
import CreateModal from "./type-select/create-modal";
import UpdateModal from "./type-select/update-modal";

export default function AttributesTypeSelect() {
  const companySetting = useSelector(s => s.companySetting);
  const updatePermission = usePermission("PS_UpdateAttributeTemplate");
  const {attributeId, attributesData, setAttributesData, loading, getData} =
    useContext<any>(AttributesContext);
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState("");
  const [selectedName, setSelectedName] = useState<any>(null);
  const [isOpenCreateModal, toggleCreateModal] = useToggleState(false);
  const [isOpenUpdateModal, toggleUpdateModal] = useToggleState(false);
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [langItems, setLangItems] = useState([]);
  const [defaultValue, setDefaultValue] = useState("");
  const [langSelected, setLangSelected] = useState("de");

  useEffect(() => {
    let arr: any = [];
    attributesData?.items?.forEach((item: any) => {
      arr.push({
        id: item?.code?.toLocaleLowerCase(),
        name: item.translates[0].labelTitle,
      });
    });
    setItems(arr);
    setLangSelected(
      companySetting?.defaultContentLanguage?.toLocaleLowerCase(),
    );
    setDefaultValue(attributesData?.defaultValue);
  }, [attributesData?.items]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let arr: any = [];
    companySetting?.contentLanguages?.forEach((item: any) => {
      arr.push({id: item, name: item});
    });
    setLangItems(arr);
  }, [companySetting]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSetData = (key: any) => {
    return (value: any) =>
      setAttributesData((p: any) => ({...p, [key]: value}));
  };

  const submitDelete = (id: any) => {
    setLoadingButton(true);
    const url = URLS.DELETE_ITEM_SELECT_ATTRIBUTES_URLS(attributeId);
    const body: any = {attributeItemId: id};
    ServiceApi.delete(url, {data: body})
      .then(({data}) => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title",
        );
        getData();
        setLoadingButton(false);
        toggleDeleteModal();
      })
      .catch(() => setLoadingButton(false));
  };

  const onChangeLanguage = (lang: any) => {
    setLangSelected(lang);
  };

  return (
    <Fragment>
      <div className="flex gap-7">
        {loading ? (
          <Wrapper>
            <Wrapper.Body className="grid grid-cols-1 gap-4 lg:grid-cols-2">
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Wrapper.Body>
          </Wrapper>
        ) : (
          <Wrapper>
            <Wrapper.Header>
              <h2 className="font-semibold text-gray-800">
                <Text>productManagement.masterData.attributes.setting</Text>
              </h2>
            </Wrapper.Header>
            <Wrapper.Body className="grid grid-cols-1 gap-4 lg:grid-cols-1">
              <div className="space-y-8">
                <InputGroup
                  label="productManagement.masterData.attributes.attributeType"
                  value={AttributesTypes(attributesData?.type)}
                  disabled
                />
                <Select
                  label="productManagement.masterData.attributes.attributeDefaultValue"
                  items={items}
                  value={attributesData?.defaultValue}
                  setValue={handleSetData("defaultValue")}
                  disabled={!updatePermission}
                />
              </div>
            </Wrapper.Body>
          </Wrapper>
        )}
        {loading ? (
          <Wrapper>
            <Wrapper.Body className="grid grid-cols-1 gap-4 lg:grid-cols-1">
              <Skeleton.List />
              <Skeleton.List />
              <Skeleton.List />
              <Skeleton.List />
              <Skeleton.List />
            </Wrapper.Body>
          </Wrapper>
        ) : (
          <Wrapper>
            <Wrapper.Header>
              <div className="flex items-center justify-between">
                <div>
                  <h2 className="font-semibold text-gray-800">
                    <Text>
                      productManagement.masterData.attributes.itemsList
                    </Text>
                  </h2>
                </div>
                <div>
                  <SelectLang
                    value={langSelected}
                    items={langItems}
                    onChangeLanguage={onChangeLanguage}
                  />
                </div>
              </div>
            </Wrapper.Header>
            <Wrapper.Body className="grid grid-cols-1 gap-4 lg:grid-cols-1">
              {attributesData?.items?.length !== 0 ? (
                <Table>
                  {attributesData?.items?.map((item: any) => {
                    return (
                      <tr key={item.id} style={{backgroundColor: "#F5F8FA"}}>
                        <td className="space-y-4">
                          <div>
                            <h6 className="font-semibold text-gray-800">
                              {
                                <HandleName
                                  translations={item?.translates}
                                  code={item?.id}
                                  keyName="labelTitle"
                                  language={langSelected}
                                />
                              }
                            </h6>
                          </div>
                          <div>
                            <span className="text-body-2 font-medium text-gray-500">
                              {item.code}
                            </span>
                          </div>
                        </td>
                        <td></td>
                        <td>
                          <div className="flex items-center justify-end gap-[54px]">
                            <div>
                              <Status.DefaultStatus
                                id={item.code === defaultValue}
                              />
                            </div>
                            {updatePermission && (
                              <div>
                                {item.code !== defaultValue && (
                                  <Button
                                    type="button"
                                    size="sm"
                                    variant="white"
                                    textColor="danger"
                                    className="mr-4"
                                    onClick={() => {
                                      setSelected(item.id);
                                      setSelectedName(item);
                                      toggleDeleteModal();
                                    }}
                                  >
                                    <Icon icon={"trash"} />
                                  </Button>
                                )}
                                <Button
                                  type="button"
                                  size="sm"
                                  variant="white"
                                  textColor="primary"
                                  onClick={() => {
                                    setSelected(item);
                                    toggleUpdateModal();
                                  }}
                                >
                                  <Icon icon={"edit"} />
                                </Button>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </Table>
              ) : (
                <div className="flex w-full flex-col items-center space-y-4">
                  <NoItems />
                </div>
              )}
              {updatePermission && (
                <Wrapper.Section className="courser-pointer mb-4 h-[52px] w-full cursor-pointer border-primary bg-gray-100 py-[17px] text-center">
                  <div
                    className="w-full"
                    onClick={() => {
                      toggleCreateModal();
                    }}
                  >
                    <h5 className="text-primary">
                      <Icon
                        className="mr-2 text-primary"
                        icon={"square-plus"}
                      />
                      <Text>
                        productManagement.masterData.attributes.addItem
                      </Text>
                    </h5>
                  </div>
                </Wrapper.Section>
              )}
            </Wrapper.Body>
          </Wrapper>
        )}
      </div>
      <CreateModal
        isOpen={isOpenCreateModal}
        toggle={toggleCreateModal}
        attributeId={attributeId}
        getData={getData}
      />
      <UpdateModal
        isOpen={isOpenUpdateModal}
        toggle={toggleUpdateModal}
        attributeId={attributeId}
        getData={getData}
        selected={selected}
        defaultValue={attributesData?.defaultValue}
      />
      <DeleteModal
        isOpen={isOpenDeleteModal}
        toggle={toggleDeleteModal}
        selected={{
          id: selected,
          name: (
            <HandleName
              translations={selectedName?.translates}
              code={selectedName?.id}
              keyName="labelTitle"
              language={langSelected}
            />
          ),
        }}
        loading={loadingButton}
        onConfirm={submitDelete}
      />
    </Fragment>
  );
}
