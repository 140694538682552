export const customerList = {
  cimNumber: "CIM-Nummer",
  firstName: "Vorname",
  lastName: "Nachname",
  basicInfo: "Basis Informationen",
  communicationChannels: "Werbekanal",
  location: "Standort",
  general: "Allgemein",
  address: "Adresse",
  gender: "Geschlecht",
  birthdate: "Geburtsdatum",
  mobileNumber: "Handynummer",
  phoneNumber: "Telefonnummer",
  emailAddress: "E-Mail-Adresse",
  street: "Straße",
  houseNumber: "Hausnummer",
  postalCode: "Postleitzahl",
  name: "Name",
  city: "Stadt",
  state: "Bundesland",
  country: "Land",
  companyName: "Firma",
  company: "Firmenname",
  taxNumber: "Steuernummer",
  channels: "Kanäle",
  email: "E-Mail",
  application: "Anwendung",
  sms: "SMS",
  phone: "Telefon",
  post: "Post",
  socialMedia: "Soziale Medien",
  familyBonus: "Familienbonus",
  newsLetterSubscription: "Newsletter-Abonnement",
  title: "Titel",
  accountInfo: "Kontoinformationen",
  status: "Status",
  id: "ID",
  customerId: "Kundennummer",
  deviceName: "Gerätename",
  mobileVerifyAt: "Handy-Verifizierung angefordert am",
  emailVerifyAt: "E-Mail-Verifizierung angefordert am",
  companyType: "Firmentyp",
  individual: "Einzelperson",
  pending: "Ausstehend",
  approved: "Genehmigt",
  suspended: "Gesperrt",
  EligibleforTax: "Steuerberechtigt",
  TaxID: "Steuer-ID",
  registrationNumber: "Registrierungsnummer",
  contactPerson: "Kontaktperson",
  registered: "Registriert",
  notRegistered: "Nicht registriert",
  verified: "Verifiziert",
  none: "No!",
};
