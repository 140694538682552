export const salesTemplates = {
  addTemplates: "Vorlagen hinzufügen",
  addPhase: "Phase hinzufügen",
  editPhase: "Phase bearbeiten",
  detailSalesTemplates: "Detailverkaufsvorlagen",
  label: "Bezeichnung",
  description: "Beschreibung",
  phase: "Phase",
  basicInfo: "Grundlegende Informationen",
  basicInfoDesc: "Name, Beschreibung, ...",
  phaseInfo: "Phaseninformationen",
  phaseInfoDesc: "Verkaufsphase definieren",
  review: "Überprüfen",
  reviewDesc: "Informationen abschließen",
  templateName: "Vorlagenname",
  code: "Code",
  shortDescription: "Kurzbeschreibung",
  phaseName: "Phasenname",
  discount: "Rabatt",
  duration: "Dauer",
  sellerCommission: "Verkäuferprovision",
  checkListItems: "Checklistenpunkte",
  day: "Day DE",
};
