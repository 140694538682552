import {NotificationManager} from "common/notifications";
import {
  Accordion,
  Button,
  CheckBox,
  Drawer,
  Form,
  Icon,
  InputGroup,
  Skeleton,
  Text,
  HandleName,
} from "components";
import {rules} from "constant";
import {useToggleState} from "hooks";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ServiceApi, URLS} from "services";

type drawerProps = {
  isOpen: boolean;
  pimProductType: number;
  toggle: () => void;
  getData: () => void;
};

const CreateDrawer = ({
  isOpen,
  toggle,
  getData,
  pimProductType,
}: drawerProps) => {
  const [data, setData] = useState({
    pimProductFamilyId: "",
    originalName: "",
  });
  const navigate = useNavigate();
  const [loadingButton, setLoadingButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productFamilyItems, setProductFamilyItems] = useState<any>([]);
  const [loadRoots, setRootLoading] = useToggleState();
  const [loadChildren, setChildrenLoading] = useToggleState();
  const [pmError, setPmError] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setData({
        pimProductFamilyId: "",
        originalName: "",
      });
      getFamiliesData();
    }
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const findChildrens = (data: any, id: string, parent: any) => {
    const root = data.filter((item: any) => !item.parentId);
    const childrens = data.filter((item: any) => item.parentId === id);
    if (childrens.length > 0) {
      parent.items = childrens;
      childrens.map((productFamily: any) => {
        findChildrens(data, productFamily.pimProductFamilyId, productFamily);
      });
    }
    return [parent];
  };

  const getFamiliesData = () => {
    const url = URLS.CREATE_GET_PUT_DELETE_PIM_PRODUCT_FAMILY_URL;
    setLoading(true);
    ServiceApi.get(url).then(({data}) => {
      let parent = data.find((item: any) => item.parentId === null);
      const list = findChildrens(data, parent.pimProductFamilyId, parent);
      setProductFamilyItems(list);
      setLoading(false);
    });
  };

  const submitHandler = () => {
    setLoadingButton(true);
    const url = URLS.CREATE_PIM_PRODUCT_URL;
    ServiceApi.post(url, {...data, pimProductType})
      .then(res => {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title",
        );
        toggle();
        getData();
        setLoadingButton(false);
        navigate(`/productManagement/products/all-products/${res?.data}`);
      })
      .finally(() => {
        setLoadingButton(false);
      });
  };

  const submit = () => {
    setPmError(false);
    if (pimProductType) {
      submitHandler();
    } else if (data?.pimProductFamilyId !== "") {
      submitHandler();
    } else {
      setPmError(true);
    }
  };

  const createProductFamilyAccordion = (productFamilyArr: any) => {
    return productFamilyArr.map((productFamily: any) => {
      return (
        <Accordion key={productFamily.code}>
          <Accordion.Item
            className={`border bg-white px-4 shadow-nested   ${
              productFamily?.parentId === null ? "rounded-2xl" : "rounded-lg"
            } mt-2`}
          >
            <Accordion.Toggle
              className={`flex items-center ${
                // productFamily?.parentId === null ? "" : "!py-2"
                ""
              } justify-between`}
            >
              <div className="whitespace-break-spaces	break-all text-start">
                <div>
                  {productFamily?.items?.length > 0 && (
                    <Icon
                      icon={"caret-right"}
                      className="mr-2 transition-transform group-[.accordion-item.active]:rotate-90"
                    />
                  )}
                  <span className="text-heading-6 font-semibold">
                    {
                      <HandleName
                        translations={productFamily?.translates}
                        code={productFamily?.code}
                        keyName="labelTitle"
                      />
                    }
                  </span>
                </div>
                <p className="text-body-2">{productFamily.code}</p>
              </div>

              <div aria-label="cy-product-family">
                <CheckBox
                  key={`checkbox_${productFamily.code}`}
                  value={
                    productFamily?.pimProductFamilyId ===
                    data?.pimProductFamilyId
                  }
                  setValue={pimProductFamilyId => {
                    setData((p: any) => ({
                      ...p,
                      pimProductFamilyId: productFamily.pimProductFamilyId,
                    }));
                  }}
                />
              </div>
            </Accordion.Toggle>
            {productFamily.items && (
              <>
                <Accordion.Body className="px-4">
                  {createProductFamilyAccordion(productFamily.items)}
                </Accordion.Body>
              </>
            )}
          </Accordion.Item>
        </Accordion>
      );
    });
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Form onSubmit={submit}>
        <Drawer.Menu className="pr-0">
          <Drawer.Header className="pr-4 pt-6">
            <div className="flex items-center justify-between">
              <span className="text-heading-2 font-semibold">
                <Text>productManagement.products.allProducts.addProduct</Text>
              </span>
              <div className="flex gap-x-2">
                <Button
                  size="sm"
                  type="button"
                  variant={"light"}
                  onClick={toggle}
                >
                  <Icon icon="times" className="mr-1" />
                  <Text>productManagement.products.allProducts.close</Text>
                </Button>
              </div>
            </div>
          </Drawer.Header>
          <Drawer.Body className="space-y-4 px-6 py-8">
            <div>
              {loading ? (
                <div className="space-y-8">
                  <Skeleton.Input />
                  <Skeleton.Input />
                </div>
              ) : (
                <div className="space-y-8" aria-label="cy-product-name">
                  <InputGroup
                    label="productManagement.products.allProducts.sku"
                    value={data.originalName}
                    className="border-white"
                    setValue={originalName =>
                      setData(p => ({...p, originalName}))
                    }
                    rules={rules.required}
                    helperText="productManagement.products.allProducts.useAsProductNameInThisDashboard"
                  />
                  <h5 className="text-heading-5 text-gray-800">
                    <Text>
                      productManagement.products.allProducts.productFamily
                    </Text>
                  </h5>
                  {pmError && (
                    <p className="!mt-1 flex items-center text-xs text-danger">
                      <i className="bi bi-info-circle mr-1"></i>
                      <span className="mr-1">This field is required</span>
                    </p>
                  )}
                  {loadChildren
                    ? [1, 2, 3, 4].map(key => <Skeleton.List key={key} />)
                    : !loadRoots && (
                        <>{createProductFamilyAccordion(productFamilyItems)}</>
                      )}
                </div>
              )}
            </div>
          </Drawer.Body>
          <Drawer.Footer className="flex items-center justify-between">
            <Button type="button" onClick={toggle} variant="light">
              <Text>global.buttons.cancel</Text>
            </Button>
            <Button
              aria-label="cy-create-product-submit"
              type="submit"
              loading={loadingButton}
            >
              <Text>productManagement.products.allProducts.addProduct</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Form>
    </Drawer>
  );
};

export default CreateDrawer;
