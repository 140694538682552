const ATTRIBUTES_GROUPS = {
  GET_ATTRIBUTE_GROUP_URLS: "/productservice/api/attributegrouptemplates",
  ADD_ATTRIBUTE_GROUP_URLS: "/productservice/api/attributegrouptemplates",
  GET_ATTRIBUTE_GROUP_DETAILS_URLS: (id) =>
    `/productservice/api/attributegrouptemplates/${id}`,
  UPDATE_ATTRIBUTE_GROUP_DETAILS_URLS: (id) =>
    `/productservice/api/attributegrouptemplates/${id}`,
  DELETE_ATTRIBUTE_GROUP_URLS: (id) =>
    `/productservice/api/attributegrouptemplates/${id}`,
  GET_AVAILABLE_ATTRIBUTES:
    "/productservice/api/attributegrouptemplates/available-attributes",
  GET_ADDED_ATTRIBUTES: (id) =>
    `/productservice/api/attributegrouptemplates/${id}/attribute-templates`,

  //... attributes
  GET_ATTRIBUTE_GROUP_ATTRIBUTES_URL: (id) =>
    `/productservice/api/attributegrouptemplates/attribute-templates?attributeTemplateGroupId=${id}`,
  DELETE_ATTRIBUTE_GROUP_ATTRIBUTES_URL: (id) =>
    `productservice/api/attributegrouptemplates/${id}/remove-attribute`,
  ADD_ATTRIBUTE_GROUP_ATTRIBUTES_URL: (id) =>
    `productservice/api/attributegrouptemplates/${id}/add-attribute`,
  GET_SEARCH_ATTRIBUTES_URL: (pageNumber, pageSize, keyword) =>
    `/productservice/api/attributetemplates/search?pageNumber=${pageNumber}&pageSize=${pageSize}&keyword=${keyword}`,
};

export default ATTRIBUTES_GROUPS;
