import {useState, useEffect} from "react";
import {config} from "constant";
import {
  Breadcrumb,
  Icon,
  Skeleton,
  Button,
  Text,
  SearchBox,
  Pagination,
  Table,
  NoItems,
  Status,
  WithPermission,
} from "components";
import {useToggleState, useConverter} from "hooks";
import {CreateCalculationUnit} from "./create";
import {ServiceApi, URLS} from "services";
import {PriceEngin} from "types";
import {unitTypes} from "constant";
import {Link} from "react-router-dom";
import {withPermission} from "hoc";

function CalculationUnits() {
  const controller = new AbortController();
  const {convertAmount} = useConverter();
  const [isOpen, toggle] = useToggleState();
  const [loading, toggleLoading] = useToggleState();
  const [params, setParams] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  });
  const [data, setData] = useState<PriceEngin.CalculationUnit>();

  useEffect(() => {
    getData();
    return () => {
      controller.abort();
    };
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = () => {
    toggleLoading();
    const url = URLS.GET_CALCULATION_UNITS;
    const config = {params};
    ServiceApi.get(url, config)
      .then(({data}) => {
        setData(data);
        toggleLoading();
      })
      .catch(() => toggleLoading());
  };

  const convertTypeValue = (key: number, val: number) => {
    let value;
    switch (key) {
      case 0:
        value = val + " %";
        break;
      case 1:
        value = convertAmount(val);
        break;
      case 2:
        value = "x" + val;
        break;
      case 3:
        value = "Pure %" + val;
        break;
    }
    return value;
  };

  const generateClassName = (key: number) => {
    return key ? "text-danger" : key === 0 ? "text-success" : "";
  };
  const generateOprationSymbol = (oprtype: number, valtype: number) => {
    if (valtype !== 2) return oprtype ? "-" : oprtype === 0 ? "+" : "";
  };
  return (
    <>
      <div className="space-y-4">
        <div className="flex items-center">
          <div className="flex-1">
            <h3 className="text-heading-3">
              <Breadcrumb />
            </h3>
          </div>
          <>
            <WithPermission permissions={["PE_ModifyCalculationUnit"]}>
              {loading ? (
                <Skeleton.Button />
              ) : (
                <Button onClick={toggle}>
                  <Icon className="mr-2" icon={"plus"} />
                  <Text>priceEngine.calculationUnits.addCalculationUnits</Text>
                </Button>
              )}
            </WithPermission>
          </>
        </div>
        <SearchBox
          value={params?.keyword}
          onSubmit={e => setParams(p => ({...p, keyword: e as string}))}
          disabled={loading}
          loading={loading}
          totalItems={data?.totalItems}
          onReload={getData}
        />
        {loading ? (
          <>
            {[1, 2, 3, 4].map(index => (
              <Skeleton.List key={index} />
            ))}
          </>
        ) : (
          <>
            {data?.items?.length ? (
              <>
                <Table>
                  {data?.items?.map(item => {
                    return (
                      <tr className="*:text-gray-800" key={item.id}>
                        <td className="space-y-2 text-heading-6">
                          <h6>{item?.title}</h6>
                          <p>#{item?.code}</p>
                        </td>
                        <td className="space-y-2 text-body-base">
                          <h6>
                            <Text>
                              {
                                unitTypes.find(
                                  type => type.value === item?.valueType,
                                )?.label
                              }
                            </Text>
                          </h6>
                          <>
                            {item.hasManualValue ? (
                              <div className="flex flex-col items-center justify-center gap-2">
                                <p
                                  className={`${generateClassName(
                                    item?.operationType,
                                  )}`}
                                >
                                  <Text>
                                    priceEngine.calculationUnits.minValue
                                  </Text>{" "}
                                  :{" "}
                                  {generateOprationSymbol(
                                    item?.operationType,
                                    item?.valueType,
                                  )}
                                  {convertTypeValue(
                                    item?.valueType,
                                    item?.minValue,
                                  ) ?? "---"}
                                </p>
                                <p
                                  className={`${generateClassName(
                                    item?.operationType,
                                  )}`}
                                >
                                  <Text>
                                    priceEngine.calculationUnits.maxValue
                                  </Text>{" "}
                                  :{" "}
                                  {generateOprationSymbol(
                                    item?.operationType,
                                    item?.valueType,
                                  )}
                                  {convertTypeValue(
                                    item?.valueType,
                                    item?.maxValue,
                                  ) ?? "---"}
                                </p>
                              </div>
                            ) : (
                              <>
                                {item?.valueType < 4 && (
                                  <p
                                    className={`${generateClassName(
                                      item?.operationType,
                                    )}`}
                                  >
                                    <Text>
                                      priceEngine.calculationUnits.defaultValueInList
                                    </Text>{" "}
                                    :{" "}
                                    {generateOprationSymbol(
                                      item?.operationType,
                                      item?.valueType,
                                    )}
                                    {convertTypeValue(
                                      item?.valueType,
                                      item?.itemValue,
                                    ) ?? "---"}
                                  </p>
                                )}
                              </>
                            )}
                          </>
                        </td>
                        <td>
                          <Status.CalculationUnits id={item?.active} />
                        </td>

                        <td>
                          <Button
                            size="sm"
                            variant="light"
                            as={Link}
                            to={item?.id}
                          >
                            <Text>priceEngine.calculationUnits.details</Text>
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </Table>
                <Pagination
                  totalItems={data?.totalItems}
                  totalPages={data?.totalPages || 1}
                  pageNumber={params.pageNumber}
                  pageSize={params.pageSize}
                  setActivePage={page =>
                    setParams(p => ({...p, pageNumber: +page}))
                  }
                  onPageSizeChange={(pageSize: number) =>
                    setParams(p => ({...p, pageSize}))
                  }
                />
              </>
            ) : (
              <div className="flex w-full flex-col items-center space-y-4">
                <NoItems />
              </div>
            )}
          </>
        )}
      </div>
      <CreateCalculationUnit
        isOpen={isOpen}
        toggle={toggle}
        getData={getData}
      />
    </>
  );
}

export default withPermission(CalculationUnits, ["PE_GetCalculationUnit"]);
