import {useEffect, useState} from "react";
import {config} from "constant";
import {
  Drawer,
  Text,
  Button,
  Icon,
  SearchBox,
  Wrapper,
  CheckBox,
  Skeleton,
  RadioButton,
} from "components";
import {ServiceApi, URLS} from "services";
import {useToggleState} from "hooks";
import {PriceEngin} from "types";
import {isEmpty} from "lodash";

type Props = {
  isOpen: boolean;
  toggle: () => void;
  onAddUnits: CallableFunction;
  loading?: boolean;
};

export default function AddNewCalculationUnit({
  isOpen,
  toggle,
  onAddUnits,
  loading,
}: Props) {
  const [params, setParams] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  });
  const [loadingList, setLoding] = useToggleState();
  const [data, setData] = useState<PriceEngin.CalculationUnit>();
  const [selectedUnits, setSelectedUnits] =
    useState<PriceEngin.CalculationUnitItem>();

  const getUnits = () => {
    setLoding();
    const url = URLS.GET_CALCULATION_UNITS;
    const config = {params};
    ServiceApi.get(url, config)
      .then(({data}) => {
        setLoding();
        setData(data);
        setSelectedUnits(undefined);
      })
      .catch(() => setLoding());
  };

  useEffect(() => {
    isOpen && getUnits();
  }, [isOpen, params]);

  const onSelectUnitHandler = (id: PriceEngin.CalculationUnitItem) => {
    // let clone = [...selectedUnits];
    // if (!selectedUnits.includes(id)) {
    //   clone.push(id);
    // } else {
    //   clone = clone.filter(unit => id !== unit);
    // }
    setSelectedUnits(id);
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header className="flex items-center justify-between">
          <span className="text-heading-2 font-semibold">
            <Text>priceEngine.purchasePrice.addNewCalculationUnit</Text>
          </span>
          <Button variant={"light"} onClick={toggle} size="sm" type="button">
            <Icon icon="times" className="mr-2" />
            <Text>global.buttons.close</Text>
          </Button>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          {loadingList ? (
            [1, 2, 3, 4, 5].map(() => <Skeleton.List />)
          ) : (
            <>
              <SearchBox
                value={params?.keyword}
                onSubmit={keyword =>
                  setParams(p => ({...p, keyword: keyword as string}))
                }
                totalItems={data?.totalItems}
                variant="gray"
                placeholder="priceEngine.purchasePrice.searchCalcUitsPlacholder"
              />
              {data?.items?.map((unit: PriceEngin.CalculationUnitItem) => {
                return (
                  <div onClick={() => onSelectUnitHandler(unit)}>
                    <Wrapper className="cursor-pointer shadow-card hover:bg-primary-light">
                      <Wrapper.Body className="flex flex-col space-y-4 !p-4">
                        <div className="flex justify-between gap-2">
                          <div className="flex flex-col space-y-2">
                            <span className="text-heading-5 font-semibold text-gray-800">
                              {unit.title}
                            </span>
                            <p className="text-body-base font-medium text-gray-500">
                              #{unit?.code}
                            </p>
                          </div>
                          <div>
                            <RadioButton
                              value={selectedUnits!?.id === unit.id}
                              setValue={() => onSelectUnitHandler(unit)}
                            />
                          </div>
                        </div>
                        <p className="truncate text-body-base font-medium text-gray-500">
                          {unit.description}
                        </p>
                      </Wrapper.Body>
                    </Wrapper>
                  </div>
                );
              })}
            </>
          )}
        </Drawer.Body>
        <Drawer.Footer className="flex justify-between">
          <Button size="sm" variant={"light"} onClick={toggle} type="button">
            <Text>global.cancel</Text>
          </Button>
          <Button
            size="sm"
            onClick={() => onAddUnits(selectedUnits)}
            loading={loading}
            disabled={isEmpty(selectedUnits)}
            type="button"
          >
            <Text>priceEngine.purchasePrice.addSelectedUnits</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
