const SELL_OF_PRODUCT = {
  GET_SELL_OFF_PRODUCT_LIST: "productservice/api/selloffproducts",

  GET_SELL_PHASE_TEMPLATES:
    "/salesservice/api/sellphasetemplate?pageNumber=1&pageSize=100",

  PUT_SELL_PHASE_TEMPLATES: (id, channelCode) =>
    `/salesservice/api/selloffproducts/${id}/sell-phase-template?channelCode=${channelCode}`,

  REORDER_ASSETS_URL: (id, channelCode) =>
    `salesservice/api/selloffproducts/${id}/reorder-assets?channelCode=${channelCode}`,

  GET_PUT_PRODUCT_DETAILS_URL: id => `productservice/api/selloffproducts/${id}`,

  GET_SUBPRODUCT_TIMELINE_URL: (id, channelCode) =>
    `salesservice/api/selloffproducttimelines/${id}?channelCode=${channelCode}`,

  ADD_SUB_PRODUCT_URL: id =>
    `/productservice/api/selloffproducts/${id}/sub-item`,

  PUT_SUB_PRODUCT_URL: (id, subItemId) =>
    `/productservice/api/selloffproducts/${id}/sub-item/${subItemId}`,

  DELETE_SUB_PRODUCT_URL: (id, subItemId) =>
    `/productservice/api/selloffproducts/${id}/sub-item/${subItemId}`,
  DELETE_PRODUCT_URL: id => `/productservice/api/selloffproducts/${id}`,

  PREPARATION_PHASE_CHECKLIST_URL: (id, channelCode) =>
    `salesservice/api/selloffproducts/${id}/preparationphase-checklistitem?channelCode=${channelCode}`,

  CHANGE_PHASE_CHECKLIST_URL: (id, channelCode) =>
    `salesservice/api/selloffproducts/${id}/sellphase-checklistitem?channelCode=${channelCode}`,

  PREPARATION_PHASE_START_REQUIREMENT_URL: (id, channelCode) =>
    `salesservice/api/selloffproducts/${id}/start-phase-requirement?channelCode=${channelCode}`,

  CHANGE_PHASE__REQUIREMENT_URL: (id, channelCode) =>
    `salesservice/api/selloffproducts/${id}/change-phase-requirement?channelCode=${channelCode}`,

  SOLD_URL: (id, channelCode) =>
    `salesservice/api/selloffproducts/${id}/sold?channelCode=${channelCode}`,
  WITHDRAW_URL: (id, channelCode) =>
    `salesservice/api/selloffproducts/${id}/withdraw-sold-item?channelCode=${channelCode}`,

  START_PHASE_URL: (id, channelCode) =>
    `salesservice/api/selloffproducts/${id}/start-phase?channelCode=${channelCode}`,

  CLOSE_PHASE_URL: (id, channelCode) =>
    `salesservice/api/selloffproducts/${id}/close-phase?channelCode=${channelCode}`,

  CHANGE_PHASE_URL: (id, channelCode) =>
    `salesservice/api/selloffproducts/${id}/change-phase?channelCode=${channelCode}`,

  GET_DETAILS_BASIC_INFO: (id, channelCode) =>
    `salesservice/api/selloffproducts/${id}/basic-info?channelCode=${channelCode}`,

  GET_DETAILS_STATUS_INFO: (id, channelCode) =>
    `salesservice/api/selloffproducts/${id}/status-info?channelCode=${channelCode}`,

  UPDATE_DELETE_ASSETS: (id, channelCode) =>
    `salesservice/api/selloffproducts/${id}/assets?channelCode=${channelCode}`,

  GET_SELL_OFF_STORES: `accountservice/api/stores?pageNumber=1&pageSize=100`,
  GET_SELL_OFF_PRODUCT_FAMILIES: `productservice/api/productfamilies`,
  GET_SELL_OFF_PRODUCT_FAMILIES_Details: id =>
    `productservice/api/productfamilies/${id}`,
  GET_SELL_OFF_BRANDS: `productservice/api/brands`,
  GET_SELL_OFF_SUPPLIERS: `productservice/api/suppliers?pageNumber=1&pageSize=100`,
  GET_SELL_OFF_STORES: `accountservice/api/stores?pageNumber=1&pageSize=100`,

  // ------------- sell off products--------------------
  SELL_OFF_PRODUCTS_URLS: "productservice/api/selloffproducts",
  SELL_OFF_PRODUCTS_TEMPLATE_URL: id =>
    `productservice/api/selloffproducts/${id}/sell-phase-template`,
  SELL_OFF_PRODUCTS_PRICE_URL: id =>
    `productservice/api/selloffproducts/${id}/price`,
  SELL_OFF_SUB_PRODUCTS_PRICE_URL: (id, subId) =>
    `productservice/api/selloffproducts/${id}/sub-item-price/${subId}`,
  PRODUCT_TIMELINE_URL: "/productservice/api/selloffproducttimelines",

  GET_CHANGE_PHASE_NEEDED_PRODUCTS_URL:
    "productservice/api/selloffproducts/need-phase-change",

  SEARCH_PRODUCTS_URL: "/salesservice/api/selloffproducts/search-sell-off",
  SEARCH_SOLD_PRODUCTS_URL: "/productservice/api/selloffproducts/search-sold",

  SEARCH_SELL_OFF_COMISSION:
    "/productservice/api/selloffcommissions/search-sell-off-commission",
  DOWNLOAD_SELL_OFF_COMISSION:
    "/productservice/api/selloffcommissions/download-sell-off-commission",
  UPDATE_PROVISION: id =>
    `productservice/api/selloffproducts/${id}/fast-sales-provision-amount`,
};

export default SELL_OF_PRODUCT;
