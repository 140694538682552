import ProductGroup from "pages/product-managment/master-data/product-group";

const ProductGroupRoute = [
  {
    path: "",
    element: <ProductGroup />,
  },
];

export default ProductGroupRoute;
