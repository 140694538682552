import {Button, Icon, Modal, Text, FileUploader} from "components";
import {useEffect, useState} from "react";
import {assetTypes} from "constant";

type ShareType = {
  isOpen: boolean;
  toggle: () => void;
  toggleImageUploader?: CallableFunction;
  onUploadHandler?: CallableFunction;
  loading?: boolean;
};

export default function SelectMediaType({
  isOpen,
  toggle,
  toggleImageUploader,
  onUploadHandler,
  loading,
}: ShareType) {
  const [file, setFile] = useState<any>();

  useEffect(() => {
    setFile(null);
  }, [isOpen]);

  const imageUploaderHandler = () => {
    toggle();
    toggleImageUploader && toggleImageUploader();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} modalClassName="z-40">
      <Modal.Header className="!text-left text-heading-3 font-bold text-gray-800">
        <Text>productManagement.products.Details.addNew</Text>
      </Modal.Header>
      <Modal.Body className="grid grid-cols-2 gap-4 overflow-auto">
        <div
          className="flex-center flex h-[220px] cursor-pointer select-none flex-col gap-4 rounded-lg  bg-gray-100 text-primary"
          onClick={imageUploaderHandler}
        >
          <Icon icon="file-image" size="3x" className="text-primary" />
          <h5 className="text-primary">
            <Text>productManagement.products.Details.addNewPhoto</Text>
          </h5>
        </div>
        <FileUploader
          value={file}
          setValue={setFile}
          onUploadHandler={() => {
            onUploadHandler && onUploadHandler(file);
          }}
          getResponse={(data: any) => setFile(data)}
          loading={loading}
          type={assetTypes.PimProduct}
        />
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-between">
        <Button size="sm" onClick={toggle} variant="white">
          <Icon className="mr-2" icon={"times"} />
          <Text>global.buttons.close</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
