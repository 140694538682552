import {NotificationManager} from "common/notifications";

export const ErrorHandler = error => {
  let message = "";
  switch (error.response?.status || error.status) {
    case 403:
      break;
    case 404:
      message =
        `errors.${error.response?.data?.detail}` ||
        `errors.${error.response.data?.statusCode}` ||
        error.response?.data.title ||
        error.response?.data.message ||
        error.response.data?.statusText ||
        error.response.data ||
        "404";
      return NotificationManager.error(message, "errors.error");
    case 409:
      message =
        `errors.${error.response.data?.detail}` ||
        error.response.data?.statusText ||
        error.response.data ||
        error.response.data?.title ||
        "409";
      return NotificationManager.error(message, "errors.error");
    case 500:
      message = error.response.statusText;
      return NotificationManager.error(message, "errors.error");

    case 400:
      message = error.response.statusText;
      return NotificationManager.error(
        message,
        `errors.${error.response.data?.detail}` ||
          error.response?.data.title ||
          error.response?.data.message ||
          error.response.data?.statusText ||
          error.response.data ||
          "400",
      );
    case 418:
      message = error.response.title;
      return NotificationManager.error(message, error.response?.detail);

    default:
      message = "errors.error";
      return NotificationManager.error(message, "errors.error");
  }
};
