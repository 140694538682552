import {useEffect, useState} from "react";
import {useToggleState} from "hooks";
import {ServiceApi, URLS} from "services";
import {config, calculationLevelEnum} from "constant";
import {InfoSection, LeftSide} from "../common";

export default function Channel() {
  const [loading, setLoading] = useToggleState();
  const [data, setData] = useState<any>([]);
  const [selectedId, setSelectedId] = useState("");
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [params, setParams] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  });
  //Channels
  useEffect(() => {
    getSalesChannels();
  }, [params]);

  const getSalesChannels = () => {
    setLoading();
    const url = URLS.GET_SALES_CHANNEL_LIST;
    const config = {params};
    ServiceApi.get(url, config)
      .then(({data}) => {
        const clone = data?.items?.map((channel: any) => ({
          id: channel.saleChannelId,
          name: channel.title,
          code: channel.code,
        }));
        setData(clone);
        setLoading();
      })
      .catch(() => setLoading());
  };
  //UI
  return (
    <div className="flex gap-8">
      <LeftSide
        title="priceEngine.calculationLevel.channel"
        loading={loading}
        list={data}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
        setParams={setParams}
        setSelectedItem={setSelectedItem}
      />
      <div className="w-full space-y-4">
        {selectedId && (
          <InfoSection
            refId={selectedId}
            level={calculationLevelEnum.channel}
            refCode={selectedItem?.code}
            refTitle={selectedItem?.name}
          />
        )}
      </div>
    </div>
  );
}
