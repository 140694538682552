import {
  Button,
  Drawer,
  Icon,
  List,
  NoItems,
  SearchBox,
  Skeleton,
  Text,
} from "components";
import {dateFormats} from "constant";
import {useConverter} from "hooks";
import {Fragment, useContext, useEffect, useMemo, useState} from "react";
import {ServiceApi} from "services";
import {Basket} from "types";
import {SelectFromBasketContext} from ".";
type Data = Basket.Item;
//Basket
export default function Baskets() {
  const {convertAmount, convertDate} = useConverter();
  const {setBasket, goBack} = useContext(SelectFromBasketContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Data[]>([]);
  const [keyword, setKeyword] = useState<string | null>(null);
  const baskets = useMemo(() => {
    return data.filter(e =>
      [e.iln, e.internalTag, e.senderName, e.tag, e.price]
        .map(String)
        .map(e => e.toLowerCase())
        .some(e => e.includes(keyword?.toLowerCase() ?? "")),
    );
  }, [data, keyword]);

  const handleClick = (basket: Data) => {
    return () => setBasket(basket);
  };
  const getData = () => {
    setLoading(true);
    const url = "/productservice/api/iwofurnconfigurations/iwofurn-baskets";
    ServiceApi.post(url)
      .then(({data}) => {
        setData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(getData, []);
  return (
    <Fragment>
      <Drawer.Header className="space-y-4 border-b-0">
        <fieldset className="text-start">
          <legend>
            <Text>productManagement.products.allProducts.chooseBasket</Text>
          </legend>
        </fieldset>
        <SearchBox
          value={keyword}
          onSubmit={setKeyword}
          variant="gray"
          totalItems={baskets?.length}
          onReload={getData}
        />
      </Drawer.Header>
      <Drawer.Body className="space-y-4">
        {loading ? (
          [...Array(10).keys()].map(key => <Skeleton.Input key={key} />)
        ) : !baskets?.length ? (
          <NoItems />
        ) : (
          <List>
            {baskets.map(e => (
              <List.Item
                key={e.internalTag}
                className="flex items-center gap-6"
                onClick={handleClick(e)}
              >
                <Icon icon="cart-shopping" className="text-primary-active" />
                <div className="flex-1 space-y-3">
                  <h6 className="block w-full truncate text-heading-6 font-semibold text-gray-800">
                    <Text>
                      productManagement.products.allProducts.basketTitle
                    </Text>{" "}
                    - {convertDate(e.stamp, dateFormats.onlyDate)}
                  </h6>
                  <p className="text-body-2 text-gray-500">
                    <Text>productManagement.products.allProducts.price</Text>:{" "}
                    {convertAmount(e.price)}
                  </p>
                  {/* <p className="text-body-2 text-gray-500">
                    <Text>
                      productManagement.products.allProducts.purchasePrice
                    </Text>
                    : {convertAmount(e.purchasePrice)}
                  </p> */}
                </div>
                <p className="self-end text-body-2 text-gray-500">#{e.tag}</p>
                {/* <p className="text-body-2 text-gray-500 self-end">
                  <Icon icon="calendar" className="text-base text-gray-700" />{" "}
                  <Text>productManagement.products.allProducts.createdAt</Text>:{" "}
                  <span className="text-gray-700">
                    {convertDate(e.stamp, dateFormats.onlyDate)}
                  </span>
                </p> */}
              </List.Item>
            ))}
          </List>
        )}
      </Drawer.Body>
      <Drawer.Footer className="flex items-center gap-6">
        <Button type="button" variant="light" size="sm" onClick={goBack}>
          <Text>productManagement.products.allProducts.backBtn</Text>
        </Button>
      </Drawer.Footer>
    </Fragment>
  );
}
