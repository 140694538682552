import {useState, useEffect} from "react";
import {NotificationManager} from "common/notifications";
import {
  Accordion,
  Button,
  Drawer,
  Icon,
  Image,
  Text,
  Wrapper,
  InputGroup,
  SelectLang,
  Skeleton,
  DeleteModal,
} from "components";
import {
  useSelector,
  useToggleState,
  useDataState,
  useTitleTranslations,
  usePermission,
} from "hooks";
import {flagCountry} from "constant";
import {ServiceApi, URLS} from "services";
import AttributeGroups from "./Edit/AttributeGroups";

type drawerProps = {
  isOpen: boolean;
  toggle: () => void;
  getProductFamilies: () => void;
  selected: string;
};

export default function EditDrawer({
  isOpen,
  toggle,
  getProductFamilies,
  selected,
}: drawerProps) {
  const removePermission = usePermission("PS_DeletePimProductFamily");
  const productFamily = useSelector(s => s.productFmilies);
  const profile = useSelector(s => s.profile);
  const [lang, setLang] = useState<string>("de");
  const [data, setData, setBaseData, isChanged, base] = useDataState<any>({});
  const companySetting = useSelector(s => s.companySetting);
  const [loading, setLoading] = useToggleState();
  const [langItems, setLangItems] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [deleteModal, setDeleteModal] = useToggleState();
  const [deleteLoading, setDeleteLoading] = useToggleState();
  const {checkTranslations} = useTitleTranslations();

  useEffect(() => {
    setBaseData(JSON.parse(JSON.stringify(productFamily)));
  }, [productFamily]);

  useEffect(() => {
    if (companySetting)
      setLang(companySetting?.defaultDashboardLanguage?.toLocaleLowerCase());
  }, [companySetting]);

  useEffect(() => {
    setBaseData(JSON.parse(JSON.stringify(productFamily)));
  }, [isOpen]);

  useEffect(() => {
    let arr: any = [];
    if (companySetting)
      companySetting?.dashboardLanguages?.forEach((item: any) => {
        arr.push({id: item, name: item});
      });
    setLangItems(arr);
  }, [profile, companySetting]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeLanguage = (lang: any) => {
    setLang(lang);
  };

  const onChangeHandler = (value: any) => {
    const isAvailable = data?.translates?.filter(
      (tr: any) => tr.language.toLowerCase() === lang,
    );
    let clone = [...data?.translates];

    if (isAvailable.length === 0) {
      clone.push({
        labelTitle: value,
        language: lang,
        description: "",
      });
    } else {
      data?.translates?.map((tr: any, index: number) => {
        if (tr.language.toLowerCase() === lang) {
          clone[index].labelTitle = value;
        }
      });
    }
    setData({...data, translates: clone});
  };

  const getTranslatedName = () => {
    const translate = data?.translates.filter(
      (tr: any) => tr.language.toLowerCase() === lang,
    );
    return translate[0]?.labelTitle;
  };

  const submitHandler = () => {
    const validTranslates = checkTranslations(data?.translates);
    if (validTranslates) {
      setLoading();
      setLoadingButton(true);
      const url = URLS.PUT_DELETE_PIM_PRODUCT_FAMILY_URL(
        data?.pimProductFamilyId,
      );
      const body = {
        icon: data?.icon,
        translates: validTranslates,
      };
      ServiceApi.put(url, body)
        .then(({data}) => {
          NotificationManager.success(
            "global.toast.edit-msg",
            "global.toast.edit-title",
          );
          setLoading();
          getProductFamilies();
          setLoadingButton(false);
        })
        .catch(err => {
          setLoadingButton(false);
          setLoading();
        });
    }
  };

  const onDeleteHandler = () => {
    setDeleteLoading();
    const url = URLS.DELETE_PRODUCT_FAMILY(productFamily?.pimProductFamilyId);
    ServiceApi.delete(url)
      .then(() => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title",
        );
        getProductFamilies();
        setDeleteLoading();
        setDeleteModal();
      })
      .catch(err => setLoading());
  };

  return (
    <>
      <Drawer isOpen={isOpen} toggle={toggle} size="xl">
        <Drawer.Menu className="pr-0">
          <Drawer.Header className="pr-4 pt-6">
            <div className="flex items-center justify-between">
              <h2 className="text-heading-2 text-gray-800">
                <Text>global.edit</Text>
              </h2>
              <div className="space-x-4">
                <Button size="sm" variant={"light"} onClick={() => toggle()}>
                  <Icon icon="times" className="mr-1" />
                  <Text>global.buttons.close</Text>
                </Button>
                {data?.parentId && removePermission && (
                  <Button
                    variant={"danger"}
                    light
                    size="sm"
                    onClick={() => {
                      setDeleteModal();
                    }}
                  >
                    <Icon icon={"trash"} className="mr-2" />
                    <Text>
                      productManagement.masterData.productFamilies.deleteSubFamily
                    </Text>
                  </Button>
                )}
              </div>
            </div>
          </Drawer.Header>
          <Drawer.Body>
            <Wrapper>
              <Wrapper.Header>
                <div className="flex items-center justify-between text-heading-2 font-semibold">
                  <Text>
                    productManagement.masterData.productFamilies.basicInfo
                  </Text>
                  <SelectLang
                    value={lang}
                    items={langItems}
                    onChangeLanguage={onChangeLanguage}
                  />
                </div>
              </Wrapper.Header>
              <Wrapper.Body className="mt-2 grid grid-cols-2 space-x-4 rounded-md shadow-sm">
                {loading ? (
                  <>
                    <Skeleton.Input />
                    <Skeleton.Input />
                  </>
                ) : (
                  <>
                    <InputGroup
                      value={data?.translates && getTranslatedName()}
                      setValue={e => onChangeHandler(e)}
                      label="productManagement.masterData.productFamilies.familyName"
                      flag={flagCountry[lang]}
                    />
                    <InputGroup
                      value={data?.code}
                      label="productManagement.masterData.productFamilies.familyCode"
                      disabled
                      readOnly
                    />
                  </>
                )}
              </Wrapper.Body>
            </Wrapper>
            {isOpen && (
              <AttributeGroups
                id={data?.pimProductFamilyId}
                selected={selected}
              />
            )}
          </Drawer.Body>
          <Drawer.Footer className="flex justify-end">
            <Button
              type="button"
              variant="primary"
              onClick={submitHandler}
              disabled={!isChanged}
              loading={loadingButton}
            >
              <Text>global.buttons.saveChanges</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Drawer>
      <DeleteModal
        isOpen={deleteModal}
        loading={deleteLoading}
        toggle={setDeleteModal}
        onConfirm={onDeleteHandler}
        selected={{name: productFamily.code}}
      />
    </>
  );
}
