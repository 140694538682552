import {
  Button,
  Drawer,
  Form,
  Icon,
  InputGroup,
  Text,
  WithPermission,
} from "components";
import { FormEvent, useEffect, useRef, useState } from "react";
import { rules } from "constant";
import { ProductLabelTemplate } from "types";

export default function AddMetaData(props: {
  isOpen: boolean;
  toggle: () => void;
  onAdd: (data: { key: string, value: string }) => void;
  addedMetaData: ProductLabelTemplate.KeyValue[];
}) {
  const formRef = useRef<any>();
  const [data, setData] = useState<{ key: string, value: string }>();
  const duplicateKey = data?.key && props.addedMetaData.some((e) => e.key === data.key);

  const submit = (e?: FormEvent) => {
    formRef.current.submitHandler(e, data);
  };

  const onSubmitForm = (formData: any) => {
    props.onAdd(formData);
    props.toggle();
  }

  const onChangeHandler = (key: any) => {
    return (value: any) => setData((p: any) => ({ ...p, [key]: value }));
  };

  useEffect(() => setData({ key: '', value: '' }), [props.isOpen])

  return (
    <Drawer isOpen={props.isOpen} toggle={props.toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pr-4 pt-6">
          <div className="flex items-center justify-between">
            <h2 className="text-heading-2 text-gray-800">
              <Text>productManagement.masterData.productLabelTemplate.addMetaData</Text>
            </h2>
            <div className="flex items-center justify-between">
              <Button
                type="button"
                variant="light"
                className="ml-2"
                onClick={() => props.toggle()}
              >
                <Icon className="mr-2" icon={"times"} />
                <span className="text-body-2">
                  {" "}
                  <Text>global.buttons.close</Text>
                </span>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body>
          <Form
            ref={formRef}
            onSubmit={onSubmitForm}
            className="mt-8 space-y-5 pr-8"
            id={"create-drawer"}
          >
            <InputGroup
              label="productManagement.masterData.productLabelTemplate.key"
              value={data?.key}
              setValue={onChangeHandler("key")}
              rules={rules.required}
              ariaLabel="cy-product-label-template-key"
            />
            {duplicateKey &&
              <p className="mt-1 text-xs text-danger">
                <i className="bi bi-info-circle mr-1" />
                <Text>productManagement.masterData.productLabelTemplate.duplicateKey</Text>
              </p>
            }
            <InputGroup
              label="productManagement.masterData.productLabelTemplate.value"
              value={data?.value}
              setValue={onChangeHandler("value")}
              rules={rules.required}
              ariaLabel="cy-product-label-template-value"
            />
          </Form>
        </Drawer.Body>
        <WithPermission
          permissions={["SC_UpdateSaleServiceDocumentTemplate"]}
        >
          <Drawer.Footer className="flex justify-between">
            <Button
              variant="white"
              type="button"
              onClick={() => props.toggle()}
            >
              <Text>global.buttons.cancel</Text>
            </Button>
            <Button
              type="button"
              variant="primary"
              onClick={submit}
              aria-label="cy-product-label-template-metadata-add"
              disabled={!!duplicateKey}
            >
              <Text>productManagement.masterData.productLabelTemplate.add</Text>
            </Button>
          </Drawer.Footer>
        </WithPermission>
      </Drawer.Menu>
    </Drawer>
  );
}
