import { useRef, useState } from "react";
import { Button, Modal, Text, InputGroup } from "components";
import {
  CropperRef,
  Cropper,
  CropperPreview,
  CropperState,
  CropperImage,
  CropperTransitions,
  ImageRestriction,
} from "react-advanced-cropper";
import {
  FlipHorizontal,
  FlipVertical,
  Rotate,
  RotateClockwise,
} from "tabler-icons-react";
import "react-advanced-cropper/dist/style.css";

const EditImageModal = (props: any) => {
  const cropperRef = useRef<CropperRef | null>(null);
  const [state, setState] = useState<{
    cropperState: CropperState | null;
    cropperImage: CropperImage | null;
    cropperTransition: CropperTransitions | null;
    saving: boolean;
    aspectRatio: number;
  }>({
    cropperState: null,
    cropperImage: null,
    cropperTransition: null,
    saving: false,
    aspectRatio: 0,
  });

  const updateState = (name: string, value: any) => {
    setState(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const onChange = (cropper: CropperRef) => {
    setState(prev => ({
      ...prev,
      cropperState: cropper.getState(),
      cropperImage: cropper.getImage(),
    }));
  };

  const rotate = (angle: number) => {
    if (cropperRef.current) {
      cropperRef?.current.rotateImage(angle);
    }
  };

  const flip = (horizontal: boolean, vertical: boolean) => {
    if (cropperRef.current) {
      cropperRef.current.flipImage(horizontal, vertical);
    }
  };

  const setAspectRatio = (ratio?: number) => {
    cropperRef?.current?.reset();
    if (!ratio) {
      updateState("aspectRatio", undefined);
    } else {
      updateState("aspectRatio", ratio);
    }
  };

  const handleCrop = () => {
    const canvas = cropperRef?.current?.getCanvas();
    if (canvas) {
      updateState("saving", true);
      const form = new FormData();
      canvas.toBlob(blob => {
        if (blob) {
          form.append("formFile", blob);
          updateState("saving", false);
          props.onCrop?.(form);
        }
      });
    }
  };

  const onTransitionsChange = (cropper: CropperRef) => {
    updateState("cropperTransition", cropper.getTransitions());
  };

  return (
    <Modal isOpen={props.isOpen} modalClassName="!z-[110]" size={"xl"}>
      <Modal.Header className="flex flex-row">
        <h6 className="text-heading-2 text-gray-800">
          <Text>global.editImage</Text>
        </h6>
      </Modal.Header>
      <Modal.Body className="max-h-100 overflow-auto">
        <div className="grid grid-cols-3 gap-8">
          <Cropper
            ref={cropperRef}
            src={props.image}
            stencilProps={{
              grid: true,
              aspectRatio: state.aspectRatio,
            }}
            imageRestriction={ImageRestriction.fitArea}
            onChange={onChange}
            onTransitionsStart={onTransitionsChange}
            onTransitionsEnd={onTransitionsChange}
            className="col-span-2 rounded-xl"
          />
          <div className="flex flex-col space-y-8 rounded-xl">
            <CropperPreview
              image={state.cropperImage}
              state={state.cropperState}
              transitions={state.cropperTransition}
              className="h-60 rounded-xl"
            />
            <div className="flex space-x-4">
              <InputGroup
                label="width"
                readOnly
                type="text"
                className="inputbox w-100"
                value={
                  state.cropperState?.coordinates?.width?.toFixed(0) + " px" ??
                  ""
                }
              />
              <InputGroup
                label="height"
                readOnly
                type="text"
                className="inputbox w-100"
                value={
                  state.cropperState?.coordinates?.height?.toFixed(0) + " px" ??
                  ""
                }
              />
            </div>
            <div className="grid grid-cols-4 gap-4">
              <Button type="button" light onClick={() => rotate(-90)}>
                <Rotate size="22" strokeWidth={1.5} className="rotate-180" />
              </Button>
              <Button type="button" light onClick={() => rotate(90)}>
                <RotateClockwise
                  size="22"
                  strokeWidth={1.5}
                  className="rotate-180"
                />
              </Button>
              <Button type="button" light onClick={() => flip(true, false)}>
                <FlipVertical size="22" strokeWidth={1.5} />
              </Button>
              <Button type="button" light onClick={() => flip(false, true)}>
                <FlipHorizontal size="22" strokeWidth={1.5} />
              </Button>
              <Button
                type="button"
                light
                onClick={() => setAspectRatio(16 / 9)}
              >
                16:9
              </Button>
              <Button type="button" light onClick={() => setAspectRatio(3 / 4)}>
                3:4
              </Button>
              <Button type="button" light onClick={() => setAspectRatio(1)}>
                1:1
              </Button>
              <Button type="button" light onClick={() => setAspectRatio()}>
                free
              </Button>
            </div>
            <div className="flex justify-between">
              <Button
                type="button"
                size="sm"
                className="mr-2"
                onClick={() => {
                  props.toggle();
                }}
                variant="white"
              >
                <Text>global.cancel</Text>
              </Button>
              <Button
                aria-label="cy-upload-image-save"
                type="button"
                size="sm"
                onClick={handleCrop}
                loading={props.loading}
              >
                <Text>global.save</Text>
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditImageModal;
