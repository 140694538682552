import {LevelUnitList} from "compositions";
import {InfoSectionContext} from "../info-section";
import {useContext, useRef} from "react";
import AssignCustomUnit from "../assign-custom-unit";
import {useToggleState} from "hooks";

export default function Units() {
  const {refId, level, refCode, refTitle, setLevelData} =
    useContext(InfoSectionContext);
  const [isOpen, toggle] = useToggleState();

  const unitRef = useRef(null);

  return (
    <>
      <LevelUnitList
        refId={refId}
        level={level}
        refCode={refCode}
        refTitle={refTitle}
        setLevelData={setLevelData}
        onClickHandler={toggle}
        ref={unitRef}
      />
      <AssignCustomUnit
        isOpen={isOpen}
        toggle={toggle}
        refId={refId}
        level={level}
        refCode={refCode}
        refTitle={refTitle}
        test={unitRef}
      />
    </>
  );
}
