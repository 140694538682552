import {Button, Drawer, Icon, Text, InputGroup} from "components";
import {useEffect, FormEvent} from "react";
import {Departments} from "types";
import {rules} from "constant";
import {useDataState, useToggleState} from "hooks";
import {ServiceApi, URLS} from "services";
import SelectTeamLead from "./SelectTeamLead";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  details?: Departments.DepartmentDetails;
  departmentId?: string;
  getDetails: () => void;
  teamLead: any;
}

export default function EditGeneralInfo({
  isOpen,
  toggle,
  details,
  departmentId,
  getDetails,
  teamLead,
}: Props) {
  const [loading, setLoading] = useToggleState();
  const [data, setData, setBaseData, isChanged] = useDataState<any>({});
  const [lead, setTeamLead, setBaseTeamLead, isChangedTeamLead] =
    useDataState<any>({});
  const [isOpenSelectTeamLead, toggleSelectTEamLead] = useToggleState();

  useEffect(() => {
    if (details) setBaseData(details);
  }, [details]);

  useEffect(() => {
    if (!isOpen) {
      setBaseTeamLead(teamLead);
      setBaseData(details);
    }
  }, [isOpen]);

  useEffect(() => {
    setBaseTeamLead(teamLead);
  }, [teamLead]);

  const onChangeHandler = (key: keyof Departments.DepartmentItemType) => {
    return (value: any) => setData((p: any) => ({...p, [key]: value}));
  };

  const onSubmitHandler = () => {
    setLoading();
    const url = URLS.PUT_DEPARTMENT_DETAILS(departmentId);
    const body = {
      storeId: data.storeId,
      leadEmployeeId: lead?.employeeId,
      title: data.title,
      shortDescription: data.shortDescription,
      emailAddress: data.emailAddress,
    };
    ServiceApi.put(url, body)
      .then(() => {
        setLoading();
        getDetails();
        toggle();
      })
      .catch(() => setLoading());
  };

  const onDeleteTeamLead = () => {
    setTeamLead(undefined);
  };

  const submit = (e: FormEvent, data?: any, condition?: boolean) => {
    e?.preventDefault();
    const nodeTarget = document.getElementById("EditForm");
    // @ts-ignore: Unreachable code error
    const formControls = nodeTarget?.querySelectorAll(".form-control");
    // @ts-ignore: Unreachable code error
    const validArr = [...formControls].map(
      // @ts-ignore: Unreachable code error
      item => item.onValid && item.onValid(),
    );
    if (!validArr.every(Boolean)) return;
    onSubmitHandler();
  };

  return (
    <>
      <Drawer isOpen={isOpen} toggle={toggle} size={"lg"}>
        <Drawer.Menu>
          <Drawer.Header className="flex items-center justify-between ">
            <span>
              <Text>company.stores.editGeneralInfo</Text>
            </span>
            <Button variant={"light"} size="sm" onClick={toggle}>
              <Icon icon="close" /> <Text>global.buttons.close</Text>
            </Button>
          </Drawer.Header>
          <Drawer.Body className="space-y-6">
            <form id="EditForm" className="space-y-6">
              <InputGroup
                value={data?.title}
                setValue={onChangeHandler("title")}
                label={"company.stores.titel"}
                rules={rules.required}
              />
              <InputGroup
                value={data?.emailAddress}
                setValue={onChangeHandler("emailAddress")}
                label={"company.stores.emailAddress"}
                rules={rules.emailRequired}
              />
              <InputGroup
                value={data?.shortDescription}
                setValue={onChangeHandler("shortDescription")}
                label={"company.stores.shortDescription"}
                as={"short-textarea"}
              />
            </form>
            <span className="block text-heading-6 font-normal text-gray-800">
              <Text>company.stores.teamLead</Text>
            </span>
            <Button light onClick={toggleSelectTEamLead}>
              <Icon icon="plus" /> <Text>company.stores.assignTeamLead</Text>
            </Button>
            {lead && (
              <div className="flex justify-between rounded-lg bg-white p-4 shadow-card">
                <div className="flex flex-col gap-2">
                  <span className="text-heading-6 font-semibold">
                    {lead?.firstName} {lead?.lastName}
                  </span>
                  <span className="text-body-2 text-gray-500">
                    # {lead?.employeeNumber}
                  </span>
                  <span className="text-body-2 text-gray-500">
                    <Icon icon="envelope" /> {lead?.emailAddress}
                  </span>
                </div>
                <Button
                  light
                  variant={"danger"}
                  size="sm"
                  onClick={onDeleteTeamLead}
                >
                  <Icon icon="trash" />
                </Button>
              </div>
            )}
          </Drawer.Body>
          <Drawer.Footer className="flex justify-between">
            <Button size="sm" variant={"light"} onClick={toggle}>
              <Text>global.buttons.cancel</Text>
            </Button>
            <Button
              size="sm"
              disabled={
                [isChanged, isChangedTeamLead].every(item => !item) || !lead
              }
              onClick={submit}
              loading={loading}
            >
              <Text>global.buttons.submit</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Drawer>
      <SelectTeamLead
        isOpen={isOpenSelectTeamLead}
        toggle={toggleSelectTEamLead}
        setTeamLead={setTeamLead}
      />
    </>
  );
}
