export const global = {
  builtIn: "Built-In",
  noAccessText: "You have no access to dashboard",
  pleaseLoginAccount: "Please login with another account",
  lgoinToDashboard: "Login to dashboard with another account",
  items: "Items",
  home: "Home",
  details: "Details",
  none: "None",
  user: "User",
  admin: "Admin",
  addNew: "Add new",
  uploadFile: "Upload File",
  dragDropFile: "Drag & Drop files here",
  required: "Required",
  default: "Default",
  standard: "Standard",
  editImage: "Edit Image",
  changeLogo: "Change Logo",
  fromCamera: "From camera",
  chooseFile: "Choose file",
  uploadNewImage: "Upload New Image",
  save: "save",
  cancel: "cancel",
  discard: "discard",
  notRequired: "Not Required",
  noItems: "There is no data",
  noItemsElse: "Please try something else",
  areYouSureYouWantToDelete: "Are you sure you want to Delete",
  youCantUndoThis: "You can't undo this",
  validation: "validation",
  all: "All",
  from: "From",
  to: "To",
  edit: "Edit",
  select: "Select",
  noName: "No name in",
  days: "Days",
  promptMassage: "Are your sure you want discard your changes?",
  noData: "No Data",
  warning: "Warning",
  placeholders: {
    search: "Search",
  },
  buttons: {
    view: "View",
    discard: "Discard",
    delete: "Delete",
    saveChanges: "Save changes",
    cancel: "Cancel",
    close: "Close",
    ImNotSure: "I’m not sure",
    next: "Next",
    back: "Back",
    submit: "Submit",
    create: "Create",
    copy: "Copy",
    details: "Details",
    edit: "Edit",
    actions: "Actions",
    invite: "Invite",
    disable: "Disable",
    enable: "Enable",
    selectAll: "Select All",
    deselectAll: "Deselect All",
    setAsCoverPhoto: "Set as cover photo",
    coverPhoto: "Cover Photo",
    approve: "Approve",
    filters: "Filters",
    resetFilters: "Reset Filters",
    reset: "Reset",
  },
  locations: {
    street: "Street",
    houseNumber: "House Number",
    postalCode: "Postal Code",
    city: "City",
    country: "Country",
    state: "State",
    location: "Location",
    locationOnMap: "Location On Map",
    address: "Address",
    searchPlaceHolder: "Search address",
    name: "Name",
    invalidAddress: "Address is not valid",
    changedAddress: "Address has been changed please check before submitting",
    addressValidation: "Address Validation",
  },
  toast: {
    "delete-msg": "Item Deleted with Success",
    "delete-title": "Item Deleted",
    "add-msg": "Item Added with Success",
    "add-title": "Item Added",
    "edit-msg": "Item Updated with Success",
    "edit-title": "Item Updated",
    "copy-msg": "Item Copy with Success",
    "copy-title": "Item Copy",
    "start-phase-msg": "Start Phase with Success",
    "start-phase-title": "Start Phase",
    "change-phase-msg": "Change Phase with Success",
    "change-phase-title": "Change Phase",
    "close-phase-msg": "close Phase with Success",
    "close-phase-title": "close Phase",
    "sold-msg": "sold with Success",
    "sold-title": "sold",
    "invite-msg": "Item Invite with Success",
    "invite-title": "Item Invite",
    "publish-msg": "Item publish with Success",
    "publish-title": "Item publish",
    "unpublish-msg": "Item unpublish with Success",
    "unpublish-title": "Item unpublish",
    "import-msg": "Item import with Success",
    "import-title": "Item import",
    "duplicate-msg": "Product duplicate with Success DE",
    "duplicate-title": "Product Duplicate DE",
    "add-default-language-value":
      "Please Add Value For dashboard Default Language",
    "print-request-sent": "Print request sent successfully",
    "print-request-sent-title": "request sent",
  },
  types: {
    infoPageLayout: {
      bannerOne: "Banner one",
      bannerTwo: "Banner two",
      bannerThree: "Banner three",
      introduction: "Introduction",
      products: "Products",
      scrollbar: "Scrollbar",
    },
    position: {
      banner: "Banner",
      carousel: "Carousel",
    },
    mobileAppLink: {
      none: "None",
      infoPage: "Info Page",
      externalLink: "External Link",
      channelProduct: "Channel Product",
      button: "Button",
    },
    program: {
      none: "None",
      manual: "Manual",
      configurable: "Configurable",
    },
    checkList: {
      pimProduct: "pimProduct",
      supplier: "supplier",
      supplierProgram: "supplierProgram",
      order: "order",
    },
    checkListCompleteness: {
      notStarted: "not started",
      inProcess: "in process",
      hasAProblem: "has a problem",
      completed: "completed",
    },
  },
  channelMessage: {
    status: "Status",
    updatedAt: "Updated at",
    channel: "Channel",
    createdAt: "Created at",
    data: "Data",
    outData: "outData",
    details: "Details",
  },
  gender: {
    rather: "Rather not say",
    male: "Male",
    female: "Female",
  },
  dayTypes: {
    workDay: "Work Day",
    calendarDay: "Calendar Day",
  },
};
