export const generalDiscount = {
  addGeneralDiscount: "Add General Discount",
  value: "Value",
  basicInfo: "Basic Info",
  discountValue: "Discount Value",
  title: "Title",
  code: "Code",
  shortDescription: "Short Description",
  from: "From",
  to: "To",
  discountValueLabel: "Discount Value(%)",
};
