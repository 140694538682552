import {
  Drawer,
  Text,
  Button,
  Icon,
  SearchBox,
  Skeleton,
  Pagination,
} from "components";
import {useToggleState} from "hooks";
import {useEffect, useState} from "react";
import {ServiceApi, URLS} from "services";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  dispatch: (channel: any) => void;
  dispatchParams: (channel: any) => void;
  channelCode: any;
}

export default function OrderList({
  isOpen,
  toggle,
  channelCode,
  dispatch,
  dispatchParams,
}: Props) {
  const [loading, toggleLoading] = useToggleState();
  const [list, setList] = useState([]);
  const [params, setParams] = useState<any>({
    pageNumber: 1,
    pageSize: 9,
    keyword: "",
  });
  const [orders, setOrders] = useState<any>();

  const getOrders = () => {
    toggleLoading();
    const url = URLS.GET_ORDER_LIST;
    const config = {params: {...params, channelCode}};
    ServiceApi.get(url, config)
      .then(({data}) => {
        setOrders(data);
        toggleLoading();
      })
      .catch(() => toggleLoading());
  };

  useEffect(() => {
    if (isOpen && channelCode) {
      setOrders([]);
      getOrders();
    }
  }, [isOpen, params]);

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header>
          <div className="flex items-center justify-between">
            <span className="text-heading-2 font-semibold">
              <Text>Orders</Text>
            </span>
            <div className="flex gap-x-2">
              <Button size="sm" variant={"light"} onClick={toggle}>
                <Icon icon="times" className="mr-1" />
                <Text>productManagement.products.allProducts.close</Text>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          <SearchBox
            value={params?.keyword}
            onSubmit={val =>
              setParams({pageNumber: 1, pageSize: 10, keyword: val})
            }
            totalItems={orders?.totalItems}
            variant="gray"
            disabled={loading}
          />
          {loading ? (
            [...Array(6)].map(() => <Skeleton.List />)
          ) : (
            <>
              {orders?.items?.map((order: any) => {
                return (
                  <div
                    className="cursor-pointer items-center space-y-2 rounded-xl p-4 shadow-items hover:bg-primary-light"
                    onClick={() => {
                      dispatch?.(order);
                      dispatchParams((params: any) => ({
                        ...params,
                        orderId: order?.id,
                      }));
                      toggle();
                    }}
                  >
                    <h5 className="text-gray-800">{order.title}</h5>
                    <p className="text-xs">number : {order.number}</p>
                  </div>
                );
              })}
            </>
          )}
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
}
