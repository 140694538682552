import {NotificationManager} from "common/notifications";
import {
  Breadcrumb,
  Button,
  Footer,
  Form,
  InputGroup,
  MultiSelect,
  Skeleton,
  Text,
  WithPermission,
  Wrapper,
} from "components";
import {withPermission} from "hoc";
import {useDataState, usePermission} from "hooks";
import {cloneDeep, isEmpty} from "lodash";
import {useEffect, useRef, useState} from "react";
import {ServiceApi, URLS} from "services";

function PreparationPhase() {
  const updatePermission = usePermission("SC_UpsertPreparationPhase");
  const controller = new AbortController();
  const methodRef = useRef<"put" | "post">();
  const [loadingButton, setLoadingButton] = useState(false);
  const [data, setData, setBaseData, isChanged] = useDataState<any>({});
  const [loadingPage, setLoadingPage] = useState(true);
  const [checkListItems, setCheckListItems] = useState([]);
  const [params] = useState({
    pageNumber: 1,
    pageSize: 10,
    keyword: "",
  });
  useEffect(() => {
    getCheckList();
    getData();
    return () => {
      controller.abort();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const getCheckList = () => {
    setCheckListItems([]);
    const url = URLS.GET_CHECK_LIST_URL;
    const config = {params};
    ServiceApi.get(url, {...config, signal: controller.signal}).then(res => {
      if (res) {
        const result = res?.data?.items?.map((e: any) => ({
          id: e.id,
          name: e.label,
        }));
        setCheckListItems(result);
      }
    });
  };
  const getData = async () => {
    setLoadingPage(true);
    ServiceApi.get(URLS.GET_PREPARATION_PHASE_URL)
      .then(({data}) => {
        methodRef.current = !isEmpty(data) ? "put" : "post";
        let result = cloneDeep(data);
        if (result.checkListItems) {
          result.checkListItemIds = result?.checkListItems?.map(
            (e: any) => e.checkListItemId,
          );
        }
        setBaseData(result);
      })
      .finally(() => {
        setLoadingPage(false);
      });
  };

  const submit = () => {
    const method = methodRef.current;
    if (!method) return;
    setLoadingButton(true);
    const url = URLS.PUT_PREPARATION_PHASE_URL;
    const body = cloneDeep(data);
    body.checkListItemIds = body.checkListItemIds ? body.checkListItemIds : [];
    body.checkListItemIds = body.checkListItemIds.map((e: any) => e.id);
    delete body.checkListItems;
    ServiceApi[method](url, body)
      .then(() => {
        getData();
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
      })
      .finally(() => {
        setLoadingButton(false);
      });
  };

  return (
    <Form onSubmit={submit} className="relative space-y-6">
      <h3 className="mr-auto text-heading-3">
        <Breadcrumb />
      </h3>
      <Wrapper>
        <Wrapper.Body className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-8">
          <div className="col-start-1 col-end-2">
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="salesManagment.sellOffSales.preparationPhase.name"
                value={data.title}
                setValue={title => setData((p: any) => ({...p, title}))}
                disabled={!updatePermission}
              />
            )}
          </div>
          <div className="col-start-1 col-end-2">
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                as="short-textarea"
                label="salesManagment.sellOffSales.preparationPhase.shortDescription"
                value={data.description}
                setValue={description =>
                  setData((p: any) => ({...p, description}))
                }
                disabled={!updatePermission}
              />
            )}
          </div>
          <div className="col-start-1 col-end-2">
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <MultiSelect
                label="salesManagment.sellOffSales.preparationPhase.checkListItems"
                items={checkListItems}
                value={data?.checkListItemIds}
                setValue={checkListItemIds => {
                  return setData((p: any) => ({
                    ...p,
                    checkListItemIds,
                  }));
                }}
              />
            )}
          </div>
        </Wrapper.Body>
      </Wrapper>
      <WithPermission permissions={["SC_UpsertPreparationPhase"]}>
        <Footer show={!isChanged}>
          <Button type="button" onClick={getData} variant="white">
            <Text>global.buttons.discard</Text>
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={!isChanged}
            loading={loadingButton}
          >
            <Text>global.buttons.saveChanges</Text>
          </Button>
        </Footer>
      </WithPermission>
    </Form>
  );
}
export default withPermission(PreparationPhase, ["SC_GetPreparationPhase"]);
