import {Icon, Dropdown} from "components";
import {useMsal} from "@azure/msal-react";
import {getLocalIdToken} from "services/service-api";
import {useNavigate} from "react-router";

const Profile = ({profile}: any) => {
  const {instance, accounts} = useMsal();
  const navigate = useNavigate();
  const logoutHandler = async () => {
    const token = getLocalIdToken();
    const currentAccount = instance.getAccountByHomeId(
      accounts[0].homeAccountId,
    );
    await instance.logoutRedirect({
      idTokenHint: token.secret,
      account: currentAccount,
      postLogoutRedirectUri: "/",
    });
    localStorage.clear();
  };
  return (
    <Dropdown onSelect={() => console.log("")}>
      <Dropdown.Toggle
        as="button"
        className="rounded-md bg-gray-200 p-3 [&>i]:hidden"
      >
        <div>
          <Icon icon="user" size="lg" />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="min-w-fit">
        <div className="flex border-b border-gray-200 p-4">
          <div className="flex h-[48px] w-[48px] items-center justify-center rounded-md bg-gray-300 p-2">
            <Icon icon="user" size="lg" />
          </div>
          <div className="flex h-[48px] flex-col justify-between pl-2">
            <p className="h5 mb-[10px] text-gray-700">
              {profile.firstName} {profile.lastName}
            </p>
            <p className="text-body-2 font-medium text-gray-400">
              {profile.emailAddress}
            </p>
          </div>
        </div>
        <div className="px-4 pb-4 pt-2">
          <ul className="text-gray-700">
            <li
              onClick={() => {
                navigate(`/profile/${profile?.userId}`);
              }}
              className="h6 cursor-pointer rounded-lg px-4 py-2 hover:bg-primary-light hover:text-primary"
            >
              Profile
            </li>
            <li
              onClick={logoutHandler}
              className="h6 cursor-pointer rounded-lg px-4 py-2 hover:bg-primary-light hover:text-primary"
            >
              Sign out
            </li>
          </ul>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Profile;
