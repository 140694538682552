import { createSlice } from "@reduxjs/toolkit";

const companySetting = createSlice({
    name: "companySetting",
    initialState: {} as any,
    reducers: {
        setCompanySetting: (state, action) => {
            return action.payload;
        },
    },
});

export const { setCompanySetting } = companySetting.actions;
export default companySetting.reducer;