import { NotificationManager } from "common/notifications";
import { Button, Form, Icon, InputGroup, Modal, SelectLang, Text, Toggler } from "components";
import { flagCountry, rules } from "constant";
import { useDataState, useSelector } from "hooks";
import { FormEvent, useEffect, useRef, useState } from "react";
import { ServiceApi, URLS } from "services";

type createModalType = {
  isOpen: boolean;
  measurementFamilyId?: string;
  toggle: () => void;
  getData: () => void;
};

export default function CreateModal(props: createModalType) {
  const [data, setData] = useDataState<any>({});
  const [loadingButton, setLoadingButton] = useState(false);
  const [langItems, setLangItems] = useState([]);
  const [langSelected, setLangSelected] = useState("de");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const companySetting = useSelector((s) => s.companySetting);
  const formRef = useRef<any>();

  useEffect(() => {
    if (props.isOpen) {
      setName("")
      setDescription("")
      let arr: any = [];
      let translatesNew: any = [];
      companySetting?.contentLanguages?.forEach((item: any) => {
        arr.push({ id: item, name: item })
        translatesNew.push({ labelTitle: "", language: item })
      })
      setData({
        symbol: "",
        isDefault: false,
        translates: translatesNew,
        code: "",
      })
      setLangItems(arr)
      setLangSelected(companySetting.defaultContentLanguage)
    }
  }, [props.isOpen])// eslint-disable-line react-hooks/exhaustive-deps

  const onSubmitForm = (formData: any) => {
    let translates: any = [];
    setLoadingButton(true);
    formData?.translates?.forEach((item: any) => {
      if (item.labelTitle !== "") {
        translates.push(item)
      }
    })
    formData.translates = translates;
    const url = URLS.ADD_MEASUREMENT_FAMILY_UNIT_URL(props.measurementFamilyId);
    ServiceApi.patch(url, formData).then((res) => {
      NotificationManager.success(
        "global.toast.add-msg",
        "global.toast.add-title"
      );
      props.getData();
      props.toggle();
    }).catch(() => {}).finally(() => {
      setLoadingButton(false);
    });
  };

  const submit = (e?: FormEvent) => {
    formRef.current.submitHandler(e, data);
  };

  const onChangeLanguage = (lang: any) => {
    setLangSelected(lang);
    data?.translates?.forEach((item: any) => {
      if (item.language === lang) {
        setName(item.labelTitle)
        setDescription(item.description)
      }
    })
  };

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} modalClassName="z-40">
      <Form ref={formRef} onSubmit={onSubmitForm} id={"create-modal"}>
        <Modal.Header className="flex items-center justify-between">
          <div>
            <h2 className="text-heading-2 text-gray-800">
              <Text>productManagement.masterData.measurementUnits.addUnit</Text>
            </h2>
          </div>
          <div>
            <SelectLang value={langSelected} items={langItems} onChangeLanguage={onChangeLanguage} />
          </div>

        </Modal.Header>
        <Modal.Body className="max-h-100 overflow-auto">
          <div className="space-y-6">
            <InputGroup
              label="productManagement.masterData.measurementUnits.code"
              value={data?.code}
              className="border-white"
              setValue={(code) => setData((p: any) => ({ ...p, code }))}
              rules={rules.required}
              />
            <InputGroup
              label="productManagement.masterData.measurementUnits.name"
              value={name}
              flag={flagCountry[langSelected]}
              setValue={(value) => {
                data?.translates?.forEach((item: any) => {
                  if (item.language === langSelected) {
                    item.labelTitle = value
                  }
                })
                setData((p: any) => ({ ...p, translates: data?.translates }));
                setName(value)
              }}
              rules={rules.required}
            />
             <InputGroup
              label="productManagement.masterData.measurementUnits.description"
              value={description}
              as="short-textarea"
              flag={flagCountry[langSelected]}
              setValue={(value) => {
                data?.translates?.forEach((item: any) => {
                  if (item.language === langSelected) {
                    item.description = value
                  }
                })
                setData((p: any) => ({ ...p, translates: data?.translates }));
                setDescription(value)
              }}
            />
            <InputGroup
              label="productManagement.masterData.measurementUnits.symbol"
              value={data?.symbol}
              className="border-white"
              setValue={(symbol) => setData((p: any) => ({ ...p, symbol }))}
            />
            <Toggler
              label={"productManagement.masterData.measurementUnits.default"}
              value={data.isDefault}
              setValue={(isDefault) => setData((p: any) => ({ ...p, isDefault }))}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-between">
          <Button type="button" onClick={() => props.toggle()} variant="white">
            <Icon className="mr-2" icon={"times"} />
            <Text>global.buttons.close</Text>
          </Button>
          <Button type="button" onClick={submit} loading={loadingButton} >
            <Text>global.buttons.submit</Text>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
