import excludeIcon from "assets/icons/attributes/exclude.svg";
import includeIcon from "assets/icons/attributes/include.svg";
import {NotificationManager} from "common/notifications";
import {Button, NoItems, Skeleton, Table, Toggler, Text} from "components";
import {Fragment, useContext, useEffect, useState} from "react";
import {ServiceApi, URLS} from "services";
import {AttributesContext} from "..";
import {useToggleState, usePermission, useSelector} from "hooks";
import {SalesChannel} from "types";

type ChannelItem = {
  title: string;
  channelCode: string;
  pm: string;
  button: number;
  searchable: boolean;
};

export default function Channels() {
  const channels = useSelector(s => s.channels);
  const updatePermission = usePermission("PS_UpdateAttributeTemplate");
  const controller = new AbortController();
  const {attributeId} = useContext<any>(AttributesContext);
  const [attributeChannel, setattributeChannel] = useState<ChannelItem[]>([]);
  const [loadingChannel, setLoadingChannel] = useState(false);
  const [disableToggler, setDisableToggler] = useToggleState();
  const [disableBtn, setDisableBtn] = useToggleState();

  useEffect(() => {
    if (channels.length) getDataAttributes();
  }, [channels]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDataAttributes = () => {
    setLoadingChannel(true);
    const url = URLS.GET_ATTRIBUTES_DETAILS_URLS(attributeId);
    ServiceApi.get(url, {signal: controller.signal})
      .then(({data}) => {
        let items: ChannelItem[] = [];
        channels.forEach((channel: any) => {
          items.push({
            title: channel.title,
            channelCode: channel.code,
            pm: "productManagement.masterData.attributes.excludeFromThisChannel",
            button: 1,
            searchable: false,
          });
        });

        items?.forEach((item: any, index: any) => {
          if (data?.attributeChannelExceptions?.length !== 0) {
            data?.attributeChannelExceptions?.forEach((channelItem: any) => {
              if (item.channelCode === channelItem.channelCode) {
                items[index].pm =
                  "productManagement.masterData.attributes.includeFromThisChannel";
                items[index].button = 2;
              }
            });
          }
          data?.attributeSearchableChannels?.forEach((i: any) => {
            if (i.channelCode === item.channelCode) {
              items[index].searchable = true;
            }
          });
        });

        setattributeChannel(items);
      })
      .catch(() => {})
      .finally(() => {
        setLoadingChannel(false);
      });
  };

  const addSearchable = (code: any) => {
    setDisableToggler();
    let data = {
      attributeSearchableChannels: {
        channelCode: code,
      },
    };
    const url = URLS.ADD_CHANNEL_TO_SEARCHABLE_ATTRIBUTE(attributeId);
    ServiceApi.post(url, data)
      .then(res => {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title",
        );
        getDataAttributes();
        setDisableToggler();
      })
      .catch(() => setDisableToggler());
  };

  const removeSearchable = (code: any) => {
    setDisableToggler();
    let data = {
      attributeSearchableChannels: {
        channelCode: code,
      },
    };
    const url = URLS.REMOVE_CHANNEL_TO_SEARCHABLE_ATTRIBUTE(attributeId);
    ServiceApi.delete(url, {data: data})
      .then(res => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title",
        );
        setDisableToggler();
        getDataAttributes();
      })
      .catch(() => setDisableToggler());
  };

  return (
    <Fragment>
      {loadingChannel ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {attributeChannel.length !== 0 ? (
            <Table>
              {attributeChannel?.map((item: any) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <div className="flex items-center gap-4">
                        <div>
                          <img
                            src={item.button === 1 ? excludeIcon : includeIcon}
                            alt=""
                          ></img>
                        </div>
                        <div>
                          <h6 className="text-heading-6 font-semibold text-gray-800">
                            {item.title}
                          </h6>
                        </div>
                      </div>
                    </td>
                    {item.button === 1 ? (
                      <td>
                        <div className="w-[60%]">
                          <Toggler
                            label={
                              "productManagement.masterData.attributes.showAttributeInFilters"
                            }
                            value={item.searchable}
                            size="sm"
                            disabled={disableToggler || !updatePermission}
                            setValue={() => {
                              item.searchable === false
                                ? addSearchable(item.channelCode)
                                : removeSearchable(item.channelCode);
                            }}
                          />
                        </div>
                      </td>
                    ) : (
                      <td></td>
                    )}
                    {updatePermission && (
                      <td>
                        <Button
                          type="button"
                          variant="light"
                          disabled={disableBtn}
                          textColor={item.button === 1 ? "danger" : "primary"}
                          size="sm"
                          onClick={() => {
                            setDisableBtn();
                            const url =
                              item.button === 2
                                ? URLS.REMOVE_CHANNEL_TO_ATTRIBUTE(attributeId)
                                : URLS.ADD_CHANNEL_TO_ATTRIBUTE(attributeId);
                            if (item.button === 2) {
                              const body = {
                                attributeChannelPublishException: {
                                  channelCode: item.channelCode,
                                },
                              };
                              ServiceApi.delete(url, {data: body})
                                .then(() => {
                                  NotificationManager.success(
                                    "global.toast.delete-msg",
                                    "global.toast.delete-title",
                                  );
                                })
                                .catch(() => {})
                                .finally(() => {
                                  getDataAttributes();
                                  setDisableBtn();
                                });
                            } else {
                              const body = {
                                attributeChannelPublishException: {
                                  channelCode: item.channelCode,
                                },
                              };
                              ServiceApi.post(url, body)
                                .then(() => {
                                  NotificationManager.success(
                                    "global.toast.add-msg",
                                    "global.toast.add-title",
                                  );
                                })
                                .catch(() => {})
                                .finally(() => {
                                  getDataAttributes();
                                  setDisableBtn();
                                });
                            }
                          }}
                        >
                          <Text>{item.pm}</Text>
                        </Button>
                      </td>
                    )}
                  </tr>
                );
              })}
            </Table>
          ) : (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
            </div>
          )}
        </>
      )}
    </Fragment>
  );
}
