import {Button, Icon, Text} from "components";
import {SimulationContext} from "pages/price-engine/price-simulator";
import {useState, useContext, useEffect} from "react";
import {useToggleState} from "hooks";
import {ServiceApi, URLS} from "services";
import {config} from "constant";
import Chooser from "../common/chooser";
import Info from "../common/info";

export default function Program() {
  const {setSimulateData, simulateData} = useContext(SimulationContext);
  const [loading, setLoading] = useToggleState();
  const [isOpen, toggle] = useToggleState();
  const [isOpenProgram, toggleProgram] = useToggleState();
  const [suppliers, setSuppliers] = useState<any>([]);
  const [programs, setPrograms] = useState<any>([]);
  const [params, setParams] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
    suppliertype: 0,
  });

  const getSuppliers = () => {
    setLoading();
    const url = URLS.GET_SUPPLIERS;
    const config = {params};
    ServiceApi.get(url, config)
      .then(({data}) => {
        const clone = data?.items?.map((supplier: any) => ({
          id: supplier.supplierId,
          name: supplier.name,
          code: supplier.code,
        }));
        setSuppliers(clone);
        setLoading();
      })
      .catch(() => setLoading());
  };

  const getPrograms = () => {
    setLoading();
    const url = URLS.GET_SUPPLIER_PROGRAMS(simulateData?.supplier?.id);
    const config = {params};
    ServiceApi.get(url, config)
      .then(({data}) => {
        const clone = data?.map((program: any) => ({
          id: program.id,
          name: program.title,
          code: program.code,
        }));
        setPrograms(clone);
        setLoading();
      })
      .catch(() => setLoading());
  };

  return (
    <>
      <div className="space-y-4">
        <div>
          <span className="text-body-base text-gray-800">
            <Text>priceEngine.simulator.program</Text> :
          </span>
        </div>
        {simulateData["program"] ? (
          <Info dataKey="program" />
        ) : (
          <Button
            light
            size="sm"
            onClick={() => {
              if (simulateData?.supplier?.id) {
                toggleProgram();
              } else {
                toggle();
              }
            }}
          >
            <Icon icon="plus" className="mr-2" />
            <Text>priceEngine.simulator.addProgram</Text>
          </Button>
        )}
      </div>
      <Chooser
        isOpen={isOpen}
        toggle={toggle}
        toggleDep={toggleProgram}
        title="priceEngine.simulator.programs"
        data={suppliers}
        getData={getSuppliers}
        loading={loading}
        setParams={setParams}
        params={params}
        dataKey="supplier"
        setSimulateData={setSimulateData}
      />
      <Chooser
        isOpen={isOpenProgram}
        toggle={toggleProgram}
        title="Programs"
        data={programs}
        getData={getPrograms}
        loading={loading}
        setParams={setParams}
        params={params}
        dataKey="program"
        setSimulateData={setSimulateData}
      />
    </>
  );
}
