export const legalEntities = {
  home: "Home",
  legalEntities: "legal Entities",
  legalEntity: "Legal Entity",
  compony: "Compony",
  configurations: "Configurations",
  addLegalEntity: "Add Legal Entity",
  updateLegalEntity: "Update Legal Entity",
  create: "Create",
  discard: "Discard",
  view: "View",
  description: "Description",
  code: "Code",
  leganName: "Legal Name",
  generalInfo: "General Info",
  saveChanges: "Save Changes",
  general: "genral",
  setting: "setting",
  salesOrderNumberPrefix: "sales Order Number Prefix",
  salesOrderNumberIteration: "sales Order Number Iteration",
};
