import {useContext, useState} from "react";
import {PurchasePrice} from "compositions";
import {useToggleState} from "hooks";
import {ServiceApi, URLS} from "services";
import {SimulationContext} from "../..";
import {withPermission} from "hoc";

function PurchasePriceSimulate() {
  const {simulateData, setSimulateData} = useContext(SimulationContext);
  const [loading, toggle] = useToggleState();
  const [data, SetData] = useState({});

  //get purchase
  const getPurchaseData = (price?: number, type?: number) => {
    toggle();
    const url = URLS.SIMULATE_PURCHASE_PRICE;
    const {
      purchasePriceTemplate,
      supplier,
      product,
      program,
      brand,
      lineItemType,
      salesChannel,
      calculationProcessType,
    } = simulateData;
    const body = {
      purchasePriceTemplateId: purchasePriceTemplate?.id || null,
      supplierId: supplier?.id || null,
      programId: program?.id || null,
      brandId: brand?.id || null,
      productId: product?.id || null,
      saleChannelId: salesChannel?.id || null,
      purchasePriceType: type || 0,
      calculationProcessType: calculationProcessType,
      basePrice: price || 0,
      lineItemType: lineItemType || 0,
    };
    ServiceApi.post(url, body)
      .then(({data}) => {
        SetData(data);
        setSimulateData((p: any) => ({
          ...p,
          purchasePrice: data?.nNetPrice,
        }));
        toggle();
      })
      .catch(() => toggle());
  };

  return (
    <PurchasePrice
      price={data}
      loading={loading}
      getData={getPurchaseData}
      showPriceSection
    />
  );
}

export default withPermission(
  PurchasePriceSimulate,
  ["PE_GetPurchasePriceSimulation"],
  true,
);
