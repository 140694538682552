import {
  Breadcrumb,
  Button,
  Icon,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Status,
  Table,
  Text,
  WithPermission,
} from "components";
import {withPermission} from "hoc";
import {usePermission, useToggleState} from "hooks";
import {useEffect, useState} from "react";
import {Link, useSearchParams} from "react-router-dom";
import {ServiceApi, URLS} from "services";
import EditProductLabelTemplateDrawer from "./edit-drawer";
import {config, flagCountry} from "constant";
import {ProductLabelTemplate} from "types";
import {useTranslation} from "react-i18next";

function ProductLabelTemplateList() {
  const controller = new AbortController();
  const [data, setData] = useState<ProductLabelTemplate.ListType>();
  const [loadingList, setLoadingList] = useState(true);
  const [selected, setSelected] = useState<ProductLabelTemplate.Item>();
  const [searchParams] = useSearchParams();
  const [params, setParams] = useSearchParams();
  const [isOpenEdit, toggleEdit] = useToggleState(false);
  const updatePermission = usePermission("SC_UpdateProductLabelTemplate");
  const {i18n} = useTranslation();
  const language = i18n.language;
  const initialTemplate: ProductLabelTemplate.Item = {
    id: "",
    title: "",
    uploadedFile: undefined,
    isGlobal: true,
    isActive: true,
    metadata: [],
    language: language,
  };
  const originalFilterData: any = {
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  };

  const handleSetParams = (key: any) => {
    return (value: any) => {
      setParams(prev => {
        value !== null && value !== ""
          ? prev.set(key, value)
          : prev.delete(key);
        return prev;
      });
    };
  };

  const getData = () => {
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url = URLS.PRODUCT_LABEL_TEMPLATE_URL;
    const config = {params};
    setLoadingList(true);
    ServiceApi.get(url, {...config})
      .then(({data}) => {
        setData(data);
      })
      .catch(() => {
        setData({});
      })
      .finally(() => {
        setLoadingList(false);
        setSelected(undefined);
      });
  };

  useEffect(() => {
    getData();
    return () => {
      controller.abort();
    };
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOpenEditDrawer = (item: ProductLabelTemplate.Item) => {
    setSelected(item);
    toggleEdit();
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
        <WithPermission permissions={["SC_CreateProductLabelTemplate"]}>
          {loadingList ? (
            <Skeleton.Button />
          ) : (
            <Button
              as={Link}
              type="button"
              aria-label="cy-create-product-label-template"
              onClick={() => handleOpenEditDrawer(initialTemplate)}
            >
              <Icon className="mr-2" icon={"plus"} />
              <Text>global.addNew</Text>
            </Button>
          )}
        </WithPermission>
      </div>
      <SearchBox
        value={params?.get("keyword") || ""}
        onSubmit={handleSetParams("keyword")}
        disabled={loadingList}
        totalItems={data?.items?.length}
        onReload={getData}
        loading={loadingList}
      />
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data &&
          Object.keys(data).length !== 0 &&
          data?.items?.length !== 0 ? (
            <>
              <Table>
                {data?.items?.map(item => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <h6
                          className={`text-heading-6 ${updatePermission && "cursor-pointer"}`}
                          onClick={() => handleOpenEditDrawer(item)}
                        >
                          {item.title}
                        </h6>
                      </td>
                      <td className="text-xs text-primary">
                        {item.isGlobal && (
                          <Text>
                            productManagement.masterData.productLabelTemplate.isGlobal
                          </Text>
                        )}
                      </td>
                      <td>
                        <Status.ProductLabelTemplateActivation
                          id={item.isActive}
                        />
                      </td>
                      <td>
                        <div className="flex items-center justify-center gap-2 text-heading-6 font-semibold text-gray-500">
                          <img src={flagCountry[item.language]} />
                          {item.language.toUpperCase()}
                        </div>
                      </td>
                      <td>
                        <WithPermission
                          permissions={["SC_UpdateProductLabelTemplate"]}
                        >
                          <Button
                            size="sm"
                            variant="light"
                            onClick={() => handleOpenEditDrawer(item)}
                          >
                            <Text>global.buttons.edit</Text>
                          </Button>
                        </WithPermission>
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages}
                pageNumber={
                  params?.get("pageNumber")
                    ? parseInt(params?.get("pageNumber") as string)
                    : 1
                }
                pageSize={
                  params?.get("pageSize")
                    ? parseInt(params?.get("pageSize") as string)
                    : config.pageSize
                }
                setActivePage={handleSetParams("pageNumber")}
                onPageSizeChange={(e: any) =>
                  setParams(p => ({...p, pageSize: e}))
                }
              />
            </>
          ) : (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
            </div>
          )}
        </>
      )}
      {selected && (
        <EditProductLabelTemplateDrawer
          isOpen={isOpenEdit}
          toggle={toggleEdit}
          getData={getData}
          selected={selected}
        />
      )}
    </div>
  );
}
export default withPermission(ProductLabelTemplateList, [
  "SC_GetProductLabelTemplate",
]);
