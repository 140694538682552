import {useMemo, ReactNode} from "react";
import {variantType} from "types";
import LoadingSvg from "./LoadingSvg";

type buttonProps = {
  id?: string;
  as?: any;
  to?: string | number;
  type?: "submit" | "button";
  variant?: variantType;
  children: ReactNode;
  onClick?: (e?: any) => void;
  className?: string;
  textColor?: string;
  target?: string;
  hidden?: boolean;
  disabled?: boolean;
  loading?: boolean;
  size?: "sm" | "base" | "lg";
} & ({light?: boolean; outline?: never} | {light?: never; outline?: boolean});

export default function Button({
  as: Component = "button",
  variant = "primary",
  onClick,
  light,
  outline,
  className = "",
  textColor = "",
  id = "",
  hidden,
  disabled,
  children,
  size = "base",
  loading = false,
  ...props
}: buttonProps) {
  const activeVariant = useMemo(() => {
    if (!variant) return "";
    if (light) return `light-${variant}`;
    if (outline) return `outline-${variant}`;
    if (textColor) return `${variant}-text-${textColor}`;
    return variant;
  }, [variant, light, outline, textColor]);
  return (
    <Component
      id={id}
      hidden={hidden}
      disabled={disabled || loading}
      onClick={onClick}
      className={`btn btn-${activeVariant} btn-${size} ${className}`}
      {...props}
    >
      <div className="flex items-center justify-center gap-2">
        {children} {loading && <LoadingSvg size="sm" />}
      </div>
    </Component>
  );
}
