import {useContext} from "react";
import {Wrapper, Text, Skeleton, InputGroup} from "components";
import {LegalEntityContext} from "./details";
import {rules} from "constant";
import {usePermission} from "hooks";

export default function SettingTab() {
  const updatePermission = usePermission("AS_UpdateLegalEntity");
  const {loadingPage, data, setData} = useContext<any>(LegalEntityContext);

  return (
    <Wrapper>
      <Wrapper.Header>
        <div className="flex">
          <div className="mr-auto">
            <h2 className="text-heading-2 font-semibold text-gray-800">
              <Text>company.legalEntities.setting</Text>
            </h2>
          </div>
        </div>
      </Wrapper.Header>
      <Wrapper.Body className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-8">
        <div className="col-start-1 col-end-2">
          {loadingPage ? (
            <Skeleton.Input />
          ) : (
            <InputGroup
              label="company.legalEntities.salesOrderNumberPrefix"
              value={data?.legalEntitySetting?.salesOrderNumberPrefix}
              rules={rules.required}
              setValue={salesOrderNumberPrefix =>
                setData((p: any) => ({
                  ...p,
                  ["legalEntitySetting"]: {
                    ...data.legalEntitySetting,
                    salesOrderNumberPrefix,
                  },
                }))
              }
              disabled={!updatePermission}
            />
          )}
        </div>
        <div className="col-start-1 col-end-2">
          {loadingPage ? (
            <Skeleton.Input />
          ) : (
            <InputGroup
              label="company.legalEntities.salesOrderNumberIteration"
              value={data?.legalEntitySetting?.salesOrderNumberIteration}
              type="number"
              rules={rules.required}
              setValue={salesOrderNumberIteration =>
                setData((p: any) => ({
                  ...p,
                  ["legalEntitySetting"]: {
                    ...data.legalEntitySetting,
                    salesOrderNumberIteration: +salesOrderNumberIteration,
                  },
                }))
              }
              disabled={!updatePermission}
            />
          )}
        </div>
      </Wrapper.Body>
    </Wrapper>
  );
}
