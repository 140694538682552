import {LazyImage, Text, Wrapper} from "components";
import {Fragment, useContext} from "react";
import {convertAddress} from "utils";
import {AddSupplierContext} from "..";

export default function Review() {
  const {data, dataImg} = useContext<any>(AddSupplierContext);
  const supplierType = [
    {
      name: "TradingGoods",
      id: 0,
    },
    {
      name: "ConsumableGoods",
      id: 1,
    },
  ];
  return (
    <Fragment>
      <h2 className="text-heading-2 text-gray-800">
        <Text>productManagement.masterData.suppliers.review</Text>
      </h2>
      <Wrapper.Section className="grid grid-cols-5 items-start gap-4 text-gray-800 [&>div:nth-child(even)]:col-span-2 [&>div:nth-child(even)]:text-body-base [&>div:nth-child(odd)]:col-span-3 [&>div:nth-child(odd)]:text-heading-6 [&>div:nth-child(odd)]:font-medium">
        <h3 className="col-span-full text-gray-600">
          <Text>productManagement.masterData.suppliers.generalInfo</Text>
        </h3>
        <div>
          <Text>productManagement.masterData.suppliers.name</Text>
        </div>
        <div>{data?.name}</div>
        <div>
          <Text>productManagement.masterData.suppliers.code</Text>
        </div>
        <div>{data?.code}</div>
        <div>
          <Text>productManagement.masterData.suppliers.ilnNumber</Text>
        </div>
        <div>{data?.iln}</div>
        <div>
          <Text>productManagement.masterData.suppliers.taxId</Text>
        </div>
        <div>{data?.taxId}</div>
        <div>
          <Text>productManagement.masterData.suppliers.description</Text>
        </div>
        <div>{data?.description}</div>
        <div>
          <Text>productManagement.masterData.suppliers.supplierType</Text>
        </div>
        <div>
          {supplierType?.find(type => type.id === data?.supplierType)?.name ||
            ""}
        </div>
      </Wrapper.Section>
      <Wrapper.Section className="grid grid-cols-5 items-start gap-4 text-gray-800 [&>div:nth-child(even)]:col-span-2 [&>div:nth-child(even)]:text-body-base [&>div:nth-child(odd)]:col-span-3 [&>div:nth-child(odd)]:text-heading-6 [&>div:nth-child(odd)]:font-medium">
        <h3 className="col-span-full text-gray-600">
          <Text>productManagement.masterData.suppliers.contactInfo</Text>
        </h3>
        <div>
          <Text>productManagement.masterData.suppliers.emailAddress</Text>
        </div>
        <div>{data?.email}</div>
        <div>
          <Text>productManagement.masterData.suppliers.phoneNumber</Text>
        </div>
        <div>{data?.phoneNumber}</div>
      </Wrapper.Section>
      <Wrapper.Section className="grid grid-cols-5 items-start gap-4 text-gray-800 [&>div:nth-child(even)]:col-span-2 [&>div:nth-child(even)]:text-body-base [&>div:nth-child(odd)]:col-span-3 [&>div:nth-child(odd)]:text-heading-6 [&>div:nth-child(odd)]:font-medium">
        <h3 className="col-span-full text-gray-600">
          <Text>productManagement.masterData.suppliers.location</Text>
        </h3>
        <div>
          <Text>global.locations.address</Text>
        </div>
        <div>{convertAddress(data.address)}</div>
      </Wrapper.Section>
      <Wrapper.Section>
        <h3 className="mb-6 text-gray-600">
          <Text>productManagement.masterData.suppliers.logo</Text>
        </h3>
        <LazyImage
          isDynamic
          src={dataImg?.thumbnailUrl}
          alt={data.title}
          className="w-full"
        />
      </Wrapper.Section>
    </Fragment>
  );
}
