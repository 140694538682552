export const Details = {
  generalTab: "General",
  pricesTab: "Prices",
  attributesTab: "Attributes",
  mediaTab: "Media",
  categoriesTab: "Categories",
  locationsTab: "Locations",
  channelsTab: "Channels",
  shareButton: "Share",
  articleNumber: "Article Number",
  supplierArticleNumber: "Supplier Article Number",
  importVersion: "import version",
  currentProductVersion: "Current Product Version",
  productFamily: "Product Family",
  category: "Category",
  creation: "creation",
  modify: "modify",
  active: "Active",
  notActive: "Not Active",
  supplierPrice: "Supplier Price",
  salesPrice: "base Price",
  addPriceDate: "Add Price/Date",
  addMedia: "Add Media",
  assignPlace: "Assign Place",
  availableTill: "Available Till",
  supplier: "Supplier",
  suppliers: "Suppliers",
  program: "Program",
  brand: "Brand",
  selectDate: "Select Date ...",
  select: "Select ...",
  previewCurrentVersion: "Preview Current Version",
  publishedVersion: "Published Version",
  unPublish: "unPublish",
  rePublish: "rePublish",
  publishCurrentVersion: "Publish Current Version",
  assignCategory: "Assign Category",
  missingRequiredAttribute: "Missing Required Attribute",
  changedAttribute: "Atttribute Value Changed",
  channels: "Channels",
  language: "Language",
  saveAttributes: "Save Attributes",
  productCategory: "Product Category",
  selectCategory: "Select Category ...",
  fileInfo: "File Info",
  imageSetting: "Image Setting",
  deleteImage: "Delete",
  imagePublicationInChannels: "Image Publication in Channels",
  imageInfo: "Image Info",
  placeHolder: "PlaceHolder",
  description: "Description",
  productPreviewFor: "Product Preview For",
  basicInfo: "Basic Info",
  prices: "Prices",
  price: "Price",
  purchasePrice: "Purchase Price",
  shareProduct: "Share Product",
  sendEmail: "Send Email",
  copyUrl: "Copy URL",
  url: "URL",
  startDate: "Start Date",
  endDate: "End Date",
  noData: "There is no data available",
  compareValues: "Compare Values",
  setValueForAllChannel: "Set Value For All Channels",
  noAttributeFound: "No Attribute Found",
  allInGroupFilter: "All",
  showInvisibleAttributes: "Show Invisible Attributes",
  searchCategory: "Serach Category",
  thereIsNoChannelEnabledForYourCompany:
    "There is no channel enabled for your company",
  aiTextGenerator: "AI Text Generator ",
  aiDescription: "Generate an AI Generated Text for the following product ",
  basicInfo: "Basic Info(optional) ",
  wordCount: "Word Count ",
  generate: "Generate ",
  copy: "copy ",
  setValue: "Set Value ",
  productGroup: "Product Group",
  allAtributeFilter: "All Attribute",
  missingRequiredAtributeFilter: "Missing Required Attributes",
  allMissingAtributeFilter: "All Missing Attributes",
  hasWarningAtributeFilter: "Has Warning Attributes",
  publishInAllChannels: "Publish in all channels",
  unPublishFromAllChannels: "Unpublish from all channels",
  published: "Published",
  sent: "Sent",
  sendFailed: "Send Failed",
  init: "Init",
  synced: "Synced",
  syncFailed: "Sync Failed",
  at: "at",
  selectLegalEntities: "Select legal entities",
  closeBtn: "Close",
  cancelBtn: "Cancel",
  submitBtn: "Submit",
  shippingLogestic: "Shipping and Logistics DE",
  addShipping: "Add Shipping",
  infoDetails: "Details",
  moreInfo: "More Info",
  type: "Type",
  assignedPriceTemplate: "Assigend Price Template",
  assignedPriceTemplateChart: "Assigend Price Template Chart",
  recalculate: "Recalculate",
  appliedPriceTemplate: "Applied Price Template",
  pimProductPriceTemplate: "Product Price Template",
  brandPriceTemplate: "Brand Price Template",
  supplierPriceTemplate: "Supplier Price Template",
  supplierProgramPriceTemplate: "Program Price Template",
  addNew: "Add New",
  addNewPhoto: "Add New Photo",
  None: "None",
  EMV: "EMV",
  Iwofurn: "Iwofurn",
  Excel: "Excel",
  shipping: "Shipping",
  warning: "Warning",
  NoAssignedPriceTemplate: "No assigned price template",
  NoAssignedTemplate: "No Assigned Template",
  requirementGathering: "Requirement Gathering",
  configurationValidation: "Configuration Validation",
  dataTransmission: "Data Transmission",
  acknowledgeReceipt: "Acknowledge Receipt",
  publishIn: "publish in",
  priceTemplates: "Price Templates",
  refrenceKey: "Refrence Key",
  addNewBundleItem: "Add New Bundle Item",
  code: "Code",
  legalEntity: "Legal Entity",
  noDiscount: "No Discount",
  NoPromotion: "No Promotion",
  availabilityStatusTab: "Availability Status",
  salesPersonCommission: "Sales Person Commission",
  amount: "Amount",
  percent: "Percent",
  availabilityStatus: {
    // list
    defaultStatus: "Default",
    statusDate: "From {{ fromDate }} to {{ toDate }}",
    addStatusButton: "Add",
    // form
    addStatusTitle: "Add status",
    editStatusTitle: "Edit status",
    closeButton: "Close",
    status: "Status",
    addValidationPeriod: "Add validation period",
    fromDate: "From",
    toDate: "To",
    cancelButton: "Cancel",
    addButton: "Add status",
    updateButton: "Update status",
    addSuccessMessage: "Availability status has been created successfully.",
    editSuccessMessage: "Availability status has been edited successfully.",
    // confirm
    removeStatusTitle: "Remove status",
    removeStatusText:
      "This operation cannot be reversed. Are you sure about your request?",
    cancelRemoveButton: "Cancel",
    removeStatusButton: "Remove status",
    removeSuccessMessage: "Availability status has been Removed successfully.",
    dateConflict: "Date range has conflict",
  },
  uvpPrice: "uvpPrice",
};
