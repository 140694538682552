export const customerList = {
  cimNumber: "CIM Number",
  firstName: "First Name",
  lastName: "Last Name",
  basicInfo: "Basic Info",
  communicationChannels: "Communication Channels",
  address: "Address",
  location: "Location",
  general: "General",
  gender: "Gender",
  birthdate: "Date Of Birth",
  mobileNumber: "Mobile Number",
  phoneNumber: "Phone Number",
  emailAddress: "Email Address",
  street: "Street",
  houseNumber: "House Number",
  postalCode: "Postal Code",
  name: "Name",
  city: "City",
  state: "State",
  country: "Country",
  companyName: "Company",
  company: "Company Name",
  taxNumber: "Tax Number",
  channels: "Channels",
  email: "Email",
  application: "Application",
  sms: "SMS",
  phone: "Phone",
  post: "Post",
  socialMedia: "Social Media",
  familyBonus: "Family Bonus",
  newsLetterSubscription: "News Letter Subscription",
  title: "Title",
  accountInfo: "Account Info",
  status: "Status",
  id: "ID",
  customerId: "Customer ID",
  deviceName: "Device Name",
  mobileVerifyAt: "Mobile verification requested at",
  emailVerifyAt: "Email verification requested at",
  companyType: "Company",
  individual: "individual",
  pending: "Pendig",
  approved: "Approved",
  suspended: "Suspended",
  EligibleforTax: "Eligible for Tax",
  TaxID: "Tax ID",
  registrationNumber: "Registration number",
  contactPerson: "Contact Person",
  registered: "Registered",
  notRegistered: "not Registered",
  verified: "Verified",
  none: "None",
};
