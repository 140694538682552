import {NotificationManager} from "common/notifications";
import {
  Breadcrumb,
  Button,
  Footer,
  Form,
  Tabs,
  Text,
  WithPermission,
  Wrapper,
} from "components";
import {useDataState} from "hooks";
import {createContext, createElement, useEffect, useState} from "react";
import {useParams} from "react-router";
import {Link, useNavigate} from "react-router-dom";
import {ServiceApi, URLS} from "services";
import GeneralTab from "./general";
import SettingTab from "./setting";

export const LegalEntityContext = createContext({});

export default function Details() {
  const {legalEntityId} = useParams();
  const [loadingButton, setLoadingButton] = useState(false);
  const [data, setData, setBaseData, isChanged] = useDataState<any>({});
  const [loadingPage, setLoadingPage] = useState(true);
  const navigate = useNavigate();
  const [selectedTab, SetTab] = useState("company.legalEntities.general");

  const tabs = [
    {
      label: "company.legalEntities.general",
      component: GeneralTab,
    },
    {
      label: "company.legalEntities.setting",
      component: SettingTab,
    },
  ];

  useEffect(() => {
    const getData = async () => {
      await ServiceApi.get(URLS.LEGALENTITY_DETAILS(legalEntityId)).then(
        ({data}) => {
          setBaseData(data);
          setLoadingPage(false);
        },
      );
    };
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const submit = () => {
    setLoadingButton(true);
    const url = URLS.UPDATE_LEGALENTITY_DETAILS(legalEntityId);
    const body = {
      name: data.name,
      description: data.description,
      salesOrderNumberPrefix: data.legalEntitySetting?.salesOrderNumberPrefix,
      salesOrderNumberIteration:
        data.legalEntitySetting?.salesOrderNumberIteration,
    };
    ServiceApi.put(url, body).then(() => {
      NotificationManager.success(
        "global.toast.edit-msg",
        "global.toast.edit-title",
      );
      navigate(-1);
      setLoadingButton(false);
    });
  };

  const clipboard = () => {
    window.navigator.clipboard.writeText(data.code);
    NotificationManager.success(
      "global.toast.copy-msg",
      "global.toast.copy-title",
    );
  };

  return (
    <Tabs activeKey={tabs[0].label}>
      <Form onSubmit={submit} className="relative space-y-6">
        <h3 className="mr-auto text-heading-3">
          <Breadcrumb />
        </h3>
        <Wrapper className="px-4">
          <Tabs.ButtonGroup>
            {tabs.map(e => (
              <Tabs.Button
                key={e.label}
                eventKey={e.label}
                onClick={e => SetTab(e)}
              >
                <Text>{e.label}</Text>
              </Tabs.Button>
            ))}
          </Tabs.ButtonGroup>
        </Wrapper>
        {tabs.map(e => (
          <Tabs.Item key={e.label} eventKey={e.label}>
            <LegalEntityContext.Provider
              value={{loadingPage, data, setData, clipboard}}
            >
              {createElement(e.component)}
            </LegalEntityContext.Provider>
          </Tabs.Item>
        ))}
        <WithPermission permissions={["AS_UpdateLegalEntity"]}>
          <Footer show={!isChanged}>
            <Button as={Link} to={-1} variant="white">
              <Text>global.buttons.discard</Text>
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={!isChanged}
              loading={loadingButton}
            >
              <Text>global.buttons.saveChanges</Text>
            </Button>
          </Footer>
        </WithPermission>
      </Form>
    </Tabs>
  );
}
