const COMPANY = {
  //------------------company -------------------------
  MY_COMPANY_URL: "accountservice/api/companies/my",
  ADD_IMAGE_TO_MY_COMPANY_URL: "accountservice/api/me/companies/avatar",
  COMPANY_URL: "statisticsservice/api/companies",
  COMPANY_PREFIX_NUMBER: "accountservice/api/me/companies/prefix-number",
  GET_COMPANY_SETTING: "accountservice/api/companysettings/my",
  UPDATE_COMPANY_SETTING: "accountservice/api/companysettings/my",
  UPDATE_COMPANY_LOGI: "accountservice/api/companies/my/logo",
  //-------------------channels-------------------------

  CHANNEL_URL: "accountservice/api/companies/channels",
};

export default COMPANY;
