import { useContext } from "react";
import PriceCalculationTab from "compositions/price-calculation-units/price-calculation-tab";
import { calculationLevelEnum } from "constant";
import { PimContext } from "..";

export default function PriceCalculation() {
  const { productData } = useContext<any>(PimContext);

  return (
    <PriceCalculationTab
      refId={productData?.id}
      level={calculationLevelEnum.product}
      refTitle={productData?.originalName}
      refCode={''}
    />
  );
}
