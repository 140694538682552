import {InteractionRequiredAuthError} from "@azure/msal-browser";
import Axios from "axios";
import {authInstance, config, loginRequest} from "../constant";
import {ErrorHandler} from "./error-handler";
import store from "store";
import {toggleLoading} from "store/loading";

const data = {};

export const getLocalIdToken = () => {
  const homeAccountId = authInstance?.getAllAccounts()[0]?.homeAccountId;
  const Idtoken = `${homeAccountId}-${`${config.tenant}.b2clogin.com`}-idtoken-${
    config.clientId
  }----`;
  const token = JSON.parse(sessionStorage.getItem(Idtoken)) || "";
  return token;
};

const getExpireTime = () => {
  const homeAccountId = authInstance?.getAllAccounts()[0]?.homeAccountId;
  const Idtoken = `${homeAccountId}-${`${config.tenant}.b2clogin.com`}-`;
  const data = JSON.parse(sessionStorage.getItem(Idtoken)) || "";
  const expDate = data?.idTokenClaims?.exp;
  return expDate;
};

const getAuthData = async clientId => {
  try {
    const authData = await authInstance.acquireTokenSilent(
      clientId ?? loginRequest,
    );
    return authData;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      return authInstance.acquireTokenRedirect(loginRequest);
    }
  }
};

const ServiceApi = Axios.create({
  baseURL: config.baseUrl,
});

ServiceApi.interceptors.request.use(
  async req => {
    store.dispatch(toggleLoading(true));

    let currentToken = "";

    const currentDate = Math.floor(new Date().getTime() / 1000);

    const token = getLocalIdToken();

    const expDate = getExpireTime();

    if (expDate > currentDate) {
      currentToken = token?.secret;
    } else {
      const accounts = authInstance.getAllAccounts();
      const token = await getAuthData(accounts.length > 0 ? accounts[0] : null);
      currentToken = token?.secret;
    }
    //Check if Token exist
    if (currentToken) {
      req.headers["Authorization"] = `Bearer ${currentToken}`;
      if (!req.params?.withoutSubscriptionKey) {
        req.headers["Ocp-Apim-Subscription-Key"] = config.subscriptionKey;
      }
      return req;
    }
    authInstance.loginRedirect(loginRequest);
    return req;
  },
  err => {
    if (err.code !== "ERR_CANCELED") {
      if (err?.response?.status !== 403 || err?.response?.status !== 401) {
        ErrorHandler(err);
      }
      return Promise.reject(err);
    }
  },
);
ServiceApi.interceptors.response.use(
  res => {
    store.dispatch(toggleLoading(false));
    const key = `${res.config.url}${JSON.stringify(res.config.params)}`;
    const value = res.data;
    data[key] = value;
    return res;
  },
  async err => {
    store.dispatch(toggleLoading(false));
    if (err.code !== "ERR_CANCELED") {
      if (err?.response?.status === 401) {
        //retry failed request
        const originalRequest = err.config;
        originalRequest._retry = true;
        const token = await getAuthData();
        let currentToken = token?.secret;
        ServiceApi.defaults.headers.common["Authorization"] =
          "Bearer " + currentToken;
        return ServiceApi(originalRequest);
      }
      if (
        ![401, 403].includes(err?.response?.status) &&
        !err.config.url.includes("/get-file")
      ) {
        ErrorHandler(err);
      }
      return Promise.reject(err);
    }
  },
);

export default ServiceApi;
