import {Icon, Status} from "components";
import {SalesService} from "types";

type Props = {item: SalesService.SellOffBasicInfoDto};
const PreparationStatusTemplate = ({item}: Props) => {
  return (
    <div className="space-y-2">
      <h6 className="text-heading-6">
        <Status.SellOff
          id={"salesManagment.sellOffSales.sellOffProduct.preparation"}
        />
      </h6>
      {item?.preparationPhase?.checkListItems?.map((p: any) => {
        return (
          <>
            {p.label && (
              <p
                key={p.id}
                className="text-left text-body-2 font-normal text-gray-600"
              >
                <Icon
                  icon={p.isChecked ? "circle-check" : "circle"}
                  className="mr-2 inline-block h-4 w-4 align-middle data-active:text-success"
                  data-active={p.isChecked}
                />
                {p.label}
              </p>
            )}
          </>
        );
      })}
    </div>
  );
};

export default PreparationStatusTemplate;
