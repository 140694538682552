export const salesPrice = {
  addSalesTemplate: "Add Sales Template",
  updateAt: "Updated at",
  title: "Title",
  code: "Code",
  shortDescription: "Short Description",
  addTemplate: "Add Template",
  validationPeriods: "Validation Periods",
  from: "From",
  to: "To",
  selectDate: "Select Date",
  general: "General",
  calculationSections: "Calculation Sections",
  active: "Active",
  basicInfo: "Basic Info",
  addNewCalculationUnit: "Add New Calculation Unit",
  step1: "Step 1 - G> N",
  step2: "Step 2 - N>NN",
  step3: "Step 3 - Calculation factor",
  step4: "Step 4",
  step5: "Step 5",
  addSelectedUnits: "Add Selected Units",
  searchCalcUitsPlacholder: "search calc. units by id or name",
  system: "System",
  custom: "Custom",
  automaticDiscount: "Automatic Discount",
  optionalDiscount: "Optional Discount",
  section1: "Section 1 (System 1) ",
  section2: "Section 2 (System 2) ",
  section3: "Section 3 (Automatic discounts) ",
  section4: "Section 4 (Catalog Calculation) ",
  section5: "Section 5 (Optional discounts)  ",
  section6: "Section 6 (Individual discounts)  ",
};
