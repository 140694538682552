import {NotificationManager} from "common/notifications";
import {
  AddressViewer,
  Breadcrumb,
  Button,
  Footer,
  Form,
  Icon,
  ImageUploader,
  LazyImage,
  LoadingSvg,
  Skeleton,
  Tabs,
  Text,
  WithPermission,
  Wrapper,
} from "components";
import {useConverter, useDataState, usePermission, useTranslate} from "hooks";
import {
  Fragment,
  createContext,
  createElement,
  useEffect,
  useState,
} from "react";
import {useToggleState} from "hooks";
import {useParams} from "react-router";
import {Link} from "react-router-dom";
import {ServiceApi, URLS} from "services";
import {Media} from "types";
import {ObjectUtils, ValidateAddress} from "utils";
import BankInfo from "./tabs/bankInfo";
import ContactPerson from "./tabs/contactPerson";
import General from "./tabs/general";
import Program from "./tabs/program";
import SaleSetting from "./tabs/saleSetting";
import {assetTypes} from "constant";
import PriceCalculation from "./tabs/priceCalculation";
import Channels from "./tabs/channels";

export const SuppliersContext = createContext({});

export default function Update() {
  const updatePermission = usePermission("PS_UpdateSupplier");
  const {convertDate} = useConverter();
  const {supplierId} = useParams();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [data, setData, setBaseData, isChanged, baseData] = useDataState<any>(
    {},
  );
  const [isOpen, toggle] = useToggleState(false);
  const [addressErrorMsg, setAddressErrorMasg] = useState("");
  const translate = useTranslate();

  const tabs = [
    {
      label: "productManagement.masterData.suppliers.generalInfo",
      component: General,
    },
    {
      label: "productManagement.masterData.suppliers.bankInfo",
      component: BankInfo,
    },
    {
      label: "productManagement.masterData.suppliers.program",
      component: Program,
    },
    {
      label: "productManagement.masterData.suppliers.contactPerson",
      component: ContactPerson,
    },
    {
      label: "productManagement.masterData.suppliers.settings",
      component: SaleSetting,
    },
    {
      label: "priceEngine.calculationLevel.priceCalculationUnits",
      component: PriceCalculation,
    },
    {
      label: "productManagement.masterData.suppliers.channels",
      component: Channels,
    },
  ];
  const getData = () => {
    setLoading(true);
    const url = URLS.GET_SUPPLIER_DETAILS(supplierId);
    ServiceApi.get(url)
      .then(({data}) => {
        setBaseData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submit = () => {
    setAddressErrorMasg("");
    setSubmitLoading(true);
    if (!data.address) submitRequest();
    else
      ValidateAddress(data.address).then(res => {
        if (!res?.selected && !res.suggested) {
          setAddressErrorMasg(
            translate("global.locations.invalidAddress") as string,
          );
          setSubmitLoading(false);
          return;
        }
        if (res.selected) {
          setData({
            ...data,
            address: {
              ...res.selected,
              name: data.address.name,
              number: data.address.number,
            },
          });
        }
        if (res.suggested) {
          setData({
            ...data,
            address: {
              ...res.suggested,
              name: data.address.name,
              number: data.address.number,
            },
          });
          setAddressErrorMasg(
            translate("global.locations.changedAddress") as string,
          );
          setSubmitLoading(false);
          return;
        }
        submitRequest();
      });
  };

  //Submit Request
  const submitRequest = () => {
    const url = URLS.SUPPLIER_URL(supplierId);
    const shipping = data?.shipping?.minimumShippingDuration
      ? {
          ...data?.shipping,
          durationUnit: data?.shipping.durationUnit
            ? data?.shipping.durationUnit
            : 1,
        }
      : data?.shipping?.minimumResponseTime
        ? {minimumResponseTime: data?.shipping?.minimumResponseTime}
        : null;
    let bankInfoIsEmpty = false;
    if (data.bankInfo) {
      bankInfoIsEmpty = Object.values(data.bankInfo).every(
        x => x === null || x === "",
      );
    }
    const payload = {...data, bankInfo: bankInfoIsEmpty ? null : data.bankInfo};
    const body = {...payload, shipping};
    ServiceApi.put(url, body)
      .then(() => {
        setBaseData(data);
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  useEffect(getData, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onUploadImage = (data: Media) => {
    const url = URLS.UPLOAD_SUPPLIERS_IMAGES(supplierId);
    const body = {
      url: data.url,
      type: data.type,
      thumbnailUrl: data.thumbnailUrl,
    };
    ServiceApi.patch(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
      })
      .finally(() => {
        getData();
      });
  };

  return (
    <Tabs
      checkSave={isChanged}
      activeKey={tabs[0].label}
      onSave={() => {
        setData(baseData);
      }}
    >
      <Form onSubmit={submit} className="relative space-y-6">
        <Breadcrumb />
        <Wrapper>
          <Wrapper.Body className="flex flex-col items-start gap-5 lg:flex-row">
            <div>
              <LazyImage
                isDynamic
                onClick={() => {
                  if (updatePermission) {
                    toggle();
                  }
                }}
                src={data?.logo?.url}
                logo
                editor
                alt={data.name}
                className="relative flex aspect-image w-fit cursor-pointer justify-center overflow-hidden rounded bg-gray-100 p-2 lg:aspect-auto lg:h-40 lg:w-80"
              />
            </div>
            <div className="space-y-4 lg:flex-1">
              {loading ? (
                <Fragment>
                  <Skeleton.Input />
                  <Skeleton.Input />
                </Fragment>
              ) : (
                <Fragment>
                  <h6 className="text-heading-6 text-gray-800">{data.name}</h6>
                  {data.phoneNumber ? (
                    <p className="text-body-2 font-medium text-gray-500">
                      <Icon
                        icon="phone-flip"
                        className="rotate-90 text-[14px] text-gray-700"
                        size="xl"
                      />{" "}
                      {data.phoneNumber}
                    </p>
                  ) : (
                    ""
                  )}
                  {data?.address?.city ? (
                    <AddressViewer address={data.address} />
                  ) : (
                    ""
                  )}
                  <p className="text-body-2 font-medium">
                    <Text>productManagement.masterData.suppliers.version</Text>{" "}
                    : {data?.version}
                  </p>
                  <p className="text-body-2 font-medium">
                    <Text>
                      productManagement.masterData.suppliers.lastModifiedAt
                    </Text>{" "}
                    : {convertDate(data?.lastModifiedAt)}
                  </p>
                  <p className="text-body-2 font-medium">
                    <Text>
                      productManagement.masterData.suppliers.lastModifiedBy
                    </Text>{" "}
                    : {data?.lastModifiedByName}
                  </p>
                </Fragment>
              )}
            </div>
          </Wrapper.Body>
          <Wrapper.Footer className="!py-0">
            <Tabs.ButtonGroup>
              {tabs.map(e => (
                <Tabs.Button key={e.label} eventKey={e.label}>
                  <Text>{e.label}</Text>
                </Tabs.Button>
              ))}
            </Tabs.ButtonGroup>
          </Wrapper.Footer>
        </Wrapper>
        {loading && (
          <div className="flex min-h-40 justify-center align-middle">
            <LoadingSvg />
          </div>
        )}
        {!loading &&
          tabs.map(e => (
            <Tabs.Item key={e.label} eventKey={e.label}>
              <SuppliersContext.Provider
                value={{
                  suppliersData: data,
                  setSuppliersData: setData,
                  getData: getData,
                  supplierLoading: loading,
                  addressErrorMsg,
                }}
              >
                {!ObjectUtils.isEmpty(data) && createElement(e.component)}
              </SuppliersContext.Provider>
            </Tabs.Item>
          ))}
        <WithPermission permissions={["PS_UpdateSupplier"]}>
          <Footer show={!isChanged}>
            <Button as={Link} to={-1} variant="white">
              <Text>global.buttons.discard</Text>
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={!isChanged}
              loading={submitLoading}
            >
              <Text>global.buttons.saveChanges</Text>
            </Button>
          </Footer>
        </WithPermission>
      </Form>
      <ImageUploader
        isOpen={isOpen}
        toggle={toggle}
        image={data?.logo?.url}
        onUpload={(data: Media) => onUploadImage(data)}
        type={assetTypes.Company}
      />
    </Tabs>
  );
}
