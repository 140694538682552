import {useState, useEffect, forwardRef, useImperativeHandle} from "react";
import {unitTypes} from "constant";
import {Icon, Skeleton, Button, Text, Table, NoItems, Status} from "components";
import {useToggleState, useConverter} from "hooks";
import {ServiceApi, URLS} from "services";
import {AddNewCalculationUnit} from "compositions";
import {PriceEngin} from "types";
import {NotificationManager} from "common/notifications";
import {convertDate} from "utils";
import EditUnit from "./edit-custom-unit";
import {dateFormats} from "constant";

type Props = {
  refId: string;
  level?: number;
  refCode?: string;
  refTitle?: string;
  setLevelData?: any;
  onClickHandler?: () => void;
};

const LevelUnitList = forwardRef(
  (
    {refId, level, refCode, refTitle, setLevelData, onClickHandler}: Props,
    ref,
  ) => {
    const controller = new AbortController();
    const {convertAmount} = useConverter();
    const [loading, toggleLoading] = useToggleState();
    const [data, setData] = useState<any>();
    const [selectedUnit, setSelected] = useState<any>();
    const [submitLoading, toggleSubmit] = useToggleState();
    const [isOpen, toggle] = useToggleState();
    const [isOpenDetails, toggleDetails] = useToggleState();
    const convertTypeValue = (key: number, val: number) => {
      let value;
      switch (key) {
        case 0:
          value = val + " %";
          break;
        case 1:
          value = convertAmount(val);
          break;
        case 2:
          value = "x" + val;
          break;
        case 3:
          value = val + " % Pure";
          break;
      }
      return value;
    };

    useEffect(() => {
      getData();
      return () => {
        controller.abort();
      };
    }, [refId]); // eslint-disable-line react-hooks/exhaustive-deps

    const generateClassName = (key: number) => {
      return key ? "text-danger" : key === 0 ? "text-success" : "";
    };

    const generateOprationSymbol = (oprtype: number, valtype: number) => {
      if (valtype !== 2) return oprtype ? "-" : oprtype === 0 ? "+" : "";
    };

    const OnSubmitHandler = (list: any) => {
      toggleSubmit();
      const {title, id, code, itemValue, active, operationType, valueType} =
        list;
      const url = URLS.ADD_CALCULATION_UNIT_CUSTOMIZATION_ITEM;
      const body = {
        title,
        level,
        refIdentifier: refId,
        refCode,
        refTitle,
        calculationUnitId: id,
        calculationUnitCode: code,
        calculationUnitTitle: title,
        operationType,
        valueType,
        itemValue,
        active,
        validFromDate: "",
        validToDate: "",
      };
      ServiceApi.post(url, body).then(() => {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title",
        );
        toggleSubmit();
        toggle();
        getData();
      });
    };

    const getData = () => {
      toggleLoading();
      const url = URLS.GET_CALCULATION_UNIT_CUSTOMIZATION;
      const config = {params: {refId: refId}};
      ServiceApi.get(url, config)
        .then(({data}) => {
          setData(data);
          setLevelData && setLevelData(data);
          toggleLoading();
        })
        .catch(() => {
          toggleLoading();
        });
    };

    useImperativeHandle(ref, () => {
      return {
        getData,
      };
    }, []);

    return (
      <>
        <div className="space-y-4">
          <div className="flex justify-end">
            {loading ? (
              <Skeleton.Button />
            ) : (
              <Button
                onClick={() => (onClickHandler ? onClickHandler() : toggle())}
                size="sm"
                type="button"
              >
                <Icon className="mr-2" icon={"plus"} />
                <Text>priceEngine.purchasePrice.addNewCalculationUnit</Text>
              </Button>
            )}
          </div>
          {loading ? (
            <>
              {[1, 2, 3, 4].map(key => (
                <Skeleton.List key={key} />
              ))}
            </>
          ) : (
            <>
              {data?.calculationUnits?.length ? (
                <Table>
                  {data?.calculationUnits?.map((item: any) => {
                    return (
                      <tr className="*:text-gray-800" key={item.title}>
                        <td className="space-y-2 text-heading-6">
                          <h6>{item?.title}</h6>
                          <p>#{item?.calculationUnitCode}</p>
                        </td>
                        <td className="space-y-2 text-body-base">
                          <h6>
                            <Text>
                              {
                                unitTypes.find(
                                  type => type.value === item?.valueType,
                                )?.label
                              }
                            </Text>
                          </h6>
                          {item?.valueType < 4 && (
                            <p
                              className={`${generateClassName(
                                item?.operationType,
                              )}`}
                            >
                              <Text>
                                priceEngine.calculationUnits.defaultValueInList
                              </Text>{" "}
                              :{" "}
                              {generateOprationSymbol(
                                item?.operationType,
                                item?.valueType,
                              )}
                              {convertTypeValue(
                                item?.valueType,
                                item?.itemValue,
                              ) ?? "---"}
                            </p>
                          )}
                        </td>
                        <td className="space-y-2 text-body-base *:text-left">
                          <p>{`From : ${
                            item?.validFromDate
                              ? convertDate(
                                  item?.validFromDate,
                                  dateFormats.onlyDate,
                                )
                              : "--"
                          }`}</p>
                          <p>{`To : ${
                            item?.validFromDate
                              ? convertDate(
                                  item?.validToDate,
                                  dateFormats.onlyDate,
                                )
                              : "--"
                          }`}</p>
                        </td>
                        <td>
                          <Status.CalculationUnits id={item?.active} />
                        </td>

                        <td>
                          <Button
                            size="sm"
                            variant="light"
                            type="button"
                            onClick={() => {
                              setSelected(item);
                              toggleDetails();
                            }}
                          >
                            <Text>priceEngine.calculationUnits.details</Text>
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </Table>
              ) : (
                <div className="flex w-full flex-col items-center space-y-4">
                  <NoItems />
                </div>
              )}
            </>
          )}
        </div>
        <AddNewCalculationUnit
          isOpen={isOpen}
          toggle={toggle}
          onAddUnits={(list: PriceEngin.CalculationUnitItem) =>
            OnSubmitHandler(list)
          }
          loading={submitLoading}
        />
        <EditUnit
          isOpen={isOpenDetails}
          toggle={toggleDetails}
          getData={getData}
          id={data?.id}
          unit={selectedUnit}
          refId={refId}
          level={level}
          refCode={refCode}
          refTitle={refTitle}
        />
      </>
    );
  },
);

export default LevelUnitList;
