import {NotificationManager} from "common/notifications";
import {
  Breadcrumb,
  Button,
  Footer,
  Form,
  Skeleton,
  Tabs,
  Text,
  WithPermission,
  Wrapper,
} from "components";
import {useDataState} from "hooks";
import isEmpty from "lodash/isEmpty";
import {
  Fragment,
  createContext,
  createElement,
  useEffect,
  useState,
} from "react";
import {useParams} from "react-router";
import {Link, useSearchParams} from "react-router-dom";
import {ServiceApi, URLS} from "services";

//... tabs
import General from "./tabs/general";
import Permissions from "./tabs/permissions";

export const UserContext = createContext({});

export default function Details() {
  const {roleId} = useParams();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [headerData, setHeaderData] = useState<any>({});
  const [data, setData, setBaseData, isChanged] = useDataState<any>({});

  const tabs = [
    {
      label: "configurations.userManagment.roles.general",
      component: General,
    },
    {
      label: "configurations.userManagment.roles.permissions",
      component: Permissions,
    },
  ];

  const getData = () => {
    const url = URLS.GET_DETAILS_ROLES_URL(roleId);
    ServiceApi.get(url)
      .then(({data}) => {
        let result = JSON.parse(JSON.stringify(data));
        setBaseData(result);
        setHeaderData(result);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(getData, []);

  const submit = () => {
    setSubmitLoading(true);
    const url = URLS.PUT_ROLES_URL(roleId);
    const body = {...data};
    ServiceApi.put(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
        setBaseData(data);
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  return (
    <Tabs activeKey={tabs[0]?.label}>
      <Form onSubmit={submit} className="relative space-y-6">
        <Breadcrumb />
        <Wrapper>
          <Wrapper.Body className="flex flex-col items-start gap-5 lg:flex-row">
            <div className="space-y-4 lg:flex-1">
              {loading ? (
                <Fragment>
                  <Skeleton.Input />
                </Fragment>
              ) : (
                <Fragment>
                  <div className="space-y-4">
                    <h5 className="text-heading-5 font-semibold text-gray-800">
                      {headerData?.name}
                    </h5>
                  </div>
                </Fragment>
              )}
            </div>
          </Wrapper.Body>
          <Wrapper.Footer className="!py-0">
            <Tabs.ButtonGroup>
              {tabs.map(e => (
                <Tabs.Button key={e.label} eventKey={e.label}>
                  <Text>{e.label}</Text>
                </Tabs.Button>
              ))}
            </Tabs.ButtonGroup>
          </Wrapper.Footer>
        </Wrapper>
        {loading && (
          <Wrapper>
            <Wrapper.Body className="grid grid-cols-1 gap-4 lg:grid-cols-2">
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Wrapper.Body>
          </Wrapper>
        )}
        {tabs.map(e => (
          <Tabs.Item key={e.label} eventKey={e.label}>
            <UserContext.Provider
              value={{
                rolesData: data,
                setRolesData: setData,
                getData: getData,
                loading: loading,
              }}
            >
              {!isEmpty(data) && createElement(e.component)}
            </UserContext.Provider>
          </Tabs.Item>
        ))}
        <WithPermission permissions={["AS_UpdateRole"]}>
          <Footer show={!isChanged}>
            <Button as={Link} to={-1} variant="white">
              <Text>global.buttons.discard</Text>
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={!isChanged}
              loading={submitLoading}
            >
              <Text>global.buttons.saveChanges</Text>
            </Button>
          </Footer>
        </WithPermission>
      </Form>
    </Tabs>
  );
}
