const BRANDS = {
  ADD_BRAND_URL: "productservice/api/brands",
  GET_BRANDS_URL: "productservice/api/brands",
  GET_BRAND_DETAILS_URL: id => `productservice/api/brands/${id}`,
  UPDATE_BRAND_DETAILS_URL: id => `productservice/api/brands/${id}`,
  ADD_ASSETS_TO_BRAND_URL: "/productservice/api/brands/",
  UPLOAD_BRAND_IMAGES: id => `productservice/api/brands/${id}/logo`,
  UPDATE_BRAND_PRICE_TEMPLATE: id =>
    `productservice/api/brands/${id}/set-price-template`,
};

export default BRANDS;
