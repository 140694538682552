export const calculationLevel = {
  none: "Keine",
  tenant: "Unternehmen",
  legalEntity: "selbst. Filialen",
  channel: "Kanal",
  customer: "Kunde",
  supplier: "Lieferant",
  suppliers: "Lieferanten",
  program: "Programm",
  brand: "Marke",
  brands: "Marken",
  product: "Produkt",
  products: "Produkte",
  configurations: "Konfigurationen",
  units: "Einheiten",
  excludeAllAutoDiscount: "Alle automatischen Rabatte ausschließen",
  excludeAllOptionalDiscount: "Alle optionalen Rabatte ausschließen",
  excludeAllVoucherDiscount: "Alle Gutscheine ausschließen",
  activeForPim: "für PIM aktivieren",
  activeForIwofurn: "für IWOfurn aktivieren",
  activeForManual: "für manuelle Erfassung aktivieren",
  calculationUnit: "Berechnungseinheit",
  value: "Wert",
  details: "Details",
  addCalculationUnit: "Berechnungseinheit hinzufügen",
  addValidationPeriod: "Gültigkeitszeitraum hinzufügen",
  priceCalculationUnits: "Preiskalkulationseinheiten",
  step: "Step",
};
