import {InfoSection} from "../common";
import {useSelector} from "hooks";
import {calculationLevelEnum} from "constant";

export default function Tenant() {
  const companySetting = useSelector(s => s.companySetting);
  return (
    <div className="space-y-4">
      <InfoSection
        refId={companySetting?.companyId}
        level={calculationLevelEnum.tenant}
        refCode={""}
        refTitle={companySetting?.name}
      />
    </div>
  );
}
