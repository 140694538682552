import {NotificationManager} from "common/notifications";
import {
  Button,
  Form,
  Icon,
  InputGroup,
  Modal,
  Status,
  Text,
  WithPermission,
} from "components";
import {rules} from "constant";
import {useConverter} from "hooks";
import {useEffect, useState} from "react";
import {ServiceApi, URLS} from "services";

type changeStatusModalType = {
  isOpen: boolean;
  toggle: () => void;
  selected: any;
  comments: any;
  projectId: string;
  getData: any;
};

export default function ChangeStatusModal({
  isOpen,
  toggle,
  selected,
  comments,
  projectId,
  getData,
}: changeStatusModalType) {
  const {convertDate, convertAmount} = useConverter();
  const [comment, setComment] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    setComment("");
  }, [isOpen]);

  const submit = () => {
    setLoadingButton(true);
    let url = "";
    if (selected.status === 2) {
      url = URLS.PENDING_APPROVAL_URL(projectId);
    } else if (selected.status === 3) {
      url = URLS.STATUS_APPROVED_URL(projectId);
    }
    ServiceApi.post(url, {
      comment: comment,
      winnerCalculationHistoryId: selected.id,
    })
      .then(res => {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title",
        );
        toggle();
        getData();
      })
      .finally(() => {
        setLoadingButton(false);
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      width="w-[500px]"
      modalClassName="z-40"
    >
      <Form onSubmit={submit}>
        <Modal.Body className="max-h-100 space-y-8 overflow-auto">
          <div
            className={`grid ${
              selected?.shippingCostHasWarning && selected?.sellFactorHasWarning
                ? "grid-cols-2"
                : "grid-cols-1"
            }  gap-4`}
          >
            {selected?.shippingCostHasWarning && (
              <div className="space-y-2 rounded-md bg-danger-light px-2 py-4 text-center">
                <div>
                  <Icon
                    size="2x"
                    className="text-danger"
                    icon={"triangle-exclamation"}
                  />
                </div>
                <div>
                  <span className="text-body-2 font-normal text-danger">
                    <Text>status.salesList.shippingCostHasWarning</Text>
                  </span>
                </div>
                <div>
                  <span className="text-body-base font-normal text-gray-600">
                    <Text>
                      salesManagment.kitchenSales.salesList.targetShippingCost
                    </Text>
                    :
                  </span>
                  <span className="ml-1 text-body-base font-normal text-gray-800">
                    {convertAmount(selected?.targetShippingCost)}
                  </span>
                </div>
                <div>
                  <span className="text-body-base font-normal  text-gray-600">
                    <Text>
                      salesManagment.kitchenSales.salesList.shippingCost
                    </Text>
                    :
                  </span>
                  <span className="ml-1 text-body-base font-normal text-gray-800">
                    {convertAmount(selected?.shippingCost)}
                  </span>
                </div>
              </div>
            )}
            {selected?.sellFactorHasWarning && (
              <div className="space-y-2 rounded-md bg-danger-light px-2 py-4 text-center">
                <div>
                  <Icon
                    size="2x"
                    className="text-danger"
                    icon={"triangle-exclamation"}
                  />
                </div>
                <div>
                  <span className="text-body-2 font-normal text-danger">
                    <Text>status.salesList.sellFactorHasWarning</Text>
                  </span>
                </div>
                <div>
                  <span className="text-body-base font-normal text-gray-600">
                    <Text>
                      salesManagment.kitchenSales.salesList.targetSellFactor
                    </Text>
                    :
                  </span>
                  <span className="ml-1 text-body-base font-normal text-gray-800">
                    {selected?.targetSellFactor?.toFixed(2)}
                  </span>
                </div>
                <div>
                  <span className="text-body-base font-normal  text-gray-600">
                    <Text>
                      salesManagment.kitchenSales.salesList.sellFactor
                    </Text>
                    :
                  </span>
                  <span className="ml-1 text-body-base font-normal text-gray-800">
                    {selected?.sellFactor?.toFixed(2)}
                  </span>
                </div>
              </div>
            )}
          </div>
          {comments?.map((item: any) => {
            return (
              <div className="space-y-2 pb-2">
                <div className="flex items-center gap-2">
                  <div>
                    <Status.salesCommentsIconStatus id={item?.statusType} />
                  </div>
                  <div className="space-y-2">
                    <div>
                      <span className="text-body-2 font-normal text-gray-700">
                        {item?.userFullName}
                      </span>
                    </div>
                    <div>
                      <span className="text-body-2 font-medium text-gray-500">
                        {convertDate(item?.createdAt)}
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <span className="text-body-base font-normal text-gray-600">
                    {item?.comment}
                  </span>
                </div>
              </div>
            );
          })}
          <WithPermission
            permissions={[
              "SS_ChangeWinnerCalculationStatusToPendingApproval",
              "SS_ChangeWinnerCalculationStatusToApproved",
            ]}
          >
            <InputGroup
              as="short-textarea"
              label="salesManagment.kitchenSales.salesList.yourComment"
              value={comment}
              setValue={e => setComment(e)}
              rules={rules.required}
            />
          </WithPermission>
        </Modal.Body>
        <WithPermission
          permissions={[
            "SS_ChangeWinnerCalculationStatusToPendingApproval",
            "SS_ChangeWinnerCalculationStatusToApproved",
          ]}
        >
          <Modal.Footer className="flex items-center justify-between">
            <Button type="button" onClick={toggle} variant="white">
              <Icon className="mr-2" icon={"times"} />
              <Text>global.buttons.cancel</Text>
            </Button>
            <Button type="submit" variant="primary" loading={loadingButton}>
              <Text>global.buttons.approve</Text>
            </Button>
          </Modal.Footer>
        </WithPermission>
      </Form>
    </Modal>
  );
}
