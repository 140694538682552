import {
  Button,
  Form,
  Icon,
  Image,
  ImageUploader,
  InputGroup,
  LazyImage,
  Modal,
  Select,
  Text,
} from "components";
import { assetTypes, rules, types } from "constant";
import { SelectInfoPage, SelectProduct } from "containers";
import { useToggleState } from "hooks";
import { cloneDeep } from "lodash";
import { useContext, useState } from "react";
import { toast } from "react-hot-toast";
import { ServiceApi } from "services";
import { toggleProps } from "types";
import { PositionContext } from ".";
import { componentItemType } from "..";

type dataType = componentItemType;
type carouselFormProps = toggleProps & {
  componentId: string;
  initData?: dataType;
};
export default function CarouselForm({
  isOpen,
  toggle,
  componentId,
  initData = {
    id: "",
    title: "",
    subTitle: "",
    url: "",
    link: "",
    order: 0,
    isVisible: true,
    linkType: 0,
    products: [],
  },
}: carouselFormProps) {
  const isNew = !initData.id;
  const { positionData, setPositionData } = useContext(PositionContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initData);
  const [showImage, toggleImage] = useToggleState(false);
  const isInfoPage = data.linkType === 3;
  const isExternalLink = data.linkType === 6;
  const isChannelProduct = data.linkType === 9;

  const handleSetValue = (key: keyof dataType) => {
    return (value: any) => {
      const isProducts = key === "products";
      setData(p => ({
        ...p,
        [key]: value,
        ...(isProducts && { products: [value] }),
      }));
    };
  };
  const addCarousel = () => {
    setLoading(true);
    const url = `/productservice/api/positions/${positionData.id}/component/${componentId}/add-item`;
    const body = { ...data };
    ServiceApi.post(url, body)
      .then(({ data: id }) => {
        setPositionData(p => {
          const data = cloneDeep(p);
          const index = data.components.findIndex(e => e.id === componentId);
          data.components[index].items ??= [];
          data.components[index].items?.push({ ...body, id });
          return data;
        });
        setData(initData);
        toggle();
        toast.success("applications.positions.carouselAddToast");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const editCarousel = () => {
    setLoading(true);
    const url = `/productservice/api/positions/${positionData.id}/component/${componentId}/update-item/${data.id}`;
    const body = { ...data };
    ServiceApi.put(url, body)
      .then(() => {
        setPositionData(p => {
          const data = cloneDeep(p);
          const componentIndex = data.components.findIndex(
            e => e.id === componentId,
          );
          const items = data.components[componentIndex].items ?? [];
          const itemIndex = items.findIndex(e => e.id === body.id);
          items[itemIndex] = body;
          return data;
        });
        setData(body);
        toggle();
        toast.success("applications.positions.carouselEditToast");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} modalClassName="!z-40">
      <Form onSubmit={isNew ? addCarousel : editCarousel}>
        <Modal.Body className="space-y-8 overflow-visible">
          <LazyImage
            onClick={toggleImage}
            src={data.url}
            className="aspect-image"
            editor
            isDynamic
          />
          <ImageUploader
            isOpen={showImage}
            toggle={toggleImage}
            image={data.url}
            onUpload={image => setData(p => ({ ...p, url: image.url }))}
            type={assetTypes.Applications}
          />
          <InputGroup
            label="applications.positions.carouselSubtitle"
            value={data.subTitle}
            setValue={handleSetValue("subTitle")}
            rules={rules.required}
          />
          <InputGroup
            label="applications.positions.carouselTitle"
            value={data.title}
            setValue={handleSetValue("title")}
            rules={rules.required}
          />
          <Select
            label="applications.positions.carouselLinkAssigned"
            value={data.linkType}
            setValue={handleSetValue("linkType")}
            rules={rules.required}
            items={types.mobileAppLink}
          />
          {isExternalLink && (
            <InputGroup
              label="applications.positions.carouselLink"
              value={data.link}
              setValue={handleSetValue("link")}
              rules={rules.required}
            />
          )}
          {isInfoPage && (
            <SelectInfoPage
              value={data.link}
              setValue={handleSetValue("link")}
            />
          )}
          {isChannelProduct && (
            <SelectProduct
              value={data.products}
              setValue={handleSetValue("products")}
            />
          )}
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-between">
          <Button type="button" variant="white" size="sm" onClick={toggle}>
            <Icon icon="close" />{" "}
            <Text>applications.positions.carouselCancelButton</Text>
          </Button>
          <Button type="submit" variant="primary" size="sm" loading={loading}>
            <Text>applications.positions.carouselSubmitButton</Text>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
