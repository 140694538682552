import {
  Badge,
  Button,
  Icon,
  NoItems,
  SearchBox,
  Skeleton,
  Status,
  Table,
  Text,
  WithPermission,
} from "components";
import { withPermission } from "hoc";
import { usePermission, useToggleState } from "hooks";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ServiceApi, URLS } from "services";
import EditDocumentTemplateDrawer from "./edit-drawer";
import { ItemsType } from "../../types/template";

type Prop = {
  page: "company" | "salesChannel";
  channelCode?: string;
};

function TemplateList({ page, channelCode }: Prop) {
  const controller = new AbortController();
  const [data, setData] = useState<ItemsType[]>();
  const [loadingList, toggleLoadingList] = useToggleState();
  const [selected, setSelected] = useState<any>();
  const [searchParams] = useSearchParams();
  const [params, setParams] = useSearchParams();
  const [isOpenEdit, toggleEdit] = useToggleState(false);
  const updatePermission = usePermission(
    "SC_UpdateSaleServiceDocumentTemplate",
  );

  const handleSetParams = (key: any) => {
    return (value: any) => {
      setParams(prev => {
        value !== null && value !== ""
          ? prev.set(key, value)
          : prev.delete(key);
        return prev;
      });
    };
  };

  const getData = () => {
    let params: any = {};
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    params.channelCode = channelCode;
    const url =
      page === "company"
        ? URLS.TENANT_DOCUMENT_TEMPLATE_URL
        : URLS.CHANNEL_DOCUMENT_TEMPLATE_URL;
    const config = { params };
    toggleLoadingList();
    ServiceApi.get(url, { ...config })
      .then(({ data }) => {
        setData(data);
      })
      .catch(() => {
        setData([]);
      })
      .finally(() => {
        toggleLoadingList();
      });
  };

  useEffect(() => {
    setSelected(undefined);
    getData();
    return () => {
      controller.abort();
    };
  }, [params, page]); // eslint-disable-line react-hooks/exhaustive-deps

  const getTypeAndIcon = (type: string) => {
    if (type?.toLowerCase()?.includes("word"))
      return { icon: "file-word", type: "Word document", accept: ".doc,.docx" };
    else if (type?.toLowerCase()?.includes("excel")) {
      return { icon: "file-excel", type: "Excel", accept: ".xls, .xlsx" };
    } else if (type?.toLowerCase()?.includes("pdf")) {
      return { icon: "file-pdf", type: "pdf", accept: ".pdf" };
    } else return { icon: "file", type: "Unknown", accept: "" };
  };

  const getCheckOrMarkIcon = (value: boolean) => {
    return (
      <Icon
        icon={value ? "circle-check" : "circle-xmark"}
        className={value ? "text-success" : "text-danger"}
        size="lg"
      />
    );
  };

  const handleOpenEditDrawer = (item: ItemsType) => {
    if (updatePermission) {
      setSelected(item);
      toggleEdit();
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex flex-wrap justify-between gap-3">
        <SearchBox
          value={params?.get("keyword") || ""}
          onSubmit={handleSetParams("keyword")}
          disabled={loadingList}
          totalItems={data?.length}
          onReload={getData}
          loading={loadingList}
          className="!w-auto"
        />
        <Button
          type="button"
          onClick={() => {
            setSelected({});
            toggleEdit();
          }}
        >
          <Icon className="mr-2" icon={"plus"} />
          <Text>templates.addNewTemplate</Text>
        </Button>
      </div>
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data && data?.length !== 0 ? (
            <Table>
              {data?.map(item => {
                const type = item.uploadedFile
                  ? item.uploadedFile.type
                  : item.defaultDocumentTemplate?.uploadedFile.type;
                return (
                  <tr key={item.id}>
                    <td className="flex gap-5">
                      <Icon
                        icon={getTypeAndIcon(type || "").icon}
                        className="text-primary"
                        size="3x"
                      />
                      <div className="space-y-2 text-left text-gray-800">
                        <h6 className="text-heading-6 ">
                          <span
                            className={`${updatePermission && "cursor-pointer"}`}
                            onClick={() => handleOpenEditDrawer(item)}
                          >
                            {item?.name}&nbsp;&nbsp;&nbsp;
                          </span>
                          <Status.TemplateType id={item.documentTemplateType} />
                        </h6>
                        <p className="text-body-2">
                          {getTypeAndIcon(type || "").type}
                        </p>
                        {/* <p className="text-body-2">
                          <Text>templates.code</Text>:
                          <span className="ml-1">{item.code}</span>
                        </p> */}
                      </div>
                    </td>
                    <td>
                      {item.isCustomTemplate && (
                        <Badge variant="gray">
                          <Text>templates.customTemplate</Text>
                        </Badge>
                      )}
                    </td>
                    <td>
                      <div className="space-y-2 text-body-base">
                        <p className="flex gap-1">
                          {getCheckOrMarkIcon(item.signMandatory)}
                          <Text>templates.requiredToBeSigned</Text>
                        </p>
                        <p className="flex gap-1">
                          {getCheckOrMarkIcon(item.needSendToErp)}
                          <Text>templates.erp</Text>
                        </p>
                        <p className="flex gap-1">
                          {getCheckOrMarkIcon(item.needSendToCrm)}
                          <Text>templates.crm</Text>
                        </p>
                      </div>
                    </td>
                    <td>
                      <WithPermission
                        permissions={["SC_UpdateSaleServiceDocumentTemplate"]}
                      >
                        <Button
                          type="button"
                          size="sm"
                          variant="light"
                          onClick={() => handleOpenEditDrawer(item)}
                        >
                          <Text>global.buttons.edit</Text>
                        </Button>
                      </WithPermission>
                    </td>
                  </tr>
                );
              })}
            </Table>
          ) : (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
            </div>
          )}
        </>
      )}
      {selected && (
        <EditDocumentTemplateDrawer
          isOpen={isOpenEdit}
          toggle={toggleEdit}
          getData={getData}
          selected={selected}
          page={page}
          channelCode={channelCode}
          type={getTypeAndIcon(
            selected?.defaultDocumentTemplate
              ? selected?.defaultDocumentTemplate?.uploadedFile.type
              : selected?.uploadedFile?.type,
          )}
        />
      )}
    </div>
  );
}
export default withPermission(TemplateList, [
  "SC_GetSaleServiceDocumentTemplate",
]);
