import {NotificationManager} from "common/notifications";
import {
  Breadcrumb,
  Button,
  Form,
  Icon,
  Stepper,
  Text,
  WithPermission,
  Wrapper,
} from "components";
import {Fragment, createContext, createElement, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ServiceApi, URLS} from "services";
import Basic from "./tabs/basic";
import Phase from "./tabs/phase";
import Review from "./tabs/review";

export const AddSalesTemplatesContext = createContext({});

export default function Create() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [data, setData] = useState<any>({
    code: "",
    name: "",
    sellPhases: [
      {
        title: "",
        value: "",
        discount: "",
        duration: "",
        sellerCommission: "",
        description: "",
        checkListItemIds: [],
      },
    ],
  });
  const [dataImg, setDataImg] = useState<any>({
    thumbnailUrl: "",
    type: "",
    url: "",
  });
  const navigate = useNavigate();
  const tabs = [
    {
      label: "salesManagment.sellOffSales.salesTemplates.basicInfo",
      desc: "salesManagment.sellOffSales.salesTemplates.basicInfoDesc",
      component: Basic,
    },
    {
      label: "salesManagment.sellOffSales.salesTemplates.phaseInfo",
      desc: "salesManagment.sellOffSales.salesTemplates.phaseInfoDesc",
      component: Phase,
    },
    {
      label: "salesManagment.sellOffSales.salesTemplates.review",
      desc: "salesManagment.sellOffSales.salesTemplates.reviewDesc",
      component: Review,
    },
  ];
  const [loading, setLoading] = useState(false);
  const activeTab = tabs[activeTabIndex];
  const isFirstTab = activeTabIndex === 0;
  const isLastTab = activeTabIndex === tabs.length - 1;
  const goNext = () => {
    if (isLastTab) return submit();
    setActiveTabIndex(p => p + 1);
  };
  const goPrev = () => {
    if (isFirstTab) return;
    setActiveTabIndex(p => p - 1);
  };

  const submit = () => {
    setLoading(true);
    const url = URLS.ADD_SELL_PHASE_URL;
    ServiceApi.post(url, data)
      .then(() => {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title",
        );
        navigate(-1);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="space-y-6">
      <Breadcrumb />
      <div className="grid grid-cols-1 items-start gap-6 lg:grid-cols-12">
        <Stepper tabs={tabs as []} activeTab={activeTabIndex} />
        <Wrapper
          as={Form}
          onSubmit={goNext}
          className="col-span-full lg:col-span-8"
        >
          <Wrapper.Body className="mx-auto w-full space-y-8 lg:w-[calc(100%-9rem)]">
            <AddSalesTemplatesContext.Provider
              value={{
                dataImg,
                setDataImg,
                data,
                setData,
              }}
            >
              {createElement(activeTab.component)}
            </AddSalesTemplatesContext.Provider>
            <div className="flex items-center justify-between">
              <Button
                type="button"
                variant="light"
                disabled={isFirstTab}
                onClick={goPrev}
              >
                <Icon icon="arrow-left" /> <Text>global.buttons.back</Text>
              </Button>
              <WithPermission permissions={["SC_CreateSellPhaseTemplate"]}>
                <Button type="submit" variant="primary" loading={loading}>
                  {isLastTab ? (
                    <Fragment>
                      <Icon icon="check" /> <Text>global.buttons.submit</Text>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Text>global.buttons.next</Text>{" "}
                      <Icon icon="arrow-right" />
                    </Fragment>
                  )}
                </Button>
              </WithPermission>
            </div>
          </Wrapper.Body>
        </Wrapper>
      </div>
    </div>
  );
}
