import { NotificationManager } from "common/notifications";
import {
  Button,
  Drawer,
  Form,
  Icon,
  InputGroup,
  MultiSelect,
  Skeleton,
  Text
} from "components";
import { config, rules } from "constant";
import { FormEvent, useEffect, useRef, useState } from "react";
import { ServiceApi, URLS } from "services";

export default function Add(props: any) {
  const [loadingList, setLoadingList] = useState(false);
  const [data, setData] = useState<any>({})
  const [chooseRoleItems, setChooseRoleItems] = useState([])
  const [loadingButton, setLoadingButton] = useState(false);
  const [params] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  });
  const formRef = useRef<any>();

  useEffect(() => {
    if (props.isOpen) {
      getDataRole();
      setLoadingButton(false);
    }
  }, [props.isOpen]);// eslint-disable-line react-hooks/exhaustive-deps


  const getDataRole = () => {
    const url = URLS.GET_ROLES_URL;
    const config = { params };
    setLoadingList(true);
    ServiceApi.get(url, config).then(({ data }) => {
      setChooseRoleItems(data?.items);
    }).finally(() => {
      setLoadingList(false);
    });
    setData({
      name: "",
      description: "",
      roles: []
    })
  }


  const handleSetData = (key: any) => {
    return (value: any) => setData((p: any) => ({ ...p, [key]: value }));
  };


  const onSubmitForm = (formData: any) => {
    setLoadingButton(true);
    const url = URLS.POST_GROUP_URL;
    ServiceApi.post(url, formData).then(() => {
      NotificationManager.success(
        "global.toast.add-msg",
        "global.toast.add-title"
      );
      props.toggle()
      props.getData()
    }).finally(() => {
      setLoadingButton(false);
    });
  };

  const submit = (e?: FormEvent) => {
    formRef.current.submitHandler(e, data);
  };

  return (
    <Drawer isOpen={props.isOpen} toggle={props.toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pt-6 pr-4">
          <div className="flex items-center justify-between">
            <h2 className="text-gray-800 text-heading-2"><Text>configurations.userManagment.userGroups.addGroup</Text></h2>
            <div className="flex items-center justify-between">
              <Button
                type="button"
                variant="light"
                size="sm"
                className="ml-2"
                onClick={() => props.toggle()}
              >
                <Icon className="mr-2" icon={"times"} /><Text>global.buttons.close</Text>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-4 pr-4">
          <Form ref={formRef} className="space-y-4 pr-4" onSubmit={onSubmitForm} id={"create-modal"}>
            <InputGroup
              label="configurations.userManagment.userGroups.name"
              value={data.name}
              setValue={handleSetData("name")}
              rules={rules.required}
            />
            <InputGroup
              as="short-textarea"
              label="configurations.userManagment.userGroups.description"
              value={data.description}
              setValue={handleSetData("description")}
              rules={rules.required}

            />
            {loadingList ?
              <Skeleton.Input /> :
              <MultiSelect
                label="configurations.userManagment.userGroups.chooseRole"
                items={chooseRoleItems}
                value={data?.roles}
                setValue={(value) => {
                  return setData((p: any) => ({ ...p, roles: value.length !== 0 ? value?.map((val: any) => val.id) : [] }));
                }}
                rules={rules.required}
              />}
          </Form>
        </Drawer.Body>
        <Drawer.Footer className="flex justify-between">
          <Button type="button" className="mr-2" onClick={() => props.toggle()} variant="light">
            <Text>global.buttons.cancel</Text>
          </Button>
          <Button type="button" onClick={submit} loading={loadingButton}>
            <Text>global.buttons.submit</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
