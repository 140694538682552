import {Skeleton, Text, Wrapper, InputGroup, Icon} from "components";
import {NotificationManager} from "common/notifications";
import {dateFormats} from "constant";
import {useConverter} from "hooks";
import {Fragment, useContext} from "react";
import {CustomerContext} from "..";

export default function General() {
  const {userData, loading} = useContext<any>(CustomerContext);
  const {convertDate} = useConverter();

  const genderType = (e: any) => {
    switch (e) {
      case 1:
        return "Male";
      case 2:
        return "Female";
      case 3:
        return "Diverse";
      default:
        return "Rather not say";
    }
  };

  const salutationType = (e: any) => {
    switch (e) {
      case 1:
        return "Dr";
      case 2:
        return "DrIng";
      case 3:
        return "DrHC";
      case 4:
        return "Prof";
      default:
        return "None";
    }
  };

  return (
    <div>
      <Wrapper>
        <Wrapper.Header>
          <h1 className="text-heading-2 font-semibold">
            <Text>customers.customerList.basicInfo</Text>
          </h1>
        </Wrapper.Header>
        <Wrapper.Body className="grid grid-cols-1 gap-8 lg:grid-cols-2">
          {loading ? (
            <Fragment>
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Fragment>
          ) : (
            <Fragment>
              <InputGroup
                label="customers.customerList.firstName"
                value={userData?.firstName}
                disabled
              />
              <InputGroup
                label="customers.customerList.lastName"
                value={userData?.lastName}
                disabled
              />
              <InputGroup
                label="customers.customerList.emailAddress"
                value={userData?.emailAddress}
                disabled
              />
              <InputGroup
                label="customers.customerList.cimNumber"
                value={userData?.cimNumber}
                disabled
                append={
                  <span
                    className="input-group-text"
                    onClick={() => {
                      window.navigator.clipboard.writeText(userData?.cimNumber);
                      NotificationManager.success(
                        "global.toast.copy-msg",
                        "global.toast.copy-title",
                      );
                    }}
                  >
                    <Icon
                      className="ml-6 cursor-pointer text-primary"
                      icon={"files"}
                    />
                  </span>
                }
              />
              <InputGroup
                label="customers.customerList.phoneNumber"
                value={
                  userData?.phoneNumber
                    ? `+${userData?.phoneNumber?.countryCode} ${userData?.phoneNumber?.number}`
                    : null
                }
                disabled
              />
              <InputGroup
                label="customers.customerList.mobileNumber"
                value={
                  userData?.mobileNumber
                    ? `+${userData?.mobileNumber?.countryCode} ${userData?.mobileNumber?.number}`
                    : null
                }
                disabled
              />
              <InputGroup
                label="customers.customerList.gender"
                value={genderType(userData?.gender)}
                disabled
              />
              <InputGroup
                label="customers.customerList.birthdate"
                value={convertDate(userData?.birthdate, dateFormats.onlyDate)}
                disabled
              />
              <InputGroup
                label="customers.customerList.title"
                value={salutationType(userData?.salutation)}
                disabled
              />
            </Fragment>
          )}
        </Wrapper.Body>
      </Wrapper>
    </div>
  );
}
