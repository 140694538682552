import {NotificationManager} from "common/notifications";
import {AxiosRequestConfig} from "axios";
import {
  Button,
  Drawer,
  Form,
  Icon,
  InputGroup,
  Text,
  WithPermission,
  Toggler,
  FileUploader,
  LoadingSvg,
  RadioButton,
  Select,
} from "components";
import {FormEvent, useEffect, useRef, useState} from "react";
import {rules} from "constant";
import {ServiceApi, URLS} from "services";
import {useDataState, useToggleState} from "hooks";
import {ItemsType} from "../../types/template";

type Props = {
  isOpen: boolean;
  toggle: () => void;
  getData: () => void;
  selected?: ItemsType;
  page: "company" | "salesChannel";
  type: {type: string; icon: string; accept: string};
  channelCode?: string;
};

export default function EditDocumentTemplateDrawer({
  isOpen,
  toggle,
  getData,
  selected,
  page,
  channelCode,
}: Props) {
  const [loading, setLoading] = useToggleState();
  const formRef = useRef<any>();
  const [data, setData, setBaseData, isChanged] = useDataState<any>({});
  const [file, setFile] = useState<any>();
  const [loadingFile, toggleLoadingFile] = useToggleState();
  const [deletLoading, toggleDeleteLoading] = useToggleState();
  const [importTemplateFromCompany, setImportTemplateFromCompany] =
    useState<boolean>(true);
  const [selectedCompanyTemplate, setSelectedCompanyTemplate] = useState();
  const [companyTemplateList, setCompanyTemplateList] = useState<ItemsType[]>(
    [],
  );

  const initialValue = {
    name: "",
    documentTemplateType: 0,
    signMandatory: false,
    needSendToErp: false,
    needSendToCrm: false,
  };

  useEffect(() => {
    setFile({});
    setSelectedCompanyTemplate(undefined);
    if (selected?.id) setBaseData(selected);
    else setBaseData(initialValue);
  }, [selected]);

  const getCompanyTemplates = () => {
    let params: any = {};
    params.channelCode = channelCode;
    const url = URLS.TENANT_DOCUMENT_TEMPLATE_URL;
    const config = {params};
    ServiceApi.get(url, {...config})
      .then(({data}) => {
        setCompanyTemplateList(data);
      })
      .catch(() => {
        setData([]);
      });
  };

  const handleSelectedCompanyTemplate = (selected: string) => {
    const selectedTemplate = companyTemplateList.filter(
      item => item.id === selected,
    )[0];
    if (selectedTemplate)
      setData({
        documentTemplateType: selectedTemplate.documentTemplateType,
        name: selectedTemplate.name,
        needSendToCrm: selectedTemplate.needSendToCrm,
        needSendToErp: selectedTemplate.needSendToErp,
        signMandatory: selectedTemplate.signMandatory,
        uploadedFile: selectedTemplate.uploadedFile,
      });
  };

  const onSubmitForm = (formData: any) => {
    setLoading();
    const params = {channelCode: channelCode};
    const body = formData;
    if (formData.id == null) {
      const url =
        page === "company"
          ? URLS.TENANT_DOCUMENT_TEMPLATE_URL
          : URLS.CHANNEL_DOCUMENT_TEMPLATE_URL;
      ServiceApi.post(url, body, {params})
        .then(() => {
          NotificationManager.success(
            "global.toast.add-msg",
            "global.toast.add-title",
          );
          toggle();
          getData();
        })
        .finally(() => {
          setLoading();
          setBaseData(data);
        });
    } else {
      const url =
        page === "company"
          ? URLS.UPDATE_TENANT_DOCUMENT_TEMPLATE_URL(formData.id)
          : URLS.UPDATE_CHANNEL_DOCUMENT_TEMPLATE_URL(formData.id);
      ServiceApi.put(url, body, {params})
        .then(() => {
          NotificationManager.success(
            "global.toast.edit-msg",
            "global.toast.edit-title",
          );
          toggle();
          getData();
        })
        .finally(() => {
          setLoading();
          setBaseData(data);
        });
    }
  };

  const onChangeHandler = (key: any) => {
    return (value: any) => setData((p: any) => ({...p, [key]: value}));
  };

  const submit = (e?: FormEvent) => {
    let payLoad = data;
    if (!file) {
      payLoad = {
        ...data,
        uploadedFile: null,
      };
    }
    formRef.current.submitHandler(e, payLoad);
  };

  const handleDeleteTemplate = () => {
    setData((p: any) => ({...p, uploadedFile: null}));
    setFile("");
  };

  const handleSetFile = (file: any) => {
    setFile(file);
    setData((p: any) => ({
      ...p,
      uploadedFile: {
        type: file?.type,
        url: file?.url,
        thumbnailUrl: file?.thumbnailUrl,
      },
    }));
  };

  const downloadFileHandler = async (url: string) => {
    let dataUrl = url;
    toggleLoadingFile();
    if (dataUrl?.includes("/get-file")) {
      const config: AxiosRequestConfig = {
        responseType: "blob",
        params: {withoutSubscriptionKey: true},
      };
      await ServiceApi.get(dataUrl, config).then(({data}) => {
        dataUrl = URL.createObjectURL(data);
      });
    }
    toggleLoadingFile();
    return dataUrl;
  };

  const deleteTemplateHandler = () => {
    toggleDeleteLoading();
    const params = {channelCode: channelCode};
    const url =
      page === "company"
        ? URLS.UPDATE_TENANT_DOCUMENT_TEMPLATE_URL(selected?.id)
        : URLS.UPDATE_CHANNEL_DOCUMENT_TEMPLATE_URL(selected?.id);
    ServiceApi.delete(url, {params})
      .then(() => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title",
        );
        toggle();
        getData();
      })
      .finally(() => toggleDeleteLoading());
  };

  const getTypeAndIcon = (type: string) => {
    if (type?.toLowerCase()?.includes("word"))
      return {icon: "file-word", type: "Word document", accept: ".doc,.docx"};
    else if (type?.toLowerCase()?.includes("excel")) {
      return {icon: "file-excel", type: "Excel", accept: ".xls, .xlsx"};
    } else if (type?.toLowerCase()?.includes("pdf")) {
      return {icon: "file-pdf", type: "pdf", accept: ".pdf"};
    } else return {icon: "file", type: "Unknown", accept: ""};
  };

  useEffect(() => {
    if (page === "salesChannel" && !selected?.id && channelCode)
      getCompanyTemplates();
  }, [page, channelCode]);

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pr-4 pt-6">
          <div className="flex items-center justify-between">
            <h2 className="text-heading-2 text-gray-800">
              {selected?.id ? (
                <Text>templates.edit</Text>
              ) : (
                <Text>templates.insertNewTemplate</Text>
              )}
            </h2>
            <div className="flex items-center justify-between">
              {selected?.id && (
                <Button
                  type="button"
                  variant="danger"
                  light
                  size="sm"
                  loading={deletLoading}
                  onClick={deleteTemplateHandler}
                >
                  <Icon icon="trash" />
                </Button>
              )}
              <Button
                type="button"
                variant="light"
                className="ml-2"
                onClick={() => toggle()}
              >
                <Icon className="mr-2" icon={"times"} />
                <span className="text-body-2">
                  {" "}
                  <Text>global.buttons.close</Text>
                </span>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body>
          <Form
            ref={formRef}
            onSubmit={onSubmitForm}
            className="mt-4 space-y-8"
            id={"create-drawer"}
          >
            {page === "salesChannel" && !selected?.id && (
              <>
                <p className="text-heading-6 text-gray-800">
                  <Text>templates.addTemplate</Text>
                </p>
                <RadioButton
                  label="templates.importFromCompany"
                  value={!!importTemplateFromCompany}
                  setValue={() => setImportTemplateFromCompany(true)}
                  labelClassName="text-heading-5 text-gray-700"
                />
                <RadioButton
                  label="templates.createNew"
                  value={!importTemplateFromCompany}
                  setValue={() => {
                    setImportTemplateFromCompany(false);
                    setData(initialValue);
                    setSelectedCompanyTemplate(undefined);
                  }}
                  labelClassName="text-heading-5 text-gray-700"
                />
              </>
            )}
            {page === "salesChannel" &&
              !selected?.id &&
              !!importTemplateFromCompany && (
                <Select
                  label="templates.companyTemplate"
                  placeholder="global.select"
                  items={companyTemplateList}
                  value={selectedCompanyTemplate}
                  setValue={v => {
                    setSelectedCompanyTemplate(v);
                    handleSelectedCompanyTemplate(v);
                  }}
                />
              )}
            {(page === "company" || !importTemplateFromCompany) &&
              !data?.uploadedFile?.url &&
              !file?.url && (
                <>
                  <div className="rounded bg-warning-light p-2 text-body-base text-warning">
                    <Icon icon="circle-info" className="mr-2" />
                    <Text>templates.fileWarning</Text>
                  </div>
                  <FileUploader
                    value={file?.url ? file : null}
                    setValue={setFile}
                    getResponse={handleSetFile}
                    isHorizontal
                    type={7}
                    accept={".pdf, .doc, .docx"}
                  />
                </>
              )}
            {(page === "company" ||
              !!selected?.id ||
              !importTemplateFromCompany) &&
              (data?.uploadedFile?.url || file?.url) && (
                <div className="flex gap-4 rounded-xl bg-gray-100 p-4">
                  <Icon
                    icon={
                      getTypeAndIcon(data?.uploadedFile?.type || file?.type)
                        .icon || "file-arrow-up"
                    }
                    className="text-primary"
                    size="3x"
                  />
                  <div className="flex flex-1 flex-col justify-center gap-3">
                    <h5 className="text-heading-5 ">{data?.name}</h5>
                    <p className="text-body-base text-gray-600">
                      {
                        getTypeAndIcon(data?.uploadedFile?.type || file?.type)
                          .type
                      }
                    </p>
                  </div>
                  <div className="flex items-center gap-4">
                    <Icon
                      icon="trash"
                      className="cursor-pointer text-danger hover:text-danger-active"
                      size="lg"
                      onClick={handleDeleteTemplate}
                    />
                    {loadingFile ? (
                      <LoadingSvg size="sm" />
                    ) : (
                      <Icon
                        icon="download"
                        className="cursor-pointer text-primary hover:text-primary-active"
                        size="lg"
                        onClick={async () => {
                          const url = await downloadFileHandler(
                            data?.uploadedFile.url,
                          );
                          window.open(url, "_blank");
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
            {!selected?.id && (
              <>
                <label className="h6 block w-full truncate font-[400] text-gray-800">
                  <Text>templates.type</Text>:
                </label>
                <div className="grid grid-cols-2 gap-4">
                  <RadioButton
                    label="templates.offer"
                    value={data?.documentTemplateType === 0}
                    setValue={() => onChangeHandler("documentTemplateType")(0)}
                  />
                  <RadioButton
                    label="templates.order"
                    value={data?.documentTemplateType === 1}
                    setValue={() => onChangeHandler("documentTemplateType")(1)}
                  />
                  <RadioButton
                    label="templates.customerLegal"
                    value={data?.documentTemplateType === 2}
                    setValue={() => onChangeHandler("documentTemplateType")(2)}
                  />
                  <RadioButton
                    label="templates.termsAndCondition"
                    value={data?.documentTemplateType === 3}
                    setValue={() => onChangeHandler("documentTemplateType")(3)}
                  />
                </div>
              </>
            )}

            <InputGroup
              label="templates.templateName"
              placeholder="templates.templateName"
              value={data?.name}
              setValue={onChangeHandler("name")}
              rules={rules.required}
            />
            {/* <InputGroup
              label="templates.code"
              placeholder="templates.code"
              value={data?.code}
              setValue={onChangeHandler("code")}
              rules={rules.required}
            /> */}
            <hr className="bg-gray-200" />
            <div className=" space-y-3">
              <div className="rounded-xl p-3 shadow-[0px_4px_20px_0px_#3F42541A]">
                <Toggler
                  label={"templates.requiredToBeSigned"}
                  value={data?.signMandatory}
                  setValue={onChangeHandler("signMandatory")}
                />
              </div>
              <div className="rounded-xl p-3 shadow-[0px_4px_20px_0px_#3F42541A]">
                <Toggler
                  label={"templates.erp"}
                  value={data?.needSendToErp}
                  setValue={onChangeHandler("needSendToErp")}
                />
              </div>
              <div className="rounded-xl p-3 shadow-[0px_4px_20px_0px_#3F42541A]">
                <Toggler
                  label={"templates.crm"}
                  value={data?.needSendToCrm}
                  setValue={onChangeHandler("needSendToCrm")}
                />
              </div>
            </div>
          </Form>
        </Drawer.Body>
        <WithPermission permissions={["SC_UpdateSaleServiceDocumentTemplate"]}>
          <Drawer.Footer className="flex justify-between">
            <Button variant="white" type="button" onClick={() => toggle()}>
              <Text>global.buttons.cancel</Text>
            </Button>
            <Button
              type="button"
              variant="primary"
              onClick={submit}
              loading={loading}
              disabled={!isChanged || !data?.uploadedFile?.url}
            >
              {selected?.id ? (
                <Text>global.buttons.submit</Text>
              ) : (
                <Text>templates.addTemplate</Text>
              )}
            </Button>
          </Drawer.Footer>
        </WithPermission>
      </Drawer.Menu>
    </Drawer>
  );
}
