import {calculationUnits} from "./calculation-units";
import {calculationLevel} from "./calculation-level";
import {purchasePrice} from "./price-templates/purchase-price";
import {salesPrice} from "./price-templates/sales-price";
import {simulator} from "./simulator";

export const priceEngine = {
  calculationUnits,
  calculationLevel,
  purchasePrice,
  salesPrice,
  simulator,
};
