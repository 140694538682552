import React from "react";
import TemplateList from "../../../compositions/templates";

const Templates: React.FunctionComponent = () => {
  return (
    <TemplateList page="company" />
  );
};

export default Templates;
