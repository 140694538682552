import {useContext} from "react";
import {CheckBox, Wrapper} from "components";
import {CalculationUnitContext, ContextTypes} from "..";
import {calculationLevel} from "constant";
import {usePermission} from "hooks";

export default function CustomizableLevels() {
  const {data, setData} = useContext<ContextTypes>(CalculationUnitContext);

  const updatePermission = usePermission(
    "PE_ModifyCalculationUnitCustomization",
  );

  const onChangeHandler = (value: number) => {
    let clone = data.calculationLevels.filter(item => item);
    if (clone.includes(value)) {
      clone = clone.filter(item => item !== value);
    } else {
      clone.push(value);
    }
    setData((p: any) => ({
      ...p,
      calculationLevels: clone.length && value !== 0 ? clone : [0],
    }));
  };

  return (
    <Wrapper>
      <Wrapper.Body>
        <div className="flex flex-col gap-8">
          {calculationLevel.map(unit => (
            <CheckBox
              label={unit.label}
              value={
                data?.calculationLevels.includes(
                  unit.value as number,
                ) as boolean
              }
              setValue={e => onChangeHandler(unit.value as number)}
              disabled={!updatePermission}
            />
          ))}
        </div>
      </Wrapper.Body>
    </Wrapper>
  );
}
