import {createElement, useState} from "react";
import {Wrapper, Text} from "components";
import ShippingLogestic from "./shipping-logestic";
import LocationsTab from "./locations";

export default function ShippingTab() {
  const [selectedTab, dispatchTab] = useState<any>({
    label: "productManagement.products.Details.shippingLogestic",
    component: ShippingLogestic,
  });
  const tabs = [
    {
      label: "productManagement.products.Details.shippingLogestic",
      component: ShippingLogestic,
    },
    // {
    //   label: "productManagement.products.Details.locationsTab",
    //   component: LocationsTab,
    // },
  ];

  return (
    <div className="grid grid-cols-1 gap-4  xl:grid-cols-4">
      <Wrapper className="col-span-3 flex flex-row  gap-2 p-4 xl:col-span-1 xl:h-[240px] xl:flex-col  xl:space-y-4">
        {tabs.map((tab: any) => (
          <span
            className={`flex cursor-pointer items-center  rounded-2xl p-3 text-body-base font-medium xl:h-12  ${selectedTab?.label === tab.label ? "bg-primary-light text-primary" : "bg-gray-100 text-gray-600"}`}
            onClick={() => dispatchTab(tab)}
          >
            <Text>{tab.label}</Text>
          </span>
        ))}
      </Wrapper>
      <div className="col-span-3 xl:col-span-3">
        {selectedTab && createElement(selectedTab.component)}
      </div>
    </div>
  );
}
