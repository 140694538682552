import {apiKeys} from "./api-keys";
import {ssoSettings} from "./sso-settings";
import {dataStorage} from "./data-storage";
import {templates} from "./templates";

export const globalSettings = {
  apiKeys: apiKeys,
  ssoSettings: ssoSettings,
  dataStorage: dataStorage,
  templates: templates,
};
