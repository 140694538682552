import { Button, CheckBox, Form, Icon, Modal, Text } from "components";
import { useEffect, useState } from "react";


type permissionsDependencyModalType = {
  isOpen: boolean;
  toggle: (value?: boolean) => void;
  list: any;
  checkedItem: boolean;
  rolesData: any;
  setList: any;
  setListTotal: any;
  permissions: any;
  labelPermissions: any;
  setRolesData: any;
  handleSearch: (e?: any) => void;
  search: any
};

export default function PermissionsDependencyModal(props: permissionsDependencyModalType) {
  const [loadingButton, setLoadingButton] = useState(false);


  useEffect(() => {
    if (props.isOpen) {
      setLoadingButton(false)
    }
  }, [props.isOpen])


  const submit = () => {
    setLoadingButton(true)
    props.setList(props.list)
    props.setListTotal(props.list)
    props.setRolesData((p: any) => ({ ...p, permissions: props.permissions }))
    if (props.search !== "") {
      props.handleSearch(props.list);
    }
    props.toggle();
  };

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} modalClassName="z-40">
      <Form id="PermissionsDependencyModal">
        <Modal.Header className="flex items-center justify-between">
          <div className="flex items-center">
            <Icon className="text-primary mr-2 w-[32px] h-[32px] !leading-8" icon={"circle-info"} />
            <h2 className="text-heading-2 text-gray-800">
              <Text>configurations.userManagment.roles.permissionDependency</Text>
            </h2>
          </div>
        </Modal.Header>
        <Modal.Body className="grid grid-cols-1 mb-8 px-8 gap-8">
          <div>
            <span>
              {props?.checkedItem ?
                <Text>configurations.userManagment.roles.thesePermissionsWillAlsoEnable</Text> :
                <Text>configurations.userManagment.roles.thesePermissionsWillAlsoDisable</Text>}
            </span>
          </div>
          <div className={`grid grid-cols-1 gap-4 ${props?.labelPermissions.length > 8 && "overflow-auto max-h-[400px]"}`}>
            {props?.labelPermissions?.map((i: any) => {
              return (
                <div key={i.code}>
                  <CheckBox
                    key={i.code}
                    label={i.label}
                    value={props.checkedItem}
                    disabled={true}
                  />
                </div>)
            })}
          </div>
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-between">
          <Button type="button" onClick={props.toggle} variant="white">
            <Icon className="mr-2" icon={"times"} />
            <Text>global.buttons.close</Text>
          </Button>
          <Button type="button" onClick={submit} loading={loadingButton}>
            <Icon className="mr-2" icon={"check"} />
            {props.checkedItem ? <Text>global.buttons.enable</Text> : <Text>global.buttons.disable</Text>}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
