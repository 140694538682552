import {useContext, useEffect, useState} from "react";
import {HandleName, Icon, DropdownButton, InputGroup} from "components";
import {AttributeContext} from "../attributes";
import {ServiceApi, URLS} from "services";
import {findValue} from "../../helpers/find-attribute.value";
import {updateValue} from "../../helpers/update-attribute-value";
import {priceFormatter} from "utils";
import {useTranslation} from "react-i18next";

const Measurment = () => {
  const {
    attribute,
    values,
    channel,
    language,
    setValues,
    flag,
    onChangeValue,
    attributeGroupIndex,
    attributeTemplateId,
    isDefault,
    channelTitle,
  } = useContext(AttributeContext);

  const [units, setUnits] = useState<any>([]);
  const [selectedUnit, setSelectedUnit] = useState("");
  const {i18n} = useTranslation();

  const label = attribute.isRequired ? (
    <>
      <HandleName
        translations={attribute?.translates}
        code={attribute?.code}
        keyName="labelTitle"
      />
      <Icon icon="star" size="sm" className="ml-1 text-danger" />{" "}
    </>
  ) : (
    <HandleName
      translations={attribute?.translates}
      code={attribute?.code}
      keyName="labelTitle"
    />
  );

  useEffect(() => {
    const url = URLS.GET_MEASUREMENT_LIST_BY_CODE(
      attribute?.measurementFamilyCode,
    );
    ServiceApi.get(url).then(({data}) => {
      setUnits(data);
    });
  }, []);

  const {value, measurementFamilyUnitCode} = findValue(
    attribute,
    values?.[attributeGroupIndex],
    attributeTemplateId,
    channel,
    language,
    isDefault,
  );
  const onChangeHandler = (e: any, code: any) => {
    const {data, changedAttribute} = updateValue(
      attribute,
      values?.[attributeGroupIndex],
      attributeTemplateId,
      priceFormatter(e),
      channel,
      language,
      code,
      isDefault,
    );
    const changedValues = [...values];
    const changedIndex = changedValues[attributeGroupIndex]?.findIndex(
      (item: any) => item.attributeTemplateId === attributeTemplateId,
    );
    changedValues[attributeGroupIndex][changedIndex] = changedAttribute;
    setValues(changedValues);
    onChangeValue(data, attribute.code);
  };

  let flagName = attribute?.perLanguage ? flag[language] : null;
  let hint = attribute?.perChannel ? {label: channelTitle} : null;

  const getTranslatedName = (translations: [], keyName: string) => {
    const language = i18n.language;
    if (translations) {
      let newArray = translations?.filter(function (item: any) {
        return (
          language?.toLocaleLowerCase() ===
            item.language?.toLocaleLowerCase() && item
        );
      });
      return newArray.length !== 0 && newArray[0][keyName]
        ? newArray[0][keyName]
        : `No name in ${language}`;
    }
  };

  const items = units?.units?.map((item: any, index: number) => ({
    id: index,
    title: getTranslatedName(item?.translates, "labelTitle"),
    permission: true,
    onClick: () => {
      setSelectedUnit(item?.code);
      onChangeHandler(value, item?.code);
    },
  }));
  return (
    <div>
      <div className="mb-4 flex items-center justify-between space-x-4">
        {label && (
          <h6 className="h6 block truncate font-normal text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']">
            {label}
          </h6>
        )}
        {(flagName || hint) && (
          <div className="flex items-center">
            {hint && (
              <span className={`text-body-2 font-normal`}>{hint?.label}</span>
            )}
            {flagName && (
              <img className="ml-4 block" src={flag[language]} alt="" />
            )}
          </div>
        )}
      </div>
      <div className="mt-4 w-full" style={{display: "flex"}}>
        <InputGroup
          code={attribute?.translates?.[0]?.labelTitle}
          value={value}
          className="py-0"
          type="price"
          hiddenPriceSymbol
          setValue={e =>
            onChangeHandler(
              e,
              selectedUnit ||
                measurementFamilyUnitCode ||
                attribute?.measurementFamilyUnitCode,
            )
          }
          append={
            <DropdownButton items={items} variant={"gray"}>
              {selectedUnit ||
                measurementFamilyUnitCode ||
                attribute?.measurementFamilyUnitCode}
            </DropdownButton>
          }
        />
      </div>
    </div>
  );
};

export default Measurment;
