import {
  Drawer,
  Skeleton,
  Text,
  Wrapper,
  SearchBox,
  Button,
  Icon,
} from "components";
import {useEffect} from "react";

type dataType = {
  name: string;
  code?: string;
  id: string;
};

type Props = {
  isOpen: boolean;
  toggle: () => void;
  toggleDep?: () => void;
  title: string;
  data: dataType[];
  loading: boolean;
  getData: () => void;
  setParams: any;
  params: any;
  dataKey?: string;
  setSimulateData?: any;
};

export default function Chooser({
  isOpen,
  toggle,
  title,
  data,
  loading,
  getData,
  setParams,
  params,
  dataKey,
  setSimulateData,
  toggleDep,
}: Props) {
  useEffect(() => {
    if (isOpen) getData();
  }, [isOpen, params]);

  return (
    <>
      <Drawer isOpen={isOpen} toggle={toggle}>
        <Drawer.Menu>
          <Drawer.Header className="flex items-center justify-between">
            <span className="text-heading-2 font-semibold">
              <Text>{title}</Text>
            </span>
            <Button variant={"light"} type="button" onClick={toggle} size="sm">
              <Icon icon="times" className="mr-2" />
              <Text>global.buttons.close</Text>
            </Button>
          </Drawer.Header>
          <Drawer.Body className="space-y-4">
            <SearchBox
              onSubmit={keyword => setParams((p: any) => ({...p, keyword}))}
              variant="gray"
              disabled={loading}
            />
            {loading
              ? [1, 2, 3, 4, 5].map(key => <Skeleton.List key={key} />)
              : data?.map((item: dataType) => {
                  return (
                    <>
                      <Wrapper
                        key={new Date().toISOString()}
                        className=" hover:bg-primary-light"
                        onClick={() => {
                          setSimulateData((p: any) => ({
                            ...p,
                            [dataKey as string]: item,
                          }));
                          if (toggleDep) {
                            toggleDep();
                          }
                          toggle();
                        }}
                      >
                        <Wrapper.Body className="cursor-pointer space-y-2 rounded-lg !p-4 shadow-card">
                          <h6>{item.name}</h6>
                          <p className="text-body-2">#{item.code}</p>
                        </Wrapper.Body>
                      </Wrapper>
                    </>
                  );
                })}
          </Drawer.Body>
        </Drawer.Menu>
      </Drawer>
    </>
  );
}
