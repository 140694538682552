import { ServiceApi } from "services";
import ETC from "services/urls/etc";
import Button from "./Button";
import { ReactNode, useRef, useState } from "react";
import LoadingSvg from "./LoadingSvg";

type FileUploaderProps = {
  value?: string | null;
  setValue?: CallableFunction;
  getResponse?: CallableFunction;
  accept?: any;
  type?: number;
  children?: ReactNode;
};

export default function CustomizedFileUploader({
  setValue = () => { },
  accept,
  getResponse,
  type,
  children
}: FileUploaderProps) {
  const intervalRef = useRef<any>(null);
  const [loadingFile, setLoading] = useState(0);
  const [name, setName] = useState("");

  const startLoading = () => {
    intervalRef.current = setInterval(() => {
      setLoading(p => {
        const value = p + Math.random() * 10;
        const max = 75;
        return p >= max ? p : value;
      });
    }, 500);
  };
  const endLoading = () => {
    clearInterval(intervalRef.current);
    setLoading(100);
    setTimeout(() => {
      setLoading(0);
    }, 500);
  };

  const hasLoading = !!loadingFile;

  const uploadFile = (e: any) => {
    if (!!e.currentTarget.files) {
      e.preventDefault();
      e.stopPropagation();
      startLoading();
      const file = e.currentTarget.files[0];
      const url = ETC.UPLOAD_IMAGES_URL(type ?? 0);
      const body = new FormData();
      body.append("formFile", file);
      ServiceApi.post(url, body)
        .then(({ data }) => {
          const originalName = data?.metaData?.map((item: any) => {
            if (item["key"] === "OriginalName") {
              return item.value;
            }
            return "";
          });
          setName(originalName);
          setValue(data?.url);
          getResponse && getResponse(data);
        })
        .finally(() => {
          endLoading();
        });
    }
  };
  return (
    <Button variant={"primary"} light className="block relative cursor-pointer" type="button" >
      <input type="file" onChange={uploadFile} className="absolute top-0 left-0 opacity-0 cursor-pointer w-full h-full z-10" accept={accept}
        aria-label='cy-upload-file' disabled={hasLoading} />
      {hasLoading ?
        <LoadingSvg size="sm" />
        :
        <>{children}</>
      }
    </Button>
  );
}
