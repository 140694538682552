import {useContext, useEffect, useState} from "react";
import {SalesPrice} from "compositions";
import {useToggleState, usePermission} from "hooks";
import {ServiceApi, URLS} from "services";
import {SimulationContext} from "../..";
import {withPermission} from "hoc";

function SalesPriceSimulate() {
  const {simulateData, optionalDiscountCalculationUnitIds} =
    useContext(SimulationContext);
  const [loading, toggle] = useToggleState();
  const [data, SetData] = useState({});
  const [salesPriceData, setSalesPriceData] = useState({
    basePrice: 0,
    salePriceType: 0,
  });

  const salePriceSimulationPermission = usePermission(
    "PE_GetSalePriceSimulation",
  );

  useEffect(() => {
    if (simulateData?.purchasePrice) {
      getSaleseData({
        ...salesPriceData,
        basePrice: simulateData?.purchasePrice,
      });
    }
  }, [simulateData]);

  const getSaleseData = (salesPriceData?: any) => {
    toggle();
    const url = salePriceSimulationPermission
      ? URLS.SALES_PRICE_FULL_PERMISSION
      : URLS.SALES_PRICE_NOT_FULL_PERMISSION;
    const {
      salePriceTemplate,
      supplier,
      product,
      program,
      brand,
      salesChannel,
      calculationProcessType,
      lineItemType,
    } = simulateData;
    const body = {
      salePriceTemplateId: salePriceTemplate?.id || null,
      supplierId: supplier?.id || null,
      programId: program?.id || null,
      brandId: brand?.id || null,
      productId: product?.id || null,
      saleChannelId: salesChannel?.id || null,
      calculationProcessType: calculationProcessType,
      lineItemType: lineItemType,
      optionalDiscountCalculationUnitIds,
      ...salesPriceData,
    };
    setSalesPriceData(salesPriceData);
    ServiceApi.post(url, body)
      .then(({data}) => {
        SetData(data);
        toggle();
      })
      .catch(() => toggle());
  };

  return (
    <SalesPrice
      price={data}
      loading={loading}
      getData={getSaleseData}
      showPriceSection
      initialPrice={simulateData?.purchasePrice}
      setSalesPriceData={setSalesPriceData}
    />
  );
}

export default withPermission(
  SalesPriceSimulate,
  ["PE_GetSalePriceSimulation"],
  true,
);
