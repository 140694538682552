import {Button, Drawer, Icon, SearchBox, Text} from "components";
import {useTranslate} from "hooks";
import {useState} from "react";
import Select from "react-select";
import {config} from "constant";
import {label} from "yet-another-react-lightbox";
import {useSearchParams} from "react-router-dom";

interface Props {
  isOpen: boolean;
  loading: boolean;
  toggle: () => void;
  resetFilter: () => void;
  filterData: any;
  isFilter: boolean;
  params: any;
  data: any;
  channels: any;
  setParams: any;
}

export default function Filter({
  isOpen,
  toggle,
  isFilter,
  resetFilter,
  filterData,
  params,
  data,
  loading,
  channels,
  setParams,
}: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [keyword, setKeyword] = useState("");
  const translate = useTranslate();

  const createProductFamilyItems = () => {
    const clone = data?.pimProductFamily?.map((item: any) => ({
      value: item.value,
      label: item.title + " (" + item.count + ")",
    }));
    clone?.unshift({value: null, label: translate("global.all")});
    return clone;
  };
  const createChannelsItems = () => {
    const clone = channels?.map((item: any) => ({
      value: item.code,
      label: item.title,
    }));
    return clone;
  };

  const createItems = (key: string) => {
    const clone = data?.[key]?.map((item: any) => ({
      value: item.value,
      label: item.title + "(" + item.count + ")",
    }));
    clone?.unshift({value: null, label: translate("global.all")});
    return clone;
  };
  const onChangeHandler = (key: string, val: any) => {
    setSearchParams({
      pageNumber: "1",
      pageSize: params.pageSize,
    });
    setParams((prev: any) => {
      return {...prev, pageNumber: 1, [key]: val};
    });
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pr-4 pt-6">
          <div className="flex items-center justify-between">
            <span className="text-heading-2 font-semibold">
              <Text>productManagement.products.allProducts.filters</Text>
            </span>
            <div className="flex gap-x-2">
              {isFilter && (
                <Button
                  size="sm"
                  variant={"danger"}
                  light
                  onClick={resetFilter}
                >
                  <Icon icon="times-circle" className="mr-1" />
                  <Text>
                    productManagement.products.allProducts.resetFilters
                  </Text>
                </Button>
              )}
              <Button size="sm" variant={"light"} onClick={toggle}>
                <Icon icon="times" className="mr-1" />
                <Text>productManagement.products.allProducts.close</Text>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          <SearchBox
            value={keyword}
            onSubmit={filterData("keyword")}
            variant="gray"
            disabled={loading}
            resetFilter={() => setKeyword("")}
          />
          <label
            data-lang-map={"Published Channels"}
            className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
          >
            <Text>{"Published Channels"}</Text>
          </label>
          <Select
            styles={config.styleSelectReact}
            defaultValue={{id: null, label: translate("global.all")}}
            value={createChannelsItems()?.find(
              (opt: any) => opt.value === params?.channelCode,
            )}
            options={createChannelsItems()}
            onChange={(e: any) => {
              onChangeHandler("channelCode", e?.value || null);
            }}
            isDisabled={loading}
          />
          <label
            data-lang-map={
              "productManagement.products.allProducts.productFamily"
            }
            className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
          >
            <Text>
              {"productManagement.products.allProducts.productFamily"}
            </Text>
          </label>
          <Select
            styles={config.styleSelectReact}
            defaultValue={{id: null, label: translate("global.all")}}
            value={createProductFamilyItems()?.find(
              (opt: any) => opt.value === params?.pimProductFamilyId,
            )}
            options={createProductFamilyItems()}
            onChange={(e: any) => {
              onChangeHandler("pimProductFamilyId", e?.value || null);
            }}
            isDisabled={loading}
          />
          <label
            data-lang-map={"productGroup"}
            className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
          >
            <Text>{"productGroup"}</Text>
          </label>
          <Select
            styles={config.styleSelectReact}
            defaultValue={{id: null, label: translate("global.all")}}
            value={createItems("pimProductGroup")?.find(
              (opt: any) => opt.value === params?.groupId,
            )}
            options={createItems("pimProductGroup")}
            onChange={(e: any) => {
              onChangeHandler("groupId", e?.value || null);
            }}
            isDisabled={loading}
          />
          <label
            data-lang-map={
              "productManagement.products.allProducts.productCategory"
            }
            className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
          >
            <Text>
              {"productManagement.products.allProducts.productCategory"}
            </Text>
          </label>
          <Select
            styles={config.styleSelectReact}
            defaultValue={{id: null, label: translate("global.all")}}
            value={createItems("category")?.find(
              (opt: any) => opt.value === params?.pimProductCategoryCode,
            )}
            options={createItems("category")}
            onChange={(e: any) => {
              onChangeHandler("pimProductCategoryCode", e?.value || null);
            }}
            isDisabled={loading}
          />
          <label
            data-lang-map={"productManagement.products.allProducts.brand"}
            className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
          >
            <Text>{"productManagement.products.allProducts.brand"}</Text>
          </label>
          <Select
            styles={config.styleSelectReact}
            defaultValue={{id: null, label: translate("global.all")}}
            value={createItems("brand")?.find(
              (opt: any) => opt.value === params?.brandCode,
            )}
            options={createItems("brand")}
            onChange={(e: any) => {
              onChangeHandler("brandCode", e?.value || null);
            }}
            isDisabled={loading}
          />
          <label
            data-lang-map={"productManagement.products.allProducts.suppliers"}
            className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
          >
            <Text>{"productManagement.products.allProducts.suppliers"}</Text>
          </label>
          <Select
            styles={config.styleSelectReact}
            defaultValue={{id: null, label: translate("global.all")}}
            value={createItems("supplier")?.find(
              (opt: any) => opt.value === params?.supplierId,
            )}
            options={createItems("supplier")}
            onChange={(e: any) => {
              onChangeHandler("supplierId", e?.value || null);
            }}
            isDisabled={loading}
          />
          <label
            data-lang-map={"productManagement.products.allProducts.program"}
            className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
          >
            <Text>{"productManagement.products.allProducts.program"}</Text>
          </label>
          <Select
            styles={config.styleSelectReact}
            defaultValue={{id: null, label: translate("global.all")}}
            value={createItems("program")?.find(
              (opt: any) => opt.value === params?.programId,
            )}
            options={createItems("program")}
            onChange={(e: any) => {
              onChangeHandler("programId", e?.value || null);
            }}
            isDisabled={loading}
          />
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
}
