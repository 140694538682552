import {Button, Icon, Modal, Text} from "components";
type Props = {
  isOpen: boolean;
  toggle: () => void;
  metaData?: any;
};

const ImageMetaData = (props: Props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggle}
      modalClassName="z-40"
      size={"lg"}
    >
      <Modal.Header className="!text-left text-heading-2 font-semibold">
        <Text>productManagement.products.Details.fileInfo</Text>
      </Modal.Header>
      <Modal.Body className="min-h-[300px] space-y-4 overflow-auto">
        {props?.metaData?.map((item: any, index: number) => {
          return (
            <div
              className={`grid grid-cols-12 items-center gap-4 p-2 ${
                index % 2 === 0 && "bg-gray-100"
              }`}
            >
              <span className="col-span-3">{item.key} </span>
              <span className="col-span-9 break-words leading-6">
                {item.value}
              </span>
            </div>
          );
        })}
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-between">
        <Button size="sm" onClick={props.toggle} variant="white">
          <Icon className="mr-2" icon={"times"} />
          <Text>global.buttons.close</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImageMetaData;
