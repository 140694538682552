export const dashboard = {
  title: "Dashboard",
  subTitle: "Home",
  productsCompleteness: "Products Completeness",
  channel: "Channel",
  publishedProducts: "Published Products",
  recentActivity: "Recent Activity",
  recentActivityFilters: {
    last10Days: "The Last 10 days",
    week: "Week",
    month: "Month",
  },
  recentlyAdded: "Recently Added",
  sellOffProducts: "Sell-off Products",
  recentlySoldSellOffProducts: "Recently Sold Sell-off Products",
  soldAt: "Sold At",
  soldSellOffProducts: "Sold Sell-off Products",
  soldSellOffProductsBadge: "Last 7 days",
  totalPIMProducts: "Total PIM Products",
  totalPIMProductsItems: {
    in7days: "Products In last 7 days",
    newAdded: "New Added",
    completed: "Completed",
    published: "Published",
  },
  sellOffProduct: {
    preparation: "Preparation",
    active: "Active",
    sold: "Sold",
    closed: "Closed",
  },
  soldSellOffProduct: {
    count: "Count",
  },
};
