const required = [
  (val: any) => `${val ?? ""}`.length !== 0 || "This field is required",
];
const confirmPassword = [
  (val: any) => "Re-Enter Password Not Matched",
];
const emailRequired = [
  (val: any) => {
    if (`${val ?? ""}`.length === 0) {
      return "This field is required";
    } else {
      return (
        /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(
          val
        ) || "Please provide a valid email address"
      );
    }
  },
];
const emailAddress = [
  (val: any) => {
    if (`${val ?? ""}`.length !== 0) {
      return (
        /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(
          val
        ) || "Please provide a valid email address"
      );
    }
    return true;
  },
];
const password = [
  (val: any) => {
    if (`${val ?? ""}`.length !== 0) {
      return (
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
          val
        ) || "Please use Valid Password"
      );
    }
    return true;
  },
];
const discount = [
  (val: any) => {
    if (`${val ?? ""}`.length === 0) {
      return "This field is required";
    } else {
      return (val >= 0 && val <= 100) || "Value must be between 0 and 100";
    }
  },
];
const color = [
  (val: any) => {
    if (`${val ?? ""}`.length === 0) {
      return "This field is required";
    } else {
      var s = new Option().style;
      s.color = val;
      const validation = (s.color ?? "") !== "";
      return validation || "Color is not valid.";
    }
  },
];
const url = [
  ...required,
  (val: any) =>
    String(val ?? "").startsWith("https") || "Url must start with https.",
  (val: any) => {
    let valid;
    try {
      new URL(val);
      valid = true;
    } catch (error) {
      valid = false;
    }
    return valid || "Url is not valid";
  },
];
const order = [
  ...required,
  (val: any) => Number(val ?? "") >= 0 || "The value must be greater than 0",
];
const iwofurn = [
  ...required,
  (val: any) =>
    val.startsWith("https://www.") ||
    "The field must match https://www.hostname.com",
  (val: any) => {
    let valid;
    try {
      new URL(val);
      valid = true;
    } catch (error) {
      valid = false;
    }
    return valid || "The field is not valid.";
  },
];
const rules = {
  required,
  emailRequired,
  emailAddress,
  discount,
  color,
  url,
  order,
  iwofurn,
  password,
  confirmPassword
};
export default rules;
