import {
  Button,
  Drawer,
  Icon,
  Image,
  List,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Text,
} from "components";
import { config } from "constant";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { ServiceApi, URLS } from "services";
import { LegalEntity, WithPaging, toggleProps } from "types";
type SubmitBody = {
  legalEntities: string[];
};
type SelectLegalEntitiesProps = toggleProps & {
  onSubmit: (body: SubmitBody) => void;
  loading: boolean;
};
type Data = WithPaging<LegalEntity.Item>;
type Params = {
  pageSize: number;
  pageNumber: number;
  keyword?: string | null;
};
export default function PublishToChannel({
  isOpen,
  toggle,
  onSubmit,
  loading,
}: SelectLegalEntitiesProps) {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [legalEntities, setLegalEntities] = useState<Data>({});
  const [params, setParams] = useState<Params>({
    pageNumber: 1,
    pageSize: config.pageSize,
  });
  // const [loading, setLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const disabledButton = !selectedItems.length;
  const isActive = (code: string) => {
    return selectedItems.includes(code);
  };
  const clearSelectedItems = () => {
    if (isOpen) return;
    setSelectedItems([]);
  };
  const handleSelect = (code: string) => {
    setSelectedItems((p) => {
      const list = cloneDeep(p);
      const index = list.findIndex((e) => e === code);
      const has = index !== -1;
      has ? list.splice(index, 1) : list.push(code);
      return list;
    });
  };
  const getLegalEntities = () => {
    if (!isOpen) return;
    setListLoading(true);
    const url = URLS.GET_LEGALENTITY;
    const config = { params };
    ServiceApi.get(url, config)
      .then(({ data }) => {
        setLegalEntities(data);
      })
      .finally(() => {
        setListLoading(false);
      });
  };
  const handleSubmit = () => {
    onSubmit({ legalEntities: selectedItems });
  };
  useEffect(clearSelectedItems, [isOpen]);
  useEffect(getLegalEntities, [isOpen, params]);
  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header className="flex items-center gap-4">
          <h6 className="flex-1 text-start text-gray-800 text-heading-2 truncate">
            <Text>productManagement.products.Details.selectLegalEntities</Text>
          </h6>
          <Button
            type="button"
            variant="light"
            size="sm"
            onClick={toggle}
            disabled={loading}
          >
            <Icon icon="close" />{" "}
            <Text>productManagement.products.Details.closeBtn</Text>
          </Button>
        </Drawer.Header>
        <Drawer.Header className="border-b-0 space-y-4">
          <SearchBox
            value={params.keyword}
            onSubmit={(keyword) => setParams((p) => ({ ...p, keyword }))}
            variant="gray"
            totalItems={legalEntities.totalItems}
          />
        </Drawer.Header>
        <Drawer.Body className="space-y-2">
          {listLoading ? (
            [...Array(10).keys()].map((key) => <Skeleton.Input key={key} />)
          ) : !legalEntities?.items?.length ? (
            <NoItems />
          ) : (
            <List>
              {legalEntities.items.map((e) => (
                <List.Item
                  key={e.id}
                  data-active={isActive(e.code)}
                  className="group flex items-center gap-4 data-active:bg-primary-light transition-colors"
                  onClick={() => handleSelect(e.code)}
                >
                  <Image
                    src={undefined}
                    alt={e.name}
                    className="w-32 h-20 rounded"
                    imageClassName="object-cover"
                  />
                  <div className="flex-1 space-y-2">
                    <h6 className="block w-full text-heading-6 text-gray-800 font-semibold">
                      {e.name} <span className="text-gray-500">({e.code})</span>
                    </h6>
                    <p className="text-body-2 text-gray-600 truncate">
                      {e.description || "--"}
                    </p>
                  </div>
                  <span className="w-6 h-6 flex-center bg-gray-300 rounded transition-colors group-data-active:bg-primary">
                    <Icon
                      icon={"check"}
                      className="text-white opacity-0 transition-opacity group-data-active:opacity-100"
                    />
                  </span>
                </List.Item>
              ))}
            </List>
          )}
        </Drawer.Body>
        <Drawer.Footer className="border-t-0">
          <Pagination
            pageNumber={params.pageNumber}
            pageSize={params.pageSize}
            totalItems={legalEntities.totalItems}
            totalPages={legalEntities.totalPages}
            // onPageSizeChange={(value: any) =>
            //   setParams((p) => ({ ...p, pageSize: +value }))
            // }
            setActivePage={(value) =>
              setParams((p) => ({ ...p, pageNumber: +value }))
            }
          />
        </Drawer.Footer>
        <Drawer.Footer className="flex items-center justify-between gap-6">
          <Button
            type="button"
            variant="light"
            size="sm"
            onClick={toggle}
            disabled={loading}
          >
            <Text>productManagement.products.Details.cancelBtn</Text>
          </Button>
          <Button
            type="button"
            size="sm"
            variant="primary"
            onClick={handleSubmit}
            disabled={disabledButton}
            loading={loading}
          >
            <Text>productManagement.products.Details.submitBtn</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
