import React, { createElement } from "react";
import { Wrapper, Text, Icon, Skeleton, SearchBox, NoItems } from "components";
import { CSSTransition } from "react-transition-group";
import { useToggleState } from "hooks";
import { classNames } from "utils";

type Props = {
  list?: any;
  setRoots?: any;
  loading?: boolean;
  selectedId?: any;
  setSelectedId?: any;
  getCategories?: any;
  title?: string;
  setParams: (key: any) => void;
  params?: any;
  setSelectedItem?: any;
  returnSection?: any;
};

export default function LeftSide({
  list,
  selectedId,
  setSelectedId,
  loading,
  title,
  setParams,
  params,
  setSelectedItem,
  returnSection,
}: Props) {
  const [isActive, setIsActive] = useToggleState(true);
  const drawer = classNames(
    "flex flex-center rounded overflow-hidden p-[5px] w-8 h-8 transition-transform absolute -right-[.9rem] bg-white shadow top-1/2",
    {
      "rotate-180": !isActive,
    },
  ); //rotate-180

  const panel = classNames(`flex-none relative transition-[width] h-[641px]`, {
    "!w-[374px]": isActive,
    "!w-[66px]": !isActive,
  });

  return (
    <Wrapper className={panel}>
      <div
        className={`flex h-[70px] space-y-4 border-b transition-transform ${isActive ? "justify-between p-4" : "justify-center px-1 py-4"
          }`}
      >
        <div className="flex items-center gap-2">
          <Icon
            icon={"list-tree"}
            size="1x"
            className="flex-none text-primary"
          />
          <CSSTransition
            in={isActive}
            timeout={50}
            unmountOnExit
            classNames="alert"
          >
            <span className="text-heading-2 font-semibold text-gray-800">
              <Text>{title}</Text>
            </span>
          </CSSTransition>
        </div>
      </div>
      <div className="px-4 py-2">
        {returnSection && createElement(returnSection)}
      </div>
      <div className="px-4 py-2">
        {setParams && isActive && (
          <SearchBox
            value={params?.keyword}
            onSubmit={keyword => setParams((p: any) => ({ ...p, keyword }))}
            variant="gray"
          />
        )}
      </div>
      <div className="flex flex-col space-y-4 rounded bg-white p-3">
        {loading &&
          [1, 2, 3, 4, 5].map((item: any) => <Skeleton.Input key={item} />)}
        {!loading && (
          <>
            {list?.length ? (
              <>
                {list?.map((item: any) => {
                  return (
                    <div
                      className={`flex min-h-12 min-w-[50px] cursor-pointer flex-col justify-center gap-2 rounded p-1 text-body-base shadow-sm ${item.id === selectedId
                          ? "bg-primary-light font-semibold text-primary"
                          : "bg-gray-100 font-normal text-gray-800"
                        }`}
                      aria-label="cy-category-item"
                      onClick={() => {
                        setSelectedId(item.id);
                        setSelectedItem(item);
                      }}
                    >
                      <CSSTransition
                        in={true}
                        timeout={200}
                        unmountOnExit
                        classNames="alert"
                      >
                        <>
                          <span className="flex h-full w-full items-center px-2 text-body-base font-normal text-gray-800">
                            {isActive
                              ? item?.name
                              : item?.name.slice(0, 2).toUpperCase()}
                          </span>
                          {isActive && (
                            <p className="px-2 text-xs">#{item.code}</p>
                          )}
                        </>
                      </CSSTransition>
                    </div>
                  );
                })}
              </>
            ) : (
              <NoItems />
            )}
          </>
        )}
      </div>
      <button
        className={drawer}
        onClick={() => {
          setIsActive();
        }}
      >
        <Icon icon={"chevrons-left"} size="1x" className="text-primary" />
      </button>
      {/* )} */}
    </Wrapper>
  );
}
