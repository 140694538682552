import {Breadcrumb, Tabs, Text} from "components";
import {withPermission} from "hoc";
import {createElement} from "react";
import DataStorageTab from "./tabs/data-storage";
import StorageConfig from "./tabs/storage-config";

function DataStorage() {
  const tabs = [
    {
      label: "data storage",
      component: DataStorageTab,
    },
    {
      label: "storage config",
      component: StorageConfig,
    },
  ];
  return (
    <section className="space-y-6">
      <Breadcrumb className="col-span-full" />
      <Tabs activeKey={tabs[0].label}>
        <Tabs.ButtonGroup className="rounded bg-white px-4">
          {tabs.map(e => (
            <Tabs.Button key={e.label} eventKey={e.label}>
              <Text>{e.label}</Text>
            </Tabs.Button>
          ))}
        </Tabs.ButtonGroup>
        {tabs.map(e => (
          <Tabs.Item key={e.label} eventKey={e.label}>
            {createElement(e.component)}
          </Tabs.Item>
        ))}
      </Tabs>
    </section>
  );
}

export default withPermission(DataStorage, ["AsS_GetBlobConfig"]);
