import { NotificationManager } from "common/notifications";
import {
  Drawer,
  Text,
  Icon,
  Button,
  InputGroup,
  Select,
} from "components";
import { useDataState, useToggleState } from "hooks";
import { useContext, useEffect } from "react";
import { useParams } from "react-router";
import { ServiceApi, URLS } from "services";
import { PimContext } from "..";
import { types } from "constant";

type Props = {
  isOpen: boolean;
  toggle: () => void;
  bundle: any;
};

export default function Commission({ isOpen, toggle, bundle }: Props) {
  const [data, setData, setBaseData, isChanged] = useDataState({
    amount: '',
    calculationType: 0
  });
  const { productId } = useParams();
  const { details, getDetails } = useContext(PimContext);
  const [submitLoading, toggleSubmitLoading] = useToggleState();

  const onSubmitHandler = () => {
    toggleSubmitLoading();
    let params = { channelCode: details.channelCode };
    const config = { params };
    const url = URLS.UPDATE_BUNDLE_ITEMS_SALES_PERSON_COMMISSION(productId, bundle?.item?.id);
    const body = { salesPersonCommission: { ...data } }
    ServiceApi.put(url, body, { ...config })
      .then(() => {
        getDetails();
        toggle();
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
      })
      .finally(() => toggleSubmitLoading());
  };

  useEffect(() => {
    setBaseData(bundle?.item?.salesPersonCommission || {
      amount: '',
      calculationType: 0
    })
  }, [bundle])
  return (
    <>
      <Drawer isOpen={isOpen} toggle={toggle}>
        <Drawer.Menu>
          <Drawer.Header className="flex items-center justify-between text-heading-2 font-semibold">
            <span>
              <Text>productManagement.products.Details.salesPersonCommission</Text>
            </span>
            <Button
              variant={"light"}
              size="sm"
              className="flex items-center"
              onClick={toggle}
            >
              <span>
                <Text>global.buttons.close</Text>
              </span>
              <Icon icon="close" className="ml-2" />
            </Button>
          </Drawer.Header>
          <Drawer.Body className="space-y-6">
            <div className="grid grid-cols-2 gap-3">
              <div className="col-span-2 lg:col-span-1">
                <InputGroup
                  label="productManagement.products.Details.amount"
                  value={data?.amount}
                  setValue={(value) => {
                    if (data?.calculationType == 1 || (value >= 0 && value <= 100)) {
                      setData({ ...data, amount: value })
                    }
                  }}
                  keyfilter="pnum"
                />
              </div>
              <div className="col-span-2 lg:col-span-1">
                <Select
                  label="productManagement.products.Details.type"
                  placeholder="global.select"
                  value={data?.calculationType}
                  items={types.amountCalculationType}
                  setValue={(value) => { setData({ ...data, calculationType: value, amount: '' }) }}
                />
              </div>
            </div>
          </Drawer.Body>
          <Drawer.Footer className="flex justify-between">
            <Button type="button" variant={"light"} onClick={toggle}>
              <Text>global.buttons.cancel</Text>
            </Button>
            <Button
              type="button"
              onClick={onSubmitHandler}
              loading={submitLoading}
              disabled={!isChanged}
            >
              <Text>global.buttons.submit</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Drawer>
    </>
  );
}
