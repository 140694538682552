import {saleProccessContext} from ".";
import {useContext} from "react";
import {checkPriceAvailability} from "utils";
import {useConverter} from "hooks";
import {Wrapper, Status, Text} from "components";

const SalesStatus = () => {
  const {data} = useContext(saleProccessContext);
  const {convertDate} = useConverter();

  return (
    <Wrapper>
      <Wrapper.Header className="flex items-center justify-between">
        <h2>
          <Text>salesManagment.sellOffSales.sellOffProduct.salesStatus</Text>
        </h2>
        {!data?.isSold && (
          <h6 className="text-heading-6">
            <Status.SellOff
              id={"salesManagment.sellOffSales.sellOffProduct.notSold"}
            />
          </h6>
        )}
      </Wrapper.Header>
      <Wrapper.Body>
        <div className="space-y-4 text-heading-5 font-semibold">
          <div className="grid grid-cols-3">
            <p className="col-span-2">
              <Text>salesManagment.sellOffSales.sellOffProduct.soldPrice</Text>:
            </p>
            <p className="text-heading-2 text-success">
              {data?.soldPrice
                ? checkPriceAvailability(data?.realSoldPrice)
                : "--"}
            </p>
          </div>
          <div className="grid grid-cols-3">
            <p className="col-span-2">
              <Text>salesManagment.sellOffSales.sellOffProduct.soldDate</Text>:
            </p>
            <p className="text-sm text-gray-600">
              {data?.soldDateTime ? convertDate(data?.soldDateTime) : "--"}
            </p>
          </div>
          <div className="grid grid-cols-3">
            <p className="col-span-2">
              <Text>salesManagment.sellOffSales.sellOffProduct.seller</Text>:
            </p>
            <p className="text-gray-600">{data?.soldByName || "--"}</p>
          </div>
          <div className="grid grid-cols-3">
            <p className="col-span-2">
              <Text>
                salesManagment.sellOffSales.sellOffProduct.sellerCommision
              </Text>
              :
            </p>
            <p className="text-gray-600">
              {data?.currentSellerCommission || "--"}
            </p>
          </div>
          <div className="grid grid-cols-3">
            <p className="col-span-2">
              <Text>
                salesManagment.sellOffSales.sellOffProduct.fastSaleCommission
              </Text>
              :
            </p>
            <p className="text-gray-600">
              {checkPriceAvailability(data?.fastSalesProvisionAmount) || "--"}
            </p>
          </div>
        </div>
      </Wrapper.Body>
    </Wrapper>
  );
};

export default SalesStatus;
