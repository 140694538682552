export const roles = {
    "addRole": "Rolle hinzufügen",
    "general": "Allgemein",
    "permissions": "Berechtigungen",
    "basicInfo": "Grundlegende Informationen",
    "name": "Name",
    "description": "Beschreibung",
    "shortDescription": "Kurzbeschreibung",
    "permissionDependency": "Berechtigungsabhängigkeit",
    "thesePermissionsWillAlsoEnable": "Auch diese Berechtigungen aktivieren",
    "thesePermissionsWillAlsoDisable": "Auch diese Berechtigungen deaktivieren"
}