import {useState} from "react";
import {
  Button,
  Drawer,
  Text,
  Icon,
  InputGroup,
  Toggler,
  DatePicker,
  Form,
  CheckBox,
} from "components";
import {useToggleState} from "hooks";
import {PriceEngin, PriceEngineService} from "types";
import {ServiceApi, URLS} from "services";
import {NotificationManager} from "common/notifications";
import {rules} from "constant";

type Props = {
  isOpen: boolean;
  toggle: () => void;
  getData: () => void;
};

export function CreateSalesTemplate({isOpen, toggle, getData}: Props) {
  const [loading, toggleLoading] = useToggleState();
  const [isChecked, toggleCheck] = useToggleState();
  const [data, setData] =
    useState<PriceEngineService.CreateIwofurnSalePriceTemplateRequest>({
      title: "",
      code: "",
      description: "",
      isActive: true,
      validFromDate: null,
      validToDate: null,
    });

  const onSubmitHandler = () => {
    toggleLoading();
    const url = URLS.CREATE_IWOFURN_SALE_PRICE_TEMPLATES;
    const body = {
      ...data,
      validFromDate: isChecked ? data?.validFromDate : null,
      validToDate: isChecked ? data?.validToDate : null,
    };
    ServiceApi.post(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title",
        );
        toggleLoading();
        toggle();
        getData();
      })
      .catch(() => toggleLoading());
  };

  const onChangeHandler = (key: string) => {
    return (value: any) => setData((p: any) => ({...p, [key]: value}));
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Form onSubmit={onSubmitHandler}>
        <Drawer.Menu>
          <Drawer.Header className="flex items-center justify-between">
            <span className="text-heading-2 font-semibold">
              <Text>priceEngine.salesPrice.addSalesTemplate</Text>
            </span>
            <Button variant={"light"} onClick={toggle}>
              <Icon icon="times" className="mr-2" />
              <Text>global.buttons.close</Text>
            </Button>
          </Drawer.Header>

          <Drawer.Body className="space-y-6">
            <InputGroup
              label={"priceEngine.salesPrice.title"}
              value={data?.title}
              rules={rules.required}
              setValue={onChangeHandler("title")}
            />
            <InputGroup
              label={"priceEngine.salesPrice.code"}
              value={data?.code}
              rules={rules.required}
              setValue={onChangeHandler("code")}
            />
            <InputGroup
              label={"priceEngine.salesPrice.shortDescription"}
              value={data?.description}
              setValue={onChangeHandler("description")}
              as={"short-textarea"}
            />
            <CheckBox
              value={isChecked}
              setValue={toggleCheck}
              label="priceEngine.calculationLevel.addValidationPeriod"
            />
            <div className="grid grid-cols-2 gap-4">
              <DatePicker
                minDate={new Date()}
                maxDate={data?.validToDate ? data?.validToDate : null}
                placeholderText="priceEngine.salesPrice.selectDate"
                label="priceEngine.salesPrice.from"
                value={isChecked ? data?.validFromDate : null}
                onChange={onChangeHandler("validFromDate")}
                disabled={!isChecked}
              />
              <DatePicker
                minDate={data?.validFromDate ? data?.validFromDate : new Date()}
                placeholderText="priceEngine.salesPrice.selectDate"
                label="priceEngine.salesPrice.to"
                value={isChecked ? data?.validToDate : null}
                onChange={onChangeHandler("validToDate")}
                disabled={!isChecked}
              />
            </div>
            <Toggler
              className="!w-fit"
              label="priceEngine.calculationUnits.active"
              value={data?.isActive as boolean}
              setValue={onChangeHandler("active")}
            />
          </Drawer.Body>
          <Drawer.Footer className="flex justify-between">
            <Button variant={"light"} onClick={toggle}>
              <Text>global.cancel</Text>
            </Button>
            <Button type="submit" loading={loading}>
              <Text>priceEngine.salesPrice.addTemplate</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Form>
    </Drawer>
  );
}
