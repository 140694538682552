export function onFilter(data: any, keys: any, filter: string) {
  let newList: any = [];
  data?.forEach((attribute: any) => {
    keys?.forEach((item: any) => {
      if (item === "translates") {
        attribute.translates.forEach((translate: any) => {
          if (
            translate.labelTitle?.toLowerCase().includes(filter?.toLowerCase())
          ) {
            newList.push(attribute);
          }
        });
      } else if (
        attribute?.[item]?.toLowerCase().includes(filter?.toLowerCase())
      ) {
        newList.push(attribute);
      }
    });
  });
  return Array.from(new Set(newList?.map((el: any) => JSON.stringify(el)))).map(
    (el: any) => JSON.parse(el),
  );
}
