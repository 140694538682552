import {Button, Icon, Wrapper, Status} from "components";
import {SimulationContext} from "pages/price-engine/price-simulator";
import {useContext} from "react";

type Props = {
  dataKey: string;
};

export default function Info({dataKey}: Props) {
  const {simulateData, setSimulateData} = useContext(SimulationContext);
  return (
    <Wrapper>
      <Wrapper.Body className="flex items-center justify-between space-y-2 rounded-lg !p-4 shadow-card">
        <div className="space-y-2">
          <h6>{simulateData[dataKey]?.name}</h6>
          <p className="text-body-2">#{simulateData[dataKey]?.code}</p>
          <Status.PurchasePrice id={simulateData[dataKey]?.active} />
        </div>
        <Button
          light
          variant={"danger"}
          size="sm"
          onClick={() => {
            setSimulateData((p: any) => ({...p, [dataKey]: null}));
          }}
        >
          <Icon icon="trash" />
        </Button>
      </Wrapper.Body>
    </Wrapper>
  );
}
