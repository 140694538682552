export const productGroup = {
  addProductGroup: "neue Warengruppe",
  editProductGroup: "Warengruppe bearbeiten",
  code: "Code",
  name: "Name",
  title: "Titel",
  description: "Beschreibung",
  shortDescription: "Kurzbeschreibung",
  tax: "Umsatzsteuergruppe",
};
