import {createSlice} from "@reduxjs/toolkit";

const channels = createSlice({
  name: "channels",
  initialState: [] as string[],
  reducers: {
    setChannels: (state, action) => {
      return action.payload;
    },
  },
});

export const {setChannels} = channels.actions;
export default channels.reducer;
