import React, {useContext} from "react";
import {
  Wrapper,
  Text,
  InputGroup,
  Footer,
  Button,
  Select,
  CheckBox,
  WithPermission,
} from "components";
import {SalesChannelcontext} from ".";
import {rules} from "constant";
import {usePermission} from "hooks";

const General: React.FunctionComponent = () => {
  const {data, setData, isChanged, submitLoading, legalEntities} =
    useContext<any>(SalesChannelcontext);
  const updatePermissions = usePermission("SC_UpdateSaleChannel");
  const handleSetData = (key: any) => {
    return (value: any) =>
      setData((p: any) => {
        if (key === "legalEntityId") {
          const clone = legalEntities?.find((leg: any) => leg.code === value);
          return {
            ...p,
            legalEntity: {legalEntityRef: clone?.legalId, code: clone?.code},
          };
        }
        return {...p, [key]: value};
      });
  };

  const onChangeHandlerBusinessChannels = (key: any) => {
    return (value: any) =>
      setData((p: any) => ({
        ...p,
        businessChannels: {...p?.businessChannels, [key]: value},
      }));
  };

  return (
    <fieldset disabled={!updatePermissions} className="space-y-6">
      <Wrapper>
        <Wrapper.Header>
          <h1 className="text-heading-2 font-semibold">
            <Text>salesManagment.salesChannels.basicInfo</Text>
          </h1>
        </Wrapper.Header>
        <Wrapper.Body className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
          <div className="space-y-6">
            <InputGroup
              label="salesManagment.salesChannels.title"
              value={data.title}
              setValue={handleSetData("title")}
              rules={rules.required}
            />
            <InputGroup
              label="salesManagment.salesChannels.code"
              value={data.code}
              setValue={handleSetData("code")}
              disabled
            />
            <InputGroup
              label="salesManagment.salesChannels.iln"
              value={data.ilnNumber}
              setValue={handleSetData("ilnNumber")}
              type="number"
            />
          </div>
          <div className="space-y-6">
            <Select
              label="salesManagment.salesChannels.legalEntity"
              items={legalEntities}
              clear
              value={data?.legalEntity?.code}
              setValue={handleSetData("legalEntityId")}
              rules={rules.required}
            />
            <InputGroup
              as="short-textarea"
              label="salesManagment.salesChannels.description"
              value={data.description}
              setValue={handleSetData("description")}
            />
          </div>
        </Wrapper.Body>
      </Wrapper>
      <Wrapper>
        <Wrapper.Header>
          <h1 className="text-heading-2 font-semibold">
            <Text>salesManagment.salesChannels.activeBusinessChannels</Text>
          </h1>
        </Wrapper.Header>
        <Wrapper.Body>
          <div className="flex flex-col gap-4">
            <CheckBox
              label="CRM"
              value={data?.businessChannels?.crmChannel}
              setValue={onChangeHandlerBusinessChannels("crmChannel")}
            />
            <CheckBox
              label="ERP"
              value={data?.businessChannels?.erpChannel}
              setValue={onChangeHandlerBusinessChannels("erpChannel")}
            />
            <CheckBox
              label="salesManagment.salesChannels.NoOnlineShop"
              value={data?.businessChannels?.onlineShopChannel}
              setValue={onChangeHandlerBusinessChannels("onlineShopChannel")}
            />
          </div>
        </Wrapper.Body>
      </Wrapper>
      <WithPermission permissions={["SC_UpdateSaleChannel"]}>
        <Footer show={!isChanged}>
          <Button type="submit" variant="primary" loading={submitLoading}>
            <Text>global.buttons.saveChanges</Text>
          </Button>
        </Footer>
      </WithPermission>
    </fieldset>
  );
};

export default General;
