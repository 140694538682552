import {NotificationManager} from "common/notifications";
import {
  Breadcrumb,
  Button,
  Form,
  Icon,
  InputGroup,
  Skeleton,
  Text,
  WithPermission,
  Wrapper,
} from "components";
import {rules} from "constant";
import {useDataState, usePermission, useToggleState} from "hooks";
import {Fragment, useEffect, useState} from "react";
import {useParams} from "react-router";
import {Link} from "react-router-dom";
import {ServiceApi, URLS} from "services";
import AddPhase from "./add-phase";
import UpdatePhase from "./update-phase";

export default function Update() {
  const updatePermission = usePermission("SC_UpdateSellPhaseTemplate");
  const {salesTemplateId} = useParams();
  const [loadingButton, setLoadingButton] = useState(false);
  const [data, setData, setBaseData, isChanged] = useDataState<any>({});
  const [selected, setSelected] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [loadingPage, setLoadingPage] = useState(true);
  const [isOpenDrawerCreate, toggleDrawerCreate] = useToggleState(false);
  const [isOpenDrawerUpdate, toggleDrawerUpdate] = useToggleState(false);

  const submit = () => {
    setLoadingButton(true);
    const url = URLS.UPDATE_DETAILS_SELL_PHASES_URL(salesTemplateId);
    delete data.sellPhaseTemplateId;
    data.sellPhases.forEach((item: any) => {
      item.checkListItemIds.forEach((i: any, index: any) => {
        item.checkListItemIds[index] = i.checkListItemId;
      });
    });
    ServiceApi.put(url, data)
      .then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
        getData();
      })
      .finally(() => {
        setLoadingButton(false);
      });
  };

  const handleSetData = (key: any) => {
    return (value: any) => setData((p: any) => ({...p, [key]: value}));
  };

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = () => {
    setLoadingPage(true);
    const url = URLS.GET_DETAILS_SELL_PHASES_URL(salesTemplateId);
    ServiceApi.get(url)
      .then(({data}) => {
        setBaseData(data);
      })
      .finally(() => {
        setLoadingPage(false);
      });
  };

  return (
    <Fragment>
      <Form onSubmit={submit} className="relative space-y-6">
        <div className="flex items-center">
          <div className="flex-1">
            <h3 className="mr-auto text-heading-3">
              <Breadcrumb />
            </h3>
          </div>
        </div>
        <Wrapper>
          <Wrapper.Header>
            <div className="flex">
              <div className="mr-auto">
                <h6 className="text-heading-2 text-gray-800">
                  <Text>
                    salesManagment.sellOffSales.salesTemplates.basicInfo
                  </Text>
                </h6>
              </div>
            </div>
          </Wrapper.Header>
          <Wrapper.Body className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-8">
            <div className="space-y-8">
              {loadingPage ? (
                <Skeleton.Input />
              ) : (
                <InputGroup
                  label="salesManagment.sellOffSales.salesTemplates.templateName"
                  value={data?.title}
                  setValue={handleSetData("title")}
                  rules={rules.required}
                  disabled={!updatePermission}
                />
              )}
              {loadingPage ? (
                <Skeleton.Input />
              ) : (
                <InputGroup
                  label="salesManagment.sellOffSales.salesTemplates.code"
                  value={data?.code}
                  setValue={handleSetData("code")}
                  disabled={!updatePermission}
                />
              )}
            </div>
            <div className="space-y-8">
              {loadingPage ? (
                <Skeleton.Input />
              ) : (
                <InputGroup
                  as="textarea"
                  label="salesManagment.sellOffSales.salesTemplates.shortDescription"
                  value={data?.description}
                  setValue={handleSetData("description")}
                  disabled={!updatePermission}
                />
              )}
            </div>
            <div className="col-span-2 flex items-center justify-end">
              {loadingPage ? (
                <Skeleton.Button />
              ) : (
                <Button as={Link} to={-1} variant="white">
                  <Text>global.buttons.discard</Text>
                </Button>
              )}
              <WithPermission permissions={["SC_UpdateSellPhaseTemplate"]}>
                {loadingPage ? (
                  <Skeleton.Button />
                ) : (
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={!isChanged}
                    loading={loadingButton}
                  >
                    <Text>global.buttons.saveChanges</Text>
                  </Button>
                )}
              </WithPermission>
            </div>
          </Wrapper.Body>
        </Wrapper>
        <Wrapper>
          <Wrapper.Header>
            <div className="flex">
              <div className="mr-auto">
                <h6 className="text-heading-2 text-gray-800">
                  <Text>
                    salesManagment.sellOffSales.salesTemplates.phaseInfo
                  </Text>
                </h6>
              </div>
            </div>
          </Wrapper.Header>
          <Wrapper.Body className="grid grid-cols-3 gap-8 lg:grid-cols-3 lg:gap-8">
            {data?.sellPhases?.map((item: any, index: any) => {
              return (
                <div
                  key={item.id}
                  className="space-y-2 rounded-xl p-6"
                  style={{boxShadow: "0px 4px 20px rgba(56, 71, 109, 0.09)"}}
                >
                  <div className="mb-6 flex">
                    <h4 className="mr-2.5 text-heading-4 text-gray-800">
                      Phase {index + 1}
                    </h4>
                    {updatePermission && (
                      <div
                        onClick={() => {
                          setSelected(item);
                          setSelectedIndex(index + 1);
                          toggleDrawerUpdate();
                        }}
                      >
                        <Icon
                          className="cursor-pointer text-primary"
                          icon="edit"
                        ></Icon>
                      </div>
                    )}
                  </div>
                  <div>
                    <h5 className="text-heading-5 text-gray-700">
                      {item.title}
                    </h5>
                  </div>
                  <div>
                    <span className="text-body-base text-gray-600">
                      <Text>
                        salesManagment.sellOffSales.salesTemplates.discount
                      </Text>
                      :
                    </span>
                    <span className="semibold ml-1 text-body-base text-danger">
                      {item.discount}
                      <span>%</span>
                    </span>
                  </div>
                  <div>
                    <span className="text-body-base text-gray-600">
                      <Text>
                        salesManagment.sellOffSales.salesTemplates.duration
                      </Text>
                      :
                    </span>
                    <span className="semibold ml-1 text-body-base text-gray-700">
                      {item.duration}
                      <span className="ml-1">day</span>
                    </span>
                  </div>
                  <div>
                    <span className="text-body-base text-gray-600">
                      <Text>
                        salesManagment.sellOffSales.salesTemplates.sellerCommission
                      </Text>
                      :
                    </span>
                    <span className="semibold ml-1 text-body-base text-gray-700">
                      {item.sellerCommission}
                      <span>%</span>
                    </span>
                  </div>
                  <div>
                    <span className="semibold text-body-base text-gray-700">
                      {item.description}
                    </span>
                  </div>
                  <div className="flex-1">
                    {item?.checkListItemIds?.map((i: any) => {
                      return (
                        <span
                          className={`m-0.5 ml-0 mr-1 inline-block gap-8 rounded-md bg-primary-light px-2 py-1 align-middle text-body-2 font-medium text-primary`}
                        >
                          {i.label}
                        </span>
                      );
                    })}
                  </div>
                </div>
              );
            })}
            <WithPermission permissions={["SC_UpdateSellPhaseTemplate"]}>
              <Wrapper.Section className="courser-pointer  h-full w-full cursor-pointer border-primary bg-primary-light text-center">
                <div
                  className="flex h-[210px] w-full items-center justify-center"
                  onClick={() => {
                    toggleDrawerCreate();
                  }}
                >
                  <h4 className="text-gray-800">
                    <Icon className="mr-2 text-primary" icon={"plus"} />
                    <Text>
                      salesManagment.sellOffSales.salesTemplates.addPhase
                    </Text>
                  </h4>
                </div>
              </Wrapper.Section>
            </WithPermission>
          </Wrapper.Body>
        </Wrapper>
      </Form>
      <AddPhase
        isOpen={isOpenDrawerCreate}
        salesTemplateId={salesTemplateId}
        data={data}
        getData={getData}
        toggle={toggleDrawerCreate}
      />
      <UpdatePhase
        isOpen={isOpenDrawerUpdate}
        toggle={toggleDrawerUpdate}
        selectedIndex={selectedIndex}
        selected={selected}
        salesTemplateId={salesTemplateId}
        data={data}
        getData={getData}
      />
    </Fragment>
  );
}
