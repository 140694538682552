import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import NotificationManager from "./NotificationManager";
import Notifications from "./Notifications";
import "./notification.scss";

const NotificationContainer = ({enterTimeout = 400, leaveTimeout = 400}) => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    NotificationManager.addChangeListener(handleStoreChange);
    return () => {
      NotificationManager.removeChangeListener(handleStoreChange);
    };
  });

  const handleStoreChange = notifications => {
    setNotifications([...notifications]);
  };

  const handleRequestHide = notification => {
    NotificationManager.remove(notification);
  };

  return (
    <Notifications
      enterTimeout={enterTimeout}
      leaveTimeout={leaveTimeout}
      notifications={notifications}
      onRequestHide={handleRequestHide}
    />
  );
};

export default NotificationContainer;
