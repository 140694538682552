export const apiKeys = {
  apiKeysList: "API-Schlüsselliste",
  generateAPIKey: "Schlüssel generieren",
  apiKeyName: "API Key Name DE",
  apiKeys: "API-Schlüssel",
  compony: "Unternehmen",
  configurations: "Konfigurationen",
  create: "Erstellen",
  discard: "Verwerfen",
  view: "Anzeigen",
  description: "Beschreibung",
  code: "Code",
  copy: "Kopieren",
  leganName: "Offizieller Name",
  generalInfo: "Allgemeine Informationen",
  saveChanges: "Änderungen speichern",
  yourApiKey: "Ihr API-Schlüssel",
  itemSelected: "Element ausgewählt",
  pmGenerateApikey:
    "Bitte notieren Sie Ihren API Key, da Sie ihn später nicht mehr anzeigen können.",
  pmDeleteApikey: "Sind Sie sicher, dass Sie dieses Element löschen möchten?",
};
