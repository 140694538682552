import {Drawer, Text, Button, Icon} from "components";
import {useSelector, useToggleState} from "hooks";
import FilterList from "./channel-list";
import {useEffect, useState} from "react";
import {isEmpty} from "lodash";
import OrderList from "./order-list";
import EmployeeList from "./employee-list";

interface Props {
  isOpen: boolean;
  isFilter: boolean;
  toggle: () => void;
  resetFilter: () => void;
  dispatchParams: any;
  params: any;
}

export default function Filter({
  isOpen,
  toggle,
  dispatchParams,
  isFilter,
  resetFilter,
  params: searchParams,
}: Props) {
  const channels = useSelector(s => s.channels);
  const [isOpenList, toggleList] = useToggleState();
  const [isOpenOrder, toggleOrder] = useToggleState();
  const [isOpenEmployee, toggleEmployee] = useToggleState();

  const [channel, setChannel] = useState<any>({});
  const [order, setOrder] = useState<any>({});
  const [employee, setEmployee] = useState<any>({});

  const [params, setParams] = useState({
    channelCode: undefined,
    orderId: undefined,
    employeeId: undefined,
  });

  const onFilter = () => {
    dispatchParams((p: any) => ({
      ...p,
      channelCode: params.channelCode,
      orderId: params.orderId,
      employeeId: params.employeeId,
    }));
    toggle();
  };

  useEffect(() => {
    if (!searchParams?.channelCode) {
      setChannel({});
    }
    if (!searchParams?.orderId) {
      setOrder({});
    }
    if (!searchParams?.employeeId) {
      setEmployee({});
    }
    setParams({
      channelCode: searchParams?.channelCode,
      orderId: searchParams?.orderId,
      employeeId: searchParams?.employeeId,
    });
  }, [searchParams]);

  return (
    <>
      <Drawer isOpen={isOpen} toggle={toggle}>
        <Drawer.Menu>
          <Drawer.Header>
            <div className="flex items-center justify-between">
              <span className="text-heading-2 font-semibold">
                <Text>productManagement.products.allProducts.filters</Text>
              </span>
              <div className="flex gap-x-2">
                {isFilter && (
                  <Button
                    size="sm"
                    variant={"danger"}
                    light
                    onClick={resetFilter}
                  >
                    <Icon icon="times-circle" className="mr-1" />
                    <Text>
                      productManagement.products.allProducts.resetFilters
                    </Text>
                  </Button>
                )}
                <div className="flex gap-2">
                  <Button size="sm" light onClick={onFilter}>
                    <Text>Filter</Text>
                  </Button>
                  <Button size="sm" variant={"light"} onClick={toggle}>
                    <Icon icon="times" className="mr-1" />
                    <Text>productManagement.products.allProducts.close</Text>
                  </Button>
                </div>
              </div>
            </div>
          </Drawer.Header>
          <Drawer.Body className="space-y-6 border-b-gray-300 *:space-y-4 *:border-b *:pb-4">
            <div>
              <h6 className="font-normal">
                <Text>Employee</Text>
              </h6>
              {!isEmpty(employee) ? (
                <div className="flex items-center justify-between rounded-xl p-4 shadow-items">
                  <h5 className="text-gray-800">
                    {employee.firstName} {employee.lastName}
                  </h5>
                  <Button
                    variant={"danger"}
                    light
                    size="sm"
                    onClick={() => {
                      setEmployee({});
                      setParams(p => ({
                        ...p,
                        employeeId: undefined,
                      }));
                    }}
                  >
                    <Icon icon="trash" />
                  </Button>
                </div>
              ) : (
                <Button size="sm" light onClick={toggleEmployee}>
                  <Icon icon="plus" className="mr-1" />
                  <Text>Select employee</Text>
                </Button>
              )}
            </div>
            <div>
              <h6 className="font-normal">
                <Text>Published Channels</Text>
              </h6>
              {!isEmpty(channel) ? (
                <div className="flex items-center justify-between rounded-xl p-4 shadow-items">
                  <h5 className="text-gray-800">{channel?.title}</h5>
                  <Button
                    variant={"danger"}
                    light
                    size="sm"
                    onClick={() => {
                      setChannel({});
                      setParams(p => ({
                        ...p,
                        channelCode: undefined,
                        orderId: undefined,
                      }));
                    }}
                  >
                    <Icon icon="trash" />
                  </Button>
                </div>
              ) : (
                <Button
                  size="sm"
                  light
                  onClick={() => {
                    toggleList();
                  }}
                >
                  <Icon icon="plus" className="mr-1" />
                  <Text>Select Channel</Text>
                </Button>
              )}
            </div>
            <div>
              <h6 className="font-normal">
                <Text>Order</Text>
              </h6>
              {!isEmpty(order) ? (
                <div className="flex items-center justify-between rounded-xl p-4 shadow-items">
                  <h5 className="text-gray-800">{order?.title}</h5>
                  <Button
                    variant={"danger"}
                    light
                    size="sm"
                    onClick={() => {
                      setOrder({});
                      setParams(params => ({
                        ...params,
                        orderId: undefined,
                      }));
                    }}
                  >
                    <Icon icon="trash" />
                  </Button>
                </div>
              ) : (
                <Button
                  size="sm"
                  light
                  disabled={!params?.channelCode}
                  onClick={toggleOrder}
                >
                  <Icon icon="plus" className="mr-1" />
                  <Text>Select Order</Text>
                </Button>
              )}
            </div>
          </Drawer.Body>
        </Drawer.Menu>
      </Drawer>
      <FilterList
        isOpen={isOpenList}
        toggle={toggleList}
        channels={channels}
        dispatch={setChannel}
        dispatchParams={setParams}
      />
      <OrderList
        isOpen={isOpenOrder}
        toggle={toggleOrder}
        channelCode={params?.channelCode}
        dispatch={setOrder}
        dispatchParams={setParams}
      />
      <EmployeeList
        isOpen={isOpenEmployee}
        toggle={toggleEmployee}
        dispatch={setEmployee}
        dispatchParams={setParams}
      />
    </>
  );
}
