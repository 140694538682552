import {useState, useEffect, useRef, FormEvent} from "react";
import {
  Form,
  Button,
  Text,
  Icon,
  DatePicker,
  Select,
  Drawer,
  InputGroup,
  RadioButton,
  CheckBox,
} from "components";
import {ServiceApi, URLS} from "services";
import {NotificationManager} from "common/notifications";
import {config, rules} from "constant";
import {useNavigate} from "react-router-dom";
import {useToggleState} from "hooks";

export default function Invite(props: any) {
  const [_loadingList, setLoadingList] = useState(true);
  const [data, setData] = useState<any>({});
  const [dataUserGroup, setDataUserGroup] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [isChecked, toggleChecked] = useToggleState();
  const navigate = useNavigate();
  const [params] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  });
  const formRef = useRef<any>();

  const getData = () => {
    setLoadingList(true);
    setData({
      emailAddress: props.selected.email,
      firstName: props.selected.givenName,
      idPUserObjectId: props.selected.id,
      lastName: props.selected.surname,
      userGroupId: "",
    });
    const url = URLS.USER_GROUP_URL;
    const config = {params};
    setLoadingList(true);
    ServiceApi.get(url, config)
      .then(({data}) => {
        setDataUserGroup(data.items);
      })
      .finally(() => {
        setLoadingList(false);
      });
  };

  useEffect(() => {
    if (props.isOpen) {
      getData();
    }
  }, [props.isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSetData = (key: any) => {
    return (value: any) => setData((p: any) => ({...p, [key]: value}));
  };

  const onSubmitForm = ({data, isChecked}: any) => {
    setLoadingButton(true);
    const url = isChecked ? URLS.POST_INVITE_ADMIN_USER : URLS.POST_INVITE_USER;
    const adminBody = {
      emailAddress: data.emailAddress,
      firstName: data.firstName,
      idPUserObjectId: data.idPUserObjectId,
      lastName: data.lastName,
    };
    const body = isChecked ? adminBody : data;
    ServiceApi.post(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.invite-msg",
          "global.toast.invite-title",
        );
        props.toggle();
        navigate(-1);
      })
      .catch(() => setLoadingButton(false));
  };

  const submit = (e?: FormEvent) => {
    formRef.current.submitHandler(e, {data, isChecked});
  };

  return (
    <Drawer isOpen={props.isOpen} toggle={props.toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pr-4 pt-6">
          <div className="flex items-center justify-between">
            <h2 className="text-heading-2 text-gray-800">
              <Text>configurations.userManagment.userList.inviteUser</Text>
            </h2>
            <div className="flex items-center justify-between">
              <Button
                type="button"
                variant="light"
                size="sm"
                className="ml-2"
                onClick={() => props.toggle()}
              >
                <Icon className="mr-2" icon={"times"} />
                <Text>global.buttons.close</Text>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-4 pr-4">
          <Form
            ref={formRef}
            className="space-y-4 pr-4"
            onSubmit={onSubmitForm}
            id={"invite-drawer"}
          >
            <CheckBox
              value={isChecked}
              setValue={toggleChecked}
              label="configurations.userManagment.userList.asAdminstrator"
            />
            {!isChecked && (
              <Select
                label="configurations.userManagment.userList.usersGroup"
                items={dataUserGroup}
                value={data.userGroupId}
                setValue={e => {
                  dataUserGroup?.forEach((i: any) => {
                    if (i.id === e) {
                      setData((p: any) => ({...p, userGroupId: i.id}));
                    }
                  });
                }}
                rules={rules.required}
              />
            )}
            <InputGroup
              label="configurations.userManagment.userList.emailAddress"
              disabled
              value={data.emailAddress}
            />
            <InputGroup
              label="configurations.userManagment.userList.firstName"
              value={data.firstName}
              setValue={handleSetData("firstName")}
              rules={rules.required}
            />
            <InputGroup
              label="configurations.userManagment.userList.lastName"
              value={data.lastName}
              setValue={handleSetData("lastName")}
              rules={rules.required}
            />
          </Form>
        </Drawer.Body>
        <Drawer.Footer className="flex justify-between">
          <Button
            type="button"
            className="mr-2"
            onClick={() => props.toggle()}
            variant="light"
          >
            <Text>global.buttons.cancel</Text>
          </Button>
          <Button type="button" onClick={submit} loading={loadingButton}>
            <Text>global.buttons.submit</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
