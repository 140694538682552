import {useTranslation} from "react-i18next";

type HandleNameProps = {
  translations: any;
  language?: any;
  code?: string;
  className?: string;
  keyName?: string;
  sliceText?: boolean;
};

const HandleName = ({
  translations,
  code,
  keyName = "name",
  language,
  sliceText = false,
}: HandleNameProps) => {
  const {i18n} = useTranslation();
  if (language === undefined) {
    language = i18n.language;
  }
  if (translations) {
    let newArray = translations?.filter(function (item: any) {
      return (
        language?.toLocaleLowerCase() === item.language?.toLocaleLowerCase() &&
        item
      );
    });
    if (newArray.length === 0) {
      newArray = translations?.filter(function (item: any) {
        return !item.language && item;
      });
    }
    return newArray.length !== 0 && newArray[0][keyName]
      ? sliceText
        ? newArray[0][keyName].slice(0, 2).toUpperCase()
        : newArray[0][keyName].replace(/(<([^>]+)>)/gi, "")
      : sliceText
        ? " "
        : keyName !== "description" && (
            <span className="font-normal text-gray-600">{`No name in ${language} [${code}]`}</span>
          );
  }
  return "";
};

export default HandleName;
