import { NotificationManager } from "common/notifications";
import {
  Button,
  DeleteModal,
  HandleName,
  Icon,
  InputGroup,
  NoItems,
  SelectLang,
  Skeleton,
  Status,
  Table,
  Text,
  WithPermission,
  Wrapper,
} from "components";
import { useSelector, useToggleState, usePermission } from "hooks";
import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ServiceApi, URLS } from "services";
import { onFilter } from "utils";
import { MeasurementContext } from "..";
import CreateModal from "./unit-modals/create-modal";
import UpdateModal from "./unit-modals/update-modal";

export default function Attributes() {
  const updatePermission = usePermission("PS_UpdateMeasurementFamily");
  const { i18n } = useTranslation();
  const companySetting = useSelector(s => s.companySetting);
  const { measurementData, measurementFamilyId, getData } =
    useContext<any>(MeasurementContext);
  const [data, setData] = useState<any>();
  const [dataList, setDataList] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<any>({});
  const [isOpenCreateModal, toggleCreateModal] = useToggleState(false);
  const [isOpenUpdateModal, toggleUpdateModal] = useToggleState(false);
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [langItems, setLangItems] = useState([]);
  const [langSelected, setLangSelected] = useState("de");
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    setDataList(measurementData?.units);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let arr: any = [];
    companySetting?.contentLanguages?.forEach((item: any) => {
      arr.push({ id: item, name: item });
    });
    setLangItems(arr);
  }, [companySetting]); // eslint-disable-line react-hooks/exhaustive-deps

  const submitDelete = (id: any) => {
    setLoadingButton(true);
    const url = URLS.DELETE_MEASUREMENT_FAMILY_UNIT_URL(
      measurementFamilyId,
      id,
    );
    ServiceApi.delete(url)
      .then(({ data }) => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title",
        );
        setLoadingButton(false);
        getData();
        toggleDeleteModal();
      })
      .catch(() => setLoadingButton(false));
  };

  const onChangeLanguage = (lang: any) => {
    setLangSelected(lang);
  };

  const onSearchHandler = (e: string) => {
    let keys = ["title", "code"];
    if (e !== "") {
      setDataList(onFilter(data, keys, e));
    } else {
      setDataList(data);
    }
    setSearchValue(e);
  };

  const handleOPenUpdateModal = (e: any) => {
    if (updatePermission) {
      setSelected(e);
      toggleUpdateModal();
    }
  }

  return (
    <div className="space-y-4">
      {loading ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <Fragment>
          <div className="flex items-center justify-between">
            {data?.length !== 0 && (
              <div className="grid grid-cols-5 items-center gap-4">
                <InputGroup
                  value={searchValue}
                  setValue={onSearchHandler}
                  placeholder="productManagement.masterData.attributesGroups.searchAttribute"
                  disabled={loading}
                />
                <p className="text-body-2">
                  {searchValue.length === 0
                    ? dataList?.length
                    : dataList?.length}{" "}
                  <Text>global.items</Text>
                </p>
              </div>
            )}
            <div className="text-right">
              <SelectLang
                className="bg-white"
                value={langSelected}
                items={langItems}
                onChangeLanguage={onChangeLanguage}
              />
            </div>
          </div>
          <Wrapper.Section className="courser-pointer mb-4 h-[52px] w-full cursor-pointer border-primary bg-gray-100 py-[17px] text-center">
            {updatePermission && (
              <div
                className="w-full"
                onClick={() => {
                  toggleCreateModal();
                }}
              >
                <h5 className="text-primary">
                  <Icon className="mr-2 text-primary" icon={"square-plus"} />
                  <Text>
                    productManagement.masterData.measurementUnits.addUnit
                  </Text>
                </h5>
              </div>
            )}
          </Wrapper.Section>
          {dataList?.length !== 0 ? (
            <Table>
              {dataList?.map((e: any) => {
                return (
                  <tr>
                    <td>
                      <div className="flex-center inline-flex w-fit gap-4">
                        <div className="flex h-[42px] w-[42px] items-center justify-center rounded-[6px] bg-primary-light">
                          <div>
                            <h3 className="text-heading-3 font-semibold text-primary" >
                              {e.symbol}
                            </h3>
                          </div>
                        </div>
                        <div className={`space-y-3 ${updatePermission && 'cursor-pointer'}`} onClick={() => handleOPenUpdateModal(e)}>
                          <div>
                            <h6 className="text-heading-6 font-semibold text-gray-800">
                              {e && (
                                <HandleName
                                  translations={e?.translates}
                                  code={e?.code}
                                  keyName="labelTitle"
                                  language={langSelected}
                                />
                              )}
                            </h6>
                          </div>
                          <div>
                            <span className="text-body-2 font-medium text-gray-500">
                              {e.code}
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <Status.DefaultStatus id={e.isDefault} />
                    </td>
                    <td>
                      {!e.isDefault && (
                        <WithPermission
                          permissions={["PS_UpdateMeasurementFamily"]}
                        >
                          <Button
                            type="button"
                            size="sm"
                            variant="danger"
                            className="mr-2"
                            light
                            onClick={() => {
                              setSelected(e);
                              toggleDeleteModal();
                            }}
                          >
                            <Icon icon={"trash"} />
                          </Button>
                        </WithPermission>
                      )}
                      <WithPermission
                        permissions={["PS_UpdateMeasurementFamily"]}
                      >
                        <Button
                          type="button"
                          onClick={() => handleOPenUpdateModal(e)}
                          variant="primary"
                          light
                          size="sm"
                        >
                          <Icon icon={"edit"} />
                        </Button>
                      </WithPermission>
                    </td>
                  </tr>
                );
              })}
            </Table>
          ) : (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
            </div>
          )}
          <CreateModal
            isOpen={isOpenCreateModal}
            toggle={toggleCreateModal}
            measurementFamilyId={measurementFamilyId}
            getData={getData}
          />
          <UpdateModal
            isOpen={isOpenUpdateModal}
            toggle={toggleUpdateModal}
            measurementFamilyId={measurementFamilyId}
            getData={getData}
            selected={selected}
          />
          <DeleteModal
            isOpen={isOpenDeleteModal}
            toggle={toggleDeleteModal}
            selected={{
              name: (
                <HandleName
                  translations={selected?.labelTitle}
                  code={selected?.code}
                  keyName="name"
                  language={langSelected}
                />
              ),
              id: selected.id,
            }}
            loading={loadingButton}
            onConfirm={submitDelete}
          />
        </Fragment>
      )}
    </div>
  );
}
