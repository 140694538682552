import {
  Button,
  Form,
  InputGroup,
  RadioButton,
  Skeleton,
  Text,
  WithPermission,
  Wrapper,
} from "components";
import {rules} from "constant";
import {useDataState} from "hooks";
import {Fragment, useEffect, useMemo, useState} from "react";
import toast from "react-hot-toast";
import {ServiceApi, URLS} from "services";

type Data = {
  personalAzureBlobConfigId?: string;
  key?: string | null;
  account?: string | null;
  connectionString?: string | null;

  isPersonal?: boolean;
};

export default function CloudStorage() {
  const [data, setData, setBaseData, isChanged] = useDataState<Data>({});
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const isPersonal = !!data.isPersonal;
  const disabled = !isPersonal;
  const canRemove = !!data.personalAzureBlobConfigId;

  const handleRules = useMemo(() => {
    if (disabled) return [];
    return rules.required;
  }, [disabled]);

  const setIsPersonal = (isPersonal: boolean) => {
    setData(p => ({...p, isPersonal}));
  };
  const handleValue = (key: keyof Data) => {
    if (!isPersonal) return "";
    return data[key];
  };
  const handleSetValue = (key: keyof Data) => {
    return (value: string) => {
      setData(p => ({...p, [key]: value}));
    };
  };
  const update = () => {
    setLoadingButton(true);
    const url = URLS.POST_PERSONAL_AZURE_BLOB_CONFIG;
    const body = {...data};
    ServiceApi.post(url, body)
      .then(({data: personalAzureBlobConfigId}) => {
        toast.success(
          "configurations.globalSettings.dataStorage.cloudStorageUpdateMessage",
        );
        setBaseData({...body, personalAzureBlobConfigId});
      })
      .finally(() => {
        setLoadingButton(false);
      });
  };
  const remove = () => {
    if (!canRemove)
      return toast.error(
        "configurations.globalSettings.dataStorage.cloudStorageRemoveErrorMessage",
      );
    setLoadingButton(true);
    const url = URLS.DELETE_PERSONAL_AZURE_BLOB_CONFIG;
    ServiceApi.delete(url)
      .then(() => {
        toast.success(
          "configurations.globalSettings.dataStorage.cloudStorageUpdateMessage",
        );
        setBaseData({});
      })
      .finally(() => {
        setLoadingButton(false);
      });
  };
  const submit = isPersonal ? update : remove;
  const getData = () => {
    setLoadingPage(true);
    ServiceApi.get(URLS.GET_PERSONAL_AZURE_BLOB_CONFIG)
      .then(({data}) => {
        const isPersonal = !!data.personalAzureBlobConfigId;
        setBaseData({...data, isPersonal});
      })
      .finally(() => {
        setLoadingPage(false);
      });
  };

  useEffect(getData, []);

  return (
    <Form onSubmit={submit}>
      <Wrapper>
        <Wrapper.Header>
          <h2 className="text-heading-2 text-gray-800">
            <Text>configurations.globalSettings.dataStorage.cloudStorage</Text>
          </h2>
        </Wrapper.Header>
        <Wrapper.Body className="space-y-8">
          {loadingPage ? (
            <Fragment>
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Fragment>
          ) : (
            <Fragment>
              <div className="grid grid-cols-2 gap-4">
                <RadioButton
                  label="configurations.globalSettings.dataStorage.personalAzureBlobStorage"
                  value={isPersonal}
                  setValue={() => setIsPersonal(true)}
                />
                <RadioButton
                  label="configurations.globalSettings.dataStorage.pulsAzureBlobStorage"
                  value={!isPersonal}
                  setValue={() => setIsPersonal(false)}
                />
              </div>
              <InputGroup
                label="configurations.globalSettings.dataStorage.key"
                value={handleValue("key")}
                setValue={handleSetValue("key")}
                rules={handleRules}
                disabled={disabled}
              />
              <InputGroup
                label="configurations.globalSettings.dataStorage.account"
                value={handleValue("account")}
                setValue={handleSetValue("account")}
                rules={handleRules}
                disabled={disabled}
              />
              <InputGroup
                label="configurations.globalSettings.dataStorage.connectionString"
                value={handleValue("connectionString")}
                setValue={handleSetValue("connectionString")}
                rules={handleRules}
                disabled={disabled}
              />
            </Fragment>
          )}
        </Wrapper.Body>
        <WithPermission permissions={["AsS_UpsertBlobConfig"]}>
          <Wrapper.Footer className="flex items-center justify-end gap-4">
            <Button type="button" onClick={getData} variant="white" size="sm">
              <Text>global.buttons.discard</Text>
            </Button>
            <Button
              type="submit"
              variant="primary"
              size="sm"
              loading={loadingButton}
              disabled={!isChanged}
            >
              <Text>global.buttons.saveChanges</Text>
            </Button>
          </Wrapper.Footer>
        </WithPermission>
      </Wrapper>
    </Form>
  );
}
