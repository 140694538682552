import {NotificationManager} from "common/notifications";
import {
  Button,
  Drawer,
  Form,
  Icon,
  Text,
  Wrapper,
  Skeleton,
  AddressViewer,
  InputGroup,
  Table,
  NoItems,
  RadioButton,
} from "components";
import {config} from "constant";
import {useToggleState} from "hooks";
import {FormEvent, useEffect, useRef, useState, Fragment} from "react";
import AsyncSelect from "react-select/async";
import {ServiceApi, URLS} from "services";
import {debounce} from "utils";

type createModalType = {
  isOpen: boolean;
  toggle: (value?: boolean) => void;
  userId: any;
  getData: () => void;
  setLoading: (value: boolean) => void;
};

export default function SearchDrawer(props: createModalType) {
  const [loading, setLoading] = useToggleState();
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<any>();
  const [userData, setUserData] = useState<any>();
  const [show, setShow] = useState<boolean>(false);
  const formRef = useRef<any>();
  const [data, setData] = useState<any>([]);
  const [selectedSSO, dispachSSO] = useState<any>(null);
  const [searchType, dispatchSearchtype] = useState<number>(0);

  const [inputValue, setInputValue] = useState("");

  const searchSSOUser = (value: string) => {
    setLoading();
    const url = URLS.SEARCH_AD_USERS(value);
    ServiceApi.get(url)
      .then(({data}) => {
        setData(data);
      })
      .finally(() => {
        setLoading();
      });
  };

  useEffect(() => {
    if (props.isOpen) {
      setShow(false);
      setData([]);
      setInputValue("");
      dispachSSO(null);
      setSelectedOptions(null);
    }
  }, [props.isOpen]);

  const onSubmitForm = (formData: any) => {
    setLoadingButton(true);
    const url = URLS.PUT_EMPLOYEE_USER(formRef?.current?.userId);
    const body = {employeeId: formData?.employeeId};
    ServiceApi.put(url, body)
      .then(() => {
        props.setLoading(true);
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
        props.getData();
        props.toggle();
      })
      .finally(() => {
        setLoadingButton(false);
      });
  };

  const submit = (e?: FormEvent) => {
    formRef.current.userId = props?.userId;
    formRef.current.submitHandler(e, userData);
  };

  const handleSearch = async (filter: string) => {
    const urlSearch =
      URLS.GET_EMPLOYEES_LIST_URL +
      `?pageNumber=${1}&pageSize=${10}&keyword=${filter}&userCreatedFromDate=&userCreatedToDate`;
    let newDataSearch: any;
    let arrSearch: any = [];
    return ServiceApi.get(urlSearch).then(({data}) => {
      newDataSearch = data;
      newDataSearch?.items?.forEach((item: any) => {
        arrSearch.push({
          value: item.employeeId,
          label: `${item.firstName} ${item.lastName}`,
        });
      });
      return arrSearch;
    });
  };

  const promiseOptions = (inputValue: string) =>
    new Promise<any[]>(resolve => {
      setTimeout(() => {
        resolve(handleSearch(inputValue));
      }, 200);
    });

  const handelDepartmentsLables = (departments: any) => {
    let label: any = [];
    departments?.forEach((department: any) => {
      label.push(department.name);
    });

    return label.length === 0 ? "" : label?.toString();
  };

  const assignSSOEmployee = (employee: any) => {
    const url = URLS.UPSERT_AAD_USER(props?.userId);
    const body = {
      emailAddress: employee?.email,
    };
    ServiceApi.put(url, body).then(() => {
      NotificationManager.success(
        "global.toast.edit-msg",
        "global.toast.edit-title",
      );
      props.getData();
      props.toggle();
    });
  };

  return (
    <Drawer isOpen={props.isOpen} toggle={props.toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="!text-left">
          <h2>
            <Text>configurations.userManagment.userList.searchEmployee</Text>
          </h2>
        </Drawer.Header>
        <Drawer.Body>
          <div className="mt-4 space-y-4 *:text-heading-5 *:text-gray-700">
            <RadioButton
              label="Search in SSO active directory"
              value={searchType === 0}
              setValue={() => dispatchSearchtype(0)}
            />
            <RadioButton
              label="Select from employees"
              value={searchType === 1}
              setValue={() => dispatchSearchtype(1)}
            />
          </div>
          {searchType === 0 && (
            <InputGroup
              value={inputValue}
              className="mt-8 border-white"
              placeholder="Search User Name, Email ... in SSO"
              setValue={value => {
                setInputValue(value);
                if (value && value?.length > 2) {
                  debounce(() => {
                    searchSSOUser(value);
                  }, 400);
                }
              }}
            />
          )}
          {searchType === 1 && (
            <Form
              ref={formRef}
              onSubmit={onSubmitForm}
              id={"create-modal"}
              className="grid grid-cols-1 gap-8 py-8 lg:grid-cols-1"
            >
              <div className="w-[443px]">
                <AsyncSelect
                  value={selectedOptions}
                  cacheOptions
                  placeholder={
                    <Text>
                      configurations.userManagment.userList.selectEmployee
                    </Text>
                  }
                  loadOptions={promiseOptions}
                  defaultOptions
                  styles={config.styleSelectReact}
                  onChange={(e: any) => {
                    setSelectedOptions(e);
                    if (e) {
                      setShow(true);
                      setLoading();
                      const url = URLS.GET_EMPLOYEES_DETAILS_URL(e?.value);
                      ServiceApi.get(url)
                        .then(({data}) => {
                          let result = JSON.parse(JSON.stringify(data));
                          setUserData(result);
                        })
                        .finally(() => {
                          setLoading();
                        });
                    } else {
                      setShow(false);
                      setUserData([]);
                    }
                  }}
                  isClearable
                />
              </div>

              {show && (
                <>
                  <h2 className="mb-6">
                    <Text>configurations.userManagment.userList.info</Text>
                  </h2>
                  <div className="grid grid-cols-1 gap-6">
                    <Wrapper.Section className="space-y-4">
                      <h3 className="mb-2 text-heading-3 font-semibold text-gray-600">
                        <Text>company.employees.basicInfo</Text>
                      </h3>
                      {loading ? (
                        <Fragment>
                          <Skeleton.Input />
                          <Skeleton.Input />
                          <Skeleton.Input />
                          <Skeleton.Input />
                          <Skeleton.Input />
                        </Fragment>
                      ) : (
                        <Fragment>
                          <div className="grid grid-cols-2 gap-8">
                            <span className="text-body-base font-medium text-gray-800">
                              <Text>company.employees.displayName</Text>
                            </span>
                            <h5 className="text-heading-5 font-normal text-gray-700">
                              {userData?.displayName}
                            </h5>
                          </div>
                          <div className="grid grid-cols-2 gap-8">
                            <span className="text-body-base font-medium text-gray-800">
                              <Text>company.employees.firstName</Text>
                            </span>
                            <h5 className="text-heading-5 font-normal text-gray-700">
                              {userData?.firstName}
                            </h5>
                          </div>
                          <div className="grid grid-cols-2 gap-8">
                            <span className="text-body-base font-medium text-gray-800">
                              <Text>company.employees.lastName</Text>
                            </span>
                            <h5 className="text-heading-5 font-normal text-gray-700">
                              {userData?.lastName}
                            </h5>
                          </div>
                          <div className="grid grid-cols-2 gap-8">
                            <span className="text-body-base font-medium text-gray-800">
                              <Text>company.employees.nickName</Text>
                            </span>
                            <h5 className="text-heading-5 font-normal text-gray-700">
                              {userData?.nickname}
                            </h5>
                          </div>
                        </Fragment>
                      )}
                    </Wrapper.Section>
                    <Wrapper.Section className="space-y-4">
                      <h3 className="mb-2 text-heading-3 font-semibold text-gray-600">
                        <Text>company.employees.employmentInfo</Text>
                      </h3>
                      {loading ? (
                        <Fragment>
                          <Skeleton.Input />
                          <Skeleton.Input />
                          <Skeleton.Input />
                          <Skeleton.Input />
                          <Skeleton.Input />
                          <Skeleton.Input />
                          <Skeleton.Input />
                          <Skeleton.Input />
                        </Fragment>
                      ) : (
                        <>
                          <div className="grid grid-cols-2 gap-8">
                            <span className="text-body-base font-medium text-gray-800">
                              <Text>company.employees.jobTitle</Text>
                            </span>
                            <h5 className="text-heading-5 font-normal text-gray-700">
                              {userData?.jobTitle}
                            </h5>
                          </div>
                          <div className="grid grid-cols-2 gap-8">
                            <span className="text-body-base font-medium text-gray-800">
                              <Text>company.employees.store</Text>
                            </span>
                            <h5 className="text-heading-5 font-normal text-gray-700">
                              {userData?.store?.title}
                            </h5>
                          </div>
                          <div className="grid grid-cols-2 gap-8">
                            <span className="text-body-base font-medium text-gray-800">
                              <Text>company.employees.departments</Text>
                            </span>
                            <h5 className="text-heading-5 font-normal text-gray-700">
                              {handelDepartmentsLables(userData?.departments)}
                            </h5>
                          </div>
                          <div className="grid grid-cols-2 gap-8">
                            <span className="text-body-base font-medium text-gray-800">
                              <Text>company.employees.employeeNumber</Text>:
                            </span>
                            <h5 className="text-heading-5 font-normal text-gray-700">
                              {userData?.employeeNumber}
                            </h5>
                          </div>
                          <div className="grid grid-cols-2 gap-8">
                            <span className="text-body-base font-medium text-gray-800">
                              <Text>company.employees.salesPersonNumber</Text>
                            </span>
                            <h5 className="text-heading-5 font-normal text-gray-700">
                              {userData?.salesPersonNumber}
                            </h5>
                          </div>
                        </>
                      )}
                    </Wrapper.Section>
                    <Wrapper.Section className="space-y-4">
                      <h3 className="mb-2 text-heading-3 font-semibold text-gray-600">
                        <Text>company.employees.contactInfo</Text>
                      </h3>
                      {loading ? (
                        <Fragment>
                          <Skeleton.Input />
                          <Skeleton.Input />
                          <Skeleton.Input />
                          <Skeleton.Input />
                          <Skeleton.Input />
                          <Skeleton.Input />
                          <Skeleton.Input />
                          <Skeleton.Input />
                        </Fragment>
                      ) : (
                        <>
                          <div className="grid grid-cols-2 items-center gap-8">
                            <span className="text-body-base font-medium text-gray-800">
                              <Text>company.employees.businessPhone</Text>
                            </span>
                            <div>
                              <h5 className="text-heading-5 font-normal text-gray-700">
                                {userData?.businessPhone}
                              </h5>
                            </div>
                          </div>
                          <div className="grid grid-cols-2 items-center gap-8">
                            <span className="text-body-base font-medium text-gray-800">
                              <Text>company.employees.mobilePhone</Text>
                            </span>
                            <div>
                              <h5 className="text-heading-5 font-normal text-gray-700">
                                {userData?.mobilePhone}
                              </h5>
                            </div>
                          </div>
                          <div className="grid grid-cols-2 items-center gap-8">
                            <span className="text-body-base font-medium text-gray-800">
                              <Text>company.employees.faxNumber</Text>
                            </span>
                            <div>
                              <h5 className="text-heading-5 font-normal text-gray-700">
                                {userData?.faxNumber}
                              </h5>
                            </div>
                          </div>
                          <div className="grid grid-cols-2 items-center gap-8">
                            <span className="text-body-base font-medium text-gray-800">
                              <Text>company.employees.emailAddress</Text>
                            </span>
                            <div>
                              <h5 className="break-all text-heading-5 font-normal text-gray-700">
                                {userData?.emailAddress}
                              </h5>
                            </div>
                          </div>
                          <div className="grid grid-cols-2 items-center gap-8">
                            <span className="text-body-base font-medium text-gray-800">
                              <Text>company.employees.address</Text>
                            </span>
                            <div>
                              <h5 className="text-heading-5 font-normal text-gray-700">
                                <AddressViewer address={userData?.address} />
                              </h5>
                            </div>
                          </div>
                        </>
                      )}
                    </Wrapper.Section>
                  </div>
                </>
              )}
            </Form>
          )}
          {loading ? (
            <div className="mt-4 space-y-4">
              {[...Array(3)].map(() => (
                <Skeleton.List />
              ))}
            </div>
          ) : (
            <>
              {data?.length !== 0 && !show && (
                <div className="mt-4 space-y-4">
                  <>
                    {data?.length === 0 ? (
                      <div className="flex w-full flex-col items-center space-y-4">
                        <NoItems />
                      </div>
                    ) : (
                      data?.map((item: any, index: number) => {
                        return (
                          <div
                            key={item.userId}
                            className="flex items-center justify-between"
                          >
                            <div className="space-y-2">
                              <div>
                                <h6 className="text-heading-6 font-semibold text-gray-800">
                                  {item.userPrincipalName}
                                </h6>
                              </div>
                              {item.email && (
                                <div>
                                  <Icon
                                    className="mr-2 font-black text-gray-500"
                                    size="sm"
                                    icon="envelope"
                                  />
                                  <span className="text-body-2 font-medium text-gray-500">
                                    {item.email}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div>
                              <Button
                                variant="light"
                                size="sm"
                                loading={selectedSSO === index}
                                onClick={e => {
                                  e.preventDefault();
                                  dispachSSO(index);
                                  assignSSOEmployee(item);
                                }}
                              >
                                <Text>assign</Text>
                              </Button>
                            </div>
                          </div>
                        );
                      })
                    )}
                  </>
                </div>
              )}
            </>
          )}
        </Drawer.Body>
        {searchType === 1 && (
          <Drawer.Footer className="flex items-center justify-between">
            <Button
              type="button"
              className="mr-2"
              onClick={props.toggle}
              variant="white"
            >
              <Icon className="mr-2" icon={"times"} />
              <Text>global.buttons.close</Text>
            </Button>
            <Button type="button" onClick={submit} loading={loadingButton}>
              <Text>global.buttons.submit</Text>
            </Button>
          </Drawer.Footer>
        )}
      </Drawer.Menu>
    </Drawer>
  );
}
