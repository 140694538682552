import {useMemo} from "react";
import {Trans, useTranslation} from "react-i18next";

type textProps = {
  children: string | undefined;
  [key: string]: string | number | null | undefined;
};
export default function Text({children, ...props}: textProps) {
  const {i18n} = useTranslation();
  const propsHandler = useMemo(() => {
    const obj = {} as {[key: string]: string | number};
    Object.keys(props).forEach(key => {
      obj[key] = props[key] ?? "";
    });
    return obj;
  }, [props]);
  return (
    <Trans
      i18n={i18n}
      i18nKey={children ?? ""}
      components={{
        italic: <i />,
        bold: <strong />,
        span: <span />,
        br: <br />,
      }}
      shouldUnescape
      tOptions={{...propsHandler, interpolation: {escapeValue: false}}}
    />
  );
}
