import {
  Button,
  Drawer,
  Icon,
  InputGroup,
  Select,
  Text,
  RadioButton,
  DatePicker,
  Toggler,
} from "components";
import {useNavigate, useSearchParams} from "react-router-dom";
import {HandleParamsUrl} from "utils";

type drawerProps = {
  isOpen: boolean;
  toggle: () => void;
  resetFilter: () => void;
  onChange: (key: any) => any;
  data: any;
  params: any;
  setParams: (key: any) => void;
  setKeyword: (key: any) => void;
  setThisDate: (key: any) => void;
  setTopBuy: (key: any) => void;
  keyword: any;
  thisDate: any;
  topBuy: any;
};

const FilterDrawer = ({
  isOpen,
  data,
  params,
  toggle,
  onChange,
  resetFilter,
  setParams,
  setKeyword,
  keyword,
  setThisDate,
  thisDate,
  setTopBuy,
  topBuy,
}: drawerProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const nameStatus = (e: any) => {
    switch (e) {
      case "1":
        return "NoWarning";
      case "2":
        return "HasWarning";
      case "3":
        return "PendingApproval";
      case "4":
        return "Approved";
      default:
        return "None";
    }
  };

  const statusItems = (key: string) => {
    const clone = data?.[key]?.map((item: any) => ({
      id: item.value,
      name: nameStatus(item.value) + "(" + item.count + ")",
    }));
    clone?.unshift({id: null, name: "All"});
    return clone;
  };

  const createItems = (key: string) => {
    const clone = data?.[key]?.map((item: any) => ({
      id: item.value,
      name: item.value + "(" + item.count + ")",
    }));
    clone?.unshift({id: null, name: "All"});
    return clone;
  };

  // const createProductFamilyItems = () => {
  //   const clone = data?.productFamily?.map((item: any) => ({
  //     id: item.value,
  //     name: item.value + "(" + item.count + ")",
  //   }));
  //   clone?.unshift({ id: null, name: "All" });
  //   return clone;
  // };

  const submit = (e: any) => {
    e.preventDefault();
    if (keyword !== "") {
      setParams((prev: any) => {
        prev.set("keyword", keyword);
        return prev;
      });
    } else {
      setParams((prev: any) => {
        prev.delete("keyword");
        return prev;
      });
    }
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pr-4 pt-6">
          <div className="flex items-center justify-between">
            <span className="text-heading-2 font-semibold">
              <Text>salesManagment.kitchenSales.salesList.filters</Text>
            </span>
            <div className="flex gap-x-2">
              {searchParams?.size !== 0 && (
                <Button
                  size="sm"
                  variant={"danger"}
                  light
                  onClick={() => resetFilter()}
                >
                  <Icon icon="times-circle" className="mr-1" />
                  <Text>
                    salesManagment.kitchenSales.salesList.resetFilters
                  </Text>
                </Button>
              )}
              <Button size="sm" variant={"light"} onClick={toggle}>
                <Icon icon="times" className="mr-1" />
                <Text>salesManagment.kitchenSales.salesList.close</Text>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-4 px-6 py-8">
          <div className="flex-center flex gap-2">
            <form onSubmit={submit} className="w-full">
              <InputGroup
                label="salesManagment.kitchenSales.salesList.keyword"
                value={keyword}
                setValue={value => setKeyword(value)}
              />
            </form>
            <div
              className="mt-8 flex h-[46px] w-[46px] cursor-pointer items-center justify-center rounded-md bg-gray-100 p-3"
              onClick={() => {
                if (keyword !== "") {
                  setParams((prev: any) => {
                    prev.set("keyword", keyword);
                    return prev;
                  });
                } else {
                  setParams((prev: any) => {
                    prev.delete("keyword");
                    return prev;
                  });
                }
              }}
            >
              <Icon
                className="h-5 w-5 !leading-6 text-primary"
                icon={"search"}
              />
            </div>
          </div>
          <div className="space-y-6">
            <div>
              <h5 className="text-heading-5 font-semibold text-gray-800">
                <Text>salesManagment.kitchenSales.salesList.dateRange</Text>
              </h5>
            </div>
            <div className="flex items-center">
              <RadioButton
                label="salesManagment.kitchenSales.salesList.thisWeek"
                value={thisDate === "thisWeek" ? true : false}
                setValue={() => {
                  let date = new Date();
                  let firstDay = date.getDate() - date.getDay();
                  let lastDay = firstDay + 6;
                  setParams((prev: any) => {
                    prev.set(
                      "dateFrom",
                      new Date(date.setDate(firstDay)).toUTCString(),
                    );
                    prev.set(
                      "dateTo",
                      new Date(date.setDate(lastDay)).toUTCString(),
                    );
                    return prev;
                  });
                  setThisDate("thisWeek");
                  const paramUrl = HandleParamsUrl(
                    searchParams,
                    "dateFrom",
                    new Date(date.setDate(firstDay)).toUTCString(),
                    "dateTo",
                    new Date(date.setDate(lastDay)).toUTCString(),
                  );
                  navigate(
                    `/salesmanagment/kitchen-sales/sales-list?${paramUrl}`,
                  );
                }}
              />
              <RadioButton
                label="salesManagment.kitchenSales.salesList.thisMonth"
                value={thisDate === "thisMonth" ? true : false}
                setValue={() => {
                  let date = new Date();
                  let firstDay = new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    1,
                  );
                  let lastDay = new Date(
                    date.getFullYear(),
                    date.getMonth() + 1,
                    0,
                  );
                  setParams((prev: any) => {
                    prev.set("dateFrom", firstDay);
                    prev.set("dateTo", lastDay);
                    return prev;
                  });
                  setThisDate("thisMonth");
                  const paramUrl = HandleParamsUrl(
                    searchParams,
                    "dateFrom",
                    firstDay.toDateString(),
                    "dateTo",
                    lastDay.toUTCString(),
                  );
                  navigate(
                    `/salesmanagment/kitchen-sales/sales-list?${paramUrl}`,
                  );
                }}
              />
              <RadioButton
                label="salesManagment.kitchenSales.salesList.lastMonth"
                value={thisDate === "lastMonth" ? true : false}
                setValue={() => {
                  let date = new Date();
                  let firstDay = new Date(
                    date.getFullYear(),
                    date.getMonth() - 1,
                    1,
                  );
                  let lastDay = new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    0,
                  );
                  setParams((prev: any) => {
                    prev.set("dateFrom", firstDay);
                    prev.set("dateTo", lastDay);
                    return prev;
                  });
                  setThisDate("lastMonth");
                  const paramUrl = HandleParamsUrl(
                    searchParams,
                    "dateFrom",
                    firstDay.toDateString(),
                    "dateTo",
                    lastDay.toUTCString(),
                  );
                  navigate(
                    `/salesmanagment/kitchen-sales/sales-list?${paramUrl}`,
                  );
                }}
              />
            </div>
            <div className="grid grid-cols-2 gap-2 gap-y-4">
              <DatePicker
                showYearDropdown
                showMonthDropdown
                maxDate={
                  params?.get("dateTo") !== null
                    ? new Date(params?.get("dateTo"))
                    : null
                }
                label="salesManagment.kitchenSales.salesList.from"
                value={
                  params?.get("dateFrom") !== null
                    ? new Date(params?.get("dateFrom"))
                    : null
                }
                onChange={onChange("dateFrom")}
              />
              <DatePicker
                showYearDropdown
                showMonthDropdown
                minDate={
                  params?.get("dateFrom") !== null
                    ? new Date(params?.get("dateFrom"))
                    : null
                }
                label="salesManagment.kitchenSales.salesList.to"
                value={
                  params?.get("dateTo") !== null
                    ? new Date(params?.get("dateTo"))
                    : null
                }
                onChange={onChange("dateTo")}
              />
            </div>
          </div>
          <Select
            label="salesManagment.kitchenSales.salesList.status"
            placeholder="Select"
            value={params?.get("currentStatus")}
            items={statusItems("currentStatus")}
            setValue={onChange("currentStatus")}
          />
          <div>
            <h6 className="text-heading-6 font-normal text-gray-800">
              <Text>salesManagment.kitchenSales.salesList.topBuyDevices</Text>
            </h6>
          </div>
          <div className="flex items-center">
            <RadioButton
              label="All"
              value={topBuy === null ? true : false}
              setValue={() => {
                setParams((prev: any) => {
                  prev.set("topBuy", "");
                  return prev;
                });
                setTopBuy(null);
              }}
            />
            <RadioButton
              label="0"
              value={topBuy === 0 ? true : false}
              setValue={() => {
                setParams((prev: any) => {
                  prev.set("topBuy", 0);
                  return prev;
                });
                setTopBuy(0);
              }}
            />
            <RadioButton
              label="1"
              value={topBuy === 1 ? true : false}
              setValue={() => {
                setParams((prev: any) => {
                  prev.set("topBuy", 1);
                  return prev;
                });
                setTopBuy(1);
              }}
            />
            <RadioButton
              label="2-3"
              value={topBuy === 23 ? true : false}
              setValue={() => {
                setParams((prev: any) => {
                  prev.set("topBuy", 23);
                  return prev;
                });
                setTopBuy(23);
              }}
            />
            <RadioButton
              label=">3"
              value={topBuy === 4 ? true : false}
              setValue={() => {
                setParams((prev: any) => {
                  prev.set("topBuy", 4);
                  return prev;
                });
                setTopBuy(4);
              }}
            />
          </div>
          <Select
            label="salesManagment.kitchenSales.salesList.advisor"
            placeholder="Select"
            value={params.get("advisorFullName")}
            items={createItems("advisorFullName")}
            setValue={onChange("advisorFullName")}
          />
          <Select
            label="salesManagment.kitchenSales.salesList.store"
            placeholder="Select"
            value={params.get("location")}
            items={createItems("location")}
            setValue={onChange("location")}
          />
          <Toggler
            label={"salesManagment.kitchenSales.salesList.sellFactorWarning"}
            value={params.get("sellFactorHasWarning")}
            setValue={e => {
              if (e) {
                setParams((prev: any) => {
                  prev.set("sellFactorHasWarning", e);
                  return prev;
                });
              } else {
                setParams((prev: any) => {
                  prev.delete("sellFactorHasWarning");
                  return prev;
                });
              }
            }}
          />
          <Toggler
            label={"salesManagment.kitchenSales.salesList.shippingCostWarning"}
            value={params.get("shippingCostWarning")}
            setValue={e => {
              if (e) {
                setParams((prev: any) => {
                  prev.set("shippingCostWarning", e);
                  return prev;
                });
              } else {
                setParams((prev: any) => {
                  prev.delete("shippingCostWarning");
                  return prev;
                });
              }
            }}
          />
          <Toggler
            label={"salesManagment.kitchenSales.salesList.hasContractNumber"}
            value={params.get("hasContractNumber")}
            setValue={e => {
              if (e) {
                setParams((prev: any) => {
                  prev.set("hasContractNumber", e);
                  return prev;
                });
              } else {
                setParams((prev: any) => {
                  prev.delete("hasContractNumber");
                  return prev;
                });
              }
            }}
          />
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
};

export default FilterDrawer;
