import JsonView from "@uiw/react-json-view";
import {
  Drawer,
  Text,
  Button,
  Icon,
  Skeleton,
  Tabs,
  NoItems,
  Status,
} from "components";
import { useToggleState } from "hooks";
import { isArray } from "lodash";
import { useEffect, useState } from "react";
import { ServiceApi, URLS } from "services";
import { convertDate } from "utils";

type DetailsProps = {
  isOpen: boolean;
  toggle: () => void;
  details: any;
};

export default function ChannelDetails({
  isOpen,
  toggle,
  details,
}: DetailsProps) {
  const [dto, setDto] = useState<any>();
  const [loading, setLoading] = useToggleState();
  const [activeTab, setActiveTab] = useState(0);

  const getChannelDetails = () => {
    setLoading();
    const url = URLS.GET_CHANNEL_MESSAGE(details?.id, details?.channelCode);
    ServiceApi.get(url)
      .then(({ data }) => {
        setDto(data);
        setActiveTab(data?.step);
        setLoading();
      })
      .catch(() => setLoading());
  };

  useEffect(() => {
    if (isOpen) {
      getChannelDetails();
    }
  }, [isOpen]);

  const convertStatus = (status: number) => {
    const MessageStatus = [
      {
        name: "status.channelMessage.init",
        id: 0,
      },
      {
        name: "status.channelMessage.sent",
        id: 1,
      },
      {
        name: "status.channelMessage.sendFailed",
        id: 2,
      },
      {
        name: "status.channelMessage.synced",
        id: 3,
      },
      {
        name: "status.channelMessage.syncFailed",
        id: 4,
      },
    ];
    return MessageStatus?.find((msg: any) => msg.id === status)?.id || "";
  };

  const tabs = [
    {
      label: "global.channelMessage.data",
      component: (
        <>
          {dto?.data ? (
            isArray(JSON.parse(dto?.data ?? "null")) ? (
              JSON.parse(dto?.data ?? "null")?.map((json: any) => {
                return (
                  <p className="w-full p-2 rounded bg-gray-100 text-gray-700 whitespace-pre-wrap break-words leading-6">
                    <JsonView value={json} />
                  </p>
                );
              })
            ) : (
              <>
                <p className="w-full p-2 rounded bg-gray-100 text-gray-700 whitespace-pre-wrap break-words leading-6">
                  <JsonView value={JSON.parse(dto?.data)} />
                </p>
              </>
            )
          ) : (
            <NoItems />
          )}
        </>
      ),
    }
  ];

  const tabs2 = [
    {
      label: "productManagement.products.Details.requirementGathering",
    },
    {
      label: "productManagement.products.Details.configurationValidation",
    },
    {
      label: "productManagement.products.Details.dataTransmission",
    },
    {
      label: "productManagement.products.Details.acknowledgeReceipt",
    },
  ];

  const isCompleted = (index: number) => {
    return index < activeTab;
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle} size={"lg"}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pt-6 pr-4">
          <div className="flex items-center justify-between">
            <span className="text-heading-2 font-semibold">
              <Text>global.channelMessage.details</Text>
            </span>
            <div className="flex gap-x-2">
              <Button size="sm" variant={"light"} onClick={toggle}>
                <Icon icon="times" className="mr-1" />
                <Text>global.buttons.close</Text>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-4 pr-4 relative">
          {loading ? (
            [1, 2, 3, 4].map((item: number) => <Skeleton.Input key={item} />)
          ) : (
            <>
              <div className="space-y-4">
                <div className="p-4 border border-gray-400 border-dashed rounded space-y-4 text-gray-800">
                  <div className="grid grid-cols-3 justify-between">
                    <span>
                      <Text>global.channelMessage.channel</Text>
                    </span>
                    <span className="col-span-2">{dto?.channelCode}</span>
                  </div>
                  <div className="grid grid-cols-3 justify-between">
                    <span>
                      <Text>global.channelMessage.createdAt</Text>
                    </span>
                    <span className="col-span-2">
                      {convertDate(dto?.createdAt)}
                    </span>
                  </div>
                  <div className="grid grid-cols-3 justify-between">
                    <span>
                      <Text>global.channelMessage.updatedAt</Text>
                    </span>
                    <span className="col-span-2">
                      {convertDate(dto?.updatedAt)}
                    </span>
                  </div>

                  <div className="col-span-full px-2 space-y-2">
                    <div className="w-full py-4">
                      <div className="relative grid w-full h-24 place-items-center">
                        <div className="w-full px-6 pt-8">
                          <div className="relative flex items-center justify-between w-full">
                            <div className="absolute border-t-2 border-dashed border-primary-light left-0 top-2/4 h-0.5 w-full -translate-y-2/4"></div>
                            <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4"></div>

                            {tabs2.map((e, i) => (
                              <div
                                data-active={activeTab === i}
                                className="group"
                              >
                                <div
                                  className={`relative z-10 grid h-7 w-7 cursor-pointer place-items-center rounded-md ${activeTab > i
                                    ? "bg-primary-light  text-primary"
                                    : " bg-gray-300  text-gray-500"
                                    } group-data-active:bg-primary `}
                                >
                                  <span
                                    className={` flex-center group-data-active:text-white`}
                                  >
                                    {isCompleted(i) ? (
                                      <Icon icon="check" size="1x" />
                                    ) : (
                                      i + 1
                                    )}
                                  </span>
                                  <div className="absolute -top-[3.3rem] w-max text-center text-xs">
                                    <h6
                                      className={`group-data-active:text-primary block w-20 font-sans text-sm antialiased leading-5 text-clip tracking-normal text-inherit font-normal`}
                                    >
                                      <Text>{e.label}</Text>
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-3 items-center justify-between">
                    <span>
                      <Text>global.channelMessage.status</Text>
                    </span>
                    <div className="flex space-x-3 col-span-2 items-center">
                      {<Status.PimChannelStatus id={dto?.status} />}
                      <p className="text-warning text-sm">
                        {dto?.errorCode && (
                          <>
                            <Icon icon="circle-info" className="mr-2" />
                            <Text>
                              {`channelMessage.list.errorCodes.${dto?.errorCode}`}
                            </Text>
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <Tabs activeKey={tabs[0].label}>
                  <Tabs.ButtonGroup>
                    {tabs.map((e) => (
                      <Tabs.Button key={e.label} eventKey={e.label}>
                        <Text>{e.label}</Text>
                      </Tabs.Button>
                    ))}
                  </Tabs.ButtonGroup>
                  {tabs.map((e) => (
                    <Tabs.Item key={e.label} eventKey={e.label}>
                      <div className="space-y-4">
                        <span className="block text-gray-800 ">json:</span>
                        {e.component}
                      </div>
                    </Tabs.Item>
                  ))}
                </Tabs>
              </div>
            </>
          )}
        </Drawer.Body>
        <Drawer.Footer className="flex justify-between">
          <Button size="sm" variant={"light"} onClick={toggle}>
            <Text>global.buttons.close</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
