import {withPermission} from "hoc";
import CloudStorage from "../CloudStorage";
import LocalStorage from "../LocalStorage";

function DataStorage() {
  return (
    <section className="grid grid-cols-1 gap-6 xl:grid-cols-2">
      <CloudStorage />
      <LocalStorage />
    </section>
  );
}
export default withPermission(DataStorage, ["AsS_GetBlobConfig"]);
