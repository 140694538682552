export const stores = {
  storeList: "Store List",
  addStore: "Add store",
  basicInfo: "Basic info",
  basicInfoDesc: "Name, description, ...",
  location: "Location",
  locationDesc: "Store location",
  image: "Image",
  imageDesc: "Image for store",
  review: "Review",
  reviewDesc: "Finalize information",
  general: "General",
  setting: "Setting",
  locationOnMap: "Location on Map",
  openingHours: "Opening Hours",
  addDay: "Add Day",
  generalSetting: "General Setting",
  salesSetting: "Sales Setting",
  storeName: "Store Name",
  storeCode: "Store code",
  code: "Code",
  legalEntity: "LegalEntity",
  shortDescription: "Short Description",
  description: "Description",
  day: "Day",
  active: "Active",
  deActive: "Deactive",
  iln: "ILN (International Location Number)",
  iwofurnSalesCalculationFactor: "iwofurnSalesCalculationFactor",
  iwofurnRoundingMethod: "iwofurnRoundingMethod",
  storeAdded: "Store added successfully.",
  storeUpdated: "Store updated successfully.",
  Departments: "Departments",
  addDepartment: "Add Department",
  details: "Details",
  titel: "Title",
  emailAddress: "Email Address",
  teamLead: "Team Lead",
  assignTeamLead: "Assign Employee as a teamLead",
  selectTEamLead: "Select Team Lead",
  search: "Search",
  searchPlaceHolder: "Search in Name, employee Number ....",
  employees: "Employees",
  editGeneralInfo: "Edit General Info",
  leadInfo: "Lead Info",
  addEmployee: "Add Employee",
  employeeNumber: "Employee Number"
};
