import { Button, Modal, Text } from "components";
import { Dispatch, useContext, useState } from "react";
import toast from "react-hot-toast";
import { ServiceApi, URLS } from "services";
import { PimContext } from "../..";

type Props = {
  id: string | null;
  isOpen: boolean;
  toggle: () => void;
  onRemoved: Dispatch<string>;
};

export default function RemoveConfirm({ id, isOpen, toggle, onRemoved }: Props) {
  const { productData } = useContext(PimContext);
  const [loading, setLoading] = useState(false);
  const remove = () => {
    if (!id) return;
    const url = URLS.UPDATE_PIM_AVAILABILITY_STATUS(productData?.id, id);
    setLoading(true);
    ServiceApi.delete(url)
      .then(() => {
        const message =
          "productManagement.products.Details.availabilityStatus.removeSuccessMessage";
        toast.success(message);
        toggle();
        onRemoved(id);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={loading ? undefined : toggle}
      className="z-40"
    >
      <Modal.Header className="flex flex-row">
        <h6 className="text-heading-2 text-gray-800">
          <Text>
            productManagement.products.Details.availabilityStatus.removeStatusTitle
          </Text>
        </h6>
      </Modal.Header>
      <Modal.Body className="max-h-100 space-y-10 overflow-auto">
        <p className="text-body-base font-normal text-gray-900">
          <Text>
            productManagement.products.Details.availabilityStatus.removeStatusText
          </Text>
        </p>
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-between">
        <Button
          type="button"
          variant={"light"}
          size="sm"
          onClick={loading ? undefined : toggle}
          disabled={loading}
        >
          <Text>
            productManagement.products.Details.availabilityStatus.cancelRemoveButton
          </Text>
        </Button>
        <Button
          type="button"
          variant={"danger"}
          size="sm"
          loading={loading}
          onClick={remove}
        >
          <Text>
            productManagement.products.Details.availabilityStatus.removeStatusButton
          </Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
