type formatType = Intl.DateTimeFormatOptions;
const onlyDate: formatType = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
};
const onlyTime: formatType = {
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
};
const full: formatType = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
};
const fullWithoutSecond: formatType = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
};
const dateFormats = {onlyDate, full, onlyTime, fullWithoutSecond};
export default dateFormats;
