import {
  Button,
  Icon,
  NoItems,
  Skeleton,
  Status,
  Table,
  Text,
  WithPermission,
  Wrapper,
} from "components";
import {usePermission, useToggleState} from "hooks";
import {useContext, useEffect, useState} from "react";
import {ServiceApi, URLS} from "services";
import {UserContext} from "..";
import DetailsDrawer from "./employee/details";
import SearchDrawer from "./employee/search-drawer";

export default function Employee() {
  const {userData, getData} = useContext<any>(UserContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [employeeData, setEmployeeData] = useState<any>();
  const [isOpenSearchDrawer, toggleSearchDrawer] = useToggleState(false);
  const [isOpenDetailsDrawer, toggleDetailsDrawer] = useToggleState(false);

  const getDataEmployee = () => {
    if (userData?.employeeId) {
      setLoading(true);
      const url = URLS.GET_EMPLOYEES_DETAILS_URL(userData?.employeeId);
      ServiceApi.get(url)
        .then(({data}) => {
          let result = JSON.parse(JSON.stringify(data));
          setEmployeeData([result]);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      setEmployeeData([]);
    }
  };

  useEffect(getDataEmployee, [userData?.employeeId]);

  const handelDepartmentsLables = (departments: any) => {
    let label: any = [];
    departments?.forEach((department: any) => {
      label.push(department.name);
    });

    return label.length === 0 ? "" : label?.toString();
  };

  const getDetailsPermission = usePermission("AS_GetEmployee");

  return (
    <div className="space-y-4">
      <Wrapper>
        <Wrapper.Header>
          <div className="flex">
            <h2 className="text-gray-800">
              <Text>configurations.userManagment.userList.assignEmployee</Text>
            </h2>
          </div>
        </Wrapper.Header>
        <Wrapper.Body>
          {loading ? (
            <>
              <Skeleton.List />
              <Skeleton.List />
              <Skeleton.List />
              <Skeleton.List />
            </>
          ) : (
            <>
              {loading ? (
                <>
                  <Skeleton.List />
                  <Skeleton.List />
                  <Skeleton.List />
                  <Skeleton.List />
                </>
              ) : employeeData && Object.keys(employeeData)?.length !== 0 ? (
                <Table>
                  {employeeData?.map((item: any) => {
                    return (
                      <tr key={item.employeeId}>
                        <td>
                          <button
                            type="button"
                            onClick={() => {
                              if (!getDetailsPermission) return;
                              toggleDetailsDrawer();
                            }}
                            className="flex-center inline-flex w-fit gap-2"
                          >
                            <Table.Image
                              placeholder="user"
                              src=""
                              alt={item.employeeId}
                              className="h-[78px] w-[78px]"
                            />
                            <div className="space-y-2">
                              <div>
                                <h6 className="text-heading-6 font-semibold text-gray-800">{`${item?.firstName} ${item?.lastName}`}</h6>
                              </div>
                              <div>
                                <Icon
                                  className="mr-2 font-black text-gray-500"
                                  size="1x"
                                  icon="user-tag"
                                />
                                <span className="text-body-2 font-medium text-gray-500">
                                  {item?.companyName}
                                </span>
                              </div>
                              <div>
                                <Icon
                                  className="mr-2 font-black text-gray-500"
                                  size="1x"
                                  icon="envelope"
                                />
                                <span className="text-body-2 font-medium text-gray-500">
                                  {item?.emailAddress}
                                </span>
                              </div>
                            </div>
                          </button>
                        </td>
                        <td className="space-y-2 text-left">
                          <div>
                            <span className="text-body-base font-medium text-gray-600">
                              {item?.store?.title}
                            </span>
                          </div>
                          {/* <div><span className="text-body-base text-gray-600 font-medium">{handelDepartmentsLables(item?.departments)}</span></div> */}
                          <div>
                            <span className="mr-1 text-body-base font-medium text-gray-800">
                              <Text>company.employees.employeeNumber</Text>:
                            </span>
                            <span className="text-body-base font-medium text-gray-600">
                              {item?.employeeNumber}
                            </span>
                          </div>
                        </td>
                        <td>
                          <Status.EmployeesType id={item?.userType} />
                        </td>
                        <td>
                          <WithPermission permissions={["AS_GetEmployee"]}>
                            <Button
                              type="button"
                              variant="light"
                              size="sm"
                              onClick={() => {
                                toggleDetailsDrawer();
                              }}
                            >
                              <Text>global.buttons.details</Text>
                            </Button>
                          </WithPermission>
                        </td>
                      </tr>
                    );
                  })}
                </Table>
              ) : (
                <div className="flex w-full flex-col items-center space-y-4">
                  <NoItems />
                </div>
              )}
            </>
          )}
        </Wrapper.Body>
      </Wrapper>
      <SearchDrawer
        isOpen={isOpenSearchDrawer}
        toggle={toggleSearchDrawer}
        userId={userData?.userId}
        getData={getData}
        setLoading={setLoading}
      />
      <DetailsDrawer
        isOpen={isOpenDetailsDrawer}
        toggle={toggleDetailsDrawer}
        employeeId={userData?.employeeId}
      />
    </div>
  );
}
