import {Button, Icon, Text} from "components";
import Chooser from "../common/chooser";
import {useToggleState} from "hooks";
import {useContext, useState} from "react";
import {ServiceApi, URLS} from "services";
import {config} from "constant";
import {SimulationContext} from "pages/price-engine/price-simulator";
import Info from "../common/info";

type dataType = {
  name: string;
  code?: string;
  id: string;
};

export default function Brand() {
  const {setSimulateData, simulateData} = useContext(SimulationContext);
  const [isOpen, toggle] = useToggleState();
  const [brands, setBrands] = useState<dataType[]>([
    {
      name: "",
      code: "",
      id: "",
    },
  ]);
  const [loading, setLoading] = useToggleState();
  const [params, setParams] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  });

  const getBrands = () => {
    setLoading();
    const url = URLS.GET_BRANDS_URL;
    const config = {params};
    ServiceApi.get(url, config)
      .then(({data}) => {
        const clone = data?.items?.map((brand: any) => ({
          id: brand.brandId,
          name: brand.name,
          code: brand.code,
        }));
        setBrands(clone);
        setLoading();
      })
      .catch(() => setLoading());
  };

  return (
    <>
      <div className="space-y-4">
        <div>
          <span className="text-body-base text-gray-800">
            <Text>priceEngine.simulator.brand</Text> :
          </span>
        </div>
        {simulateData["brand"] ? (
          <Info dataKey="brand" />
        ) : (
          <>
            <Button light size="sm" onClick={toggle}>
              <Icon icon="plus" className="mr-2" />
              <Text>priceEngine.simulator.addBrand</Text>
            </Button>
          </>
        )}
      </div>
      <Chooser
        isOpen={isOpen}
        toggle={toggle}
        title="priceEngine.simulator.brands"
        data={brands}
        getData={getBrands}
        loading={loading}
        setParams={setParams}
        params={params}
        dataKey="brand"
        setSimulateData={setSimulateData}
      />
    </>
  );
}
