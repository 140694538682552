import {
  AddressForm,
  Button,
  DatePicker,
  Footer,
  InputGroup,
  Select,
  Skeleton,
  Text,
  Wrapper,
} from "components";
import {Fragment, useContext} from "react";
import {Link} from "react-router-dom";
import {UserContext} from "..";
import {rules} from "constant";
import {usePermission} from "hooks";

export default function General() {
  const {
    userData,
    setUserData,
    loading,
    UPDATE_PERMISSIONS,
    isChanged,
    submitLoading,
    addressErrorMsg,
  } = useContext<any>(UserContext);
  const updatePermission = usePermission("AS_UpdateUser");

  const handleSetData = (key: any) => {
    return (value: any) => setUserData((p: any) => ({...p, [key]: value}));
  };

  return (
    <>
      <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
        <Wrapper>
          <Wrapper.Header>
            <h1 className="text-heading-2 font-semibold">
              <Text>configurations.userManagment.userList.basicInfo</Text>
            </h1>
          </Wrapper.Header>
          <Wrapper.Body>
            <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
              {loading ? (
                <Fragment>
                  <Skeleton.Input />
                  <Skeleton.Input />
                  <Skeleton.Input />
                  <Skeleton.Input />
                </Fragment>
              ) : (
                <Fragment>
                  <InputGroup
                    label="configurations.userManagment.userList.firstName"
                    value={userData.firstName}
                    setValue={handleSetData("firstName")}
                    rules={rules.required}
                    disabled={!updatePermission}
                  />
                  <InputGroup
                    label="configurations.userManagment.userList.lastName"
                    value={userData.lastName}
                    setValue={handleSetData("lastName")}
                    rules={rules.required}
                    disabled={!updatePermission}
                  />
                  <Select
                    label="configurations.userManagment.userList.gender"
                    items={[
                      {id: 0, name: "global.gender.rather"},
                      {id: 1, name: "global.gender.male"},
                      {id: 2, name: "global.gender.female"},
                    ]}
                    value={userData.gender}
                    setValue={handleSetData("gender")}
                    disabled={!updatePermission}
                  />
                  <DatePicker
                    label="configurations.userManagment.userList.dateOfBirth"
                    showYearDropdown
                    showMonthDropdown
                    maxDate={new Date()}
                    value={
                      userData?.dateOfBirth !== null
                        ? new Date(userData?.dateOfBirth)
                        : null
                    }
                    onChange={handleSetData("dateOfBirth")}
                    disabled={!updatePermission}
                  />
                </Fragment>
              )}
            </div>
            {/* <div className="grid grid-cols-1 lg:grid-cols-1 mt-8">
            {loading ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="configurations.userManagment.userList.personalNumber"
                value={userData.personalNumber}
                setValue={handleSetData("personalNumber")}
              />
            )}
          </div> */}
            <div className="mt-8 grid grid-cols-1 gap-8 lg:grid-cols-2">
              {loading ? (
                <Fragment>
                  <Skeleton.Input />
                  <Skeleton.Input />
                </Fragment>
              ) : (
                <Fragment>
                  <InputGroup
                    label="configurations.userManagment.userList.phoneNumber"
                    value={userData.phoneNumber}
                    setValue={handleSetData("phoneNumber")}
                    disabled={!updatePermission}
                  />
                  <InputGroup
                    label="configurations.userManagment.userList.mobileNumber"
                    value={userData.mobileNumber}
                    setValue={handleSetData("mobileNumber")}
                    disabled={!updatePermission}
                  />
                </Fragment>
              )}
            </div>
            <div className="mt-8 grid grid-cols-1 lg:grid-cols-1">
              <div className="space-y-8">
                {loading ? (
                  <Skeleton.Input />
                ) : (
                  <InputGroup
                    label="configurations.userManagment.userList.emailAddress"
                    value={userData.emailAddress}
                    setValue={handleSetData("emailAddress")}
                    rules={rules.emailRequired}
                    disabled={!updatePermission}
                  />
                )}
              </div>
            </div>
          </Wrapper.Body>
        </Wrapper>
        <Wrapper>
          <Wrapper.Header>
            <h1 className="text-heading-2 font-semibold">
              <Text>configurations.userManagment.userList.address</Text>
            </h1>
          </Wrapper.Header>
          <Wrapper.Body className="grid grid-cols-1 gap-8 lg:grid-cols-1 lg:gap-8">
            <section className="space-y-8">
              <AddressForm
                data={userData}
                setData={setUserData}
                errorMsg={addressErrorMsg}
              />
            </section>
          </Wrapper.Body>
        </Wrapper>
      </div>
      {UPDATE_PERMISSIONS && (
        <Footer show={!isChanged}>
          <Button as={Link} to={-1} variant="white">
            <Text>global.buttons.discard</Text>
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={!isChanged}
            loading={submitLoading}
          >
            <Text>global.buttons.saveChanges</Text>
          </Button>
        </Footer>
      )}
    </>
  );
}
