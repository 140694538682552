import {Icon, Image, ImageUploader, LazyImage, Text, Wrapper} from "components";
import {useToggleState, usePermission} from "hooks";
import {useContext, useState} from "react";
import {ServiceApi, URLS} from "services";
import {Media as MediaType} from "types";
import {CategoryContext} from "..";
import {assetTypes} from "constant";

const Media = () => {
  const editPermission = usePermission("PS_UpdateProductCategory");

  const {selectedCategory, setRoots, selectedIndex, roots} =
    useContext(CategoryContext);
  const [isOpen, toggle] = useToggleState(false);
  const [isOpenThumbnailUrl, toggleThumbnailUrl] = useToggleState(false);
  const [imageKey, setImageKey] = useState<string>("");

  const onUploadImage = (data: any) => {
    const updatedRoots = roots.map((item: any) => {
      if (item.productCategoryId === selectedCategory.productCategoryId) {
        const url = URLS.EDIT_CATEGORY_TRANSLATES(item.productCategoryId);
        const body = {
          code: item.code,
          pictureThumbnailUrl: item.thumbnailUrl,
          pictureUrl: data.url,
          translates: item.translates,
        };
        ServiceApi.put(url, body).then(() => {});
        return {...item, pictureUrl: data.url};
      } else {
        return item;
      }
    });
    setRoots(updatedRoots);
  };

  const onUploadImageThumbnailUrl = (data: any) => {
    const updatedRoots = roots.map((item: any) => {
      if (item.productCategoryId === selectedCategory.productCategoryId) {
        const url = URLS.EDIT_CATEGORY_TRANSLATES(item.productCategoryId);
        const body = {
          code: item.code,
          pictureThumbnailUrl: data.thumbnailUrl,
          pictureUrl: item.pictureUrl,
          translates: item.translates,
        };
        ServiceApi.put(url, body).then(() => {});
        return {...item, thumbnailUrl: data.thumbnailUrl};
      } else {
        return item;
      }
    });
    setRoots(updatedRoots);
  };

  return (
    <Wrapper>
      <Wrapper.Header className="text-heading-2 font-semibold">
        <Text>productManagement.masterData.productCategory.images</Text>
      </Wrapper.Header>
      <Wrapper.Body className="flex space-x-6">
        {editPermission && (
          <>
            <div className="space-y-4">
              <span className="text-heading-6 font-normal text-gray-800">
                <Text>
                  productManagement.masterData.productCategory.mainImage
                </Text>
              </span>
              <div
                className="flex-center flex h-[220px] w-[176px] cursor-pointer rounded-xl bg-gray-200"
                onClick={() => {
                  toggle();
                }}
              >
                {selectedCategory.pictureUrl ? (
                  <LazyImage
                    isDynamic
                    src={selectedCategory.pictureUrl}
                    alt={""}
                    editor
                    imageClassName="h-full"
                    className="aspect-image h-[220px] w-[176px] cursor-pointer lg:aspect-auto"
                  />
                ) : (
                  <div className="flex flex-col space-y-4">
                    <Icon
                      icon="file-arrow-up"
                      size="3x"
                      className="text-gray-500"
                    />
                    <span className="text-body-base font-medium text-gray-600">
                      <Text>
                        productManagement.masterData.productCategory.uploadImage
                      </Text>
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="space-y-4">
              <span className="text-heading-6 font-normal text-gray-800">
                <Text>
                  productManagement.masterData.productCategory.thumbnail
                </Text>
              </span>
              <div
                className="flex-center flex h-[220px] w-[176px] cursor-pointer rounded-xl bg-gray-200"
                onClick={() => {
                  toggleThumbnailUrl();
                }}
              >
                {selectedCategory.thumbnailUrl ? (
                  <LazyImage
                    isDynamic
                    src={selectedCategory.thumbnailUrl}
                    alt={""}
                    editor
                    imageClassName="h-full"
                    className="aspect-image h-[220px] w-[176px] cursor-pointer lg:aspect-auto"
                  />
                ) : (
                  <div className="flex flex-col space-y-4">
                    <Icon
                      icon="file-arrow-up"
                      size="3x"
                      className="text-gray-500"
                    />
                    <span className="text-body-base font-medium text-gray-600">
                      <Text>
                        productManagement.masterData.productCategory.uploadImage
                      </Text>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </Wrapper.Body>
      <ImageUploader
        isOpen={isOpen}
        toggle={toggle}
        image={selectedCategory.pictureUrl}
        onUpload={(data: MediaType) => onUploadImage(data)}
        type={assetTypes.Company}
      />
      <ImageUploader
        isOpen={isOpenThumbnailUrl}
        toggle={toggleThumbnailUrl}
        image={selectedCategory.thumbnailUrl}
        onUpload={(data: MediaType) => onUploadImageThumbnailUrl(data)}
        type={assetTypes.Company}
      />
    </Wrapper>
  );
};

export default Media;
