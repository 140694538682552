import {InputGroup, Select, Text, Wrapper} from "components";
import {rules} from "constant";
import {useContext, useEffect, useState} from "react";
import {ServiceApi} from "services";
import LEGAL_ENTITY from "services/urls/legal-entity";
import {StoreContext} from ".";
import {usePermission} from "hooks";

export default function General() {
  const updatePermission = usePermission("AS_UpdateStore");
  const {storeData, setStoreData} = useContext(StoreContext);
  const [legalEntities, setLegalEntities] = useState([]);
  const getLegalEntities = () => {
    const url = LEGAL_ENTITY.GET_LEGALENTITY;
    const config = {params: {pageNumber: 1, pageSize: 50}};
    ServiceApi.get(url, config).then(({data}) => {
      const result = data.items.map(e => ({
        name: e.name,
        id: e.id,
      }));
      setLegalEntities(result);
    });
  };
  const handleSetData = key => {
    return value => setStoreData(p => ({...p, [key]: value}));
  };
  useEffect(getLegalEntities, []);
  return (
    <Wrapper>
      <Wrapper.Header>
        <h1 className="text-heading-2 font-semibold">
          <Text>company.stores.basicInfo</Text>
        </h1>
      </Wrapper.Header>
      <Wrapper.Body className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
        <div className="space-y-8">
          <InputGroup
            label="company.stores.storeName"
            value={storeData.title}
            setValue={handleSetData("title")}
            rules={rules.required}
            disabled={!updatePermission}
          />
          <Select
            label="company.stores.legalEntity"
            items={legalEntities}
            clear
            value={storeData.legalEntityId}
            setValue={handleSetData("legalEntityId")}
            disabled={!updatePermission}
            // rules={rules.required}
          />
          <InputGroup
            as="short-textarea"
            label="company.stores.shortDescription"
            value={storeData.shortDescription}
            setValue={handleSetData("shortDescription")}
            disabled={!updatePermission}
            // rules={rules.required}
          />
        </div>
        <div className="space-y-8">
          <InputGroup
            label="company.stores.iln"
            value={storeData.iln}
            setValue={handleSetData("iln")}
            disabled={!updatePermission}
          />
          <InputGroup
            as="textarea"
            label="company.stores.description"
            value={storeData.description}
            setValue={handleSetData("description")}
            disabled={!updatePermission}
            // rules={rules.required}
          />
        </div>
      </Wrapper.Body>
    </Wrapper>
  );
}
