import {useEffect, useState} from "react";
import {useToggleState} from "hooks";
import {ServiceApi, URLS} from "services";
import {config, calculationLevelEnum} from "constant";
import {InfoSection, LeftSide} from "../common";

export default function Supplier() {
  const [loading, setLoading] = useToggleState();
  const [data, setData] = useState<any>([]);
  const [selectedId, setSelectedId] = useState("");
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [params, setParams] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
    suppliertype: 0,
  });

  useEffect(() => {
    getSuppliers();
  }, [params]);

  const getSuppliers = () => {
    setLoading();
    const url = URLS.GET_SUPPLIERS;
    const config = {params};
    ServiceApi.get(url, config)
      .then(({data}) => {
        const clone = data?.items?.map((supplier: any) => ({
          id: supplier.supplierId,
          name: supplier.name,
          code: supplier.code,
        }));
        setData(clone);
        setLoading();
      })
      .catch(() => setLoading());
  };

  return (
    <div className="flex gap-8">
      <LeftSide
        title="priceEngine.calculationLevel.suppliers"
        loading={loading}
        list={data}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
        setParams={setParams}
        setSelectedItem={setSelectedItem}
      />
      <div className="w-full space-y-4">
        {selectedId && (
          <InfoSection
            refId={selectedId}
            level={calculationLevelEnum.supplier}
            refCode={selectedItem?.code}
            refTitle={selectedItem?.name}
          />
        )}
      </div>
    </div>
  );
}
