import {Text, Wrapper} from "components";
import {Fragment, useContext} from "react";
import {convertAddress, convertDate} from "utils";
import {AddUserListContext} from "../index";

export default function Review() {
  const {data, checked} = useContext<any>(AddUserListContext);
  return (
    <Fragment>
      <h2 className="text-heading-2 text-gray-800">
        <Text>configurations.userManagment.userList.review</Text>
      </h2>
      <Wrapper.Section className="grid grid-cols-5 items-start gap-4 text-gray-800 [&>div:nth-child(even)]:col-span-2 [&>div:nth-child(even)]:text-body-base [&>div:nth-child(odd)]:col-span-3 [&>div:nth-child(odd)]:text-heading-6 [&>div:nth-child(odd)]:font-medium">
        <h3 className="col-span-full text-gray-600">
          <Text>configurations.userManagment.userList.general</Text>
        </h3>
        <div>
          <span className="text-body-base text-gray-800">
            <Text>configurations.userManagment.userList.firstName</Text>
          </span>
        </div>
        <div>
          {" "}
          <h6 className="text-heading-6 text-gray-800">{data?.firstName}</h6>
        </div>
        <div>
          <span className="text-body-base text-gray-800">
            <Text>configurations.userManagment.userList.lastName</Text>
          </span>
        </div>
        <div>
          <h6 className="text-heading-6 text-gray-800">{data?.lastName}</h6>
        </div>
        {/* <div>
          <span className="text-body-base text-gray-800">
            <Text>configurations.userManagment.userList.personalNumber</Text>
          </span>
        </div>
        <div className="text-heading-5 text-gray-700">{data?.personalNumber}</div> */}
        <div>
          <span className="text-body-base text-gray-800">
            <Text>configurations.userManagment.userList.emailAddress</Text>
          </span>
        </div>
        <div className="text-heading-5 text-gray-700">{data?.emailAddress}</div>
        <div>
          <span className="text-body-base text-gray-800">
            <Text>configurations.userManagment.userList.gender</Text>
          </span>
        </div>
        <div className="text-heading-5 text-gray-700">{data?.gender}</div>
        <div>
          <span className="text-body-base text-gray-800">
            <Text>configurations.userManagment.userList.dateOfBirth</Text>
          </span>
        </div>
        <div className="text-heading-5 text-gray-700">
          {convertDate(data.dateOfBirth)}
        </div>
        <div>
          <span className="text-body-base text-gray-800">
            <Text>configurations.userManagment.userList.phoneNumber</Text>
          </span>
        </div>
        <div className="text-heading-5 text-gray-700">{data?.phoneNumber}</div>
        <div>
          <span className="text-body-base text-gray-800">
            <Text>configurations.userManagment.userList.mobileNumber</Text>
          </span>
        </div>
        <div className="text-heading-5 text-gray-700">{data?.mobileNumber}</div>
        <div>
          <span className="text-body-base text-gray-800">
            <Text>configurations.userManagment.userList.password</Text>
          </span>
        </div>
        <div className="text-heading-5 text-gray-700">
          {checked ? (
            <Text>configurations.userManagment.userList.hasPassword</Text>
          ) : (
            <Text>configurations.userManagment.userList.hasntPassword</Text>
          )}
        </div>
      </Wrapper.Section>
      <Wrapper.Section className="grid grid-cols-5 items-start gap-4 text-gray-800 [&>div:nth-child(even)]:col-span-2 [&>div:nth-child(even)]:text-body-base [&>div:nth-child(odd)]:col-span-3 [&>div:nth-child(odd)]:text-heading-6 [&>div:nth-child(odd)]:font-medium">
        <h3 className="col-span-full text-gray-600">
          <Text>configurations.userManagment.userList.userGroup</Text>
        </h3>
        <div>
          <span className="text-body-base text-gray-800">
            <Text>configurations.userManagment.userList.userGroup</Text>
          </span>
        </div>
        <div className="text-heading-5 text-gray-700">
          {data?.userGroupName}
        </div>
      </Wrapper.Section>
      <Wrapper.Section className="grid grid-cols-5 items-start gap-4 text-gray-800 [&>div:nth-child(even)]:col-span-2 [&>div:nth-child(even)]:text-body-base [&>div:nth-child(odd)]:col-span-3 [&>div:nth-child(odd)]:text-heading-6 [&>div:nth-child(odd)]:font-medium">
        <h3 className="col-span-full text-gray-600">
          <Text>configurations.userManagment.userList.location</Text>
        </h3>
        <div>
          <Text>global.locations.address</Text>
        </div>
        <div>
          {convertAddress({
            street: data?.address?.street,
            number: data?.address?.number,
            postalCode: data?.address?.postalCode,
            city: data?.address?.city,
            country: data?.address?.country,
            state: data?.address?.state,
          })}
        </div>
      </Wrapper.Section>
    </Fragment>
  );
}
