import {InputGroup} from "components";
import {rules} from "constant";
import {Fragment, useContext} from "react";
import {AddSupplierContext} from "..";
import {Select} from "components";

export default function General() {
  const {data, setData} = useContext<any>(AddSupplierContext);

  const handleSetData = (key: any) => {
    return (value: any) => setData((p: any) => ({...p, [key]: value}));
  };

  const supplierType = [
    {
      name: "TradingGoods",
      id: 0,
    },
    {
      name: "ConsumableGoods",
      id: 1,
    },
  ];

  return (
    <Fragment>
      <InputGroup
        ariaLabel="cy-supplier-name"
        label="productManagement.masterData.suppliers.name"
        value={data?.name}
        setValue={handleSetData("name")}
        rules={rules.required}
      />
      <InputGroup
        ariaLabel="cy-supplier-code"
        label="productManagement.masterData.suppliers.code"
        value={data?.code}
        setValue={handleSetData("code")}
        rules={rules.required}
      />
      <InputGroup
        ariaLabel="cy-supplier-linNumber"
        label="productManagement.masterData.suppliers.ilnNumber"
        value={data?.iln}
        setValue={handleSetData("iln")}
      />
      <InputGroup
        ariaLabel="cy-supplier-textId"
        label="productManagement.masterData.suppliers.taxId"
        value={data?.taxId}
        setValue={handleSetData("taxId")}
      />
      <Select
        label="productManagement.products.Details.type"
        placeholder="Select"
        value={data?.supplierType}
        items={supplierType}
        setValue={handleSetData("supplierType")}
      />
      <InputGroup
        ariaLabel="cy-supplier-description"
        as="textarea"
        label="productManagement.masterData.suppliers.description"
        value={data?.description}
        setValue={handleSetData("description")}
      />
    </Fragment>
  );
}
