import {Button, CheckBox, Icon, Modal, Skeleton, Text} from "components";
import {useContext, useEffect, useState} from "react";
import {ServiceApi, URLS} from "services";
import {checkPriceAvailability, convertDate} from "utils";
import {SellOffProductsContext} from "..";

import {NotificationManager} from "common/notifications";
import {useToggleState} from "hooks";
import {cloneDeep, isEmpty} from "lodash";
import {SalesService} from "types";
import {useSearchParams} from "react-router-dom";
//
type Props = {
  isOpen: boolean;
  toggle: (value?: boolean) => void;
  getData: () => void;
  productId?: string;
};

const ChangePhaseModal = ({isOpen, toggle, getData, productId}: Props) => {
  const [searchParams] = useSearchParams();
  const {item: sellOffProductITem} = useContext(SellOffProductsContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [changePhaseLoading, setChangePhaseLoading] = useToggleState();
  const [changePhaseData, setChangePhaseData] =
    useState<SalesService.ChangePhaseSellOffProductRequirementDto>();

  const channelCode =
    sellOffProductITem?.channelCode || searchParams.get("channelCode");

  const changePhaseCheckList = () => {
    setLoading(true);
    const pid = sellOffProductITem?.id || productId;
    if (!pid) return;
    const url = URLS.CHANGE_PHASE__REQUIREMENT_URL(pid, channelCode);
    ServiceApi.get(url)
      .then(({data}) => {
        setChangePhaseData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onChangeHandler = (listId: string) => {
    setLoading(true);
    const pid = sellOffProductITem?.id || productId;
    const url = URLS.CHANGE_PHASE_CHECKLIST_URL(pid, channelCode);
    const body = {sellPhaseCheckListItemId: listId};
    ServiceApi.patch(url, body)
      .then(({data}) => {
        getData();
        changePhaseCheckList();
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const changePhase = () => {
    setChangePhaseLoading();
    const pid = sellOffProductITem?.id || productId;
    const url = URLS.CHANGE_PHASE_URL(pid, channelCode);
    ServiceApi.post(url)
      .then(() => {
        NotificationManager.success(
          "global.toast.change-phase-msg",
          "global.toast.change-phase-title",
        );
        getData();
        toggle();
      })
      .finally(() => {
        setChangePhaseLoading();
      });
  };

  useEffect(() => {
    if (isOpen) {
      changePhaseCheckList();
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} modalClassName="z-40">
      <Modal.Header className="flex flex-row">
        <h6 className="text-heading-2 text-gray-800">
          <Text>
            salesManagment.sellOffSales.sellOffProduct.changePhaseproccess
          </Text>
        </h6>
      </Modal.Header>
      <Modal.Body className="max-h-100 space-y-10 overflow-auto">
        <>
          <div className="flex flex-col gap-y-2 text-body-2 font-medium text-gray-500">
            <h6 className="text-heading-6">
              {changePhaseData?.sellPhaseTemplate?.title}
            </h6>
            <p>
              <Text>
                salesManagment.sellOffSales.sellOffProduct.articleNumber
              </Text>{" "}
              #{changePhaseData?.pimProduct?.articleNumber}
            </p>
          </div>
          <div className="grid grid-cols-11 gap-2">
            <div className="col-span-5 space-y-4 rounded bg-gray-100 p-4">
              <p>
                <Text>
                  salesManagment.sellOffSales.sellOffProduct.currentPhase
                </Text>
              </p>
              <p className="text-body-2 font-medium text-gray-800">
                {changePhaseData?.currentPhaseName}
              </p>
              <p className="text-danger">%{changePhaseData?.currentDiscount}</p>
              <p>{checkPriceAvailability(changePhaseData?.currentPrice)}</p>
            </div>
            <div className="flex-center flex">
              <Icon icon="arrow-right" />
            </div>
            <div className="col-span-5 space-y-4 rounded bg-gray-100 p-4">
              <p>
                <Text>
                  salesManagment.sellOffSales.sellOffProduct.nextPhase
                </Text>
              </p>
              <p className="text-body-2 font-medium text-gray-800">
                {changePhaseData?.nextPhaseName}
              </p>
              <p className="text-body-2 font-medium text-gray-800">
                0/{changePhaseData?.nextPhaseTotalDays}{" "}
                <Text>salesManagment.sellOffSales.sellOffProduct.days</Text>
              </p>
              <div className="space-y-2 text-body-2">
                <p className="text-gray-500 line-through">
                  {checkPriceAvailability(
                    changePhaseData?.pimProduct?.vk1Price?.amount,
                  )}
                </p>
                <p className="text-danger">%{changePhaseData?.nextDiscount}</p>
                <p className="text-heading-6 font-semibold text-primary-active">
                  {checkPriceAvailability(changePhaseData?.nextPrice)}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-2 text-body-2 font-medium text-gray-500">
            <h6 className="text-heading-6">
              <Text>salesManagment.sellOffSales.sellOffProduct.checkList</Text>
            </h6>
            <p>{changePhaseData?.sellPhaseTemplate?.description}</p>
          </div>
          {loading && isEmpty(changePhaseData) && (
            <>
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </>
          )}
          <div className="grid gap-4">
            {changePhaseData?.sellPhaseCheckListItems?.map(
              (
                list: SalesService.SellOffProductCheckListItem,
                index: number,
              ) => {
                return (
                  <>
                    {loading ? (
                      <Skeleton.Input />
                    ) : (
                      <div
                        className="flex items-center space-x-4 rounded bg-primary-light p-6"
                        key={list.label}
                      >
                        <div>
                          <CheckBox
                            setValue={checked => {
                              let clone = cloneDeep(
                                changePhaseData.sellPhaseCheckListItems,
                              );
                              if (clone) {
                                clone[index].isChecked = checked;
                                onChangeHandler(list.checkListItemId as string);
                              }
                            }}
                            value={list.isChecked as boolean}
                            className="bg-white"
                          />
                        </div>
                        <div className="flex flex-col space-y-2">
                          <span className="text-heading-5 font-normal text-gray-700">
                            {list.label}
                          </span>
                          <p className="text-body-2">
                            {list.updatedBy} {convertDate(list.updatedAt)}
                          </p>
                        </div>
                        {/* <Icon icon="info-circle" className="ml-auto" /> */}
                      </div>
                    )}
                  </>
                );
              },
            )}
          </div>
        </>
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-between">
        <Button
          className="mr-2"
          onClick={toggle}
          variant="white"
          disabled={loading}
        >
          <Text>salesManagment.sellOffSales.sellOffProduct.close</Text>
        </Button>
        <Button
          type="button"
          onClick={changePhase}
          loading={changePhaseLoading}
          // disabled={
          //   !changePhaseData?.clonedSellPhaseCheckListItems ||
          //   changePhaseData?.clonedSellPhaseCheckListItems
          //     ?.map((item: any) => item.isChecked)
          //     .includes(false)
          // }
        >
          <Text>salesManagment.sellOffSales.sellOffProduct.changePhase</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ChangePhaseModal;
