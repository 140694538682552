import {Channels} from "components";
import {useContext} from "react";
import {ProgramContext} from "../details";
import {URLS} from "services";

export default function General() {
  const {data, loading} = useContext(ProgramContext);

  return (
    <Channels
      key={`channel-${data?.id}`}
      id={data?.id}
      loading={loading}
      publishUrl={URLS.PUBLISH_PROGRAM_TO_CHANNEL}
      unpublishUrl={URLS.UNPUBLISH_PROGRAM_FROM_CHANNEL}
    />
  );
}
