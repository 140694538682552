import {AvailabilityStatus, PrintRequestStatus} from "enum";

const store = [
  {
    name: "status.store.active",
    id: true,
    variant: "success",
  },
  {
    name: "status.store.notActive",
    id: false,
    variant: "danger",
  },
];
const supplier = [
  {
    name: "status.store.active",
    id: true,
    variant: "success",
  },
  {
    name: "status.store.notActive",
    id: false,
    variant: "gray",
  },
];
const required = [
  {
    name: "global.required",
    id: true,
    variant: "success",
  },
  {
    name: "global.notRequired",
    id: false,
    variant: "danger",
  },
];
const employeeSatus = [
  {
    name: "status.employee.active",
    id: true,
    variant: "success",
  },
  {
    name: "status.store.notActive",
    id: false,
    variant: "gray",
  },
];
const sellOff = [
  {
    name: "salesManagment.sellOffSales.sellOffProduct.preparation",
    variant: "primary",
  },
  {
    name: "salesManagment.sellOffSales.sellOffProduct.active",
    variant: "success",
  },
  {
    name: "salesManagment.sellOffSales.sellOffProduct.sold",
    variant: "info",
  },
  {
    name: "salesManagment.sellOffSales.sellOffProduct.needPhaseChange",
    variant: "warning",
  },
  {
    name: "salesManagment.sellOffSales.sellOffProduct.needClose",
    variant: "danger",
  },
  {
    name: "salesManagment.sellOffSales.sellOffProduct.closed",
    variant: "gray",
  },
  {
    name: "salesManagment.sellOffSales.sellOffProduct.notSold",
    variant: "gray",
  },
];
const requiredAttribute = [
  {
    name: "global.required",
    id: true,
    variant: "success",
  },
];
const builtInAttribute = [
  {
    name: "global.builtIn",
    id: true,
    variant: "success",
  },
];
const perChannelAttribute = [
  {
    name: "productManagement.masterData.attributes.perChannel",
    id: true,
    variant: "info",
  },
];
const perLanguageAttribute = [
  {
    name: "productManagement.masterData.attributes.perLanguage",
    id: true,
    variant: "primary",
  },
];
const defaultLabel = [
  {
    name: "global.default",
    id: true,
    variant: "success",
  },
];
const standard = [
  {
    name: "global.standard",
    id: true,
    variant: "success",
  },
];

const productFamilies = [
  {
    name: "productManagement.masterData.productFamilies.perLanguage",
    variant: "primary",
  },
  {
    name: "productManagement.masterData.productFamilies.required",
    variant: "success",
  },
  {
    name: "productManagement.masterData.productFamilies.perChannel",
    variant: "info",
  },
  {
    name: "productManagement.masterData.productFamilies.inherit",
    variant: "gray",
  },
];
const roles = [
  {
    name: "global.none",
    id: 0,
    variant: "success",
  },
  {
    name: "global.user",
    id: 8,
    variant: "success",
  },
  {
    name: "global.admin",
    id: 4,
    variant: "warning",
  },
];

const productActive = [
  {
    name: "status.store.active",
    id: true,
    variant: "success",
  },
  {
    name: "status.store.notActive",
    id: false,
    variant: "gray",
  },
];
const salesListStatus = [
  {
    name: "",
    id: 1,
    variant: "",
    icon: "",
  },
  {
    name: "",
    id: 2,
    variant: "danger",
    icon: "triangle-exclamation",
  },
  {
    name: "status.salesList.pendingApproval",
    id: 3,
    variant: "warning",
    icon: "clock-five",
  },
  {
    name: "status.salesList.approved",
    id: 4,
    variant: "success",
    icon: "check",
  },
];

const sellFactorHasWarning = [
  {
    name: "status.salesList.sellFactorHasWarning",
    id: true,
    variant: "danger",
  },
  {
    name: "",
    id: false,
    variant: "",
  },
];

const shippingCostHasWarning = [
  {
    name: "status.salesList.shippingCostHasWarning",
    id: true,
    variant: "danger",
  },
  {
    name: "",
    id: false,
    variant: "",
  },
];

const pimChannelStatus = [
  {
    name: "status.channelMessage.all",
    id: "",
    variant: "success",
  },
  {
    name: "productManagement.products.Details.init",
    variant: "success",
    id: 0,
  },
  {
    name: "productManagement.products.Details.sent",
    variant: "success",
    id: 1,
  },
  {
    name: "productManagement.products.Details.sendFailed",
    variant: "danger",
    id: 2,
  },
  {
    name: "productManagement.products.Details.synced",
    variant: "success",
    id: 3,
  },
  {
    name: "productManagement.products.Details.syncFailed",
    variant: "danger",
    id: 4,
  },
];

const active = [
  {
    name: "status.store.active",
    id: true,
    variant: "success",
  },
  {
    name: "status.store.notActive",
    id: false,
    variant: "danger",
  },
];
const channelMessage = [
  {
    name: "status.channelMessage.all",
    id: "",
    variant: "success",
  },
  {
    name: "status.channelMessage.published",
    id: 0,
    variant: "success",
  },
  {
    name: "status.channelMessage.processed",
    id: 1,
    variant: "warning",
  },
  {
    name: "status.channelMessage.synced",
    id: 2,
    variant: "success",
  },
  {
    name: "status.channelMessage.failed",
    id: 3,
    variant: "danger",
  },
];
const priceTemplates = [
  {
    name: "status.priceTemplates.active",
    active: true,
    variant: "success",
  },
  {
    name: "status.priceTemplates.notActive",
    active: false,
    variant: "gray",
  },
];
const generalDiscount = [
  {
    name: "status.generalDiscount.active",
    active: true,
    variant: "success",
  },
  {
    name: "status.generalDiscount.notActive",
    active: false,
    variant: "gray",
  },
];
const templateType = [
  {
    name: "status.templateType.salesOfferContract",
    id: 0,
    variant: "warning",
  },
  {
    name: "status.templateType.salesOrderContract",
    id: 1,
    variant: "primary",
  },
  {
    name: "status.templateType.customerLegalContract",
    id: 2,
    variant: "info",
  },
  {
    name: "status.templateType.TermsAndConditions",
    id: 3,
    variant: "success",
  },
];

const calculationUnits = [
  {
    name: "status.calculationUnits.active",
    active: true,
    variant: "success",
  },
  {
    name: "status.calculationUnits.notActive",
    active: false,
    variant: "danger",
  },
];
const purchasePrice = [
  {
    name: "status.purchasePrice.active",
    active: true,
    variant: "success",
  },
  {
    name: "status.purchasePrice.notActive",
    active: false,
    variant: "danger",
  },
];
const supplierType = [
  {
    name: "status.supplier.TradingGoods",
    id: 0,
    variant: "info",
  },
  {
    name: "status.supplier.ConsumableGoods",
    id: 1,
    variant: "warning",
  },
];
const availability = [
  {
    name: "status.availability.active",
    id: AvailabilityStatus.Active,
    variant: "success",
  },
  {
    name: "status.availability.inactive",
    id: AvailabilityStatus.Inactive,
    variant: "danger",
  },
  {
    name: "status.availability.salesOnly",
    id: AvailabilityStatus.SalesOnly,
    variant: "info",
  },
  {
    name: "status.availability.blocked",
    id: AvailabilityStatus.Blocked,
    variant: "danger",
  },
];
const pimProductType = [
  {
    name: "single",
    id: 0,
    variant: "primary",
  },
  {
    name: "bundle",
    id: 1,
    variant: "info",
  },
];
const productLabelActivation = [
  {
    name: "status.productLabelActivation.active",
    id: true,
    variant: "success",
  },
  {
    name: "status.productLabelActivation.notActive",
    id: false,
    variant: "danger",
  },
];

const printRequestStatus = [
  {
    name: "status.printRequest.completed",
    id: PrintRequestStatus.Completed,
    variant: "success",
  },
  {
    name: "status.printRequest.onProcess",
    id: PrintRequestStatus.OnProcess,
    variant: "primary",
  },
  {
    name: "status.printRequest.failed",
    id: PrintRequestStatus.Faild,
    variant: "danger",
  },
];

const pimCheckList = [
  {
    name: "status.pimCheckList.active",
    active: true,
    variant: "success",
  },
  {
    name: "status.pimCheckList.notActive",
    active: false,
    variant: "warning",
  },
];

const checkListTtypeStatus = [
  {
    name: "status.checkList.pim",
    id: 0,
    variant: "primary",
  },
  {
    name: "status.checkList.supplier",
    id: 1,
    variant: "warning",
  },
  {
    name: "status.checkList.supplierProgram",
    id: 2,
    variant: "danger",
  },
  {
    name: "status.checkList.order",
    id: 3,
    variant: "info",
  },
];

const status = {
  priceTemplates,
  store,
  required,
  sellOff,
  supplier,
  requiredAttribute,
  perChannelAttribute,
  perLanguageAttribute,
  defaultLabel,
  standard,
  productFamilies,
  roles,
  productActive,
  salesListStatus,
  sellFactorHasWarning,
  shippingCostHasWarning,
  active,
  pimChannelStatus,
  channelMessage,
  generalDiscount,
  builtInAttribute,
  templateType,
  calculationUnits,
  purchasePrice,
  supplierType,
  employeeSatus,
  availability,
  pimProductType,
  productLabelActivation,
  printRequestStatus,
  pimCheckList,
  checkListTtypeStatus,
};
export default status;
