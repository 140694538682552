export const completeness = {
  addCheckList: "Add check list",
  checkListDetails: "Check list Details",
  title: "Title",
  shortDescription: "Short Description",
  active: "Active",
  type: "Type",
  subject: "Subject",
  description: "Description",
  descriptionPlaceHolder: "Add something about this...",
  by: "By",
  assignToItem: "Assign to item",
  assignedCheckListItem: "Assigned checklist item",
  attachements: "Attachments",
  replies: "Replies",
  replyTo: "Reply to:",
  hideReply: "Hide reply",
  hideReplies: "Hide Replies",
  checkListItems: "Checklist Items: ",
  newest: "Newest",
  oldest: "Oldest",
  person: "Person",
  checkListItem: "Checklist item",
  sortBy: "Sort by",
  repliesTo: "Replies to",
  closeReplies: "Close replies",
  completed: "Completed",
  inprocess: "In process",
};
