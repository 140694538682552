import {
  Breadcrumb,
  Button,
  Icon,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Status,
  Table,
  Text,
  WithPermission,
} from "components";
import {config} from "constant";
import {withPermission} from "hoc";
import {usePermission, useToggleState} from "hooks";
import {useEffect, useState} from "react";
import {Link, useSearchParams} from "react-router-dom";
import {ServiceApi, URLS} from "services";
import InviteSearchModal from "./invite/invite-search-modal";

type UserListItems = {
  userId: string;
  code: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  userGroupName: string;
  userType: number;
  avatar?: AvatarItems;
};

type AvatarItems = {
  id: string;
  thumbnailUrl?: string;
  type: string;
  url: string;
};

type ListType = {
  items?: UserListItems[];
  pageNumber?: number;
  pageSize?: number;
  totalItems?: number;
  totalPages?: number;
};

function UserList() {
  const controller = new AbortController();
  const [data, setData] = useState<ListType>();
  const [loadingList, setLoadingList] = useState(true);
  const [isOpen, toggle] = useToggleState(false);
  const [searchParams] = useSearchParams();
  //original filter data for reset
  const originalFilterData: any = {
    pageNumber: 1,
    userGroupId: "",
    pageSize: config.pageSize,
    keyword: "",
  };

  const [params, setParams] = useSearchParams();

  const handleSetParams = (key: string) => {
    return (value: any) => {
      setParams(prev => {
        value !== null && value !== ""
          ? key === "pageNumber" && value === 1
            ? prev.delete(key)
            : prev.set(key, value)
          : prev.delete(key);
        key !== "pageNumber" && prev.delete("pageNumber");
        return prev;
      });
    };
  };

  const getData = () => {
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url = URLS.GET_B2B_USERS_URL;
    const config = {params};
    setLoadingList(true);
    ServiceApi.get(url, {...config, signal: controller.signal})
      .then(res => {
        setData(res?.data);
      })
      .finally(() => {
        setLoadingList(false);
      });
  };
  useEffect(() => {
    getData();
    return () => {
      controller.abort();
    };
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDetailPermission = usePermission("AS_GetUser");

  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
          <WithPermission permissions={["AS_InviteUserIdP"]}>
            {loadingList ? (
              <Skeleton.Button />
            ) : (
              <Button
                type="button"
                variant="white"
                textColor="primary"
                onClick={() => toggle()}
              >
                <Icon className="mr-2" icon={"plus"} />
                <Text>configurations.userManagment.userList.inviteUser</Text>
              </Button>
            )}
          </WithPermission>
          <WithPermission permissions={["AS_CreateUser"]}>
            {loadingList ? (
              <Skeleton.Button />
            ) : (
              <Button type="button" as={Link} to="new">
                <Icon className="mr-2" icon={"plus"} />
                <Text>configurations.userManagment.userList.addUser</Text>
              </Button>
            )}
          </WithPermission>
        </div>
      </div>
      <SearchBox
        value={params?.get("keyword") || ""}
        onSubmit={handleSetParams("keyword")}
        disabled={loadingList}
        totalItems={data?.totalItems}
        onReload={getData}
        loading={loadingList}
      />
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data?.items?.length !== 0 ? (
            <>
              <Table>
                {data?.items?.map(item => {
                  return (
                    <tr key={item.userId}>
                      <td>
                        <Link
                          to={getDetailPermission ? item.userId : ""}
                          className="flex-center flex w-fit gap-2"
                          replace={!getDetailPermission}
                        >
                          <Table.Image
                            placeholder="user"
                            src={item?.avatar?.url}
                            alt={item.firstName}
                            className="h-[78px] w-[78px]"
                          />
                          <div className="space-y-2">
                            <div>
                              <h6 className="text-heading-6 font-semibold text-gray-800">{`${item.firstName} ${item.lastName}`}</h6>
                            </div>
                            <div className="flex items-center">
                              <Icon
                                className="mr-2 font-black text-gray-500 "
                                icon="envelope"
                                size="1x"
                              />
                              <span className="text-body-2 font-medium text-gray-500">
                                {item.emailAddress}
                              </span>
                            </div>
                            {/* {item.personalNumber && (
                              <div>
                                <Icon
                                  className="text-gray-500 font-black mr-2"
                                  icon="hashtag"
                                />
                                <span className="text-body-2 text-gray-500 font-medium">
                                  {item.personalNumber}
                                </span>
                              </div>
                            )} */}
                          </div>
                        </Link>
                      </td>
                      <td>
                        <div className="text-left text-body-base  font-normal">
                          <span className="text-gray-800">
                            <Text>
                              configurations.userManagment.userList.group
                            </Text>
                          </span>
                          :
                          <span className="ml-1 text-gray-600">
                            {item.userGroupName}
                          </span>
                        </div>
                      </td>
                      <td>
                        <Status.roles id={item.userType} />
                      </td>
                      <td>
                        <WithPermission permissions={["AS_GetUser"]}>
                          <Button
                            as={Link}
                            to={item.userId}
                            variant="light"
                            size="sm"
                          >
                            <Text>global.buttons.details</Text>
                          </Button>
                        </WithPermission>
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages}
                pageNumber={
                  params?.get("pageNumber")
                    ? parseInt(params?.get("pageNumber") as string)
                    : 1
                }
                pageSize={
                  params?.get("pageSize")
                    ? parseInt(params?.get("pageSize") as string)
                    : config.pageSize
                }
                setActivePage={handleSetParams("pageNumber")}
                onPageSizeChange={(e: any) =>
                  setParams(p => ({...p, pageSize: e}))
                }
              />
              <InviteSearchModal isOpen={isOpen} toggle={toggle} />
            </>
          ) : (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
            </div>
          )}
        </>
      )}
    </div>
  );
}
export default withPermission(UserList, ["AS_GetUser"]);
