const STORE = {
  ADD_STORE_URL: "accountservice/api/stores",
  GET_STORE_URL: id => `accountservice/api/stores/${id}`,
  UPDATE_STORE_URL: id => `accountservice/api/stores/${id}`,
  GET_DELETE_EDIT_STORE_DETAIL_URL: "accountservice/api/stores/",
  SEARCH_STORE_LOCATION: (
    query,
    countrySet,
    language = "",
    radius = "",
    limit = 1,
    lat = "",
    lon = "",
  ) =>
    `addressservice/api/address/search?query=${query}&countrySet=${countrySet}&language=${language}&radius=${radius}&limit=${limit}&lat=${lat}&lon=${lon}`,
  GET_DEPARTMENTS_URL: `accountservice/api/departments`,
  POST_DEPARTMENTS_URL: `accountservice/api/departments`,
  GET_DEPARTMENT_DETAILS_URL: storeId =>
    `accountservice/api/departments/${storeId}`,
  REMOVE_EMPLOYEE_FROM_DEPARTMENT: (id, employeeId) =>
    `accountservice/api/departments/${id}/employee/${employeeId}`,
  ADD_EMPLOYEE_TO_DEPARTMENT: id =>
    `accountservice/api/departments/${id}/employee`,
  REMOVE_DEPARTMENT: id => `accountservice/api/departments/${id}`,
  GET_EMPLOYEE_DETAILS: id => `accountservice/api/employees/${id}`,
  PUT_DEPARTMENT_DETAILS: id => `accountservice/api/departments/${id}`,
};

export default STORE;
