import { useEffect, useState, useRef, useId } from "react";
import Text from "./Text";
import AsyncSelect from "react-select/async";
import { config } from "constant";
import { rulesType } from "../types";
import { CountrySelectOption } from "types/country";
import { useTranslate } from "hooks";

type selectSearchableProps = {
  label?: string;
  value: string | CountrySelectOption;
  isClearable?: boolean;
  cacheOptions?: boolean;
  defaultOptions?: boolean;
  rules?: rulesType;
  loadOptions: (val: string) => void;
  onChange: (val: any) => void;
};

export default function SelectSearchable({
  label,
  value,
  loadOptions,
  onChange,
  defaultOptions,
  isClearable,
  cacheOptions,
  rules = [],
}: selectSearchableProps) {
  const selectSearchableRef = useRef<HTMLDivElement>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const translate = useTranslate();
  const selectId = useId();
  useEffect(() => {
    selectSearchableRef.current?.classList.toggle("required", !!rules.length);
  }, [rules]);
  useEffect(() => {
    selectSearchableRef.current?.classList.toggle("error", !!errorMessage);
  }, [errorMessage]);
  useEffect(() => {
    const formControl =
      selectSearchableRef.current?.querySelector(".form-control");
    // @ts-ignore: Unreachable code error
    formControl.onValid = () => {
      return rules.every(rule => {
        const ruleValue = rule(value);
        const isValid = ruleValue === true;
        setErrorMessage(isValid ? "" : ruleValue);
        return isValid;
      });
    };
  }, [rules, value]);

  return (
    <div
      ref={selectSearchableRef}
      className="select-group group w-full space-y-4"
    >
      {label && <label
        htmlFor={selectId}
        data-lang-map={label}
        className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.select-group.required]:after:content-['*']"
      >
        <Text>{label}</Text>
      </label>}
      <div className="rounded-md group-[.select-group.error]:border group-[.select-group.error]:border-danger">
        <AsyncSelect
          value={value}
          className="form-control"
          cacheOptions={cacheOptions}
          loadOptions={loadOptions}
          defaultOptions={defaultOptions}
          styles={config.styleSelectReact}
          onChange={onChange}
          isClearable={isClearable}
          placeholder={`${translate('global.select')}...`}
        />
      </div>
      {!!rules.length && !label && (
        <i className="bi bi-asterisk input-group-text text-secondary text-xs" />
      )}
      {errorMessage && (
        <p className="mt-1 text-xs text-danger">
          <i className="bi bi-info-circle mr-1" />
          <Text>{errorMessage}</Text>
        </p>
      )}
    </div>
  );
}
