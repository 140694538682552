import {Text, Wrapper} from "components";
import {createElement, useContext, useState} from "react";
import {PimContext} from "..";
import Price from "./priceTab/price";
import PriceTemplates from "./priceTab/priceTemplates";
import PriceCalculation from "./priceCalculation";
import SalesPersonCommission from "./salesPersonCommission";

const PriceTab = () => {
  const {productData} = useContext(PimContext);
  const [selectedTab, dispatchTab] = useState<any>({
    label: "productManagement.products.Details.prices",
    component: Price,
  });

  const tabs = [
    {
      label: "productManagement.products.Details.prices",
      component: Price,
    },
    {
      label: "productManagement.products.Details.priceTemplates",
      component: PriceTemplates,
    },
    {
      label: "priceEngine.calculationLevel.priceCalculationUnits",
      component: PriceCalculation,
    },
    ...(productData?.pimProductType == 0
      ? [
          {
            label: "productManagement.products.Details.salesPersonCommission",
            component: SalesPersonCommission,
          },
        ]
      : []),
  ];

  return (
    <div className="grid grid-cols-1 gap-4  xl:grid-cols-4">
      <Wrapper className="col-span-3 flex flex-row  gap-2 p-4 xl:col-span-1 xl:h-[300px] xl:flex-col  xl:space-y-4">
        {tabs.map((tab: any) => (
          <span
            className={`flex cursor-pointer items-center  rounded-2xl p-3 text-body-base font-medium xl:h-12  ${selectedTab?.label === tab.label ? "bg-primary-light text-primary" : "bg-gray-100 text-gray-600"}`}
            onClick={() => dispatchTab(tab)}
          >
            <Text>{tab.label}</Text>
          </span>
        ))}
      </Wrapper>
      <div className="col-span-3 space-y-4 xl:col-span-3">
        {selectedTab && createElement(selectedTab.component)}
      </div>
    </div>
  );
};

export default PriceTab;
