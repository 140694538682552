import {
  Breadcrumb,
  Button,
  Icon,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Table,
  Text,
  WithPermission,
} from "components";
import {config} from "constant";
import {withPermission} from "hoc";
import {usePermission, useToggleState} from "hooks";
import {useEffect, useState} from "react";
import {Link, useSearchParams} from "react-router-dom";
import {ServiceApi, URLS} from "services";
import AddDrawer from "./add-drawer";
import LEGAL_ENTITY from "services/urls/legal-entity";

type ChannelItem = {
  saleChannelId: string;
  code: string;
  title: string;
  description: string;
  ilnNumber: string;
  businessChannels: {
    crmChannel: boolean;
    erpChannel: boolean;
    onlineShopChannel: boolean;
  };
  legalEntity: {
    legalEntityId: string;
    code: string;
  };
};

type ListType = {
  items?: ChannelItem[];
  pageNumber?: number;
  pageSize?: number;
  totalItems?: number;
  totalPages?: number;
};

type LegalEntity = {
  id: string;
  code: string;
  name: string;
};

function SalesChannels() {
  const controller = new AbortController();
  const [data, setData] = useState<ListType>();
  const [loadingList, setLoadingList] = useState(true);
  const [isOpenDrawerCreate, toggleDrawerCreate] = useToggleState(false);
  const [searchParams] = useSearchParams();
  const [legalEntities, setLegalEntities] = useState<LegalEntity[]>([]);

  //original filter data for reset
  const originalFilterData: any = {
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  };

  const [params, setParams] = useSearchParams();

  const handleSetParams = (key: string) => {
    return (value: any) => {
      if (key === "keyword" && value === params?.get("keyword")) {
        getData();
        getLegalEntities();
      }
      setParams(prev => {
        value !== null && value !== ""
          ? key === "pageNumber" && value === 1
            ? prev.delete(key)
            : prev.set(key, value)
          : prev.delete(key);
        key !== "pageNumber" && prev.delete("pageNumber");
        return prev;
      });
    };
  };

  const getData = () => {
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url = URLS.SALES_CHANNEL;
    const config = {params};
    setLoadingList(true);
    ServiceApi.get(url, {...config, signal: controller.signal})
      .then(({data}) => {
        setData(data);
      })
      .catch(() => {
        setData({});
      })
      .finally(() => {
        setLoadingList(false);
      });
  };

  const getLegalEntities = () => {
    const url = LEGAL_ENTITY.GET_LEGALENTITY;
    const config = {params: {pageNumber: 1, pageSize: 50}};
    ServiceApi.get(url, config).then(({data}) => {
      const result = data.items.map((e: any) => ({
        name: e.name,
        id: e.id,
        code: e.code,
      }));
      setLegalEntities(result);
    });
  };

  const getLegalEntityTitle = (id: string) => {
    return legalEntities.filter(item => item.id === id)?.[0]?.name;
  };

  useEffect(() => {
    getData();
    getLegalEntities();
    return () => {
      controller.abort();
    };
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDetailsPermission = usePermission("SC_GetSaleChannel");

  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
        <WithPermission permissions={["SC_CreateSaleChannel"]}>
          {loadingList ? (
            <Skeleton.Button />
          ) : (
            <Button type="button" onClick={toggleDrawerCreate}>
              <Icon className="mr-2" icon={"plus"} />
              <Text>salesManagment.salesChannels.addSalesChannel</Text>
            </Button>
          )}
        </WithPermission>
      </div>
      <SearchBox
        value={params?.get("keyword") || ""}
        onSubmit={handleSetParams("keyword")}
        disabled={loadingList}
        totalItems={data?.totalItems}
        onReload={getData}
        loading={loadingList}
      />
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data &&
          Object.keys(data).length !== 0 &&
          data?.items?.length !== 0 ? (
            <>
              <Table>
                {data?.items?.map(item => {
                  return (
                    <tr key={item.saleChannelId}>
                      <td className="w-1/3">
                        <Link
                          to={getDetailsPermission ? item.saleChannelId : ""}
                          className="inline-flex w-fit gap-2"
                          replace={!getDetailsPermission}
                        >
                          <div className="space-y-2">
                            <h6 className="text-heading-6 font-semibold text-gray-800">
                              {item.title}
                            </h6>
                            <p className="text-body-2 font-normal text-gray-500">
                              <Text>salesManagment.salesChannels.code</Text>:{" "}
                              {item.code}
                            </p>
                            <p className="text-body-2 font-normal text-gray-500">
                              <Text>ILN</Text>:{" "}
                              {item.ilnNumber ? item.ilnNumber : "---"}
                            </p>
                          </div>
                        </Link>
                      </td>
                      <td className="space-y-2 text-left text-body-base font-normal text-gray-800">
                        <p>
                          <Text>salesManagment.salesChannels.legalEntity</Text>:
                        </p>
                        <h6 className="text-heading-6 font-semibold text-gray-800">
                          {getLegalEntityTitle(item.legalEntity.code)}
                        </h6>
                        <p>
                          <span>{item.legalEntity.code}</span>
                        </p>
                      </td>
                      <td>
                        <WithPermission permissions={["SC_GetSaleChannel"]}>
                          <Button
                            as={Link}
                            to={item.saleChannelId}
                            variant="light"
                            size="sm"
                          >
                            <Text>global.buttons.details</Text>
                          </Button>
                        </WithPermission>
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages}
                pageNumber={
                  params?.get("pageNumber")
                    ? parseInt(params?.get("pageNumber") as string)
                    : 1
                }
                pageSize={
                  params?.get("pageSize")
                    ? parseInt(params?.get("pageSize") as string)
                    : config.pageSize
                }
                setActivePage={handleSetParams("pageNumber")}
                onPageSizeChange={(e: any) =>
                  setParams(p => ({...p, pageSize: e}))
                }
              />
            </>
          ) : (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
            </div>
          )}
        </>
      )}
      <AddDrawer
        isOpen={isOpenDrawerCreate}
        toggle={toggleDrawerCreate}
        getData={getData}
        legalEntities={legalEntities}
      />
    </div>
  );
}
export default withPermission(SalesChannels, ["SC_GetSaleChannel"]);
