import {attributes} from "./attributes";
import {attributesGroups} from "./attributes-groups";
import {suppliers} from "./suppliers";
import {brand} from "./brand";
import {measurementUnits} from "./measurement-units";
import {productFamilies} from "./product-families";
import {productCategory} from "./product-category";
import {productGroup} from "./product-group";
import {shipping} from "./shipping";
import {productLabelTemplate} from "./product-label-template";

export const masterData = {
  attributes: attributes,
  attributesGroups: attributesGroups,
  suppliers: suppliers,
  brand: brand,
  measurementUnits: measurementUnits,
  productFamilies,
  productCategory,
  productGroup,
  shipping,
  productLabelTemplate,
};
