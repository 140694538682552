import {NotificationManager} from "common/notifications";
import {
  Breadcrumb,
  Button,
  Footer,
  Form,
  InputGroup,
  Skeleton,
  Text,
  WithPermission,
  Wrapper,
} from "components";
import {rules} from "constant";
import {withPermission} from "hoc";
import {useDataState, usePermission} from "hooks";
import {useEffect, useState} from "react";
import {ServiceApi, URLS} from "services";

function CrmConfiguration() {
  const controller = new AbortController();
  const [loadingButton, setLoadingButton] = useState(false);
  const [dataFirst, setDataFirst] = useState(false);
  const [data, setData, setBaseData, isChanged] = useDataState<any>({});
  const [loadingPage, setLoadingPage] = useState(true);
  const [passWord, setPassWord] = useState("");
  const updatePermission = usePermission("CMS_UpdateCrmConfig");

  const submit = () => {
    setLoadingButton(true);
    const url = URLS.EDIT_DYNAMICS_365;
    if (dataFirst) {
      ServiceApi.put(url, data)
        .then(() => {
          NotificationManager.success(
            "global.toast.edit-msg",
            "global.toast.edit-title",
          );
        })
        .finally(() => {
          setLoadingButton(false);
          getData();
        });
    } else {
      ServiceApi.post(url, data)
        .then(() => {
          NotificationManager.success(
            "global.toast.add-msg",
            "global.toast.add-title",
          );
        })
        .finally(() => {
          setLoadingButton(false);
          getData();
        });
    }
  };

  useEffect(() => {
    getData();
    return () => {
      controller.abort();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    setLoadingPage(true);
    await ServiceApi.get(URLS.GET_DYNAMICS_365, {signal: controller.signal})
      .then(({data}) => {
        setBaseData(data);
        if (data.length === 0) {
          setDataFirst(false);
        } else {
          setDataFirst(true);
          setPassWord(data?.clientSecret);
        }
      })
      .catch(() => {
        setData([]);
      })
      .finally(() => {
        setLoadingPage(false);
      });
  };

  return (
    <Form onSubmit={submit} className="relative space-y-6">
      <h3 className="mr-auto text-heading-3">
        <Breadcrumb />
      </h3>
      <Wrapper>
        <Wrapper.Header>
          <div className="flex">
            <div className="mr-auto">
              <h2 className="text-heading-2 font-semibold text-gray-800">
                <Text>thirdParty.crmConfiguration.dynamics365</Text>
              </h2>
            </div>
          </div>
        </Wrapper.Header>
        <Wrapper.Body className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-8">
          <div className="space-y-8">
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="thirdParty.crmConfiguration.dynamicsURL"
                value={data.url}
                setValue={url => setData((p: any) => ({...p, url}))}
                rules={rules.required}
                disabled={!updatePermission}
              />
            )}
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="thirdParty.crmConfiguration.clientId"
                value={data.clientId}
                setValue={clientId => setData((p: any) => ({...p, clientId}))}
                rules={rules.required}
                disabled={!updatePermission}
              />
            )}
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="thirdParty.crmConfiguration.clientSecret"
                value={data.clientSecret}
                disabled={!updatePermission}
                setValue={clientSecret => {
                  if (passWord === clientSecret) {
                    setData((p: any) => ({
                      ...p,
                      clientSecret,
                      isClientSecretModified: false,
                    }));
                  } else {
                    setData((p: any) => ({
                      ...p,
                      clientSecret,
                      isClientSecretModified: true,
                    }));
                  }
                }}
                rules={rules.required}
              />
            )}
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="thirdParty.crmConfiguration.tenantId"
                value={data.appTenantId}
                setValue={appTenantId =>
                  setData((p: any) => ({...p, appTenantId}))
                }
                rules={rules.required}
                disabled={!updatePermission}
              />
            )}
          </div>
        </Wrapper.Body>
      </Wrapper>
      <WithPermission permissions={["CMS_UpdateCrmConfig"]}>
        <Footer show={!isChanged}>
          <Button type="button" onClick={getData} variant="white">
            <Text>global.buttons.discard</Text>
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={!isChanged}
            loading={loadingButton}
          >
            <Text>global.buttons.saveChanges</Text>
          </Button>
        </Footer>
      </WithPermission>
    </Form>
  );
}
export default withPermission(CrmConfiguration, ["CMS_GetCrmConfig"]);
