import {
  Breadcrumb,
  Button,
  Icon,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Table,
  Text,
} from "components";
import { config } from "constant";
import { useConverter, useToggleState } from "hooks";
import download from "js-file-download";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ServiceApi, URLS } from "services";
import FilterDrawer from "./FilterDrawer";
import { withPermission } from "hoc";

type Params = {
  keyword?: string;
  status?: string;
  sellerName?: string;
  soldPriceFrom?: string;
  soldPriceTo?: string;
  createdDateFrom?: string;
  createdDateTo?: string;
  soldDateFrom?: string;
  soldDateTo?: string;
  sortType?: string;
  pageNumber: string;
  pageSize: string;
};

function SellOffCommissions() {
  const controller = new AbortController();
  const navigate = useNavigate();
  const { convertDate, convertAmount } = useConverter();
  const initParams: Params = {
    pageNumber: "1",
    pageSize: config.pageSize.toString(),
  };
  const [data, setData] = useState<any>();
  const [params, setParams] = useSearchParams(initParams);
  const [loadingList, setLoadingList] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [filterModal, setFilterModal] = useToggleState(false);
  const [keyword, setKeyword] = useState("");
  const [thisDate, setThisDate] = useState("");

  const getData = () => {
    const url = URLS.SEARCH_SELL_OFF_COMISSION;
    const body: any = {};
    params.forEach((value, key) => {
      body[key] = value;
    });
    setLoadingList(true);
    ServiceApi.post(url, body, { signal: controller.signal })
      .then(res => {
        if (res?.data) {
          setData(res?.data);
        }
      })
      .finally(() => {
        setLoadingList(false);
      });
  };

  const resetFilter = () => {
    setParams(initParams);
  };

  const handleSetFilterData = (key: keyof Params) => {
    return (initValue: any) => {
      setParams(prev => {
        const isDate = [
          "createdDateFrom",
          "createdDateTo",
          "phaseChangedFrom",
          "phaseChangedTo",
        ].includes(key);
        const isPageNumber = key === "pageNumber";
        const hasValue = !!initValue;
        const value = isDate ? initValue.toDateString() : initValue;

        hasValue ? prev.set(key, value) : prev.delete(key);
        !isPageNumber && prev.set("pageNumber", "1");

        return prev;
      });
    };
  };

  const exportFile = () => {
    setLoadingBtn(true);
    const url = URLS.DOWNLOAD_SELL_OFF_COMISSION;
    const body: any = {};
    params.forEach((value, key) => {
      body[key] = value;
    });
    ServiceApi.post(url, body)
      .then(({ data }: any) => {
        download(data, `sellOffCommission_${convertDate(new Date())}.csv`);
      })
      .finally(() => {
        setLoadingBtn(false);
      });
  };
  useEffect(() => {
    getData();
    return () => {
      controller.abort();
    };
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>

        <Button onClick={exportFile} loading={loadingBtn}>
          <Icon className="mr-2" icon={"file-arrow-down"} />
          <Text>salesManagment.sellOffSales.sellOffCommissions.export</Text>
        </Button>
      </div>
      <SearchBox
        value={keyword}
        onSubmit={handleSetFilterData("keyword")}
        disabled={loadingList}
        totalItems={data?.totalItems}
        onFilter={setFilterModal}
        resetFilter={resetFilter}
        onReload={getData}
      />
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {!!data?.searchResult?.length ? (
            <>
              <Table>
                {data?.searchResult?.map((item: any) => {
                  return (
                    <tr key={item.checkListItemId}>
                      <td className="space-y-2">
                        <h6 className="text-heading-6 font-semibold text-gray-800">
                          {item.title}
                        </h6>
                        <div className="text-body-2 font-medium text-gray-600">
                          <Text>
                            salesManagment.sellOffSales.sellOffCommissions.articleNumber
                          </Text>
                          :<span className="ml-1">{item.articleNumber}</span>
                        </div>
                      </td>
                      <td className="flex flex-wrap items-center gap-2 text-left">
                        <div>
                          <Table.Image
                            placeholder="user"
                            src=""
                            alt=""
                            className="h-[78px] w-[78px]"
                          />
                        </div>
                        <div className="space-y-3">
                          <div>
                            <span className="text-body-base font-normal text-gray-800">
                              {item.creatorName}
                            </span>
                          </div>
                          <div className="flex items-center">
                            <span className="text-body-base font-normal text-gray-600">
                              <Text>
                                salesManagment.sellOffSales.sellOffCommissions.soldPrice
                              </Text>
                              :
                            </span>
                            <h6 className="ml-1 text-heading-6 font-semibold text-gray-800">
                              {convertAmount(item.soldPrice)}
                            </h6>
                          </div>
                          <div>
                            <span className="text-body-base font-normal text-gray-600">
                              {convertDate(item.creationAt)}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="space-y-1 lg:w-[300px]">
                        <div className="flex flex-wrap items-center lg:justify-between">
                          <span className="text-body-base font-normal text-gray-600">
                            <Text>
                              salesManagment.sellOffSales.sellOffCommissions.sellerCommission
                            </Text>
                            :
                          </span>
                          <span className="text-body-base font-normal text-gray-800">
                            {item.sellerCommission}%
                          </span>
                        </div>
                        <div className="flex flex-wrap items-center lg:justify-between">
                          <span className="text-body-base font-normal text-gray-600">
                            <Text>
                              salesManagment.sellOffSales.sellOffCommissions.sellerCommissionAmount
                            </Text>
                            :
                          </span>
                          <h6 className="text-heading-6 font-semibold text-gray-800">
                            {convertAmount(item.sellerCommissionAmount)}
                          </h6>
                        </div>
                        <div className="flex flex-wrap items-center lg:justify-between">
                          <span className="text-body-base font-normal text-gray-600">
                            <Text>
                              salesManagment.sellOffSales.sellOffCommissions.fastSaleCommission
                            </Text>
                            :
                          </span>
                          <h6 className="text-body-base font-normal text-gray-800">
                            {convertAmount(item.fastSalesProvisionAmount)}
                          </h6>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages}
                pageNumber={+(params?.get("pageNumber") ?? 0)}
                pageSize={+(params?.get("pageSize") ?? 0)}
                setActivePage={handleSetFilterData("pageNumber")}
                onPageSizeChange={handleSetFilterData("pageSize")}
              />
            </>
          ) : (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
            </div>
          )}
        </>
      )}
      <FilterDrawer
        isOpen={filterModal}
        data={data}
        keyword={keyword}
        thisDate={thisDate}
        setThisDate={setThisDate}
        setKeyword={setKeyword}
        toggle={setFilterModal}
        onChange={handleSetFilterData}
        resetFilter={resetFilter}
        setParams={setParams}
        params={params}
      />
    </div>
  );
}

export default withPermission(SellOffCommissions, ["PS_GetSellOffCommission"]);
