import {
  Accordion,
  Drawer,
  HandleName,
  Icon,
  List,
  NoItems,
  Skeleton,
  Text,
} from "components";
import { Fragment, useContext, useEffect, useState } from "react";
import { ServiceApi } from "services";
import { ProductFamily } from "types";
import { ConfigProductContext } from ".";
export default function ChooseProductFamily() {
  const { setProductFamily } = useContext(ConfigProductContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ProductFamily.Item[]>([]);

  const roots = data.filter((e) => !e.parentId);

  const renderProductFamilies = (items: ProductFamily.Item[] = roots) => {
    if (!items.length) return null;
    return items.map((item) => {
      const children = data.filter(
        (e) => e.parentId === item.pimProductFamilyId
      );
      const hasChildren = !!children.length;
      return (
        <Accordion.Item
          key={item.pimProductFamilyId}
          className="bg-white px-4 rounded-2xl shadow-nested"
        >
          <div className="flex items-center">
            <Accordion.Toggle className="flex-1">
              <div className="flex space-x-4 items-center font-medium">
                {hasChildren && (
                  <Icon
                    icon={"caret-right"}
                    className="transition-transform group-[.accordion-item.active]:rotate-90"
                  />
                )}
                <div
                  className={`space-y-2 text-left cursor-pointer select-none`}
                  // onClick={() => setIsOpen()}
                >
                  <span className="text-heading-6 font-semibold">
                    <HandleName
                      translations={item.translates}
                      code={item.code}
                      keyName="labelTitle"
                    />
                  </span>
                  <p className="text-body-2 font-medium">{item.code}</p>
                </div>
              </div>
            </Accordion.Toggle>
            <button
              type="button"
              onClick={() => setProductFamily(item)}
              className="w-6 h-6 bg-gray-300 rounded"
            >
              {/* <Icon icon={"ellipsis-vertical"} className="text-primary" /> */}
            </button>
          </div>
          {hasChildren && (
            <Accordion.Body className="px-4 space-y-2">
              <Accordion>{renderProductFamilies(children)}</Accordion>
            </Accordion.Body>
          )}
        </Accordion.Item>
      );
    });
  };

  const getProductFamilies = () => {
    setLoading(true);
    const url = "/productservice/api/pimproductfamilies";
    ServiceApi.get(url)
      .then(({ data }) => {
        setData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(getProductFamilies, []);
  return (
    <Fragment>
      <Drawer.Header className="border-b-0 space-y-4">
        <fieldset className="text-start">
          <legend>
            <Text>
              productManagement.products.allProducts.chooseProductFamily
            </Text>
          </legend>
        </fieldset>
      </Drawer.Header>
      <Drawer.Body className="space-y-4">
        {loading ? (
          [...Array(10).keys()].map((key) => <Skeleton.Input key={key} />)
        ) : !data?.length ? (
          <NoItems />
        ) : (
          <List>
            <Accordion>{renderProductFamilies()}</Accordion>
          </List>
        )}
      </Drawer.Body>
    </Fragment>
  );
}
