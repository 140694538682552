export const calculationUnits = {
  addCalculationUnits: "Add Calculation units",
  addCalculationUnit: "Add Calculation unit",
  details: "Details",
  title: "Title",
  code: "Code",
  shortDescription: "Short Description",
  calculationUnitTypes: "Calculation Unit Types",
  percent: "Percent",
  amount: "Amount",
  multiply: "Multiply",
  pureProfit: "Pure Profit",
  pulsRound: "Puls Round",
  simpleRound: "Simple Round",
  minus: "Minus (-)",
  plus: "Plus (+)",
  defaultValue: "Default Value (Optional)",
  active: "Active",
  general: "General",
  availableInPriceTemplate: "Available In Price Template",
  customizableLevels: "Customizable Levels",
  basicInfo: "Basic Info",
  typeAndValue: "Type And Value",
  avilableOnlyInPurchase: "Avilable only in Purchase",
  availableOnlyInSalesTemplate: "Available only in Sales template",
  availableInBothPurchaseAndSales: "Available in both purchase and sales",
  supplier: "Supplier",
  program: "Program",
  brand: "Brand",
  product: "Product",
  deleteUnit: "Delete Unit",
  defaultValueInList: "Default Value",
  minValue: "Min Value",
  maxValue: "Max Value",
  typeWarning: "Calculation unit type",
};
