import {
  applications,
  channelMessage,
  company,
  configurations,
  customers,
  dashboard,
  errors,
  global,
  menu,
  productManagement,
  salesManagment,
  status,
  thirdParty,
  priceEngine,
  templates,
} from "./en_EN";
const en = {
  menu,
  configurations,
  status,
  global,
  errors,
  productManagement,
  thirdParty,
  salesManagment,
  applications,
  company,
  customers,
  dashboard,
  channelMessage,
  priceEngine,
  templates,
};
export default en;
