import { Wrapper, Text, Skeleton } from "components";
import { useContext, useEffect, useState } from "react";
import { ServiceApi, URLS } from "services";
import { CustomerContext } from "..";
import { useConverter, useToggleState } from "hooks";
export default function AccountInfo() {
  const { convertDate } = useConverter();
  const { userData } = useContext(CustomerContext);
  const [loading, setLoading] = useToggleState();
  const [data, setData] = useState<any>({});
  useEffect(() => {
    setLoading();
    const url = URLS.GET_CUSTOMER_ACCOUNT_DETAILS(userData?.customerAccountId);
    ServiceApi.get(url)
      .then(({ data }) => {
        setData(data);
        setLoading();
      })
      .catch(() => setLoading());
  }, []);
  //UI
  return (
    <Wrapper>
      <Wrapper.Header>
        <Text>customers.customerList.accountInfo</Text>
      </Wrapper.Header>
      <Wrapper.Body className="space-y-4 text-sm">
        {loading ? (
          <>
            <Skeleton.TextArea />
            <Skeleton.TextArea />
          </>
        ) : (
          <>
            <div className="flex justify-between bg-gray-200 rounded-md p-4">
              <Text>customers.customerList.status</Text>
              <span className="text-gray-700">
                {data?.status === 1 ? "Registered" : "not Registered"}
              </span>
            </div>

            <div className="flex justify-between items-center border-b border-gray-300 border-solid pb-4">
              <Text>customers.customerList.id</Text>
              <span className="text-gray-700">{data?.id}</span>
            </div>
            <div className="flex justify-between items-center border-b border-gray-300 border-solid pb-4">
              <Text>customers.customerList.customerId</Text>
              <span className="text-gray-700">{data?.customerId}</span>
            </div>
            <div className="flex justify-between items-center border-b border-gray-300 border-solid pb-4">
              <Text>customers.customerList.firstName</Text>
              <span className="text-gray-700">{data?.firstName}</span>
            </div>
            <div className="flex justify-between items-center border-b border-gray-300 border-solid pb-4">
              <Text>customers.customerList.lastName</Text>
              <span className="text-gray-700">{data?.lastName}</span>
            </div>
            <div className="flex justify-between items-center border-b border-gray-300 border-solid pb-4">
              <Text>customers.customerList.emailAddress</Text>
              <div className="space-x-2">
                <span className="text-gray-700">{data?.emailAddress}</span>
                {data?.isEmailAddressVerified && (
                  <span className="p-2 text-sm bg-success-light text-success rounded-md">
                    <Text>customers.customerList.verified</Text>
                  </span>
                )}
              </div>
            </div>
            <div className="flex justify-between items-center border-b border-gray-300 border-solid pb-4">
              <Text>customers.customerList.mobileNumber</Text>
              <div className="space-x-2">
                <span className="text-gray-700">
                  +{data?.mobileNumber?.countryCode}{" "}
                  {data?.mobileNumber?.number}
                </span>
                {data?.isMobileNumberVerified && (
                  <span className="p-2 text-sm bg-success-light text-success rounded-md">
                    <Text>customers.customerList.verified</Text>
                  </span>
                )}
              </div>
            </div>
            <div className="flex justify-between items-center border-b border-gray-300 border-solid pb-4">
              <Text>customers.customerList.status</Text>
              <span className="text-gray-700">
                <Text>{data?.status === 1 ? "customers.customerList.registered" : "customers.customerList.notRegistered"}</Text>
              </span>
            </div>
            <div className="flex justify-between items-center border-b border-gray-300 border-solid pb-4">
              <Text>customers.customerList.deviceName</Text>
              <span className="text-gray-700">{data?.deviceName}</span>
            </div>
            <div className="flex justify-between items-center border-b border-gray-300 border-solid pb-4">
              <Text>customers.customerList.mobileVerifyAt</Text>
              <span className="text-gray-700">
                {convertDate(data?.lastMobileNumberVerificationRequested)}
              </span>
            </div>
            <div className="flex justify-between items-center border-b border-gray-300 border-solid pb-4">
              <Text>customers.customerList.emailVerifyAt</Text>
              <span className="text-gray-700">
                {convertDate(data?.lastEmailVerificationRequested)}
              </span>
            </div>
          </>
        )}
      </Wrapper.Body>
    </Wrapper >
  );
}
