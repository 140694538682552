import { Wrapper, Text, Select, InputGroup, WithPermission, Button } from "components";
import { useDataState, useToggleState } from "hooks";
import { ServiceApi, URLS } from "services";
import { PimContext } from "..";
import { useContext, useEffect } from "react";
import { NotificationManager } from "common/notifications";
import { types } from "constant";

export default function SalesPersonCommission() {
  const [submitLoading, toggleSubmitLoading] = useToggleState();
  const { productData, loading, getPIMProduct } = useContext(PimContext);
  const [data, setData, setBaseData, isChanged] = useDataState({
    amount: '',
    calculationType: 0
  });

  const onSubmitHandler = () => {
    toggleSubmitLoading();
    const url = URLS.ADD_SALES_PERSON_COMMISSION(productData.id);
    const body = { ...data }
    ServiceApi.post(url, body)
      .then(() => {
        getPIMProduct();
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
      })
      .finally(() => toggleSubmitLoading());
  };

  useEffect(() => {
    setBaseData(productData?.salesPersonCommission || {
      amount: '',
      calculationType: 0
    })
  }, [productData])

  return (
    <>
      <Wrapper>
        <Wrapper.Header>
          <Text>productManagement.products.Details.salesPersonCommission</Text>
        </Wrapper.Header>
        <Wrapper.Body className="grid grid-cols-2 md:grid-cols-4 gap-8">
          <div className="col-span-2">
            <InputGroup
              label="productManagement.products.Details.amount"
              value={data?.amount}
              setValue={(value) => {
                if (data?.calculationType == 1 || (value >= 0 && value <= 100)) {
                  setData({ ...data, amount: value })
                }
              }}
              keyfilter="pnum"
            />
          </div>
          <div className="col-span-2 lg:col-span-1">
            <Select
              label="productManagement.products.Details.type"
              placeholder="global.select"
              value={data?.calculationType}
              items={types.amountCalculationType}
              setValue={(value) => { setData({ ...data, calculationType: value, amount: '' }) }}
            />
          </div>
        </Wrapper.Body>
      </Wrapper >
      {!loading && isChanged && (
        <WithPermission permissions={["PS_UpdatePimProduct"]}>
          <Wrapper className="sticky bottom-0 shadow-card">
            <Wrapper.Body className="flex items-center justify-end gap-4">
              <Button
                type="submit"
                variant="primary"
                disabled={!isChanged}
                onClick={onSubmitHandler}
                loading={submitLoading}
              >
                <Text>global.buttons.saveChanges</Text>
              </Button>
            </Wrapper.Body>
          </Wrapper>
        </WithPermission>
      )}
    </>
  );
}
