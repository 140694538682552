import {
  Breadcrumb,
  Button,
  Form,
  Icon,
  Text,
  Wrapper,
  Stepper,
} from "components";
import {createContext, createElement} from "react";
import {Fragment, useState} from "react";
import {ServiceApi} from "services";
import STORE from "services/urls/store";
import BasicInfo from "./BasicInfo";
import Location from "./Location";
import Image from "./Image";
import Review from "./Review";
import {useLocation, useNavigate} from "react-router";
import {NotificationManager} from "common/notifications";
import {ValidateAddress} from "utils";
import {useTranslate} from "hooks";

export const AddStoreContext = createContext({});

export default function AddNewStore() {
  const location = useLocation();
  const navigate = useNavigate();
  const tabs = [
    {
      label: "company.stores.basicInfo",
      desc: "company.stores.basicInfoDesc",
      component: BasicInfo,
    },
    {
      label: "company.stores.location",
      desc: "company.stores.locationDesc",
      component: Location,
    },
    {
      label: "company.stores.image",
      desc: "company.stores.imageDesc",
      component: Image,
    },
    {
      label: "company.stores.review",
      desc: "company.stores.reviewDesc",
      component: Review,
    },
  ];
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    code: "",
    title: "",
    legalEntityId: null,
    shortDescription: "",
    iln: "",
    body: "",
    mainPhotoUrl: "",
    isActive: true,
    address: null,
    openingHours: [],
    salesSettings: {
      iwofurnSalesCalculationFactor: 0,
      iwofurnRoundingMethod: "",
    },
  });
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const activeTab = tabs[activeTabIndex];
  const isFirstTab = activeTabIndex === 0;
  const isLastTab = activeTabIndex === tabs.length - 1;
  const isLocationTab = activeTabIndex === 1;
  const hasNotMainPhoto = activeTabIndex === 2 && !data.mainPhotoUrl;
  const [addressErrorMsg, setAddressErrorMasg] = useState("");
  const translate = useTranslate();

  const goNext = () => {
    if (isLastTab) return submit();
    if (!isLocationTab || !data.address) setActiveTabIndex(p => p + 1);
    else {
      setAddressErrorMasg("");
      setLoading(true);
      ValidateAddress(data.address)
        .then(res => {
          if (!res?.selected && !res.suggested) {
            setAddressErrorMasg(translate("global.locations.invalidAddress"));
            setLoading(false);
            return;
          }
          if (res.selected) {
            setData({
              ...data,
              address: {
                ...res.selected,
                name: data.address.name,
                number: data.address.number,
              },
            });
          }
          if (res.suggested) {
            setData({
              ...data,
              address: {
                ...res.suggested,
                name: data.address.name,
                number: data.address.number,
              },
            });
            setAddressErrorMasg(translate("global.locations.changedAddress"));
            setLoading(false);
            return;
          }
          setActiveTabIndex(p => p + 1);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const goPrev = () => {
    if (isFirstTab) return;
    setActiveTabIndex(p => p - 1);
  };
  const submit = () => {
    setLoading(true);
    const url = STORE.ADD_STORE_URL;
    const body = {...data};
    ServiceApi.post(url, body)
      .then(({data: storeId}) => {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title",
        );
        const baseUrl = location.pathname.split("/");
        baseUrl[baseUrl.length - 1] = storeId;
        navigate(baseUrl.join("/"));
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="space-y-6">
      <Breadcrumb />
      <div className="grid grid-cols-1 items-start gap-6 lg:grid-cols-12">
        <Stepper tabs={tabs} activeTab={activeTabIndex} />
        <Wrapper
          as={Form}
          onSubmit={goNext}
          className="col-span-full lg:col-span-8"
        >
          <Wrapper.Body className="mx-auto w-full space-y-8 lg:w-[calc(100%-9rem)]">
            <AddStoreContext.Provider
              value={{
                data,
                setData,
                addressErrorMsg,
              }}
            >
              {createElement(activeTab.component)}
            </AddStoreContext.Provider>
            <div className="flex items-center justify-between">
              <Button
                type="button"
                variant="light"
                disabled={isFirstTab}
                onClick={goPrev}
              >
                <Icon icon="arrow-left" /> <Text>global.buttons.back</Text>
              </Button>
              <Button
                type="submit"
                variant="primary"
                // disabled={hasNotMainPhoto}
                loading={loading}
              >
                {isLastTab ? (
                  <Fragment>
                    <Icon icon="check" /> <Text>global.buttons.submit</Text>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Text>global.buttons.next</Text> <Icon icon="arrow-right" />
                  </Fragment>
                )}
              </Button>
            </div>
          </Wrapper.Body>
        </Wrapper>
      </div>
    </div>
  );
}
