import {Dropdown} from "components";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useSelector} from "hooks";
import {ServiceApi, URLS} from "services";
import {setCompanySetting} from "store/companySetting";
export default function Lang() {
  const {i18n} = useTranslation();
  const dispatch = useDispatch();
  const companySetting = useSelector(s => s.companySetting);
  const [lang, setLang] = useState<string>("");
  const [companyLang, setCompanyLang] = useState([]);
  const changeLanguageHandler = (lang: string) => {
    setLang(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
  };
  const langCountry: any = {
    de: "German",
    en: "English",
    tr: "Turkish",
    fr: "French",
  };
  const items =
    companyLang?.map((lang: string) => ({
      name: lang,
      id: lang,
    })) ?? [];

  const effectCallback = async () => {
    const {data}: any = await ServiceApi.get(URLS.GET_COMPANY_SETTING);
    const result = await ServiceApi.get(URLS.MY_COMPANY_URL);
    setLang(data?.defaultDashboardLanguage);
    dispatch(setCompanySetting({...data, ...result?.data}));
    setCompanyLang(data?.dashboardLanguages);
  };

  useEffect(() => {
    effectCallback();
  }, []);

  useEffect(() => {
    if (companySetting) {
      setLang(companySetting.defaultDashboardLanguage);
    }
  }, [companySetting]);

  return (
    <Dropdown onSelect={changeLanguageHandler}>
      <Dropdown.Toggle
        as="button"
        type="button"
        className="text-body-base font-medium uppercase text-gray-600"
      >
        {lang}
      </Dropdown.Toggle>
      <Dropdown.Menu className="z-50 mt-2 min-w-fit p-2">
        <ul className="w-[104px] text-gray-700">
          {items.map((e: any) => (
            <li
              key={e.id}
              onClick={() => changeLanguageHandler(e.id)}
              className={`h6 px-4 py-2 font-medium first:mb-1 ${
                e.id === lang && "bg-primary-light text-primary "
              } cursor-pointer rounded-lg text-body-base hover:bg-primary-light hover:text-primary`}
            >
              {langCountry[e.name]}
            </li>
          ))}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
}
