import {isArray} from "lodash";
export const updateValue = (
  attribute: any,
  values: any,
  id: string,
  value: string,
  channel?: string,
  language?: string,
  measurementFamilyUnitCode?: string,
  isDefault?: boolean,
) => {
  let changedAttribute;
  let globalIndex = 0;

  const dto = values?.find((item: any) => {
    return item.attributeTemplateId === id;
  });

  const innerDto = isDefault
    ? dto?.pimProductAttribute?.pimProductAttributeDefaultValueDto
    : dto?.pimProductAttribute?.pimProductAttributeValueDto;
  let data = isArray(innerDto) ? JSON.parse(JSON.stringify(innerDto)) : null;

  // const attributeValues = values?.find(
  //   (item: any) => item.attributeGroupId === id
  // );

  // const target = attributeValues?.pimProductAttributeValues?.find(
  //   (item: any) => item.code === attribute.code
  // );

  // const updateIndex = attributeValues?.pimProductAttributeValues?.findIndex(
  //   (item: any) => item.code === attribute.code
  // );

  const perChannelAttribute = () => {
    return innerDto?.find((item: any, index: number) => {
      globalIndex = index;
      return item.channel === channel;
    });
  };
  const perLangAttribute = () => {
    return innerDto?.find((item: any, index: number) => {
      globalIndex = index;
      return item.language === language;
    });
  };

  const perLangAndChannel = () => {
    return innerDto?.find((item: any, index: number) => {
      globalIndex = index;
      return item.language === language && item.channel === channel;
    });
  };

  const noneDependent = () => {
    return innerDto?.find((item: any, index: number) => {
      globalIndex = index;
      return !item.language && !item.channel;
    });
  };

  const notExist = () => {
    globalIndex = 0;
    return {
      channel: attribute.perChannel ? channel : null,
      language: attribute.perLanguage ? language : null,
      measurementFamilyUnitCode: null,
      value: value,
    };
  };

  let targetObject;

  if (isDefault) {
    targetObject = attribute?.perLanguage
      ? perLangAttribute()
      : noneDependent();
  } else {
    targetObject =
      attribute?.perChannel && attribute?.perLanguage
        ? perLangAndChannel()
        : attribute?.perChannel
          ? perChannelAttribute()
          : attribute?.perLanguage
            ? perLangAttribute()
            : noneDependent();
  }

  if (targetObject) {
    if (attribute.type === 70) {
      targetObject = {...targetObject, value, measurementFamilyUnitCode};
    } else {
      targetObject = {...targetObject, value};
    }
  } else {
    targetObject = notExist();
  }

  const updateValuePerLang = (attrs: any) => {
    const specificValueIndex = attrs?.findIndex(
      (item: any) => item.language === language,
    );
    return specificValueIndex;
  };
  const updateValuePerChannel = (attrs: any) => {
    const specificValueIndex = attrs?.findIndex(
      (item: any) => item.channel === channel,
    );
    return specificValueIndex;
  };
  const updateValuePerLangAndChannel = (attrs: any) => {
    const specificValueIndex = attrs?.findIndex(
      (item: any) => item.language === language && item.channel === channel,
    );
    return specificValueIndex;
  };
  const updateValueNoneDeps = (attrs: any) => {
    const specificValueIndex = attrs?.findIndex(
      (item: any) => !item.language && !item.channel,
    );
    return specificValueIndex;
  };

  let specificValueIndex;
  if (isDefault) {
    specificValueIndex = attribute?.perLanguage
      ? updateValuePerLang(data)
      : updateValueNoneDeps(data);
  } else {
    specificValueIndex =
      attribute?.perChannel && attribute?.perLanguage
        ? updateValuePerLangAndChannel(data)
        : attribute?.perChannel
          ? updateValuePerChannel(data)
          : attribute?.perLanguage
            ? updateValuePerLang(data)
            : updateValueNoneDeps(data);
  }

  if (data) {
    if (specificValueIndex > -1) {
      data[specificValueIndex] = targetObject;
    } else {
      data.push(targetObject);
    }
    const key = isDefault
      ? "pimProductAttributeDefaultValueDto"
      : "pimProductAttributeValueDto";
    changedAttribute = {
      ...attribute,
      pimProductAttribute: {...attribute.pimProductAttribute, [key]: data},
    };
  } else {
    data = [targetObject];
    const key = isDefault
      ? "pimProductAttributeDefaultValueDto"
      : "pimProductAttributeValueDto";
    changedAttribute = {
      ...attribute,
      pimProductAttribute: {...attribute.pimProductAttribute, [key]: data},
    };
  }

  return {data, changedAttribute};
};
