export const productCategory = {
  primaryCategories: "Hauptkategorien",
  addButton: "Hinzufügen",
  generalTab: "Allgemein",
  subCategoriesTab: "Unterkategorie",
  mediaTab: "Medien",
  channelsTab: "Kanal",
  deleteButton: "Löschen",
  unPublish: "Veröffentlichung aufheben",
  publish: "Veröffentlichen",
  basicInfo: "Grundlegende Informationen",
  categoryName: "Kategoriename",
  categoryCode: "Kategoriecode",
  sortOrder: "Sortierreihenfolge",
  shortDescription: "Kurzbeschreibung",
  editSubCategory: "Bearbeiten",
  addBranch: "Zweig hinzufügen",
  addSubCategory: "Unterkategorie hinzufügen",
  images: "Bilder",
  mainImage: "Hauptbild",
  uploadImage: "Bild hochladen",
  thumbnail: "Miniaturansicht",
  showInThisChannel: "In diesem Kanal anzeigen",
  note: "Hinweis",
  noteText:
    "Um die Änderungen in den Kanälen anzuzeigen, müssen Sie die Hauptkategorie im Kanal veröffentlichen.",
  addCategory: "Kategorie hinzufügen",
  details: "Detailinformationen",
  channelSetting: "CKanaleinstellungen",
  requirementGathering: "Anforderungserfassung",
  configurationValidation: "Konfigurations-Validierung",
  dataTransmission: "Datenübertragung",
  acknowledgeReceipt: "Empfang bestätigt",
};
