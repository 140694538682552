export const simulator = {
  brands: "Brands",
  brand: "Brand",
  addBrand: "Add Brand",
  salesChannels: "Sales Channels",
  salesChannel: "Sales Channel",
  addSalesChannel: "Add Sales Channel",
  PurchasePriceProcess: "PurchasePriceProcess",
  SalePriceProcess: "SalePriceProcess",
  CalculationProcessType: "Calculation Process Type",
  products: "Products",
  product: "Product",
  addProduct: "Add Product",
  programs: "Programs",
  program: "Program",
  addProgram: "Add Program",
  suppliers: "Suppliers",
  supplier: "Supplier",
  addSupplier: "Add Supplier",
  purchasePriceTemplate: "Purchase Price Template",
  assignPurchasePriceTemplate: "Assign Purchase Price Template",
  salesPriceTemplate: "Sales Price Template",
  assignSalesPriceTemplate: "Assign Sales Price Template",
  simulation: "Simulation",
  purchasePrice: "purchasePrice",
  vk3: "vk3",
  vk2: "vk2",
  type: "Type",
  lineItemType: "Line Item Type",
  PimProduct: "PimProduct",
  IwofurnProduct: "IwofurnProduct",
  ManualProduct: "ManualProduct",
};
