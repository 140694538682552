import {createElement, useEffect, useState} from "react";
import {Button, Drawer, Icon, Tabs, Text, DeleteModal} from "components";
import GeneralTab from "./GeneralTab";
import EmployeesTab from "./EmployeesTab";
import {ServiceApi, URLS} from "services";
import {useParams} from "react-router";
import {Departments} from "types";
import {createContext} from "react";
import {useToggleState} from "hooks";
import {NotificationManager} from "common/notifications";
import EditGeneralInfo from "./EditGeneralInfo";
import {AxiosResponse} from "axios";

interface DetailsDepartmentType {
  isOpen: boolean;
  toggle: () => void;
  departmentId?: string;
  getData: any;
}

type contextTtype = {
  details?: Departments.DepartmentDetails;
  loading: boolean;
  departmentId: string;
  ids: string[];
  getDetails: () => void;
};

export const DetailsContext = createContext({} as contextTtype);

export default function DetailsDepartment({
  isOpen,
  toggle,
  departmentId,
  getData,
}: DetailsDepartmentType) {
  const {storeId} = useParams();
  const [loading, setLoading] = useToggleState();
  const [deleteLoading, setDeleteLoading] = useToggleState();
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState();
  const [isOpenEditGeneral, toggleEditGeneral] = useToggleState();
  const [details, setDetails] = useState<Departments.DepartmentDetails>();
  const [teamLead, setTEamLead] = useState<Departments.EmployeeDetail>();
  const [ids, setIds] = useState<string[]>([]);

  const tabs = [
    {
      label: "company.stores.general",
      component: GeneralTab,
    },
    {
      label: "company.stores.employees",
      component: EmployeesTab,
    },
  ];

  const getDetails = () => {
    setLoading();
    const url = URLS.GET_DEPARTMENT_DETAILS_URL(storeId);
    const params = {id: departmentId};
    const config = {params};
    ServiceApi.get(url, config)
      .then(({data}: any) => {
        setDetails(data);
        const ids = data?.departmentEmployees?.map(
          (e: Departments.EmployeeItem) => e.employeeId,
        );
        setIds(ids);
        setLoading();
      })
      .catch(() => setLoading());
  };

  useEffect(() => {
    if (isOpen) getDetails();
  }, [isOpen]);

  const getTeamLeadInfo = () => {
    if (details) {
      const url = URLS.GET_EMPLOYEE_DETAILS(details.leadEmployeeId);
      ServiceApi.get(url).then(({data}: AxiosResponse) => {
        setTEamLead(data);
      });
    }
  };

  useEffect(getTeamLeadInfo, [details]);

  const onDeleteDepartment = () => {
    setDeleteLoading();
    const url = URLS.REMOVE_DEPARTMENT(departmentId);
    ServiceApi.delete(url)
      .then(() => {
        setDeleteLoading();
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title",
        );
        getData();
        toggle();
      })
      .catch(() => setDeleteLoading());
  };
  return (
    <>
      <Drawer isOpen={isOpen} toggle={toggle} size={"lg"}>
        <Drawer.Menu>
          <Drawer.Header className="flex items-center justify-between">
            <span>
              <Text>company.stores.details</Text>
            </span>
            <div className="space-x-2">
              <Button
                size="sm"
                disabled={loading}
                variant={"danger"}
                light
                onClick={toggleDeleteModal}
              >
                <Icon icon="trash" className="mr-1" />{" "}
                <Text>global.buttons.delete</Text>
              </Button>
              <Button
                size="sm"
                disabled={loading}
                variant={"primary"}
                light
                onClick={toggleEditGeneral}
              >
                <Icon icon="edit" className="mr-1" />{" "}
                <Text>company.stores.editGeneralInfo</Text>
              </Button>
              <Button size="sm" variant={"light"} onClick={toggle}>
                <Icon icon="close" className="mr-1" />
                <Text>global.buttons.close</Text>
              </Button>
            </div>
          </Drawer.Header>
          <Drawer.Body>
            <Tabs activeKey={tabs[0].label}>
              <Tabs.ButtonGroup className="mb-6">
                {tabs.map(e => (
                  <Tabs.Button key={e.label} eventKey={e.label}>
                    <Text>{e.label}</Text>
                  </Tabs.Button>
                ))}
              </Tabs.ButtonGroup>
              {tabs.map(e => (
                <DetailsContext.Provider
                  value={{
                    details,
                    loading,
                    departmentId: departmentId as string,
                    ids,
                    getDetails,
                  }}
                >
                  <Tabs.Item key={e.label} eventKey={e.label}>
                    {createElement(e.component)}
                  </Tabs.Item>
                </DetailsContext.Provider>
              ))}
            </Tabs>
          </Drawer.Body>
        </Drawer.Menu>
      </Drawer>
      <DeleteModal
        isOpen={isOpenDeleteModal}
        toggle={toggleDeleteModal}
        selected={{name: details?.title}}
        loading={deleteLoading}
        onConfirm={onDeleteDepartment}
      />
      <EditGeneralInfo
        isOpen={isOpenEditGeneral}
        toggle={toggleEditGeneral}
        details={details}
        departmentId={departmentId}
        getDetails={getDetails}
        teamLead={teamLead}
      />
    </>
  );
}
