import {
  Fragment,
  createContext,
  createElement,
  useEffect,
  useState,
} from "react";
import {
  Image,
  Breadcrumb,
  Wrapper,
  Status,
  Tabs,
  Text,
  Form,
  Skeleton,
  ImageUploader,
  LazyImage,
  Toggler,
} from "components";
import {ObjectUtils} from "utils";
import {useDataState, usePermission, useToggleState} from "hooks";
import {useParams} from "react-router";
import {ServiceApi, URLS} from "services";
import {Media} from "types";
import {useConverter} from "hooks";
import {NotificationManager} from "common/notifications";
import {assetTypes} from "constant";

//... tabs
import General from "./tabs/general";
import TechnicalInfo from "./tabs/technicalInfo";
import Department from "./tabs/department";

export const CustomerContext = createContext({} as any);
export default function Details() {
  const [isOpen, toggle] = useToggleState(false);
  const {employeeId} = useParams();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [headerData, setHeaderData] = useState<any>({});
  const [data, setData, setBaseData, isChanged] = useDataState<any>({});
  const {convertDate} = useConverter();
  const [disabledStatus, setDisabledStatus] = useToggleState();
  const updatePermission = usePermission("AS_EmployeeFullAccess");

  let tabs: any = [];

  tabs = [
    {
      label: "company.employees.general",
      component: General,
    },
    {
      label: "company.employees.technicalInfo",
      component: TechnicalInfo,
    },
    {
      label: "company.employees.department",
      component: Department,
      permission: ["AS_GetDepartment"],
    },
  ];

  const getData = () => {
    const url = URLS.GET_EMPLOYEES_DETAILS_URL(employeeId);
    ServiceApi.get(url)
      .then(({data}) => {
        let result = JSON.parse(JSON.stringify(data));
        setBaseData(result);
        setHeaderData(result);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(getData, []);

  const submit = () => {
    setSubmitLoading(true);
    const url = URLS.UPDATE_B2B_USERS_URL(employeeId);
    const body = {...data};
    ServiceApi.put(url, body)
      .then(() => {
        const url = URLS.UPDATE_SET_DEFAULT_FILTER_USER_URL(employeeId);
        ServiceApi.patch(url, body).then(() => {
          NotificationManager.success(
            "global.toast.edit-msg",
            "global.toast.edit-title",
          );
        });
        setBaseData(data);
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const onUploadImage = (data: Media) => {
    const url = URLS.UPLOAD_USER_IMAGES(employeeId);
    const body = {
      url: data.url,
      type: data.type,
      thumbnailUrl: data.thumbnailUrl,
    };
    ServiceApi.patch(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
      })
      .finally(() => {
        getData();
      });
  };

  const handelDepartmentsLables = (departments: any) => {
    let label: any = [];
    departments?.forEach((department: any) => {
      label.push(department.name);
    });

    return label.length === 0 ? "" : label?.toString();
  };

  const toggleStatus = () => {
    setDisabledStatus();
    const url = URLS.CHANGE_EMPLOYEE_STATUS(employeeId);
    const body = {accountEnabled: !data.accountEnabled};
    ServiceApi.post(url, body)
      .then(() => {
        getData();
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
      })
      .finally(() => {
        setDisabledStatus();
      });
  };

  return (
    <Tabs activeKey={tabs[0].label}>
      <Form onSubmit={submit} className="relative space-y-6">
        <Breadcrumb />
        <Wrapper>
          <Wrapper.Body className="flex flex-col items-start gap-5 lg:flex-row">
            <div className="h-[114px] w-[114px]">
              <LazyImage
                placeholder="user"
                // onClick={() => toggle()}
                src=""
                alt={data?.cimNumber}
                className="aspect-image h-full w-full cursor-pointer lg:aspect-auto"
              />
            </div>
            <div className="space-y-4 lg:flex-1">
              {loading ? (
                <Fragment>
                  <Skeleton.Input />
                  <Skeleton.Input />
                  <Skeleton.Input />
                  <Skeleton.Input />
                </Fragment>
              ) : (
                <Fragment>
                  <div className="space-y-3">
                    <div className="flex gap-2">
                      <div className="flex flex-1 items-center gap-2">
                        <h5 className="text-heading-5 font-semibold text-gray-800">
                          {headerData?.displayName}
                        </h5>
                        <Status.Store id={headerData?.accountEnabled} />
                        <Status.EmployeesType id={headerData?.userType} />
                      </div>
                      {disabledStatus ? (
                        <Skeleton.Button />
                      ) : (
                        <Toggler
                          className="!w-fit"
                          label="company.employees.active"
                          value={data?.accountEnabled}
                          setValue={toggleStatus}
                          disabled={disabledStatus || !updatePermission}
                        />
                      )}
                    </div>
                    <div className="flex items-center gap-32">
                      <div className="space-y-3">
                        <div className="flex items-center gap-2">
                          <div>
                            <span className="text-body-2 font-medium  text-gray-500">
                              <Text>company.employees.employeeNumber</Text>:
                            </span>
                          </div>
                          <div>
                            <span className="text-body-2 font-medium  text-gray-500">
                              {headerData?.employeeNumber}
                            </span>
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <div>
                            <span className="text-body-2 font-medium  text-gray-500">
                              <Text>company.employees.salesPersonNumber</Text>:
                            </span>
                          </div>
                          <div>
                            <span className="text-body-2 font-medium  text-gray-500">
                              {headerData?.salesPersonNumber}
                            </span>
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <div>
                            <span className="text-body-2 font-medium  text-gray-500">
                              <Text>company.employees.creationDate</Text>:
                            </span>
                          </div>
                          <div>
                            <span className="text-body-2 font-medium  text-gray-500">
                              {convertDate(headerData?.userCreatedDateTime)}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="space-y-3">
                        <div>
                          <span className="text-body-2 font-medium  text-gray-500">
                            {headerData?.store?.title}
                          </span>
                        </div>
                        <div>
                          <span className="text-body-2 font-medium  text-gray-500">
                            {handelDepartmentsLables(headerData?.departments)}
                          </span>
                        </div>
                        <div>
                          <span className="text-body-2 font-medium  text-gray-500">
                            {headerData?.jobTitle}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </Wrapper.Body>
          <Wrapper.Footer className="!py-0">
            <Tabs.ButtonGroup>
              {tabs.map((e: any) => (
                <Tabs.Button
                  key={e.label}
                  eventKey={e.label}
                  permission={e.permission}
                >
                  <Text>{e.label}</Text>
                </Tabs.Button>
              ))}
            </Tabs.ButtonGroup>
          </Wrapper.Footer>
        </Wrapper>
        {/* <Wrapper className="p-8"> */}
        {loading && (
          <Wrapper>
            <Wrapper.Body className="grid grid-cols-1 gap-4 lg:grid-cols-2">
              <Skeleton.Input />
              <Skeleton.Input />
            </Wrapper.Body>
          </Wrapper>
        )}
        {tabs.map((e: any) => (
          <Tabs.Item key={e.label} eventKey={e.label}>
            <CustomerContext.Provider
              value={{
                userData: data,
                setUserData: setData,
                loading: loading,
                getData: getData,
              }}
            >
              {!ObjectUtils.isEmpty(data) && createElement(e.component)}
            </CustomerContext.Provider>
          </Tabs.Item>
        ))}
        {/* </Wrapper> */}
        {/* {UPDATE_PERMISSIONS && (
          <Footer show={!isChanged} >
            <Button as={Link} to={-1} variant="white">
              <Text>global.buttons.discard</Text>
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={!isChanged}
              loading={submitLoading}
            >
              <Text>global.buttons.saveChanges</Text>
            </Button>
          </Footer>
        )} */}
      </Form>
      <ImageUploader
        isOpen={isOpen}
        toggle={toggle}
        image={data.imageUrl}
        onUpload={(data: Media) => onUploadImage(data)}
        type={assetTypes.Company}
      />
    </Tabs>
  );
}
