import {Button, Icon, Table, Text, WithPermission} from "components";
import {usePermission, useToggleState} from "hooks";
import {Fragment, useContext, useState} from "react";
import toast from "react-hot-toast";
import {ServiceApi, URLS} from "services";
import {Employee} from "types";
import {UserContext} from "../..";
import DetailsDrawer from "./details";

type EmployeeRowProps = {
  employee: Employee.Details;
  isUserEmployee: boolean;
};

export default function EmployeeRow({
  employee,
  isUserEmployee,
}: EmployeeRowProps) {
  const {userData, setUserBaseData} = useContext<any>(UserContext);
  const getDetailsPermission = usePermission("AS_GetEmployee");
  const [isOpenDetailsDrawer, toggleDetailsDrawer] = useToggleState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const removeEmployee = () => {
    setLoadingButton(true);
    const url = URLS.PUT_EMPLOYEE_USER(userData?.userId);
    const body = {employeeId: null};
    ServiceApi.put(url, body)
      .then(() => {
        toast.success("global.toast.edit-msg");
        setUserBaseData({...userData, employeeId: null, employee: null});
      })
      .finally(() => {
        setLoadingButton(false);
      });
  };
  //   const handelDepartmentsLables = (departments: any) => {
  //     let label: any = [];
  //     departments?.forEach((department: any) => {
  //       label.push(department.name);
  //     });

  //     return label.length === 0 ? "" : label?.toString();
  //   };

  return (
    <Fragment>
      <tr key={employee.employeeId}>
        <td>
          <button
            type="button"
            onClick={() => {
              if (!getDetailsPermission) return;
              toggleDetailsDrawer();
            }}
            className="flex-center inline-flex w-fit gap-2"
          >
            <Table.Image
              placeholder="user"
              src=""
              alt={employee.employeeId}
              className="h-[78px] w-[78px]"
            />
            <div className="space-y-2 text-left">
              <div>
                <h6 className="text-heading-6 font-semibold text-gray-800">{`${employee.firstName} ${employee.lastName}`}</h6>
              </div>
              {employee.companyName && (
                <div>
                  <Icon
                    className="mr-2 font-black text-gray-500"
                    size="1x"
                    icon="user-tag"
                  />
                  <span className="text-body-2 font-medium text-gray-500">
                    {employee.companyName}
                  </span>
                </div>
              )}
              <div>
                <Icon
                  className="mr-2 font-black text-gray-500"
                  size="1x"
                  icon="envelope"
                />
                <span className="text-body-2 font-medium text-gray-500">
                  {employee.emailAddress}
                </span>
              </div>
            </div>
          </button>
        </td>
        <td className="space-y-2 text-left">
          <div>
            <span className="text-body-base font-medium text-gray-600">
              {employee.store?.title}
            </span>
          </div>
          {/* <div><span className="text-body-base text-gray-600 font-medium">{handelDepartmentsLables(employee.departments)}</span></div> */}
          <div>
            <span className="mr-1 text-body-base font-medium text-gray-800">
              <Text>company.employees.employeeNumber</Text>:
            </span>
            <span className="text-body-base font-medium text-gray-600">
              {employee.employeeNumber}
            </span>
          </div>
        </td>
        <td>{/* <Status.EmployeesType id={employee.userType} /> */}</td>
        <td className="space-x-1">
          <WithPermission permissions={["AS_GetEmployee"]}>
            {!isUserEmployee && (
              <Button
                type="button"
                variant="danger"
                light
                size="sm"
                onClick={removeEmployee}
                loading={loadingButton}
              >
                {!loadingButton && <Icon icon="trash" />}
              </Button>
            )}
            <Button
              type="button"
              variant="light"
              size="sm"
              onClick={toggleDetailsDrawer}
            >
              <Text>global.buttons.details</Text>
            </Button>
          </WithPermission>
        </td>
      </tr>
      <DetailsDrawer
        isOpen={isOpenDetailsDrawer}
        toggle={toggleDetailsDrawer}
        employeeId={employee.employeeId}
      />
    </Fragment>
  );
}
