import {
  InputGroup,
  Select,
  SelectLang,
  Skeleton,
  Text,
  Wrapper,
} from "components";
import {flagCountry} from "constant";
import {useSelector, usePermission} from "hooks";
import {Fragment, useContext, useEffect, useState} from "react";
import {AttributesTypes} from "utils";
import {AttributesContext} from "..";

export default function AttributesTypeYesNo() {
  const updatePermission = usePermission("PS_UpdateAttributeTemplate");
  const companySetting = useSelector(s => s.companySetting);
  const [langSelected, setLangSelected] = useState(
    companySetting.defaultContentLanguage,
  );
  const {attributesData, setAttributesData, langItems, loading} =
    useContext<any>(AttributesContext);
  const [positiveName, setPositiveName] = useState("");
  const [negativeName, setNegativeName] = useState("");
  const [items, setItems] = useState([]);

  const handleSetData = (key: any) => {
    return (value: any) =>
      setAttributesData((p: any) => ({...p, [key]: value}));
  };

  useEffect(() => {
    let arr: any = [];
    arr = [
      {id: "true", name: "productManagement.masterData.attributes.positive"},
      {id: "false", name: "productManagement.masterData.attributes.negative"},
    ];
    let attributesDataNew: any = JSON.parse(JSON.stringify(attributesData));

    attributesDataNew?.items?.forEach((item: any) => {
      if (item?.translates) {
        item?.translates?.forEach((i: any) => {
          if (item.code?.toLocaleLowerCase() === "true") {
            if (
              i?.language?.toLocaleLowerCase() ===
              langSelected?.toLocaleLowerCase()
            ) {
              setPositiveName(i.labelTitle);
            }
          } else {
            if (
              i?.language?.toLocaleLowerCase() ===
              langSelected?.toLocaleLowerCase()
            ) {
              setNegativeName(i.labelTitle);
            }
          }
        });
      }
    });
    setItems(arr);
  }, [langSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeLanguage = (lang: any) => {
    setLangSelected(lang);
    attributesData?.items?.forEach((item: any) => {
      item?.translates?.forEach((i: any) => {
        if (item?.code?.toLocaleLowerCase() === "true") {
          if (i?.language?.toLocaleLowerCase() === lang?.toLocaleLowerCase()) {
            setPositiveName(i.labelTitle);
          }
        } else {
          if (i?.language?.toLocaleLowerCase() === lang?.toLocaleLowerCase()) {
            setNegativeName(i.labelTitle);
          }
        }
      });
    });
  };

  return (
    <Fragment>
      <div className="flex gap-6">
        {loading ? (
          <Wrapper>
            <Wrapper.Body className="grid grid-cols-1 gap-4 lg:grid-cols-2">
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Wrapper.Body>
          </Wrapper>
        ) : (
          <Wrapper>
            <Wrapper.Header>
              <h2 className="font-semibold text-gray-800">
                <Text>productManagement.masterData.attributes.setting</Text>
              </h2>
            </Wrapper.Header>
            <Wrapper.Body className="grid grid-cols-1 gap-4 lg:grid-cols-1">
              <div className="space-y-8">
                <InputGroup
                  label="productManagement.masterData.attributes.attributeType"
                  value={AttributesTypes(attributesData?.type)}
                  disabled
                />
                <Select
                  label="productManagement.masterData.attributes.attributeDefaultValue"
                  items={items}
                  value={
                    attributesData?.defaultValue
                      ?.toLocaleLowerCase()
                      ?.includes("true")
                      ? true
                      : false
                  }
                  setValue={handleSetData("defaultValue")}
                  disabled={!updatePermission}
                />
              </div>
            </Wrapper.Body>
          </Wrapper>
        )}
        {loading ? (
          <Wrapper>
            <Wrapper.Body className="grid grid-cols-1 gap-4 lg:grid-cols-1">
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Wrapper.Body>
          </Wrapper>
        ) : (
          <Wrapper>
            <Wrapper.Header>
              <div className="flex items-center justify-between">
                <div>
                  <h2 className="font-semibold text-gray-800">
                    <Text>
                      productManagement.masterData.attributes.translation
                    </Text>
                  </h2>
                </div>
                <div>
                  <SelectLang
                    value={langSelected}
                    items={langItems}
                    onChangeLanguage={onChangeLanguage}
                  />
                </div>
              </div>
            </Wrapper.Header>
            <Wrapper.Body className="grid grid-cols-1 gap-4 lg:grid-cols-1">
              <div className="space-y-8">
                <InputGroup
                  label="productManagement.masterData.attributes.positiveName"
                  value={positiveName}
                  flag={flagCountry[langSelected]}
                  setValue={value => {
                    attributesData?.items?.forEach((item: any) => {
                      item?.translates?.forEach((i: any) => {
                        if (item.code?.toLocaleLowerCase() === "true") {
                          if (
                            i?.language?.toLocaleLowerCase() ===
                            langSelected?.toLocaleLowerCase()
                          ) {
                            i.labelTitle = value;
                          }
                        }
                      });
                    });
                    setAttributesData((p: any) => ({
                      ...p,
                      positive: `true${value}`,
                    }));
                    setAttributesData((p: any) => ({
                      ...p,
                      items: attributesData?.items,
                    }));
                    setPositiveName(value);
                  }}
                />
                <InputGroup
                  flag={flagCountry[langSelected]}
                  label="productManagement.masterData.attributes.negativeName"
                  value={negativeName}
                  setValue={value => {
                    attributesData?.items?.forEach((item: any) => {
                      item?.translates?.forEach((i: any) => {
                        if (item.code?.toLocaleLowerCase() === "false") {
                          if (
                            i?.language?.toLocaleLowerCase() ===
                            langSelected?.toLocaleLowerCase()
                          ) {
                            i.labelTitle = value;
                          }
                        }
                      });
                    });
                    setAttributesData((p: any) => ({
                      ...p,
                      negativ: `false${value}`,
                    }));
                    setAttributesData((p: any) => ({
                      ...p,
                      items: attributesData?.items,
                    }));
                    setNegativeName(value);
                  }}
                />
              </div>
            </Wrapper.Body>
          </Wrapper>
        )}
      </div>
    </Fragment>
  );
}
