import {
  Breadcrumb,
  Skeleton,
  Tabs,
  Wrapper,
  Text,
  Button,
  Icon,
} from "components";
import {createContext, createElement, useEffect, useState} from "react";
import {useParams} from "react-router";
import {General, Attribute, Media, BundleItems, Inventory} from "./tabs";
import {ServiceApi, URLS} from "services";
import {useToggleState} from "hooks";
import Info from "./info";
import Print from "./drawers/print";
import PrintHistory from "./tabs/print-history";
import {useSearchParams} from "react-router-dom";
import Share from "./drawers/share";
import AvailabilityStatus from "./tabs/availability-status/availability-status";
import PushToSellOff from "./drawers/push-to-selloff";
import SalesPersonCommission from "./tabs/salesPersonCommission";

export const PimContext = createContext<any>({});
//Published Products
export default function PublishedProductsDetails() {
  const {productId} = useParams();
  const [share, shareToggle] = useToggleState();
  const [loading, setLoading] = useToggleState();
  const [isOpen, toggle] = useToggleState();
  const [details, setDetails] = useState<any>([]);
  let [searchParams] = useSearchParams();
  const [params] = useState<any>({
    channelCode: searchParams.get("channelCode"),
  });
  const [activeKey, setActiveKey] = useState<string>();
  const [print, printToggle] = useToggleState();
  const [updatePrintHistory, toggleUpdatePrintHistory] = useToggleState();
  const [inventoryConfigExisted, setInventoryConfigExisted] = useState(false);

  const tabs = [
    {
      label: "productManagement.products.Details.generalTab",
      component: General,
    },
    ...(details?.pimProductType
      ? [
          {
            label: "items",
            component: BundleItems,
          },
        ]
      : [
          {
            label: "productManagement.products.Details.attributesTab",
            component: Attribute,
          },
        ]),

    {
      label: "productManagement.products.Details.mediaTab",
      component: Media,
      permission: ["PS_GetPimProductMedia"],
    },
    {
      label: "productManagement.publishedProduct.Details.inventory",
      component: Inventory,
    },
    {
      label: "productManagement.publishedProduct.Details.printHistory",
      component: PrintHistory,
    },
    {
      label: "productManagement.products.Details.availabilityStatusTab",
      component: AvailabilityStatus,
    },
    ...(details?.pimProductType == 0
      ? [
          {
            label: "productManagement.products.Details.salesPersonCommission",
            component: SalesPersonCommission,
          },
        ]
      : []),
  ];

  useEffect(() => {
    setActiveKey(tabs[0].label);
    getDetails();
    isInventoryConfigExisted();
  }, [productId]);

  const getDetails = () => {
    setLoading();
    const url = URLS.GET_PUBLISHED_PRODUCT_DETAILS(productId);
    const config = {params};
    ServiceApi.get(url, config)
      .then(({data}) => {
        setLoading();
        setDetails(data);
      })
      .catch(() => setLoading());
  };

  const isInventoryConfigExisted = () => {
    const url = URLS.GET_POST_ERP_INVENTORY_CONFIG;
    ServiceApi.get(url).then(({data}) => {
      setInventoryConfigExisted(!!data);
    });
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <div>
          {/* <Breadcrumb to="/productManagement/products/published-products" /> */}
          <Breadcrumb backward />
        </div>
        <div className="flex items-center gap-4">
          <Button
            size="sm"
            light
            textColor="primary"
            onClick={toggle}
            disabled={loading}
            className="shadow-card"
          >
            <span>
              <Text>Push to sell-off product</Text>
            </span>
          </Button>
          <Button
            size="sm"
            variant={"white"}
            textColor="primary"
            onClick={shareToggle}
            disabled={loading}
          >
            <Icon icon="share-nodes" className="mr-2" />
            <span>
              <Text>productManagement.products.Details.shareButton</Text>
            </span>
          </Button>
          <Button
            size="sm"
            variant={"white"}
            textColor="primary"
            onClick={printToggle}
            disabled={loading}
          >
            <Icon icon="print" className="mr-2" />
            <span>
              <Text>productManagement.publishedProduct.Details.print</Text>
            </span>
          </Button>
        </div>
      </div>
      <Tabs
        activeKey={activeKey}
        // onSave={() => {
        //   setData(baseData);
        //   setAttributeChanged(false);
        // }}
      >
        <div className="space-y-6">
          <PimContext.Provider
            value={{
              details,
              loading,
              updatePrintHistory,
              getDetails,
            }}
          >
            <Wrapper>
              <Wrapper.Body>
                <Info />
              </Wrapper.Body>
              <Wrapper.Footer className="!py-0">
                {loading ? (
                  <div className="flex w-full gap-2 p-4">
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                  </div>
                ) : (
                  <Tabs.ButtonGroup>
                    {tabs.map(e => (
                      <Tabs.Button
                        key={e.label}
                        eventKey={e.label}
                        //   disabled={loading}
                        onClick={() => setActiveKey(e.label)}
                      >
                        <Text>{e.label}</Text>
                      </Tabs.Button>
                    ))}
                  </Tabs.ButtonGroup>
                )}
              </Wrapper.Footer>
            </Wrapper>
            {tabs.map(e => (
              // <PimContext.Provider
              //   value={{
              //     details,
              //     loading,
              //   }}
              // >
              <Tabs.Item key={e.label} eventKey={e.label}>
                {createElement(e.component)}
              </Tabs.Item>
            ))}
          </PimContext.Provider>
        </div>
      </Tabs>
      <Print
        isOpen={print}
        toggle={printToggle}
        id={productId}
        getData={toggleUpdatePrintHistory}
      />
      <Share isOpen={share} toggle={shareToggle} id={productId} />
      <PushToSellOff
        isOpen={isOpen}
        toggle={toggle}
        pimProductId={productId as string}
        channelCode={params.channelCode}
        inventoryConfigExisted={inventoryConfigExisted}
      />
    </div>
  );
}
