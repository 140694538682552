import { NotificationManager } from "common/notifications";
import {
  Drawer,
  Text,
  Icon,
  Button,
  InputGroup,
  CheckBox,
  LoadingSvg,
  Select,
} from "components";
import { useConverter, useToggleState } from "hooks";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { ServiceApi, URLS } from "services";
import { PimContext } from "..";
import { types } from "constant";

type Props = {
  isOpen: boolean;
  toggle: () => void;
  item: any;
  setItem: any;
};

type Vks = {
  vk1?: number;
  vk2?: number;
};
export default function AddItem({ isOpen, toggle, item, setItem }: Props) {
  const [value, setValue] = useState(1);
  const { convertAmount } = useConverter();
  const [status, toggleStatus] = useToggleState();
  const [salePrice, setSalePrice] = useState({
    currency: "EUR",
    amount: null,
    currencySymbol: "€",
  });
  const { productId } = useParams();
  const [loading, toggleLoading] = useToggleState();
  const [isChecked, toggleCheck] = useToggleState();
  const { getPIMProduct } = useContext(PimContext);
  const [salesStatus, setSalesStatus] = useState<Vks>({});
  const [salesPersonCommission, setSalesPersonCommission] = useState({
    amount: '',
    calculationType: 0
  });

  useEffect(() => {
    if (isOpen) {
      setSalePrice({
        currency: "EUR",
        amount: null,
        currencySymbol: "€",
      });
      getOverwrittenPrices();
      if (isChecked) toggleCheck();
      setValue(1);
      setSalesPersonCommission({
        amount: '',
        calculationType: 0
      })
    }
  }, [isOpen]);

  const onSubmitHandler = () => {
    toggleLoading();
    const url = URLS.ADD_BUNDLE_ITEM(productId);
    const body = {
      pimProductBundleItemId: item.id,
      quantity: value,
      overwrittenSalePrice: isChecked
        ? {
          ...salePrice,
          amount: salePrice.amount,
        }
        : null,
      salesPersonCommission: salesPersonCommission?.amount ? salesPersonCommission : null
    };
    ServiceApi.post(url, body)
      .then(() => {
        toggleLoading();
        getPIMProduct();
        toggle();
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title",
        );
      })
      .catch(() => toggleLoading());
  };

  const getOverwrittenPrices = () => {
    toggleStatus();
    const body = {
      productId: item?.id,
      supplierId: item.supplierId,
      programId: item.supplierProgramId,
      brandId: item.brandId,
      basePrice: item.purchasePrice?.amount,
      purchasePriceType: item.purchasePriceType,
    };
    ServiceApi.post(URLS.CALC_PIM_SALE_PRICE, body)
      .then(({ data }) => {
        setSalesStatus(data);
        toggleStatus();
      })
      .catch(() => {
        toggleStatus();
      });
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header className="flex items-center justify-between text-heading-2 font-semibold">
          <span>
            <Text>productManagement.products.Details.addNewBundleItem</Text>
          </span>
          <Button
            variant={"light"}
            size="sm"
            className="flex items-center"
            onClick={toggle}
          >
            <span>
              <Text>Close</Text>
            </span>
            <Icon icon="close" className="ml-2" />
          </Button>
        </Drawer.Header>
        <Drawer.Body className="space-y-6">
          <div
            key={item.id}
            className="flex items-center justify-between rounded-lg p-2 shadow-card "
          >
            <div>
              <div className="space-y-2 text-wrap py-2">
                <h6 className="text-heading-6 font-semibold leading-6 text-gray-800">
                  {item.originalName}
                </h6>
                <div className="text-sm text-gray-500">
                  #<span className="ml-1">{item.articleNumber}</span>
                </div>
              </div>
            </div>
            {status ? (
              <LoadingSvg size="sm" />
            ) : (
              <div className="flex flex-col space-y-2 *:text-sm">
                <p>vk2 : {convertAmount(salesStatus?.vk2)}</p>
                <p>vk1 : {convertAmount(salesStatus?.vk1)}</p>
              </div>
            )}
            <div className="space-y-4">
              <Button
                variant={"danger"}
                light
                size="sm"
                onClick={() => {
                  setItem({});
                  toggle();
                }}
              >
                <Icon icon="trash" />
              </Button>
            </div>
          </div>
          <div className="grid grid-cols-2">
            <InputGroup
              label={
                <Text>productManagement.products.allProducts.quantity</Text>
              }
              value={value}
              setValue={setValue}
              type="number"
            ></InputGroup>
          </div>
          <CheckBox
            value={isChecked}
            setValue={toggleCheck}
            label="productManagement.products.allProducts.addOverwrittenSalePrice"
          />
          <div className="grid grid-cols-2 gap-4">
            <InputGroup
              label={
                <Text>
                  productManagement.products.allProducts.overwrittenSalePrice
                </Text>
              }
              value={salePrice?.amount}
              setValue={e => setSalePrice(p => ({ ...p, amount: e }))}
              disabled={!isChecked}
              type="price"
            ></InputGroup>
          </div>
          <div className="grid grid-cols-2 gap-3">
            <div className="col-span-2 lg:col-span-1">
              <InputGroup
                label="productManagement.products.Details.amount"
                value={salesPersonCommission?.amount}
                setValue={(value) => {
                  if (salesPersonCommission?.calculationType == 1 || (value >= 0 && value <= 100)) {
                    setSalesPersonCommission({ ...salesPersonCommission, amount: value })
                  }
                }}
                keyfilter="pnum"
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <Select
                label="productManagement.products.Details.type"
                placeholder="global.select"
                value={salesPersonCommission?.calculationType}
                items={types.amountCalculationType}
                setValue={(value) => setSalesPersonCommission(
                  { ...salesPersonCommission, calculationType: value, amount: '' }
                )}
              />
            </div>
          </div>
        </Drawer.Body>
        <Drawer.Footer className="flex justify-between">
          <Button type="button" variant={"light"} onClick={toggle}>
            <Text>global.buttons.cancel</Text>
          </Button>
          <Button
            type="button"
            onClick={onSubmitHandler}
            loading={loading}
            disabled={isChecked && !salePrice?.amount}
          >
            <Text>productManagement.products.allProducts.addItem</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
