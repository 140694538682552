import {
  Breadcrumb,
  Button,
  Icon,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Table,
  Text,
  WithPermission,
} from "components";
import {config} from "constant";
import {withPermission} from "hoc";
import {usePermission, useToggleState} from "hooks";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {ServiceApi, URLS} from "services";
import AddDrawwer from "./add-drawer";
import EditDrawwer from "./edit-drawer";

type UserGroupsItems = {
  id: string;
  name: string;
  description: string;
};

type ListType = {
  items?: UserGroupsItems[];
  pageNumber?: number;
  pageSize?: number;
  totalItems?: number;
  totalPages?: number;
};

function UserGroups() {
  const controller = new AbortController();
  const [data, setData] = useState<ListType>();
  const [loadingList, setLoadingList] = useState(true);
  const [selected, setSelected] = useState("");
  const [isOpenDrawerAdd, toggleDrawerAdd] = useToggleState(false);
  const [isOpenDrawerEdit, toggleDrawerEdit] = useToggleState(false);
  const [searchParams] = useSearchParams();
  //original filter data for reset
  const originalFilterData: any = {
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  };

  const [params, setParams] = useSearchParams();

  const handleSetParams = (key: string) => {
    return (value: any) => {
      setParams(prev => {
        value !== null && value !== ""
          ? key === "pageNumber" && value === 1
            ? prev.delete(key)
            : prev.set(key, value)
          : prev.delete(key);
        key !== "pageNumber" && prev.delete("pageNumber");
        return prev;
      });
    };
  };

  const getData = () => {
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url = URLS.USER_GROUP_URL;
    const config = {params};
    setLoadingList(true);
    ServiceApi.get(url, {...config, signal: controller.signal})
      .then(res => {
        setData(res?.data);
      })
      .finally(() => {
        setLoadingList(false);
      });
  };
  useEffect(() => {
    getData();
    return () => {
      controller.abort();
    };
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDetailPermission = usePermission("AS_GetUserGroup");

  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
        <WithPermission permissions={["AS_CreateUserGroup"]}>
          {loadingList ? (
            <Skeleton.Button />
          ) : (
            <Button
              type="button"
              onClick={() => {
                toggleDrawerAdd();
              }}
            >
              <Icon className="mr-2" icon={"plus"} />
              <Text>configurations.userManagment.userGroups.addUserGroup</Text>
            </Button>
          )}
        </WithPermission>
      </div>
      <SearchBox
        value={params?.get("keyword") || ""}
        onSubmit={handleSetParams("keyword")}
        disabled={loadingList}
        totalItems={data?.totalItems}
        onReload={getData}
        loading={loadingList}
      />
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data?.items?.length !== 0 ? (
            <>
              <Table>
                {data?.items?.map(item => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <button
                          type="button"
                          onClick={() => {
                            if (!getDetailPermission) return;
                            setSelected(item.id);
                            toggleDrawerEdit();
                          }}
                          className="flex-center inline-flex w-fit gap-2"
                        >
                          <div>
                            <h6 className="text-heading-6 font-semibold text-gray-800">
                              {item.name}
                            </h6>
                          </div>
                        </button>
                      </td>
                      <td>
                        <div className="text-wrap text-left text-body-base font-normal leading-6">
                          <span className="text-gray-600">
                            {item.description}
                          </span>
                        </div>
                      </td>
                      <td>
                        <WithPermission permissions={["AS_GetUserGroup"]}>
                          <Button
                            onClick={() => {
                              setSelected(item.id);
                              toggleDrawerEdit();
                            }}
                            variant="light"
                            size="sm"
                          >
                            <Text>global.buttons.details</Text>
                          </Button>
                        </WithPermission>
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages}
                pageNumber={
                  params?.get("pageNumber")
                    ? parseInt(params?.get("pageNumber") as string)
                    : 1
                }
                pageSize={
                  params?.get("pageSize")
                    ? parseInt(params?.get("pageSize") as string)
                    : config.pageSize
                }
                setActivePage={handleSetParams("pageNumber")}
                onPageSizeChange={(e: any) =>
                  setParams(p => ({...p, pageSize: e}))
                }
              />
            </>
          ) : (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
            </div>
          )}
        </>
      )}
      <AddDrawwer
        isOpen={isOpenDrawerAdd}
        toggle={toggleDrawerAdd}
        getData={getData}
      />
      <EditDrawwer
        isOpen={isOpenDrawerEdit}
        toggle={toggleDrawerEdit}
        selected={selected}
        updateList={getData}
      />
    </div>
  );
}
export default withPermission(UserGroups, ["AS_GetUserGroup"]);
