import Stores from "./stores";
import LegalEntities from "./legal-entities";
import Profile from "./profile";
import Employees from "./employees";

const Company = [
  {
    path: "company/profile",
    children: [...Profile],
  },
  {
    path: "company/employees",
    children: [...Employees],
  },
  {
    path: "company/stores",
    children: [...Stores],
  },
  {
    path: "company/legal-entities",
    children: [...LegalEntities],
  }
];

export default Company;
