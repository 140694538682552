import {NotificationManager} from "common/notifications";
import {Button, Footer, Skeleton, Table, Text, Toggler} from "components";
import {Fragment, useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {ServiceApi, URLS} from "services";
import {UserContext} from "..";
import {useSelector} from "hooks";

export default function SalesChannels() {
  const channels = useSelector(s => s.channels);
  const {
    userData,
    getData,
    salesChannelsData,
    setSalesChannelsData,
    setBaseSalesChannelsData,
    isChangedSalesChannels,
    UPDATE_PERMISSIONS,
  } = useContext<any>(UserContext);
  const [salesChannelList, setSalesChannelList] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const getChannels = () => {
    let newList = JSON.parse(JSON.stringify(channels));
    newList.forEach((item: any) => {
      item["isActive"] = false;
      userData?.availableSaleChannels?.forEach((i: any) => {
        if (item.saleChannelId === i.saleChannelId) {
          item["isActive"] = true;
        }
      });
    });
    setSalesChannelList(newList);

    let availableSaleChannels: any = [];
    userData?.availableSaleChannels?.forEach((i: any) => {
      availableSaleChannels.push(i.saleChannelId);
    });
    setBaseSalesChannelsData({
      saleChannelIds: availableSaleChannels?.sort(),
      defaultSaleChannel: userData?.defaultSaleChannel,
    });
    setSalesChannelsData((p: any) => {
      return {
        ...p,
        saleChannelIds: availableSaleChannels?.sort(),
        defaultSaleChannel: userData?.defaultSaleChannel,
      };
    });
  };

  const updateSalesChannels = () => {
    setSubmitLoading(true);
    const url = URLS.SET_USER_SALESCHANNELS(userData.userId);
    const body = {
      saleChannelIds: salesChannelsData?.saleChannelIds,
      defaultSaleChannelId: salesChannelsData?.defaultSaleChannel,
    };
    ServiceApi.patch(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
        getData();
      })
      .finally(() => {
        setSubmitLoading(false);
        setLoadingList(false);
        setSalesChannelsData([]);
      });
  };

  useEffect(getChannels, [userData, channels]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClickButton = () => {
    updateSalesChannels();
  };

  const onActivateChannel = (value: boolean, item: any) => {
    let newList: any = JSON.parse(
      JSON.stringify(salesChannelsData?.saleChannelIds),
    );
    if (value) {
      newList.push(item.saleChannelId);
      item.isActive = value;
    } else {
      if (
        newList.length > 1 &&
        item.saleChannelId !== salesChannelsData?.defaultSaleChannel
      ) {
        newList = [];
        salesChannelsData?.saleChannelIds?.forEach((i: any) => {
          if (i !== item.saleChannelId) {
            newList.push(i);
            item.isActive = value;
          }
        });
      }
    }
    setSalesChannelsData((p: any) => {
      return {
        ...p,
        saleChannelIds: newList?.sort(),
        defaultSaleChannel:
          newList.length === 1 ? newList[0] : p.defaultSaleChannel,
      };
    });
  };
  return (
    <Fragment>
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <Table>
          {salesChannelList?.map((item: any) => {
            return (
              <tr key={item.saleChannelId}>
                <td>{item?.title}</td>
                <td></td>
                <td></td>
                <td className="w-[10rem]">
                  <Toggler
                    label={"configurations.userManagment.userList.active"}
                    value={item?.isActive ? true : false}
                    size="sm"
                    disabled={!UPDATE_PERMISSIONS}
                    setValue={value => onActivateChannel(value, item)}
                  />
                </td>
                <td>
                  <Toggler
                    label={"configurations.userManagment.userList.setDefault"}
                    value={
                      item.saleChannelId ===
                      salesChannelsData?.defaultSaleChannel
                        ? true
                        : false
                    }
                    disabled={!item.isActive || !UPDATE_PERMISSIONS}
                    size="sm"
                    setValue={value => {
                      if (value)
                        setSalesChannelsData((p: any) => {
                          return {
                            ...p,
                            defaultSaleChannel: value
                              ? item.saleChannelId
                              : null,
                          };
                        });
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </Table>
      )}
      {UPDATE_PERMISSIONS && (
        <Footer show={!isChangedSalesChannels}>
          <Button as={Link} to={-1} variant="white">
            <Text>global.buttons.discard</Text>
          </Button>
          <Button
            type="button"
            variant="primary"
            disabled={!isChangedSalesChannels}
            loading={submitLoading}
            onClick={onClickButton}
          >
            <Text>global.buttons.saveChanges</Text>
          </Button>
        </Footer>
      )}
    </Fragment>
  );
}
