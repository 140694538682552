const DATA_STORAGE = {
  GET_PERSONAL_AZURE_BLOB_CONFIG: "/assetservice/api/personalazureblobconfigs",
  POST_PERSONAL_AZURE_BLOB_CONFIG: "/assetservice/api/personalazureblobconfigs",
  DELETE_PERSONAL_AZURE_BLOB_CONFIG:
    "/assetservice/api/personalazureblobconfigs",

  GET_ELO_CONFIGS: "/assetservice/api/eloconfigs",
  POST_ELO_CONFIGS: "/assetservice/api/eloconfigs",
  PUT_ELO_CONFIGS: (id) => `/assetservice/api/eloconfigs/${id}`,
  DELETE_ELO_CONFIGS: (id) => `/assetservice/api/eloconfigs/${id}`,
  GET_POST_STORAGE_CONFIGS: `/assetservice/api/storageConfigs`,
};

export default DATA_STORAGE;
