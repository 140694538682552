import { NotificationManager } from "common/notifications";
import {
  Button,
  Form,
  Icon,
  InputGroup,
  Modal,
  SelectLang,
  Text,
} from "components";
import { flagCountry, rules } from "constant";
import { useSelector, useTitleTranslations, useToggleState } from "hooks";
import { useEffect, useRef, useState } from "react";
import { ServiceApi, URLS } from "services";

type Props = {
  isOpen: boolean;
  toggle: any;
  getCategories: any;
  parentId?: string;
};

const AddCategory = ({ isOpen, toggle, getCategories, parentId = "" }: Props) => {
  const [loadingInput, setLoading] = useToggleState();
  const formRef = useRef<any>();
  const [data, setData] = useState<any>({
    code: "",
    parentId: "",
    translates: [
      { language: "de", shortDescription: "" },
      { language: "en", shortDescription: "" },
    ],
  });
  const [lang, setLang] = useState<string>("de");
  const companySetting = useSelector(s => s.companySetting);
  const [langItems, setLangItems] = useState([]);
  const { checkTranslations } = useTitleTranslations();

  useEffect(() => {
    setLang(companySetting.defaultContentLanguage);
  }, [companySetting.defaultContentLanguage]);

  useEffect(() => {
    let arr: any = [];
    companySetting?.contentLanguages?.forEach((item: any) => {
      arr.push({ id: item, name: item });
    });
    setLangItems(arr);
  }, [companySetting]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isOpen) {
      setData({
        code: "",
        parentId: "",
        translates: [
          { language: "de", shortDescription: "" },
          { language: "en", shortDescription: "" },
        ],
      });
    }
  }, [isOpen]);

  const onChangeLanguage = (lang: any) => {
    setLang(lang);
  };

  const onChangeHandler = (value: any, key: string) => {
    const isAvailable = data?.translates?.filter(
      (tr: any) => tr.language === lang,
    );
    let clone = [...data?.translates];
    if (isAvailable.length === 0) {
      clone.push({
        [key]: value,
        language: lang,
        description: "",
      });
    } else {
      data?.translates?.map((tr: any, index: number) => {
        if (tr.language?.toLowerCase() === lang) {
          clone[index][key] = value;
        }
      });
    }
    setData({ ...data, translates: clone });
  };

  const onChangeCodeHandler = (value: any) => {
    setData((p: any) => ({ ...p, code: value }));
  };

  const findCategoryByLang = () => {
    const obj = data?.translates?.filter(
      (item: any) => item?.language === lang,
    )[0];
    return obj;
  };

  const submit = (e?: React.FormEvent) => {
    formRef.current.submitHandler(e, { ...data });
  };

  const onSubmitHandler = (data: any) => {
    const validTranslates = checkTranslations(data.translates);
    if (validTranslates) {
      setLoading();
      const url = URLS.ADD_CATEGORY;
      const body = { ...data, translates: validTranslates, parentId };
      ServiceApi.post(url, body)
        .then(() => {
          NotificationManager.success(
            "global.toast.add-msg",
            "global.toast.add-title",
          );
          setLoading();
          getCategories();
          toggle();
        })
        .catch(() => setLoading());
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      width="w-[400px]"
      modalClassName="z-40"
    >
      <Modal.Header className="flex text-heading-2 font-semibold">
        <Text>
          {parentId
            ? "productManagement.masterData.productCategory.addSubCategory"
            : "productManagement.masterData.productCategory.addCategory"}
        </Text>
        <div className="!absolute right-4 top-[1.4rem]">
          <SelectLang
            value={lang}
            items={langItems}
            onChangeLanguage={onChangeLanguage}
          />
        </div>
      </Modal.Header>
      <Modal.Body className="overflow-auto">
        <div className="col-span-2">
          <Form
            ref={formRef}
            onSubmit={onSubmitHandler}
            id={`add-modal-category-${parentId}`}
            className="space-y-4"
          >
            <InputGroup
              ariaLabel="cy-product-category-code"
              value={data?.code}
              setValue={e => onChangeCodeHandler(e)}
              label="productManagement.masterData.productCategory.categoryCode"
              rules={rules.required}
              disabled={loadingInput}
              readOnly={loadingInput}
            />
            <InputGroup
              ariaLabel="cy-product-category-name"
              value={data?.translates && findCategoryByLang()?.labelTitle}
              setValue={e => onChangeHandler(e, "labelTitle")}
              label="productManagement.masterData.productCategory.categoryName"
              flag={flagCountry[lang]}
              rules={rules.required}
              disabled={loadingInput}
              readOnly={loadingInput}
            />
            <InputGroup
              ariaLabel="cy-product-category-description"
              as={"short-textarea"}
              flag={flagCountry[lang]}
              value={data?.translates && findCategoryByLang()?.description}
              setValue={e => onChangeHandler(e, "description")}
              label="productManagement.masterData.productCategory.shortDescription"
              disabled={loadingInput}
              readOnly={loadingInput}
            />
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-between">
        <Button
          aria-label="cy-category-cancel"
          onClick={() => {
            toggle();
          }}
          variant="white"
        >
          <Icon className="mr-2" icon={"times"} />
          <Text>global.buttons.cancel</Text>
        </Button>
        <Button
          aria-label="cy-product-submit"
          type="button"
          size="sm"
          onClick={submit}
          loading={loadingInput}
        >
          <Text>global.buttons.submit</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCategory;
