import CalculationLevel from "pages/price-engine/calculation-level";

const CalculationLevelRoutes = [
  {
    path: "",
    element: <CalculationLevel />,
  },
];

export default CalculationLevelRoutes;
