import PublishedProducts from "pages/product-managment/published-products";
import PublishedProductsDetails from "pages/product-managment/published-products/details";
const Published = [
  {
    path: "",
    element: <PublishedProducts />,
  },
  {
    path: ":productId",
    element: <PublishedProductsDetails />,
  },
];

export default Published;
