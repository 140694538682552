import {Icon, Text} from "components";
import {useEffect, useState} from "react";
import {unset} from "lodash";
import {useConverter} from "hooks";
import {purchaseTemplatesSteps, salesTemplatesSteps} from "constant";

type FullPermissionSalesPriceProps = {
  data: any;
  type: "sales" | "purchase";
};

export default function WithoutPermissionPrice({
  data,
  type,
}: FullPermissionSalesPriceProps) {
  const {convertAmount} = useConverter();
  const [customData, SetCustomData] = useState<any>({});
  const [simulation, setSimulation] = useState<any>([]);
  const stepArray = type === "sales" ? [0, 1, 2, 3] : [0, 1, 2];

  useEffect(() => {
    const clone = {...data};
    unset(clone, "simulationResult");
    SetCustomData(clone);
    setSimulation(data?.simulationItems);
  }, [data]);

  return (
    <div className="space-y-4 px-4">
      {Object.keys(customData).some((key: string) => customData[key]) ? (
        <div className="relative space-y-4 border-l-4 border-gray-300 p-4 pt-0 ">
          {stepArray?.map((steps: any, index: number) => {
            let indexStep = simulation?.filter(
              (item: any) => item.step === index,
            );
            return (
              <>
                {indexStep.length > 0 && (
                  <div className="space-y-4">
                    <span className="absolute left-[-10px] h-4 w-4 rounded bg-primary"></span>
                    <div className="flex flex-col space-y-2">
                      <span className="text-primary">
                        {type === "sales" ? (
                          <Text>{salesTemplatesSteps[index].label}</Text>
                        ) : (
                          <Text>{purchaseTemplatesSteps[index].label}</Text>
                        )}
                      </span>
                      {index + 1 === 5 && (
                        <span>
                          <Text>
                            productManagement.products.Details.salesPrice
                          </Text>{" "}
                          (VK2):{" "}
                          <span className="ml-4 text-heading-1 font-normal text-gray-600 line-through	">
                            {convertAmount(data?.simulationResult?.salePrice2)}
                          </span>
                        </span>
                      )}
                    </div>
                  </div>
                )}
                {indexStep.length > 0 && (
                  <div className="flex flex-col gap-4 rounded-md bg-gray-100 p-4">
                    {indexStep?.map((step: any, index: number) => {
                      return (
                        <div
                          className={`flex flex-col gap-2 ${
                            index > 0 && "border-t-2 border-t-gray-300 pt-2"
                          } *:text-body-2`}
                        >
                          <span className="font-medium">{step?.title}</span>
                          <span className="font-normal">
                            {step?.operationText}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            );
          })}
        </div>
      ) : (
        <div className="flex flex-col gap-8 border-b p-4 pb-5">
          <div className="flex min-h-14 items-center gap-2 rounded-lg bg-gray-100 p-4">
            <Icon icon="circle-info" className="text-warning-active" />
            <span className="text-body-2 font-medium text-gray-600">
              <Text>
                productManagement.products.Details.NoAssignedPriceTemplate
              </Text>
            </span>
          </div>
          <span>
            <Text>productManagement.products.Details.salesPrice</Text> (VK2):{" "}
            <span className="ml-4 text-heading-1 font-normal text-gray-600 line-through	">
              {convertAmount(data?.simulationResult?.salePrice2)}
            </span>
          </span>
        </div>
      )}
    </div>
  );
}
