import {
  Drawer,
  Text,
  Button,
  Icon,
  Skeleton,
  Tabs,
} from "components";
import { CheckListCompletenessStatus } from "enum";
import { useDataState, useToggleState } from "hooks";
import { createContext, createElement, useEffect, useMemo, useState } from "react";
import { ServiceApi, URLS } from "services";
import Checklist from "./tabs/checklist";
import Conversations from "./tabs/conversations";

type ItemType = {
  checkListId: string;
  title: string;
  status: number;
};

type Data = {
  checkListHistories?: ItemType[];
  id?: string;
}

type Props = {
  isOpen: boolean;
  toggle: () => void;
  productId: string;
  updatePIMData: (
    completed: number,
    inProcess: number
  ) => void;
};

export const CompletenessContext = createContext<any>({});

export default function Completeness({ isOpen, toggle, productId, updatePIMData }: Props) {
  const controller = new AbortController();
  const [loading, toggleLoading] = useToggleState();
  const [data, setData, setBaseData, isChanged, baseData] = useDataState<Data>({});
  const [loadingConversation, toggleLoadingConversation] = useToggleState();
  const [conversationData, setConversationData] = useState<ItemType[]>([]);
  const [conversationParams, setConversationParams] = useState({});
  const [percentages, setPercentages] = useState({
    completed: 0,
    inProcess: 0,
    hasProblem: 0,
    notStarted: 0
  });
  const background = `conic-gradient(
  #B5B5C3 0%, #B5B5C3 var(--notStarted), 
  #50CD89 var(--notStarted), #50CD89 var(--completed), 
  #D9214E var(--completed), #D9214E var(--hasProblem), 
  #F6C000 var(--hasProblem))`;
  const style = useMemo(() => {
    return {
      background, "--notStarted": `${percentages.notStarted}%`,
      "--completed": `${percentages.completed + percentages.notStarted}%`,
      "--hasProblem": `${percentages.hasProblem + percentages.notStarted + percentages.completed}%`,
    }
  }, [percentages]);

  const [activeKey, setActiveKey] = useState<string>();
  const tabs = [
    {
      label: "productManagement.products.allProducts.checklistTab",
      component: Checklist,
      permission: ["PS_GetPimProductMedia"],
      amount: data.checkListHistories?.length
    },
    {
      label: "productManagement.products.allProducts.conversationTab",
      component: Conversations,
      amount: conversationData.length
    },
  ]

  const getChecklistData = () => {
    toggleLoading();
    const url = URLS.CHECKLIST_COMPLETATION_STATE_URL(productId);
    ServiceApi.get(url)
      .then(({ data }) => {
        setBaseData(data);
      })
      .finally(() => {
        toggleLoading();
      });
  };

  const getConversationData = () => {
    toggleLoadingConversation();
    const config = { params: { ...conversationParams, objectId: productId } };
    const url = URLS.PIM_CONVERSATIONS_BY_OBJECT_URL;
    ServiceApi.get(url, { ...config, signal: controller.signal })
      .then(({ data }) => {
        setConversationData(data);
      })
      .finally(() => {
        toggleLoadingConversation();
      });
  };

  const getPercentage = (value: number) => {
    const amountOffAll = data.checkListHistories?.length || 0;
    return (value && amountOffAll) ?
      Math.min(Math.round(100 * (value / amountOffAll)), 100) : 0;
  }

  const calculateStatistics = (data: Data) => {
    const completed = getPercentage(data.checkListHistories?.filter(item => item.status === CheckListCompletenessStatus.Completed).length || 0);
    const inProcess = getPercentage(data.checkListHistories?.filter(item => item.status === CheckListCompletenessStatus.InProcess).length || 0);
    const hasProblem = getPercentage(data.checkListHistories?.filter(item => item.status === CheckListCompletenessStatus.HasAProblem).length || 0);
    const notStarted = getPercentage(data.checkListHistories?.filter(item => item.status === CheckListCompletenessStatus.NotStarted).length || 0);
    return { completed, inProcess, hasProblem, notStarted };
  }

  useEffect(
    () => {
      if (isOpen && !!productId)
        getChecklistData();
      setActiveKey(tabs[0].label);
    }, [productId, isOpen])

  useEffect(
    () => {
      setPercentages(calculateStatistics(baseData));
    }, [data]);

  useEffect(
    () => {
      if (!!productId)
        getConversationData();
    }, [conversationParams, productId]);

  return (
    <Drawer isOpen={isOpen} toggle={toggle} size='md'>
      <Drawer.Menu>
        <Drawer.Header className="flex items-center justify-between text-heading-2 font-semibold">
          <span>
            <Text>
              productManagement.products.allProducts.completeness
            </Text>
          </span>
          <Button
            variant={"light"}
            size="sm"
            className="flex items-center"
            onClick={toggle}
          >
            <span>
              <Text>global.buttons.close</Text>
            </span>
            <Icon icon="close" className="ml-2" />
          </Button>
        </Drawer.Header>
        <Drawer.Body className="space-y-6 py-6">
          <div className="space-y-6">
            <div
              style={loading ? { background: '#B5B5C3' } : style}
              className={` m-auto flex-center w-[118px] h-[118px] rounded-full bg-gradient-to-t`}
            >
              <div className="flex-center w-[87px] h-[87px] rounded-full bg-white font-medium text-gray-800 text-body-4">
                <Text>productManagement.products.allProducts.completeness</Text>
              </div>
            </div>
            <div className="flex text-body-2 divide-x-2 [&>div]:w-full [&>div]:space-y-2 text-center text-gray-700">
              <div>
                <p><Text>global.types.checkListCompleteness.notStarted</Text></p>
                <p className="text-[#888]">{loading ? '--' : percentages.notStarted}%</p>
              </div>
              <div>
                <p><Text>global.types.checkListCompleteness.inProcess</Text></p>
                <p className="text-warning">{loading ? '--' : percentages.inProcess}%</p>
              </div>
              <div>
                <p><Text>global.types.checkListCompleteness.hasAProblem</Text></p>
                <p className="text-danger">{loading ? '--' : percentages.hasProblem}%</p>
              </div>
              <div>
                <p><Text>global.types.checkListCompleteness.completed</Text></p>
                <p className="text-success">{loading ? '--' : percentages.completed}%</p>
              </div>
            </div>
          </div>
          <Tabs
            activeKey={activeKey}
            checkSave={isChanged}
            onSave={() => {
              setData(baseData);
            }}
          >
            <div className="space-y-6 flex-1 flex flex-col">
              <Tabs.ButtonGroup className="justify-around border-b border-gray-200">
                {tabs.map(e => (
                  <Tabs.Button
                    key={e.label}
                    eventKey={e.label}
                    disabled={loading}
                    onClick={() => setActiveKey(e.label)}
                  >
                    <Text>{e.label}</Text> ({e.amount})
                  </Tabs.Button>
                ))}
              </Tabs.ButtonGroup>
              {tabs.map(e => (
                <CompletenessContext.Provider
                  value={{
                    getChecklistData,
                    data,
                    setData,
                    loading,
                    percentages,
                    isChanged,
                    toggle,
                    updatePIMData: () => updatePIMData(calculateStatistics(data).completed, calculateStatistics(data).inProcess),
                    baseData,
                    isOpen,
                    checkList: data?.checkListHistories,
                    completionStateId: data.id,
                    productId,
                    conversationData,
                    loadingConversation,
                    getConversationData,
                    conversationParams,
                    setConversationParams
                  }
                  }
                >
                  <Tabs.Item key={e.label} eventKey={e.label}>
                    {createElement(e.component)}
                  </Tabs.Item>
                </CompletenessContext.Provider>
              ))}
            </div>
          </Tabs>
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer >
  );
}
