import { useContext } from "react";
import PriceCalculationTab from "compositions/price-calculation-units/price-calculation-tab";
import { calculationLevelEnum } from "constant";
import { SalesChannelcontext } from ".";

export default function PriceCalculation() {
  const { data } = useContext<any>(SalesChannelcontext);

  return (
    <PriceCalculationTab
      refId={data?.saleChannelId}
      level={calculationLevelEnum.channel}
      refTitle={data?.title}
      refCode={data?.code}
    />
  );
}
