import {
  Badge,
  Button,
  CheckBox,
  Drawer,
  Icon,
  Image,
  List,
  NoItems,
  Skeleton,
  Text,
} from "components";
import {useConverter} from "hooks";
import {Fragment, useContext, useEffect, useState} from "react";
import {ServiceApi} from "services";
import {Basket} from "types";
import {SelectFromBasketContext} from ".";

export default function Products() {
  const {
    goBack,
    basket,
    deleteAfterImport,
    toggleDeleteAfterImport,
    toggleBasketSubmitted,
  } = useContext(SelectFromBasketContext);
  const {convertDate, convertAmount} = useConverter();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<Basket.LineItem[]>([]);
  const getProducts = () => {
    if (!basket) return;
    setLoading(true);
    const url = `/productservice/api/iwofurnconfigurations/iwofurn-basket-lines/${basket?.internalTag}`;
    ServiceApi.post(url)
      .then(({data}) => {
        setProducts(data.lineItems);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(getProducts, [basket]);
  return (
    <Fragment>
      <Drawer.Header className="space-y-4 border-b-0">
        <fieldset className="text-start">
          <legend>
            <Text>productManagement.products.allProducts.basketInfo</Text>
          </legend>
        </fieldset>
        <table className="w-full text-body-2 [&_td]:py-1">
          <tbody>
            <tr>
              <td className="text-start text-gray-600">
                <Icon icon="bags-shopping" />{" "}
                <Text>
                  productManagement.products.allProducts.basketInternalTag
                </Text>
                :
              </td>
              <td className="text-end text-gray-800">#{basket?.internalTag}</td>
            </tr>
            <tr>
              <td className="text-start text-gray-600">
                <Icon icon="tag" />{" "}
                <Text>productManagement.products.allProducts.basketTag</Text>:
              </td>
              <td className="text-end text-gray-800">#{basket?.tag}</td>
            </tr>
            <tr>
              <td className="text-start text-gray-600">
                <Icon icon="calendar" />{" "}
                <Text>productManagement.products.allProducts.createdAt</Text>:
              </td>
              <td className="text-end text-gray-800">
                {convertDate(basket?.stamp)}
              </td>
            </tr>
            <tr>
              <td className="text-start text-gray-600">
                <Icon icon="user" />{" "}
                <Text>productManagement.products.allProducts.senderName</Text>:
              </td>
              <td className="text-end text-gray-800">
                {basket?.senderName} (iLN:{basket?.iln})
              </td>
            </tr>
            <tr>
              <td className="text-start text-gray-600">
                <Icon icon="dollar" />{" "}
                <Text>
                  productManagement.products.allProducts.iwofurnBasketPrice
                </Text>
                :
              </td>
              <td className="text-end text-gray-800">
                <Badge variant="primary">{convertAmount(basket?.price)}</Badge>
              </td>
            </tr>
            <tr>
              <td className="text-start text-gray-600">
                <Icon icon="dollar" />{" "}
                <Text>
                  productManagement.products.allProducts.iwofurnBasketPurchasePrice
                </Text>
                :
              </td>
              <td className="text-end text-gray-800">
                <Badge variant="primary">
                  {convertAmount(basket?.purchasePrice)}
                </Badge>
              </td>
            </tr>
          </tbody>
        </table>
      </Drawer.Header>
      <Drawer.Body className="space-y-4">
        <fieldset className="text-start">
          <legend>
            <Text>productManagement.products.allProducts.products</Text>
          </legend>
        </fieldset>
        <List>
          {loading ? (
            [...Array(10).keys()].map(key => <Skeleton.Input key={key} />)
          ) : !products?.length ? (
            <NoItems />
          ) : (
            products.map(e => (
              <List.Item
                key={e.lineName + e.articleNumber}
                className="flex items-center gap-4"
              >
                <Image
                  src={undefined}
                  alt={e.lineName}
                  className="h-20 w-32 rounded"
                  imageClassName="object-cover"
                />
                <div className="flex-1 space-y-2 [&>*]:block [&>*]:w-full [&>*]:truncate">
                  <h6 className="block w-full truncate text-heading-6 font-semibold text-gray-800">
                    {e.lineName}
                  </h6>
                  <p className="text-body-2 text-gray-500">
                    <span className="font-medium">
                      <Text>
                        productManagement.products.allProducts.articleNumber
                      </Text>
                      :
                    </span>{" "}
                    #{e.articleNumber}
                  </p>
                  <p className="text-body-2 text-gray-500">
                    <span className="font-medium">
                      <Text>
                        productManagement.products.allProducts.program
                      </Text>
                      :
                    </span>{" "}
                    {e.program}
                  </p>
                  <p className="text-body-2 text-gray-500">
                    <span className="font-medium">
                      <Text>
                        productManagement.products.allProducts.supplier
                      </Text>
                      :
                    </span>{" "}
                    {e.supplier}
                  </p>
                  <p className="text-body-2 text-gray-500">
                    <span className="font-medium">
                      <Text>
                        productManagement.products.allProducts.supplierILN
                      </Text>
                      :
                    </span>{" "}
                    {e.supplierIln}
                  </p>
                </div>
                <p className="px-4 text-body-2 text-gray-600">x{e.quantity}</p>
                <p className="px-4 text-heading-6 leading-6 text-gray-700">
                  <Text>productManagement.products.allProducts.price</Text>:{" "}
                  {convertAmount(e.price?.amount)}
                  <br />
                  <Text>
                    productManagement.products.allProducts.purchasePrice
                  </Text>
                  : {convertAmount(e.purchasePrice?.amount)}
                </p>
                {/* <div className="px-4">
                  <Badge variant="success">
                    <Text>productManagement.products.allProducts.active</Text>
                  </Badge>
                </div> */}
              </List.Item>
            ))
          )}
        </List>
      </Drawer.Body>
      <Drawer.Footer className="flex items-center gap-6">
        <div className="flex-1">
          <CheckBox
            label="productManagement.products.allProducts.removeBasketAfterImport"
            value={deleteAfterImport}
            setValue={toggleDeleteAfterImport}
          />
        </div>
        <Button type="button" variant="light" size="sm" onClick={goBack}>
          <Text>productManagement.products.allProducts.backBtn</Text>
        </Button>
        <Button
          type="button"
          size="sm"
          variant="primary"
          onClick={toggleBasketSubmitted}
        >
          <Text>productManagement.products.allProducts.importBasket</Text>
        </Button>
      </Drawer.Footer>
    </Fragment>
  );
}
