import {
  Drawer,
  Text,
  Button,
  Icon,
  Skeleton,
  NoItems,
  SearchBox,
} from "components";
import {useConverter, useToggleState} from "hooks";
import {isEmpty} from "lodash";
import {useEffect, useState} from "react";
import {ServiceApi, URLS} from "services";
import AddItem from "./add-item";

type Props = {
  isOpen: boolean;
  toggle: () => void;
  ids: string[];
};
const originalFilterData = {
  productId: null,
  categoryId: null,
  keyword: "",
  language: null,
  pageNumber: "1",
  pageSize: 10,
  pimProductFamilyId: null,
  priceFrom: null,
  priceTo: null,
  programId: null,
  searchFields: "",
  sortType: null,
  supplierId: null,
  brandId: null,
  title: null,
  version: null,
  pimProductType: 0,
};

export default function AddItemDrawer({isOpen, toggle, ids}: Props) {
  const controller = new AbortController();
  const [keyword, setKeyword] = useState("");
  const [params, setParams] = useState<any>({});
  const [loadingList, setLoadingList] = useToggleState();
  const [isOpenItem, toggleItem] = useToggleState();
  const [data, setData] = useState<any>();
  const [selectedProduct, setSelectedProduct] = useState<any>({});
  const {convertAmount} = useConverter();
  useEffect(() => {
    if (isOpen) setParams(originalFilterData);
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = () => {
    const keys = Object.keys(params);
    if (keys.length) {
      const url = URLS.POST_SEARCH_LIST_PRODUCTS;
      const config = params;
      setLoadingList();
      ServiceApi.post(url, {...config, signal: controller.signal})
        .then(({data}) => {
          let newData = data;
          newData?.searchResult?.forEach((item: any) => {
            newData?.pimProductFamily?.forEach((productFamily: any) => {
              if (productFamily.value === item.pimProductFamilyId) {
                item["productFamily"] = productFamily.title;
              }
            });
            newData?.brand?.forEach((brand: any) => {
              if (brand.value === item.brandId) {
                item["brandName"] = brand.title;
              }
            });
            newData?.supplier?.forEach((supplier: any) => {
              if (supplier.value === item.supplierId) {
                item["supplierName"] = supplier.title;
              }
            });
          });

          setData(newData);
        })
        .catch(() => {
          setData([]);
        })
        .finally(() => {
          setLoadingList();
        });
    }
  };

  useEffect(() => {
    !isEmpty(params) && getData();
    return () => {
      controller.abort();
    };
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSetFilterData = (key: string) => {
    return (value: any) => {
      setParams((p: any) => ({
        ...p,
        filter: true,
        pageNumber: 1,
        [key]: value,
      }));
    };
  };

  return (
    <>
      <Drawer isOpen={isOpen} toggle={toggle}>
        <Drawer.Menu>
          <Drawer.Header className="flex items-center justify-between text-heading-2 font-semibold">
            <span>
              <Text>
                productManagement.products.allProducts.selectPimProduct
              </Text>
            </span>
            <Button
              variant={"light"}
              size="sm"
              className="flex items-center"
              onClick={toggle}
            >
              <span>
                <Text>global.buttons.close</Text>
              </span>
              <Icon icon="close" className="ml-2" />
            </Button>
          </Drawer.Header>
          <Drawer.Body className="space-y-4">
            <SearchBox
              variant="gray"
              value={keyword}
              onSubmit={handleSetFilterData("searchFields")}
              disabled={loadingList}
              totalItems={data?.totalItems}
            />
            {loadingList ? (
              [1, 2, 3, 4, 5, 6, 7].map(() => <Skeleton.List />)
            ) : (
              <>
                {data?.searchResult && data?.searchResult?.length !== 0 ? (
                  <>
                    <div className="space-y-4">
                      {data?.searchResult?.map((item: any) => {
                        return (
                          <div
                            key={item.id}
                            className={`flex items-center justify-between rounded-lg p-2 shadow-card hover:bg-primary-light ${ids?.includes(item.id) ? "pointer-events-none cursor-not-allowed bg-gray-100 *:text-gray-400" : "cursor-pointer"}`}
                            onClick={() => {
                              setSelectedProduct(item);
                              toggleItem();
                            }}
                          >
                            <div>
                              <div className="space-y-2 text-wrap py-2">
                                <h6
                                  className={`text-heading-6 font-semibold leading-6 ${ids?.includes(item.id) ? "text-gray-400" : "text-gray-800"}`}
                                >
                                  {item.originalName}
                                </h6>
                                <div className="text-sm text-gray-500">
                                  #
                                  <span className="ml-1">
                                    {item.articleNumber}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="space-y-4">
                              <div className="flex flex-col items-center space-y-2">
                                {/* <h6
                                  className={`text-heading-6 font-normal ${ids?.includes(item.id) ? "text-gray-400" : "text-gray-700"}`}
                                >
                                  {convertAmount(item?.price?.amount)}
                                </h6> */}
                                {ids?.includes(item.id) && (
                                  <span className="text-body-3 text-warning-active">
                                    (Already selected)
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <div className="flex w-full flex-col items-center space-y-4">
                    <NoItems />
                  </div>
                )}
              </>
            )}
          </Drawer.Body>
        </Drawer.Menu>
      </Drawer>
      <AddItem
        isOpen={isOpenItem}
        toggle={toggleItem}
        item={selectedProduct}
        setItem={setSelectedProduct}
      />
    </>
  );
}
