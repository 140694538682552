import {InteractionType} from "@azure/msal-browser";
import {MsalAuthenticationTemplate} from "@azure/msal-react";
import {NotificationContainer} from "common/notifications";
import {Toaster} from "components";
import {useRoutes} from "react-router";
import {loginRequest} from "./constant";
import routes from "./routes";

function App() {
  const elements = useRoutes(routes);
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
    >
      <NotificationContainer />
      {elements}
      <Toaster />
    </MsalAuthenticationTemplate>
  );
}

export default App;
