import {SalesService} from "types";

export const findChannelName = (
  channels: SalesService.SaleChannelDto[],
  code: string,
) => {
  const channel = channels?.filter(
    (channel: any) => channel?.code === code,
  )?.[0];
  return channel?.title;
};
