import {
  InputGroup,
  SelectLang,
  Skeleton,
  Text,
  Wrapper,
  Icon,
} from "components";
import {Fragment, useContext} from "react";
import {NotificationManager} from "common/notifications";
import {flagCountry, rules} from "constant";
import {MeasurementContext} from "..";
import {usePermission} from "hooks";

export default function General() {
  const updatePermission = usePermission("PS_UpdateMeasurementFamily");
  const {
    measurementData,
    setMeasurementData,
    name,
    description,
    langItems,
    setName,
    setDescription,
    loading,
    langSelected,
    setLangSelected,
  } = useContext<any>(MeasurementContext);

  const onChangeLanguage = (lang: any) => {
    setLangSelected(lang);
    measurementData?.translates?.forEach((item: any) => {
      if (item.language === lang) {
        setName(item.labelTitle);
        setDescription(item.description);
      }
    });
  };

  return (
    <Fragment>
      <div className="flex gap-6">
        {loading ? (
          <Wrapper>
            <Wrapper.Body className="grid grid-cols-2 gap-4 lg:grid-cols-2">
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Wrapper.Body>
          </Wrapper>
        ) : (
          <Wrapper>
            <Wrapper.Header>
              <div className="flex items-center justify-between">
                <div>
                  <h2 className="font-semibold text-gray-800">
                    <Text>
                      productManagement.masterData.measurementUnits.basicInfo
                    </Text>
                  </h2>
                </div>
                <div>
                  <SelectLang
                    value={langSelected}
                    items={langItems}
                    onChangeLanguage={onChangeLanguage}
                  />
                </div>
              </div>
            </Wrapper.Header>
            <Wrapper.Body className="grid grid-cols-2 gap-4 lg:grid-cols-2">
              <div className="space-y-8">
                <InputGroup
                  label="productManagement.masterData.measurementUnits.code"
                  value={measurementData?.code}
                  disabled
                  append={
                    <span
                      className="input-group-text"
                      onClick={() => {
                        window.navigator.clipboard.writeText(
                          measurementData.code,
                        );
                        NotificationManager.success(
                          "global.toast.copy-msg",
                          "global.toast.copy-title",
                        );
                      }}
                    >
                      <Icon
                        className="ml-6 cursor-pointer text-primary"
                        icon={"files"}
                      />
                    </span>
                  }
                />
                <InputGroup
                  label="productManagement.masterData.measurementUnits.name"
                  value={name}
                  flag={flagCountry[langSelected]}
                  disabled={!updatePermission}
                  setValue={value => {
                    measurementData?.translates?.forEach((item: any) => {
                      if (item.language === langSelected) {
                        item.labelTitle = value;
                      }
                    });
                    setMeasurementData((p: any) => ({
                      ...p,
                      translates: measurementData?.translates,
                    }));
                    setName(value);
                  }}
                  rules={rules.required}
                />
                <InputGroup
                  label="productManagement.masterData.measurementUnits.description"
                  value={description}
                  as="short-textarea"
                  disabled={!updatePermission}
                  flag={flagCountry[langSelected]}
                  setValue={value => {
                    measurementData?.translates?.forEach((item: any) => {
                      if (item.language === langSelected) {
                        item.description = value;
                      }
                    });
                    setMeasurementData((p: any) => ({
                      ...p,
                      translates: measurementData?.translates,
                    }));
                    setDescription(value);
                  }}
                />
              </div>
            </Wrapper.Body>
          </Wrapper>
        )}
      </div>
    </Fragment>
  );
}
