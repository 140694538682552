export enum AvailabilityStatus {
  Active = 0,
  Inactive = 1,
  SalesOnly = 2,
  Blocked = 3,
}

export enum PrintRequestStatus {
  OnProcess = 0,
  Completed = 1,
  Faild = 2,
}

export enum PurchasePriceType {
  Gross = 0,
  Net = 1,
  NNet = 2
}

export enum CheckListCompletenessStatus {
  NotStarted = 0,
  InProcess = 1,
  HasAProblem = 2,
  Completed = 3,
}
