export const errors = {
  error: "Error",
  required: "This field is required",

  "001": "ConfigNotFound",
  "002": "ConfigIsWrong",
  "003": "SyncError",
  404: "Resource not found",

  100: "Channel Already Added To Company",

  1000: "Place Code Should Be Unique",
  1001: "Invalid Channel",
  1003: "Brand Code Should Be Unique In Company",
  1004: "Place Code Should Be Unique In Area",
  1006: "Attribute Group Template Not Found",
  1007: "Brand Could Not Found",
  1008: "Brand Already Exists",
  1009: "Chek List Item Could Not Found",
  1010: "Check List Item Label Already Exists",
  1011: "Legal Entity Could Not Found",
  1012: "Legal Entity Code Should Be Unique In Company",
  1013: "Measurement Family Code Should be Unique",
  1014: "Measurement Family Unit Code Should be Unique",
  1015: "Measurement Family Could Not Found",
  1016: "Pim Product Family Could Not Found",
  1017: "Place Could Not Found",
  1018: "Pim Product Media Could Not Found",
  1019: "Supplier Holiday Not Found",
  1020: "Favorite Product Not Found",
  1021: "Only Channel Of None Channel Specific Attribute Values Could Be Null",
  1022: "Channel Specific Attributes Should Use Built In Channels",
  1023: "Only Language Of None Language Specific Attribute Values Could Be Null",
  1024: "Pim Product Media Fields Can Not Have Duplicate Language In The Same Channel",
  1025: "Media Fields Either All Or Non Should Have Channel Value",
  1026: "Media Fields Either All Or Non Should Have Language Value",
  1027: "Required Items Should Have Value",
  1028: "Delete Check list Item Is possible When Not Used In Preparation Phase",
  1029: "Delete Check list Item Is possible When Not Used In Sell Phase",
  1030: "Product Family Code Should Be Unique In Company",
  1031: "Product Family Already Exist",
  1032: "Zone Code Should Be Unique In Store",
  1033: "Store Code Should Be Unique In Company",
  1034: "Store Not Found",
  1035: "Product Family Not Found",
  1036: "Channel Setting Not Found",
  1037: "Channel Is Not Availble In This Category",
  1038: "Company Not Found",
  1039: "Company Channel Not Found",
  1040: "Place Already Exists",
  1041: "Company Already Exist",
  1042: "LocationNotFound",
  1043: "Pim Product Check List Not Found",
  1044: "Pim Product Check List Completion State Not Found",
  1045: "Discussion Not Found",

  13000: "Product Categor CanNot Have Duplicate ChannelInfo",
  13001: "Product Category CanNot Have Duplicate ChannelException",
  13002: "Product Category ChannelCode Must Exist In ChannelException",
  13003: "Product Category ChannelInfo Must Be Exist",
  13004: "Product Category Sort Order ShouldNot Be LessThanZero",

  2000: "Channels Should Be Unique in Searchable Attribute Channels",
  2001: "Channels Should Be In Built In Channels",
  2002: "Attribute Search able Channels Must Be Exist",
  2003: "Attribute Publishable Exception Channels Should Be Unique",
  2004: "Attribute Publish able Exception Channels Should Be Exist",
  2005: "Attribute Item Should Not Be Duplicated",
  2006: "Attribute Must Include At Least One Translation",
  2007: "Attribute Code Must Be Unique",
  2008: "Attribute Item Default Value Must Be In Item List Id",
  2009: "Item Must Be Exist In Attribute",
  2010: "Attribute Should not Used In Group",
  2011: "List Type Must Have Same Translate In Item Translate",
  2012: "Attribute Group Code Must Be Unique",
  2013: "Attribute Group Is Used In Pim Product Family",
  2014: "Attribute Can Not Be Repeated In Group",
  2015: "Attribute Must Be Exist In Group",
  2016: "Attribute Template Order Should Not Be Less Than Zero",
  2017: "AttributeGroupTemplateTypeMustNotBuiltin",

  2100: "AttributeTemplateTypeMustBeList",
  2101: "AttributeTemplateProviderTypeMustNotBuiltin",

  3000: "Product Category Can Not Be Deleted When Is Used In Pim Product",
  3001: "Product Category Can Only Be Published To Available Channels",
  3002: "Product Category Can Only Be Published To Built In Channels",
  3003: "Product Category Code Must Be Unique",
  3004: "Product Category Can Not It selfs Parent",
  3005: "Parent Of Root Product Category Cannot Be Changed",
  3006: "The Product Category And Its Parent Shoud Be In The Same Category",
  3007: "The Product Category Should Not Have Any Sub Product Category",
  3008: "Product Category Not Found",

  4000: "Supplier Not Found",
  4001: "Supplier Iln Must Be Unique",
  4002: "Supplier Already Exist",
  4003: "Supplier Could Not Be Deleted When Used In Selloff Product",
  4004: "Supplier Can Only Be Published To Built In Channels",
  4005: "Supplier Code Should Be Unique In Company",
  4006: "SupplierBankInfoIsNotValid",
  4007: "Supplier Iln And Alternative Iln Not Valid",
  4010: "Discount Not Found",
  4011: "Invalid Discount Amount",
  4012: "Contact Person Not Found",

  5000: "Down Payment Accepted Range",
  5001: "Address Could Not Be Null",
  5003: "Important Documents Should Be Signed",
  5004: "Offer Should Have Items With Down Payment",
  5005: "SalesOfferDownPaymentIsNotSet",

  6000: "Attribute Not Found",
  6001: "Configurable Program Has No Attribute",
  6002: "Program Not Found",
  6003: "Program Attribute Code Should Be Unique",
  6004: "Info Page Not Found",
  6005: "MediaId Must Exist For Remove Media From Lahoma Order",
  6006: "Remove Picture Or Document Is Not Possible For Inprogress Lahoma Order",
  6007: "Document Must Exist To Remove From Lahoma Orders",
  6008: "Media Direction Must Be Unique In Perspective Media",
  6009: "Order Posted Lahoma Should Be Unique With in The Company",
  6010: "Remove Picture Or Document Is Not Possible For Submitted Lahoma Order",
  6011: "Position Code Must Be Unique",
  6012: "Banner Position Components Must Have Only One Item",
  6013: "Component Not Exists",
  6014: "Info Page Layout Not Found",
  6015: "Info Page Code Must Be Unique",
  6016: "Channel Setting Should Have One Active Setting",
  6017: "Value Is Not An ItemId",
  6018: "Lahoma Order Not Found",
  6019: "Position Not Found",
  6020: "Program Code Should Be Unique",
  6021: "Supplier Program Availability Status Not Found",
  6022: "Supplier Program Availability Status Is Default",
  6023: "Supplier Program Availability Status Date Range Should Not Conflict",

  7000: "Pim Product Not Found",
  7001: "Attribute Group Should Not Used In Pim Product Family Tree Path",
  7002: "Attribute Group Should Exist In Pim Product Family Tree Path",
  7003: "Leaf Can Removed In Pim Product Family Tree Path",
  7004: "Pim Product Family Code Should be Unique",
  7005: "Pim Product Family Could Not Be Rempved When Is Used In Pim Product",
  7006: "Pim Product Location Could Not Be Repeated",
  7007: "Pim Product Media Could Be Published To Built In Channels",
  7008: "Article Number Should Be Unique",
  7009: "Attribute Values Can Not Be Null Nor Empty",
  7010: "Pim Product Attributes Can Not Ne Null",
  7011: "Pim Product Can Only Be Published To Built In Channels",
  7012: "Article Number Is Out Of Range",
  7013: "Pim Product Family As Parent Not Found",
  7014: "Pim Product Attribute Is Required Not Valid",
  7015: "Pim Product Attribute Is Per Language Not Valid",
  7016: "Pim Product Attribute Is Per Channel Not Valid",
  7018: "Pim Product Attribute Is Not In Pim Based On Pim Product Family Not Valid",
  7019: "Pim Product Published Required Attribute Missing Value",
  7020: "Pim Product Group Code Is Already Given",
  7021: "Pim Product Group Not Found",
  7022: "PimProductCategoryCanNotBeDuplicateInPimProduct",
  7023: "PimProductAttributesShouldBeUnique",
  7024: "PimProductSupplierIsNotSet",
  7025: "PimProductSupplierProgramIsNotSet",
  7026: "PimProductGroupIsNotSet",
  7027: "PimProductBundleItemNotFound",
  7028: "PimProductTypeMustBeBundle",
  7029: "PimProductBundleItemSupplierIsNotSet",
  7030: "PimProductBundleItemSupplierProgramIsNotSet",
  7031: "PimProductBundleItemGroupIsNotSet",
  7032: "Pim Product Availability Status Not Found",
  7033: "Pim Product Availability Status Is Default",
  7034: "Pim Product Availability Status Date Range Should Not Conflict",
  7035: "Pim Product Type Must Be Standard",

  8000: "Line Item Not Found",
  8001: "Duplicated Offer Version Is Not Allowed",
  8002: "Offer Customer Add ress Should Be In Different Type",
  8003: "Add Sub Line Item Is Not Possible When Line Item Not Found",
  8004: "Only Active Offer Could Be Cloned",
  8005: "Last Version Offer Could Be Cloned",
  8006: "Only Accepted Offer Could Be Reactivate",
  8007: "Only Rejected Offer Could Be Reactivate",
  8008: "Reject Offer Is Allowed For Active Offer",
  8009: "Remove Document Is Not Possible When Document Not Found",
  8010: "Not Existing Line Item Could Not be Removed",
  8011: "Remove Sub Line Item Is Not Possible When Line Item Not Found",
  8012: "Not Existing Sub Line Item Could Not Be Removed",
  8013: "Not Existing Document Could Not Be Removed Or Updated",
  8014: "Signed Document Could Not Be Removed Or Updateed",
  8015: "Update Line Item Is Not Possible When Line Item Not Found",
  8016: "Only Active Offer Could Be Updated",
  8017: "Last Version Of Offer Colud Be Updated",
  8018: "Update Sub Line Item Is Not Possible When Line Item Not Found",
  8019: "Update Sub Line Item Is Not Possible When Sub Line Item Not Found",
  8020: "Only Active Order Could Be Canceled",
  8021: "Order Can Only Be Published To Built In Channels",
  8022: "Publish To Erp Is Possible When Order Documents With Sign Mandatory Signed",
  8023: "Signed Document Could Not Be Removed Or Updated",
  8024: "Active Order Could Be Updated",
  8025: "Offer Not Found",
  8026: "Document Not Found",
  8027: "Order Not Found",
  8028: "Make Order Not Possible When Line Item Supplier Id Null",
  8029: "Make Order Not Possible When Sub Line Item Supplier Id Null",
  8030: "OfferLineItemNotFound",
  8031: "OfferDocumentNotFound",
  8032: "OfferIsExpired",
  8033: "CustomerNotFound",
  8034: "CustomerCompanyContactPersonNotFound",
  8035: "CustomerHasNotCimNumber",
  8036: "CustomerNotApproved",
  8037: "OrderLineNotFound",
  8038: "OfferLineItemHasReservedInInventory",
  8039: "OfferLineItemHasNotReservedInInventory",

  9000: "Prepration Phase Not Found",
  9001: "Sell Off Product Not Found",
  9002: "Sell Phase Template Not Found",
  9003: "PreparationPhaseAlreadyCreated",
  9004: "Sell Off Product Time line Not Found",
  9005: "Sell Off Product Time line Already Exist",
  9006: "Check List Items Not Found",
  9007: "Order Of Sell Off Product Assets Should Be Unique",
  9008: "Change Phase Is Available After Current Phase Duration",
  9009: "Change Phase Is Only Possible For Active Sell Off Product",
  9010: "Change Phase Is Only Possible When All Sell Phase Check List Items Have Been Checked",
  9011: "Close Phase Is Available Only After Last Phase Duration",
  9012: "Close Phase Is Only Possible For Active Sell Off Product",
  9013: "Last Phase Can Not Be Changed",
  9014: "Media Id Must Exist For Remove Asset From Sell Off Product",
  9015: "Next Sell Phase Should Contains Check List Item",
  9016: "Only Last Phase Can Be Closed",
  9017: "Preparation Phase Should Contains Check List Item",
  9018: "Real Sold Price Should Greater Than Zero",
  9019: "Remove Picture Is Not Possible For Closed Sell Off Product",
  9020: "Remove Picture Is Not Possible For Sold Sell Off Product",
  9021: "Reorder Assets Is Not Possible For Closed Sell Off Product",
  9022: "Reorder Assets Is Not Possible For Sold Sell Off Product",
  9023: "Start Phase Is Not Possible Without Product family",
  9024: "Start Phase Is Not Possible Without Sell Phase Template",
  9025: "Sold Is Available Only After Sell Off Product Start Date Time",
  9026: "Sold Is Only Possible For Active Sell Off Product",
  9027: "Start Phase Is Only Possible For Preparation Sell Off Product",
  9028: "Start Phase Is Only Possible When All Preparation Phase Check List Items Have Been Checked",
  9029: "Update Is Not Possible For Closed Sell Off Product",
  9030: "Update Is Not Possible For Sold Sell Off Product",
  9031: "Update Preparation Phase Check List Item Is Only Possible For Preparation Sell Off Product",
  9032: "Update Sell Phase Check List Item Is Available Only After Current Phase Duration",
  9033: "Update Sell Phase Check List Item Is Only Possible For Active Sell Off Product",
  9034: "Update Sell Phase Template Is Not Possible For Active Sell Off Product",
  9035: "Withdraw Sold Item Is Only Possible For Sold Sell Off Product",
  9036: "Sell Phase Template Code Must Be Unique",

  11000: "Provider Product Import Pursuit Not Found",
  11001: "Provider Product Import Not Found",
  11002: "Provider Configuration Not Installed",
  11003: "B2B Provider Configuration Already Exist",
  11004: "Provider Configuration Not Found",

  12000: "Duplicate Iwofurn Configuration",
  12001: "Iwofurn Configuration Not Found",
  12002: "Store Iln Not Exist",
  12003: "Iwofurn Configuration Url Is Not Set",
  12004: "Iwofurn Configuration User Name Is Not Set",
  12005: "Iwofurn Configuration Password Is Not Set",
  12006: "IwofurnInternalTagIsNotFound",
  12007: "IwofurnOrderHasNoLineItem",
  12008: "IwofurnApiNotResponding",

  14000: "ShippingMethodWithThisCodeAlreadyExist",
  14001: "ShippingMethodNotFound",
  14002: "ShippingMethodCanNotBeRepeatedInProduct",
  14003: "ShippingMethodNotExistInProduct",

  14100: "DocumentTemplateNotFound",
  14101: "SalesOfferDocumentPdfNotCreated",
  14102: "SalesOfferDocumentNotFound",
  14103: "SalesOfferHasAccepted",
  14104: "SalesOfferHasNotActive",

  14200: "GeneralDiscountNotFound",
  14201: "PriceTemplateNotFound",
  14202: "PriceTemplateItemNotFound",
  14203: "PriceTemplateDiscountNotFound",
  14204: "GeneralDiscountCodeAlreadyExists",
  14205: "PriceTemplateCodeAlreadyExists",
  14206: "InvalidPriceTemplateDiscountsRequested",
  14207: "InvalidGeneralDiscountRequested",

  14300: "ErpInventoryConfigNotFound",
  14301: "ErpInventoryServiceLoginMicrosoftFailed",
  14302: "ErpInventoryServiceLoginDynamicsFailed",
  14303: "ErpInventoryServiceGetProductInventoryResponseHaveBadRequest",
  14304: "ErpInventoryServiceGetProductInventoryResponseProductNotAvailable",
  14305: "OnlyStoreHaveLegalEntityCodeCanUsedInErpInventoryService",
  14306: "ErpInventoryServiceReserveProductInInventoryResponseHaveBadRequest",
  14307: "ErpInventoryServiceReserveProductInInventoryNoResponse",
  14308: "ErpInventoryServiceUnreserveProductFromInventoryHaveBadRequest",
  14309: "ErpInventoryServiceUnreserveProductFromInventoryNoResponse",
  14310: "ErpInventoryServiceProductQuantityIsLessThanRequest",
  14311: "OnlyProductHaveArticleNumberCanUsedInErpInventoryService",

  14400: "SalesPersonInformationNotFound",

  15000: "FileNotFound",
  15001: "ExcelFileIsCorrupt",
  15002: "ExcelFileSheetNotFound",
  15003: "ArticleNumberIncorrectFormat",
  15004: "PimProductFamilyCodeIncorrectFormat",
  15005: "PimProductGroupCodeIncorrectFormat",
  15006: "SupplierArticleNumberIncorrectFormat",
  15007: "OriginalNameIncorrectFormat",
  15008: "EANNumberIncorrectFormat",
  15009: "ShortDescriptionIncorrectFormat",
  15010: "SupplierCodeIncorrectFormat",
  15011: "SupplierIlnIncorrectFormat",
  15012: "SupplierProgramCodeIncorrectFormat",
  15013: "MaterialIncorrectFormat",
  15014: "ColorIncorrectFormat",
  15015: "PurchasePriceIncorrectFormat",

  17000: "PublishedContentNotFound",
  17001: "SupplierIsNotPublished",
  17002: "SupplierProgramIsNotPublished",
  17003: "SupplierIsNotSet",
  17004: "SupplierProgramIsNotSet",
  17101: "Product label template uploaded file must not be null",

  A1000: "CompanyNotFound",
  A1001: "CustomerNotFound",
  A1002: "UserNotFount",
  A1003: "EmployeeNotFound",
  A1004: "UserGroupNotFound",
  A1005: "LegalEntityNotFound",
  A1006: "StoreNotFound",
  A1007: "MobileNumberIsAlreadyRegistered",
  A1008: "CustomerAccountNotFound",
  A1009: "MobileNumberVerificationCodeIsNotValid",
  A1010: "MobileNumberIsNotVerifiedYet",
  A1011: "EmailAddressIsNotVerified",
  A1012: "PassCodeShouldBeSet",
  A1013: "EmailAddressIsAlreadyRegistered",
  A1014: "PassCodeShouldBeValid",
  A1015: "EmailAddressIsNotMatch",
  A1016: "LegalEntityWithSameCodeAlreadyExist",
  A1017: "NewPassCodeIsNotMatch",
  A1018: "InvalidPassCode",
  A1019: "UserPrincipalNameNotTheSameAsEmailAddress",
  A1020: "UserAlreadySignedUp",
  A1021: "UserAlreadyExist",
  A1022: "DeviceTokenIsNotValid",
  A1023: "EmailConfirmationCodeIsExpired",
  A1024: "EmailConfirmationCodeIsNotValid",
  A1025: "MobileNumberIsNotConfirmed",
  A1026: "RemoveCustomerIsNotPossible",
  A1027: "CustomerMustAcceptGdpr",
  A1028: "CustomerMustBePerson",
  A1029: "MobileNumberMustRegistered",
  A1030: "CompanyTaxIdMustBeFilled",
  A1031: "CompanyRegistrationNumberMustBeUnique",
  A1032: "CustomerMustBeCompany",
  A1033: "CompanyContactPersonNotFound",
  A1034: "CountryNotFound",
  A1043: "SsoConfigurationNotFound",
  A1044: "SsoConfigurationAlreadyExists",

  C9001: "ErpConfigurationNotFound ",
  C9002: "SendMessageToErpFailed",
  C9003: "ChannelMessageNotFound",
  C9004: "FavoriteNotFound",
  C9005: "FavoriteItemShouldBeUnique",
  C9006: "FavoriteItemNotFound",
  C9007: "CanNotCreateIncident",
  C9008: "CanNotUpdateIncident",
  C9009: "CanNotUpsertOrderInCrm",
  C9101: "CrmStoreNotFound",
  C9102: "CrmOrderNotFound",
  C9103: "CrmOrderDetailNotFound",
  C9104: "CrmCustomerNotFound",
  C9105: "CrmEmployeeNotFound",
  C9106: "CanNotUpsertEmployeeInCrm",
  C9107: "SaleToolsPimProductNotFound",
  C9108: "PimProductAttributesShouldBeUnique",
  C9109: "CanNotRemoveOrderCommissionGroup",
  C9110: "CanNotChangeOrderCommissionGroup",
  C9111: "CanNotUpsetCustomerInCrm",
  C9112: "CanNotDeactiveContactPerson",
  C9113: "CrmCustomerTypeNotDefined",
  C9114: "CanNotUpsetContactPersonInCrm",
  C9115: "ThereIsNoPriceListInCrm",
  C9116: "ThereIsNoTransactionCurrencyInCrm",
  C9117: "SelectedcurrencyNotFoundInCrm",
  C9118: "CanNotGetPriceListFromCrm",
  C9119: "CanNotGetTransactionCurrencyListFromCrm",
  C9120: "CrmCustomerCIMNumberIsEmpty",
  C9121: "CrmLegalEntityCodeIsEmpty",
  C9122: "CrmEmployeeNumberIsEmpty",
  C9123: "CustomerWithInputCIMNumberNotFoundInCRm",
  C9124: "StoreWithInputLegalEntityCodeNotFoundInCRm",
  C9125: "CanNotGetCustomerWithCIMNumberFromCrm",
  C9126: "CanNotGetStoreWithLegalEntityCodeFromCrm",
  C9127: "CanNotGetEmployeeWithEmployeeNumberFromCrm",
  C9128: "CrmCustomerModelIsEmpty",
  C9129: "CrmSalePersonModelIsEmpty",
  C9130: "ThereIsNocurrencyInCrm",
  C9131: "CanNotGetCurrencyListFromCrm",
  C9132: "SyncOrderDetailToCrmFailed",
  C9133: "SyncOrderDocumentToCrmFailed",
  C9134: "SupplierWithInputSupplierNumberNotFoundInCRm",
  C9135: "CanNotGetSupplierWithSuppliereNumberFromCrm",
  C9136: "GetOrderDetailsOfOrderFromCrmFailed",
  C9137: "GetOrderDocumentsOfOrderFromCrmFailed",
  C9138: "ChangeOrderDetailStatusInCrmFailed",
  C9139: "ChangeOrderStatusInCrmFailed",
  C9140: "SyncSupplierToCrmFailed",
  C9141: "SyncLegalToCrmFailed",
  C9142: "SyncStoreToCrmFailed",
  C9143: "ChangeOrderCommissionInCrmFailed",
  C91044: "CrmSupplierNotFound",
  C9145: "SyncContactPersonToCrmFailed",
  C9146: "SyncCustomerAccountToCrmFailed",
  C9147: "SyncFavoriteToCrmFailed",
  C9148: "SyncSupplierHolidayToCrmFailed",
  C9149: "SyncSupplierProgramToCrmFailed",
  C9150: "RemoveFavoriteFromCrmFailed",
  C9151: "RemoveRemoveOrderCommissionGroupFromCrmFailed",
  C9152: "SyncTaskToCrmFailed",
  C9153: "AssignTaskInCrmFailed",
  C9154: "ChangeTaskInCrmFailed",
  C9155: "GetLeadFromCrmFailed",
  C9156: "GetTaskFromCrmFailed",
  C9157: "GetTaskListFromCrmFailed",
  C9158: "GetActivityFromCrmFailed",
  C9159: "ChangeIncidentStatusInCrmFailed",
  C9160: "AssignIncidentInCrmFailed",
  C9161: "GetIncidentListFromCrmFailed",
  C9162: "GetIncidentFromCrmFailed",
  C9900: "CrmUnknownError",
  C9901: "CrmDataverseConnectionFailed",
  C9163: "CrmDepartmentNotFound",
  C9164: "SyncDepartmentToCrmFailed",
  C9165: "DeactiveDepartmentInCrmFailed",
  C9166: "CanNotCreateDepartmentInCrm",
  C9167: "CanNotDeleteDepartmentInCrm",
  C9168: "GetSubjectIncidentFromCrmFailed",
  C9169: "CrmSubjectNotFound",
  C9170: "CrmSupplierCodeIsEmpty",
  C9171: "EmployeeeWithInputEmployeeNumberNotFoundInCRm",

  "000": "ObjectNotFound",
  PE0000: "CalculationUnitNotFound",
  PE0100: "CalculationUnitCustomizationNotFound",
  PE0101: "CustomCalculationUnitNotFound",
  PE0200: "PriceTemplateCodeAlreadyExists",
  PE0201: "PurchasePriceTemplateNotFound",
  PE0202: "PurchasePriceTemplateIsNotActive",
  PE0203: "PurchasePriceTemplatePeriodNotStarted",
  PE0204: "PurchasePriceTemplatePeriodFinished",
  PE0205: "NoPurchasePriceTemplateIsAvailable",
  PE0206: "PurchasePriceTemplateDoseNotHaveItem",
  PE0300: "SalePriceTemplateNotFound",
  PE0301: "SalePriceTemplateIsNotActive",
  PE0302: "SalePriceTemplatePeriodNotStarted",
  PE0303: "SalePriceTemplatePeriodFinished",
  PE0304: "NoSalePriceTemplateIsAvailable",
  PE0305: "SalePriceTemplateDoseNotHaveItem",
  PE0306: "OptionalDiscountsNotFoundInSalePriceTemplate",
  PE0400: "PurchasePriceTemplateItemNotFound",
  PE0500: "SalePriceTemplateItemNotFound",

  AS1001: "EloConfigNotFound",
  AS1002: "EloConfigAlreadyExist",
  AS1003: "EloServiceNotAvailable",

  AS2001: "PersonalAzureBlobConfigNotFound",
  AS2002: "TenantStorageConfigNotFound",
  AS2003: "StorageTypeNotSetForPulsFileType",

  AS3001: "TenantStorageConfigNotFound",
  AS3002: "StorageTypeNotSetForPulsFileType",
  AS3010: "UnableToDownloadSourceFileByUrl",

  SS0002: "Sale Channel Not Found",
  SS0003: "Sale Channel Code Should Be Unique",

  SS14300: "ErpInventoryConfigNotFound",
  SS14301: "ErpInventoryServiceLoginMicrosoftFailed",
  SS14302: "ErpInventoryServiceLoginDynamicsFailed",
  SS14303: "ErpInventoryServiceIsNotAvailable",
  SS14304: "OnlyStoreHaveLegalEntityCodeCanUsedInErpInventoryService",
  SS14305: "ErpInventoryServiceReserveProductIsNotAvailable",
  SS14306: "ErpInventoryServiceReserveProductInInventoryHasFailed",
  SS14307: "ErpInventoryServiceUnreserveProductIsNotAvailable",
  SS14308: "ErpInventoryServiceUnreserveProductFromInventoryHasFailed",
  SS14309: "ErpInventoryServiceProductQuantityIsNotEnough",
  SS14310: "OnlyProductHaveArticleNumberCanUsedInErpInventoryService",
  SS14311: "ErpInventoryServiceOnHandResultHasNoResponse",
  SS14312: "ErpInventoryServiceOnHandResultLocationNotExist",
  SS14313: "ErpInventoryServiceLoginMicrosoftHasBadRequestResult",
  SS14314: "ErpInventoryServiceLoginMicrosoftHasUnauthorizedResult",
  SS14315: "ErpInventoryServiceLoginDynamicsHasBadRequestResult",
  SS14316: "ErpInventoryServiceLoginDynamicsHasUnauthorizedResult",
  SS14100: "Document Template Not Found",
  SS14105: "Platform Document Template Not Found",
  SS14106: "Document Template Already Exists",
  SS14107: "Document Template Mime Type Must Be Equal",
  SS14108: "Tenant Document Template Not Found",

  SS17100: "Product LabelT emplate Not Found",
  SS17101: "Product Label Template Uploaded File Must Not Be Null",
  SS17200: "Print Request Not Found",
  SS17201: "Print Request Has OnProcess Status",

  SS18000: "SellPhaseTemplateCodeMustBeUnique",
  SS18001: "DeleteChecklistItemIsPossibleWhenNotUsedInPreparationPhase",
  SS18002: "DeleteChecklistItemIsPossibleWhenNotUsedInSellPhase",
  SS18003: "CheckListItemCouldNotFound",
  SS18004: "PreparationPhaseAlreadyCreated",
  SS18005: "CheckListItemsNotFound",
  SS18006: "PreparationPhaseNotFound",
  SS18007: "SellPhaseTemplateNotFound",
  SS18008: "SellOffProductNotFound",
  SS18009: "OrderOfSellOffProductAssetsShouldBeUnique",
  SS18010: "ChangePhaseIsAvailableAfterCurrentPhaseDuration",
  SS18011: "ChangePhaseIsOnlyPossibleForActiveSellOffProduct",
  SS18012:
    "ChangePhaseIsOnlyPossibleWhenAllSellPhaseCheckListItemsHaveBeenChecked",
  SS18013: "UpdateIsNotPossibleForClosedSellOffProduct",
  SS18014: "UpdateSellPhaseTemplateIsNotPossibleForActiveSellOffProduct",
  SS18015: "LastPhaseCanNotBeChanged",
  SS18016: "SoldIsOnlyPossibleForActiveSellOffProduct",
  SS18017: "WithdrawSoldItemIsOnlyPossibleForSoldSellOffProduct",
  SS18018: "ClosePhaseIsOnlyPossibleForActiveSellOffProduct",
  SS18019: "OnlyLastPhaseCanBeClosed",
  SS18020: "ClosePhaseIsAvailableOnlyAfterLastPhaseDuration",
  SS18021: "PreparationPhaseShouldContainsCheckListItem",
  SS18022:
    "UpdatePreparationPhaseCheckListItemIsOnlyPossibleForPreparationSellOffProduct",
  SS18023: "UpdateSellPhaseCheckListItemIsOnlyPossibleForActiveSellOffProduct",
  SS18024:
    "UpdateSellPhaseCheckListItemIsAvailableOnlyAfterCurrentPhaseDuration",
  SS18025: "NextSellPhaseShouldContainsCheckListItem",
  SS18026: "StartPhaseIsNotPossibleWithoutProductFamily",
  SS18027:
    "StartPhaseIsOnlyPossibleWhenAllPreparationPhaseCheckListItemsHaveBeenChecked",
  SS18028: "MediaIdMustExistForRemoveAssetFromSellOffProduct",
  SS18029: "RealSoldPriceShouldGreaterThanZero",
  SS18030: "SoldIsAvailableOnlyAfterSellOffProductStartDateTime",
  SS18031: "StartPhaseIsNotPossibleWithoutSellPhaseTemplate",
  SS18032: "StartPhaseIsOnlyPossibleForPreparationSellOffProduct",
  SS18033: "CheckListItemLabelAlreadyExists",
  SS18034: "StartPhaseIsOnlyPossibleWhenSellPhaseTemplateIsNotEmpty",
  SS18035: "SellPhaseTemplateIsNotSet",

  SS18100: "SellOffProductTimelineNotFound",
  SS18101: "SellOffProductTimelineAlreadyExist",
  SS7035: "Pim Product Type Must Be Bundle",
  SS7036: "Pim Product Type Must BeStandard",
};
