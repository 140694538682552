import Countries from "pages/configurations/address-configuration/countries";

const CountryList = [
  {
    path: "",
    element: <Countries />,
  },
];

export default CountryList;
