import {NotificationManager} from "common/notifications";
import {Button, Form, InputGroup, Modal, Text} from "components";
import {rules} from "constant";
import {useToggleState} from "hooks";
import {useState} from "react";
import {ServiceApi, URLS} from "services";

type Props = {
  isOpen: boolean;
  toggle: (value?: boolean) => void;
  getData: CallableFunction;
  id?: string;
  channelCode?: string;
};
const WithdrawModal = ({isOpen, toggle, getData, id, channelCode}: Props) => {
  const [loading, setLoading] = useToggleState();
  const [withdrawReason, setWithdrawReason] = useState<any>("");

  const withdrawProductHandler = () => {
    if (id) {
      setLoading();
      const url = URLS.WITHDRAW_URL(id, channelCode);
      ServiceApi.post(url, {withdrawReason: withdrawReason})
        .then(() => {
          NotificationManager.success(
            "global.toast.edit-msg",
            "global.toast.edit-title",
          );
          setLoading();
          getData();
          toggle();
        })
        .catch(() => {
          setLoading();
        });
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="z-40">
      <Modal.Header className="flex flex-row">
        <h6 className="text-heading-2 text-gray-800">
          <Text>
            salesManagment.sellOffSales.sellOffProduct.withdrawProduct
          </Text>
        </h6>
      </Modal.Header>
      <Form onSubmit={withdrawProductHandler}>
        <Modal.Body className="max-h-100 space-y-6 overflow-auto">
          <div className="flex flex-col space-y-4">
            <span>
              <Text>
                salesManagment.sellOffSales.sellOffProduct.areUSureWithdraw
              </Text>
              ?{" "}
            </span>
          </div>
          <InputGroup
            label="salesManagment.sellOffSales.sellOffProduct.comment"
            rules={rules.required}
            value={withdrawReason}
            setValue={e => setWithdrawReason(e)}
          />
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-between">
          <Button variant={"light"} size="sm" onClick={toggle}>
            <Text>salesManagment.sellOffSales.sellOffProduct.cancel</Text>
          </Button>
          <Button size="sm" type="submit" loading={loading}>
            <Text>salesManagment.sellOffSales.sellOffProduct.submit</Text>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default WithdrawModal;
