import { NotificationManager } from "common/notifications";
import { Button, Drawer, Icon, NoItems, Skeleton, Wrapper, Text, Pagination, CheckBox, SelectLang } from "components";
import { config } from "constant";
import { useSelector, useToggleState } from "hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { ServiceApi, URLS } from "services";
import { ProductLabelTemplate } from "types";

type PropsType = {
  isOpen: boolean;
  toggle: () => void;
  getData: () => void;
  id?: string;
};

export default function Print(props: PropsType) {
  const [loading, setLoading] = useToggleState();
  const [printLoading, setPrintLoading] = useToggleState();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState<ProductLabelTemplate.ListType>();
  const [params, setParams] = useSearchParams();
  const [selectedTemplates, setSelectedTemplates] = useState<string[]>([]);
  const { i18n } = useTranslation();
  const language = i18n.language;
  const [lang, setLang] = useState(language);
  const [items, setItems] = useState<any>([]);
  const companySetting = useSelector(s => s.companySetting);

  const originalFilterData: any = {
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
    isActive: true,
  };

  const handleSetParams = (key: any) => {
    return (value: any) => {
      setParams(prev => {
        value !== null && value !== ""
          ? prev.set(key, value)
          : prev.delete(key);
        return prev;
      });
    };
  };

  const getTemplateList = () => {
    setLoading();
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    params['language'] = lang;
    const url = URLS.PRODUCT_LABEL_TEMPLATE_URL;
    const config = { params };
    ServiceApi.get(url, { ...config })
      .then(({ data }) => {
        setData(data);
      })
      .catch(() => {
        setData({});
      })
      .finally(() => {
        setLoading();
      });
  };

  const handleCheckItems = (e: boolean, item: ProductLabelTemplate.Item) => {
    if (e)
      setSelectedTemplates([...selectedTemplates, item.id]);
    else {
      const list = selectedTemplates.filter(i => i !== item.id);
      setSelectedTemplates(list);
    }
  };

  const onPrint = () => {
    setPrintLoading();
    const params = { channelCode: searchParams.get('channelCode') };
    const url = URLS.PRINT_REQUEST(props.id);
    const body = { productLabelTemplateIds: selectedTemplates, };
    ServiceApi.post(url, body, { params })
      .then(() => {
        NotificationManager.success(
          "global.toast.print-request-sent",
          "global.toast.print-request-sent-title",
        );
        props.toggle();
        props.getData();
      })
      .finally(() => setPrintLoading());
  }

  useEffect(() => {
    if (companySetting) {
      let arr: any = {};
      let arrItem: any = [];
      companySetting?.contentLanguages?.forEach((item: any) => {
        arr[item] = item;
        arrItem.push({
          name: item,
          id: item,
        });
      });
      setItems(arrItem);
    }
  }, [companySetting]);

  useEffect(() => {
    setSelectedTemplates([]);
    if (props.isOpen)
      getTemplateList();
  }, [props.isOpen, params]);

  useEffect(() => {
    getTemplateList();
  }, [lang]);

  useEffect(() => {
    setLang(language);
  }, [language]);

  return (
    <Drawer isOpen={props.isOpen} toggle={props.toggle}>
      <Drawer.Menu>
        <Drawer.Header className="flex items-center justify-between text-heading-2 font-semibold">
          <Text>productManagement.publishedProduct.Details.print</Text>
          <div className="flex gap-2 justify-center items-center">
            <SelectLang
              value={lang}
              items={items}
              onChangeLanguage={(v: string) => setLang(v)}
              className="!h-[38px] items-center"
            />
            <Button
              variant={"light"}
              size="sm"
              className="flex items-center"
              onClick={props.toggle}
            >
              <span>
                <Text>global.buttons.close</Text>
              </span>
              <Icon icon="close" className="ml-2" />
            </Button>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-6">
          <div className="flex gap-2 items-center">
            <label className="text-heading-5 font-semibold whitespace-nowrap">
              <Text>productManagement.publishedProduct.Details.chooseTemplates</Text>
            </label>
            <hr className="bg-gray-200 flex-1" />
          </div>
          {loading ? (
            <>
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </>
          ) : (
            <>
              {data && Object.keys(data).length !== 0 && data?.items?.length !== 0 ? (
                <div className="flex flex-col flex-1 space-y-10">
                  <div className="flex-1 space-y-3">
                    {data?.items?.map(item => {
                      return (
                        <div key={item.id} className="shadow-high p-5 rounded-xl flex justify-between items-center">
                          <h6 className='text-heading-6'>
                            {item.title}
                          </h6>
                          <div>
                            <CheckBox
                              value={selectedTemplates.includes(item.id)}
                              setValue={(e) => handleCheckItems(e, item)}
                            />
                          </div>
                        </div>
                      );
                    })
                    }
                  </div>
                  <Pagination
                    totalItems={data?.totalItems}
                    totalPages={data?.totalPages}
                    pageNumber={
                      params?.get("pageNumber")
                        ? parseInt(params?.get("pageNumber") as string)
                        : 1
                    }
                    pageSize={
                      params?.get("pageSize")
                        ? parseInt(params?.get("pageSize") as string)
                        : config.pageSize
                    }
                    setActivePage={handleSetParams("pageNumber")}
                    onPageSizeChange={(e: any) =>
                      setParams(p => ({ ...p, pageSize: e }))
                    }
                  />
                </div>
              ) : (
                <div className="flex w-full flex-col items-center space-y-4">
                  <NoItems />
                </div>
              )}
            </>
          )}
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-between">
          <Button size="sm" onClick={props.toggle} variant="white">
            <Icon className="mr-2" icon={"times"} />
            <Text>global.buttons.close</Text>
          </Button>
          <Button
            size="sm"
            variant={"primary"}
            onClick={onPrint}
            disabled={printLoading || !selectedTemplates?.length}
          >
            <Icon icon="print" className="mr-2" />
            <span>
              <Text>productManagement.publishedProduct.Details.print</Text>
            </span>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
