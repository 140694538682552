import { NotificationManager } from "common/notifications";
import {
  Breadcrumb,
  Button,
  DeleteModal,
  HandleName,
  Icon,
  Tabs,
  Text,
  Wrapper,
} from "components";
import { useSelector, useToggleState } from "hooks";
import { isEmpty } from "lodash";
import { createElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { ServiceApi, URLS } from "services";
import Channels from "./channels";
import General from "./general";
import Media from "./media";

const EditSubCategory = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const productCategory = useSelector((s) => s.productCategories);
  const [data, setData] = useState<any>(productCategory);
  const [deleteModal, setDeleteModal] = useToggleState();
  const [deleteLoading, setDeleteLoading] = useToggleState();

  useEffect(() => {
    if (isEmpty(productCategory)) {
      navigate(-1);
    } else {
      setData(productCategory);
    }
  }, [productCategory]);

  const tabs = [
    {
      label: "productManagement.masterData.productCategory.generalTab",
      component: General,
    },
    {
      label: "productManagement.masterData.productCategory.mediaTab",
      component: Media,
    },
    {
      label: "productManagement.masterData.productCategory.channelsTab",
      component: Channels,
    },
  ];

  const onDeleteHandler = () => {
    setDeleteLoading();
    const url = URLS.DELETE_CATEGORY(data?.productCategoryId);
    ServiceApi.delete(url)
      .then(() => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title"
        );
        setDeleteLoading();
        setDeleteModal();
        navigate(-1);
      })
      .catch(() => setDeleteLoading());
  };

  return (
    <div className="space-y-4">
      <Breadcrumb />
      <Tabs activeKey={tabs[0].label}>
        <Wrapper>
          <Wrapper.Body className="flex justify-between items-center">
            <div className="space-y-4">
              <span className="text-heading-5 font-semibold text-gray-800">
                {
                  <HandleName
                    translations={data?.translates}
                    code={data?.code}
                    keyName="labelTitle"
                  />
                }
              </span>
              <p className="text-body-2 font-medium">{data?.code}</p>
            </div>
            <Button
              variant={"danger"}
              light
              size="sm"
              onClick={() => {
                setDeleteModal();
              }}
            >
              <Icon icon={"trash"} className="mr-2" />
              <Text>
                productManagement.masterData.productCategory.deleteButton
              </Text>
            </Button>
          </Wrapper.Body>
          <Wrapper.Footer className="!py-0">
            <Tabs.ButtonGroup>
              {tabs.map((e) => (
                <Tabs.Button key={e.label} eventKey={e.label}>
                  <Text>{e.label}</Text>
                </Tabs.Button>
              ))}
            </Tabs.ButtonGroup>
          </Wrapper.Footer>
        </Wrapper>
        {tabs.map((e) => (
          <Tabs.Item key={e.label} eventKey={e.label}>
            {createElement(e.component)}
          </Tabs.Item>
        ))}
      </Tabs>
      <DeleteModal
        isOpen={deleteModal}
        loading={deleteLoading}
        toggle={setDeleteModal}
        onConfirm={onDeleteHandler}
        selected={{ name: data?.code }}
      />
    </div>
  );
};

export default EditSubCategory;
