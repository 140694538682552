import CustomersList from "./customer-list";

const Customers = [
  {
    path: "customers/customer-list",
    children: [...CustomersList],
  }
];

export default Customers;
