export const channelMessage = {
  list: {
    aggregateId: "Aggregate ID",
    channel: "Channel",
    createdAt: "Created at",
    updatedAt: "Updated at",
    detailsBtn: "Details",
    errorCodes: {
      "001": "Config Not Found",
      "002": "Config Is Wrong",
      "003": "Sync Error",
    },
  },
  details: {
    title: "Message details",
    closeBtn: "CLose",
    dataTab: "Data",
    outDataTab: "Out data",
    json: "JSON",
    noData: "No data",
  },
  filter: {
    filters: "Filters",
    resetFilterBtn: "Reset filter",
    closeBtn: "CLose",
    status: "Status",
    channel: "Channel",
    dateRange: "Date Range",
    from: "From",
    to: "To",
  },
  steps: {
    requirementGathering: "Requirement Gathering",
    configurationValidation: "Configuration Validation",
    dataTransmission: "Data Transmission",
    acknowledgeReceipt: "Acknowledge Receipt",
  },
};
