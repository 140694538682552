import {Text, Wrapper} from "components";
import PurchasePriceSimulate from "./purchase-price";
import SalesPriceSimulate from "./sales-price";
import {usePermission} from "hooks";

function Simulation() {
  const salesSimulationPermission = usePermission("PE_GetSalePriceSimulation");
  const purchaseSimulationPermission = usePermission(
    "PE_GetPurchasePriceSimulation",
  );

  return (
    <>
      {(salesSimulationPermission || purchaseSimulationPermission) && (
        <Wrapper>
          <Wrapper.Header>
            <Text>priceEngine.simulator.simulation</Text>
          </Wrapper.Header>
          <Wrapper.Body className="grid grid-cols-1 gap-8 xl:grid-cols-2">
            <PurchasePriceSimulate />
            <SalesPriceSimulate />
          </Wrapper.Body>
        </Wrapper>
      )}
    </>
  );
}

export default Simulation;
