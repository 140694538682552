export const priceTemplates = {
  assignedPriceTemplate: "Zugewiesenes Preis-Templates",
  assignPriceTemplate: "Preis-Template zuweisen",
  valid: "Gültig",
  from: "Von",
  to: "Bis",
  info: "Info Text",
  basicInfo: "Grundlegende Informationen",
  title: "Titel",
  code: "Code",
  shortDescription: "Kurzbeschreibung",
  general: "Allgemein",
  steps: "Schritte",
  active: "Aktiv",
  deActive: "Inaktiv",
  addStepItem: "Schrittelelement hinzufügen",
  addDiscountItem: "Rabattelement hinzufügen",
  calculationFactor: "Berechnungsfaktor",
  optionalDiscounts: "Optionale Rabatte",
  value: "Wert",
  step1: "Schritt 1 - Brutto > Netto",
  step2: "Schritt 2 - Netto > NettoNetto",
  step3: "Schritt 3 - Berechnungsfaktor",
  step4: "Schritt 4",
  step5: "Schritt 5",
  addPriceTemplate: "Preis-Template hinzufügen",
  name: "Name",
  pure: "pur",
  type: "Typ",
  addNewStepItem: "Neues Schrittelelement hinzufügen",
  percent: "Prozent (%)",
  amount: "Betrag (€)",
  normalProfit: "Normaler Gewinn",
  pureProfit: "Prozentualer Aufschlag",
  discount: "Rabatt",
  editStepItem: "Schrittelelement bearbeiten",
};

