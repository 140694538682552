import {
  InputGroup,
  SelectLang,
  Skeleton,
  Text,
  Toggler,
  Wrapper,
  Icon,
} from "components";
import {NotificationManager} from "common/notifications";
import {flagCountry, rules} from "constant";
import {Fragment, useContext} from "react";
import {usePermission} from "hooks";

import {AttributesContext} from "..";

export default function General() {
  const updatePermission = usePermission("PS_UpdateAttributeTemplate");
  const {
    attributesData,
    setAttributesData,
    description,
    name,
    langItems,
    setName,
    setDescription,
    loading,
    setLangSelected,
    langSelected,
  } = useContext<any>(AttributesContext);

  const onChangeLanguage = (lang: any) => {
    setLangSelected(lang);
    attributesData?.translates?.forEach((item: any) => {
      if (item?.language?.toLocaleLowerCase() === lang?.toLocaleLowerCase()) {
        setName(item.labelTitle);
        setDescription(item.description);
      }
    });
  };

  const handleSetData = (key: any) => {
    return (value: any) =>
      setAttributesData((p: any) => ({...p, [key]: value}));
  };

  return (
    <Fragment>
      <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
        {loading ? (
          <Wrapper>
            <Wrapper.Body className="grid grid-cols-1 gap-4 lg:grid-cols-1">
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Wrapper.Body>
          </Wrapper>
        ) : (
          <Wrapper>
            <Wrapper.Header>
              <div className="flex items-center justify-between">
                <div>
                  <h2 className="font-semibold text-gray-800">
                    <Text>
                      productManagement.masterData.attributes.basicInfo
                    </Text>
                  </h2>
                </div>
                <div>
                  <SelectLang
                    value={langSelected}
                    items={langItems}
                    onChangeLanguage={onChangeLanguage}
                  />
                </div>
              </div>
            </Wrapper.Header>
            <Wrapper.Body className="grid grid-cols-1 gap-4 lg:grid-cols-1">
              <div className="space-y-8">
                <InputGroup
                  label="productManagement.masterData.attributes.attributeName"
                  disabled={!updatePermission}
                  value={name}
                  flag={flagCountry[langSelected]}
                  setValue={value => {
                    attributesData?.translates?.forEach((item: any) => {
                      if (
                        item?.language?.toLocaleLowerCase() ===
                        langSelected?.toLocaleLowerCase()
                      ) {
                        item.labelTitle = value;
                      }
                    });
                    setAttributesData((p: any) => ({
                      ...p,
                      translates: attributesData?.translates,
                    }));
                    setName(value);
                  }}
                  rules={rules.required}
                />
                <InputGroup
                  as="textarea"
                  flag={flagCountry[langSelected]}
                  label="productManagement.masterData.attributes.shortDescription"
                  value={description}
                  disabled={!updatePermission}
                  setValue={value => {
                    attributesData?.translates?.forEach((item: any) => {
                      if (
                        item?.language?.toLocaleLowerCase() ===
                        langSelected?.toLocaleLowerCase()
                      ) {
                        item.description = value;
                      }
                    });
                    setAttributesData((p: any) => ({
                      ...p,
                      translations: attributesData?.translates,
                    }));
                    setDescription(value);
                  }}
                />
              </div>
            </Wrapper.Body>
          </Wrapper>
        )}
        {loading ? (
          <Wrapper>
            <Wrapper.Body className="grid grid-cols-1 gap-4 lg:grid-cols-2">
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Wrapper.Body>
          </Wrapper>
        ) : (
          <Wrapper>
            <Wrapper.Header>
              <h2 className="font-semibold text-gray-800">
                <Text>productManagement.masterData.attributes.properties</Text>
              </h2>
            </Wrapper.Header>
            <Wrapper.Body className="grid grid-cols-1 gap-4 lg:grid-cols-1">
              <div className="space-y-8">
                <InputGroup
                  label="productManagement.masterData.attributes.attributeCode"
                  value={attributesData?.code}
                  disabled
                  append={
                    <span
                      className="input-group-text"
                      onClick={() => {
                        window.navigator.clipboard.writeText(
                          attributesData.code,
                        );
                        NotificationManager.success(
                          "global.toast.copy-msg",
                          "global.toast.copy-title",
                        );
                      }}
                    >
                      <Icon
                        className="ml-6 cursor-pointer text-primary"
                        icon={"files"}
                      />
                    </span>
                  }
                />
                <Toggler
                  disabled={!updatePermission}
                  label={"productManagement.masterData.attributes.isRequired"}
                  value={attributesData.isRequired}
                  setValue={handleSetData("isRequired")}
                />
                <Toggler
                  disabled={!updatePermission}
                  label={"productManagement.masterData.attributes.perLanguage"}
                  value={attributesData.perLanguage}
                  setValue={handleSetData("perLanguage")}
                />
                <Toggler
                  disabled={!updatePermission}
                  label={"productManagement.masterData.attributes.perChannel"}
                  value={attributesData.perChannel}
                  setValue={handleSetData("perChannel")}
                />
                <Toggler
                  disabled={!updatePermission}
                  label={
                    "productManagement.masterData.attributes.updatefromProviderAttribute"
                  }
                  value={attributesData?.provider?.connected}
                  setValue={e => {
                    attributesData.provider.connected = e;
                    setAttributesData((p: any) => ({
                      ...p,
                      provider: attributesData.provider,
                      isConnected: e,
                    }));
                  }}
                />
                <InputGroup
                  label="productManagement.masterData.attributes.order"
                  value={attributesData?.sortOrder}
                  setValue={handleSetData("sortOrder")}
                  type="number"
                  keyfilter="onlyNum"
                  disabled={!updatePermission}
                />
              </div>
            </Wrapper.Body>
          </Wrapper>
        )}
      </div>
    </Fragment>
  );
}
