import { NotificationManager } from "common/notifications";
import {
  Button,
  DeleteModal,
  Drawer,
  Form,
  Icon,
  InputGroup,
  MultiSelect,
  Skeleton,
  Text,
} from "components";
import { rules } from "constant";
import { useToggleState } from "hooks";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { ServiceApi, URLS } from "services";

type drawerProps = {
  isOpen: boolean;
  salesTemplateId?: string;
  selectedIndex?: any;
  selected?: any;
  toggle: () => void;
  data: any;
  getData: () => void;
};

export default function Phase({
  isOpen,
  salesTemplateId,
  data,
  toggle,
  getData,
  selected,
  selectedIndex,
}: drawerProps) {
  const [loadingPage, setLoadingPage] = useState(false);
  const [dataAdd, setDataAdd] = useState<any>(null);
  const [checkListItems, setCheckListItems] = useState([]);
  const [params] = useState({
    pageNumber: 1,
    pageSize: 10,
    keyword: "",
  });
  const [selectedName, setSelectedName] = useState<string>();
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    if (!isOpen) return;
    setLoadingPage(true);
    let newData = cloneDeep(selected);
    newData.checkListItemIds ??= [];
    newData.checkListItemIds = newData.checkListItemIds?.map(
      (e: any) => e.checkListItemId
    );
    setDataAdd(newData);
    setCheckListItems([]);
    const url = URLS.GET_CHECK_LIST_URL;
    const config = { params };
    ServiceApi.get(url, config).then(({ data }) => {
      let result = data.items.map((e: any) => ({ id: e.id, name: e.label }));
      setCheckListItems(result);
      setLoadingPage(false);
    });
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSetValue = (key: any) => {
    return (value: any) => setDataAdd((p: any) => ({ ...p, [key]: value }));
  };

  const submitDelete = (id: any) => {
    setLoadingButton(true);
    const dataNew = cloneDeep(data);
    dataNew.sellPhases ??= [];
    dataNew.sellPhases = dataNew.sellPhases.map((e: any) => {
      e.checkListItemIds = e.checkListItemIds.map(
        (e: any) => e.checkListItemId
      );
      return e;
    });
    const index = dataNew.sellPhases.findIndex((e: any) => e.id === id);
    dataNew?.sellPhases.splice(index, 1);
    const url = URLS.UPDATE_DETAILS_SELL_PHASES_URL(salesTemplateId);
    ServiceApi.put(url, dataNew)
      .then(() => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title"
        );
        getData();
        toggleDeleteModal();
        toggle();
      })
      .finally(() => setLoadingButton(false));
  };

  const onSubmitForm = () => {
    const dataNew = cloneDeep(data);
    dataNew.sellPhases ??= [];
    dataNew.sellPhases = dataNew.sellPhases.map((e: any) => {
      e.checkListItemIds ??= [];
      e.checkListItemIds = e.checkListItemIds.map(
        (e: any) => e.checkListItemId
      );
      return e;
    });
    const index = dataNew.sellPhases.findIndex((e: any) => e.id === dataAdd.id);
    dataNew.sellPhases[index] = dataAdd;
    setLoadingPage(true);
    const url = URLS.UPDATE_DETAILS_SELL_PHASES_URL(salesTemplateId);
    ServiceApi.put(url, dataNew)
      .then(() => {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title"
        );
        getData();
        toggle();
      })
      .finally(() => {
        setLoadingPage(false);
      });
  };

  return (
    <Drawer as={Form} onSubmit={onSubmitForm} isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pt-6 pr-4">
          <div className="flex items-center justify-between">
            <h2 className="text-gray-800 text-heading-2 mt-2">
              <Text>salesManagment.sellOffSales.salesTemplates.editPhase</Text>
              <span>{selectedIndex}</span>
            </h2>
            <div className="flex items-center justify-between">
              {selectedIndex !== 1 ? (
                loadingPage ? (
                  <Skeleton.Button />
                ) : (
                  <Button
                    type="button"
                    variant="danger"
                    light
                    onClick={() => {
                      setSelectedName(dataAdd?.title);
                      toggleDeleteModal();
                    }}
                  >
                    <Icon className="mr-2" icon={"trash"} />
                    <Text>global.buttons.delete</Text>
                  </Button>
                )
              ) : (
                ""
              )}
              {loadingPage ? (
                <Skeleton.Button />
              ) : (
                <Button
                  type="button"
                  variant="light"
                  className="ml-2"
                  onClick={toggle}
                >
                  <Icon className="mr-2" icon={"times"} />
                  <Text>global.buttons.close</Text>
                </Button>
              )}
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-4 pr-4">
          {loadingPage ? (
            <>
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.TextArea />
              <Skeleton.Input />
            </>
          ) : (
            <>
              <InputGroup
                label="salesManagment.sellOffSales.salesTemplates.phaseName"
                value={dataAdd?.title}
                setValue={handleSetValue("title")}
                rules={rules.required}
              />
              <InputGroup
                label="salesManagment.sellOffSales.salesTemplates.discount"
                value={dataAdd?.discount}
                setValue={(discount) => {
                  if (discount >= 0 && discount <= 100) {
                    setDataAdd((p: any) => ({ ...p, discount: discount }));
                  }
                }}
                rules={rules.discount}
                keyfilter="pnum"
                append={
                  <h5 className="text-heading-5 font-normal text-gray-400 border-gray-300 border-l px-4 py-3">
                    %
                  </h5>
                }
              />
              <InputGroup
                type="number"
                label="salesManagment.sellOffSales.salesTemplates.duration"
                value={dataAdd?.duration}
                setValue={handleSetValue("duration")}
                rules={rules.required}
                append={
                  <h5 className="text-heading-5 font-normal text-gray-400 border-gray-300 border-l px-4 py-3">
                    Day
                  </h5>
                }
              />
              <InputGroup
                keyfilter="pnum"
                label="salesManagment.sellOffSales.salesTemplates.sellerCommission"
                value={dataAdd?.sellerCommission}
                setValue={(sellerCommission) => {
                  if (sellerCommission >= 0 && sellerCommission <= 100) {
                    setDataAdd((p: any) => ({
                      ...p,
                      sellerCommission: sellerCommission,
                    }));
                  }
                }}
                rules={rules.discount}
                append={
                  <h5 className="text-heading-5 font-normal text-gray-400 border-gray-300 border-l px-4 py-3">
                    %
                  </h5>
                }
              />
              <InputGroup
                as="short-textarea"
                label="salesManagment.sellOffSales.salesTemplates.shortDescription"
                value={dataAdd?.description}
                setValue={handleSetValue("description")}
              />
              {selectedIndex > 0 && (
                <MultiSelect
                  label="salesManagment.sellOffSales.salesTemplates.checkListItems"
                  items={checkListItems}
                  value={dataAdd?.checkListItemIds}
                  setValue={(value) => {
                    return setDataAdd((p: any) => ({
                      ...p,
                      checkListItemIds:
                        value?.map?.((val: any) => val.id) ?? [],
                    }));
                  }}
                />
              )}
            </>
          )}
        </Drawer.Body>
        <Drawer.Footer className="flex justify-between">
          {loadingPage ? (
            <Skeleton.Button />
          ) : (
            <Button
              type="button"
              className="mr-2"
              onClick={toggle}
              variant="light"
            >
              <Text>global.buttons.cancel</Text>
            </Button>
          )}
          {loadingPage ? (
            <Skeleton.Button />
          ) : (
            <Button type="submit" loading={loadingPage}>
              <Text>global.buttons.submit</Text>
            </Button>
          )}
        </Drawer.Footer>
      </Drawer.Menu>
      <DeleteModal
        isOpen={isOpenDeleteModal}
        toggle={toggleDeleteModal}
        selected={{ name: selectedName, id: selected?.id }}
        loading={loadingButton}
        onConfirm={submitDelete}
      />
    </Drawer>
  );
}
