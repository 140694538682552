import {
  Button,
  DatePicker,
  Drawer,
  Icon,
  InputGroup,
  SearchBox,
  Text,
  Toggler,
} from "components";
import Select from "react-select";
import {useTranslate} from "hooks";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {SalesService, SellOffProduct} from "types";
import {config, status} from "constant";

const SellOffProductStatus = ["Preparation", "Active", "Closed", "Sold"];

type drawerProps = {
  data: any;
  params: SalesService.SearchSellOffRequest;
  isOpen: boolean;
  toggle: () => void;
  setParams: (key: any) => void;
  resetFilter: () => void;
  setKeyword: (key: any) => void;
  keyword: any;
};

const FilterDrawer = ({
  isOpen,
  data,
  params,
  toggle,
  resetFilter,
  setParams,
}: drawerProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const translate = useTranslate();

  const [options, setOptions] = useState<any>({
    productFamily: [],
    brand: [],
    supplier: [],
    location: [],
    status: [],
  });

  useEffect(() => {
    if (isOpen) {
      ["productFamily", "brand", "supplier", "location", "status"].map(
        (key: string) => createItems(key),
      );
    }
  }, [isOpen]);

  const createItems = (key: string) => {
    const clone = data?.[key]?.map((item: any) => ({
      value: item.value,
      label:
        key === "status"
          ? SellOffProductStatus[parseInt(item.value)]
          : item.title + "(" + item.count + ")",
    }));
    setOptions((p: any) => ({...p, [key]: clone}));
    clone?.unshift({
      value: key === "status" ? 99 : null,
      label: translate("global.all"),
    });
    return clone;
  };

  const onChangeHandler = (key: string, val: any) => {
    setSearchParams({
      pageNumber: "1",
      pageSize: params.pageSize ? params?.pageSize.toString() : "10",
    });
    setParams((prev: any) => {
      return {
        ...prev,
        filter: true,
        pageNumber: 1,
        [key]: key === "status" ? parseInt(val) : val,
      };
    });
  };

  const submit = (key: any) => {
    return (value: any) => {
      setSearchParams({
        pageNumber: "1",
        pageSize: params.pageSize ? params.pageSize.toString() : "10",
      });
      setParams((prev: any) => {
        return {...prev, pageNumber: 1, filter: true, searchFields: value};
      });
    };
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pr-4 pt-6">
          <div className="flex items-center justify-between">
            <span className="text-heading-2 font-semibold">
              <Text>salesManagment.sellOffSales.sellOffProduct.filters</Text>
            </span>
            <div className="flex gap-x-2">
              {searchParams?.size !== 0 && (
                <Button
                  size="sm"
                  variant={"danger"}
                  light
                  onClick={() => resetFilter()}
                >
                  <Icon icon="times-circle" className="mr-1" />
                  <Text>
                    salesManagment.sellOffSales.sellOffProduct.resetFilters
                  </Text>
                </Button>
              )}
              <Button size="sm" variant={"light"} onClick={toggle}>
                <Icon icon="times" className="mr-1" />
                <Text>salesManagment.sellOffSales.sellOffProduct.close</Text>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-4 pr-4">
          <SearchBox
            value={params.searchFields}
            onSubmit={submit("searchFields")}
            className="w-full [&>*]:w-full"
            formClassName="md:w-full lg:w-full"
            variant="gray"
          />
          <label
            data-lang-map={"productManagement.products.Details.productFamily"}
            className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
          >
            <Text>{"salesManagment.sellOffSales.sellOffProduct.status"}</Text>
          </label>
          <Select
            styles={config.styleSelectReact}
            defaultValue={{id: null, label: translate("global.all")}}
            value={
              options?.status?.filter(
                (item: any) => item?.value === params?.status,
              )?.[0]
            }
            options={options?.status}
            onChange={(e: any) => {
              onChangeHandler("status", e?.value || null);
            }}
          />
          <label
            data-lang-map={"productManagement.products.Details.productFamily"}
            className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
          >
            <Text>
              {"salesManagment.sellOffSales.sellOffProduct.productFamily"}
            </Text>
          </label>
          <Select
            styles={config.styleSelectReact}
            defaultValue={{id: null, label: translate("global.all")}}
            value={
              options?.productFamily?.filter(
                (item: any) => item?.value === params?.pimProductFamilyCode,
              )?.[0]
            }
            options={options?.productFamily}
            onChange={(e: any) => {
              onChangeHandler("pimProductFamilyCode", e?.value || null);
            }}
          />
          <label
            data-lang-map={"productManagement.products.Details.productFamily"}
            className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
          >
            <Text>{"salesManagment.sellOffSales.sellOffProduct.brand"}</Text>
          </label>
          <Select
            styles={config.styleSelectReact}
            defaultValue={{id: null, label: translate("global.all")}}
            value={
              options?.brand?.filter(
                (item: any) => item?.value === params?.brandCode,
              )?.[0]
            }
            options={options?.brand}
            onChange={(e: any) => {
              onChangeHandler("brandCode", e?.value || null);
            }}
          />
          <label
            data-lang-map={"productManagement.products.Details.productFamily"}
            className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
          >
            <Text>
              {"salesManagment.sellOffSales.sellOffProduct.suppliers"}
            </Text>
          </label>
          <Select
            styles={config.styleSelectReact}
            defaultValue={{id: null, label: translate("global.all")}}
            value={
              options?.supplier?.filter(
                (item: any) => item?.value === params?.supplierCode,
              )?.[0]
            }
            options={options?.supplier}
            onChange={(e: any) => {
              onChangeHandler("supplierCode", e?.value || null);
            }}
          />
          <label
            data-lang-map={"productManagement.products.Details.productFamily"}
            className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
          >
            <Text>{"salesManagment.sellOffSales.sellOffProduct.location"}</Text>
          </label>
          <Select
            styles={config.styleSelectReact}
            defaultValue={{id: null, label: translate("global.all")}}
            value={
              options?.location?.filter(
                (item: any) => item?.value === params?.location,
              )?.[0]
            }
            options={options?.location}
            onChange={(e: any) => {
              onChangeHandler("location", e?.value || null);
            }}
          />
          {/* <Select
            label="salesManagment.sellOffSales.sellOffProduct.productFamily"
            placeholder="Select"
            value={params?.get("pimProductFamilyCode") ?? ""}
            items={createItems("productFamily")}
            setValue={onChange("pimProductFamilyCode")}
          />
          <Select
            label="salesManagment.sellOffSales.sellOffProduct.status"
            placeholder="Select"
            value={params?.get("status") ?? ""}
            items={createItems("status")}
            setValue={onChange("status")}
          />
          <Select
            label="salesManagment.sellOffSales.sellOffProduct.suppliers"
            placeholder="Select"
            value={params?.get("supplierCode") ?? ""}
            items={createItems("supplier")}
            setValue={onChange("supplierCode")}
          />
          <Select
            label="salesManagment.sellOffSales.sellOffProduct.store"
            placeholder="Select"
            value={params?.get("storeCode") ?? ""}
            items={createItems("store")}
            setValue={onChange("storeCode")}
          />
          <Select
            label="salesManagment.sellOffSales.sellOffProduct.location"
            placeholder="Select"
            value={params?.get("location") ?? ""}
            items={createItems("location")}
            setValue={onChange("location")}
          /> */}
          {/* <div className="grid grid-cols-2 gap-2 gap-y-4">
            <span className="col-span-2 text-sm text-gray-800">
              <Text>
                salesManagment.sellOffSales.sellOffProduct.startProccessDateRange
              </Text>
            </span>
            <DatePicker
              placeholderText="global.from"
              value={
                params?.get("startedDateFrom") !== null
                  ? new Date(params?.get("startedDateFrom") as string)
                  : null
              }
              onChange={onChange("startedDateFrom")}
              maxDate={
                params?.get("startedDateTo") !== null
                  ? new Date(params?.get("startedDateTo") as string)
                  : null
              }
            />
            <DatePicker
              placeholderText="global.to"
              value={
                params?.get("startedDateTo") !== null
                  ? new Date(params?.get("startedDateTo") as string)
                  : null
              }
              onChange={onChange("startedDateTo")}
              minDate={
                params?.get("startedDateFrom") !== null
                  ? new Date(params?.get("startedDateFrom") as string)
                  : null
              }
            />
            <span className="col-span-2 text-sm text-gray-800">
              <Text>
                salesManagment.sellOffSales.sellOffProduct.changePhaseDateRange
              </Text>
            </span>
            <DatePicker
              placeholderText="global.from"
              value={
                params?.get("phaseChangedFrom") !== null
                  ? new Date(params?.get("phaseChangedFrom") as string)
                  : null
              }
              maxDate={
                params?.get("phaseChangedTo") !== null
                  ? new Date(params?.get("phaseChangedTo") as string)
                  : null
              }
              onChange={onChange("phaseChangedFrom")}
            />
            <DatePicker
              placeholderText="global.to"
              value={
                params?.get("phaseChangedTo") !== null
                  ? new Date(params?.get("phaseChangedTo") as string)
                  : null
              }
              minDate={
                params?.get("phaseChangedFrom") !== null
                  ? new Date(params?.get("phaseChangedFrom") as string)
                  : null
              }
              onChange={onChange("phaseChangedTo")}
            />
          </div>
          <Toggler
            label={"salesManagment.sellOffSales.sellOffProduct.needPhaseChange"}
            value={params?.get("needChangePhase") === "true"}
            setValue={onChange("needChangePhase")}
          />
          <Toggler
            label={"salesManagment.sellOffSales.sellOffProduct.needClosePhase"}
            value={params?.get("needClosePhase") === "true"}
            setValue={onChange("needClosePhase")}
          />
          <Toggler
            label={"salesManagment.sellOffSales.sellOffProduct.hasProvision"}
            value={params?.get("hasFastSalesProvisionAmount") === "true"}
            setValue={onChange("hasFastSalesProvisionAmount")}
          /> */}
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
};

export default FilterDrawer;
