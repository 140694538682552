import { AxiosRequestConfig } from "axios";
import { Button, Icon, LoadingSvg, Text } from "components";
import { useConverter, useSelector, useToggleState } from "hooks";
import { useContext, useState } from "react";
import { ServiceApi } from "services";
import { ItemType } from "types/conversation";
import { CompletenessContext } from "../..";
import { dateFormats } from "constant";

type Props = {
  item: ItemType;
  handleReply: (item: ItemType) => void;
  showReplies?: () => void;
  parentReply?: ItemType;
}

type CheckListItemType = {
  checkListId: string;
  title: string;
  status: number;
};

export default function ConversationCard({ item, handleReply, showReplies, parentReply }: Props) {
  const [loadingFile, toggleLoadingFile] = useToggleState();
  const profile = useSelector(s => s.profile);
  const { checkList } = useContext(CompletenessContext);
  const { convertDate } = useConverter();
  const downloadFileHandler = async (url: string) => {
    let dataUrl = url;
    toggleLoadingFile();
    if (dataUrl?.includes("/get-file")) {
      const config: AxiosRequestConfig = {
        responseType: "blob",
        params: { withoutSubscriptionKey: true },
      };
      await ServiceApi.get(dataUrl, config).then(({ data }) => {
        dataUrl = URL.createObjectURL(data);
      });
    }
    toggleLoadingFile();
    return dataUrl;
  };

  const getCheckListData = () => {
    return checkList?.filter((i: CheckListItemType) => i.checkListId === item.checkListId)[0]?.title;
  }

  return (
    <div className={`p-4 rounded-2xl space-y-3
    ${item.createdUser.userId !== profile.userId ? 'bg-gray-100 rounded-bl-sm' : 'bg-primary-light rounded-br-sm'}`} id={item.id}>
      {item.replyOfId && parentReply?.note &&
        <>
          <p className="text-body-2">
            <span className="text-gray-500">
              <Text>productManagement.products.completeness.replyTo</Text>{' '}
            </span>
            <span className="text-gray-700">{parentReply.createdUser.fullName}</span>
          </p>
          <a className={`block rounded p-2 text-gray-700 text-body-2 
          ${item.createdUser.userId === profile.userId ? 'bg-gray-100' : 'bg-primary-light'}
        `} href={`#${item.replyOfId}`}>
            {parentReply?.note}
          </a>
        </>
      }
      <div className="flex flex-wrap gap-3 justify-between items-center">
        <p className="text-heading-6 font-semibold text-gray-800">{item.title}</p>
        <p className="text-gray-500 text-body-2">
          {convertDate(item.createdAt, dateFormats.fullWithoutSecond)}
        </p>
      </div>
      {!item.replyOfId && item.checkListId &&
        <div className="bg-gray-300 rounded p-2 flex items-center gap-2">
          <Icon icon="list" className="text-primary" size="sm" />
          <span className="text-gray-800 text-body-2 font-medium">{getCheckListData()}</span>
        </div>
      }
      <p>
        <span className="text-gray-500 text-body-2">
          <Text>productManagement.products.completeness.by</Text>:{' '}
        </span>
        <span className="text-gray-700 text-body-2">{item.createdUser.fullName}</span>
      </p>
      <p className="text-gray-700 text-body-2">{item.note}</p>
      {item.attachments?.map(file =>
        <p className="text-primary text-body-2 cursor-pointer flex items-center gap-2"
          key={file.url}
          onClick={async () => {
            const url = await downloadFileHandler(
              file?.url,
            );
            window.open(url, "_blank");
          }}>
          {loadingFile ?
            <span> <LoadingSvg size="sm" /></span> :
            <Icon icon="link" />
          }
          <span className="underline">{file?.name}...</span>
        </p>
      )}
      <div className="flex justify-between items-center">
        <p className="underline text-primary text-body-2 cursor-pointer">
          {item.repliedDiscussionCount > 0 && !item.replyOfId &&
            <div onClick={showReplies}>
              {item.repliedDiscussionCount}
              {' '} <Text>productManagement.products.completeness.replies</Text>
            </div>}
        </p>
        <Button variant='white' className="!p-2 !w-10 !h-10 self-end" onClick={() => handleReply(item)}>
          <Icon icon="reply" className="text-primary" size="lg" />
        </Button>
      </div>
    </div>
  );
}
