import ReactDOM from 'react-dom/client';
import App from './App';
import AppProviders from 'providers';
import './assets/css/index.css';
import './assets/icons/css/fontawesome.css';
import './assets/icons/css/duotone.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <AppProviders>
    <App />
  </AppProviders>,
);
