import {useEffect, useState} from "react";
import {Icon, Text, Button, Table, Pagination, Skeleton} from "components";
import {useParams} from "react-router-dom";
import {ServiceApi, URLS} from "services";
import AddDepartment from "./departments-drawer/Add";
import {useToggleState} from "hooks";
import DetailsDepartment from "./departments-drawer/details";
import {Departments as DepartmentsTypes} from "types";
import {usePermission} from "hooks";
import {withPermission} from "hoc";

function Departments() {
  const updatePermission = usePermission("AS_UpdateDepartment");
  const {storeId} = useParams();
  const [isOpenAdd, toggleAdd] = useToggleState();
  const [isOpenDetails, toggleDetails] = useToggleState();
  const [loading, setLoading] = useToggleState();
  const [departmentId, setDepartmentId] = useState<string>();
  const [params, setParams] = useState({
    keyword: "",
    pageNumber: 1,
    pageSize: 10,
    storeId,
  });

  const [data, setData] = useState<DepartmentsTypes.DepartmentDataType>();

  const getData = () => {
    setLoading();
    const url = URLS.GET_DEPARTMENTS_URL;
    const config = {params};
    ServiceApi.get(url, config)
      .then(({data}) => {
        setData(data);
        setLoading();
      })
      .catch(() => setLoading());
  };

  useEffect(getData, [params]);

  if (loading)
    return (
      <>
        {[1, 2, 3, 4].map(() => (
          <Skeleton.List />
        ))}
      </>
    );

  return (
    <>
      <div className="space-y-4">
        {updatePermission && (
          <div
            onClick={toggleAdd}
            className="flex cursor-pointer justify-center rounded-lg border border-dashed border-primary bg-primary-light p-4"
          >
            <span className="flex items-center gap-2 text-heading-5 font-semibold text-primary">
              <Icon icon="square-plus" />
              <Text>company.stores.addDepartment</Text>
            </span>
          </div>
        )}

        <Table>
          {data?.items?.map(department => {
            return (
              <tr>
                <td>
                  <div className="flex flex-col gap-4">
                    <span className="text-heading-6 font-semibold">
                      {department.title}
                    </span>
                    <span className="text-body-2 text-gray-500">
                      <Icon icon="envelope" /> {department.emailAddress}
                    </span>
                    <span className="flex items-center gap-2 text-body-2 text-gray-500">
                      <Icon icon="user" /> {department.leadEmployeeFirstName}{" "}
                      {department.leadEmployeeLastName}
                    </span>
                  </div>
                </td>
                <td className="text-wrap text-left leading-6">
                  <span className="text-body-2 font-normal text-gray-500">
                    {department.shortDescription}
                  </span>
                </td>
                <td>
                  <Button
                    variant={"light"}
                    type="button"
                    onClick={() => {
                      setDepartmentId(department?.id);
                      toggleDetails();
                    }}
                  >
                    <Text>company.stores.details</Text>
                  </Button>
                </td>
              </tr>
            );
          })}
        </Table>
        <Pagination
          pageNumber={params.pageNumber}
          pageSize={params.pageSize}
          totalItems={data?.totalItems}
          totalPages={data?.totalPages}
          setActivePage={value => setParams(p => ({...p, pageNumber: +value}))}
        />
      </div>
      <AddDepartment isOpen={isOpenAdd} toggle={toggleAdd} getData={getData} />
      {departmentId && (
        <DetailsDepartment
          isOpen={isOpenDetails}
          toggle={toggleDetails}
          departmentId={departmentId}
          getData={getData}
        />
      )}
    </>
  );
}

export default withPermission(Departments, ["AS_GetDepartment"]);
