const PRICE_TEMPLATES = {
  // ------------- preparation phase-------------------
  GET_PRICE_TEMPLATES_URL: "/productservice/api/price-templates",
  CREATE_PRICE_TEMPLATES_URL: "/productservice/api/price-templates",
  GET_PRICE_TEMPLATES_DETAIL_URL: id =>
    `/productservice/api/price-templates/${id}`,
  PUT_PRICE_TEMPLATES_URL: id => `/productservice/api/price-templates/${id}`,
  GET_PRICE_TEMPLATES_DRODOWN_URL:
    "/productservice/api/price-templates/dropdown",
  GET_PRICE_TEMPLATES_ITEMS: id =>
    `/productservice/api/price-templates/${id}/items`,
  CREATE_PRICE_TEMPLATES_ITEMS: id =>
    `/productservice/api/price-templates/${id}/items`,
  UPDATE_PRICE_TEMPLATES_ITEMS: (id, itemId) =>
    `/productservice/api/price-templates/${id}/items/${itemId}`,
  DELETE_PRICE_TEMPLATES_ITEMS: (id, itemId) =>
    `/productservice/api/price-templates/${id}/items/${itemId}`,
  GET_PRICE_TEMPLATES_DISCOUNTS: id =>
    `/productservice/api/price-templates/${id}/discounts`,
  CREATE_PRICE_TEMPLATES_DISCOUNTS: id =>
    `/productservice/api/price-templates/${id}/discounts`,
  UPDATE_PRICE_TEMPLATES_DISCOUNTS: (id, itemId) =>
    `/productservice/api/price-templates/${id}/discounts/${itemId}`,
  DELETE_PRICE_TEMPLATES_DISCOUNTS: (id, itemId) =>
    `/productservice/api/price-templates/${id}/discounts/${itemId}`,
};

export default PRICE_TEMPLATES;
