import {Fragment, useContext} from "react";
import cloneDeep from "lodash/cloneDeep";
import {InputGroup, Text, Wrapper} from "components";
import {StoreContext} from ".";
import {usePermission} from "hooks";

export default function Setting() {
  const updatePermission = usePermission("AS_UpdateStore");
  const {storeData, setStoreData} = useContext(StoreContext);
  const handleChangeSales = key => {
    return value =>
      setStoreData(p => {
        const data = cloneDeep(p);
        data.salesSettings[key] = value;
        return data;
      });
  };
  return (
    <Fragment>
      <Wrapper>
        <Wrapper.Header>
          <h2>
            <Text>company.stores.salesSetting</Text>
          </h2>
        </Wrapper.Header>
        <Wrapper.Body>
          <div className="s-full space-y-8 lg:w-1/2">
            <InputGroup
              label="company.stores.iwofurnSalesCalculationFactor"
              value={storeData.salesSettings.iwofurnSalesCalculationFactor}
              setValue={handleChangeSales("iwofurnSalesCalculationFactor")}
              type="number"
              disabled={!updatePermission}
            />
            <InputGroup
              label="company.stores.iwofurnRoundingMethod"
              value={storeData.salesSettings.iwofurnRoundingMethod}
              setValue={handleChangeSales("iwofurnRoundingMethod")}
              disabled={!updatePermission}
            />
          </div>
        </Wrapper.Body>
      </Wrapper>
    </Fragment>
  );
}
