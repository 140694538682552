export const calculationUnits = {
  addCalculationUnits: "Berechnungseinheiten hinzufügen",
  addCalculationUnit: "Berechnungseinheit hinzufügen",
  details: "Details",
  title: "Titel",
  code: "Code",
  shortDescription: "Kurzbeschreibung",
  calculationUnitTypes: "Berechnungseinheitstypen",
  percent: "Prozent",
  amount: "Betrag",
  multiply: "Multiplizieren",
  pureProfit: "Prozent-Aufschlag",
  pulsRound: "Rundung (PULS)",
  simpleRound: "Einfache Rundung",
  minus: "Minus (-)",
  plus: "Plus (+)",
  defaultValue: "Standardwert (Optional)",
  active: "Aktiv",
  general: "Allgemein",
  availableInPriceTemplate: "In Preis-Template verfügbar",
  customizableLevels: "Anpassbare Ebenen",
  basicInfo: "Basisinformationen",
  typeAndValue: "Typ und Wert",
  avilableOnlyInPurchase: "Nur im Einkauf verfügbar",
  availableOnlyInSalesTemplate: "Nur in Verkaufs-Template verfügbar",
  availableInBothPurchaseAndSales:
    "Im Einkauf UND im Verkauf verfügbar",
  supplier: "Lieferant",
  program: "Programm",
  brand: "Marke",
  product: "Produkt",
  deleteUnit: "Einheit löschen",
  defaultValueInList: "Standardwert",
  minValue: "Mininmal Wert",
  maxValue: "Maximal Wert",
  typeWarning: "Beachte den Kalkulationsweg!",
};
