import {useContext} from "react";
import {Wrapper, Text, InputGroup, DatePicker, Skeleton} from "components";
import {rules} from "constant";
import {PurchasePriceContext} from "..";
import {usePermission} from "hooks";

export default function General() {
  const updatePermission = usePermission("PE_ModifyPurchasePriceTemplate");
  const {data, loading, setData} = useContext(PurchasePriceContext);

  const onChangeHandler = (key: string) => {
    return (value: any) => setData((p: any) => ({...p, [key]: value}));
  };

  return (
    <>
      <Wrapper>
        <Wrapper.Header>
          <Text>priceEngine.purchasePrice.basicInfo</Text>
        </Wrapper.Header>
        <Wrapper.Body className="grid grid-cols-2 gap-4">
          <div className="space-y-4 ">
            {loading ? (
              [1, 2].map(() => <Skeleton.Input />)
            ) : (
              <>
                <InputGroup
                  label={"priceEngine.purchasePrice.title"}
                  value={data?.title}
                  rules={rules.required}
                  setValue={onChangeHandler("title")}
                  disabled={!updatePermission}
                />
                <InputGroup
                  label={"priceEngine.purchasePrice.code"}
                  value={data?.code}
                  disabled
                />
              </>
            )}
            <div className="grid grid-cols-2 gap-4">
              {loading ? (
                [1, 2].map(() => <Skeleton.Input />)
              ) : (
                <>
                  <DatePicker
                    minDate={new Date()}
                    maxDate={data?.validToDate ? data?.validToDate : null}
                    placeholderText="priceEngine.purchasePrice.selectDate"
                    label="priceEngine.purchasePrice.from"
                    value={data?.validFromDate}
                    onChange={onChangeHandler("validFromDate")}
                    disabled={!updatePermission}
                  />
                  <DatePicker
                    minDate={
                      data?.validFromDate ? data?.validFromDate : new Date()
                    }
                    placeholderText="priceEngine.purchasePrice.selectDate"
                    label="priceEngine.purchasePrice.to"
                    value={data?.validToDate}
                    onChange={onChangeHandler("validToDate")}
                    disabled={!updatePermission}
                  />
                </>
              )}
            </div>
          </div>
          {loading ? (
            <Skeleton.TextArea />
          ) : (
            <InputGroup
              label={"priceEngine.purchasePrice.shortDescription"}
              value={data?.description}
              as={"textarea"}
              setValue={onChangeHandler("description")}
              disabled={!updatePermission}
            />
          )}
        </Wrapper.Body>
      </Wrapper>
    </>
  );
}
