export const suppliers = {
  addSupplier: "Neuen Lieferanten hinzufügen",
  supplierName: "Lieferantenname",
  code: "Code",
  name: "Name",
  title: "Titel",
  text: "Text",
  ilnNumber: "ILN (Internationale Standortnummer)",
  alterNativeIlnNumber: "Alternative ILN",
  iln: "ILN",
  taxId: "SteuerId",
  description: "Beschreibung",
  emailAddress: "E-Mail-Adresse",
  emailAddress1: "E-Mail-Adresse 1",
  emailAddress2: "E-Mail-Adresse 2",
  phoneNumber: "Telefonnummer",
  basicInfo: "Grundlegende Informationen",
  generalInfo: "Allgemeine Informationen",
  contactInfo: "Kontaktinformationen",
  program: "Programm",
  saleSetting: "Verkaufseinstellung",
  location: "Standort",
  review: "Überprüfung",
  configurable: "Konfigurierbar",
  manual: "Manuell",
  addProgram: "Hinzufügen",
  editProgram: "Programm bearbeiten",
  attribute: "Attribut",
  attributes: "Attribute",
  active: "Aktiv",
  deActive: "Inaktiv",
  addAttribute: "Attribut hinzufügen",
  required: "Erforderlich",
  generalInfoDesc: "Name, Beschreibung ...",
  contactInfoDesc: "Logo, E-Mail-Adresse ...",
  reviewDesc: "Informationen abschließen",
  locationDesc: "Lieferantenstandort",
  logo: "Logo",
  amount: "Betrag",
  discounts: "Rabatte",
  discount: "Rabatt",
  adddiscount: "Rabatt hinzufügen",
  supplierDiscount: "Lieferantenrabatt",
  programDiscount: "Programmrabatt",
  customText: "Benutzerdefinierter Text",
  customTitle: "Benutzerdefinierter Titel",
  dragChangeOrder: "Elemente ziehen, um die Reihenfolge zu ändern",
  sortOrder: "Sortierreihenfolge",
  zrNumber: "ZR-Nummer",
  iwofurnCode: "Iwofurn Code",
  settings: "Einstellungen",
  holidays: "Betriebsferien",
  addHoliday: "Betriebsferien hinzufügen",
  start: "Start",
  end: "Ende",
  lastOrder: "Letzte Bestellung",
  earliestShip: "Frühester Versand",
  startDate: "Startdatum",
  endDate: "Enddatum",
  lastOrderDate: "Datum der letzten Bestellung",
  earliestShipDate: "Datum des frühesten Versands",
  bankInfo: "Bankinformationen",
  defaultBankInfo: "Standard-Bankinformationen",
  bankName: "Bankname",
  accountOwnerName: "Kontoinhabername",
  iban: "IBAN",
  bic: "BIC",
  shipping: "Versand",
  defaultMinimumShippingTime: "Vertragliche Lieferzeit",
  none: "Keine",
  workDay: "Arbeitstag",
  calendarDay: "Kalendertag",
  holiday: "Betriebsferien",
  useDefaultMinimumShippingTime: "Vertragliche Lieferzeit verwenden",
  minimumShippingTime: "Mindestversandzeit",
  useDefaultBankInfo: "Standard-Bankinformationen verwenden",
  brand: "Marke",
  select: "Auswählen",
  contactPerson: "Kontaktpersonen",
  addContactPerson: "neuer Ansprechpartner",
  firstName: "Vorname",
  lastName: "Nachname",
  department: "Abteilungen",
  phone: "Telefonnummer",
  phone1: "Telefonnummer (Festnetz)",
  phone2: "Telefonnummer (mobil)",
  OFF: "OFF",
  minimumResponseTime: "Kundendienst-Antwortzeit",
  programs: "Programme",
  generalTab: "Allgemein",
  configurationTab: "Konfiguration",
  availabilityStatusTab: "Aktuelle Verfügbarkeit",
  programType: "Programmtyp",
  noProgram: "Kein Programm hinzugefügt",
  canAddProgram: "Button drücken, um ein eneues Programm hinzuzufügen",
  add: "Hinzufügen",
  supplierType: "Lieferantentyp",
  productGroup: "Warengruppe",
  modelNumber: "Modellnummer",
  version: "Version",
  salesPersonProvision: "Verkaufsprovision",
  channels: "Verkaufskanäle",
  publish: "veröffentlichen",
  rePublish: "Erneut veröffentlichen",
  unPublish: "Veröffentlichung aufheben",
  at: "in Kanal",
  thereIsNoChannel: "kein Kanal gewählt",
  details: "Details",
  availabilityStatus: {
    // list
    defaultStatus: "Aktiv (Kann verkauft, bestellt, disponiert werden)",
    statusDate: "von {{ fromDate }} bis {{ toDate }}",
    addStatusButton: "Hinzugügen",
    // form
    addStatusTitle: "Status anpassen",
    editStatusTitle: "Status anpassen",
    closeButton: "Schliessen",
    status: "Status",
    addValidationPeriod: "Gültigkeitszeitraum",
    fromDate: "vom",
    toDate: "bis",
    cancelButton: "Abbrechen",
    addButton: "Status hinzufügen",
    updateButton: "STatus update",
    addSuccessMessage: "Neuer Verfügbarkeitsstatus erstellt",
    editSuccessMessage: "Verfügbarkeitsstatus würde bearbeitet",
    // confirm
    removeStatusTitle: "Status entfernen",
    removeStatusText:
      "Diese Änderung kann nicht rückgängig gemacht werden. Sicher? ",
    cancelRemoveButton: "Abbrechen",
    removeStatusButton: "Status löschen",

    removeSuccessMessage:
      "Verfügbarkeitsstatus wurde gelöscht",
  },
  lastModifiedAt: "Letzte Änderung am",
  lastModifiedBy: "Letzte Änderung von",
};
