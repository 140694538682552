import {InputGroup, Skeleton, Text, Wrapper} from "components";
import {Fragment, useContext} from "react";
import {AttributesTypes} from "utils";
import {AttributesContext} from "..";

export default function AttributesType() {
  const {attributesData, loading} = useContext<any>(AttributesContext);

  return (
    <Fragment>
      <div className="flex">
        {loading ? (
          <Wrapper>
            <Wrapper.Body className="grid grid-cols-1 gap-4 lg:grid-cols-1">
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Wrapper.Body>
          </Wrapper>
        ) : (
          <Wrapper>
            <Wrapper.Header>
              <h2 className="font-semibold text-gray-800">
                <Text>productManagement.masterData.attributes.setting</Text>
              </h2>
            </Wrapper.Header>
            <Wrapper.Body className="grid grid-cols-1 gap-4 lg:grid-cols-2">
              <div className="space-y-8">
                <InputGroup
                  label="productManagement.masterData.attributes.attributeType"
                  value={AttributesTypes(attributesData?.type)}
                  disabled
                />
              </div>
            </Wrapper.Body>
          </Wrapper>
        )}
      </div>
    </Fragment>
  );
}
