import CustomerListPage from "pages/customers/customer-list";
import Details from "pages/customers/customer-list/details";

const CustomersList = [
  {
    path: "",
    element: <CustomerListPage />,
  },
  {
    path: ":customerId",
    element: <Details />,
  },
];

export default CustomersList;
