import {
  Accordion,
  Button,
  Drawer,
  HandleName,
  Icon,
  List,
  NoItems,
  Skeleton,
  Text,
} from "components";
import { Fragment, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ServiceApi } from "services";
import { ProductFamily } from "types";
import { SelectFromBasketContext } from ".";
export default function ChooseProductFamily() {
  const {
    basket,
    productFamily,
    setProductFamily,
    deleteAfterImport,
    goBack,
    onSubmitted,
  } = useContext(SelectFromBasketContext);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [data, setData] = useState<ProductFamily.Item[]>([]);

  const roots = data.filter((e) => !e.parentId);

  const isActive = (id: string) => {
    return productFamily?.pimProductFamilyId === id;
  };
  const renderProductFamilies = (items: ProductFamily.Item[] = roots) => {
    if (!items.length) return null;
    return items.map((item) => {
      const children = data.filter(
        (e) => e.parentId === item.pimProductFamilyId
      );
      const hasChildren = !!children.length;
      return (
        <Accordion.Item
          key={item.pimProductFamilyId}
          className="bg-white px-4 rounded-2xl shadow-nested"
        >
          <div className="flex items-center">
            <Accordion.Toggle className="flex-1">
              <div className="flex space-x-4 items-center font-medium">
                {hasChildren && (
                  <Icon
                    icon={"caret-right"}
                    className="transition-transform group-[.accordion-item.active]:rotate-90"
                  />
                )}
                <div
                  className={`space-y-2 text-left cursor-pointer select-none`}
                  // onClick={() => setIsOpen()}
                >
                  <span className="text-heading-6 font-semibold">
                    <HandleName
                      translations={item.translates}
                      code={item.code}
                      keyName="labelTitle"
                    />
                  </span>
                  <p className="text-body-2 font-medium">{item.code}</p>
                </div>
              </div>
            </Accordion.Toggle>
            <button
              type="button"
              onClick={() => setProductFamily(item)}
              className="w-6 h-6 bg-gray-300 rounded transition-colors data-active:bg-primary-light"
              data-active={isActive(item.pimProductFamilyId)}
            >
              <Icon
                icon={"check"}
                className="text-primary opacity-0 transition-opacity data-active:opacity-100"
                data-active={isActive(item.pimProductFamilyId)}
              />
            </button>
          </div>
          {hasChildren && (
            <Accordion.Body className="px-4 space-y-2">
              <Accordion>{renderProductFamilies(children)}</Accordion>
            </Accordion.Body>
          )}
        </Accordion.Item>
      );
    });
  };

  const getProductFamilies = () => {
    setLoading(true);
    const url = "/productservice/api/pimproductfamilies";
    ServiceApi.get(url)
      .then(({ data }) => {
        setData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const submit = () => {
    setSubmitLoading(true);
    const url = `/productservice/api/pimproducts/importfromiwofurn/${basket?.internalTag}`;
    const pimProductFamilyId = productFamily?.pimProductFamilyId;
    const body = { pimProductFamilyId, deleteAfterImport };
    ServiceApi.post(url, body)
      .then(() => {
        toast.success("productManagement.products.allProducts.successMessage");
        onSubmitted();
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  useEffect(getProductFamilies, []);
  return (
    <Fragment>
      <Drawer.Header className="border-b-0 space-y-4">
        <fieldset className="text-start">
          <legend>
            <Text>
              productManagement.products.allProducts.chooseProductFamily
            </Text>
          </legend>
        </fieldset>
      </Drawer.Header>
      <Drawer.Body className="space-y-4">
        {loading ? (
          [...Array(10).keys()].map((key) => <Skeleton.Input key={key} />)
        ) : !data?.length ? (
          <NoItems />
        ) : (
          <List>
            <Accordion>{renderProductFamilies()}</Accordion>
          </List>
        )}
      </Drawer.Body>
      {!loading && (
        <Drawer.Footer className="flex items-center justify-between gap-6">
          {/* <div className="flex-1">
          <CheckBox
            value
            label="productManagement.products.allProducts.removeBasketAfterImport"
          />
        </div> */}
          <Button type="button" variant="light" size="sm" onClick={goBack}>
            <Text>productManagement.products.allProducts.backBtn</Text>
          </Button>
          <Button
            type="button"
            size="sm"
            variant="primary"
            onClick={submit}
            disabled={!productFamily}
            loading={submitLoading}
          >
            <Text>productManagement.products.allProducts.importBasket</Text>
          </Button>
        </Drawer.Footer>
      )}
    </Fragment>
  );
}
