import {
  Button,
  Drawer,
  Form,
  Icon,
  ImageUploader,
  InputGroup,
  LazyImage,
  Select,
  Text,
} from "components";
import { rules, types, assetTypes } from "constant";
import { SelectInfoPage, SelectProduct } from "containers";
import { useToggleState } from "hooks";
import { cloneDeep } from "lodash";
import { Fragment, useContext, useState } from "react";
import { ServiceApi } from "services";
import { toggleProps } from "types";
import { InfoPageContext } from "../..";
import { layoutStyleType, layoutType } from "../../../type";
import { NotificationManager } from "common/notifications";

type dataType = layoutType;
type editBannerComponentProps = toggleProps & {
  initData: dataType;
};
export default function EditBanner({
  isOpen,
  toggle,
  initData,
}: editBannerComponentProps) {
  const { infoPageData, setInfoPageData } = useContext(InfoPageContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initData);
  const [showImage, toggleImage] = useToggleState(false);
  const isNopBanner = data.infoPageLayoutType === 5;

  const isInfoPage = data.linkType === 3;
  const isExternalLink = data.linkType === 6;
  const isChannelProduct = data.linkType === 9;
  const isButton = data.linkType === 12;

  const handleSetValue = (key: keyof dataType) => {
    return (value: any) => {
      const isProducts = key === "products";
      setData(p => ({
        ...p,
        [key]: value,
        ...(isProducts && { products: [value] }),
      }));
    };
  };
  const handleSetStyle = (key: keyof layoutStyleType) => {
    return (value: any) =>
      setData(p => {
        const data = cloneDeep(p);
        data.style ??= {
          backgroundColor: "",
          titleTextColor: "",
          bodyTextColor: "",
          buttonBackgroundColor: "",
          buttonTextColor: "",
          other: null,
        };
        data.style[key] = value;
        return data;
      });
  };

  const submit = () => {
    setLoading(true);
    const url = `/productservice/api/infopages/${infoPageData.id}/layout/${data.id}`;
    const body = { ...data };
    ServiceApi.put(url, body)
      .then(() => {
        setInfoPageData(p => {
          const data = cloneDeep(p);
          const layoutIndex = data.infoPageLayouts.findIndex(
            e => e.id === body.id,
          );
          data.infoPageLayouts[layoutIndex] = body;
          return data;
        });
        setData(body);
        toggle();
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Form onSubmit={submit}>
        <Drawer.Menu>
          <Drawer.Header>
            <h2 className="text-start">
              <Text>
                {
                  types.infoPageLayout.find(
                    e => e.id === data.infoPageLayoutType,
                  )?.name
                }
              </Text>
            </h2>
          </Drawer.Header>
          <Drawer.Body className="space-y-8 overflow-auto">
            <LazyImage
              onClick={toggleImage}
              src={data.url}
              className="aspect-image"
              editor
              isDynamic
            />
            <ImageUploader
              isOpen={showImage}
              toggle={toggleImage}
              image={data.url}
              onUpload={image => setData(p => ({ ...p, url: image.url }))}
              type={assetTypes.Applications}
            />
            <InputGroup
              label="applications.infoPage.bannerTitle"
              value={data.title}
              setValue={handleSetValue("title")}
              rules={rules.required}
            />
            <InputGroup
              as="short-textarea"
              label="applications.infoPage.bannerDescription"
              value={data.subTitle}
              setValue={handleSetValue("subTitle")}
              rules={rules.required}
            />
            <Select
              label="applications.infoPage.bannerLinkAssignedToItem"
              value={data.linkType}
              setValue={handleSetValue("linkType")}
              rules={rules.required}
              items={types.mobileAppLink}
            />
            {isButton && (
              <Fragment>
                <InputGroup
                  label="applications.infoPage.bannerButtonLabel"
                  value={data.linkTitle}
                  setValue={handleSetValue("linkTitle")}
                  rules={rules.required}
                />
                <InputGroup
                  label="applications.infoPage.bannerButtonLink"
                  value={data.link}
                  setValue={handleSetValue("link")}
                  rules={rules.required}
                />
              </Fragment>
            )}
            {isNopBanner && (
              <fieldset className="space-y-8">
                <legend>
                  <Text>Styles EN</Text>
                </legend>
                <InputGroup
                  label="applications.infoPage.bannerBackgroundColor"
                  value={data.style?.backgroundColor}
                  setValue={handleSetStyle("backgroundColor")}
                  type="color"
                  rules={rules.color}
                />
                <InputGroup
                  label="applications.infoPage.bannerTitleTextColor"
                  value={data.style?.titleTextColor}
                  setValue={handleSetStyle("titleTextColor")}
                  type="color"
                  rules={rules.color}
                />
                <InputGroup
                  label="applications.infoPage.bannerBodyTextColor"
                  value={data.style?.bodyTextColor}
                  setValue={handleSetStyle("bodyTextColor")}
                  type="color"
                  rules={rules.color}
                />
                <InputGroup
                  label="applications.infoPage.bannerButtonBackgroundColor"
                  value={data.style?.buttonBackgroundColor}
                  setValue={handleSetStyle("buttonBackgroundColor")}
                  type="color"
                  rules={rules.color}
                />
                <InputGroup
                  label="applications.infoPage.bannerButtonTextColor"
                  value={data.style?.buttonTextColor}
                  setValue={handleSetStyle("buttonTextColor")}
                  type="color"
                  rules={rules.color}
                />
              </fieldset>
            )}
            {isExternalLink && (
              <InputGroup
                label="applications.infoPage.bannerLink"
                value={data.link}
                setValue={handleSetValue("link")}
                rules={rules.required}
              />
            )}
            {isInfoPage && (
              <SelectInfoPage
                value={data.link}
                setValue={handleSetValue("link")}
              />
            )}
            {isChannelProduct && (
              <SelectProduct
                value={data.products}
                setValue={handleSetValue("products")}
              />
            )}
          </Drawer.Body>
          <Drawer.Footer className="flex items-center justify-between">
            <Button type="button" variant="white" size="sm" onClick={toggle}>
              <Icon icon="close" />{" "}
              <Text>applications.infoPage.bannerCancelButton</Text>
            </Button>
            <Button type="submit" variant="primary" size="sm" loading={loading}>
              <Text>applications.infoPage.bannerSubmitButton</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Form>
    </Drawer>
  );
}
