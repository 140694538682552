export const dashboard = {
  "title": "Dashboard",
  "subTitle": "Startseite",
  "productsCompleteness": "Produkt vollständig",
  "channel": "Kanal",
  "publishedProducts": "Veröffentlichte Produkte",
  "recentActivity": "Letzte Aktivität",
  "recentActivityFilters": {
    "last10Days": "Die letzten 10 Tage",
    "week": "Woche",
    "month": "Monat"
  },
  "recentlyAdded": "Kürzlich hinzugefügt",
  "sellOffProducts": "Abverkaufsprodukte",
  "recentlySoldSellOffProducts": "Kürzlich verkaufte Abverkaufsprodukte",
  "soldAt": "Verkauft am",
  "soldSellOffProducts": "Verkaufte Abverkaufsprodukte",
  "soldSellOffProductsBadge": "Letzte 7 Tage",
  "totalPIMProducts": "Gesamtzahl PIM-Produkte",
  "totalPIMProductsItems": {
    "in7days": "Produkte in den letzten 7 Tagen",
    "newAdded": "Neu hinzugefügt",
    "completed": "Abgeschlossen",
    "published": "Veröffentlicht"
  },
  "sellOffProduct": {
    "preparation": "Vorbereitung",
    "active": "Aktiv",
    "sold": "Verkauft",
    "closed": "Geschlossen"
  },
  "soldSellOffProduct": {
    "count": "Anzahl"
  },
};

