import {useContext, useEffect, useState} from "react";
import {
  Wrapper,
  Text,
  Button,
  Icon,
  Skeleton,
  CheckBox,
  Status,
} from "components";
import Chooser from "./common/chooser";
import Info from "./common/info";
import {useToggleState, useConverter} from "hooks";
import {config} from "constant";
import {ServiceApi, URLS} from "services";
import {SimulationContext} from "../..";

type dataType = {
  name: string;
  code?: string;
  id: string;
  active: boolean;
};

export default function SalesPrice() {
  const {convertAmount} = useConverter();
  const {
    setSimulateData,
    simulateData,
    optionalDiscountCalculationUnitIds,
    setOptionalDiscountCalculationUnitIds,
  } = useContext(SimulationContext);
  const [isOpen, toggle] = useToggleState();
  const [loading, setLoading] = useToggleState();
  const [loadingDiscounts, toggleDiscounts] = useToggleState();
  const [discounts, setDiscounts] = useState([]);
  const [params, setParams] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  });
  const [templates, setTemplates] = useState<dataType[]>([
    {
      name: "",
      code: "",
      id: "",
      active: false,
    },
  ]);

  const getTemplates = () => {
    setLoading();
    const url = URLS.GET_SALES_PRICE_TEMPLATES;
    const config = {params};
    ServiceApi.get(url, config)
      .then(({data}) => {
        const clone = data?.items?.map((template: any) => ({
          id: template.id,
          name: template.title,
          code: template.code,
          active: template.active,
        }));
        setTemplates(clone);
        setLoading();
      })
      .catch(() => setLoading());
  };

  const getAvailableDiscounts = () => {
    toggleDiscounts();
    const url = URLS.GET_AVAILABLE_OPTIONAL_DISCOUNTS;
    const {salePriceTemplate, supplier, product, program, brand} = simulateData;
    const body = {
      salePriceTemplateId: salePriceTemplate?.id,
      supplierId: supplier?.id,
      programId: program?.id,
      brandId: brand?.id,
      productId: product?.id,
    };
    ServiceApi.post(url, body)
      .then(({data}) => {
        setDiscounts(data);
        toggleDiscounts();
      })
      .catch(() => toggleDiscounts());
  };

  useEffect(() => {
    if (simulateData?.salePriceTemplate) {
      getAvailableDiscounts();
    }
  }, [simulateData]);

  const convertTypeValue = (key: number, val: number) => {
    let value;
    switch (key) {
      case 0:
        value = val + " %";
        break;
      case 1:
        value = convertAmount(val);
        break;
      case 2:
        value = val;
        break;
      case 3:
        value = convertAmount(val);
        break;
    }
    return value;
  };

  const generateClassName = (key: number) => {
    return key ? "text-danger" : key === 0 ? "text-success" : "";
  };

  return (
    <>
      <Wrapper>
        <Wrapper.Header>
          <Text>priceEngine.simulator.salesPriceTemplate</Text>
        </Wrapper.Header>
        <Wrapper.Body>
          {simulateData?.["salePriceTemplate"] ? (
            <div className="space-y-4">
              <Info dataKey="salePriceTemplate" />
              {loadingDiscounts ? (
                [1, 2].map(() => <Skeleton.SimpleList />)
              ) : (
                <>
                  {discounts?.map((discount: any) => {
                    return (
                      <div className="flex items-center justify-between">
                        <div>
                          <CheckBox
                            value={optionalDiscountCalculationUnitIds?.includes(
                              discount?.calculationUnitId,
                            )}
                            label={discount?.title}
                            setValue={() => {
                              let clone = [
                                ...optionalDiscountCalculationUnitIds,
                              ];
                              if (
                                optionalDiscountCalculationUnitIds?.includes(
                                  discount?.calculationUnitId,
                                )
                              ) {
                                clone = clone.filter(
                                  id => id !== discount?.calculationUnitId,
                                );
                              } else {
                                clone.push(discount?.calculationUnitId);
                              }
                              setOptionalDiscountCalculationUnitIds(clone);
                            }}
                          />
                        </div>
                        <p className="text-body-base text-gray-700">
                          <Text>value</Text> :{" "}
                          <span
                            className={`${generateClassName(
                              discount?.operationType,
                            )}`}
                          >
                            {convertTypeValue(
                              discount?.valueType,
                              discount?.itemValue,
                            ) ?? "---"}
                          </span>
                        </p>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          ) : (
            <Button light size="sm" onClick={toggle}>
              <Icon icon="plus" className="mr-2" />
              <Text>priceEngine.simulator.assignSalesPriceTemplate</Text>
            </Button>
          )}
        </Wrapper.Body>
      </Wrapper>
      <Chooser
        isOpen={isOpen}
        toggle={toggle}
        title="priceEngine.simulator.salesPriceTemplate"
        data={templates}
        getData={getTemplates}
        loading={loading}
        setParams={setParams}
        params={params}
        dataKey="salePriceTemplate"
        setSimulateData={setSimulateData}
      />
    </>
  );
}
