import { NotificationManager } from "common/notifications";
import {
  AddressViewer,
  Breadcrumb,
  Form,
  ImageUploader,
  Skeleton,
  Tabs,
  Text,
  Wrapper,
  LazyImage,
  DeleteModal,
} from "components";
import { withPermission } from "hoc";
import { useDataState, usePermission, useToggleState, useTranslate } from "hooks";
import React, {
  Fragment,
  createContext,
  createElement,
  useEffect,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { ServiceApi, URLS } from "services";
import { setCompanySetting } from "store/companySetting";
import { setProfile } from "store/profile";
import { Media } from "types";
import General from "./General";
import Language from "./Language";
import { assetTypes } from "constant";
import { ValidateAddress } from "utils";
import PriceCalculation from "./priceCalculation";
import Templates from "./templates";

type dataType = {
  companyId?: string;
  prefixNumber?: number;
  prefixOrderNumber?: number;
  avatarUrl?: string | null;
  name?: string | null;
  city?: string | null;
  country?: string | null;
  houseNo?: string | null;
  postalCode?: string | null;
  state?: string | null;
  street?: string | null;
  address?: any;
  channels?: any;
  logo?: any;
};

export const ProfileContext = createContext({});

const ProfileCompany: React.FunctionComponent = () => {
  const updatePermissions = usePermission("AS_UpdateMyCompany");
  const controller = new AbortController();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [addressErrorMsg, setAddressErrorMasg] = useState("");
  const [data, setData, setBaseData, isChanged, base] = useDataState<dataType>(
    {},
  );
  const [
    settingData,
    setSettingData,
    setBaseSettingData,
    isChangedSetting,
    settingBase,
  ] = useDataState<any>({});
  const [isOpen, toggle] = useToggleState(false);
  const [selectedTab, SetTab] = useState("company.profile.general");
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState(false);
  const [loadingDeleteButton, setLoadingDeleteButton] = useState(false);
  const translate = useTranslate();

  const tabs = [
    {
      label: "company.profile.general",
      component: General,
    },
    {
      label: "company.profile.setting",
      component: Language,
    },
    {
      label: "priceEngine.calculationLevel.priceCalculationUnits",
      component: PriceCalculation,
    },
    {
      label: "company.profile.templates",
      component: Templates,
    },
  ];

  const updateUserProfile = async () => {
    const result = await ServiceApi.get(URLS.B2B_USER_URL);
    dispatch(setProfile(result.data));
  };

  const getCompanyProfile = () => {
    const url = URLS.MY_COMPANY_URL;
    setLoading(true);
    ServiceApi.get(url, { signal: controller.signal })
      .then(async response => {
        if (response) {
          setBaseData(response.data);
          updateUserProfile();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCompanyProfile();
    return () => {
      controller.abort();
    };
  }, []);

  const onSubmitGeneral = () => {
    setAddressErrorMasg("");
    setSubmitLoading(true);
    if (!data.address) submitRequest();
    else
      ValidateAddress(data.address).then(res => {
        if (!res?.selected && !res.suggested) {
          setAddressErrorMasg(
            translate("global.locations.invalidAddress") as string,
          );
          setSubmitLoading(false);
          return;
        }
        if (res.selected) {
          setData({
            ...data,
            address: {
              ...res.selected,
              name: data.address.name,
              number: data.address.number,
            },
          });
        }
        if (res.suggested) {
          setData({
            ...data,
            address: {
              ...res.suggested,
              name: data.address.name,
              number: data.address.number,
            },
          });
          setAddressErrorMasg(
            translate("global.locations.changedAddress") as string,
          );
          setSubmitLoading(false);
          return;
        }
        submitRequest();
      });
  };

  const submitRequest = () => {
    const url = URLS.MY_COMPANY_URL;
    const body = { ...data };
    delete body.companyId;
    delete body.prefixNumber;
    delete body.prefixOrderNumber;
    delete body.avatarUrl;
    delete body.channels;
    delete body.logo;

    ServiceApi.put(url, body)
      .then(async () => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
        getCompanyProfile();
        updateUserProfile();
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const onSubmitSetting = () => {
    const url = URLS.UPDATE_COMPANY_SETTING;
    setSubmitLoading(true);

    ServiceApi.post(url, settingData)
      .then(async () => {
        dispatch(setCompanySetting(settingData));
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
        getCompanyProfile();
        updateUserProfile();
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const onSubmit = () => {
    if (selectedTab === "company.profile.general") {
      onSubmitGeneral();
    } else if (selectedTab === "company.profile.setting") {
      onSubmitSetting();
    }
    //set basedata on call put api
  };

  const onUploadImage = (data: Media) => {
    // setData((p) => ({ ...p, avatarUrl: data.url }));
    const url = URLS.UPDATE_COMPANY_LOGI;
    const body = {
      url: data.url,
      thumbnailUrl: data.thumbnailUrl,
    };
    ServiceApi.patch(url, body).then(() => {
      NotificationManager.success(
        "global.toast.edit-msg",
        "global.toast.edit-title",
      );
      getCompanyProfile();
    });
  };

  const onDeleteImage = () => {
    setLoadingDeleteButton(true);
    const url = URLS.UPDATE_COMPANY_LOGI;
    ServiceApi.delete(url)
      .then(() => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title",
        );
        toggleDeleteModal();
        getCompanyProfile();
      })
      .finally(() => {
        setLoadingDeleteButton(false);
      });
  };

  return (
    <Fragment>
      <Tabs
        activeKey={tabs[0].label}
        checkSave={isChanged || isChangedSetting}
        onSave={() => {
          if (isChanged) {
            setData(base);
          } else if (isChangedSetting) {
            setBaseSettingData(settingBase);
          }
        }}
      >
        <Form className="relative space-y-6" onSubmit={onSubmit}>
          <Breadcrumb />
          <Wrapper>
            <Wrapper.Body className="flex flex-col items-start gap-5 lg:flex-row">
              <div className="h-[176px] w-[250px]">
                <LazyImage
                  onClick={() => {
                    if (updatePermissions) {
                      toggle();
                    }
                  }}
                  src={data.logo?.url}
                  alt={""}
                  logo
                  isDynamic
                  editor
                  onDeleteHandler={data.logo?.url && toggleDeleteModal}
                  className="aspect-image h-full w-full cursor-pointer lg:aspect-auto"
                />
              </div>
              {!loading && (
                <div className="space-y-4 lg:flex-1">
                  <h6 className="text-heading-6 text-gray-800">{data.name}</h6>
                  {
                    <AddressViewer
                      address={{
                        city: data?.address?.city,
                        country: data?.address?.country,
                        houseNo: data?.address?.houseNo,
                        postalCode: data?.address?.postalCode,
                        state: data?.address?.state,
                        street: data?.address?.street,
                      }}
                    />
                  }
                </div>
              )}
              {loading && (
                <div className="space-y-4 lg:flex-1">
                  <Fragment>
                    <Skeleton.Input />
                    <Skeleton.Input />
                  </Fragment>
                </div>
              )}
            </Wrapper.Body>
            <Wrapper.Footer className="!py-0">
              <Tabs.ButtonGroup>
                {tabs.map(e => (
                  <Tabs.Button
                    key={e.label}
                    eventKey={e.label}
                    onClick={e => SetTab(e)}
                  >
                    <Text>{e.label}</Text>
                  </Tabs.Button>
                ))}
              </Tabs.ButtonGroup>
            </Wrapper.Footer>
          </Wrapper>
          {loading ? (
            <Wrapper>
              <Wrapper.Body className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <Skeleton.Input />
                <Skeleton.Input />
                <Skeleton.Input />
                <Skeleton.Input />
                <Skeleton.Input />
                <Skeleton.Input />
              </Wrapper.Body>
            </Wrapper>
          ) : (
            tabs.map(e => (
              <Tabs.Item key={e.label} eventKey={e.label}>
                <ProfileContext.Provider
                  value={{
                    profileData: data,
                    setProfileData: setData,
                    settingData,
                    setSettingData,
                    setBaseSettingData,
                    isChangedSetting,
                    submitLoading,
                    isChanged,
                    addressErrorMsg,
                  }}
                >
                  {createElement(e.component)}
                </ProfileContext.Provider>
              </Tabs.Item>
            ))
          )}
        </Form>
        <ImageUploader
          isOpen={isOpen}
          toggle={toggle}
          image={data.logo?.url}
          onUpload={onUploadImage}
          type={assetTypes.Company}
        />
        <DeleteModal
          isOpen={isOpenDeleteModal}
          toggle={toggleDeleteModal}
          selected={{
            name: <Text>company.profile.companyProfileImage</Text>,
          }}
          loading={loadingDeleteButton}
          onConfirm={onDeleteImage}
        />
      </Tabs>
    </Fragment>
  );
};

export default withPermission(ProfileCompany, ["AS_GetMyCompany"]);
