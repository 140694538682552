import ApiKeys from "./api-keys";
import SsoSettings from "pages/configurations/global-settings/sso-settings";
import DataStorage from "pages/configurations/global-settings/data-storage";
import TemplateList from "pages/configurations/global-settings/templates";

const GlobalSettings = [
  {
    path: "api-keys",
    children: [...ApiKeys],
  },
  {
    path: "sso-settings",
    element: <SsoSettings />,
  },
  {
    path: "data-storage",
    element: <DataStorage />,
  },
  {
    path: "templates",
    element: <TemplateList />,
  },
];

export default GlobalSettings;
