export const attributesGroups = {
  addAttributeGroups: "Attributgruppen hinzufügen",
  code: "Code",
  name: "Name",
  title: "Titel",
  description: "Beschreibung",
  shortDescription: "Kurzbeschreibung",
  perChannel: "Pro Kanal",
  type: "Typ",
  attributes: "Attribute",
  attributeType: "Attributtyp",
  attributeTypeDesc: "Text, Datum, Ja/Nein ...",
  infoAndProperties: "Informationen und Eigenschaften",
  infoAndPropertiesDesc: "Name, Beschreibung ...",
  review: "Überprüfung",
  reviewDesc: "Informationen abschließen",
  attributeName: "Attributname",
  attributeCode: "Attributcode",
  groupName: "Gruppenname",
  groupCode: "Gruppencode",
  isRequired: "Erforderlich",
  perLanguage: "Pro Sprache",
  chooseAttributeType: "Attributtyp wählen",
  basicInfo: "Grundlegende Informationen",
  properties: "Eigenschaften",
  general: "Allgemein",
  channels: "Kanäle",
  addToGroup: "Zur Gruppe hinzufügen",
  selectAttribute: "Attribut auswählen",
  updatefromProviderAttribute: "Von Anbieterattribut aktualisieren",
  searchAttribute: "Attribute suchen",
  value: "Wert",
};
