import { NotificationManager } from "common/notifications";
import {
  AddressForm,
  Badge,
  Button,
  Icon,
  InputGroup,
  Text,
  Wrapper,
  Select,
} from "components";
import { rules } from "constant";
import { FormEvent, useContext, useState } from "react";
import { SuppliersContext } from "..";
import { usePermission } from "hooks";

export default function General() {
  const updatePermission = usePermission("PS_UpdateSupplier");
  const { suppliersData, setSuppliersData, addressErrorMsg } =
    useContext<any>(SuppliersContext);

  const supplierType = [
    {
      name: "TradingGoods",
      id: 0,
    },
    {
      name: "ConsumableGoods",
      id: 1,
    },
  ];

  const [iln, setIln] = useState("");
  const alternativeILN = suppliersData?.alternativeIln
    ? suppliersData?.alternativeIln?.split(";")
    : [];

  const handleSetData = (key: any) => {
    return (value: any) => setSuppliersData((p: any) => ({ ...p, [key]: value }));
  };

  const checkIlnExist = (iln: string) =>
    alternativeILN.some((i: string) => i === iln);

  const handleSetILN = (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (iln && !checkIlnExist(iln)) {
      const list = [...alternativeILN, iln];
      setSuppliersData({ ...suppliersData, alternativeIln: list.join(";") });
    }
    setIln("");
  };

  const handleRemoveILN = (selectedIln: string) => {
    const list = alternativeILN.filter((iln: string) => iln !== selectedIln);
    setSuppliersData({ ...suppliersData, alternativeIln: list.join(";") });
  };

  return (
    <>
      <Wrapper>
        <Wrapper.Header>
          <h2 className="text-heading-2 font-semibold text-gray-800">
            <Text>productManagement.masterData.suppliers.generalInfo</Text>
          </h2>
        </Wrapper.Header>
        <Wrapper.Body className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-8">
          <div className="space-y-8">
            <div className="col-start-1 col-end-2">
              <InputGroup
                label="productManagement.masterData.suppliers.supplierName"
                value={suppliersData.name}
                setValue={handleSetData("name")}
                rules={rules.required}
                disabled={!updatePermission}
              />
            </div>
            <div className="col-start-1 col-end-2">
              <InputGroup
                label="productManagement.masterData.suppliers.emailAddress"
                value={suppliersData.email}
                setValue={handleSetData("email")}
                rules={rules.emailAddress}
                disabled={!updatePermission}
              />
            </div>
            <div className="col-start-1 col-end-2">
              <InputGroup
                label="productManagement.masterData.suppliers.phoneNumber"
                value={suppliersData.phoneNumber}
                setValue={handleSetData("phoneNumber")}
                disabled={!updatePermission}
              />
            </div>
            <div>
              <Select
                label="productManagement.masterData.suppliers.supplierType"
                placeholder="Select"
                value={suppliersData?.supplierType}
                items={supplierType}
                setValue={handleSetData("supplierType")}
              />
            </div>
          </div>
          <div className="space-y-8">
            <div>
              <InputGroup
                disabled
                append={
                  <span
                    className="input-group-text"
                    onClick={() => {
                      window.navigator.clipboard.writeText(suppliersData.code);
                      NotificationManager.success(
                        "global.toast.copy-msg",
                        "global.toast.copy-title",
                      );
                    }}
                  >
                    <Icon
                      className="ml-6 cursor-pointer text-primary"
                      icon={"files"}
                    />
                  </span>
                }
                label="productManagement.masterData.suppliers.code"
                value={suppliersData.code}
              />
            </div>
            <div>
              <InputGroup
                label="productManagement.masterData.suppliers.ilnNumber"
                value={suppliersData.iln}
                setValue={handleSetData("iln")}
                disabled={!updatePermission}
              />
            </div>
            <div>
              <InputGroup
                label="productManagement.masterData.suppliers.taxId"
                value={suppliersData.taxId}
                setValue={handleSetData("taxId")}
                disabled={!updatePermission}
                keyfilter="alphanum"
              />
            </div>
            <div>
              <InputGroup
                as="short-textarea"
                label="productManagement.masterData.suppliers.description"
                value={suppliersData.description}
                setValue={handleSetData("description")}
                disabled={!updatePermission}
              />
            </div>
          </div>
        </Wrapper.Body>
      </Wrapper>
      <Wrapper>
        <Wrapper.Header>
          <h2 className="text-heading-2 font-semibold text-gray-800">
            {" "}
            <Text>
              productManagement.masterData.suppliers.alterNativeIlnNumber
            </Text>
          </h2>
        </Wrapper.Header>
        <Wrapper.Body className="space-y-3">
          <label className="h6 block w-full truncate font-[400] text-gray-800 ">
            <Text>
              productManagement.masterData.suppliers.alterNativeIlnNumber
            </Text>
          </label>
          <div className="flex flex-wrap gap-3">
            <div className="w-1/2">
              <InputGroup
                value={iln}
                setValue={setIln}
                disabled={!updatePermission}
                type="number"
                onPressEnter={e => handleSetILN(e)}
              />
            </div>
            <Button
              type="button"
              variant="primary"
              size="sm"
              light
              className="!h-auto"
              onClick={e => handleSetILN(e)}
            >
              <Icon icon="add" />{" "}
              <Text>productManagement.masterData.suppliers.add</Text>
            </Button>
          </div>
          <div className="flex flex-wrap gap-3">
            {alternativeILN?.map((iln: string) => (
              <Badge variant="gray" className="space-x-2" key={iln}>
                <Icon
                  icon="circle-xmark"
                  className="cursor-pointer"
                  onClick={() => handleRemoveILN(iln)}
                />
                <span>{iln}</span>
              </Badge>
            ))}
          </div>
        </Wrapper.Body>
      </Wrapper>
      <Wrapper>
        <Wrapper.Header>
          <h2 className="text-heading-2 font-semibold text-gray-800">
            {" "}
            <Text>productManagement.masterData.suppliers.location</Text>
          </h2>
        </Wrapper.Header>
        <Wrapper.Body className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-8">
          <section className="space-y-8">
            <AddressForm
              data={suppliersData}
              setData={setSuppliersData}
              errorMsg={addressErrorMsg}
            />
          </section>
        </Wrapper.Body>
      </Wrapper>
    </>
  );
}
