import {useId, useState} from "react";
import Text from "./Text";
import Dropdown from "./Dropdown";
import Icon from "./Icon";
import ReactQuill from "react-quill";
import "./TextEditor.scss";
import "quill/dist/quill.snow.css"; // Add css for snow theme
// or import 'quill/dist/quill.bubble.css'; // Add css for bubble theme
type Props = {
  value?: any;
  setValue: any;
  flag?: any;
  label?: any;
  hint?: any;
  actions?: any;
  withAi?: boolean;
  onClickAI?: any;
};

const TextEditor = ({
  value = "",
  setValue,
  flag,
  label,
  hint,
  actions,
  withAi = false,
  onClickAI,
}: Props) => {
  const [selectedItem, setSelectedItem] = useState("");
  var toolbarOptions = [
    [{header: [1, 2, 3, 4, 5, 6, false]}],
    ["bold", "italic", "underline", "strike"], // toggled buttons

    [{list: "ordered"}, {list: "bullet"}],
    [{indent: "-1"}, {indent: "+1"}], // outdent/indent

    [{color: []}, {background: []}], // dropdown with defaults from theme
    [{align: []}],

    ["clean"],
  ];

  const inputId = useId();

  return (
    <div>
      {label || flag ? (
        <div className="mb-4 flex items-center justify-between">
          {label && (
            <div>
              <label
                htmlFor={inputId}
                className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
              >
                {typeof label === "object" ? label : <Text>{label}</Text>}
              </label>
            </div>
          )}
          {(flag || hint) && (
            <div className="flex items-center gap-4">
              {hint && (
                <span className={`text-body-2 font-normal text-${hint?.color}`}>
                  {hint?.label}
                </span>
              )}
              <img className="block" src={flag} alt="" />
              {actions?.length > 0 && (
                <Dropdown onSelect={setSelectedItem}>
                  <Dropdown.Toggle
                    as="button"
                    icon={true}
                    type="button"
                    className="text-body-base font-medium uppercase text-gray-600"
                  >
                    <Icon icon="ellipsis-vertical"></Icon>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="mt-2 min-w-fit p-2">
                    <ul className="w-max text-gray-700">
                      {actions?.map((e: any) => (
                        <li
                          key={e.id}
                          onClick={value => {
                            setSelectedItem(e.id);
                            e.onClick(value);
                          }}
                          className={`h6 px-4 py-2 font-medium first:mb-1 ${
                            e.id === selectedItem &&
                            "bg-primary-light text-primary "
                          } cursor-pointer rounded-lg text-body-base hover:bg-primary-light hover:text-primary`}
                        >
                          {e.title}
                        </li>
                      ))}
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
      <div className="relative">
        <ReactQuill
          theme="snow"
          className="rounded-md bg-gray-100"
          value={value}
          onChange={(e, delta, source, editor) => {
            if (source === "user") {
              setValue(e);
            }
          }}
          placeholder="Write and Edit..."
          data-lang-map={label}
        />
        {withAi && (
          <span
            onClick={onClickAI}
            className="flex-center absolute bottom-4 right-4 h-6 w-6 cursor-pointer rounded-full bg-purple-600 text-xs text-white opacity-80"
          >
            Ai
          </span>
        )}
      </div>
    </div>
  );
};

export default TextEditor;
