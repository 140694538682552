export const productLabelTemplate = {
  addProductLabelTemplate: "Add Product label template",
  editProductLabelTemplate: "Product label template",
  title: "Title",
  active: "Active",
  isGlobal: "Is Global",
  file: "File",
  metaData: "Meta Data",
  addMetaData: "Add New Meta Data",
  key: "key",
  value: "value",
  add: "Add",
  duplicateKey: "key cannot be duplicated",
  language: "Language",
};
