import {Button, Icon, Skeleton, Text, DeleteModal} from "components";
import {DetailsContext} from "./details";
import {useContext, useEffect, useState} from "react";
import {Departments} from "types";
import {ServiceApi, URLS} from "services";
import {useToggleState} from "hooks";
import {NotificationManager} from "common/notifications";
import AddEmployeeToDepartment from "./Details-Add";

export default function EmployeesTab() {
  const {
    details,
    departmentId,
    loading: laodDetails,
    ids,
    getDetails,
  } = useContext(DetailsContext);
  const [loading, setLoading] = useToggleState();
  const [isOpen, toggle] = useToggleState();
  const [selected, setSelected] = useState<any>();
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState();
  const [employees, setEmployees] =
    useState<Departments.DepartmentDetailsEmployee[]>();

  useEffect(() => {
    setEmployees(details?.departmentEmployees);
  }, [details]);

  const onDeleteEmployee = (id: string) => {
    setLoading();
    const url = URLS.REMOVE_EMPLOYEE_FROM_DEPARTMENT(departmentId, id);
    ServiceApi.delete(url)
      .then(() => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title",
        );
        setLoading();
        getDetails();
        toggleDeleteModal();
      })
      .catch(() => setLoading());
  };

  return (
    <>
      <div className="space-y-6">
        {laodDetails ? (
          [1, 2, 3].map(() => <Skeleton.List />)
        ) : (
          <>
            <div
              onClick={toggle}
              className="flex cursor-pointer justify-center rounded-lg border border-dashed border-primary bg-primary-light p-4"
            >
              <span className="flex items-center gap-2 text-heading-5 font-semibold text-primary">
                <Icon icon="square-plus" />
                <Text>company.stores.addEmployee</Text>
              </span>
            </div>
            {employees?.map((emp: Departments.DepartmentDetailsEmployee) => {
              return (
                <div className="flex justify-between rounded-lg bg-white p-4 shadow-card">
                  <div className="flex flex-col gap-2">
                    <span className="text-heading-6 font-semibold">
                      {emp?.firstName} {emp?.lastName}
                    </span>
                    <span className="text-body-2 text-gray-500">
                      # {emp?.employeeId}
                    </span>
                  </div>
                  {
                    <Button
                      light
                      variant={"danger"}
                      size="sm"
                      onClick={() => {
                        setSelected(emp);
                        toggleDeleteModal();
                      }}
                    >
                      <Icon icon="trash" />
                    </Button>
                  }
                </div>
              );
            })}
          </>
        )}
      </div>
      <AddEmployeeToDepartment
        isOpen={isOpen}
        toggle={toggle}
        departmentId={departmentId}
        employeesIds={ids}
        getDetails={getDetails}
      />
      <DeleteModal
        isOpen={isOpenDeleteModal}
        toggle={toggleDeleteModal}
        selected={{
          name: `${selected?.firstName} ${selected?.lastName}`,
          id: selected?.employeeId,
        }}
        loading={loading}
        onConfirm={onDeleteEmployee}
      />
    </>
  );
}
