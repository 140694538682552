import {useState} from "react";
import {Dropdown, InputGroup, LoadingSvg, SelectCountry} from "..";
import {useSearchAddress} from "hooks";
import {debounce} from "utils";
import {CountrySelectOption} from "types/country";
import {AddressType} from "utils/convertAddress";

type SearchAddressProps = {
  setValue: (address: AddressType) => void;
  inputAriaLabel?: string;
  menuItemAriaLabel?: string;
  defaultCountry?: string;
  countryList?: CountrySelectOption[];
};

type addressItemsType = {
  name: string;
  id: string;
  address: any;
}[];

export default function SearchAddress({
  setValue,
  inputAriaLabel,
  menuItemAriaLabel,
  defaultCountry,
  countryList,
}: SearchAddressProps) {
  const [loading, setLoading] = useState(false);
  const searchAddress = useSearchAddress();
  const [search, setSearch] = useState<string>("");
  const [items, setItems] = useState<addressItemsType>([]);
  const [country, setCountry] = useState<CountrySelectOption>();

  const handleSetSearch = (query: string) => {
    setSearch(query);
    debounce(() => {
      const hasQuery = !!query;
      if (!hasQuery) {
        setItems([]);
      }
      handleSearchAddress(query);
    });
  };

  const handleSearchAddress = (query: string) => {
    if (!query) return setItems([]);
    setLoading(true);
    searchAddress({query, countrySet: country?.value || defaultCountry || "DE"})
      .then(setItems)
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSetAddress = (id: string) => {
    const address = items.find(e => e.id === id);
    if (address) {
      setValue(address.address);
      setSearch("");
    }
  };

  return (
    <div className="flex gap-2">
      <SelectCountry
        value={defaultCountry}
        setValue={setCountry}
        countryList={countryList}
      />
      <Dropdown
        data-active={"true"}
        className="block w-full"
        onSelect={handleSetAddress}
      >
        <Dropdown.Toggle
          ariaLabel={inputAriaLabel}
          as={InputGroup}
          label="global.locations.address"
          value={search}
          setValue={handleSetSearch}
          placeholder="global.locations.searchPlaceHolder"
          // prepend={
          //   <div hidden={!loading} className="input-group-text">
          //     <LoadingSvg size="sm" />
          //   </div>
          // }
          append={
            <>
              <div hidden={!loading} className="input-group-text">
                <LoadingSvg size="sm" />
              </div>
              <i className="bi bi-chevron-down input-group-text text-primary" />
            </>
          }
        />
        <Dropdown.Menu>
          {items.map((e: any) => (
            <Dropdown.Item
              aria-label={menuItemAriaLabel}
              key={e.id}
              eventKey={e.id}
            >
              {e.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
