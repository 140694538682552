import {
  createContext,
  Fragment,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import WithPermission from "./WithPermission";
import {Permission} from "types";
type tabContextType = {
  active: string | null;
  setActive: (val: string) => void;
  onSave?: () => void;
  checkSave?: boolean;
};
type tabsProps = {
  children: ReactNode;
  activeKey?: string | null;
  checkSave?: boolean;
  onSave?: () => void;
};
type tabWrapperProps = {
  children: ReactNode;
  className?: string;
};
type tabButtonProps = {
  children: ReactNode;
  eventKey: string;
  disabled?: boolean;
  onClick?: (e: string) => void;
  permission?: Permission[];
  className?: string;
};
type tabItemProps = {
  children: ReactNode;
  eventKey: string;
  permission?: Permission[];
};
const TabContext = createContext({} as tabContextType);
function Tabs({
  activeKey = null,
  children,
  checkSave = false,
  onSave,
}: tabsProps) {
  useEffect(() => {
    setActive(activeKey);
  }, [activeKey]);

  const [active, setActive] = useState(activeKey);
  return (
    <TabContext.Provider value={{active, setActive, checkSave, onSave}}>
      {children}
    </TabContext.Provider>
  );
}

function TabButtonGroup({children, className}: tabWrapperProps) {
  return (
    <div
      className={`flex w-full items-center gap-8 overflow-auto ${className}`}
    >
      {children}
    </div>
  );
}

function TabButton({
  eventKey,
  children,
  disabled,
  onClick,
  permission,
  className,
  ...props
}: tabButtonProps) {
  const {active, setActive, checkSave, onSave} = useContext(TabContext);
  const isActive = active === eventKey;

  const handleClick = () => {
    if (checkSave) {
      const response = window.confirm(
        "Are your sure you want discard your changes?",
      );
      if (response) {
        setActive(eventKey);
        onSave && onSave();
      }
    } else {
      onClick && onClick(eventKey);
      setActive(eventKey);
    }
  };
  return (
    <>
      {permission ? (
        <WithPermission permissions={permission}>
          <button
            disabled={disabled}
            data-active={isActive}
            type="button"
            onClick={handleClick}
            className={`min-w-fit whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-heading-5 font-semibold text-gray-500 transition-colors data-active:border-b-primary data-active:text-primary ${className}`}
            {...props}
          >
            {children}
          </button>
        </WithPermission>
      ) : (
        <button
          disabled={disabled}
          data-active={isActive}
          type="button"
          onClick={handleClick}
          className={`min-w-fit whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-heading-5 font-semibold text-gray-500 transition-colors data-active:border-b-primary data-active:text-primary ${className}`}
          {...props}
        >
          {children}
        </button>
      )}
    </>
  );
}
function TabItem({eventKey, children, permission}: tabItemProps) {
  const {active} = useContext(TabContext);
  const isActive = active === eventKey;
  return (
    <Fragment>
      {isActive && (
        <>
          {permission ? (
            <WithPermission permissions={permission}>children</WithPermission>
          ) : (
            children
          )}
        </>
      )}
    </Fragment>
  );
}
Tabs.ButtonGroup = TabButtonGroup;
Tabs.Button = TabButton;
Tabs.Item = TabItem;
export default Tabs;
