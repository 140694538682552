import { NotificationManager } from "common/notifications";
import {
  Button,
  Form,
  Icon,
  InputGroup,
  Modal,
  SelectLang,
  Text,
  Toggler,
} from "components";
import { flagCountry, rules } from "constant";
import { useDataState, useSelector } from "hooks";
import { FormEvent, useEffect, useRef, useState } from "react";
import { ServiceApi, URLS } from "services";

type udapteModalType = {
  isOpen: boolean;
  attributeId?: string;
  defaultValue?: string;
  selected?: any;
  toggle: () => void;
  getData: () => void;
};

export default function UpdateModal(props: udapteModalType) {
  const [data, setData] = useState<any>({});
  const [loadingButton, setLoadingButton] = useState(false);
  const [langItems, setLangItems] = useState([]);
  const [langSelected, setLangSelected] = useState("de");
  const [name, setName] = useState("");
  const companySetting = useSelector((s) => s.companySetting);
  const formRef = useRef<any>();

  useEffect(() => {
    if (props.isOpen) {
      setData({});
      setName("");
      setLangSelected(companySetting?.defaultContentLanguage?.toLocaleLowerCase());
      let arr: any = [];
      companySetting?.contentLanguages?.forEach((item: any) => {
        arr.push({ id: item?.toLocaleLowerCase(), name: item });
      });

      let newData = JSON.parse(JSON.stringify(props?.selected));
      if (newData?.translates?.length !== arr.length) {
        var propsNew = ["id"];
        var result = arr
          ?.filter(function (i: any) {
            return !newData?.translates?.some(function (a: any) {
              return (
                i?.id?.toLocaleLowerCase() === a?.language?.toLocaleLowerCase()
              );
            });
          })
          .map(function (o: any) {
            return propsNew?.reduce(function (newo: any, language: any) {
              newo["id"] = o[language];
              return newo;
            }, {});
          });

        result?.forEach((item: any) => {
          newData?.translates?.push({
            language: item.id,
            labelTitle: "",
          });
        });
      }
      newData?.translates?.forEach((item: any) => {
        if (
          item?.language?.toLocaleLowerCase() ===
          companySetting?.defaultContentLanguage?.toLocaleLowerCase()
        ) {
          setName(item.labelTitle);
        }
      });

      setData({
        attributeTemplateId: props.attributeId,
        attributeItemId: newData.id,
        code: newData.code,
        translates: newData.translates,
        setAsDefault: props.defaultValue === newData.id ? true : false,
      });
      setLangItems(arr);
      setLangSelected(companySetting?.defaultContentLanguage?.toLocaleLowerCase());
    }
  }, [props.isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmitForm = (formData: any) => {
    setLoadingButton(true);
    let translatesNew: any = [];
    formData?.translates?.forEach((item: any, index: string) => {
      if (item.labelTitle !== "") {
        translatesNew[index] = item;
      }
    });
    formData.translates = translatesNew;
    const url = URLS.UPDATE_ITEM_SELECT_ATTRIBUTES_URLS(props.attributeId);
    ServiceApi.put(url, formData)
      .then((res) => {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title"
        );
        props.getData();
        props.toggle();
      }).catch(() => { })
      .finally(() => {
        setLoadingButton(false);
      });
  };

  const submit = (e?: FormEvent) => {
    formRef.current.submitHandler(e, data);
  };

  const onChangeLanguage = (lang: any) => {
    setLangSelected(lang);
    data?.translates?.forEach((item: any) => {
      if (item?.language?.toLocaleLowerCase() === lang?.toLocaleLowerCase()) {
        setName(item.labelTitle);
      }
    });
  };

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} modalClassName="z-40">
      <Form ref={formRef} onSubmit={onSubmitForm} id="update-select-type">
        <Modal.Header className="flex items-center justify-between">
          <div>
            <h2 className="text-heading-2 text-gray-800">
              <Text>productManagement.masterData.attributes.editItem</Text>
            </h2>
          </div>
          <div>
            <SelectLang
              value={langSelected}
              items={langItems}
              onChangeLanguage={onChangeLanguage}
            />
          </div>
        </Modal.Header>
        <Modal.Body className="max-h-100 overflow-auto">
          <div className="space-y-6">
            <InputGroup
              label="productManagement.masterData.attributes.name"
              value={name}
              flag={flagCountry[langSelected]}
              setValue={(value) => {
                data?.translates?.forEach((item: any) => {
                  if (
                    item?.language?.toLocaleLowerCase() ===
                    langSelected?.toLocaleLowerCase()
                  ) {
                    item.labelTitle = value;
                  }
                });
                setData((p: any) => ({ ...p, translates: data?.translates }));
                setName(value);
              }}
              rules={rules.required}
            />
            <InputGroup
              label="productManagement.masterData.attributes.value"
              disabled
              value={data?.code}
              className="border-white"
              append={
                <span
                  className="input-group-text"
                  onClick={() => {
                    window.navigator.clipboard.writeText(data?.code)
                    NotificationManager.success(
                      "global.toast.copy-msg",
                      "global.toast.copy-title"
                    );
                  }}
                >
                  <Icon
                    className="text-primary ml-6 cursor-pointer"
                    icon={"files"}
                  />
                </span>
              }
            />
            <Toggler
              label={"productManagement.masterData.attributes.default"}
              disabled={props?.defaultValue === data?.code ? true : false}
              value={props?.defaultValue === data?.code ? true : data?.setAsDefault}
              setValue={(setAsDefault) =>
                setData((p: any) => ({ ...p, setAsDefault }))
              }
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-between">
          <Button type="button" onClick={() => props.toggle()} variant="white">
            <Icon className="mr-2" icon={"times"} />
            <Text>global.buttons.close</Text>
          </Button>
          <Button type="button" onClick={submit} loading={loadingButton}>
            <Text>global.buttons.submit</Text>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
