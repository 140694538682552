const TEMPLATES = {
  TENANT_DOCUMENT_TEMPLATE_URL: "salesservice/api/documenttemplates/tenant",
  UPDATE_TENANT_DOCUMENT_TEMPLATE_URL: id =>
    `salesservice/api/documenttemplates/tenant/${id}`,
  CHANNEL_DOCUMENT_TEMPLATE_URL: "salesservice/api/documenttemplates/channel",
  UPDATE_CHANNEL_DOCUMENT_TEMPLATE_URL: id =>
    `salesservice/api/documenttemplates/channel/${id}`,
  DOCUMENT_TEMPLATE_URL: "salesservice/api/documenttemplates",
  UPDATE_DOCUMENT_TEMPLATE_URL: id =>
    `salesservice/api/documenttemplates/${id}`,
};

export default TEMPLATES;
