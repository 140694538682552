import {Wrapper, Status, Button, Text} from "components";
import {classNames} from "utils";
import {saleProccessContext} from ".";
import {useContext} from "react";

const CurrentPhase = () => {
  const {data, setChangePhase, setClosePhase} = useContext(saleProccessContext);

  const createPhaseSection = (item: any) => {
    const isSold = item.isSold;
    const active = item.currentPhaseDay;

    return (
      <>
        <h6 className="text-heading-6">
          {item.isClosed ? (
            <Status.SellOff
              id={"salesManagment.sellOffSales.sellOffProduct.closed"}
            />
          ) : isSold ? (
            <h6 className="text-heading-6">
              <Status.SellOff
                id={"salesManagment.sellOffSales.sellOffProduct.sold"}
              />
            </h6>
          ) : active ? (
            <h6 className="text-heading-6">
              <Status.SellOff
                id={"salesManagment.sellOffSales.sellOffProduct.active"}
              />{" "}
              {item.changePhaseRequired && (
                <Status.SellOff
                  id={
                    "salesManagment.sellOffSales.sellOffProduct.needPhaseChange"
                  }
                />
              )}
              {item.closePhaseRequired && (
                <Status.SellOff
                  id={"salesManagment.sellOffSales.sellOffProduct.needClose"}
                />
              )}
            </h6>
          ) : (
            <h6 className="text-heading-6">
              <Status.SellOff
                id={"salesManagment.sellOffSales.sellOffProduct.preparation"}
              />
            </h6>
          )}
        </h6>
      </>
    );
  };

  const percentClassName = classNames(
    "h-full block rounded transition-[width] max-w-full",
    {
      "bg-warning":
        data?.currentPhaseDay === data?.currentPhaseTotalDays &&
        !data?.closePhaseRequired &&
        !data.isSold,
      "bg-success":
        data?.currentPhaseDay !== data?.currentPhaseTotalDays && !data.isSold,
      "bg-danger": data?.closePhaseRequired && !data.isSold,
      "bg-gray-500": data?.isSold,
    },
  );

  const textClassName = classNames("text-heading-5 font-normal", {
    "text-warning":
      data?.currentPhaseDay === data?.currentPhaseTotalDays &&
      !data?.closePhaseRequired &&
      !data.isSold,
    "text-success":
      data?.currentPhaseDay !== data?.currentPhaseTotalDays && !data.isSold,
    "text-danger": data?.closePhaseRequired && !data.isSold,
    "text-gray-500": data?.isSold,
  });

  const percent = (data?.currentPhaseDay / data?.currentPhaseTotalDays) * 100;
  const phaseDayClassName = classNames("text-body-2 ", {
    "text-gray-500": data?.isSold,
    "text-warning":
      data?.currentPhaseDay === data?.currentPhaseTotalDays && !data.isSold,
    "text-success":
      data?.currentPhaseDay !== data?.currentPhaseTotalDays && !data.isSold,
  });

  return (
    <Wrapper>
      <Wrapper.Header>
        <div className="flex items-center justify-between">
          <h2>
            <Text>salesManagment.sellOffSales.sellOffProduct.currentPhase</Text>
          </h2>
          {createPhaseSection(data)}
        </div>
      </Wrapper.Header>
      <Wrapper.Body className="space-y-6">
        <p>{data.sellPhaseTemplateTitle}</p>
        {!data?.isClosed && (
          <>
            <p className={textClassName}>{data.currentPhaseName}</p>
            {data?.changePhaseRequired ? (
              <Button
                size="sm"
                className="w-full"
                variant={"warning"}
                onClick={() => setChangePhase()}
              >
                <Text>
                  salesManagment.sellOffSales.sellOffProduct.changePhase
                </Text>
              </Button>
            ) : data?.closePhaseRequired ? (
              <Button
                size="sm"
                className="w-full"
                variant={"danger"}
                onClick={() => setClosePhase()}
              >
                <Text>
                  salesManagment.sellOffSales.sellOffProduct.closeProccess
                </Text>
              </Button>
            ) : null}
            {data?.currentPhaseDay &&
              !data?.changePhaseRequired &&
              !data?.closePhaseRequired && (
                <div className="block h-2 w-full overflow-hidden rounded-full bg-gray-200">
                  <span
                    style={{width: `${percent}%`}}
                    className={percentClassName}
                  />
                </div>
              )}
            <div className="text-center">
              {data?.closePhaseRequired ? (
                <p className="text-danger">
                  <Text>
                    salesManagment.sellOffSales.sellOffProduct.lastDay
                  </Text>
                </p>
              ) : (
                <p className={phaseDayClassName}>
                  {"(" +
                    data?.currentPhaseDay +
                    "/" +
                    data?.currentPhaseTotalDays +
                    " "}
                  <Text>salesManagment.sellOffSales.sellOffProduct.days</Text>{" "}
                  {" )"}
                </p>
              )}
            </div>
          </>
        )}
      </Wrapper.Body>
    </Wrapper>
  );
};

export default CurrentPhase;
