import {useContext} from "react";
import {Wrapper, CheckBox, InputGroup, Text} from "components";
import {AddUserListContext} from "..";
import {rules} from "constant";

export default function Password() {
  const {data, setData, checked, toggle} = useContext<any>(AddUserListContext);

  const passwordConditions = [
    "Must have at least 8 characters",
    "Must have at least 1 lower case alphabet character",
    "Must have at least 1 upper case alphabet character",
    "Must have at least 1 special character (!@#$%^&*)",
  ];
  const handleSetData = (key: any) => {
    return (value: any) => setData((p: any) => ({...p, [key]: value}));
  };

  return (
    <div className="space-y-6">
      <span className="text-heading-2 font-semibold">
        <Text>configurations.userManagment.userList.setPasswordTitle</Text>
      </span>
      <CheckBox
        label="configurations.userManagment.userList.setPassword"
        value={checked}
        setValue={toggle}
      />
      <Wrapper.Section className="space-y-4">
        <InputGroup
          label={"configurations.userManagment.userList.password"}
          value={data?.password}
          setValue={handleSetData("password")}
          disabled={!checked}
          rules={rules.password}
          type="password"
        />
        <InputGroup
          label={"configurations.userManagment.userList.reEnterpassword"}
          value={data?.confirmPassword}
          setValue={handleSetData("confirmPassword")}
          disabled={!checked}
          {...(data?.password !== data?.confirmPassword && {
            rules: rules.confirmPassword,
          })}
          type="password"
        />
        <ul className="list-disc space-y-2 *:text-sm *:text-gray-600">
          {passwordConditions.map((cond: string) => (
            <li key={cond}>{cond}</li>
          ))}
        </ul>
      </Wrapper.Section>
    </div>
  );
}
