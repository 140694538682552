import { ServiceApi } from "services";
import Icon from "./Icon";
import Text from "./Text";
import ETC from "services/urls/etc";
import Button from "./Button";
import { useEffect, useRef, useState } from "react";
import { showFileName } from "utils";
import { useToggleState } from "hooks";

type FileUploaderProps = {
  value?: string | null;
  setValue?: CallableFunction;
  getResponse?: CallableFunction;
  onUploadHandler?: CallableFunction;
  accept?: any;
  type?: number;
  loading?: boolean;
  hideAction?: boolean;
  className?: string;
  isHorizontal?: boolean
};

export default function FileUploader({
  value = "",
  setValue = () => { },
  accept,
  getResponse,
  onUploadHandler,
  type,
  loading,
  className = "",
  hideAction = false,
  isHorizontal = false
}: FileUploaderProps) {
  const intervalRef = useRef<any>(null);
  const [loadingFile, setLoading] = useState(0);
  const [name, setName] = useState("");

  const startLoading = () => {
    intervalRef.current = setInterval(() => {
      setLoading(p => {
        const value = p + Math.random() * 10;
        const max = 75;
        return p >= max ? p : value;
      });
    }, 500);
  };
  const endLoading = () => {
    clearInterval(intervalRef.current);
    setLoading(100);
    setTimeout(() => {
      setLoading(0);
    }, 500);
  };

  const hasFile = !!value;

  const hasLoading = !!loadingFile;

  const clearFile = () => {
    setValue(null);
    setLoading(0);
  };
  const uploadFile = (e: any) => {
    // setName("");
    e.preventDefault();
    e.stopPropagation();
    startLoading();
    const file = e.currentTarget.files[0];
    const url = ETC.UPLOAD_IMAGES_URL(type ?? 0);
    const body = new FormData();
    body.append("formFile", file);
    ServiceApi.post(url, body)
      .then(({ data }) => {
        const originalName = data?.metaData?.map((item: any) => {
          if (item["key"] === "OriginalName") {
            return item.value;
          }
          return "";
        });
        setName(originalName);
        setValue(data?.url);
        getResponse && getResponse(data);
      })
      .finally(() => {
        endLoading();
      });
  };
  if (hasFile || hasLoading)
    return (
      <div className="flex-center flex w-full flex-col space-y-4  rounded border-[0.5px] border-gray-100 bg-gray-100 p-8 text-center">
        <button type="button" onClick={clearFile}>
          <Icon
            icon="trash"
            size={hasLoading ? "2x" : "lg"}
            className="text-danger"
          />
        </button>
        {hasLoading ? (
          <div className="block h-2 w-full overflow-hidden rounded-full bg-white">
            <span
              style={{ width: `${loadingFile}%` }}
              className="block h-full max-w-full rounded bg-success transition-[width]"
            />
          </div>
        ) : (
          <></>
          // hasFile && (
          //   <img
          //     src={value}
          //     alt={showFileName(value)}
          //     className="h-24 w-full rounded object-contain"
          //   />
          // )
        )}
        <p className="w-full truncate text-body-base text-gray-600">
          {/* {showFileName(value)} */}
          {name}
        </p>
        {!hideAction && (
          <Button
            light
            className="!mt-6"
            loading={loading}
            disabled={loadingFile !== 0}
            onClick={() => {
              onUploadHandler && onUploadHandler();
            }}
          >
            <Text>global.uploadFile</Text>
          </Button>
        )}
      </div>
    );
  if (isHorizontal)
    return (
      <label className="bg-primary-light border border-dashed border-primary flex gap-4 p-4 rounded-xl relative cursor-pointer"
        aria-label='cy-upload-file'>
        <input type="file" onChange={uploadFile} className="absolute top-0 left-0 opacity-0 cursor-pointer w-full h-full" accept={accept}
          aria-label='cy-upload-file' />
        <Icon icon="file-arrow-up" className="text-primary" size="3x" />
        <div className="flex flex-col justify-center gap-3">
          <h5 className="text-heading-5 "><Text>global.uploadFile</Text></h5>
          <p className="text-body-base text-gray-600">
            <Text>configurations.globalSettings.templates.dragDrop</Text>{' '}
            <span data-type="button" className=" text-primary">
              <Text>configurations.globalSettings.templates.clickHere</Text>
            </span>
          </p>
        </div>
      </label>);
  return (
    <label
      data-type="button"
      className={`${className} flex-center flex h-[220px] w-full cursor-pointer flex-col space-y-4 rounded  bg-gray-100 p-8 text-center`}
    >
      <Icon icon="file-arrow-up" size="3x" className="text-primary" />
      <h5 className="text-primary">
        <Text>global.uploadFile</Text>
      </h5>
      <input type="file" onChange={uploadFile} hidden accept={accept} />
    </label>
  );
}
