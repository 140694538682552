import { useContext } from "react";
import PriceCalculationTab from "compositions/price-calculation-units/price-calculation-tab";
import { calculationLevelEnum } from "constant";
import { BrandContext } from "../details";

export default function PriceCalculation() {
  const { data } = useContext<any>(BrandContext);

  return (
    <PriceCalculationTab
      refId={data?.brandId}
      level={calculationLevelEnum.brand}
      refTitle={data?.name}
      refCode={data?.code}
    />
  );
}
