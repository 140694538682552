import { NotificationManager } from "common/notifications";
import {
  Breadcrumb,
  Form,
  ImageUploader,
  LazyImage,
  Skeleton,
  Tabs,
  Text,
  Wrapper,
} from "components";
import { useDataState } from "hooks";
import { Fragment, createContext, createElement, useEffect, useState } from "react";
import { assetTypes } from "constant";
import { useToggleState, usePermission } from "hooks";
import { useParams } from "react-router";
import { ServiceApi, URLS } from "services";
import { Media } from "types";
import PriceCalculation from "./tabs/priceCalculation";
import General from "./tabs/general";
import { ObjectUtils } from "utils";

export const BrandContext = createContext({});

export default function Details() {
  const updatePermission = usePermission("PS_UpdateBrand");
  const { brandId } = useParams();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [data, setData, setBaseData, isChanged, baseData] = useDataState<any>({});
  const [isOpen, toggle] = useToggleState(false);
  const tabs = [
    {
      label: "productManagement.masterData.brand.generalTab",
      component: General,
    },
    {
      label: "priceEngine.calculationLevel.priceCalculationUnits",
      component: PriceCalculation,
    },
  ];

  const getData = () => {
    setLoading(true);
    const url = URLS.GET_BRAND_DETAILS_URL(brandId);
    ServiceApi.get(url)
      .then(({ data }) => {
        setBaseData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submit = () => {
    setSubmitLoading(true);
    const url = URLS.UPDATE_BRAND_DETAILS_URL(brandId);
    const body = { ...data };
    ServiceApi.put(url, body)
      .then(() => {
        setBaseData(data);
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };
  useEffect(getData, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onUploadImage = (data: Media) => {
    // setData((p) => ({ ...p, avatarUrl: data.url }));
    const url = URLS.UPLOAD_BRAND_IMAGES(brandId);
    const body = {
      url: data.url,
      type: data.type,
      thumbnailUrl: data.thumbnailUrl,
    };
    ServiceApi.patch(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
      })
      .finally(() => {
        getData();
      });
  };

  return (
    <Tabs
      checkSave={isChanged}
      activeKey={tabs[0].label}
      onSave={() => {
        setData(baseData);
      }}
    >
      <Form onSubmit={submit} className="relative space-y-6">
        <Breadcrumb />
        <Wrapper>
          <Wrapper.Body className="flex flex-col items-start gap-5 lg:flex-row">
            <LazyImage
              isDynamic
              onClick={() => { if (updatePermission) toggle() }}
              logo
              editor={updatePermission ? true : false}
              src={data.logoUrl}
              alt={data.name}
              className="aspect-image w-fit cursor-pointer bg-gray-100   p-2 lg:aspect-auto lg:h-40 lg:w-80 "
            />
            <div className="space-y-4 lg:flex-1">
              {loading ? (
                <Fragment>
                  <Skeleton.Input />
                  <Skeleton.Input />
                </Fragment>
              ) : (
                <Fragment>
                  <h6 className="text-heading-6 text-gray-800">{data.name}</h6>
                  <p className="text-body-2 font-medium text-gray-500">
                    {data.code}
                  </p>
                </Fragment>
              )}
            </div>
          </Wrapper.Body>
          <Wrapper.Footer className="!py-0">
            <Tabs.ButtonGroup>
              {tabs.map(e => (
                <Tabs.Button key={e.label} eventKey={e.label}>
                  <Text>{e.label}</Text>
                </Tabs.Button>
              ))}
            </Tabs.ButtonGroup>
          </Wrapper.Footer>
        </Wrapper>
        {loading ? (
          <Wrapper>
            <Wrapper.Body className="grid grid-cols-1 gap-4 lg:grid-cols-2">
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Wrapper.Body>
          </Wrapper>
        ) : (tabs.map(e => (
          <Tabs.Item key={e.label} eventKey={e.label}>
            <BrandContext.Provider
              value={{
                data: data,
                setData: setData,
                getData: getData,
                isChanged: isChanged,
                submitLoading: submitLoading
              }}
            >
              {!ObjectUtils.isEmpty(data) && createElement(e.component)}
            </BrandContext.Provider>
          </Tabs.Item>
        )))}
      </Form>
      {updatePermission && (
        <ImageUploader
          isOpen={isOpen}
          toggle={toggle}
          image={data.logoUrl}
          onUpload={(data: Media) => onUploadImage(data)}
          type={assetTypes.Company}
        />
      )}
    </Tabs>
  );
}
