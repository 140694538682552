export const salesPrice = {
  addSalesTemplate: "Verkaufspreis- Vorlage hinzufügen",
  updateAt: "letztes Update",
  title: "Titel",
  code: "Code",
  shortDescription: "Kurzbechreibung",
  addTemplate: "Template hinzufügen",
  validationPeriods: "Gültigkeitsperiode",
  from: "von",
  to: "bis",
  selectDate: "Datum wählen",
  general: "Allgemein",
  calculationSections: "Kalkulations-Bereiche",
  active: "Aktiv",
  basicInfo: "Basisinformationen",
  addNewCalculationUnit: "Neue Kalkulationseinheit hinzufügen",
  step1: "Step 1 - Brutto > Netto",
  step2: "Step 2 - Netto > NettoNetto",
  step3: "Step 3 - Kalkulationsfaktor",
  step4: "Step 4",
  step5: "Step 5",
  addSelectedUnits: "Ausgewählte Elemente hinzufügen",
  searchCalcUitsPlacholder: "Kalkulationseinheiten suchen (Name oder ID)",
  system: "System",
  custom: "Custom",
  automaticDiscount: "Automatischer Rabatt",
  optionalDiscount: "Optionaler Rabatt",
  section1: "Abschnitt 1 (System 1)",
  section2: "Abschnitt 2 (System 2)",
  section3: "Abschnitt 3 (Automatische Rabatte)",
  section4: "Abschnitt 4 (Katalogkalkulation)",
  section5: "Abschnitt 5 (Optionale Rabatte)",
  section6: "Abschnitt 6 (Individuelle Rabatte)",
};
