import SellOffSales from "./sell-off-sales";
import Settings from "./settings";
import Report from "./report";
import KitchenSales from "./kitchen-sales";
import SalesArea from "./sales-area";
import SalesChannelRoutes from "./sales-channel";
import CommissionReports from "./commission-reports";

const salesManagment = [
  {
    path: "salesmanagment/sell-off-sales",
    children: [...SellOffSales],
  },
  {
    path: "salesmanagment/settings",
    children: [...Settings],
  },
  {
    path: "salesmanagment/reports",
    children: [...Report],
  },
  {
    path: "salesmanagment/kitchen-sales",
    children: [...KitchenSales],
  },
  {
    path: "salesmanagment/sales-area",
    children: [...SalesArea],
  },
  {
    path: "salesmanagment/sales-channels",
    children: [...SalesChannelRoutes],
  },
  {
    path: "salesmanagment/commission-reports",
    children: [...CommissionReports],
  },
];

export default salesManagment;
