export const productFamilies = {
  general: "Allgemein",
  subFamily: "Unterfamilie",
  basicInfo: "Grundlegende Informationen",
  addSubFamiliy: "Unterfamilie hinzufügen",
  addBranch: "Zweig hinzufügen",
  addToFamily: "Zur Familie hinzufügen",
  cancelAddBranch: "Abbrechen",
  edit: "Bearbeiten",
  attributeGroups: "Attributgruppen",
  deleteSubFamily: "Löschen",
  attributes: "Attribute",
  close: "Schließen",
  required: "Erforderlich",
  perLanguage: "Pro Sprache",
  perChannel: "Pro Kanal",
  inherit: "Vererben",
  familyName: "Name Produktfamilie",
  familyCode: "Code Produktfamilie",
  availableForSelect: "Auswahl möglich",
  inheritFromParent: "Übernahme von Kopfprodukt",
  exists: "Existiert bereits",
  usedInChild: "Wird in Sub-Produkt verwendet",
};
