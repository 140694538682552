const ERP = {
  GET_ERP_DYNAMICS_365: "channelmanagerservice/api/erpconfig",
  ADD_ERP_DYNAMICS_365: "channelmanagerservice/api/erpconfig",
  EDI_ERPT_DYNAMICS_365: "channelmanagerservice/api/erpconfig",

  // inventory
  GET_POST_ERP_INVENTORY_CONFIG: "salesservice/api/erpinventoryconfigs",
  PUT_DELETE_ERP_INVENTORY_CONFIG: id =>
    `salesservice/api/erpinventoryconfigs/${id}`,
};
export default ERP;
