import {Icon, NoItems, Skeleton, Table, Text, Wrapper} from "components";
import {useSelector, useToggleState} from "hooks";
import {useContext, useEffect, useState} from "react";
import {ServiceApi, URLS} from "services";
import {Employee} from "types";
import {UserContext} from "..";
import EmployeeRow from "./employee/EmployeeRow";
import SearchDrawer from "./employee/search-drawer";

export default function Employees() {
  const {userData, getData, UPDATE_PERMISSIONS} = useContext<any>(UserContext);
  const profile = useSelector(s => s.profile);
  const [loading, setLoading] = useState(false);
  const [employeeData, setEmployeeData] = useState<Employee.Details | null>(
    null,
  );
  const [isOpenSearchDrawer, toggleSearchDrawer] = useToggleState(false);
  const hasEmployee = !!userData?.employeeId;
  const hasEmployeeData = !!employeeData;
  const isUserEmployee = userData?.userId === profile?.userId;

  const getDataEmployee = () => {
    if (!hasEmployee) return setEmployeeData(null);
    setLoading(true);
    const url = URLS.GET_EMPLOYEES_DETAILS_URL(userData?.employeeId);
    ServiceApi.get(url)
      .then(({data}) => {
        setEmployeeData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(getDataEmployee, [userData?.employeeId, hasEmployee]);
  return (
    <div className="space-y-4">
      <Wrapper>
        <Wrapper.Header>
          <div className="flex">
            <h2 className="text-gray-800">
              <Text>configurations.userManagment.userList.assignEmployee</Text>
            </h2>
          </div>
        </Wrapper.Header>
        <Wrapper.Body className="!p-4">
          {!isUserEmployee && UPDATE_PERMISSIONS && !userData?.employeeId && (
            <Wrapper.Section className="courser-pointer mb-4 h-[52px] w-full cursor-pointer border-primary bg-primary-light py-[17px] text-center">
              <div
                className="w-full"
                onClick={() => {
                  toggleSearchDrawer();
                }}
              >
                <h5 className="text-primary">
                  <Icon className="mr-2 text-primary" icon={"square-plus"} />
                  <Text>
                    configurations.userManagment.userList.assignEmployee
                  </Text>
                </h5>
              </div>
            </Wrapper.Section>
          )}
          {loading ? (
            <Skeleton.List />
          ) : hasEmployeeData ? (
            <Table>
              <EmployeeRow
                employee={employeeData}
                isUserEmployee={isUserEmployee}
              />
            </Table>
          ) : (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
            </div>
          )}
        </Wrapper.Body>
      </Wrapper>
      <SearchDrawer
        isOpen={isOpenSearchDrawer}
        toggle={toggleSearchDrawer}
        userId={userData?.userId}
        getData={getData}
        setLoading={setLoading}
      />
    </div>
  );
}
