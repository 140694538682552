import {Breadcrumb, Tabs, Text} from "components";
import {createElement} from "react";

//... tabs
import Dynamics365 from "./tabs/dynamic365";
import Inventory from "./tabs/inventory";

export default function ErpConfiguration() {
  const tabs = [
    {
      label: "thirdParty.erpConfiguration.dynamics365",
      component: Dynamics365,
    },
    {
      label: "thirdParty.erpConfiguration.inventory",
      component: Inventory,
      permission: ["PS_GetErpInventoryConfig"],
    },
  ];

  return (
    <section className="space-y-6">
      <Breadcrumb className="col-span-full" />
      <Tabs activeKey={tabs[0].label}>
        <Tabs.ButtonGroup className="rounded bg-white px-4">
          {tabs.map(e => (
            <Tabs.Button key={e.label} eventKey={e.label}>
              <Text>{e.label}</Text>
            </Tabs.Button>
          ))}
        </Tabs.ButtonGroup>
        {tabs.map(e => (
          <Tabs.Item key={e.label} eventKey={e.label}>
            {createElement(e.component)}
          </Tabs.Item>
        ))}
      </Tabs>
    </section>
  );
}
