const SHIPPING_METHODS = {
  GET_SHIPPING_LIST: "productservice/api/shippingmethods",
  ADD_SHIPPING_METHODS: "productservice/api/shippingmethods",
  GET_SHIPPING_METHOD_DETAILS: (id) =>
    `productservice/api/shippingmethods/${id}`,
  PUT_SHIPPING_METHODS_DETAILS: (id) =>
    `productservice/api/shippingmethods/${id}`,
};

export default SHIPPING_METHODS;
