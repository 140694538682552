import { Button, Drawer, Icon, Text } from "components";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useMemo,
  useState,
} from "react";
import { Basket, ProductFamily, Store, toggleProps } from "types";
import Baskets from "./Baskets";
import ChooseProductFamily from "./ChooseProductFamily";
import Products from "./Products";
import Stores from "./Stores";

type SelectFromBasketProps = toggleProps & { getData: () => void };
type Context = {
  store: Store.Item | null;
  setStore: Dispatch<SetStateAction<Store.Item | null>>;
  basket: Basket.Item | null;
  setBasket: Dispatch<SetStateAction<Basket.Item | null>>;
  basketSubmitted: boolean;
  toggleBasketSubmitted: () => void;
  deleteAfterImport: boolean;
  toggleDeleteAfterImport: () => void;
  productFamily: ProductFamily.Item | null;
  setProductFamily: Dispatch<SetStateAction<ProductFamily.Item | null>>;
  goBack: () => void;
  toggle: () => void;
  onSubmitted: () => void;
};
export const SelectFromBasketContext = createContext({} as Context);
export default function SelectFromBasket({
  isOpen,
  toggle,
  getData,
}: SelectFromBasketProps) {
  const [store, setStore] = useState<Store.Item | null>(null);
  const [basket, setBasket] = useState<Basket.Item | null>(null);
  const [basketSubmitted, setBasketSubmitted] = useState(false);
  const [deleteAfterImport, setDeleteAfterImport] = useState(false);
  const [productFamily, setProductFamily] = useState<ProductFamily.Item | null>(
    null
  );
  const hasStore = !!store;
  const hasBasket = !!basket;
  const hasProductFamily = !!productFamily;
  const handleMenuSize = [hasStore, hasBasket].every(Boolean)
    ? "xl"
    : undefined;
  const toggleBasketSubmitted = () => {
    setBasketSubmitted((p) => !p);
  };
  const toggleDeleteAfterImport = () => {
    setDeleteAfterImport((p) => !p);
  };
  const ActiveTab = useMemo(() => {
    if (!store) return Stores;
    if (!basket) return Baskets;
    if (!basketSubmitted) return Products;
    return ChooseProductFamily;
  }, [store, basket, basketSubmitted]);

  const goBack = () => {
    if (hasProductFamily) setProductFamily(null);
    if (basketSubmitted) return setBasketSubmitted(false);
    if (hasBasket) return setBasket(null);
    setStore(null);
  };

  const handleToggle = () => {
    setStore(null);
    setBasket(null);
    setProductFamily(null);
    setBasketSubmitted(false);
    setDeleteAfterImport(false);
    toggle();
  };
  const onSubmitted = () => {
    handleToggle();
    getData();
  };

  return (
    <Drawer isOpen={isOpen} toggle={handleToggle} size={handleMenuSize}>
      <Drawer.Menu>
        <Drawer.Header className="flex items-center gap-4">
          <h6 className="flex-1 text-start text-gray-800 text-heading-2 truncate">
            <Text>
              productManagement.products.allProducts.addBasketFromIwofurn
            </Text>
          </h6>
          {/* {hasBasket && (
            <Button
              type="button"
              variant="primary"
              light
              size="sm"
              onClick={clearBasket}
            >
              <Text>
                productManagement.products.allProducts.changeBasketBtn
              </Text>{" "}
              <Icon icon="refresh" />
            </Button>
          )} */}
          <Button
            type="button"
            variant="light"
            size="sm"
            onClick={handleToggle}
          >
            <Icon icon="close" />{" "}
            <Text>productManagement.products.allProducts.closeBtn</Text>
          </Button>
        </Drawer.Header>

        {isOpen && (
          <SelectFromBasketContext.Provider
            value={{
              store,
              setStore,
              basket,
              setBasket,
              basketSubmitted,
              toggleBasketSubmitted,
              deleteAfterImport,
              toggleDeleteAfterImport,
              productFamily,
              setProductFamily,
              goBack,
              onSubmitted,
              toggle: handleToggle,
            }}
          >
            <ActiveTab />
          </SelectFromBasketContext.Provider>
        )}
      </Drawer.Menu>
    </Drawer>
  );
}
