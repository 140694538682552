import {twMerge} from "tailwind-merge";
import {Children, ClassName} from "types";
import Wrapper from "./Wrapper";

type FooterProps = {
  show?: boolean;
} & ClassName &
  Children;
export default function Footer({show, className, children}: FooterProps) {
  return (
    <Wrapper
      className={twMerge(
        "sticky bottom-0 shadow-[0px_-2px_7px_0px_#eef0f8] transition-opacity",
        show
          ? "pointer-events-none opacity-0"
          : "pointer-events-auto opacity-100",
        className,
      )}
    >
      <Wrapper.Body className="flex items-center justify-end gap-4">
        {children}
      </Wrapper.Body>
    </Wrapper>
  );
}
