import {Icon, Text} from "components";
import {useMenu} from "hooks";
import {Fragment, useMemo} from "react";
import {useLocation, useNavigate} from "react-router";
import {twMerge} from "tailwind-merge";
import {isUUID} from "utils";
type Props = {
  className?: string;
  to?: string;
  backward?: boolean;
};
export default function Breadcrumb({className, to, backward}: Props) {
  const {menu} = useMenu();
  const navigate = useNavigate();
  const location = useLocation();
  const items = useMemo(() => {
    const pathname = location.pathname;
    const pathnameItems = pathname.split("/").slice(1);
    const items: any = [];
    pathnameItems.forEach(key => {
      const parentItems = items.at(-1)?.items ?? menu;
      if (isUUID(key)) {
        items.push({label: "global.details"});
      } else if (key === "new") {
        items.push({label: "global.addNew"});
      } else {
        const item = parentItems.find((e: any) => e.path === key);
        if (item) {
          items.push(item);
        } else {
          items.push({label: key});
        }
      }
    });
    items.unshift({label: "global.home"});
    return items.map((e: any) => e?.label);
  }, [location.pathname, menu]);

  //create Parent Path for backward Navigation
  const parentPath = useMemo(() => {
    const path = location.pathname.split("/").slice(1);
    const isDetailsPage = path.some((p: string) => isUUID(p) || p === "new");
    const parent = path.slice(0, -1);
    return isDetailsPage ? "/" + parent.join("/") : "/";
  }, [location.pathname, menu]);

  return (
    <div className={twMerge("block w-full space-y-2", className)}>
      <h3 className="flex items-center text-gray-800">
        <Icon
          icon="chevron-left"
          size="sm"
          className="mr-2 w-[14px] cursor-pointer rounded-sm p-1 hover:bg-gray-300"
          onClick={() =>
            backward ? navigate(-1) : to ? navigate(to) : navigate(parentPath)
          }
        />
        <span
          onClick={() =>
            backward ? navigate(-1) : to ? navigate(to) : navigate(parentPath)
          }
          className="cursor-pointer"
        >
          <Text>{items.at(-1)}</Text>
        </span>
      </h3>
      <p className="text-body-2 font-medium text-gray-500">
        {items.map((e: any) => (
          <Fragment key={e}>
            <span className="first:hidden">/</span>{" "}
            <span className="last:text-gray-800">
              <Text>{e}</Text>
            </span>{" "}
          </Fragment>
        ))}
      </p>
    </div>
  );
}
