import {Drawer} from "components";
import {Fragment, useContext, useEffect, useState} from "react";
import {ServiceApi} from "services";
import {ConfigProductContext} from ".";

export default function Iwofurn() {
  const {supplier, program, store, internalTag} =
    useContext(ConfigProductContext);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");
  const hasURL = !!url;
  const iwofurnLoading = !url && loading;

  const getUrl = () => {
    setLoading(true);
    const url = "/productservice/api/iwofurnconfigurations/iwofurn-url";
    const body = {
      // storeCode: offerData.storeInfo?.code,
      supplierCode: supplier?.code,
      programCode: program?.iwofurnCode,
      storeCode: store?.code,
      storeIln: store?.iln,
      iwofurnSalesCalculationFactor:
        store?.salesSettings?.iwofurnSalesCalculationFactor,
      iwofurnRoundingMethod: store?.salesSettings?.iwofurnRoundingMethod,
    };
    ServiceApi.post(url, body)
      .then(({data}) => {
        const url = new URL(data);
        url.searchParams.set("transaction", internalTag);
        setUrl(url.toString());
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(getUrl, [supplier, program, store, internalTag]);
  return (
    <Fragment>
      <Drawer.Body>
        <div
          data-loading={iwofurnLoading}
          className="relative h-full w-full overflow-hidden rounded bg-primary/10 data-loading:animate-pulse  data-loading:cursor-not-allowed"
        >
          {hasURL && (
            <iframe
              title={["iwofurn", internalTag].join("-")}
              src={url}
              className="absolute inset-0 h-full w-full border-0 bg-transparent"
            />
          )}
        </div>
      </Drawer.Body>
    </Fragment>
  );
}
