import {Drawer, Text, Button, Icon, SearchBox} from "components";
import {useEffect, useState} from "react";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  dispatch: (channel: any) => void;
  dispatchParams: (channel: any) => void;
  channels: any;
}

export default function ChannelList({
  isOpen,
  toggle,
  channels,
  dispatch,
  dispatchParams,
}: Props) {
  const [keyword, setKeyword] = useState("");
  const [list, setList] = useState([]);

  useEffect(() => {
    isOpen && setList(channels);
  }, [isOpen]);

  const onChangeHandler = (value: any) => {
    if (value) {
      const filteredList = channels?.filter(
        (channel: any) =>
          channel.title.toLowerCase().includes(value.toLowerCase()) ||
          channel.code.toLowerCase().includes(value.toLowerCase()),
      );
      setList(filteredList);
    } else {
      setList(channels);
    }
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header>
          <div className="flex items-center justify-between">
            <span className="text-heading-2 font-semibold">
              <Text>Channels</Text>
            </span>
            <div className="flex gap-x-2">
              <Button size="sm" variant={"light"} onClick={toggle}>
                <Icon icon="times" className="mr-1" />
                <Text>productManagement.products.allProducts.close</Text>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          <SearchBox
            value={keyword}
            onSubmit={val => onChangeHandler(val)}
            totalItems={list.length}
            variant="gray"
          />
          {list?.map((channel: any) => {
            return (
              <div
                className="cursor-pointer items-center space-y-2 rounded-xl p-4 shadow-items hover:bg-primary-light"
                onClick={() => {
                  dispatch?.(channel);
                  dispatchParams((params: any) => ({
                    ...params,
                    channelCode: channel?.code,
                  }));
                  toggle();
                }}
              >
                <h5 className="text-gray-800">{channel.title}</h5>
                <p className="text-xs">Code : {channel.code}</p>
              </div>
            );
          })}
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
}
