import {
  Button,
  InputGroup,
  Skeleton,
  Text,
  WithPermission,
  Wrapper,
  Form,
  Icon,
} from "components";
import {rules} from "constant";
import {useEffect, useState} from "react";
import {ServiceApi, URLS} from "services";
import {NotificationManager} from "common/notifications";
import {useDataState, usePermission} from "hooks";
import {withPermission} from "hoc";

function Inventory() {
  const upsertPermission = usePermission(
    "SC_UpsertSaleServiceErpInventoryConfig",
  );
  const controller = new AbortController();
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingResetButton, setLoadingResetButton] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [data, setData, setBaseData, isChanged] = useDataState<any>({});
  const [loadingPage, setLoadingPage] = useState(true);

  const submit = () => {
    setLoadingButton(true);
    if (updateData) {
      const url = URLS.PUT_DELETE_ERP_INVENTORY_CONFIG(data.id);
      ServiceApi.put(url, data)
        .then(() => {
          NotificationManager.success(
            "global.toast.edit-msg",
            "global.toast.edit-title",
          );
        })
        .finally(() => {
          setLoadingButton(false);
          getData();
        });
    } else {
      const url = URLS.GET_POST_ERP_INVENTORY_CONFIG;
      ServiceApi.post(url, data)
        .then(() => {
          NotificationManager.success(
            "global.toast.add-msg",
            "global.toast.add-title",
          );
        })
        .finally(() => {
          setLoadingButton(false);
          getData();
        });
    }
  };

  const handleReset = () => {
    setLoadingResetButton(true);
    const url = URLS.PUT_DELETE_ERP_INVENTORY_CONFIG(data.id);
    ServiceApi.delete(url, data)
      .then(() => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title",
        );
      })
      .finally(() => {
        setLoadingResetButton(false);
        getData();
      });
  };

  useEffect(() => {
    getData();
    return () => {
      controller.abort();
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    setLoadingPage(true);
    await ServiceApi.get(URLS.GET_POST_ERP_INVENTORY_CONFIG, {
      signal: controller.signal,
    })
      .then(({data}) => {
        setBaseData(data);
        if (data.length === 0) {
          setUpdateData(false);
        } else {
          setUpdateData(true);
        }
      })
      .catch(() => {
        setBaseData({});
      })
      .finally(() => {
        setLoadingPage(false);
      });
  };

  return (
    <Form onSubmit={submit} className="relative space-y-6">
      <Wrapper>
        <Wrapper.Body className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-8">
          <div className="space-y-8">
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="thirdParty.erpConfiguration.erpInventoryBaseUrl"
                value={data.erpInventoryBaseUrl}
                setValue={erpInventoryBaseUrl =>
                  setData((p: any) => ({...p, erpInventoryBaseUrl}))
                }
                rules={rules.required}
                disabled={!upsertPermission}
              />
            )}
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="thirdParty.erpConfiguration.environmentIdentifier"
                value={data.environmentIdentifier}
                setValue={environmentIdentifier =>
                  setData((p: any) => ({...p, environmentIdentifier}))
                }
                rules={rules.required}
                disabled={!upsertPermission}
              />
            )}
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="thirdParty.erpConfiguration.tenantIdentifier"
                value={data.tenantIdentifier}
                setValue={tenantIdentifier =>
                  setData((p: any) => ({...p, tenantIdentifier}))
                }
                rules={rules.required}
                disabled={!upsertPermission}
              />
            )}
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="thirdParty.erpConfiguration.clientIdentifier"
                value={data.clientIdentifier}
                setValue={clientIdentifier =>
                  setData((p: any) => ({...p, clientIdentifier}))
                }
                rules={rules.required}
                disabled={!upsertPermission}
              />
            )}
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="thirdParty.erpConfiguration.clientSecret"
                value={data.clientSecret}
                setValue={clientSecret =>
                  setData((p: any) => ({...p, clientSecret}))
                }
                rules={rules.required}
                disabled={!upsertPermission}
              />
            )}
            {updateData && upsertPermission && (
              <Button
                type="button"
                variant="danger"
                loading={loadingResetButton}
                light
                onClick={handleReset}
              >
                <Icon icon="circle-xmark" className="mr-2" />
                <Text>global.buttons.reset</Text>
              </Button>
            )}
          </div>
        </Wrapper.Body>
        <WithPermission
          permissions={["SC_UpsertSaleServiceErpInventoryConfig"]}
        >
          <Wrapper.Footer className="flex items-center justify-end gap-4">
            <Button type="button" onClick={getData} variant="white">
              <Text>global.buttons.discard</Text>
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={!isChanged}
              loading={loadingButton}
            >
              <Text>global.buttons.saveChanges</Text>
            </Button>
          </Wrapper.Footer>
        </WithPermission>
      </Wrapper>
    </Form>
  );
}
export default withPermission(Inventory, [
  "SC_GetSaleServiceErpInventoryConfig",
]);
