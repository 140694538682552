import {InputGroup, Select, Skeleton, Text, Wrapper} from "components";
import {config} from "constant";
import {Fragment, useContext, useEffect, useState} from "react";
import {ServiceApi, URLS} from "services";
import {AttributesTypes} from "utils";
import {AttributesContext} from "..";
import {usePermission} from "hooks";

export default function AttributesTypeMeasurement() {
  const updatePermission = usePermission("PS_UpdateAttributeTemplate");
  const {attributesData, setAttributesData, loading} =
    useContext<any>(AttributesContext);
  const [items, setItems] = useState([]);
  const [itemsUnits, setItemsUnits] = useState([]);
  const [params] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  });

  useEffect(() => {
    const url = URLS.GET_MEASUREMENT_FAMILY_URL;
    const config = {params};
    let arr: any = [];
    ServiceApi.get(url, config)
      .then(({data}) => {
        let measurementId;
        data?.items?.forEach((item: any) => {
          if (item?.code === attributesData?.measurementFamilyCode) {
            measurementId = item.measurementFamilyId;
          }
          item?.translates?.forEach((i: any, index: number) => {
            if (index === 0) {
              arr.push({
                id: item.code,
                name: i.labelTitle,
                unitId: item.measurementFamilyId,
              });
            }
          });
        });
        getDataUnits(measurementId);
        setItems(arr);
      })
      .catch(() => {});
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getDataUnits = (id: any) => {
    if (id) {
      const urlUnits = URLS.GET_DETAIL_MEASUREMENT_FAMILY_URL(id);
      let arrUnits: any = [];
      ServiceApi.get(urlUnits)
        .then(({data}) => {
          data?.units?.forEach((item: any) => {
            item?.translates?.forEach((i: any, index: number) => {
              if (index === 0) {
                arrUnits.push({id: item.code, name: i.labelTitle});
              }
            });
          });
          setItemsUnits(arrUnits);
        })
        .catch(() => {});
    }
  };

  const handleSetData = (key: any) => {
    return (value: any) =>
      setAttributesData((p: any) => ({...p, [key]: value}));
  };

  return (
    <Fragment>
      <div className="flex">
        {loading ? (
          <Wrapper>
            <Wrapper.Body className="grid grid-cols-1 gap-4 lg:grid-cols-1">
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Wrapper.Body>
          </Wrapper>
        ) : (
          <Wrapper>
            <Wrapper.Header>
              <h2 className="font-semibold text-gray-800">
                <Text>productManagement.masterData.attributes.setting</Text>
              </h2>
            </Wrapper.Header>
            <Wrapper.Body className="grid grid-cols-1 gap-4 lg:grid-cols-2">
              <div className="space-y-8">
                <InputGroup
                  label="productManagement.masterData.attributes.attributeType"
                  value={AttributesTypes(attributesData?.type)}
                  disabled
                />
                <Select
                  label="productManagement.masterData.attributes.measurementTypeList"
                  items={items}
                  value={attributesData?.measurementFamilyCode}
                  setValue={e => {
                    items.forEach((item: any) => {
                      if (item.id === e) {
                        getDataUnits(item.unitId);
                      }
                    });
                    setAttributesData((p: any) => ({
                      ...p,
                      measurementFamilyCode: e,
                    }));
                  }}
                  disabled={!updatePermission}
                />
                <Select
                  label="productManagement.masterData.attributes.unitsList"
                  items={itemsUnits}
                  value={attributesData?.defaultValue}
                  setValue={handleSetData("defaultValue")}
                  disabled={!updatePermission}
                />
              </div>
            </Wrapper.Body>
          </Wrapper>
        )}
      </div>
    </Fragment>
  );
}
