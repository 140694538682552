import {NotificationManager} from "common/notifications";
import {
  Button,
  Drawer,
  Form,
  Icon,
  Text,
  Wrapper,
  Skeleton,
  AddressViewer,
  InputGroup,
  Table,
  NoItems,
  RadioButton,
  CheckBox,
} from "components";
import {config} from "constant";
import {useToggleState} from "hooks";
import {FormEvent, useEffect, useRef, useState, Fragment} from "react";
import AsyncSelect from "react-select/async";
import {ServiceApi, URLS} from "services";
import {debounce} from "utils";

type createModalType = {
  isOpen: boolean;
  toggle: (value?: boolean) => void;
  getData: () => void;
};

export default function ImportDrawer(props: createModalType) {
  const [loading, setLoading] = useToggleState();
  const [loadingButton, toggleButton] = useToggleState();
  const [show, setShow] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const [selectedSSOs, dispachSSOs] = useState<any>([]);

  const [inputValue, setInputValue] = useState("");

  const searchSSOUser = (value: string) => {
    setLoading();
    const url = URLS.SEARCH_AD_USERS(value);
    ServiceApi.get(url)
      .then(({data}) => {
        setData(data);
      })
      .finally(() => {
        setLoading();
      });
  };

  const toggleItem = (item: any) => {
    dispachSSOs((prevSelected: any) => {
      if (prevSelected.some((selected: any) => selected.id === item.id)) {
        return prevSelected.filter((selected: any) => selected.id !== item.id);
      } else {
        return [...prevSelected, item];
      }
    });
  };

  useEffect(() => {
    if (props.isOpen) {
      setShow(false);
      setData([]);
      setInputValue("");
      dispachSSOs([]);
    }
  }, [props.isOpen]);

  const submit = () => {
    toggleButton();
    const url = URLS.IMPORT_EMPLOYEES_URL;
    const body = {
      ids: selectedSSOs?.map((user: any) => user.id),
    };
    ServiceApi.post(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title",
        );
        toggleButton();
        props.getData();
        props.toggle();
      })
      .catch(() => {
        toggleButton();
      });
  };

  return (
    <Drawer isOpen={props.isOpen} toggle={props.toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="!text-left">
          <h2>
            <Text>configurations.userManagment.userList.searchEmployee</Text>
          </h2>
        </Drawer.Header>
        <Drawer.Body>
          <InputGroup
            value={inputValue}
            className="mt-4 border-white"
            placeholder="Search User Name, Email ... in SSO"
            setValue={value => {
              setInputValue(value);
              if (value && value?.length > 2) {
                debounce(() => {
                  searchSSOUser(value);
                }, 400);
              }
            }}
          />
          {loading ? (
            <div className="mt-4 space-y-4">
              {[...Array(3)].map(() => (
                <Skeleton.List />
              ))}
            </div>
          ) : (
            <>
              {data?.length !== 0 && !show && (
                <>
                  <div className="mt-4 space-y-4">
                    <>
                      {data?.length === 0 ? (
                        <div className="flex w-full flex-col items-center space-y-4">
                          <NoItems />
                        </div>
                      ) : (
                        data?.map((item: any) => {
                          const isSelected = selectedSSOs.some(
                            (selected: any) => selected.id === item.id,
                          );
                          return (
                            <div
                              key={item.userId}
                              className={`${isSelected ? "bg-primary-light" : "bg-white"} flex items-center justify-between rounded p-4 shadow-card`}
                            >
                              <div className="flex-1 space-y-2">
                                <div>
                                  <h6 className="max-w-[95%] truncate text-heading-6 font-semibold text-gray-800">
                                    {item.userPrincipalName}
                                  </h6>
                                </div>
                                {item.displayName && (
                                  <div>
                                    <Icon
                                      className="mr-2 font-black text-gray-500"
                                      size="sm"
                                      icon="user"
                                    />
                                    <span className="text-body-2 font-medium text-gray-500">
                                      {item.displayName}
                                    </span>
                                  </div>
                                )}
                                {item.email && (
                                  <div>
                                    <Icon
                                      className="mr-2 font-black text-gray-500"
                                      size="sm"
                                      icon="envelope"
                                    />
                                    <span className="text-body-2 font-medium text-gray-500">
                                      {item.email}
                                    </span>
                                  </div>
                                )}
                              </div>
                              <div>
                                <CheckBox
                                  value={isSelected}
                                  setValue={() => toggleItem(item)}
                                />
                              </div>
                            </div>
                          );
                        })
                      )}
                    </>
                  </div>
                </>
              )}
            </>
          )}
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-between">
          <Button
            type="button"
            className="mr-2"
            size="sm"
            onClick={props.toggle}
            variant="white"
          >
            <Icon className="mr-2" icon={"times"} />
            <Text>global.buttons.close</Text>
          </Button>
          <Button
            type="button"
            size="sm"
            onClick={submit}
            loading={loadingButton}
            disabled={!!!selectedSSOs?.length}
          >
            <Text>global.buttons.submit</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
