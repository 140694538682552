export const generalDiscount = {
  addGeneralDiscount: "Allgemeinen Rabatt hinzufügen",
  value: "Wert",
  basicInfo: "Grundlegende Informationen",
  discountValue: "Rabattwert",
  title: "Titel",
  code: "Code",
  shortDescription: "Kurzbeschreibung",
  from: "Von",
  to: "Bis",
  discountValueLabel: "Rabattwert (%)"
};
