import {Button, Icon, Skeleton, Text, InputGroup, Select} from "components";
import FullPermissionSalesPrice from "./full-permission-price";
import {useConverter, usePermission} from "hooks";
import {createElement, useState} from "react";

type Props = {
  price: any;
  loading: boolean;
  getData: (basePrice?: number, type?: number) => void;
  showPriceSection?: boolean;
};

const PurchasePrice = ({price, loading, getData, showPriceSection}: Props) => {
  const purchasePriceCalculationPermission = usePermission(
    "PE_GetPurchasePriceCalculation",
  );
  const purchasePriceSimulationPermission = usePermission(
    "PE_GetPurchasePriceSimulation",
  );

  const PurchasePriceType = [
    {
      name: "Gross",
      id: 0,
    },
    {
      name: "Net",
      id: 1,
    },
    {
      name: "NNet",
      id: 2,
    },
  ];

  const {convertAmount} = useConverter();
  const [basePrice, SetBasePrice] = useState(0);
  const [type, SetType] = useState(0);
  return (
    <>
      {(purchasePriceCalculationPermission ||
        purchasePriceSimulationPermission) && (
        <div className="space-y-4 rounded-md border border-dashed border-gray-500 p-4">
          <h3 className="mb-5 text-heading-3 text-gray-800">
            <Text>productManagement.products.Details.purchasePrice</Text>
          </h3>

          {showPriceSection ? (
            <div className="grid grid-cols-3 gap-4">
              <div>
                <InputGroup
                  label="productManagement.products.Details.purchasePrice"
                  value={basePrice}
                  setValue={value => SetBasePrice(value)}
                  type="price"
                />
              </div>
              <Select
                label="productManagement.products.Details.type"
                placeholder="Select"
                value={type}
                items={PurchasePriceType}
                setValue={(value: any) => SetType(value)}
              />
              <Button
                variant="primary"
                light
                onClick={() => getData(Number(basePrice), type)}
                loading={loading}
                className="self-end"
              >
                <Icon icon="arrows-rotate" className="mr-2" />
                <span>
                  <Text>productManagement.products.Details.recalculate</Text>
                </span>
              </Button>
            </div>
          ) : (
            <Button
              variant="primary"
              light
              onClick={() => getData(Number(basePrice), type)}
              loading={loading}
              className="self-end"
            >
              <Icon icon="arrows-rotate" />
              <span>
                <Text>productManagement.products.Details.recalculate</Text>
              </span>
            </Button>
          )}

          {Object.keys(price)?.length > 0 && (
            <div>
              <span className="text-base text-gray-800">
                <Text>
                  productManagement.products.Details.assignedPriceTemplateChart
                </Text>
                :
              </span>
            </div>
          )}

          {loading ? (
            [1, 2, 3, 4].map((index: number) => <Skeleton.Input key={index} />)
          ) : (
            <>
              {Object.keys(price)?.length > 0 && (
                <>
                  {purchasePriceSimulationPermission && (
                    <div className="space-y-4 rounded-lg bg-primary-light p-3">
                      <h5 className="text-heading-5 text-gray-700">
                        {price.purchasePriceTemplateTitle}
                      </h5>
                      <p className="text-sm">
                        {" "}
                        <Icon icon="hashtag" size="sm" />{" "}
                        {price.purchasePriceTemplateCode}
                      </p>
                    </div>
                  )}
                  {purchasePriceSimulationPermission && (
                    <div className="h-[450px] overflow-scroll">
                      <FullPermissionSalesPrice
                        data={price}
                        type="purchase"
                        simulateType={type}
                      />
                    </div>
                  )}
                  <div className="border-gray-250 mt-4 flex flex-wrap space-x-32 border-t bg-white p-4">
                    <div className="flex-1 space-y-3">
                      <div className="grid grid-cols-3 items-center gap-2">
                        <p className="text-gray-700">
                          {/* <Text>
                          productManagement.products.Details.purchasePrice
                        </Text>{" "} */}
                          (Gross) price :{" "}
                        </p>
                        <span className="ml-4 text-heading-1 font-normal text-gray-800">
                          {convertAmount(price?.grossPrice)}
                        </span>
                      </div>
                      <div className="grid grid-cols-3 items-center gap-2">
                        <p className="text-gray-700">
                          {/* <Text>
                          productManagement.products.Details.purchasePrice
                        </Text>{" "} */}
                          (N) price :{" "}
                        </p>
                        <span className="ml-4 text-heading-1 font-normal text-gray-800">
                          {convertAmount(price?.netPrice)}
                        </span>
                      </div>

                      <div className="grid grid-cols-3 items-center gap-2">
                        <p className="text-gray-700">
                          {/* <Text>
                          productManagement.products.Details.purchasePrice
                        </Text>{" "} */}
                          (NN) price :{" "}
                        </p>
                        <span className="ml-4 text-heading-1 font-normal text-gray-800">
                          {convertAmount(price?.nNetPrice)}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default PurchasePrice;
