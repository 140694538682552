export const simulator = {
  brands: "Marken",
  brand: "Marke",
  addBrand: "Marke hinzufügen",
  salesChannels: "Sales Channels",
  salesChannel: "Sales Channel",
  addSalesChannel: "Sales Channel hinzufügen",
  PurchasePriceProcess: "Prozesse Einkaufspreis ermitteln",
  SalePriceProcess: "Prozess Verkaufspreis ermitteln",
  CalculationProcessType: "Prozesstyp Kalkulation",
  products: "Produkte",
  product: "Produkt",
  addProduct: "Produkt hinzufügen",
  programs: "Programme",
  program: "Programm",
  addProgram: "Programm hinzufügen",
  suppliers: "Lieferanten",
  supplier: "Lieferant",
  addSupplier: "Lieferant hinzufügen",
  purchasePriceTemplate: "Einkaufspreis-Template",
  assignPurchasePriceTemplate: "Einkaufspreis-Template zuweisen",
  salesPriceTemplate: "Verkaufspreis-Template",
  assignSalesPriceTemplate: "Verkaufspreis-Template zuweisen",
  simulation: "Simulation",
  purchasePrice: "Einkaufspreis",
  vk3: "vk3",
  vk2: "vk2",
  type: "Type",
  lineItemType: "Artikeltyp",
  PimProduct: "PIM Produkt",
  IwofurnProduct: "Iwofurn Produkt",
  ManualProduct: "Manuell erstellltes Produkt",
};
