import { NotificationManager } from "common/notifications";
import {
  Button,
  Drawer,
  Form,
  Icon,
  InputGroup,
  SelectLang,
  Text
} from "components";
import { flagCountry, rules } from "constant";
import { useSelector, useTitleTranslations } from "hooks";
import { useEffect, useRef, useState } from "react";
import { ServiceApi, URLS } from "services";


export default function Create(props: any) {
  const [data, setData] = useState<any>({});
  const [dataUnit, setDataUnit] = useState<any>({});
  const [loadingButton, setLoadingButton] = useState(false);
  const [langItems, setLangItems] = useState([]);
  const [langSelected, setLangSelected] = useState("de");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [standardUnitName, setStandardUnitName] = useState("");
  const [standardUnitDescription, setStandardUnitDescription] = useState("");
  const companySetting = useSelector((s) => s.companySetting);
  const formRef = useRef<any>();
  const { checkTranslations } = useTitleTranslations();

  useEffect(() => {
    if (props.isOpen && companySetting) {
      setName("")
      setDescription("")
      setStandardUnitName("")
      let arr: any = [];
      let translatesNew: any = [];
      let standardUnitTranslationsNew: any = [];
      companySetting?.contentLanguages?.forEach((item: any) => {
        arr.push({ id: item, name: item })
        translatesNew.push({ labelTitle: "", language: item })
        standardUnitTranslationsNew.push({ labelTitle: "", language: item })
      })
      setData({
        translates: translatesNew,
        code: "",
        isActive: true,
      })
      setDataUnit({
        "code": "",
        "symbol": "",
        "isDefault": true,
        "translates": standardUnitTranslationsNew,
      })
      setLangItems(arr)
      setLangSelected(companySetting.defaultContentLanguage)
    }
  }, [props.isOpen, companySetting])// eslint-disable-line react-hooks/exhaustive-deps


  const submit = () => {
    const translates = checkTranslations(data.translates);
    if (translates) {
      const standardUnitTranslates = checkTranslations(dataUnit.translates);
      if (standardUnitTranslates) {
        setLoadingButton(true);
        data.translates = translates;
        dataUnit.translates = standardUnitTranslates;
        const url = URLS.ADD_MEASUREMENT_FAMILY_URL;
        ServiceApi.post(url, data).then((res) => {
          const urlUnit = URLS.ADD_MEASUREMENT_FAMILY_UNIT_URL(res.data);
          ServiceApi.patch(urlUnit, dataUnit).then(() => { }).catch(() => { })
          NotificationManager.success(
            "global.toast.add-msg",
            "global.toast.add-title"
          );
          props.getData();
          props.toggle();
        }).catch(() => { }).finally(() => {
          setLoadingButton(false);
        });
      }
    }
  };

  const onChangeLanguage = (lang: any) => {
    setLangSelected(lang);
    data?.translates?.forEach((item: any) => {
      if (item.language === lang) {
        setName(item.labelTitle)
        setDescription(item.description)
      }
    })
    dataUnit?.translates?.forEach((item: any) => {
      if (item.language === lang) {
        setStandardUnitName(item.labelTitle)
        setStandardUnitDescription(item.description)
      }
    })
  };

  return (
    <Drawer isOpen={props.isOpen} toggle={props.toggle}>
      <Form ref={formRef} onSubmit={submit} id={"create-modal"}>
        <Drawer.Menu className="pr-0">
          <Drawer.Header className="pt-6 pr-4">
            <div className="flex items-center justify-between">
              <h2 className="text-gray-800 text-heading-2"><Text>productManagement.masterData.measurementUnits.addMeasurement</Text></h2>
              <div className="flex items-center justify-between">
                <Button
                  type="button"
                  variant="light"
                  size="sm"
                  className="ml-2"
                  onClick={() => props.toggle()}
                >
                  <Icon className="mr-2" icon={"times"} /><Text>global.buttons.close</Text>
                </Button>
                <div className="ml-1">
                  <SelectLang value={langSelected} items={langItems} onChangeLanguage={onChangeLanguage} />
                </div>
              </div>
            </div>
          </Drawer.Header>
          <Drawer.Body className="space-y-8 pr-4">
            <InputGroup
              ariaLabel="cy-measurement-name"
              label="productManagement.masterData.measurementUnits.name"
              value={name}
              flag={flagCountry[langSelected]}
              setValue={(value) => {
                data?.translates?.forEach((item: any) => {
                  if (item.language === langSelected) {
                    item.labelTitle = value
                  }
                })
                setData((p: any) => ({ ...p, translates: data?.translates }));
                setName(value)
              }}
              rules={rules.required}
            />
            <InputGroup
              ariaLabel="cy-measurement-code"
              label="productManagement.masterData.measurementUnits.code"
              value={data?.code}
              className="border-white"
              setValue={(code) => setData((p: any) => ({ ...p, code }))}
              rules={rules.required}
            />
            <InputGroup
              ariaLabel="cy-measurement-description"
              label="productManagement.masterData.measurementUnits.description"
              value={description}
              as="short-textarea"
              flag={flagCountry[langSelected]}
              setValue={(value) => {
                data?.translates?.forEach((item: any) => {
                  if (item.language === langSelected) {
                    item.description = value
                  }
                })
                setData((p: any) => ({ ...p, translates: data?.translates }));
                setDescription(value)
              }}
            />
            <div><h5 className="text-gray-800" ><Text>productManagement.masterData.measurementUnits.defaultUnit</Text></h5></div>
            <InputGroup
              ariaLabel="cy-measurement-defaultUnitName"
              label="productManagement.masterData.measurementUnits.defaultUnitName"
              value={standardUnitName}
              flag={flagCountry[langSelected]}
              setValue={(value) => {
                dataUnit?.translates?.forEach((item: any) => {
                  if (item.language === langSelected) {
                    item.labelTitle = value
                  }
                })
                setDataUnit((p: any) => ({ ...p, translates: dataUnit?.translates }));
                setStandardUnitName(value)
              }}
              rules={rules.required}
            />
            <InputGroup
              ariaLabel="cy-measurement-defaultUnitCode"
              label="productManagement.masterData.measurementUnits.defaultUnitCode"
              value={dataUnit?.code}
              className="border-white"
              setValue={(code) => setDataUnit((p: any) => ({ ...p, code }))}
              rules={rules.required}
            />
            <InputGroup
              ariaLabel="cy-measurement-defaultUnitDescription"
              label="productManagement.masterData.measurementUnits.defaultUnitDescription"
              value={standardUnitDescription}
              as="short-textarea"
              flag={flagCountry[langSelected]}
              setValue={(value) => {
                dataUnit?.translates?.forEach((item: any) => {
                  if (item.language === langSelected) {
                    item.description = value
                  }
                })
                setData((p: any) => ({ ...p, translates: dataUnit?.translates }));
                setStandardUnitDescription(value)
              }}
            />
            <InputGroup
              ariaLabel="cy-measurement-defaultUnitSymbol"
              label="productManagement.masterData.measurementUnits.defaultUnitSymbol"
              value={dataUnit?.symbol}
              className="border-white"
              setValue={(symbol) => setDataUnit((p: any) => ({ ...p, symbol }))}
            />
          </Drawer.Body>
          <Drawer.Footer className="flex justify-between">
            <Button
              aria-label="cy-create-measurement-submit"
              type="submit"
              variant="primary"
              loading={loadingButton}
            >
              <Icon icon="check" /> <Text>global.buttons.submit</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Form>
    </Drawer>
  );
}
