import { NotificationManager } from "common/notifications";
import {
  Drawer,
  Text,
  Icon,
  Button,
  InputGroup,
} from "components";
import { useConverter, useDataState, useToggleState } from "hooks";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { ServiceApi, URLS } from "services";
import { PimContext } from "..";

type Price = {
  currency?: string;
  amount?: number;
  currencySymbol?: string;
}

type Props = {
  isOpen: boolean;
  toggle: () => void;
  onChangePrice: () => void;
  type: 'uvp' | 'vk1';
};

export default function EditPriceDrawer({ isOpen, toggle, onChangePrice, type }: Props) {
  const [data, setData, setBaseData, isChanged] = useDataState<Price>({});
  const { productId } = useParams();
  const { convertAmount } = useConverter();
  const [loading, toggleLoading] = useToggleState();
  const { details } = useContext(PimContext);

  const onSubmitHandler = () => {
    toggleLoading();
    const url = type === 'vk1' ? URLS.OVERWRITE_PUBLISHED_PRODUCT_PRICE(productId) : URLS.OVERWRITE_PUBLISHED_PRODUCT_UVP_PRICE(productId);
    const params = { channelCode: details.channelCode };
    const body = type === 'vk1' ? { vk1Price: { ...data } } : {
      uvpPrice: !!data?.amount ?
        {
          ...data, currency: data?.currency || details?.vk1Price?.currency,
          currencySymbol: data?.currencySymbol || details?.vk1Price?.currencySymbol
        }
        : null
    };
    ServiceApi.put(url, body, { params })
      .then(() => {
        toggleLoading();
        onChangePrice();
        toggle();
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
      })
      .catch(() => toggleLoading());
  };

  useEffect(() => {
    setBaseData(type === 'vk1' ? details?.vk1Price : details?.uvpPrice);
  }, [details, type]);

  return (
    <>
      <Drawer isOpen={isOpen} toggle={toggle}>
        <Drawer.Menu>
          <Drawer.Header className="flex items-center justify-between text-heading-2 font-semibold">
            <span>
              <Text>productManagement.publishedProduct.Details.editPrice</Text>
            </span>
            <div className="space-x-2">
              <Button
                variant={"light"}
                size="sm"
                className="flex items-center"
                onClick={toggle}
              >
                <span>
                  <Text>global.buttons.close</Text>
                </span>
                <Icon icon="close" className="ml-2" />
              </Button>
            </div>
          </Drawer.Header>
          <Drawer.Body className="space-y-6">
            <div
              className="p-3 border border-dashed rounded-xl space-y-3 border-gray-600 text-body-2 font-medium text-gray-600"
            >
              <div>
                <Text>vk2</Text> :{" "}
                {convertAmount(details?.vk2Price?.amount)}
              </div>
              <div>
                <Text>productManagement.publishedProduct.Details.uvpPrice</Text> :{" "}
                {!!details?.uvpPrice?.amount ? `${convertAmount(details?.uvpPrice?.amount)}` : '--'}
              </div>
              <div>
                <Text>vk1</Text> :{" "}
                {convertAmount(details?.originalVk1Price?.amount)}
              </div>
              <div>
                <Text>productManagement.publishedProduct.Details.uvpPrice</Text> :{" "}
                {convertAmount(details?.uvpPrice?.amount)}
              </div>
              {details?.hasOverwrittenPrice && (
                <div>
                  <Text>
                    productManagement.publishedProduct.Details.overWritedPrice
                  </Text>{" "}
                  :{" "}
                  {convertAmount(details?.vk1Price?.amount)}
                </div>
              )}
            </div>
            <div className="grid grid-cols-2 gap-4">
              <InputGroup
                label={
                  <Text>
                    productManagement.publishedProduct.Details.enterPrice
                  </Text>
                }
                value={data?.amount || ''}
                setValue={(e) => { setData(p => ({ ...p, amount: e })) }}
                type="price"
              />
            </div>
          </Drawer.Body>
          <Drawer.Footer className="flex justify-between">
            <Button type="button" variant={"light"} onClick={toggle}>
              <Text>global.buttons.cancel</Text>
            </Button>
            <Button
              type="button"
              onClick={onSubmitHandler}
              loading={loading}
              disabled={!isChanged}
            >
              <Text>productManagement.publishedProduct.Details.overwritePrice</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Drawer >
    </>
  );
}
