import { useContext } from "react";
import PriceCalculationTab from "compositions/price-calculation-units/price-calculation-tab";
import { calculationLevelEnum } from "constant";
import { SuppliersContext } from "..";

export default function PriceCalculation() {
  const { suppliersData } = useContext<any>(SuppliersContext);

  return (
    <PriceCalculationTab
      refId={suppliersData?.supplierId}
      level={calculationLevelEnum.supplier}
      refTitle={suppliersData?.name}
      refCode={suppliersData?.code}
    />
  );
}
