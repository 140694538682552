export default function getFileProperties(type: string) {
  if (type?.toLowerCase()?.includes("image"))
    return {icon: "", type: "Image", accept: ""};
  else if (type?.toLowerCase()?.includes("word"))
    return {icon: "file-word", type: "Word document", accept: ".doc,.docx"};
  else if (type?.toLowerCase()?.includes("excel")) {
    return {icon: "file-excel", type: "Excel", accept: ".xls, .xlsx"};
  } else if (type?.toLowerCase()?.includes("pdf")) {
    return {icon: "file-pdf", type: "pdf", accept: ".pdf"};
  } else return {icon: "file", type: "Unknown", accept: ""};
}
