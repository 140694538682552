import { useEffect, useState } from "react";
import { Button, Modal, Form, InputGroup, Text, Icon, Select } from "components";
import { ServiceApi } from "services";
import { URLS } from "services";
import { useDataState } from "hooks";
import { rules, types } from "constant";
import { NotificationManager } from "common/notifications";

type addModalType = {
  isOpen: boolean;
  toggle: (value?: boolean) => void;
  getData: () => void;
};

export default function AddModal({ isOpen, toggle, getData }: addModalType) {
  const [data, setData] = useDataState({
    title: "",
    description: "",
    objectType: 0
  });
  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    setData({
      title: "",
      description: "",
      objectType: 0
    });
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const submit = () => {
    setLoadingButton(true);
    const url = URLS.PIM_CHECK_LIST_URL;
    ServiceApi.post(url, data)
      .then((res) => {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title"
        );
        getData();
      })
      .finally(() => {
        toggle();
        setLoadingButton(false);
      });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} modalClassName="z-40">
      <Form onSubmit={submit}>
        <Modal.Header className="flex flex-row">
          <h6 className="text-heading-2 text-gray-800">
            <Text>productManagement.products.completeness.addCheckList</Text>
          </h6>
        </Modal.Header>
        <Modal.Body className="max-h-100 overflow-auto">
          <div className="space-y-6">
            <Select
              label="productManagement.products.completeness.type"
              setValue={(objectType) =>
                setData((p) => ({ ...p, objectType }))
              }
              items={types.checkListObjectType}
              value={data.objectType}
            />
            <InputGroup
              label="productManagement.products.completeness.title"
              value={data.title}
              className="border-white"
              setValue={(title) => setData((p) => ({ ...p, title }))}
              rules={rules.required}
            />
            <InputGroup
              as="short-textarea"
              label="productManagement.products.completeness.shortDescription"
              value={data.description}
              className="border-white"
              setValue={(description) =>
                setData((p) => ({ ...p, description }))
              }
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-between">
          <Button type="button" onClick={toggle} variant="white">
            <Icon className="mr-2" icon={"times"} />
            <Text>global.buttons.close</Text>
          </Button>
          <Button type="submit" loading={loadingButton}>
            <Text>global.buttons.submit</Text>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
