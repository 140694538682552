export const erpConfiguration = {
  name: "Name",
  inboundConnectionString: "In bound Connection string of the service bus",
  outboundConnectionString: "Out bound Connection string of the service bus",
  inboundQueueName: "Inbound Queue Name",
  outboundQueueName: "Outbound Queue Name",
  dynamics365: "Dynamics 365",
  outboundQueueSubscriptionName: "Outbound Queue Subscription Name",
  erpInventoryBaseUrl: "Erp Inventory Base Url",
  environmentIdentifier: "Environment Id",
  tenantIdentifier: "Tenant Id",
  clientIdentifier: "Client Id",
  clientSecret: "Client Secret",
  inventory: "Inventory",
};
