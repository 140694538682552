import {NotificationManager} from "common/notifications";
import useSelector from "./useSelector";
import {useTranslate} from "./useTranslate";

export default function useTitleTranslations() {
  const companySetting = useSelector(s => s.companySetting);
  const translate = useTranslate();
  const checkTranslations = (translates: any) => {
    const defaultLang = translates?.find(
      (item: any) =>
        item.language.toLowerCase() ===
        companySetting.defaultDashboardLanguage.toLowerCase(),
    );

    const isValid = !!defaultLang?.labelTitle;
    const cloneTranslate = translates?.filter((item: any) => item?.labelTitle);

    if (isValid) {
      return cloneTranslate;
    } else
      NotificationManager.error(
        translate("global.toast.add-default-language-value"),
        translate("global.validation"),
      );
  };
  return {checkTranslations};
}
