import {Breadcrumb} from "components";
import Config from "./sections/config";
import Templates from "./sections/Templates";
import Simulation from "./sections/simulation";
import {createContext, useState} from "react";

export const SimulationContext = createContext({} as any);

type SimulateProps = {
  supplier: dataType | null;
  program: dataType | null;
  brand: dataType | null;
  product: dataType | null;
  purchasePriceTemplate: dataType | null;
  salePriceTemplate: dataType | null;
  salesChannel: dataType | null;
  basePrice: number;
  purchasePriceType: number;
  calculationProcessType: number;
  purchasePrice?: number;
  lineItemType?: number;
  salePriceType?: number;
};

type dataType = {
  name: string;
  code?: string;
  id: string;
};

export default function PriceSimulator() {
  const [simulateData, setSimulateData] = useState<SimulateProps>({
    supplier: null,
    program: null,
    brand: null,
    product: null,
    salesChannel: null,
    purchasePriceTemplate: null,
    salePriceTemplate: null,
    basePrice: 0,
    purchasePriceType: 0,
    calculationProcessType: 0,
    lineItemType: 0,
    purchasePrice: 0,
    salePriceType: 0,
  });

  const [
    optionalDiscountCalculationUnitIds,
    setOptionalDiscountCalculationUnitIds,
  ] = useState<string[]>([]);

  return (
    <SimulationContext.Provider
      value={{
        simulateData,
        setSimulateData,
        optionalDiscountCalculationUnitIds,
        setOptionalDiscountCalculationUnitIds,
      }}
    >
      <div className="space-y-8">
        <Breadcrumb />
        <Config />
        <Templates />
        <Simulation />
      </div>
    </SimulationContext.Provider>
  );
}
