import { InputGroup, SelectLang, Text, Toggler } from "components";
import { flagCountry, rules } from "constant";
import { useSelector } from "hooks";
import { Fragment, useContext, useEffect, useState } from "react";
import { AttributeContext } from "..";

export default function InfoAndProperties() {
  const { data, setData } = useContext<any>(AttributeContext);
  const [langItems, setLangItems] = useState([]);
  const [langSelected, setLangSelected] = useState("de");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const companySetting = useSelector(s => s.companySetting);

  const handleSetData = (key: any) => {
    return (value: any) => setData((p: any) => ({ ...p, [key]: value }));
  };

  useEffect(() => {
    let arr: any = [];
    companySetting?.contentLanguages?.forEach((item: any) => {
      arr.push({ id: item, name: item });
    });
    setLangItems(arr);
    setLangSelected(companySetting.defaultContentLanguage);
    if (data?.translations.length !== 0) {
      data?.translations?.forEach((item: any) => {
        if (item.language === companySetting.defaultContentLanguage) {
          setName(item.labelTitle);
          setDescription(item.description);
        }
      });
    }
  }, [companySetting]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeLanguage = (lang: any) => {
    setLangSelected(lang);
    data?.translations?.forEach((item: any) => {
      if (item.language === lang) {
        setName(item.labelTitle);
        setDescription(item.description);
      }
    });
  };

  return (
    <Fragment>
      <div className="flex items-center justify-between">
        <div>
          <h2 className="text-gray-800">
            <Text>
              productManagement.masterData.attributes.infoAndProperties
            </Text>
          </h2>
        </div>
        <div aria-label="cy-attribute-select-lang">
          <SelectLang
            value={langSelected}
            items={langItems}
            onChangeLanguage={onChangeLanguage}
          />
        </div>
      </div>
      <InputGroup
        ariaLabel="cy-attribute-attributeName"
        label="productManagement.masterData.attributes.attributeName"
        value={name}
        flag={flagCountry[langSelected]}
        setValue={value => {
          data?.translations?.forEach((item: any) => {
            if (item.language === langSelected) {
              item.labelTitle = value;
            }
          });
          setData((p: any) => ({ ...p, translations: data?.translations }));
          setName(value);
        }}
        rules={rules.required}
      />
      {/* <InputGroup
        ariaLabel="cy-attribute-attributeCode"
        label="productManagement.masterData.attributes.attributeCode"
        value={data?.code}
        setValue={handleSetData("code")}
        rules={rules.required}
      /> */}
      <InputGroup
        ariaLabel="cy-attribute-attributeDescribtion"
        as="short-textarea"
        flag={flagCountry[langSelected]}
        label="productManagement.masterData.attributes.shortDescription"
        value={description}
        setValue={value => {
          data?.translations?.forEach((item: any) => {
            if (item.language === langSelected) {
              item.description = value;
            }
          });
          setData((p: any) => ({ ...p, translations: data?.translations }));
          setDescription(value);
        }}
      />
      <Toggler
        label={"productManagement.masterData.attributes.isRequired"}
        value={data.isRequired}
        setValue={handleSetData("isRequired")}
      />
      <Toggler
        label={"productManagement.masterData.attributes.perLanguage"}
        value={data.perLanguage}
        setValue={handleSetData("perLanguage")}
      />
      <Toggler
        label={"productManagement.masterData.attributes.perChannel"}
        value={data.perChannel}
        setValue={handleSetData("perChannel")}
      />
      <InputGroup
        ariaLabel="cy-attribute-attributeOrder"
        label="productManagement.masterData.attributes.order"
        value={data?.sortOrder}
        setValue={handleSetData("sortOrder")}
        type="number"
        keyfilter="onlyNum"
      />
    </Fragment>
  );
}
