import {Text, Wrapper, AddressForm} from "components";
import {useContext} from "react";
import {generateMapSrc} from "utils";
import {StoreContext} from ".";

export default function Location() {
  const {storeData, setStoreData, addressErrorMsg} = useContext(StoreContext);

  return (
    <Wrapper>
      <Wrapper.Header>
        <h1 className="text-heading-2 font-semibold">
          <Text>company.stores.location</Text>
        </h1>
      </Wrapper.Header>
      <Wrapper.Body className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
        <section className="space-y-8">
          <AddressForm
            data={storeData}
            setData={setStoreData}
            errorMsg={addressErrorMsg}
          />
        </section>
        <div className="flex flex-col">
          <h6 className="mb-2">
            <Text>company.stores.locationOnMap</Text>
          </h6>
          <iframe
            title="Address"
            src={generateMapSrc(storeData.address)}
            className="block aspect-square w-full rounded bg-gray-300 shadow lg:aspect-auto lg:flex-1"
          />
        </div>
      </Wrapper.Body>
    </Wrapper>
  );
}
