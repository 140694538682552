import {
  Button,
  Icon,
  Skeleton,
  Status,
  Text,
  Wrapper,
  NoItems,
} from "components";
import {dateFormats} from "constant";
import {useConverter, useToggleState} from "hooks";
import {cloneDeep} from "lodash";
import {useContext, useEffect, useState} from "react";
import {Fragment} from "react/jsx-runtime";
import {ServiceApi} from "services";
import {Supplier} from "types";
import {ProgramContext} from "../details";
import AvailabilityStatusForm from "./AvailabilityStatusForm";
import RemoveConfirm from "./RemoveConfirm";

export default function AvailabilityStatus() {
  const programContext = useContext(ProgramContext);
  const program = programContext.data;
  const {convertDate} = useConverter();
  const [openAddStatus, toggleAddStatus] = useToggleState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Supplier.AvailableStatusItem[]>([]);
  const [editId, setEditId] = useState<string | null>(null);
  const [removeId, setRemoveId] = useState<string | null>(null);
  const hasLoading = !!programContext?.loading || loading;
  const clearEditId = () => {
    setEditId(null);
  };
  const clearRemoveId = () => {
    setRemoveId(null);
  };
  const handleAddItem = (id: string) => {
    getData();
  };
  const handleEditItem = (id: string) => {
    getData();
  };
  const handleRemoveItem = (id: string) => {
    setData(p => {
      const data = cloneDeep(p);
      const index = data.findIndex(e => e.id === id);
      const has = index !== -1;
      if (has) data.splice(index, 1);
      return data;
    });
  };
  const getData = () => {
    if (!program?.id) return;
    const url = `/productservice/api/supplierprograms/${program.id}/availability-status`;
    setLoading(true);
    ServiceApi.get(url)
      .then(({data}) => {
        setData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(getData, [program]);
  return (
    <Fragment>
      <Wrapper>
        <Wrapper.Body className="flex flex-col gap-4">
          {hasLoading ? (
            <Skeleton.AvailableStatus />
          ) : (
            <Fragment>
              <>
                {data && data?.length ? (
                  <>
                    {data?.map(e => (
                      <Fragment key={e.id}>
                        <div className="flex w-full items-center gap-2 rounded-2xl border bg-white p-4 shadow-nested">
                          <Status.Availability id={e.availabilityStatus} />
                          <p className="flex-1 text-body-base font-normal text-gray-600">
                            {e.isDefault ? (
                              <Text>
                                productManagement.masterData.suppliers.availabilityStatus.defaultStatus
                              </Text>
                            ) : e.validationPeriod ? (
                              <Text
                                fromDate={convertDate(
                                  e.fromDate,
                                  dateFormats.onlyDate,
                                )}
                                toDate={
                                  convertDate(e.toDate, dateFormats.onlyDate) ||
                                  "--"
                                }
                              >
                                productManagement.masterData.suppliers.availabilityStatus.statusDate
                              </Text>
                            ) : null}
                          </p>
                          {!e.isDefault && (
                            <Button
                              type="button"
                              variant="danger"
                              light
                              className="!px-4"
                              onClick={() => setRemoveId(e.id)}
                            >
                              <Icon icon="trash" className="size-4" />
                            </Button>
                          )}
                          <Button
                            type="button"
                            variant="primary"
                            light
                            className="!px-4"
                            onClick={() => setEditId(e.id)}
                          >
                            <Icon icon="edit" className="size-4" />
                          </Button>
                        </div>
                        <AvailabilityStatusForm
                          isOpen={editId === e.id}
                          toggle={clearEditId}
                          availableStatus={e}
                          onSubmitted={handleEditItem}
                        />
                      </Fragment>
                    ))}
                  </>
                ) : (
                  <NoItems />
                )}
              </>
            </Fragment>
          )}
          <div>
            <Button
              type="button"
              variant="primary"
              light
              onClick={toggleAddStatus}
              disabled={hasLoading}
            >
              <Icon icon="add" className="size-4" />{" "}
              <Text>
                productManagement.masterData.suppliers.availabilityStatus.addStatusButton
              </Text>
            </Button>
          </div>
        </Wrapper.Body>
      </Wrapper>
      {!hasLoading && (
        <Fragment>
          <RemoveConfirm
            id={removeId}
            isOpen={!!removeId}
            toggle={clearRemoveId}
            onRemoved={handleRemoveItem}
          />
          <AvailabilityStatusForm
            isOpen={openAddStatus}
            toggle={toggleAddStatus}
            onSubmitted={handleAddItem}
          />
        </Fragment>
      )}
    </Fragment>
  );
}
