import {AddressForm} from "components";
import {Fragment, useContext} from "react";
import {AddUserListContext} from "..";

export default function Location() {
  const {data, setData, addressErrorMsg} = useContext<any>(AddUserListContext);

  return (
    <Fragment>
      <AddressForm data={data} setData={setData} errorMsg={addressErrorMsg} />
    </Fragment>
  );
}
