import {useEffect, useState} from "react";
import {
  Button,
  Drawer,
  Text,
  Skeleton,
  InputGroup,
  Icon,
  CheckBox,
} from "components";
import {ServiceApi, URLS} from "services";
import {Departments} from "types";
import {useToggleState} from "hooks";
import {cloneDeep} from "lodash";
import {NotificationManager} from "common/notifications";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  getDetails: () => void;
  departmentId: string;
  employeesIds: string[];
}

type Params = {
  keyword?: string | null;
  pageSize: number;
  pageNumber: number;
};

export default function AddEmployeeToDepartment({
  isOpen,
  toggle,
  departmentId,
  employeesIds,
  getDetails,
}: Props) {
  const [data, setData] = useState<Departments.EmployeeList>();
  const [ids, setIds] = useState<string[]>([]);
  const [params, setParams] = useState<Params>({
    keyword: "",
    pageNumber: 1,
    pageSize: 10,
  });

  const [loading, setLoading] = useToggleState();
  const [loadingSubmit, setLoadingSubmit] = useToggleState();

  const onSelectEmployeeHandler = (id: string) => {
    let clone = cloneDeep(ids);
    if (clone.includes(id)) {
      clone = clone.filter((e: string) => e !== id);
    } else {
      clone.push(id);
    }
    setIds(clone);
    // setTeamLead(teamLead);
  };

  const getAllEmployee = () => {
    setLoading();
    const url = URLS.GET_EMPLOYEES_LIST_URL;
    const config = {params};
    ServiceApi.get(url, config)
      .then(({data}: any) => {
        setData(data);
        setLoading();
      })
      .catch(() => setLoading());
  };

  useEffect(() => {
    if (isOpen) {
      const timeoutId = setTimeout(() => getAllEmployee(), 500);
      return () => clearTimeout(timeoutId);
    } else {
      setIds([]);
      setData(undefined);
    }
  }, [params, isOpen]);

  const onSubmitHandler = () => {
    setLoadingSubmit();
    const url = URLS.ADD_EMPLOYEE_TO_DEPARTMENT(departmentId);
    const body = {
      employeeIds: ids,
    };
    ServiceApi.post(url, body)
      .then(() => {
        setLoadingSubmit();
        getDetails();
        toggle();
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title",
        );
      })
      .catch(() => setLoadingSubmit());
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle} size={"lg"}>
      <Drawer.Menu>
        <Drawer.Header className="flex items-center justify-between">
          <Text>company.stores.addEmployee</Text>
          <Button size="sm" variant={"light"} onClick={toggle}>
            <Text>global.buttons.close</Text>
          </Button>
        </Drawer.Header>
        <Drawer.Body>
          <Drawer.Body className="space-y-6">
            {loading ? (
              [1, 2, 3, 4].map(() => <Skeleton.List />)
            ) : (
              <>
                <InputGroup
                  value={params?.keyword}
                  label={"company.stores.search"}
                  setValue={keyword =>
                    setParams(p => ({...p, keyword, pageNumber: 1}))
                  }
                  placeholder="company.stores.searchPlaceHolder"
                />
                {data?.items?.map((employee: Departments.EmployeeItem) => {
                  const isEmployee = employeesIds?.includes(
                    employee.employeeId,
                  );
                  return (
                    <>
                      {!isEmployee && (
                        <div
                          onClick={() =>
                            onSelectEmployeeHandler(employee?.employeeId)
                          }
                          className="flex cursor-pointer justify-between rounded-lg bg-white p-4 shadow-card hover:bg-primary-light"
                        >
                          <div className="flex flex-col gap-2">
                            <span className="text-heading-6 font-semibold">
                              {employee?.firstName} {employee?.lastName}
                            </span>
                            <span className="text-body-2 text-gray-500">
                              # {employee?.employeeNumber}
                            </span>
                            <span className="text-body-2 text-gray-500">
                              <Icon icon="envelope" /> {employee?.emailAddress}
                            </span>
                          </div>
                          <div>
                            <CheckBox
                              value={
                                ids?.includes(employee?.employeeId) as boolean
                              }
                            />
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
              </>
            )}
          </Drawer.Body>
        </Drawer.Body>
        <Drawer.Footer className="flex justify-end">
          <Button size="sm" onClick={onSubmitHandler} loading={loadingSubmit}>
            Add Selected employee
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
