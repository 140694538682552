const EMPLOYEES = {
  GET_EMPLOYEES_LIST_URL: "accountservice/api/employees",
  IMPORT_EMPLOYEES_URL: "accountservice//api/employees/import-by-ids",
  GET_EMPLOYEES_DETAILS_URL: id => `accountservice/api/employees/${id}`,
  CHANGE_EMPLOYEE_STATUS: id =>
    `/accountservice/api/employees/${id}/change-account-enabled`,
  UPSERT_AAD_USER: id =>
    `/accountservice/api/users/${id}/upsert-employee-and-assign`,
};

export default EMPLOYEES;
