import {allProducts} from "./all-products";
import {importProduct} from "./import-product";
import {Details} from "./details";
import {completeness} from "./completeness";

export const products = {
  allProducts: allProducts,
  importProduct: importProduct,
  Details,
  completeness,
};
