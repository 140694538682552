import Notifications from "./notifications";
import GlobalSettings from "./global-settings";
import UserManagment from "./user-managment";
import AddressConfiguration from "./address-configuration";

const configurations = [
  {
    path: "configurations/notifications",
    children: [...Notifications],
  },
  {
    path: "configurations/global-settings",
    children: [...GlobalSettings],
  },
  {
    path: "configurations/user-managment",
    children: [...UserManagment],
  },
  {
    path: "configurations/address-configuration",
    children: [...AddressConfiguration],
  },
];

export default configurations;
