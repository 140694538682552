import {Button, Icon, Text} from "components";
import {useState, useContext} from "react";
import {useToggleState} from "hooks";
import {ServiceApi, URLS} from "services";
import Chooser from "../common/chooser";
import {SimulationContext} from "pages/price-engine/price-simulator";
import Info from "../common/info";

const originalFilterData = {
  productId: null,
  categoryId: null,
  keyword: "",
  language: null,
  pageNumber: 1,
  pageSize: 10,
  pimProductFamilyId: null,
  priceFrom: null,
  priceTo: null,
  programId: null,
  searchFields: "",
  sortType: null,
  supplierId: null,
  brandId: null,
  title: null,
  version: null,
};

export default function Product() {
  const {setSimulateData, simulateData} = useContext(SimulationContext);
  const [loading, setLoading] = useToggleState();
  const [isOpen, toggle] = useToggleState();
  const [products, setProducts] = useState<any>([]);
  const [params, setParams] = useState<any>(originalFilterData);

  const Products = () => {
    setLoading();
    const url = URLS.POST_SEARCH_LIST_PRODUCTS;
    const config = params;
    ServiceApi.post(url, config)
      .then(({data}) => {
        const clone = data?.searchResult?.map((product: any) => ({
          id: product.id,
          name: product.originalName,
          code: product.articleNumber,
        }));
        setProducts(clone);
        setLoading();
      })
      .catch(() => setLoading());
  };

  return (
    <>
      <div className="space-y-4">
        <div>
          <span className="text-body-base text-gray-800">
            <Text>priceEngine.simulator.product</Text> :
          </span>
        </div>
        {simulateData["product"] ? (
          <Info dataKey="product" />
        ) : (
          <Button light size="sm" onClick={toggle}>
            <Icon icon="plus" className="mr-2" />
            <Text>priceEngine.simulator.addProduct</Text>
          </Button>
        )}
      </div>
      <Chooser
        isOpen={isOpen}
        toggle={toggle}
        title="priceEngine.simulator.products"
        data={products}
        getData={Products}
        loading={loading}
        setParams={setParams}
        params={params}
        dataKey="product"
        setSimulateData={setSimulateData}
      />
    </>
  );
}
