export const infoPage = {
  // Add info page
  "addInfoPageTitle": "Seite hinzufügen",
  "infoPageTitle": "Seitentitel",
  "infoPageCode": "Code",
  "infoPageDescription": "Beschreibung",
  "infoPageCloseButton": "Schließen",
  "infoPageSubmitButton": "Senden",
  "infoPageDetailsButton": "Details",
  // Main section
  "deletePageButton": "Seite löschen",
  "activeStatus": "Aktiv",
  "inactiveStatus": "Inaktiv",
  "layoutDetails": "Details",
  // Edit info page
  "editPage": "Seite bearbeiten",
  "pageTitle": "Seitentitel",
  "description": "Beschreibung",
  "closeEditModal": "Schließen",
  "submitEditModal": "Senden",
  "reorderLayoutsToast": "Layout erfolgreich neu geordnet.",
  // Add layout form
  "addLayoutButton": "Layout hinzufügen",
  "addLayoutHeader": "Layout hinzufügen",
  "layoutTitle": "Titel",
  "selectLayouts": "Layouts",
  "closeLayoutMenu": "Schließen",
  "submitAddLayout": "Layout hinzufügen",
  "addLayoutToast": "Layout erfolgreich hinzugefügt.",
  // Edit banner
  "bannerTitle": "Titel",
  "bannerDescription": "Beschreibung",
  "bannerLinkAssignedToItem": "Link dem Element zugeordnet",
  "bannerLink": "Link",
  "bannerButtonLink": "Button-Link",
  "bannerButtonLabel": "Button-Beschriftung",
  "bannerCancelButton": "Abbrechen",
  "bannerSubmitButton": "Senden",
  "bannerEditedToast": "Layout erfolgreich bearbeitet.",
  // Edit Banner Style Items
  "bannerBackgroundColor": "Hintergrundfarbe",
  "bannerTitleTextColor": "Titelfarbe",
  "bannerBodyTextColor": "Farbe Beschreibungstext",
  "bannerButtonBackgroundColor": "Button-Hintergrundfarbe",
  "bannerButtonTextColor": "Button-Textfarbe",
  // Edit products menu
  "productsTitle": "Titel",
  "productsSection": "Produkte",
  "productsAddButton": "Produkt hinzufügen",
  "productsCancelButton": "Abbrechen",
  "productsSubmitButton": "Senden",
  "productsEditedToast": "Layout erfolgreich bearbeitet.",
  "productsRemovedToast": "Layout erfolgreich entfernt.",
  // Edit product menu
  "productAddTitle": "Produkt hinzufügen",
  "productEditTitle": "Produkt bearbeiten",
  "productLabel": "Bezeichnung",
  "productPrice": "Preis",
  "productDiscount": "Rabatt",
  "productSelectImage": "Bild auswählen",
  "productAddImageButton": "Bild hinzufügen",
  "productCancelButton": "Abbrechen",
  "productSubmitButton": "Senden",
  // Remove layout
  "removeLayoutToast": "Layout erfolgreich entfernt."
}