import { Channels } from "components";
import { useContext } from "react";
import { SuppliersContext } from "..";
import { URLS } from "services";

export default function General() {
  const { suppliersData } = useContext<any>(SuppliersContext);

  return (
    <Channels
      id={suppliersData.supplierId}
      publishUrl={URLS.PUBLISH_SUPPLIER_TO_CHANNEL}
      unpublishUrl={URLS.UNPUBLISH_SUPPLIER_FROM_CHANNEL} />
  );
}
