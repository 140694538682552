import {FC, ReactNode} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {MsalProvider} from '@azure/msal-react';
import {authInstance} from './constant';
import {I18nextProvider} from 'react-i18next';
import i18n from './langs/i18n';
import {Provider} from 'react-redux';
import store from './store';

interface Props {
  children: ReactNode;
}

const AppProviders: FC<Props> = ({children}) => {
  return (
    <MsalProvider instance={authInstance}>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <BrowserRouter>{children}</BrowserRouter>
        </Provider>
      </I18nextProvider>
    </MsalProvider>
  );
};

export default AppProviders;
