import {
  Button,
  Form,
  Icon,
  InputGroup,
  Drawer,
  Text,
  Table,
} from "components";
import { FormEvent, Fragment, useContext, useEffect, useRef, useState } from "react";
import { toggleProps } from "types";
import { ServiceApi } from "services";
import { rules, types } from "constant";
import { cloneDeep } from "lodash";
import { toast } from "react-hot-toast";
import { layoutType } from "../../../type";
import { InfoPageContext } from "../..";
import { useToggleState } from "hooks";
import ProductForm from "./ProductForm";
import Product from "./Product";

type dataType = layoutType;
type editBannerComponentProps = toggleProps & {
  initData: dataType;
};
export default function EditProducts({
  isOpen,
  toggle,
  initData,
}: editBannerComponentProps) {
  const formRef = useRef<any>(null);
  const { infoPageData, setInfoPageData } = useContext(InfoPageContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initData);
  const [isProductFormOpen, toggleProductForm] = useToggleState(false);

  const handleSetValue = (key: keyof dataType) => {
    return (value: any) => {
      const isProducts = key === "products";
      setData((p) => ({
        ...p,
        [key]: value,
        ...(isProducts && { products: [value] }),
      }));
    };
  };
  const submitHandler = (formData: dataType) => {
    setLoading(true);
    const url = `/productservice/api/infopages/${infoPageData.id}/layout/${data.id}`;
    const body = { ...formData };
    ServiceApi.put(url, body)
      .then(() => {
        setInfoPageData((p) => {
          const data = cloneDeep(p);
          const layoutIndex = data.infoPageLayouts.findIndex(
            (e) => e.id === body.id
          );
          data.infoPageLayouts[layoutIndex] = body;
          return data;
        });
        setData(body);
        toggle();
        toast.success("applications.infoPage.productsEditedToast");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const submit = (e?: FormEvent) => {
    formRef.current.submitHandler(e, data);
  };
  useEffect(() => {
    setData(initData);
  }, [initData]);
  return (
    <Fragment>
      <Drawer isOpen={isOpen} toggle={toggle}>
        <Drawer.Menu>
          <Drawer.Header>
            <h2 className="text-start">
              <Text>
                {
                  types.infoPageLayout.find(
                    (e) => e.id === data.infoPageLayoutType
                  )?.name
                }
              </Text>
            </h2>
          </Drawer.Header>
          <Drawer.Body className="space-y-8">
            <Form ref={formRef} onSubmit={submitHandler}>
              <InputGroup
                label="applications.infoPage.productsTitle"
                value={data.title}
                setValue={handleSetValue("title")}
                rules={rules.required}
              />
            </Form>
            <fieldset>
              <legend>
                <Text>applications.infoPage.productsSection</Text>
              </legend>
              <Table shadow>
                {data.products.map((e) => (
                  <Product key={JSON.stringify(e)} {...e} />
                ))}
              </Table>
            </fieldset>
            <button
              type="button"
              className="h4 block bg-primary-light border border-dashed border-primary w-full rounded p-6"
              onClick={toggleProductForm}
            >
              <Icon icon="plus" className="text-primary" />{" "}
              <span className="text-gray-800">
                <Text>applications.infoPage.productsAddButton</Text>
              </span>
            </button>
          </Drawer.Body>
          <Drawer.Footer className="flex items-center justify-between">
            <Button type="button" variant="white" size="sm" onClick={toggle}>
              <Icon icon="close" />{" "}
              <Text>applications.infoPage.productsCancelButton</Text>
            </Button>
            <Button
              type="button"
              variant="primary"
              size="sm"
              loading={loading}
              onClick={submit}
            >
              <Text>applications.infoPage.productsSubmitButton</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Drawer>
      <ProductForm isOpen={isProductFormOpen} toggle={toggleProductForm} />
    </Fragment>
  );
}
