import {useEffect, useState} from "react";
import {Button, Icon, Skeleton, Text, InputGroup, Select} from "components";
import {useConverter, usePermission} from "hooks";
import FullPermissionSalesPrice from "./full-permission-price";
import WithoutPermissionPrice from "./without-permission-price";

type Props = {
  price: any;
  loading: boolean;
  getData: (p?: any) => void;
  purchasePrice?: string;
  showPriceSection?: boolean;
  initialPrice?: string;
  setSalesPriceData?: any;
  simulateFrom?: string;
};

const SalesPrice = ({
  price,
  loading,
  getData,
  purchasePrice,
  showPriceSection,
  initialPrice,
  setSalesPriceData,
  simulateFrom,
}: Props) => {
  const {convertAmount} = useConverter();
  const [data, setData] = useState<any>({
    basePrice: 0,
    salePriceType: 0,
  });
  const [type, SetType] = useState(0);
  const salePriceCalculationPermission = usePermission(
    "PE_GetSalePriceCalculation",
  );
  const salePriceSimulationPermission = usePermission(
    "PE_GetSalePriceSimulation",
  );

  const SalesPriceType = [
    {
      name: "NNet",
      id: 0,
    },
    {
      name: "Vk0",
      id: 1,
    },
    {
      name: "Vk1",
      id: 2,
    },
    {
      name: "Vk2",
      id: 3,
    },
    {
      name: "Vk3",
      id: 4,
    },
  ];

  useEffect(() => {
    if (initialPrice) {
      setData((p: any) => ({...p, basePrice: initialPrice}));
    }
    if (purchasePrice) {
      setData((p: any) => ({...p, basePrice: purchasePrice, salePriceType: 0}));
    }
  }, [initialPrice, purchasePrice]);

  const onChangeHandler = (key: string) => {
    return (value: any) => setData((p: any) => ({...p, [key]: value}));
  };

  const iteratePrice =
    simulateFrom === "pim"
      ? ["vk0", "vk1", "vk2"]
      : ["nNet", "vk0", "vk1", "vk2", "vk3"];

  return (
    <div className="space-y-4 rounded-md border border-dashed border-gray-500 p-4 ">
      <h3 className="mb-5 text-heading-3 text-gray-800">
        <Text>productManagement.products.Details.salesPrice</Text>
      </h3>
      {showPriceSection && (
        <div className="grid grid-cols-2 gap-2">
          <div>
            <InputGroup
              label="productManagement.products.Details.salesPrice"
              value={data?.basePrice}
              setValue={onChangeHandler("basePrice")}
              type="price"
            />
          </div>
          {simulateFrom !== "pim" && (
            <Select
              label="productManagement.products.Details.type"
              placeholder="Select"
              value={type}
              items={SalesPriceType}
              setValue={(value: any) => {
                SetType(value);
                setSalesPriceData &&
                  setSalesPriceData((p: any) => ({...p, salePriceType: value}));
              }}
            />
          )}
          <div className="self-end">
            <Button
              variant="primary"
              light
              onClick={() =>
                getData({
                  basePrice: data?.basePrice,
                  salePriceType: type,
                  pimSimulate: simulateFrom === "pim" ? true : false,
                })
              }
            >
              <Icon icon="arrows-rotate" />
              <span>
                <Text>productManagement.products.Details.recalculate</Text>
              </span>
            </Button>
          </div>
          {/* <Select
            label="priceEngine.simulator.type"
            setValue={onChangeHandler("salePriceType")}
            items={InputSalePriceType}
            value={data?.salePriceType}
          /> */}
        </div>
      )}
      {Object.keys(price)?.length > 0 && (
        <>
          <div>
            <span className="text-base text-gray-800">
              <Text>
                productManagement.products.Details.assignedPriceTemplateChart
              </Text>
              :
            </span>
          </div>
        </>
      )}

      {loading ? (
        [1, 2, 3, 4].map((index: number) => <Skeleton.Input key={index} />)
      ) : (
        <>
          {Object.keys(price)?.length > 0 && (
            <div>
              {salePriceSimulationPermission && (
                <div className="space-y-4 rounded-lg bg-primary-light p-3">
                  <h5 className="text-heading-5 text-gray-700">
                    {price.salePriceTemplateTitle}
                  </h5>
                  <p className="text-sm">
                    <Icon icon="hashtag" size="sm" />{" "}
                    {price.salePriceTemplateCode}
                  </p>
                </div>
              )}
              <div className="h-[400px] overflow-scroll">
                {salePriceSimulationPermission ? (
                  <FullPermissionSalesPrice
                    data={price}
                    type="sales"
                    simulateType={type}
                    simulateFrom={simulateFrom}
                  />
                ) : (
                  salePriceCalculationPermission && (
                    <WithoutPermissionPrice data={price} type="sales" />
                  )
                )}
              </div>
              <div className="border-gray-250 mt-4 flex flex-wrap space-x-32 border-t bg-white p-4">
                <div className="flex-1 space-y-3">
                  {iteratePrice.map(i => (
                    <div className="grid grid-cols-3 items-center gap-2">
                      <p className="text-gray-700" key={i}>
                        ({i}) price:{" "}
                      </p>
                      <span className="ml-4 text-heading-1 font-normal text-gray-800">
                        {convertAmount(price?.[`${i}`])}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SalesPrice;
