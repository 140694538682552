import {useEffect, useState} from "react";
import {useToggleState} from "hooks";
import {ServiceApi, URLS} from "services";
import {calculationLevelEnum} from "constant";
import {InfoSection, LeftSide} from "../common";

const originalFilterData = {
  productId: null,
  categoryId: null,
  keyword: "",
  language: null,
  pageNumber: 1,
  pageSize: 10,
  pimProductFamilyId: null,
  priceFrom: null,
  priceTo: null,
  programId: null,
  searchFields: "",
  sortType: null,
  supplierId: null,
  brandId: null,
  title: null,
  version: null,
};

export default function Product() {
  const [loading, setLoading] = useToggleState();
  const [data, setData] = useState<any>([]);
  const [selectedId, setSelectedId] = useState("");
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [params, setParams] = useState<any>(originalFilterData);

  useEffect(() => {
    Products();
  }, [params]);

  const Products = () => {
    setLoading();
    const url = URLS.POST_SEARCH_LIST_PRODUCTS;
    const config = params;
    ServiceApi.post(url, config)
      .then(({data}) => {
        const clone = data?.searchResult?.map((product: any) => ({
          id: product.id,
          name: product.originalName,
          code: product.articleNumber,
        }));
        setData(clone);
        setLoading();
      })
      .catch(() => setLoading());
  };

  return (
    <div className="flex gap-8">
      <LeftSide
        title="priceEngine.calculationLevel.products"
        loading={loading}
        list={data}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
        setParams={setParams}
        setSelectedItem={setSelectedItem}
      />
      <div className="w-full space-y-4">
        {selectedId && (
          <InfoSection
            refId={selectedId}
            level={calculationLevelEnum.product}
            refTitle={selectedItem?.name}
            refCode={""}
          />
        )}
      </div>
    </div>
  );
}
