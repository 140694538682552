export const suppliers = {
  addSupplier: "Add Supplier",
  supplierName: "Supplier name",
  code: "Code",
  title: "Title",
  text: "Text",
  name: "Name",
  ilnNumber: "ILN (International Location Number)",
  alterNativeIlnNumber: "Alternative ILN",
  iln: "ILN",
  taxId: "Tax Id",
  description: "Description",
  emailAddress: "Email address",
  emailAddress1: "Email address 1",
  emailAddress2: "Email address 2",
  phoneNumber: "Phone number",
  basicInfo: "Basic info",
  generalInfo: "General info",
  contactInfo: "Contact info",
  program: "Program",
  saleSetting: "Sale Setting",
  location: "Location",
  review: "Review",
  configurable: "Configurable",
  manual: "Manual",
  addProgram: "Add",
  editProgram: "Edit Program",
  attribute: "Attribute",
  attributes: "Attributes",
  active: "Active",
  deActive: "Deactive",
  addAttribute: "Add attribute",
  required: "Required",
  generalInfoDesc: "Name, description, ...",
  contactInfoDesc: "Logo, email address, ...",
  reviewDesc: "Finalize information",
  locationDesc: "Supplier location",
  logo: "Logo",
  amount: "Amount",
  discount: "Discount",
  discounts: "Discounts",
  adddiscount: "Add Discount",
  supplierDiscount: "Supplier discount",
  programDiscount: "Program discount",
  customText: "Custom text",
  customTitle: "Custom title",
  dragChangeOrder: "Drag items to change order",
  sortOrder: "Sort order",
  zrNumber: "ZR Number",
  iwofurnCode: "Iwofurn Code",
  settings: "Settings",
  holidays: "Holidays",
  addHoliday: "Add Holiday",
  start: "Start",
  end: "End",
  lastOrder: "Last Order",
  earliestShip: "Earliest Ship",
  startDate: "Start Date",
  endDate: "End Date",
  lastOrderDate: "Last Order Date",
  earliestShipDate: "Earliest Ship Date",
  bankInfo: "Bank Info",
  defaultBankInfo: "Default Bank Info",
  bankName: "Bank Name",
  accountOwnerName: "Account Owner Name",
  iban: "IBAN",
  bic: "BIC",
  shipping: "Shipping",
  defaultMinimumShippingTime: "Default Minimum shipping Time",
  none: "None",
  workDay: "WorkDay",
  calendarDay: "CalendarDay",
  holiday: "Holiday",
  useDefaultMinimumShippingTime: "Use Default Minimum Shipping Time",
  minimumShippingTime: "Minimum shipping Time",
  useDefaultBankInfo: "Use Default Bank Info",
  brand: "Brand",
  select: "Select...",
  contactPerson: "Contact Person",
  addContactPerson: "Add Contact Person",
  firstName: "First Name",
  lastName: "Last Name",
  department: "Department",
  phone: "Phone",
  phone1: "Phone 1",
  phone2: "Phone 2",
  OFF: "OFF",
  minimumResponseTime: "Minimum Response Time",
  programs: "Programs",
  generalTab: "General",
  configurationTab: "Configuration",
  availabilityStatusTab: "Availability Status",
  programType: "Program Type",
  noProgram: "No program added yet",
  canAddProgram: "You can add program with Add Button",
  add: "Add",
  supplierType: "Supplier Type",
  productGroup: "Product Group",
  modelNumber: "Model Number",
  version: "Version",
  salesPersonProvision: "Sales person provision",
  channels: "Channels",
  publish: "Publish",
  unPublish: "unPublish",
  rePublish: "rePublish",
  at: "at",
  thereIsNoChannel: "There is no channel",
  details: "Details",
  availabilityStatus: {
    // list
    defaultStatus: "Default",
    statusDate: "From {{ fromDate }} to {{ toDate }}",
    addStatusButton: "Add",
    // form
    addStatusTitle: "Add status",
    editStatusTitle: "Edit status",
    closeButton: "Close",
    status: "Status",
    addValidationPeriod: "Add validation period",
    fromDate: "From",
    toDate: "To",
    cancelButton: "Cancel",
    addButton: "Add status",
    updateButton: "Update status",
    addSuccessMessage: "Availability status has been created successfully.",
    editSuccessMessage: "Availability status has been edited successfully.",
    // confirm
    removeStatusTitle: "Remove status",
    removeStatusText:
      "This operation cannot be reversed. Are you sure about your request?",
    cancelRemoveButton: "Cancel",
    removeStatusButton: "Remove status",
    removeSuccessMessage: "Availability status has been Removed successfully.",
  },
  lastModifiedAt: "Last Modified At",
  lastModifiedBy: "last Modified By",
};
