import {isEmpty} from "lodash";
import {ComponentProps, ReactNode} from "react";
import {twMerge} from "tailwind-merge";
import Image from "./Image";
import {LazyImage} from "components";
type TableProps = {
  children: ReactNode;
  className?: string;
  shadow?: boolean;
};

type TableImageProps = {
  src: string | null | undefined;
  alt?: string;
  className?: string;
  imageClassName?: string;
  placeholder?: any;
};

function Table({
  children,
  className = "",
  shadow = false,
  ...props
}: TableProps & Omit<ComponentProps<"table">, keyof TableProps>) {
  const hasChildren = !isEmpty(children);
  return (
    <div hidden={!hasChildren} className="w-full overflow-auto">
      <table
        className={twMerge(
          "min-w-full border-separate border-spacing-y-4 whitespace-nowrap text-center [&_td:first-child]:rounded-l-xl [&_td:first-child]:text-left [&_td:last-child]:rounded-r-xl [&_td:last-child]:text-right [&_td]:p-4 [&_tr[draggable].dragging]:opacity-25 [&_tr]:rounded-xl [&_tr]:bg-white",
          className,
          shadow && "[&_tr]:shadow-high",
        )}
        {...props}
      >
        <tbody>{children}</tbody>
      </table>
    </div>
  );
}
function TableImage({
  src = "",
  alt = "",
  className,
  imageClassName = "",
  placeholder = "",
}: TableImageProps) {
  return (
    <div
      className={`${className} flex-center h-24 w-32 overflow-hidden rounded border border-gray-200 bg-gray-100`}
    >
      <LazyImage
        placeholder={placeholder}
        className={`h-full w-full object-cover`}
        src={src}
        alt={alt}
        isDynamic
        imageClassName={"w-full h-full"}
      />
    </div>
  );
}

function TableLogo({src = "", alt = "", className}: TableImageProps) {
  return (
    <div
      className={`${className} flex-center h-20 w-32 overflow-hidden rounded border border-gray-200 bg-gray-200`}
    >
      <LazyImage
        isDynamic
        className="h-full w-full"
        logo={true}
        src={src}
        alt={alt}
      />
    </div>
  );
}
function TableTouchPad() {
  return (
    <button
      type="button"
      className="flex-center inline-flex h-32 cursor-grab rounded-s bg-gray-200 p-2"
    >
      <div className="grid h-fit w-fit grid-cols-2 gap-2">
        {[...Array(6)].fill("").map((e, i) => (
          <div key={i} className="flex-center">
            <span className="h-[2px] w-[2px] rounded-full bg-gray-400" />
          </div>
        ))}
      </div>
    </button>
  );
}
Table.Image = TableImage;
Table.Logo = TableLogo;
Table.TouchPad = TableTouchPad;
export default Table;
