import {Button, Icon, InputGroup, MultiSelect, Text, Wrapper} from "components";
import {rules} from "constant";
import cloneDeep from "lodash/cloneDeep";
import {Fragment, useContext, useEffect, useState} from "react";
import {ServiceApi, URLS} from "services";
import {AddSalesTemplatesContext} from "..";
import Select from "react-select";
import {config} from "constant";

export default function Phase() {
  const {data, setData} = useContext<any>(AddSalesTemplatesContext);
  const [checkListItems, setCheckListItems] = useState<any>([]);
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [params] = useState({
    pageNumber: 1,
    pageSize: 10,
    keyword: "",
  });
  useEffect(() => {
    setCheckListItems([]);
    const url = URLS.GET_CHECK_LIST_URL;
    const config = {params};
    ServiceApi.get(url, config).then(({data}) => {
      const result = data.items.map((e: any) => ({
        id: e.id,
        name: e.label,
      }));
      setCheckListItems(result);
    });
  }, []);

  const handleSetValue = (index: string, key: any) => {
    return (value: any) =>
      setData((p: any) => {
        const data = cloneDeep(p);
        data.sellPhases[index][key] = value;
        return data;
      });
  };
  const removeItem = (index: any) => {
    return () =>
      setData((p: any) => {
        const data = cloneDeep(p);
        data.sellPhases.splice(index, 1);
        return data;
      });
  };
  const addPhase = () => {
    const item = {
      title: "",
      value: "",
      discount: "",
      duration: "",
      sellerCommission: "",
      description: "",
      checkListItemIds: [],
    };

    setData((p: any) => {
      const data = cloneDeep(p);
      data.sellPhases.push(item);
      return data;
    });
  };

  return (
    <Fragment>
      {data.sellPhases?.map((item: any, index: any) => {
        return (
          <Fragment key={index}>
            <div className="flex w-full justify-between">
              <div className="flex-center flex gap-6">
                <h4>
                  <Text>salesManagment.sellOffSales.salesTemplates.phase</Text>
                </h4>
                <Button
                  type="button"
                  size="sm"
                  variant="danger"
                  light
                  onClick={removeItem(index)}
                >
                  <Icon icon={"trash"} />
                </Button>
              </div>
            </div>
            <div className="grid grid-cols-1 items-start gap-6 lg:grid-cols-2">
              <InputGroup
                label="salesManagment.sellOffSales.salesTemplates.phaseName"
                value={item?.title}
                setValue={handleSetValue(index, "title")}
                rules={rules.required}
              />
              <InputGroup
                label="salesManagment.sellOffSales.salesTemplates.discount"
                value={item?.discount}
                setValue={discount => {
                  if (discount >= 0 && discount <= 100) {
                    setData((p: any) => {
                      const data = cloneDeep(p);
                      data.sellPhases[index]["discount"] = discount;
                      return data;
                    });
                  }
                }}
                rules={rules.discount}
                keyfilter="pnum"
                append={
                  <h5 className="border-l border-gray-300 px-4 py-3 text-heading-5 text-gray-400">
                    %
                  </h5>
                }
              />
            </div>
            <div className="grid grid-cols-1 items-start gap-6 lg:grid-cols-2">
              <InputGroup
                type="number"
                label="salesManagment.sellOffSales.salesTemplates.duration"
                value={item?.duration}
                setValue={handleSetValue(index, "duration")}
                rules={rules.required}
                append={
                  <h5 className="border-l border-gray-300 px-4 py-3 text-heading-5 text-gray-400">
                    <Text>salesManagment.sellOffSales.salesTemplates.day</Text>
                  </h5>
                }
              />
              <InputGroup
                label="salesManagment.sellOffSales.salesTemplates.sellerCommission"
                value={item?.sellerCommission}
                setValue={sellerCommission => {
                  if (sellerCommission >= 0 && sellerCommission <= 100) {
                    setData((p: any) => {
                      const data = cloneDeep(p);
                      data.sellPhases[index]["sellerCommission"] =
                        sellerCommission;
                      return data;
                    });
                  }
                }}
                rules={rules.discount}
                keyfilter="pnum"
                append={
                  <h5 className="border-l border-gray-300 px-4 py-3 text-heading-5 text-gray-400">
                    %
                  </h5>
                }
              />
            </div>
            <InputGroup
              as="short-textarea"
              label="salesManagment.sellOffSales.salesTemplates.shortDescription"
              value={item?.description}
              setValue={handleSetValue(index, "description")}
            />
            {index > 0 && (
              <>
                <MultiSelect
                  label="salesManagment.sellOffSales.salesTemplates.checkListItems"
                  items={checkListItems}
                  value={item?.checkListItemIds}
                  setValue={value => {
                    setData((p: any) => {
                      const data = cloneDeep(p);
                      const ids = value ? value?.map((e: any) => e.id) : [];
                      data.sellPhases[index]["checkListItemIds"] = ids;
                      return data;
                    });
                  }}
                />
              </>
            )}
          </Fragment>
        );
      })}
      <Wrapper.Section className="courser-pointer mb-4 h-[52px] w-full cursor-pointer border-primary bg-primary-light py-[17px] text-center">
        <div
          className="w-full"
          onClick={() => {
            addPhase();
          }}
        >
          <h4 className="text-gray-800">
            <Icon className="mr-2 text-primary" icon={"plus"} />
            <Text>salesManagment.sellOffSales.salesTemplates.addPhase</Text>
          </h4>
        </div>
      </Wrapper.Section>
    </Fragment>
  );
}
