import {useEffect, useState} from "react";
import {useToggleState} from "hooks";
import {ServiceApi, URLS} from "services";
import {config, calculationLevelEnum} from "constant";
import {InfoSection, LeftSide} from "../common";
import {Button, Icon, Wrapper} from "components";

export default function Program() {
  const [loading, setLoading] = useToggleState();
  const [suppliers, setSuppliers] = useState<any>([]);
  const [programs, setPrograms] = useState<any>([]);
  const [selectedId, setSelectedId] = useState("");
  const [selectedProgramId, setSelectedProgramId] = useState("");
  const [selectedSupplier, setSelectedSupplier] = useState<any>({});
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [params, setParams] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
    suppliertype: 0,
  });
  const [paramsProgram, setParamsProgram] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  });

  useEffect(() => {
    if (!selectedId) getSuppliers();
  }, [params]);
  useEffect(() => {
    if (selectedId) getSuppliers();
  }, [paramsProgram]);

  const getSuppliers = () => {
    setLoading();
    const url = URLS.GET_SUPPLIERS;
    const config = {params};
    ServiceApi.get(url, config)
      .then(({data}) => {
        const clone = data?.items?.map((supplier: any) => ({
          id: supplier.supplierId,
          name: supplier.name,
          code: supplier.code,
        }));
        setSuppliers(clone);
        setLoading();
      })
      .catch(() => setLoading());
  };

  const getPrograms = () => {
    setLoading();
    const url = URLS.GET_SUPPLIER_PROGRAMS(selectedId);
    const config = {params: paramsProgram};
    ServiceApi.get(url, config)
      .then(({data}) => {
        const clone = data?.map((program: any) => ({
          id: program.id,
          name: program.title,
          code: program.code,
        }));
        setPrograms(clone);
        setLoading();
      })
      .catch(() => setLoading());
  };

  useEffect(() => {
    if (selectedId) {
      getPrograms();
    }
  }, [selectedId]);

  const returnSection = () => {
    return (
      <Wrapper className="shadow-card">
        <Wrapper.Body className="flex items-center justify-between !p-4">
          <span className="text-body-base font-semibold text-gray-800">
            {selectedSupplier.name}
          </span>
          <Button light size="sm" onClick={() => setSelectedId("")}>
            <Icon icon="arrows-retweet" />
          </Button>
        </Wrapper.Body>
      </Wrapper>
    );
  };

  return (
    <div className="flex gap-8">
      {selectedId ? (
        <LeftSide
          title="priceEngine.calculationLevel.program"
          loading={loading}
          list={programs}
          selectedId={selectedProgramId}
          setSelectedId={setSelectedProgramId}
          setParams={setParamsProgram}
          params={paramsProgram}
          setSelectedItem={setSelectedItem}
          returnSection={returnSection}
        />
      ) : (
        <LeftSide
          title="priceEngine.calculationLevel.suppliers"
          loading={loading}
          list={suppliers}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          setParams={setParams}
          params={params}
          setSelectedItem={setSelectedSupplier}
        />
      )}

      <div className="w-full space-y-4">
        {selectedProgramId && (
          <InfoSection
            refId={selectedProgramId}
            level={calculationLevelEnum.program}
            refCode={selectedItem?.code}
            refTitle={selectedItem?.name}
          />
        )}
      </div>
    </div>
  );
}
