import CountryList from "./countries";

const AddressConfiguration = [
  {
    path: "countries",
    children: [...CountryList],
  },
];

export default AddressConfiguration;
