import {Skeleton, Status, Text, Wrapper} from "components";
import {Fragment, useContext} from "react";
import {CustomerContext} from "..";

export default function CommunicationChannels() {
  const {userData, loading} = useContext<any>(CustomerContext);

  return (
    <div>
      <Wrapper>
        <Wrapper.Header>
          <h1 className="text-heading-2 font-semibold">
            <Text>customers.customerList.channels</Text>
          </h1>
        </Wrapper.Header>
        <Wrapper.Body className="grid grid-cols-1 gap-8 lg:grid-cols-2">
          {loading ? (
            <Fragment>
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Fragment>
          ) : (
            <Fragment>
              <div className="flex items-center justify-between rounded-xl p-4 shadow-[0px_4px_20px_0px_#38476D17]">
                <div>
                  <span className="text-body-base font-medium text-gray-800">
                    <Text>customers.customerList.email</Text>
                  </span>
                </div>
                <div>
                  <Status.Active id={userData?.communicationChannel?.email} />
                </div>
              </div>
              <div className="flex items-center justify-between rounded-xl p-4 shadow-[0px_4px_20px_0px_#38476D17]">
                <div>
                  <span className="text-body-base font-medium text-gray-800">
                    <Text>customers.customerList.application</Text>
                  </span>
                </div>
                <div>
                  <Status.Active
                    id={userData?.communicationChannel?.application}
                  />
                </div>
              </div>
              <div className="flex items-center justify-between rounded-xl p-4 shadow-[0px_4px_20px_0px_#38476D17]">
                <div>
                  <span className="text-body-base font-medium text-gray-800">
                    <Text>customers.customerList.sms</Text>
                  </span>
                </div>
                <div>
                  <Status.Active id={userData?.communicationChannel?.sms} />
                </div>
              </div>
              <div className="flex items-center justify-between rounded-xl p-4 shadow-[0px_4px_20px_0px_#38476D17]">
                <div>
                  <span className="text-body-base font-medium text-gray-800">
                    <Text>customers.customerList.phone</Text>
                  </span>
                </div>
                <div>
                  <Status.Active id={userData?.communicationChannel?.phone} />
                </div>
              </div>
              <div className="flex items-center justify-between rounded-xl p-4 shadow-[0px_4px_20px_0px_#38476D17]">
                <div>
                  <span className="text-body-base font-medium text-gray-800">
                    <Text>customers.customerList.post</Text>
                  </span>
                </div>
                <div>
                  <Status.Active id={userData?.communicationChannel?.post} />
                </div>
              </div>
              <div className="flex items-center justify-between rounded-xl p-4 shadow-[0px_4px_20px_0px_#38476D17]">
                <div>
                  <span className="text-body-base font-medium text-gray-800">
                    <Text>customers.customerList.socialMedia</Text>
                  </span>
                </div>
                <div>
                  <Status.Active
                    id={userData?.communicationChannel?.socialMedia}
                  />
                </div>
              </div>
              <div className="flex items-center justify-between rounded-xl p-4 shadow-[0px_4px_20px_0px_#38476D17]">
                <div>
                  <span className="text-body-base font-medium text-gray-800">
                    <Text>customers.customerList.newsLetterSubscription</Text>
                  </span>
                </div>
                <div>
                  <Status.Active
                    id={userData?.communicationChannel?.newsletter}
                  />
                </div>
              </div>
            </Fragment>
          )}
        </Wrapper.Body>
      </Wrapper>
    </div>
  );
}
