import ShippingList from "pages/product-managment/master-data/shipping-methods";

const Shipping = [
  {
    path: "",
    element: <ShippingList />,
  },
];

export default Shipping;
