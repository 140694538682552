import {useContext} from "react";
import {Wrapper, Text, Skeleton, InputGroup, Icon} from "components";
import {LegalEntityContext} from "./details";
import {usePermission} from "hooks";

export default function GeneralTab() {
  const updatePermission = usePermission("AS_UpdateLegalEntity");
  const {loadingPage, data, setData, clipboard} =
    useContext<any>(LegalEntityContext);

  return (
    <Wrapper>
      <Wrapper.Header>
        <div className="flex">
          <div className="mr-auto">
            <h2 className="text-heading-2 font-semibold text-gray-800">
              <Text>company.legalEntities.generalInfo</Text>
            </h2>
          </div>
        </div>
      </Wrapper.Header>
      <Wrapper.Body className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-8">
        <div className="col-start-1 col-end-2">
          {loadingPage ? (
            <Skeleton.Input />
          ) : (
            <InputGroup
              label="company.legalEntities.leganName"
              value={data.name}
              setValue={name => setData((p: any) => ({...p, name}))}
              disabled={!updatePermission}
            />
          )}
        </div>
        <div className="col-start-1 col-end-2">
          {loadingPage ? (
            <Skeleton.Input />
          ) : (
            <InputGroup
              disabled
              label="company.legalEntities.code"
              value={data.code}
              append={
                <span
                  className="input-group-text"
                  onClick={() => {
                    clipboard();
                  }}
                >
                  <Icon
                    className="ml-6 cursor-pointer text-primary"
                    icon={"files"}
                  />
                </span>
              }
            />
          )}
        </div>
        <div className="col-start-1 col-end-2">
          {loadingPage ? (
            <Skeleton.Input />
          ) : (
            <InputGroup
              as="short-textarea"
              label="company.legalEntities.description"
              value={data.description}
              setValue={description =>
                setData((p: any) => ({...p, description}))
              }
              disabled={!updatePermission}
            />
          )}
        </div>
      </Wrapper.Body>
    </Wrapper>
  );
}
