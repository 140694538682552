import CommissionReports from "pages/sales-managment/commission-reports";

const CommissionReportsRoute = [
  {
    path: "",
    element: <CommissionReports />,
  },
];

export default CommissionReportsRoute;
