import { NotificationManager } from "common/notifications";
import { useDispatch } from "react-redux";
import {
  Button,
  Icon,
  Skeleton,
  Text,
  Toggler,
  Wrapper,
  HandleName,
} from "components";
import { useDataState, useSelector, useToggleState } from "hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ServiceApi, URLS } from "services";
import ChannelDrawer from "../drawers/channel-drawer";
import { setproductCategories } from "store/productCategories";

const Channels = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const productCategory = useSelector((s) => s.productCategories);
  const companySetting = useSelector((s) => s.companySetting);
  const [channels, setChannels] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [selectedChannel, setSelectedChannel] = useState<any>({});
  const [toggles, setToggles] = useState<any>([]);
  const [nonPublishedChannels, setNonPublishedChannels] = useState<any>([]);

  const [loading, setLoading] = useToggleState();
  const [publishLoading, setPublishLoading] = useToggleState();
  const [isOpen, toggle] = useToggleState();
  const [
    publishChannelExceptions,
    setPublishChannelExceptions,
    setBaseData,
    isChanged,
  ] = useDataState<any>([]);

  useEffect(() => {
    if (productCategory?.publishChannelExceptions?.length > 0) {
      setBaseData(productCategory?.publishChannelExceptions);
    }
    let clone = companySetting?.channels?.map((channel: any) => {
      return productCategory?.publishChannelExceptions?.includes(channel.code)
        ? false
        : true;
    });
    setToggles(clone);
  }, [productCategory]);

  const getChannelsInfo = (chanel = channels) => {
    const url = URLS.GET_CATEGORY_BY_ID(productCategory.productCategoryId);
    ServiceApi.get(url).then(({ data }) => {
      setData(data);
      setNonPublishedChannels(data.nonPublishableChannels);
      let clone = chanel?.map((channel: any) => {
        return data?.nonPublishableChannels.includes(channel.code)
          ? false
          : true;
      });
      setToggles(clone);
    });
  };

  useEffect(() => {
    setChannels(companySetting?.channels);
  }, [productCategory]);

  const onSubmit = () => {
    setLoading();
    const url = URLS.MANAGE_CHANNEL_EXEPTION(productCategory.productCategoryId);
    ServiceApi.put(url, publishChannelExceptions)
      .then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title"
        );
        dispatch(
          setproductCategories({ ...productCategory, publishChannelExceptions })
        );
        setLoading();
      })
      .catch(() => setLoading());
  };

  const getInfo = () => {
    const info = data?.availableChannels?.filter(
      (channel: any) => channel.channelCode === selectedChannel.code
    )[0];
    return info;
  };

  const info = getInfo();

  return (
    <>
      <div className="grid grid-cols-3 gap-6">
        <div className="space-y-4 col-span-2">
          {loading
            ? [1, 2, 3, 4, 5].map((l) => <Skeleton.List key={l} />)
            : channels.map((channel: any, index: number) => {
                return (
                  <Wrapper>
                    <Wrapper.Body className="!p-4">
                      <div className="flex justify-between items-center">
                        <span className="text-heading-6 font-semibold">
                          {
                            <HandleName
                              translations={channel?.channelNameTranslates}
                              code={channel?.code}
                              keyName="name"
                            />
                          }
                        </span>
                        <div className="flex items-center gap-8">
                          <span className="text-[15px] text-gray-800">
                            <Text>
                              productManagement.masterData.productCategory.showInThisChannel
                            </Text>
                          </span>
                          <div>
                            <Toggler
                              value={toggles?.[index]}
                              size="sm"
                              setValue={(e) => {
                                let clone = [...toggles];
                                clone[index] = e;
                                let exclude = [...publishChannelExceptions];
                                if (
                                  publishChannelExceptions.includes(
                                    channels[index]?.code
                                  )
                                ) {
                                  exclude = exclude.filter((ch) => {
                                    return ch !== channels[index]?.code;
                                  });
                                } else {
                                  exclude.push(channels[index]?.code);
                                }
                                setPublishChannelExceptions(exclude);
                                setToggles(clone);
                              }}
                            />
                          </div>
                          <Button
                            size="sm"
                            variant={"light"}
                            disabled={publishLoading}
                            onClick={() => {
                              setSelectedChannel(channel);
                              toggle();
                            }}
                          >
                            <Icon icon="sliders" className="text-gray-600" />
                          </Button>
                        </div>
                      </div>
                    </Wrapper.Body>
                  </Wrapper>
                );
              })}
        </div>
        <div className="bg-white rounded-xl px-8 py-6">
          <Icon icon="lightbulb" className="text-warning-active mr-2" />
          <span className="text-warning-active font-semibold text-body-base">
            <Text>productManagement.masterData.productCategory.note</Text>
          </span>
          <p className="mt-6 !text-gray-600 text-body-2 font-medium leading-6">
            <Text>productManagement.masterData.productCategory.noteText</Text>
          </p>
        </div>
      </div>
      <Wrapper className="sticky bottom-0 shadow-card">
        <Wrapper.Body className="flex items-center justify-end gap-4">
          <Button
            type="button"
            variant="primary"
            onClick={onSubmit}
            loading={loading}
            disabled={!isChanged}
          >
            <Text>global.buttons.saveChanges</Text>
          </Button>
        </Wrapper.Body>
      </Wrapper>
      <ChannelDrawer
        isOpen={isOpen}
        toggle={toggle}
        channelInfo={info}
        id={productCategory.productCategoryId}
        selectedChanel={selectedChannel}
        getChannelsInfo={getChannelsInfo}
      />
    </>
  );
};

export default Channels;
