import i18n from "langs/i18n";

export default function convertPrice(value: string): string {
  if (!value) return "";
  const locale = i18n.language;
  const isDe = locale === "de";
  const [intValue = "", decimalValue = null] = value.split(".");
  const int = Number(intValue).toLocaleString(locale);
  const decimal = decimalValue?.slice(0, 2);
  const hasDecimal = decimalValue !== null;
  if (!hasDecimal) return int;
  return [int, decimal].join(isDe ? "," : ".");
}
