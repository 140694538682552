import {NotificationManager} from "common/notifications";
import {
  Button,
  Drawer,
  Dropdown,
  Form,
  Icon,
  InputGroup,
  RadioButton,
  Text,
  Toggler,
  Wrapper,
} from "components";
import {config, rules} from "constant";
import cloneDeep from "lodash/cloneDeep";
import {FormEvent, useEffect, useRef, useState} from "react";
import AsyncSelect from "react-select/async";
import {ServiceApi, URLS} from "services";

type createModalType = {
  isOpen: boolean;
  supplierCode: string;
  supplierData: any;
  toggle: (value?: boolean) => void;
  getData: () => void;
};

export default function Attribute(props: createModalType) {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<any>();
  const [selectedItem, setSelectedItem] = useState<any>("");
  const [selectedOptionsBrand, setSelectedOptionsBrand] = useState<any>();
  const [selectedOptionsGroup, setSelectedOptionsGroup] = useState<any>([]);

  const [items] = useState([
    {
      name: "workDay",
      id: 1,
    },
    {
      name: "calendarDay",
      id: 2,
    },
  ]);
  const [data, setData] = useState({
    title: "",
    shortDescription: "",
    modelNumber: "",
    zrNumber: "",
    isActive: true,
    programType: 2,
    brandId: "",
    pimProductGroupId: "",
    iwofurnCode: "",
    supplierId: props.supplierData.supplierId,
    hasShippingInfo: true,
    shipping: {minimumShippingDuration: null, durationUnit: null},
    hasBankInfo: true,
    bankInfo: {
      bankName: null,
      iban: null,
      accountOwnerName: null,
      bic: null,
    },
  });

  useEffect(() => {
    if (props.isOpen) {
      setData({
        title: "",
        shortDescription: "",
        modelNumber: "",
        zrNumber: "",
        brandId: "",
        pimProductGroupId: "",
        iwofurnCode: "",
        isActive: true,
        programType: 2,
        supplierId: props.supplierData.supplierId,
        hasShippingInfo: props?.supplierData?.shipping ? true : false,
        shipping: {minimumShippingDuration: null, durationUnit: null},
        hasBankInfo: props?.supplierData?.bankInfo ? true : false,
        bankInfo: {
          bankName: null,
          iban: null,
          accountOwnerName: null,
          bic: null,
        },
      });
      getBrands(data?.brandId);
      getProductGroup();
    }
  }, [props.isOpen]);

  const getBrands = (brandId: any) => {
    if (brandId) {
      const urlBrand = URLS.GET_BRAND_DETAILS_URL(brandId);
      ServiceApi.get(urlBrand).then(({data}) => {
        setSelectedOptionsBrand({...data, label: data.name, value: data.id});
      });
    } else {
      setSelectedOptionsBrand(null);
    }
  };

  const onChangeHandlerBrand = (selected: any) => {
    if (!selected) {
      setSelectedOptionsBrand(null);
    } else {
      setSelectedOptionsBrand(selected);
    }
    setData((p: any) => ({
      ...p,
      brandId: selected?.value,
    }));
  };

  const handleSearchBrands = async (filter: string) => {
    const url = URLS.GET_SELL_OFF_BRANDS;
    const params = {
      pageNumber: 1,
      pageSize: 1000,
      keyword: filter,
    };
    return ServiceApi.get(url, {params}).then(({data}) => {
      const brands = data.items.map((brand: any) => ({
        value: brand.brandId,
        label: brand.name,
      }));
      return brands;
    });
  };

  const promiseOptionsBrand = (inputValue: string) =>
    new Promise<any[]>(resolve => {
      setTimeout(() => {
        resolve(handleSearchBrands(inputValue));
      }, 200);
    });

  const onSubmitForm = (formData: any) => {
    setLoading(true);
    const url = URLS.CREATE_NEW_PROGRAM;
    if (formData?.hasShippingInfo) {
      formData.shipping = {
        durationUnit: props?.supplierData?.shipping?.durationUnit,
        minimumShippingDuration:
          props?.supplierData?.shipping?.minimumShippingDuration,
      };
    } else {
      if (
        formData?.shipping?.minimumShippingDuration === null ||
        formData?.shipping?.minimumShippingDuration === ""
      ) {
        formData.shipping = {durationUnit: formData?.shipping?.durationUnit};
      }
      if (formData?.shipping?.durationUnit === null) {
        formData.shipping = {
          minimumShippingDuration: formData?.shipping?.minimumShippingDuration,
        };
      }
    }
    if (formData.hasBankInfo) {
      formData.bankInfo = {
        bankName: props?.supplierData?.bankInfo?.bankName,
        iban: props?.supplierData?.bankInfo?.iban,
        accountOwnerName: props?.supplierData?.bankInfo?.accountOwnerName,
        bic: props?.supplierData?.bankInfo?.bic,
      };
    }
    ServiceApi.post(url, formData)
      .then(({}) => {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title",
        );
        props.toggle(false);
        props.getData();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submit = (e?: FormEvent) => {
    formRef.current.submitHandler(e, data);
  };

  const handleSetValue = (key: any) => {
    if (!data.hasShippingInfo) {
      return (value: any) =>
        setData((p: any) => {
          const data = cloneDeep(p);
          if (data.shipping !== null) {
            data.shipping[key] = value;
          } else {
            data.shipping = {minimumShippingDuration: null, durationUnit: null};
            data.shipping[key] = value;
          }
          return data;
        });
    }
  };

  const handleSetValueBank = (key: any) => {
    if (!data?.hasBankInfo) {
      return (value: any) =>
        setData((p: any) => {
          const data = cloneDeep(p);
          if (data.bankInfo !== null) {
            data.bankInfo[key] = value;
          } else {
            data.bankInfo = {
              bankName: null,
              iban: null,
              accountOwnerName: null,
              bic: null,
            };
            data.bankInfo[key] = value;
          }
          return data;
        });
    }
  };

  const shippingType = (e: any) => {
    switch (e) {
      case 1:
        return "WorkDay";
      case 2:
        return "CalendarDay";
    }
  };

  const selectedShippingDayType = (selected: any) => {
    return (
      items?.find((item: any) => item.id === selected)?.name || items[0].name
    );
  };

  const onChangeHandler = (e: any) => {
    if (!data.hasShippingInfo) {
      setData((p: any) => {
        const data = cloneDeep(p);
        if (data.shipping !== null) {
          data.shipping.durationUnit = e;
        } else {
          data.shipping = {minimumShippingDuration: null, durationUnit: null};
          data.shipping.durationUnit = e;
        }
        return data;
      });
    }
  };

  const handleSearchGroups = async (filter: string) => {
    const url = URLS.GET_PIM_PRODUCT_GROUPS;
    const params = {
      pageNumber: 1,
      pageSize: 100,
      keyword: filter,
    };

    return ServiceApi.get(url, {params}).then(({data}) => {
      const groups = data.items.map((group: any) => ({
        ...group,
        id: group.id,
        label: group.name,
        value: group.code,
        code: group.code,
      }));
      return groups;
    });
  };

  const onProductGroupChange = (event: any) => {
    setSelectedOptionsGroup(event);
    setData((p: any) => ({
      ...p,
      pimProductGroupId: event?.id,
    }));
  };

  const promiseGroupOptions = (inputValue: string) =>
    new Promise<any[]>(resolve => {
      setTimeout(() => {
        resolve(handleSearchGroups(inputValue));
      }, 200);
    });

  const getProductGroup = () => {
    if (data?.pimProductGroupId) {
      const url = URLS.GET_PIM_PRODUCT_GROUPS_DETAILS(data?.pimProductGroupId);
      ServiceApi.get(url).then(({data}) => {
        setSelectedOptionsGroup({
          ...data,
          label: data.name,
          value: data.code,
        });
      });
    }
  };

  return (
    <Drawer isOpen={props.isOpen} toggle={props.toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pr-4 pt-6">
          <div className="flex items-center justify-between">
            <h2 className="text-heading-2 text-gray-800">
              <Text>productManagement.masterData.suppliers.addProgram</Text>
            </h2>
            <Button
              type="button"
              variant="light"
              size="sm"
              className="ml-2"
              onClick={() => props.toggle()}
            >
              <Icon className="mr-2" icon={"times"} />
              <Text>global.buttons.close</Text>
            </Button>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-4 overflow-auto pr-4">
          <Form
            ref={formRef}
            onSubmit={onSubmitForm}
            id={"create-modal"}
            className="space-y-4 px-2 pt-4"
          >
            <InputGroup
              label="productManagement.masterData.suppliers.title"
              value={data.title}
              className="border-white"
              setValue={title => setData(p => ({...p, title}))}
              rules={rules.required}
            />
            <Toggler
              label="productManagement.masterData.suppliers.active"
              value={data.isActive}
              setValue={isActive => setData(p => ({...p, isActive}))}
            />
            <InputGroup
              label="productManagement.masterData.suppliers.modelNumber"
              value={data.modelNumber}
              setValue={modelNumber => setData(p => ({...p, modelNumber}))}
              rules={rules.required}
            />
            <InputGroup
              label="productManagement.masterData.suppliers.zrNumber"
              value={data.zrNumber}
              setValue={zrNumber => setData(p => ({...p, zrNumber}))}
            />
            <InputGroup
              label="productManagement.masterData.suppliers.iwofurnCode"
              value={data.iwofurnCode}
              setValue={iwofurnCode => setData(p => ({...p, iwofurnCode}))}
            />
            <label
              data-lang-map={"productManagement.masterData.suppliers.brand"}
              className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
            >
              <Text>{"productManagement.masterData.suppliers.brand"}</Text>
            </label>
            <AsyncSelect
              value={selectedOptionsBrand}
              cacheOptions
              loadOptions={promiseOptionsBrand}
              defaultOptions
              styles={config.styleSelectReact}
              onChange={onChangeHandlerBrand}
              isClearable
            />
            <div>
              <label
                data-lang-map={
                  "productManagement.masterData.suppliers.productGroup"
                }
                className="h6 mb-3 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
              >
                <Text>
                  {"productManagement.masterData.suppliers.productGroup"}
                </Text>
              </label>
              <AsyncSelect
                value={selectedOptionsGroup}
                cacheOptions
                loadOptions={promiseGroupOptions}
                defaultOptions
                onChange={onProductGroupChange}
                styles={config.styleSelectReact}
                isClearable
              />
            </div>
            <InputGroup
              as="short-textarea"
              label="productManagement.masterData.suppliers.description"
              value={data.shortDescription}
              setValue={shortDescription =>
                setData(p => ({...p, shortDescription}))
              }
            />
            <div className="flex space-x-2">
              <RadioButton
                label="productManagement.masterData.suppliers.configurable"
                value={data.programType === 2 ? true : false}
                setValue={() =>
                  setData((p: any) => ({...p, ["programType"]: 2}))
                }
              />
              <RadioButton
                label="productManagement.masterData.suppliers.manual"
                value={data.programType === 1 ? true : false}
                setValue={() =>
                  setData((p: any) => ({...p, ["programType"]: 1}))
                }
              />
            </div>
            <Toggler
              label={
                "productManagement.masterData.suppliers.useDefaultMinimumShippingTime"
              }
              value={data.hasShippingInfo}
              disabled={props?.supplierData?.shipping ? false : true}
              setValue={hasShippingInfo => {
                setData((p: any) => ({...p, hasShippingInfo}));
                if (hasShippingInfo) {
                  setData((p: any) => {
                    const data = cloneDeep(p);
                    data.shipping = null;
                    return data;
                  });
                } else {
                  setData((p: any) => {
                    const data = cloneDeep(p);
                    data.shipping = {
                      minimumShippingDuration: null,
                      durationUnit: null,
                    };
                    return data;
                  });
                }
              }}
            />
            {data.hasShippingInfo && props?.supplierData?.shipping ? (
              <Wrapper.Section className="grid grid-cols-3 items-start gap-4 text-gray-800 [&>div:nth-child(even)]:col-span-2 [&>div:nth-child(even)]:text-body-base [&>div:nth-child(odd)]:col-span-3 [&>div:nth-child(odd)]:text-heading-6 [&>div:nth-child(odd)]:font-medium">
                <div className="flex items-center">
                  <span>
                    {props?.supplierData?.shipping?.minimumShippingDuration}
                  </span>
                  <span className="ml-2">
                    {shippingType(props?.supplierData?.shipping?.durationUnit)}
                  </span>
                </div>
              </Wrapper.Section>
            ) : (
              <div>
                <div className="mb-2 flex items-center justify-between space-x-4">
                  <h6 className="h6 block truncate font-normal text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']">
                    <Text>
                      productManagement.masterData.suppliers.defaultMinimumShippingTime
                    </Text>
                  </h6>
                </div>
                <div className="mt-4" style={{display: "flex"}}>
                  <InputGroup
                    type="number"
                    value={data?.shipping?.minimumShippingDuration}
                    setValue={handleSetValue("minimumShippingDuration")}
                    className="rounded-r-none"
                  />
                  <Dropdown>
                    <Dropdown.Toggle
                      as="button"
                      type="button"
                      className="flex h-full items-center rounded-md rounded-l-none bg-primary-light p-3 align-middle text-body-base font-medium text-primary"
                    >
                      <Text>{`global.dayTypes.${selectedShippingDayType(data?.shipping?.durationUnit)}`}</Text>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="mt-2 min-w-fit p-2">
                      <ul className="w-[104px] text-gray-700">
                        {items?.map((e: any) => (
                          <li
                            key={e.id}
                            onClick={() => {
                              setSelectedItem(e.id);
                              onChangeHandler(e.id);
                            }}
                            className={`${
                              e.id === selectedItem &&
                              "bg-primary-light text-primary "
                            } h6 cursor-pointer rounded-lg px-4 py-2  text-body-base font-medium first:mb-1 hover:bg-primary-light hover:text-primary`}
                          >
                            <Text>{`global.dayTypes.${e?.name}`}</Text>
                          </li>
                        ))}
                      </ul>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            )}
            <Toggler
              label={
                "productManagement.masterData.suppliers.useDefaultBankInfo"
              }
              value={data?.hasBankInfo}
              disabled={props?.supplierData?.bankInfo ? false : true}
              setValue={hasBankInfo => {
                setData((p: any) => ({...p, hasBankInfo}));
                if (hasBankInfo) {
                  setData((p: any) => {
                    const data = cloneDeep(p);
                    data.bankInfo = null;
                    return data;
                  });
                } else {
                  setData((p: any) => {
                    const data = cloneDeep(p);
                    data.bankInfo = {
                      bankName: null,
                      iban: null,
                      accountOwnerName: null,
                      bic: null,
                    };
                    return data;
                  });
                }
              }}
            />
            {data?.hasBankInfo && props?.supplierData?.bankInfo ? (
              <Wrapper.Section className="grid grid-cols-3 items-start gap-4 text-gray-800 [&>div:nth-child(even)]:col-span-2 [&>div:nth-child(even)]:text-body-base [&>div:nth-child(odd)]:col-span-3 [&>div:nth-child(odd)]:text-heading-6 [&>div:nth-child(odd)]:font-medium">
                <div className="space-y-2">
                  <div>
                    <span className="font-normal text-gray-800">
                      <Text>
                        productManagement.masterData.suppliers.bankName
                      </Text>
                      :
                    </span>
                    <span className="ml-2 text-body-base text-gray-700">
                      {props?.supplierData?.bankInfo?.bankName}
                    </span>
                  </div>
                  <div>
                    <span className="font-normal text-gray-800">
                      <Text>
                        productManagement.masterData.suppliers.accountOwnerName
                      </Text>
                      :
                    </span>
                    <span className="ml-2 text-body-base text-gray-700">
                      {props?.supplierData?.bankInfo?.accountOwnerName}
                    </span>
                  </div>
                  <div>
                    <span className="font-normal text-gray-800">
                      <Text>productManagement.masterData.suppliers.iban</Text>:
                    </span>
                    <span className="ml-2 text-body-base text-gray-700">
                      {props?.supplierData?.bankInfo?.iban}
                    </span>
                  </div>
                  <div>
                    <span className="font-normal text-gray-800">
                      <Text>productManagement.masterData.suppliers.bic</Text>:
                    </span>
                    <span className="ml-2 text-body-base text-gray-700">
                      {props?.supplierData?.bankInfo?.bic}
                    </span>
                  </div>
                </div>
              </Wrapper.Section>
            ) : (
              <>
                <InputGroup
                  label="productManagement.masterData.suppliers.bankName"
                  value={data?.bankInfo?.bankName}
                  setValue={handleSetValueBank("bankName")}
                />
                <InputGroup
                  label="productManagement.masterData.suppliers.accountOwnerName"
                  value={data?.bankInfo?.accountOwnerName}
                  setValue={handleSetValueBank("accountOwnerName")}
                />
                <InputGroup
                  label="productManagement.masterData.suppliers.iban"
                  value={data?.bankInfo?.iban}
                  setValue={handleSetValueBank("iban")}
                />
                <InputGroup
                  label="productManagement.masterData.suppliers.bic"
                  value={data?.bankInfo?.bic}
                  setValue={handleSetValueBank("bic")}
                />
              </>
            )}
          </Form>
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-between">
          <Button type="button" onClick={props.toggle} variant="white">
            <Text>global.buttons.discard</Text>
          </Button>
          <Button type="button" onClick={submit} loading={loading}>
            <Text>global.buttons.submit</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
