import Suppliers from "./suppliers";
import Brand from "./brand";
import Attributes from "./attributes";
import AttributesGroups from "./attributes-groups";
import ProductFamiliesRoutes from "./product-families";
import MeasurementUnits from "./measurement-units";
import ProductCategoryRoutes from "./product-category";
import ProductGroupRoute from "./product-group";
import Shipping from "./shipping-methods";
import ProductLabelTemplates from "./product-label-templates";

const MasterData = [
  {
    path: "attributes",
    children: [...Attributes],
  },
  {
    path: "attribute-groupes",
    children: [...AttributesGroups],
  },
  {
    path: "product-families",
    children: [...ProductFamiliesRoutes],
  },
  {
    path: "product-categories",
    children: [...ProductCategoryRoutes],
  },
  {
    path: "measurement-units",
    children: [...MeasurementUnits],
  },
  {
    path: "suppliers",
    children: [...Suppliers],
  },
  {
    path: "brands",
    children: [...Brand],
  },
  {
    path: "brands",
    children: [...Brand],
  },
  {
    path: "shipping",
    children: [...Shipping],
  },
  {
    path: "product-group",
    children: [...ProductGroupRoute],
  },
  {
    path: "product-label-templates",
    children: [...ProductLabelTemplates],
  },
];

export default MasterData;
