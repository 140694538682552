import { Button, Icon, Text, Toggler, Wrapper, DeleteModal } from "components";
import { positionType } from "..";
import { Dispatch, SetStateAction, createContext, useState } from "react";
import AddComponent from "./AddComponent";
import { useToggleState, usePermission } from "hooks";
import Component from "./Component";
import { ServiceApi } from "services";
import { toast } from "react-hot-toast";
import PositionForm from "../PositionForm";
import { NotificationManager } from "common/notifications";

type positionContextType = {
  positionData: positionType;
  setPositionData: Dispatch<SetStateAction<positionType>>;
  isBanner: boolean;
  isCarousel: boolean;
};
type positionProps = {
  position: positionType;
  onRemoved: (id: string) => void;
};

export const PositionContext = createContext({} as positionContextType);

export default function Position({ position, onRemoved }: positionProps) {
  const removePermission = usePermission("PS_DeletePosition");
  const updatePermission = usePermission("PS_UpdatePosition");
  const [isAddComponentOpen, toggleAddComponent] = useToggleState(false);
  const [isEditPositionOpen, toggleEditPosition] = useToggleState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [data, setData] = useState(position);
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState(false);
  const [selected, setSelected] = useState<any>();
  const isBanner = data.componentType === 1;
  const isCarousel = data.componentType === 0;
  const togglePositionStatus = () => {
    setStatusLoading(true);
    const url = `/productservice/api/positions/${data.id}`;
    const body = { ...data };
    body.isActive = !body.isActive;
    ServiceApi.put(url, body)
      .then(() => {
        toast.success("applications.positions.positionEditToast");
        setData(p => ({ ...p, isActive: !p.isActive }));
      })
      .finally(() => {
        setStatusLoading(false);
      });
  };

  const removePosition = () => {
    setRemoveLoading(true);
    const url = `/productservice/api/positions/${position.id}`;
    ServiceApi.delete(url)
      .then(() => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title",
        );
        onRemoved(position.id);
        toggleDeleteModal();
      })
      .finally(() => {
        setRemoveLoading(false);
      });
  };

  return (
    <PositionContext.Provider
      value={{
        positionData: data,
        setPositionData: setData,
        isBanner,
        isCarousel,
      }}
    >
      <Wrapper>
        <Wrapper.Header className="flex items-center gap-4">
          <h2>{data.title}</h2>
          {updatePermission && (
            <button
              type="button"
              className="text-primary"
              onClick={toggleEditPosition}
            >
              <Icon icon="edit" />
            </button>
          )}
          <h6 className="me-auto text-gray-800">{data.code}</h6>
          <Toggler
            label="applications.positions.positionActiveStatus"
            value={data.isActive}
            setValue={togglePositionStatus}
            className="!w-fit"
            size="sm"
            disabled={statusLoading || !updatePermission}
          />
          {removePermission && (
            <Button
              variant="danger"
              light
              size="sm"
              loading={removeLoading}
              onClick={() => {
                setSelected(data);
                toggleDeleteModal();
              }}
            >
              <Icon icon="trash" />
            </Button>
          )}
        </Wrapper.Header>
        <Wrapper.Body className="space-y-8">
          {data.components?.map(e => <Component key={e.id} {...e} />)}
          {updatePermission && (
            <button
              type="button"
              className="h4 block w-full rounded border border-dashed border-primary bg-primary-light p-4"
              onClick={toggleAddComponent}
            >
              <Icon icon="plus" className="text-primary" />{" "}
              <span className="text-gray-800">
                <Text>applications.positions.addComponentButton</Text>
              </span>
            </button>
          )}
        </Wrapper.Body>
      </Wrapper>
      <AddComponent isOpen={isAddComponentOpen} toggle={toggleAddComponent} />
      <PositionForm
        isOpen={isEditPositionOpen}
        toggle={toggleEditPosition}
        onSubmitted={data => setData(p => ({ ...p, ...data }))}
        initData={{
          id: data.id,
          title: data.title,
          code: data.code,
          componentType: data.componentType,
          description: data.description,
          isActive: data.isActive,
        }}
      />
      <DeleteModal
        isOpen={isOpenDeleteModal}
        toggle={toggleDeleteModal}
        selected={{ name: selected?.title, id: selected?.id }}
        loading={removeLoading}
        onConfirm={removePosition}
      />
    </PositionContext.Provider>
  );
}
