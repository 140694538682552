import {Status, Text} from "components";
import {SalesService} from "types";
import {convertDate} from "utils";

type Props = {
  item: SalesService.SellOffBasicInfoDto;
};
export default function SoldStatusTemplate({item}: Props) {
  return (
    <div className="space-y-2 font-normal">
      <h6 className="text-heading-6">
        <Status.SellOff
          id={"salesManagment.sellOffSales.sellOffProduct.sold"}
        />
      </h6>
      <p>{item.currentPhaseName}</p>
      <p className="text-body-2">
        <Text>salesManagment.sellOffSales.sellOffProduct.soldBy</Text>{" "}
        {item?.soldByName}
      </p>
      <p className="text-body-2">{convertDate(item.soldDateTime)}</p>
    </div>
  );
}
