// import noItemsIcon from "../assets/icons/no items.svg";
import Image from "./Image";
import NoData from "assets/image/no-data.svg";
import Text from "./Text";
import {LazyImage} from "components";
export default function NoItems() {
  return (
    <div className="w-full space-y-4 p-4 text-center text-2xl text-[#B5B5C3]">
      <LazyImage
        className="mx-auto block w-64"
        src={NoData}
        alt="No item found"
      />
      <h5>
        <Text>global.noItems</Text>
      </h5>
      <p className="text-base">
        <Text>global.noItemsElse</Text>
      </p>
    </div>
  );
}
