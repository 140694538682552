import {
  Breadcrumb,
  Button,
  Icon,
  NoItems,
  Skeleton,
  Table,
  Text,
  WithPermission,
} from "components";
import { config } from "constant";
import { withPermission } from "hoc";
import { usePermission, useToggleState } from "hooks";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { ServiceApi, URLS } from "services";
import AddDrawer from "./add-drawer";

type ShippingItems = {
  code: string;
  title: string;
  shippingMethodId: string;
};

function Brand() {
  const controller = new AbortController();
  const [data, setData] = useState<any>();
  const [loadingList, setLoadingList] = useState(true);
  const [isOpenDrawerCreate, toggleDrawerCreate] = useToggleState(false);
  const [shippingMethod, setShippingMethod] = useState<ShippingItems>();
  const [searchParams] = useSearchParams();
  const permission = usePermission("PS_GetShippingMethod");
  //original filter data for reset
  const originalFilterData: any = {
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  };

  // const [params, setParams] = useSearchParams();

  // const handleSetParams = (key: string) => {
  //   return (value: any) => {
  //     setParams((prev) => {
  //       value !== null && value !== ""
  //         ? key === "pageNumber" && value === 1
  //           ? prev.delete(key)
  //           : prev.set(key, value)
  //         : prev.delete(key);
  //       key !== "pageNumber" && prev.delete("pageNumber");
  //       return prev;
  //     });
  //   };
  // };

  const getData = () => {
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url = URLS.GET_SHIPPING_LIST;
    setLoadingList(true);
    ServiceApi.get(url, { signal: controller.signal })
      .then(({ data }) => {
        setData(data);
      })
      .catch(() => {
        setData({});
      })
      .finally(() => {
        setLoadingList(false);
      });
  };
  useEffect(() => {
    getData();
    return () => {
      controller.abort();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOpenDetail = (item: any) => {
    if (permission) {
      setShippingMethod(item);
      toggleDrawerCreate();
    }
  }

  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
        <WithPermission permissions={["PS_CreateShippingMethod"]}>
          {loadingList ? (
            <Skeleton.Button />
          ) : (
            <Button
              aria-label="cy-create-shipping-button"
              as={Link}
              type="button"
              onClick={() => {
                setShippingMethod({
                  title: "",
                  code: "",
                  shippingMethodId: "",
                });
                toggleDrawerCreate();
              }}
            >
              <Icon className="mr-2" icon={"plus"} />
              <Text>productManagement.masterData.shipping.addShipping</Text>
            </Button>
          )}
        </WithPermission>
      </div>
      {/* <SearchBox
    value={params?.get("keyword") || ""}
    onSubmit={handleSetParams("keyword")}
    disabled={loadingList}
    totalItems={data?.totalItems}
  /> */}
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {!isEmpty(data) ? (
            <>
              <Table>
                {data?.map((item: ShippingItems) => {
                  return (
                    <tr key={item.title}>
                      <td className={`text-left text-body-base text-gray-600 ${permission && 'cursor-pointer'}`} onClick={() => handleOpenDetail(item)}>
                        {item.title}
                      </td>
                      <td className="text-left text-body-base text-gray-600">
                        {item.code}
                      </td>
                      <td>
                        <WithPermission permissions={["PS_GetShippingMethod"]}>
                          <Button
                            variant="light"
                            size="sm"
                            onClick={() => handleOpenDetail(item)}
                          >
                            <Text>global.buttons.details</Text>
                          </Button>
                        </WithPermission>
                      </td>
                    </tr>
                  );
                })}
              </Table>
              {/* <Pagination
            totalItems={data?.totalItems}
            totalPages={data?.totalPages}
            pageNumber={
              params?.get("pageNumber")
                ? parseInt(params?.get("pageNumber") as string)
                : 1
            }
            pageSize={
              params?.get("pageSize")
                ? parseInt(params?.get("pageSize") as string)
                : config.pageSize
            }
            setActivePage={handleSetParams("pageNumber")}
            onPageSizeChange={(e: any) =>
              setParams((p) => ({ ...p, pageSize: e }))
            }
          /> */}
            </>
          ) : (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
            </div>
          )}
        </>
      )}
      <AddDrawer
        isOpen={isOpenDrawerCreate}
        toggle={toggleDrawerCreate}
        getData={getData}
        shippingMethod={shippingMethod}
      />
    </div>
  );
}
export default withPermission(Brand, ["PS_GetShippingMethod"]);
