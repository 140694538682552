import {sellOffSales} from "./sell-off-sales";
import {salesArea} from "./sales-area";
import {kitchenSales} from "./kitchen-sales";
import {priceTemplates} from "./price-templates";
import {generalDiscount} from "./genaral-discount";
import {salesChannels} from "./sales-channels";
import {commissionReports} from "./commission-group";

export const salesManagment = {
  sellOffSales,
  salesArea,
  kitchenSales,
  priceTemplates,
  generalDiscount,
  salesChannels,
  commissionReports,
};
