import {usePermission} from "hooks";
import {ComponentType} from "react";
import {Permission} from "types";
import {LazyImage} from "components";
import NoData from "assets/image/no-permission.svg";

export default function withPermission<P extends object>(
  Component: ComponentType<P>,
  permissions: Permission[],
  hideHint?: boolean,
) {
  const Error = () => {
    return (
      <p>
        <LazyImage className="mx-auto" src={NoData} alt="No Permission" />
      </p>
    );
  };
  return (props: P) => {
    const hasPermission = usePermission(...permissions);
    if (!hasPermission && hideHint) return <></>;
    if (!hasPermission) return <Error />;
    return <Component {...props} />;
  };
}
