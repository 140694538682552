import {NotificationManager} from "common/notifications";
import {Button, Drawer, Icon, Modal, Text} from "components";
import {useConverter} from "hooks";
import QRCode from "qrcode.react";
import {useSearchParams} from "react-router-dom";

type Type = {
  isOpen: boolean;
  toggle: () => void;
  bundle?: any;
};

//Share Drawer
export default function ChangedPriceDrawer({isOpen, toggle, bundle}: Type) {
  const {convertAmount} = useConverter();
  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header className="flex items-center justify-between text-heading-2 font-semibold">
          <Text>productManagement.publishedProduct.Details.priceInfo</Text>
          <Button
            variant={"light"}
            size="sm"
            className="flex items-center"
            onClick={toggle}
          >
            <span>
              <Text>global.buttons.close</Text>
            </span>
            <Icon icon="close" className="ml-2" />
          </Button>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          <div className="space-y-2 rounded border border-dashed border-gray-500 p-6">
            <h5>{bundle?.item?.originalName}</h5>
            {bundle?.item?.productFamily && (
              <div className="text-sm text-gray-500">
                <Text>productManagement.products.Details.productFamily</Text> :
                <span className="ml-1">{bundle?.item.productFamily?.code}</span>
              </div>
            )}
            {bundle?.item?.supplier && (
              <>
                <div className="text-sm text-gray-500">
                  <Text>productManagement.products.Details.supplier</Text> :
                  <span className="ml-1">{bundle?.item.supplier?.name} </span>
                </div>
                {bundle?.item?.supplier?.program && (
                  <div className="text-sm text-gray-500">
                    <Text>productManagement.products.Details.program</Text> :
                    <span className="ml-1">
                      {bundle?.item?.supplier?.program?.name}{" "}
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="space-y-2 rounded border border-dashed border-gray-500 p-6">
            <div className="flex items-center justify-between text-gray-600">
              <span className="text-body-base text-heading-6">
                <Text>vk2</Text> :{" "}
              </span>
              <span className="text-heading-5 line-through">
                {convertAmount(bundle?.item?.vk2Price?.amount)}
              </span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-body-base text-heading-6 text-gray-600">
                <Text>vk1</Text> :{" "}
              </span>
              <span className="text-heading-5 font-semibold text-primary-active">
                {convertAmount(bundle?.item?.originalVk1Price?.amount)}
              </span>
            </div>
            {bundle?.item?.hasOverwrittenPrice && (
              <div className="flex items-center justify-between">
                <span className="text-body-base text-heading-6 text-gray-600">
                  <Text>
                    productManagement.publishedProduct.Details.overWritedPrice
                  </Text>{" "}
                  :{" "}
                </span>
                <span className="text-heading-5 font-semibold text-primary-active">
                  {convertAmount(bundle?.item?.vk1Price?.amount)}
                </span>
              </div>
            )}
          </div>
          <div className={`space-y-2 rounded p-6 bg-${bundle?.type}-light`}>
            <h6 className="text-heading-6 font-semibold text-gray-700">
              <Text>productManagement.publishedProduct.Details.priceNow</Text>:
            </h6>
            <div className="flex items-center justify-between text-gray-600">
              <span className="text-body-base text-heading-6">
                <Text>vk2</Text> :{" "}
              </span>
              <span className="text-heading-5 line-through">
                {convertAmount(bundle?.item?.vk2Price?.amount)}
              </span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-body-base text-heading-6 text-gray-600">
                <Text>vk1</Text> :{" "}
              </span>
              <span
                className={`text-heading-5 font-semibold text-${bundle?.type}-active`}
              >
                {convertAmount(bundle?.item?.originalVk1Price?.amount)}
              </span>
            </div>
          </div>
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
}
