import {
  AddressViewer,
  Drawer,
  Image,
  LazyImage,
  List,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Text,
} from "components";
import {config} from "constant";
import {Fragment, useContext, useEffect, useState} from "react";
import {ServiceApi} from "services";
import {Store, WithPaging} from "types";
import {SelectFromBasketContext} from ".";
type Data = Store.Item;
type Params = {
  keyword?: string | null;
  pageSize: number;
  pageNumber: number;
};
export default function Stores() {
  const {setStore} = useContext(SelectFromBasketContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<WithPaging<Data> | null>(null);
  const [params, setParams] = useState<Params>({
    pageNumber: 1,
    pageSize: config.pageSize,
  });
  const handleClick = (store: Data) => {
    return () => setStore(store);
  };

  const getData = () => {
    setLoading(true);
    const url = "/accountservice/api/stores";
    const config = {params};
    ServiceApi.get(url, config)
      .then(({data}) => {
        setData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(getData, [params]);
  return (
    <Fragment>
      <Drawer.Header className="space-y-4 border-b-0">
        <fieldset className="text-start">
          <legend>
            <Text>productManagement.products.allProducts.chooseStore</Text>
          </legend>
        </fieldset>
        <SearchBox
          value={params.keyword}
          onSubmit={keyword => setParams(p => ({...p, keyword, pageNumber: 1}))}
          variant="gray"
          totalItems={data?.totalItems}
          onReload={getData}
        />
      </Drawer.Header>
      <Drawer.Body className="space-y-4">
        {loading ? (
          [...Array(10).keys()].map(key => <Skeleton.Input key={key} />)
        ) : !data?.items?.length ? (
          <NoItems />
        ) : (
          <List>
            {data.items.map(e => (
              <List.Item
                key={e.id}
                className="flex items-center gap-4"
                onClick={handleClick(e)}
              >
                <LazyImage
                  isDynamic
                  src={e.mainPhotoUrl}
                  alt={e.title}
                  className="h-20 w-32 rounded"
                  imageClassName="object-cover"
                />
                <div className="flex-1 space-y-2">
                  <h6 className="block w-full text-heading-6 font-semibold text-gray-800">
                    {e.title}
                  </h6>
                  <AddressViewer address={e.address} className="text-sm" />
                </div>
              </List.Item>
            ))}
          </List>
        )}
      </Drawer.Body>
      <Drawer.Footer>
        <Pagination
          setActivePage={page => setParams(p => ({...p, pageNumber: +page}))}
          onPageSizeChange={(size: any) =>
            setParams(p => ({...p, pageSize: +size}))
          }
          pageNumber={params.pageNumber}
          pageSize={params.pageSize}
          totalItems={data?.totalItems}
          totalPages={data?.totalPages}
        />
      </Drawer.Footer>
    </Fragment>
  );
}
