import {Text, AddressForm} from "components";
import {Fragment, useContext} from "react";
import {generateMapSrc} from "utils";
import {AddStoreContext} from ".";

export default function Location() {
  const {data, setData, addressErrorMsg} = useContext(AddStoreContext);

  return (
    <Fragment>
      <h2 className="text-heading-2 text-gray-800">
        <Text>company.stores.location</Text>
      </h2>
      <AddressForm data={data} setData={setData} errorMsg={addressErrorMsg} />
      <div className="flex flex-col">
        <h6 className="mb-2">
          <Text>company.stores.locationOnMap</Text>
        </h6>
        <iframe
          title="Address"
          src={generateMapSrc(data.address)}
          className="block aspect-video w-full rounded bg-gray-300 shadow"
        />
      </div>
    </Fragment>
  );
}
