import { NotificationManager } from "common/notifications";
import { Button, Icon, Text, Drawer } from "components";
import { useState, useContext } from "react";
import { useToggleState } from "hooks";
import { ServiceApi, URLS } from "services";
import { PimContext } from "..";

type shippingMethodsItems = {
  shippingMethodId: string;
  code: string;
  title: string;
};

type pimProductShippingMethodsItems = {
  id: string;
  code: string;
  title: string;
};

type AddShippingMethods = {
  isOpen: boolean;
  toggle: () => void;
  shippingList: pimProductShippingMethodsItems[];
  pimShippingIds: string[];
};

export default function AddShippingMethods({
  isOpen,
  toggle,
  shippingList,
  pimShippingIds,
}: any) {
  const { productData, setData, getPIMProduct } = useContext(PimContext);
  const [loading, setLoading] = useToggleState();
  const [selectedIndex, setIndex] = useState<number | null>(null);

  const onSubmitHandler = () => {
    setLoading();
    const url = URLS.ADD_SHIPPING_METHOD_TO_PIM(productData?.id);
    const shippingMethod = shippingList[selectedIndex as number];
    const body = {
      shippingMethodId: shippingMethod?.shippingMethodId,
    };
    ServiceApi.post(url, body).then(() => {
      NotificationManager.success(
        "global.toast.add-msg",
        "global.toast.add-title"
      );
      //   const clone = [...productData?.pimProductShippingMethods];
      //   const newData = shippingList[selectedIndex as number];
      //   clone.push({
      //     id: newData.shippingMethodId,
      //     title: newData.title,
      //     code: newData.code,
      //   });
      //   setData((p: any) => ({ ...p, pimProductShippingMethods: clone }));
      setLoading();
      setIndex(null);
      toggle();
      getPIMProduct();
    });
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header className="flex text-heading-2 font-semibold">
          <Text>productManagement.products.Details.addShipping</Text>
        </Drawer.Header>
        <Drawer.Body className="overflow-auto space-y-4">
          {shippingList?.map(
            (shipping: shippingMethodsItems, index: number) => {
              return (
                <div
                  onClick={() => {
                    if (!pimShippingIds.includes(shipping?.shippingMethodId))
                      setIndex(index);
                  }}
                  className={`
                ${selectedIndex === index && "bg-primary-light"} 
                ${
                  pimShippingIds.includes(shipping?.shippingMethodId)
                    ? "opacity-70"
                    : "hover:bg-primary-light cursor-pointer"
                }
                shadow-high p-4 rounded-xl flex justify-between items-center`}
                >
                  <div className="space-y-1">
                    <h6 className="text-heading-6 text-gray-800 font-semibold">
                      {shipping.title}
                    </h6>
                    <div>
                      <span className="text-body-base text-gray-500 font-normal">
                        {shipping.code}
                      </span>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-between">
          <Button onClick={toggle} variant="white">
            <Icon className="mr-2" icon={"times"} />
            <Text>global.buttons.cancel</Text>
          </Button>
          <Button
            type="button"
            size="sm"
            onClick={onSubmitHandler}
            loading={loading}
            disabled={selectedIndex === null}
          >
            <Text>global.buttons.submit</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
