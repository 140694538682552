import {NotificationManager} from "common/notifications";
import {AxiosRequestConfig} from "axios";
import {
  Button,
  Drawer,
  Form,
  Icon,
  InputGroup,
  Text,
  WithPermission,
  Toggler,
  FileUploader,
  Skeleton,
  SelectLang,
  LoadingSvg,
} from "components";
import {FormEvent, useEffect, useRef, useState} from "react";
import {rules} from "constant";
import {ServiceApi, URLS} from "services";
import {useDataState, useSelector, useToggleState} from "hooks";
import {ProductLabelTemplate} from "types";
import AddMetaData from "./add-meta-data";

export default function EditProductLabelTemplateDrawer(props: {
  isOpen: boolean;
  toggle: () => void;
  getData: () => void;
  selected: ProductLabelTemplate.Item;
}) {
  const isNew = props.selected.id == "";
  const [loading, setLoading] = useToggleState();
  const [displayError, setDisplayError] = useState(false);
  const [submitLoading, setSubmitLoading] = useToggleState();
  const formRef = useRef<any>();
  const [data, setData, setBaseData, isChanged] = useDataState<
    ProductLabelTemplate.Item | undefined
  >(undefined);
  const [file, setFile] = useState<any>();
  const [isOpenMetaDataDrawer, toggleMetaDataDrawer] = useToggleState(false);
  const [loadingFile, toggleLoadingFile] = useToggleState();
  const [deleteLoading, setDeleteLoading] = useToggleState();
  const [items, setItems] = useState<any>([]);
  const companySetting = useSelector(s => s.companySetting);
  const typeList = [
    {expression: "word", icon: "file-word", type: "Word document"},
    {expression: "pdf", icon: "file-pdf", type: "pdf"},
  ];

  const getData = () => {
    setLoading();
    const url = URLS.UPDATE_PRODUCT_LABEL_TEMPLATE_URL(props.selected.id);
    ServiceApi.get(url)
      .then(({data}) => {
        setBaseData(data);
      })
      .finally(() => {
        setLoading();
      });
  };

  const onSubmitForm = (formData: any) => {
    setDisplayError(true);
    if (!formData?.uploadedFile) return;
    setSubmitLoading();
    const body = formData;
    if (!formData.id) {
      const url = URLS.PRODUCT_LABEL_TEMPLATE_URL;
      ServiceApi.post(url, body)
        .then(() => {
          NotificationManager.success(
            "global.toast.add-msg",
            "global.toast.add-title",
          );
          props.toggle();
          props.getData();
        })
        .finally(() => setSubmitLoading());
    } else {
      const url = URLS.UPDATE_PRODUCT_LABEL_TEMPLATE_URL(formData.id);
      ServiceApi.put(url, body)
        .then(() => {
          NotificationManager.success(
            "global.toast.edit-msg",
            "global.toast.edit-title",
          );
          props.toggle();
          props.getData();
        })
        .finally(() => setSubmitLoading());
    }
  };

  const onChangeHandler = (key: any) => {
    return (value: any) => setData((p: any) => ({...p, [key]: value}));
  };

  const submit = (e?: FormEvent) => {
    formRef.current.submitHandler(e, data);
  };

  const handleDeleteFile = () => {
    setData((p: any) => ({...p, uploadedFile: undefined}));
    setFile("");
  };

  const handleSetFile = (file: any) => {
    setFile(file);
    setData((p: any) => ({
      ...p,
      uploadedFile: {
        type: file?.type,
        url: file?.url,
      },
    }));
  };

  const getTypeAndIcon = (type: string) => {
    return (
      typeList.filter(item =>
        type?.toLowerCase()?.includes(item.expression),
      )[0] || {icon: "file", type: "Unknown", accept: ""}
    );
  };

  const addMetaDataHandler = (newObject: ProductLabelTemplate.KeyValue) => {
    setData((p: any) => {
      const list = [...p.metadata, newObject];
      return {...p, metadata: list};
    });
  };

  const deleteMetaDataHandler = (object: ProductLabelTemplate.KeyValue) => {
    setData((p: any) => {
      const list = p.metadata.filter(
        (item: ProductLabelTemplate.KeyValue) =>
          item.key !== object.key || item.value !== object.value,
      );
      return {...p, metadata: list};
    });
  };

  const downloadFileHandler = async (url: string) => {
    let dataUrl = url;
    toggleLoadingFile();
    if (dataUrl?.includes("/get-file")) {
      const config: AxiosRequestConfig = {
        responseType: "blob",
        params: {withoutSubscriptionKey: true},
      };
      await ServiceApi.get(dataUrl, config).then(({data}) => {
        dataUrl = URL.createObjectURL(data);
      });
    }
    toggleLoadingFile();
    return dataUrl;
  };

  const deletetemplateHandler = () => {
    setDeleteLoading();
    const url = URLS.UPDATE_PRODUCT_LABEL_TEMPLATE_URL(props.selected.id);
    ServiceApi.delete(url)
      .then(() => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title",
        );
        props.toggle();
        props.getData();
      })
      .finally(() => setDeleteLoading());
  };

  useEffect(() => {
    if (props.isOpen) {
      if (props.selected.id) getData();
      else setBaseData(props.selected);
    } else setFile("");
  }, [props.selected, props.isOpen]);

  useEffect(() => {
    if (companySetting) {
      let arr: any = {};
      let arrItem: any = [];
      companySetting?.contentLanguages?.forEach((item: any) => {
        arr[item] = item;
        arrItem.push({
          name: item,
          id: item,
        });
      });
      setItems(arrItem);
    }
  }, [companySetting]);

  return (
    <>
      <Drawer isOpen={props.isOpen} toggle={props.toggle}>
        <Drawer.Menu className="pr-0">
          <Drawer.Header className="pr-4 pt-6">
            <div className="flex items-center justify-between">
              <h2 className="text-heading-2 text-gray-800">
                {isNew ? (
                  <Text>
                    productManagement.masterData.productLabelTemplate.addProductLabelTemplate
                  </Text>
                ) : (
                  <Text>
                    productManagement.masterData.productLabelTemplate.editProductLabelTemplate
                  </Text>
                )}
              </h2>
              <div className="flex items-center justify-between">
                <WithPermission permissions={["SC_DeleteProductLabelTemplate"]}>
                  {!isNew && (
                    <Button
                      type="button"
                      variant="danger"
                      light
                      onClick={() => deletetemplateHandler()}
                      className="!px-4"
                    >
                      {deleteLoading ? (
                        <LoadingSvg size="sm" />
                      ) : (
                        <Icon icon={"trash"} />
                      )}
                    </Button>
                  )}
                </WithPermission>
                <Button
                  type="button"
                  variant="light"
                  className="ml-2"
                  onClick={() => props.toggle()}
                >
                  <Icon className="mr-2" icon={"times"} />
                  <span className="text-body-2">
                    {" "}
                    <Text>global.buttons.close</Text>
                  </span>
                </Button>
              </div>
            </div>
          </Drawer.Header>
          <Drawer.Body>
            {loading ? (
              <div className="mt-8 space-y-5">
                <Skeleton.Input key={1} />
                <Skeleton.Input key={2} />
              </div>
            ) : (
              data && (
                <Form
                  ref={formRef}
                  onSubmit={onSubmitForm}
                  className="mt-8 space-y-5"
                  id={"create-drawer"}
                >
                  <InputGroup
                    label="productManagement.masterData.productLabelTemplate.title"
                    value={data.title}
                    setValue={onChangeHandler("title")}
                    rules={rules.required}
                    ariaLabel="cy-product-label-template-title"
                  />
                  <Toggler
                    label={
                      "productManagement.masterData.productLabelTemplate.active"
                    }
                    value={data.isActive}
                    setValue={onChangeHandler("isActive")}
                  />
                  <Toggler
                    label={
                      "productManagement.masterData.productLabelTemplate.isGlobal"
                    }
                    value={data.isGlobal}
                    setValue={onChangeHandler("isGlobal")}
                  />
                  <div className="flex items-center gap-2">
                    <label className="whitespace-nowrap text-heading-5 font-semibold">
                      <Text>
                        productManagement.masterData.productLabelTemplate.file
                      </Text>
                      <span className="text-danger">*</span>
                    </label>
                    <hr className="flex-1 bg-gray-200" />
                  </div>
                  {!data?.uploadedFile && (
                    <FileUploader
                      value={file}
                      setValue={setFile}
                      getResponse={handleSetFile}
                      isHorizontal
                      type={7}
                      accept={".doc,.docx,.pdf"}
                    />
                  )}
                  {!data?.uploadedFile && displayError && (
                    <p className="text-xs text-danger">
                      <i className="bi bi-info-circle mr-1"></i>
                      <Text>errors.required</Text>
                    </p>
                  )}
                  {data?.uploadedFile && (
                    <div className="flex cursor-pointer justify-between gap-4 rounded-xl bg-gray-100 p-4">
                      <div
                        className="flex gap-4"
                        onClick={async () => {
                          const url = await downloadFileHandler(
                            data?.uploadedFile?.url || "",
                          );
                          window.open(url, "_blank");
                        }}
                      >
                        {loadingFile ? (
                          <LoadingSvg size="sm" />
                        ) : (
                          <Icon
                            icon={
                              getTypeAndIcon(data?.uploadedFile?.type).icon ||
                              "file-arrow-up"
                            }
                            className="text-primary"
                            size="3x"
                          />
                        )}
                        <div className="flex flex-1 flex-col justify-center gap-3">
                          <h5 className="text-heading-5 ">{data?.title}</h5>
                          <p className="text-body-base text-gray-600">
                            {getTypeAndIcon(data?.uploadedFile?.type).type}
                          </p>
                        </div>
                      </div>
                      <Button
                        className="flex !h-12 !w-12 items-center justify-center !rounded-full !p-0"
                        variant={"danger"}
                        light
                        onClick={handleDeleteFile}
                      >
                        <Icon icon="trash" size="lg" />
                      </Button>
                    </div>
                  )}
                  <div className="flex items-center gap-2">
                    <label className="whitespace-nowrap text-heading-5 font-semibold">
                      <Text>
                        productManagement.masterData.productLabelTemplate.language
                      </Text>
                    </label>
                    <hr className="flex-1 bg-gray-200" />
                  </div>
                  <div className="flex py-3">
                    <h6 className="flex-1 font-normal">
                      <Text>productManagement.products.Details.language</Text>
                    </h6>
                    <SelectLang
                      value={data.language}
                      items={items}
                      onChangeLanguage={onChangeHandler("language")}
                      className="bg-white"
                    />
                  </div>
                  <div className="flex items-center gap-2">
                    <label className="whitespace-nowrap text-heading-5 font-semibold">
                      <Text>
                        productManagement.masterData.productLabelTemplate.metaData
                      </Text>
                    </label>
                    <hr className="flex-1 bg-gray-200" />
                  </div>
                  <Button
                    type="button"
                    variant="primary"
                    light
                    onClick={toggleMetaDataDrawer}
                    aria-label="cy-product-label-template-metadata"
                  >
                    <Icon className="mr-2" icon={"plus"} />
                    <Text>
                      productManagement.masterData.productLabelTemplate.addMetaData
                    </Text>
                  </Button>
                  {data.metadata?.map((metaData, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between rounded p-3 shadow-[0px_4px_20px_0px_#38476D17]"
                    >
                      <div>
                        <h5 className="mb-1 text-heading-5 font-semibold">
                          {metaData.key}
                        </h5>
                        <span className="text-xs text-gray-600">
                          {metaData.value}
                        </span>
                      </div>
                      <Button
                        type="button"
                        variant="danger"
                        light
                        onClick={() => deleteMetaDataHandler(metaData)}
                        className="!px-4"
                      >
                        <Icon icon={"trash"} />
                      </Button>
                    </div>
                  ))}
                </Form>
              )
            )}
          </Drawer.Body>
          <WithPermission
            permissions={["SC_UpdateSaleServiceDocumentTemplate"]}
          >
            <Drawer.Footer className="flex justify-between">
              <Button
                variant="white"
                type="button"
                onClick={() => props.toggle()}
              >
                <Text>global.buttons.cancel</Text>
              </Button>
              <Button
                type="button"
                variant="primary"
                onClick={submit}
                loading={submitLoading}
                disabled={!isChanged}
                aria-label="cy-product-label-template-submit"
              >
                <Text>global.buttons.submit</Text>
              </Button>
            </Drawer.Footer>
          </WithPermission>
        </Drawer.Menu>
      </Drawer>
      <AddMetaData
        isOpen={isOpenMetaDataDrawer}
        toggle={toggleMetaDataDrawer}
        onAdd={addMetaDataHandler}
        addedMetaData={data?.metadata || []}
      />
    </>
  );
}
