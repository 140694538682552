export const priceTemplates = {
  assignedPriceTemplate: "Assigned price template",
  assignPriceTemplate: "Assign Price Template",
  valid: "Valid",
  from: "from",
  to: "to",
  info: "Info Text",
  basicInfo: "Basic Info",
  title: "Title",
  code: "Code",
  shortDescription: "Short Description",
  general: "General",
  steps: "Steps",
  active: "Active",
  deActive: "deActive",
  addStepItem: "Add Step Item",
  addDiscountItem: "Add Discount Item",
  calculationFactor: "Calculation Factor",
  optionalDiscounts: "Optional Discounts",
  value: "Value",
  step1: "Step 1 - G> N",
  step2: "Step 2 - N>NN",
  step3: "Step 3 - Calculation factor",
  step4: "Step 4",
  step5: "Step 5",
  addPriceTemplate: "Add Price Templates",
  name: "Name",
  pure: "Pure",
  type: "Type",
  addNewStepItem: "Add New Step Item",
  percent: "Percent (%)",
  amount: "Amount (€)",
  normalProfit: "Normal Profit",
  pureProfit: "Pure Profit",
  discount: "Discount",
  editStepItem: "Edit Step item",
};
