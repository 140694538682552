import { Button, Drawer, Icon, Skeleton, Status, Text } from "components";
import { useTranslation } from "react-i18next";
import { useToggleState } from "hooks";
import { useEffect, useState } from "react";
import { ServiceApi, URLS } from "services";

type drawerProps = {
  isOpen: boolean;
  toggle: () => void;
  selectedAttribute: any;
};

const AttributeDrawer = ({
  isOpen,
  toggle,
  selectedAttribute,
}: drawerProps) => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useToggleState();
  const { i18n } = useTranslation();

  const getAttribute = () => {
    setLoading();
    const url = URLS.GET_ADDED_ATTRIBUTES(selectedAttribute);
    ServiceApi.get(url)
      .then(({ data }) => {
        setData(data);
        setLoading();
      })
      .catch(() => setLoading());
  };

  useEffect(() => {
    if (isOpen) {
      setData([]);
      getAttribute();
    }
  }, [isOpen]);

  const convertType = (type: number) => {
    switch (type) {
      case 10:
        return "String";
      case 20:
        return "Boolean";
      case 30:
        return "List";
      case 40:
        return "Date";
      case 50:
        return "Time";
      case 60:
        return "DateTime";
      case 70:
        return "Measurement";
      case 80:
        return "TextArea";
    }
  };

  return (
    <>
      <Drawer isOpen={isOpen} toggle={toggle}>
        <Drawer.Menu className="pr-0">
          <Drawer.Header className="pt-6 pr-4">
            <div className="flex items-center justify-between">
              <span className="text-heading-2 font-semibold">
                <Text>
                  productManagement.masterData.productFamilies.attributes
                </Text>
              </span>
              <div className="flex gap-x-2">
                <Button size="sm" variant={"light"} onClick={toggle}>
                  <Icon icon="times" className="mr-1" />
                  <Text>
                    productManagement.masterData.productFamilies.close
                  </Text>
                </Button>
              </div>
            </div>
          </Drawer.Header>
          <Drawer.Body className="space-y-4">
            {loading && [1, 2, 3, 4, 5].map((id) => <Skeleton.List key={id} />)}
            {data?.map((attr: any) => {
              return (
                <div className="shadow-card p-4 rounded-xl flex justify-between items-center">
                  <div className="h-100 flex flex-col justify-between h-[70px]">
                    <span className="text-gray-800 text-heading-6 font-semibold">
                      {
                        attr?.translates?.filter(
                          (tr: any) =>
                            tr.language === i18n.language.toLowerCase()
                        )?.[0]?.labelTitle
                      }
                    </span>
                    <span className="text-body-2 font-medium text-gray-500">
                      {attr?.code} | {convertType(attr?.type)}
                    </span>
                  </div>
                  <div className="space-y-1 text-right">
                    {attr?.isRequired && (
                      <p>
                        <Status.ProductFamilies
                          id={
                            "productManagement.masterData.productFamilies.required"
                          }
                        />
                      </p>
                    )}
                    {attr?.perChannel && (
                      <p>
                        <Status.ProductFamilies
                          id={
                            "productManagement.masterData.productFamilies.perChannel"
                          }
                        />
                      </p>
                    )}
                    {attr?.perLanguage && (
                      <p>
                        <Status.ProductFamilies
                          id={
                            "productManagement.masterData.productFamilies.perLanguage"
                          }
                        />
                      </p>
                    )}
                  </div>
                </div>
              );
            })}
          </Drawer.Body>
        </Drawer.Menu>
      </Drawer>
    </>
  );
};

export default AttributeDrawer;
