const unitTypes = [
  {label: "priceEngine.calculationUnits.percent", value: 0, symbol: "%"},
  {label: "priceEngine.calculationUnits.amount", value: 1},
  {label: "priceEngine.calculationUnits.multiply", value: 2},
  {label: "priceEngine.calculationUnits.pureProfit", value: 3},
  {label: "priceEngine.calculationUnits.pulsRound", value: 4},
  {label: "priceEngine.calculationUnits.simpleRound", value: 5},
];
const hasManualUnitTypes = [
  {label: "priceEngine.calculationUnits.percent", value: 0, symbol: "%"},
  {label: "priceEngine.calculationUnits.amount", value: 1},
];
const unitOperationType = [
  {label: "priceEngine.calculationUnits.minus", value: 1},
  {label: "priceEngine.calculationUnits.plus", value: 0},
];
const calculationLevel = [
  {label: "priceEngine.calculationLevel.none", value: 0},
  {label: "priceEngine.calculationLevel.tenant", value: 1},
  // { label: "priceEngine.calculationLevel.legalEntity", value: 4 },
  {label: "priceEngine.calculationLevel.channel", value: 8},
  // { label: "priceEngine.calculationLevel.customer", value: 12 },
  {label: "priceEngine.calculationLevel.supplier", value: 16},
  {label: "priceEngine.calculationLevel.brand", value: 21},
  {label: "priceEngine.calculationLevel.program", value: 25},
  {label: "priceEngine.calculationLevel.product", value: 28},
];

const purchaseTemplatesSteps = [
  {label: "priceEngine.purchasePrice.grossToNet", step: 0, items: []},
  {label: "priceEngine.purchasePrice.netToNNet", step: 1, items: []},
  // { label: "priceEngine.purchasePrice.nNetToPurchase", step: 2, items: [] },
];
const salesTemplatesSteps = [
  {label: "priceEngine.salesPrice.section1", step: 1, items: []},
  {label: "priceEngine.salesPrice.section2", step: 2, items: []},
  {label: "priceEngine.salesPrice.section3", step: 3, items: []},
  {label: "priceEngine.salesPrice.section4", step: 4, items: []},
  {label: "priceEngine.salesPrice.section5", step: 5, items: []},
  {label: "priceEngine.salesPrice.section6", step: 6, items: []},
];
const iwofurnSalesTemplatesSteps = [
  {label: "priceEngine.salesPrice.section3", step: 3, items: []},
  {label: "priceEngine.salesPrice.section5", step: 5, items: []},
  {label: "priceEngine.salesPrice.section6", step: 6, items: []},
];

const InputSalePriceType = [
  {name: "priceEngine.simulator.purchasePrice", id: 0},
  {name: "priceEngine.simulator.vk3", id: 1},
  {name: "priceEngine.simulator.vk2", id: 2},
];

const LineItemType = [
  {name: "priceEngine.simulator.PimProduct", id: 0},
  {name: "priceEngine.simulator.IwofurnProduct", id: 1},
  {name: "priceEngine.simulator.ManualProduct", id: 2},
];
const CalculationProcessType = [
  {name: "priceEngine.simulator.PurchasePriceProcess", id: 0},
  {name: "priceEngine.simulator.SalePriceProcess", id: 1},
];

const calculationLevelEnum = {
  none: 0,
  tenant: 1,
  legalEntity: 4,
  channel: 8,
  customer: 12,
  supplier: 16,
  brand: 21,
  program: 25,
  product: 28,
};
export {
  unitOperationType,
  unitTypes,
  calculationLevel,
  purchaseTemplatesSteps,
  salesTemplatesSteps,
  calculationLevelEnum,
  InputSalePriceType,
  LineItemType,
  CalculationProcessType,
  hasManualUnitTypes,
  iwofurnSalesTemplatesSteps,
};
