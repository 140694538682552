import {NotificationManager} from "common/notifications";
import {
  Button,
  DeleteModal,
  HandleName,
  Icon,
  Skeleton,
  Text,
  WithPermission,
} from "components";
import {withPermission} from "hoc";
import {useToggleState, usePermission} from "hooks";
import {useContext, useState} from "react";
import {ServiceApi, URLS} from "services";
import {PimContext} from "..";
import AssignCategoryDrawer from "../drawers/assign-category";

const CategoriesTab = () => {
  const updatePermission = usePermission("PS_UpdatePimProduct");
  const {
    productData,
    setData,
    setPatchData,
    getPIMProduct,
    productCategory,
    loading,
  } = useContext(PimContext);
  const [isOpen, toggle] = useToggleState();
  const [deletModal, toggleDelete] = useToggleState();
  const [deletLoading, toggleDeleteLoading] = useToggleState();
  const [selectedItem, setSelectedItem] = useState<any>({});

  const onDeleteHandler = (id: string) => {
    toggleDeleteLoading();
    const url = URLS.REMOVE_CATEGORY_FROM_PIM(productData.id);
    const body = {
      productCategoryId: id,
    };
    ServiceApi.post(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title",
        );
        toggleDeleteLoading();
        toggleDelete();
        getPIMProduct();
      })
      .catch(() => toggleDeleteLoading());
  };

  return (
    <>
      {productData.pimProductCategories?.map((category: any) => {
        return (
          <>
            {loading ? (
              <Skeleton.List />
            ) : (
              <div
                key={category.id}
                className="flex h-[78px] items-center justify-between rounded-xl bg-white p-4 shadow-card"
              >
                <h5 className="text-gray-800">
                  <HandleName
                    translations={category?.translates}
                    code={category?.code}
                    keyName="labelTitle"
                  />
                </h5>
                {updatePermission && (
                  <Button
                    variant={"danger"}
                    light
                    size="sm"
                    onClick={() => {
                      setSelectedItem(category);
                      toggleDelete();
                    }}
                  >
                    <Icon icon="trash" />
                  </Button>
                )}
              </div>
            )}
          </>
        );
      })}
      {updatePermission && (
        <div
          onClick={toggle}
          className="flex-center flex h-[52px] cursor-pointer select-none gap-4 rounded-xl border border-dashed border-primary bg-gray-100 text-primary"
        >
          <Icon
            icon="plus"
            size="xs"
            className="rounded-md bg-primary-light p-2"
          />
          <h5 className="text-primary">
            <Text>productManagement.products.Details.assignCategory</Text>
          </h5>
        </div>
      )}
      <WithPermission permissions={["PS_UpdatePimProduct"]}>
        <AssignCategoryDrawer
          isOpen={isOpen}
          toggle={toggle}
          productData={productData}
          setData={setData}
          setPatchData={setPatchData}
          getPIMProduct={getPIMProduct}
          productCategory={productCategory}
        />
      </WithPermission>
      <DeleteModal
        isOpen={deletModal}
        toggle={toggleDelete}
        selected={{name: selectedItem?.code}}
        onConfirm={() => onDeleteHandler(selectedItem.id)}
        loading={deletLoading}
      />
    </>
  );
};

export default withPermission(CategoriesTab, ["PS_GetProductCategory"]);
