import {Button, Icon, SearchBox, Skeleton, Text, Wrapper} from "components";
import {useToggleState, useTranslate} from "hooks";
import {CSSTransition} from "react-transition-group";
import {classNames} from "utils";
import {usePermission} from "hooks";
import CreateDrawer from "./create-drawer";
import {useEffect, useState} from "react";
import {debounce} from "utils";

type Props = {
  programs: any;
  loading: boolean;
  selectedId: any;
  setSelectedId: any;
  updateList: any;
  suppliersData: any;
  isOpenCreateDrawer: boolean;
  toggleCreateDrawer: () => void;
};

const ProgramList = ({
  programs,
  selectedId,
  setSelectedId,
  loading,
  updateList,
  suppliersData,
  isOpenCreateDrawer,
  toggleCreateDrawer,
}: Props) => {
  const addPermission = usePermission("PS_UpdateSupplier");
  const translate = useTranslate();
  const [keyword, setKeyword] = useState("");
  const [isActive, setIsActive] = useToggleState(true);
  const [programList, dispatchProgramList] = useState([]);
  const drawer = classNames(
    "flex flex-center rounded overflow-hidden p-[5px] w-8 h-8 transition-transform absolute -right-[.9rem] bg-white shadow top-1/2",
    {
      "rotate-180": !isActive,
    },
  ); //rotate-180

  const panel = classNames(`flex-none relative transition-[width] h-[641px] `, {
    "!w-[374px]": isActive,
    "!w-[66px]": !isActive,
  });

  useEffect(() => {
    dispatchProgramList(programs);
  }, [programs]);

  const filterProgramList = (val: string) => {
    const filteredList = programs?.filter(
      (program: any) =>
        program.code.toLowerCase().includes(val.toLowerCase()) ||
        program.title.toLowerCase().includes(val.toLowerCase()),
    );
    dispatchProgramList(filteredList);
  };

  const onSearchHandler = (val: any) => {
    debounce(() => filterProgramList(val), 400);
  };

  return (
    <Wrapper className={panel}>
      <div
        className={`space-y-2 border-b transition-transform ${
          isActive ? "p-4" : "px-1 py-4"
        }`}
      >
        <div
          className={`flex ${isActive ? "justify-between" : "justify-center "}`}
        >
          <div className="flex items-center gap-2">
            <Icon
              icon={"list-tree"}
              size="1x"
              className="flex-none text-primary"
            />
            <CSSTransition
              in={isActive}
              timeout={50}
              unmountOnExit
              classNames="alert"
            >
              <span className="text-heading-2 font-semibold text-gray-800">
                <Text>productManagement.masterData.suppliers.programs</Text>
              </span>
            </CSSTransition>
          </div>
          {addPermission && (
            <CSSTransition
              in={isActive}
              timeout={50}
              unmountOnExit
              classNames="alert"
            >
              {
                <Button
                  type="button"
                  size="sm"
                  onClick={toggleCreateDrawer}
                  aria-label="cy-product-add-button"
                >
                  <Icon icon={"plus"} className="mr-2 after:!opacity-100" />
                  <Text>productManagement.masterData.suppliers.addProgram</Text>
                </Button>
              }
            </CSSTransition>
          )}
        </div>
        <CSSTransition
          in={isActive}
          timeout={50}
          unmountOnExit
          classNames="alert"
        >
          <div
            className={
              "flex w-full items-center gap-2 rounded bg-gray-100 px-2 py-1 text-body-2 font-medium ring-gray-500 ring-offset-1 transition-shadow focus-within:ring-1"
            }
          >
            <button type="button">
              <Icon icon="search" />
            </button>
            <input
              aria-label="cy-search-input"
              value={keyword}
              onChange={e => {
                setKeyword(e.target.value);
                onSearchHandler(e.target.value);
              }}
              placeholder={translate("global.placeholders.search") ?? ""}
              className="h-8 flex-1 px-2"
            />
          </div>
        </CSSTransition>
      </div>
      <div
        className="max-h-[641px] overflow-scroll rounded bg-white p-3"
        style={{direction: "rtl"}}
      >
        <div className="space-y-4 rounded bg-white" style={{direction: "ltr"}}>
          {loading &&
            [1, 2, 3, 4, 5].map((item: any) => <Skeleton.Input key={item} />)}
          {!loading &&
            programList?.map((program: any) => {
              return (
                <div
                  key={program.id}
                  className={` h-12 min-w-[40px] cursor-pointer rounded p-1 text-body-base shadow-sm ${
                    program.id === selectedId
                      ? "bg-primary-light font-semibold text-primary"
                      : "bg-gray-100 font-normal text-gray-800"
                  }`}
                  aria-label="cy-category-item"
                  onClick={() => setSelectedId(program.id)}
                >
                  <CSSTransition
                    in={true}
                    timeout={200}
                    unmountOnExit
                    classNames="alert"
                  >
                    <span className="flex h-full w-full items-center px-2">
                      {isActive
                        ? program.title
                        : program.title.slice(0, 2).toUpperCase()}
                    </span>
                  </CSSTransition>
                </div>
              );
            })}
        </div>
      </div>
      <button
        type="button"
        className={drawer}
        onClick={() => {
          setIsActive();
        }}
      >
        <Icon icon={"chevrons-left"} size="1x" className="text-primary" />
      </button>
      <CreateDrawer
        isOpen={isOpenCreateDrawer}
        toggle={toggleCreateDrawer}
        getData={updateList}
        supplierCode={suppliersData.code}
        supplierData={suppliersData}
      />
    </Wrapper>
  );
};

export default ProgramList;
