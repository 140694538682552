import {
  Button,
  Dropdown,
  Icon,
  Image,
  LazyImage,
  Modal,
  Skeleton,
  Text,
} from "components";
import {flagCountry} from "constant";
import {useToggleState, useSelector} from "hooks";
import {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ServiceApi, URLS} from "services";
import {checkPriceAvailability} from "utils";
import Lightbox from "yet-another-react-lightbox";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import {PimContext} from "..";
import {findValue} from "../../helpers/find-attribute.value";
import {AxiosRequestConfig} from "axios";

type PreviewType = {
  isOpen: boolean;
  toggle: () => void;
  id?: string;
  channels: any;
};

export default function Preview(props: PreviewType) {
  const {i18n} = useTranslation();
  const [imageUrls, setImageUrls] = useState([]);
  const [selectedImage, setSelectedImage] = useState<number>();
  const {productData} = useContext(PimContext);
  const [loadingAttributes, setLoadingAttributes] = useToggleState();
  const [loadingMedias, setLoadingMedias] = useToggleState();
  const [attributes, setAttributes] = useState<any>([]);
  const [toggler, setToggler] = useToggleState();
  const [medias, setMedias] = useState<any>([]);
  const [selectedChannel, setSelectedChannel] = useState<any>();
  const [lang, setLang] = useState<string>("de");
  const [channelLanguage, setChannelLanguage] = useState<any>();
  const [url, setUrl] = useState<string>("");
  const items = [
    {
      name: "de",
      id: "de",
    },
    {
      name: "en",
      id: "en",
    },
    {
      name: "tr",
      id: "tr",
    },
    {
      name: "fr",
      id: "fr",
    },
  ];

  const filterLanguagePerChannel = () => {
    const findChannel = props.channels?.filter(
      (item: any) => item.code === selectedChannel,
    );

    const filteredItems = items?.filter((lang: any) =>
      findChannel[0]?.availableLanguages?.includes(lang.id),
    );
    setChannelLanguage(filteredItems);
  };

  useEffect(() => {
    filterLanguagePerChannel();
  }, [selectedChannel]);

  const langCountry: any = {
    de: "German",
    en: "English",
    tr: "Turkish",
    fr: "French",
  };

  const findTranslatedName = (channel: any) => {
    // return channel?.channelNameTranslates?.filter(
    //   (tr: any) => tr.language.toLowerCase() === i18n.language,
    // )?.[0]?.name;
    return channel?.title;
  };

  const onChangeLanguage = (lang: any) => {
    setLang(lang);
  };

  const getPreview = () => {
    const url = URLS.GET_PIM_PRODUCT_ATTRIBUTES(productData?.id);
    ServiceApi.get(url)
      .then(({data}) => {
        setAttributes(data);
        setLoadingAttributes();
      })
      .catch(() => setLoadingAttributes());
  };

  async function fetchImages(data: any) {
    let urlImg: any = [];
    await data?.forEach(async (image: any) => {
      const test = await imageSrcHandler(image.url);
      urlImg.push({src: test});
    });
    setImageUrls(urlImg);
  }

  const getMedias = () => {
    setMedias([]);
    const url = URLS.GET_PIM_PRODUCT_MEDIA_LIST_URL(productData.id, 1, 100);
    ServiceApi.get(url)
      .then(({data}) => {
        fetchImages(data?.items);
        setMedias(data.items);
        setLoadingMedias();
      })
      .catch(() => setLoadingMedias());
  };

  const getAttributeValue = (item: any, index: number) => {
    const {value} = findValue(
      item,
      attributes?.[index]?.attributes,
      item.attributeTemplateId,
      selectedChannel,
      lang,
    );
    return value;
  };
  useEffect(() => {
    if (props.isOpen) {
      setLoadingAttributes();
      setLoadingMedias();
      setSelectedChannel(props.channels?.[0].code);
      getPreview();
      getMedias();
    }
  }, [props.isOpen]);

  const findAttributeGroupTranslate = (item: any, group = false) => {
    const condition = group ? i18n.language : lang;
    const translate = item.translates?.find(
      (tr: any) => tr.language === condition,
    );
    if (translate) {
      return translate.labelTitle;
    }
    return item.translates?.[0]?.labelTitle;
  };

  const findAttributeTranslate = (item: any) => {
    const translate = item.translates?.find(
      (tr: any) => tr.language === i18n.language,
    );
    if (translate) {
      return translate?.labelTitle;
    }
    return item.translates?.[0]?.labelTitle;
  };

  const imageSrcHandler = async (src: string) => {
    //url
    let url;
    if (src?.includes("/get-file")) {
      const config: AxiosRequestConfig = {
        responseType: "blob",
        params: {withoutSubscriptionKey: true},
      };
      await ServiceApi.get(src, config).then(({data}) => {
        url = URL.createObjectURL(data);
      });
    } else {
      url = src;
    }
    return url;
  };

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggle}
      modalClassName="z-40"
      size={"xl"}
    >
      <Modal.Header className="flex items-center justify-between !text-left text-heading-3 font-bold text-gray-800">
        <div className="flex items-center space-x-2">
          <span>
            <Text>productManagement.products.Details.productPreviewFor</Text>
          </span>
          <Dropdown>
            <Dropdown.Toggle
              as="button"
              type="button"
              className="flex min-w-[10rem] space-x-2 rounded-md bg-gray-100 p-3 text-body-base font-medium uppercase text-gray-600"
            >
              <span>
                {findTranslatedName(
                  props.channels.filter(
                    (channel: any) => channel?.code === selectedChannel,
                  )?.[0],
                )}{" "}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="mt-2 min-w-fit p-2">
              <ul className="w-max text-gray-700">
                {props.channels.map((channel: any) => (
                  <li
                    key={channel?.code}
                    onClick={() => {
                      // getPreview(channel.code);
                      // getMedias(channel.code);
                      setSelectedChannel(channel.code);
                      // setLoadingAttributes();
                      // setLoadingMedias();
                    }}
                    className={`h6 px-4 py-2 font-medium first:mb-1 ${
                      channel.code === selectedChannel &&
                      "bg-primary-light text-primary "
                    } cursor-pointer rounded-lg text-body-base hover:bg-primary-light hover:text-primary`}
                  >
                    {findTranslatedName(channel)}
                  </li>
                ))}
              </ul>
            </Dropdown.Menu>
          </Dropdown>
          {!!channelLanguage?.length && (
            <Dropdown>
              <Dropdown.Toggle
                as="button"
                type="button"
                className="flex space-x-2 rounded-md bg-gray-100 p-3 text-body-base font-medium uppercase text-gray-600"
              >
                <img src={flagCountry[lang]} />
                <span>{lang}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="mt-2 min-w-fit p-2">
                <ul className="w-[104px] text-gray-700">
                  {channelLanguage?.map((e: any) => (
                    <li
                      key={e.id}
                      onClick={() => onChangeLanguage(e.id)}
                      className={`h6 px-4 py-2 font-medium first:mb-1 ${
                        e.id === lang && "bg-primary-light text-primary "
                      } cursor-pointer rounded-lg text-body-base hover:bg-primary-light hover:text-primary`}
                    >
                      {langCountry[e.name]}
                    </li>
                  ))}
                </ul>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
        <Button size="sm" onClick={props.toggle} variant="white">
          <Icon className="mr-2" icon={"times"} />
          <Text>global.buttons.close</Text>
        </Button>
      </Modal.Header>
      <Modal.Body className="min-h-[300px] space-y-4 overflow-auto">
        <>
          <div className="space-y-4 rounded border border-dashed border-gray-400 p-6">
            <div className="flex flex-col gap-4 [&>div:nth-child(even)]:bg-gray-100">
              <p className="mb-4 text-heading-3 text-gray-600">
                <Text>productManagement.products.Details.basicInfo</Text>
              </p>
              <div className="grid grid-cols-12 gap-4 p-2">
                <span className="col-span-3">
                  <Text>productManagement.products.allProducts.sku</Text>{" "}
                </span>
                <span className="col-span-9">{productData?.originalName}</span>
              </div>
              <div className="grid grid-cols-12 gap-4 p-2">
                <span className="col-span-3">
                  <Text>productManagement.products.Details.price</Text>{" "}
                </span>
                <span className="col-span-9">
                  {productData?.price?.currencySymbo}{" "}
                  {checkPriceAvailability(productData?.price?.amount)}
                </span>
              </div>
              <div className="grid grid-cols-12 gap-4 p-2">
                <span className="col-span-3">
                  <Text>productManagement.products.Details.articleNumber</Text>{" "}
                </span>
                <span className="col-span-9">
                  #{productData?.articleNumber}
                </span>
              </div>
              {/* Supplier */}
              {productData?.supplierInfo && (
                <div className="grid grid-cols-12 gap-4 p-2">
                  <span className="col-span-3">
                    <Text>productManagement.products.Details.supplier</Text>{" "}
                  </span>
                  <span className="col-span-9">
                    {productData?.supplierInfo?.name}
                  </span>
                </div>
              )}
              {/* Program */}
              {productData?.programInfo && (
                <div className="grid grid-cols-12 gap-4 p-2">
                  <span className="col-span-3">
                    <Text>productManagement.products.Details.program</Text>{" "}
                  </span>
                  <span className="col-span-9">
                    {productData?.programInfo?.title}
                  </span>
                </div>
              )}
              {/* Brand */}
              {productData?.brandInfo && (
                <div className="grid grid-cols-12 gap-4 p-2">
                  <span className="col-span-3">
                    <Text>productManagement.products.Details.brand</Text>{" "}
                  </span>
                  <span className="col-span-9">
                    {productData?.brandInfo?.name}
                  </span>
                </div>
              )}
              {/* locations */}
              {productData?.locations?.length > 0 && (
                <div className="grid grid-cols-12 gap-4 p-2">
                  <span className="col-span-3">
                    <Text>productManagement.products.Details.locationsTab</Text>{" "}
                  </span>
                  <span className="col-span-9">
                    {productData?.locations?.map(
                      (location: any, index: any) => {
                        return (
                          <span className="mr-2">{location.storeName} ,</span>
                        );
                      },
                    )}
                  </span>
                </div>
              )}
            </div>
          </div>
        </>
        {loadingAttributes &&
          [1, 2, 3, 4].map((key: number) => <Skeleton.List key={key} />)}
        {!loadingAttributes &&
          attributes?.map((attr: any, index: number) => {
            return (
              <>
                <div className="space-y-4 rounded border border-dashed border-gray-400 p-6">
                  <p className="mb-8 text-heading-3 text-gray-600">
                    {findAttributeGroupTranslate(attr, true)}
                  </p>
                  {attr?.attributes?.map((item: any) => {
                    return (
                      <>
                        {
                          <div className="flex flex-col gap-4 [&>div:nth-child(even)]:bg-gray-100">
                            <div className="grid grid-cols-12 gap-4 p-2">
                              <span className="col-span-3">
                                {findAttributeTranslate(item)}
                                {item.isRequired && (
                                  <Icon
                                    icon="star"
                                    className="ml-2 text-danger"
                                    size={"1x"}
                                  />
                                )}
                              </span>
                              <span className="text-body col-span-9 leading-5">
                                {getAttributeValue(item, index) || (
                                  <span className="text-body-2 text-gray-600">
                                    <Text>
                                      productManagement.products.Details.noData
                                    </Text>
                                  </span>
                                )}
                              </span>
                            </div>
                          </div>
                        }
                      </>
                    );
                  })}
                </div>
              </>
            );
          })}
        {loadingMedias &&
          [1, 2, 3, 4].map((key: number) => <Skeleton.List key={key} />)}
        {!loadingMedias && (
          <div className="space-y-4 rounded border border-dashed border-gray-400 p-6">
            <p className="mb-4 text-heading-3 text-gray-600">
              <Text>productManagement.products.Details.mediaTab</Text>
            </p>
            <div className="flex flex-wrap justify-center gap-2">
              {medias?.map((image: any, index: any) => {
                return (
                  <LazyImage
                    isDynamic
                    // cover={image.tags.length === 0 ? false : true}
                    key={image?.url}
                    src={image?.url}
                    className="w-[325px] max-w-lg flex-none cursor-pointer bg-gray-100"
                    imageClassName="w-72 object-contain"
                    onClick={() => {
                      setSelectedImage(index);
                      setToggler();
                    }}
                  />
                );
              })}
            </div>
            <Lightbox
              open={toggler}
              index={selectedImage}
              close={() => {
                document.body.style.overflow = "visible";
                setToggler();
              }}
              slides={imageUrls}
              plugins={[Zoom, Slideshow]}
            />
          </div>
        )}
      </Modal.Body>
      {/* End */}
    </Modal>
  );
}
