import {
  ComponentProps,
  createContext,
  ElementType,
  ReactNode,
  useContext,
  useMemo,
  useRef,
} from "react";
import { Transition, TransitionStatus } from "react-transition-group";
import { twMerge } from "tailwind-merge";
import { asProps, sizeType, TransitionClasses } from "types";
import Portal from "./Portal";
type DrawerMainProps = {
  className?: string;
  children: ReactNode;
};
type DrawerContextType = {
  size: sizeType;
  transitionState: TransitionStatus;
};
type DrawerProps = {
  isOpen: boolean;
  toggle: () => void;
  size?: sizeType;
};

const DrawerContext = createContext({} as DrawerContextType);
function Drawer<E extends ElementType = "div">({
  as,
  className = "",
  children,
  isOpen,
  toggle,
  size = null,
  ...props
}: asProps<E> & ComponentProps<E> & DrawerMainProps & DrawerProps) {
  const divRef = useRef<HTMLDivElement>(null);
  const Component = as || "div";
  const transitionClasses: TransitionClasses = {
    entering: "active opacity-100 pointer-events-auto",
    entered: "active opacity-100 pointer-events-auto",
    exiting: "opacity-0 pointer-events-none",
    exited: "opacity-0 pointer-events-none",
    unmounted: "",
  };
  return (
    <Portal>
      <Transition nodeRef={divRef} in={isOpen} timeout={300} unmountOnExit>
        {state => (
          <Component
            ref={divRef}
            className={twMerge(
              "drawer group fixed inset-0 z-[90] !m-0 flex bg-black/20 transition-opacity",
              transitionClasses[state],
              className,
            )}
            {...props}
          >
            <button
              type="button"
              onClick={toggle}
              className="h-full min-w-[5%] flex-1 cursor-default opacity-0"
            />
            <DrawerContext.Provider value={{ size, transitionState: state }}>
              {children}
            </DrawerContext.Provider>
          </Component>
        )}
      </Transition>
    </Portal>
  );
}
function DrawerMenu({ children, className = "" }: DrawerMainProps) {
  const { size, transitionState } = useContext(DrawerContext);
  const transitionClasses: TransitionClasses = {
    entering: "translate-x-0",
    entered: "translate-x-0",
    exiting: "translate-x-full",
    exited: "translate-x-full",
    unmounted: "",
  };
  const handleSizeWidth = useMemo(() => {
    if (!size) return "w-[31rem]";
    const sizes = {
      sm: "",
      md: "w-[900px]",
      lg: "w-[50vw]",
      xl: "w-[75vw]",
    };
    return sizes[size];
  }, [size]);
  return (
    <div
      className={twMerge(
        "flex h-full max-w-full flex-col rounded-l bg-white transition-[transform,width]",
        handleSizeWidth,
        transitionClasses[transitionState],
        className,
      )}
    >
      {children}
    </div>
  );
}
function DrawerHeader({ className = "", children }: DrawerMainProps) {
  return (
    <div className={`border-gray border-b px-6 py-3 text-center ${className}`}>
      {children}
    </div>
  );
}
function DrawerBody({ className = "", children }: DrawerMainProps) {
  return (
    <div className={`flex-1 overflow-auto px-6 py-3 ${className}`}>
      {children}
    </div>
  );
}
function DrawerFooter({ className = "", children }: DrawerMainProps) {
  return (
    <div className={`border-gray w-full border-t px-6 py-3 ${className}`}>
      {children}
    </div>
  );
}
Drawer.Menu = DrawerMenu;
Drawer.Header = DrawerHeader;
Drawer.Body = DrawerBody;
Drawer.Footer = DrawerFooter;
export default Drawer;
