export const userList = {
  inviteUser: "Invite User",
  addUser: "Add User",
  general: "General",
  group: "Group",
  store: "Store",
  stores: "Stores",
  authenticationSetting: "Authentication setting",
  basicInfo: "Basic Info",
  address: "Address",
  usersGroup: "Users Group",
  firstName: "First Name",
  lastName: "last Name",
  gender: "gender",
  dateOfBirth: "Date Of Birth",
  personalNumber: "Personal Number",
  phoneNumber: "Phone Number",
  mobileNumber: "Mobile Number",
  emailAddress: "Email Address",
  active: "Active",
  setDefault: "Set as default",
  providerName: "Provider name",
  userName: "User Name",
  activeLogin: "Active Login",
  generalDesc: "First Name, Last Name, ...",
  location: "Location",
  locationDesc: "User location",
  userGroup: "User Group",
  userGroupDesc: "Select User Group",
  review: "Review",
  reviewDesc: "Finalize information",
  searchUser: "Search user",
  search: "Search",
  addAsAnAdmin: "Add as an admin",
  userType: "User Type",
  admin: "Admin",
  user: "User",
  //.....Provider name
  local: "Local",
  add: "ADD",
  //.....Employee
  employee: "Employee",
  assignEmployee: "Assign Employee",
  searchEmployee: "Search Employee",
  selectEmployee: "Select Employee",
  info: "Info",
  detailsEmployee: "Details Employee",
  password: "Password",
  reEnterpassword: "Re-Enter Password",
  passwordDesc: "Set Password",
  setPasswordTitle: "Set Password (Optional)",
  setPassword: "Set Password",
  hasPassword: "has been set",
  hasntPassword: "generate by system",
  salesChannels: "Sales Channels",
  asAdminstrator: "As adminstrator",
};
