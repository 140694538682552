import {
  Button,
  Icon,
  Skeleton,
  Status,
  Text,
  Wrapper,
  NoItems,
} from "components";
import { dateFormats } from "constant";
import { useConverter, useToggleState } from "hooks";
import { cloneDeep } from "lodash";
import { useContext, useEffect, useState } from "react";
import { Fragment } from "react/jsx-runtime";
import { ServiceApi, URLS } from "services";
import { PimProduct } from "types";
import { PimContext } from "../..";
import AvailabilityStatusForm from "./AvailabilityStatusForm";
import RemoveConfirm from "./RemoveConfirm";

export default function AvailabilityStatus() {
  const { productData, loading } = useContext(PimContext);
  const { convertDate } = useConverter();
  const [openAddStatus, toggleAddStatus] = useToggleState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [data, setData] = useState<PimProduct.AvailableStatusItem[]>([]);
  const [editId, setEditId] = useState<string | null>(null);
  const [removeId, setRemoveId] = useState<string | null>(null);
  const hasLoading = !!loading || loadingStatus;
  const clearEditId = () => {
    setEditId(null);
  };
  const clearRemoveId = () => {
    setRemoveId(null);
  };
  const handleRemoveItem = (id: string) => {
    setData(p => {
      const data = cloneDeep(p);
      const index = data.findIndex(e => e.id === id);
      const has = index !== -1;
      if (has) data.splice(index, 1);
      return data;
    });
  };
  const getData = () => {
    if (!productData?.id) return;
    const url = URLS.ADD_PIM_AVAILABILITY_STATUS(productData.id);
    setLoadingStatus(true);
    ServiceApi.get(url)
      .then(({ data }) => {
        setData(data);
      })
      .finally(() => {
        setLoadingStatus(false);
      });
  };
  useEffect(getData, [productData]);
  return (
    <Fragment>
      <Wrapper>
        <Wrapper.Body className="flex flex-col gap-4">
          {hasLoading ? (
            <Skeleton.AvailableStatus />
          ) : (
            <Fragment>
              <>
                {data && data?.length ? (
                  <>
                    {data?.map(e => (
                      <Fragment key={e.id}>
                        <div className="flex w-full items-center gap-2 rounded-2xl border bg-white p-4 shadow-nested">
                          <Status.Availability id={e.availabilityStatus} />
                          <p className="flex-1 text-body-base font-normal text-gray-600">
                            {e.isDefault ? (
                              <Text>
                                productManagement.products.Details.availabilityStatus.defaultStatus
                              </Text>
                            ) : e.validationPeriod ? (
                              <Text
                                fromDate={convertDate(
                                  e.fromDate,
                                  dateFormats.onlyDate,
                                ) || "--"}
                                toDate={
                                  convertDate(e.toDate, dateFormats.onlyDate) ||
                                  "--"
                                }
                              >
                                productManagement.products.Details.availabilityStatus.statusDate
                              </Text>
                            ) : null}
                          </p>
                          {!e.isDefault && (
                            <Button
                              type="button"
                              variant="danger"
                              light
                              className="!px-4"
                              onClick={() => setRemoveId(e.id)}
                            >
                              <Icon icon="trash" className="size-4" />
                            </Button>
                          )}
                          <Button
                            type="button"
                            variant="primary"
                            light
                            className="!px-4"
                            onClick={() => setEditId(e.id)}
                          >
                            <Icon icon="edit" className="size-4" />
                          </Button>
                        </div>
                        <AvailabilityStatusForm
                          isOpen={editId === e.id}
                          toggle={clearEditId}
                          availableStatus={e}
                          onSubmitted={getData}
                          statusList={data}
                        />
                      </Fragment>
                    ))}
                  </>
                ) : (
                  <NoItems />
                )}
              </>
            </Fragment>
          )}
          <div>
            <Button
              type="button"
              variant="primary"
              light
              onClick={toggleAddStatus}
              disabled={hasLoading}
            >
              <Icon icon="add" className="size-4" />{" "}
              <Text>
                productManagement.products.Details.availabilityStatus.addStatusButton
              </Text>
            </Button>
          </div>
        </Wrapper.Body>
      </Wrapper>
      {!hasLoading && (
        <Fragment>
          <RemoveConfirm
            id={removeId}
            isOpen={!!removeId}
            toggle={clearRemoveId}
            onRemoved={handleRemoveItem}
          />
          <AvailabilityStatusForm
            isOpen={openAddStatus}
            toggle={toggleAddStatus}
            onSubmitted={getData}
            statusList={data}
          />
        </Fragment>
      )}
    </Fragment>
  );
}
