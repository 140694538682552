import { NotificationManager } from "common/notifications";
import {
  Button,
  Form,
  InputGroup,
  SelectLang,
  Text,
  Wrapper,
} from "components";
import { flagCountry, rules } from "constant";
import { useDataState, useSelector, useTitleTranslations, useToggleState } from "hooks";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ServiceApi, URLS } from "services";
import { setproductCategories } from "store/productCategories";

const General = () => {
  const productCategory = useSelector((s) => s.productCategories);
  const dispatch = useDispatch();
  const companySetting = useSelector((s) => s.companySetting);
  const [lang, setLang] = useState<string>("de");
  const [data, setData, setBaseData, isChanged] = useDataState<any>({});
  const [loading, setLoading] = useToggleState();
  const [langItems, setLangItems] = useState([]);
  const { checkTranslations } = useTitleTranslations();

  useEffect(() => {
    // setData(productCategory);
    setBaseData(productCategory);
    setLang(companySetting.defaultContentLanguage);
  }, []);

  useEffect(() => {
    let arr: any = [];
    companySetting?.contentLanguages?.forEach((item: any) => {
      arr.push({ id: item, name: item });
    });
    setLangItems(arr);
  }, [companySetting]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeLanguage = (lang: any) => {
    setLang(lang);
  };

  const onChangeHandler = (value: any, key: string) => {
    const isAvailable = data?.translates?.filter(
      (tr: any) => tr.language.toLowerCase() === lang
    );
    let clone = JSON.parse(JSON.stringify(data.translates));
    if (isAvailable.length === 0) {
      clone.push({
        [key]: value,
        language: lang,
        description: "",
      });
    } else {
      data?.translates?.map((tr: any, index: number) => {
        if (tr.language.toLowerCase() === lang) {
          clone[index][key] = value;
        }
      });
    }
    setData({ ...data, translates: clone });
    dispatch(setproductCategories({ ...data, translates: clone }));
  };

  const findCategoryByLang = () => {
    const obj = data?.translates?.filter(
      (item: any) => item.language.toLowerCase() === lang
    )[0];
    return obj;
  };

  const submitHandler = () => {
    const validTranslates = checkTranslations(data.translates);
    if (validTranslates) {
      setLoading();
      const url = URLS.EDIT_CATEGORY_TRANSLATES(data.productCategoryId);
      const body = {
        code: data.code,
        pictureThumbnailUrl: data.thumbnailUrl,
        pictureUrl: data.pictureUrl,
        translates: validTranslates,
        sortOrder: data.sortOrder,
      };
      ServiceApi.put(url, body)
        .then((data) => {
          NotificationManager.success(
            "global.toast.add-msg",
            "global.toast.add-title"
          );
          setBaseData(productCategory);
          setLoading();
        })
        .catch(() => setLoading());
    }
  };

  return (
    <Form onSubmit={submitHandler} className="space-y-4">
      <Wrapper>
        <Wrapper.Header className="flex justify-between items-center text-heading-2 font-semibold">
          <span>
            <Text>productManagement.masterData.productCategory.basicInfo</Text>
          </span>
          <SelectLang
            value={lang}
            items={langItems}
            onChangeLanguage={onChangeLanguage}
          />
        </Wrapper.Header>
        <Wrapper.Body className="grid grid-cols-2 gap-9">
          <div className="col-span-full">
            <InputGroup
              value={data?.translates && findCategoryByLang()?.labelTitle}
              setValue={(e) => onChangeHandler(e, "labelTitle")}
              label="productManagement.masterData.productCategory.categoryName"
              flag={flagCountry[lang]}
              rules={rules.required}
            />
          </div>
          <InputGroup
            value={data?.code}
            label="productManagement.masterData.productCategory.categoryCode"
            disabled
            readOnly
          />
          <InputGroup
            value={data?.sortOrder}
            label="productManagement.masterData.productCategory.sortOrder"
            setValue={(sortOrder) => {
              dispatch(setproductCategories({ ...data, sortOrder }));
              setData((p: any) => ({ ...p, sortOrder }));
            }}
            type="number"
            rules={rules.order}
          />
          <div className="col-span-full">
            <InputGroup
              as={"short-textarea"}
              setValue={(e) => onChangeHandler(e, "description")}
              value={data?.translates && findCategoryByLang()?.description}
              label="productManagement.masterData.productCategory.shortDescription"
            />
          </div>
        </Wrapper.Body>
      </Wrapper>
      <Wrapper className="sticky bottom-0 shadow-card">
        <Wrapper.Body className="flex items-center justify-end gap-4">
          <Button
            type="submit"
            variant="primary"
            loading={loading}
            disabled={!isChanged}
          >
            <Text>global.buttons.saveChanges</Text>
          </Button>
        </Wrapper.Body>
      </Wrapper>
    </Form>
  );
};

export default General;
