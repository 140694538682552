import { LevelUnitList } from "compositions";
import { PriceCalculationContext } from "../price-calculation-tab";
import { useContext } from "react";

export default function Units() {
  const { refId, level, refCode, refTitle, setLevelData } =
    useContext(PriceCalculationContext);
  return (
    <LevelUnitList
      refId={refId}
      level={level}
      refCode={refCode}
      refTitle={refTitle}
      setLevelData={setLevelData}
    />
  );
}
