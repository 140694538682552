import SalesPrice from "pages/price-engine/price-templates/sales-price";
import {SalesTemplateDetails} from "pages/price-engine/price-templates/sales-price/details";
const SalesPriceRoute = [
  {
    path: "",
    element: <SalesPrice />,
  },
  {
    path: ":salesTemplateId",
    element: <SalesTemplateDetails />,
  },
];

export default SalesPriceRoute;
