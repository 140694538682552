import CatalogSalesPrice from "pages/price-engine/price-templates/catalog-sales-price";
import {CatalogSalesTemplateDetails} from "pages/price-engine/price-templates/catalog-sales-price/details";

const CatalogSalesPriceRoute = [
  {
    path: "",
    element: <CatalogSalesPrice />,
  },
  {
    path: ":salesTemplateId",
    element: <CatalogSalesTemplateDetails />,
  },
];

export default CatalogSalesPriceRoute;
