import {Button, Icon, Wrapper} from "components";
import {SimulationContext} from "pages/price-engine/price-simulator";
import program from "pages/product-managment/master-data/suppliers/details/tabs/program";
import {useContext} from "react";

type Props = {
  dataKey: string;
};

export default function Info({dataKey}: Props) {
  const {simulateData, setSimulateData} = useContext(SimulationContext);
  return (
    <Wrapper>
      <Wrapper.Body className="flex items-center justify-between space-y-2 rounded-lg !p-4 shadow-card">
        <div className="space-y-2">
          <h6>{simulateData[dataKey].name}</h6>
          <p className="text-body-2">#{simulateData[dataKey].code}</p>
        </div>
        <Button
          light
          variant={"danger"}
          size="sm"
          onClick={() => {
            if (dataKey === "supplier") {
              setSimulateData((p: any) => ({
                ...p,
                supplier: null,
                program: null,
              }));
            } else {
              setSimulateData((p: any) => ({...p, [dataKey]: null}));
            }
          }}
        >
          <Icon icon="trash" />
        </Button>
      </Wrapper.Body>
    </Wrapper>
  );
}
