export const channelMessage = {
  list: {
    aggregateId: "Aggregat-ID",
    channel: "Kanal",
    createdAt: "Erstellt am",
    updatedAt: "Aktualisiert am",
    detailsBtn: "Details",
    errorCodes: {
      "001": "Konfiguration nicht gefunden",
      "002": "Konfiguration ist falsch",
      "003": "Synchronisationsfehler",
    },
  },
  details: {
    title: "Nachrichtendetails",
    closeBtn: "Schließen",
    dataTab: "Daten",
    outDataTab: "Ausgehende Daten",
    json: "JSON",
    noData: "Keine Daten",
  },
  filter: {
    filters: "Filter",
    resetFilterBtn: "Filter zurücksetzen",
    closeBtn: "Schließen",
    status: "Status",
    channel: "Kanal",
    dateRange: "Datumsbereich",
    from: "Von",
    to: "Bis",
  },
  steps: {
    requirementGathering: "Anforderungserfassung",
    configurationValidation: "Konfigurationsvalidierung",
    dataTransmission: "Datenübertragung",
    acknowledgeReceipt: "Empfang bestätigen",
  },
};