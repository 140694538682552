export const measurementUnits = {
    addMeasurement: "Add Measurement",
    general: "General",
    basicInfo: "Basic Info",
    units: "Units",
    name: "Name",
    description: "Description",
    code: "Code",
    symbol: "Symbol",
    addUnit: "Add Unit",
    editUnit: "Edit Unit",
    unitsList: "Units List",
    default: "Default",
    defaultUnit: "Default Unit",
    defaultUnitCode: "Default Unit Code",
    defaultUnitName: "Default Unit Name",
    defaultUnitDescription: "Default Unit Description",
    defaultUnitSymbol: "Default Unit Symbol"

}