import {useConverter} from "hooks";
import {Fragment} from "react";
import {SalesService} from "types";

type Details = SalesService.SellOffProductBasicInfoDto &
  SalesService.SellOffProductStatusInfoDto;

type Props = {
  item: any;
  className?: string;
  inDetails?: boolean;
};
const PriceTemplate = ({item, className, inDetails = false}: Props) => {
  const {convertAmount} = useConverter();
  const hasDiscount = !!item?.currentDiscount;
  const hasCurrentPrice = !!item?.currentPrice || !!item.currentPhasePrice;
  return (
    <div className={`${className} text-left`}>
      <div
        className={`font-semibold  ${hasDiscount ? "text-gray-500 line-through " : "text-primary-active"}`}
      >
        {convertAmount(item?.pimProduct?.vk1Price?.amount)}
      </div>
      {hasDiscount && (
        <Fragment>
          <p className="text-danger">{item?.currentDiscount}%</p>
        </Fragment>
      )}
      {hasCurrentPrice && (
        <div>
          {inDetails ? (
            <h2 className={"font-semibold text-primary-active"}>
              {convertAmount(item?.currentPhasePrice)}
            </h2>
          ) : (
            <h6 className={"font-semibold text-primary-active"}>
              {convertAmount(item?.currentPrice)}
            </h6>
          )}
        </div>
      )}
    </div>
  );
};

export default PriceTemplate;
