import { Button, Icon, LoadingSvg, Text, TotalItems } from "components";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";
import { useTranslate } from "../hooks";

type inputValue = string | null;

type searchBoxProps = {
  value?: inputValue;
  placeholder?: string;
  variant?: "white" | "gray";
  onSubmit: (val: inputValue) => void;
  onFilter?: () => void;
  resetFilter?: () => void;
  disabled?: boolean;
  totalItems?: any;
  className?: string;
  formClassName?: any;
  onReload?: () => void;
  loading?: boolean;
};

export default function SearchBox({
  value,
  placeholder = "global.placeholders.search",
  onSubmit = () => { },
  onFilter,
  resetFilter,
  variant = "white",
  disabled = false,
  totalItems,
  className,
  formClassName = "",
  onReload,
  loading,
}: searchBoxProps) {
  const translate = useTranslate();
  const activeVariant = useMemo(() => {
    const variants: any = {
      white: "bg-white",
      gray: "bg-gray-100",
    };
    return variants[variant];
  }, [variant]);
  const [valueInput, setValueInput] = useState<string | null | undefined>(
    value,
  );

  useEffect(() => {
    setValueInput(value);
  }, [value]);

  const clearValue = () => {
    setValueInput("");
    onSubmit("");
  };
  const submit = (e: any) => {
    e.preventDefault();
    onSubmit(valueInput || null);
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValueInput(e.target.value);
  };
  return (
    <div
      className={twMerge(
        "w-full flex-wrap gap-1 space-y-1 lg:flex lg:items-center lg:justify-between",
        className,
      )}
    >
      <div className="lg:flex-center flex items-center gap-2">
        <div
          className={twMerge(
            "input-wrapper flex items-center gap-2 rounded px-2 py-1 text-body-2 font-medium ring-gray-500 ring-offset-1 transition-shadow focus-within:ring-1 md:w-[15rem] lg:w-[19rem]",
            activeVariant,
            formClassName,
          )}
        >
          <button type="button" onClick={submit}>
            <Icon icon="search" />
          </button>
          <input
            aria-label="cy-search-input"
            value={valueInput ?? ""}
            onChange={handleChange}
            onKeyUp={e => e?.key === "Enter" && submit(e)}
            placeholder={translate(placeholder) ?? ""}
            className="h-8 flex-1 px-2"
            disabled={disabled}
            data-lang-map={placeholder}
          />
        </div>
        <button
          aria-label="cy-search-button"
          type="button"
          onClick={submit}
          className={`search-btn flex-center h-[38px] w-[38px] cursor-pointer rounded-md ${activeVariant}`}
        >
          <Icon size="1x" className="text-primary" icon={"search"} />
        </button>
        {onReload && (
          <button
            type="button"
            onClick={onReload}
            className={`flex-center h-[38px] w-[38px] cursor-pointer rounded-md ${activeVariant}`}
          >
            <Icon size="1x" className="text-primary" icon={"refresh"} />
          </button>
        )}
        {loading ? (
          <LoadingSvg size="sm" />
        ) : (
          <TotalItems totalItems={totalItems} />
        )}
      </div>
      {onFilter && (
        <div className="space-x-2">
          {resetFilter && (
            <Button
              variant={"danger"}
              light
              size="sm"
              onClick={() => {
                clearValue();
                resetFilter();
              }}
              disabled={disabled}
            >
              <Icon icon="times-circle" className="mr-1" />
              <Text>global.buttons.resetFilters</Text>
            </Button>
          )}
          <Button
            variant="white"
            type="button"
            size="sm"
            onClick={() => onFilter()}
            disabled={disabled}
          >
            <Text>global.buttons.filters</Text>
          </Button>
        </div>
      )}
    </div>
  );
}
