import {configureStore} from "@reduxjs/toolkit";
import profile from "./profile";
import loading from "./loading";
import permissions from "./permissions";
import selloff from "./sell-off";
import productFmilies from "./productFmilies";
import productCategories from "./productCategories";
import companySetting from "./companySetting";
import channels from "./channels";
const store = configureStore({
  reducer: {
    profile,
    loading,
    permissions,
    selloff,
    productFmilies,
    productCategories,
    companySetting,
    channels,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export default store;
