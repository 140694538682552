import PurchasePrice from "pages/price-engine/price-templates/purchase-price";
import {PurchaseTemplateDetails} from "pages/price-engine/price-templates/purchase-price/details";
const PurchasePriceRoute = [
  {
    path: "",
    element: <PurchasePrice />,
  },
  {
    path: ":purchaseTemplateId",
    element: <PurchaseTemplateDetails />,
  },
];

export default PurchasePriceRoute;
