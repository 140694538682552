import { Button, Drawer, Icon, NoItems, SearchBox, Skeleton, Text } from "components";
import { useConverter, useToggleState } from "hooks";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { ServiceApi, URLS } from "services";

type PropsType = {
  isOpen: boolean;
  toggle: () => void;
  id?: string;
};

type Price = {
  currency: string;
  amount: number;
  currencySymbol: string;
};

type ListItemType = {
  id: string;
  channelTitle: string;
  channelCode: string;
  channelIlnNumber: string;
  vk1Price: Price,
  vk2Price: Price,
  uvpPrice: Price,
  originalVk1Price: Price,
  hasOverwrittenPrice: boolean
}

export default function ChannelPrice(props: PropsType) {
  const [loading, setLoading] = useToggleState();
  const [otherChannels, setOtherChannels] = useState<ListItemType[]>();
  const [thisChannel, setThisChannel] = useState<ListItemType>();
  const { productId } = useParams();
  const [searchChannelCode, setSearchChannelCode] = useState('');
  let [searchParams] = useSearchParams();
  const { convertAmount } = useConverter();

  const getData = () => {
    setLoading();
    let params = { channelCode: searchChannelCode };
    const url = URLS.GET_PUBLISHED_PRODUCT_PRICE_PER_CHANNEL(productId);
    const config = { params };
    ServiceApi.get(url, { ...config })
      .then(({ data }) => {
        const channelCode = searchParams.get('channelCode');
        const otherChannels = data.filter((item: ListItemType) => item.channelCode !== channelCode);
        setOtherChannels(otherChannels);
        const thisChannel = data.filter((item: ListItemType) => item.channelCode === channelCode)[0];
        setThisChannel(thisChannel)
      })
      .catch(() => {
        setOtherChannels([]);
        setThisChannel(undefined);
      })
      .finally(() => {
        setLoading();
      });
  };

  useEffect(() => {
    props.isOpen && getData();
  }, [props.isOpen, searchChannelCode, searchParams]);

  return (
    <Drawer isOpen={props.isOpen} toggle={props.toggle}>
      <Drawer.Menu>
        <Drawer.Header className="flex items-center justify-between text-heading-2 font-semibold">
          <Text>productManagement.publishedProduct.Details.priceInOtherhannels</Text>
          <Button
            variant={"light"}
            size="sm"
            className="flex items-center"
            onClick={props.toggle}
          >
            <span>
              <Text>global.buttons.close</Text>
            </span>
            <Icon icon="close" className="ml-2" />
          </Button>
        </Drawer.Header>
        <Drawer.Body className="space-y-6">
          <SearchBox
            variant="gray"
            value={searchChannelCode}
            onSubmit={(value) => setSearchChannelCode(value || '')}
            disabled={loading}
            placeholder="productManagement.publishedProduct.Details.searchChannelByCode"
            onReload={getData}
          />
          {loading ? (
            <>
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </>
          ) : (
            <>
              {thisChannel &&
                <>
                  <h6 className="text-heading-6">
                    <Text>productManagement.publishedProduct.Details.thisChannel</Text>:
                  </h6>
                  <div key={thisChannel?.id} className="border border-dashed border-gray-600 p-5 rounded-xl flex justify-between items-center">
                    <div className="space-y-2">
                      <h5 className="text-heading-5 font-semibold">
                        {thisChannel?.channelTitle}
                      </h5>
                      <p className="text-body-base font-medium"># {thisChannel?.channelCode}</p>
                      <p className="text-body-base font-medium">ILN #{thisChannel?.channelIlnNumber}</p>
                    </div>
                    <div className="space-y-2 text-body-base text-gray-700">
                      <p className="font-medium">
                        <Text>productManagement.publishedProduct.Details.priceInThisChannel</Text>:
                      </p>
                      <div className="space-y-1">
                        <div>
                          <Text>vk2</Text> :{" "}
                          <span className={`${!!thisChannel?.uvpPrice?.amount ? 'line-through text-gray-600' : "text-primary-active"}`}>
                            {convertAmount(thisChannel?.vk2Price?.amount)}
                          </span>
                        </div>
                        <div>
                          <Text>productManagement.publishedProduct.Details.uvpPrice</Text> :{" "}
                          {!!thisChannel?.uvpPrice?.amount ? `${convertAmount(thisChannel?.uvpPrice?.amount)}` : '--'}
                        </div>
                        <div>
                          <Text>vk1</Text> :{" "}
                          <span className={`${!!thisChannel?.hasOverwrittenPrice ? 'line-through text-gray-600' : "text-primary-active"}`}>
                            {convertAmount(thisChannel?.originalVk1Price?.amount)}
                          </span>
                        </div>
                        {thisChannel?.hasOverwrittenPrice && (
                          <div>
                            <Text>
                              productManagement.publishedProduct.Details.overWritedPrice
                            </Text>{" "}
                            :{" "}
                            {convertAmount(thisChannel?.vk1Price?.amount)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              }

              {otherChannels && Object.keys(otherChannels).length !== 0 && otherChannels?.length !== 0 && (
                <>
                  {searchChannelCode ?
                    <h6 className="text-heading-6">
                      <Text>productManagement.publishedProduct.Details.results</Text>:
                    </h6> :
                    <h6 className="text-heading-6">
                      <Text>productManagement.publishedProduct.Details.otherChannels</Text>:
                    </h6>
                  }
                  <div className="flex flex-col flex-1 space-y-10">
                    <div className="flex-1 space-y-3">
                      {otherChannels?.map(item => {
                        return (
                          <div key={item.id} className="shadow-high p-5 rounded-xl flex justify-between items-center">
                            <div className="space-y-2">
                              <h5 className="text-heading-5 font-semibold">
                                {item.channelTitle}
                              </h5>
                              <p className="text-body-base font-medium"># {item.channelCode}</p>
                              <p className="text-body-base font-medium">ILN #{item.channelIlnNumber}</p>
                            </div>
                            <div className="space-y-2 text-body-base ">
                              <p className="tfont-medium">
                                <Text>productManagement.publishedProduct.Details.priceInThisChannel</Text>:
                              </p>
                              <div className="space-y-1 text-gray-700">
                                <div>
                                  <Text>vk2</Text> :{" "}
                                  <span className={`${!!item?.uvpPrice?.amount ? 'line-through text-gray-600' : "text-primary-active"}`}>
                                    {convertAmount(item?.vk2Price?.amount)}
                                  </span>
                                </div>
                                <div>
                                  <Text>productManagement.publishedProduct.Details.uvpPrice</Text> :{" "}
                                  {!!item?.uvpPrice?.amount ? `${convertAmount(item?.uvpPrice?.amount)}` : '--'}
                                </div>
                                <div>
                                  <Text>vk1</Text> :{" "}
                                  <span className={`${!!item?.hasOverwrittenPrice ? 'line-through text-gray-600' : "text-primary-active"}`}>
                                    {convertAmount(item?.originalVk1Price?.amount)}
                                  </span>
                                </div>
                                {item?.hasOverwrittenPrice && (
                                  <div>
                                    <Text>
                                      productManagement.publishedProduct.Details.overWritedPrice
                                    </Text>{" "}
                                    :{" "}
                                    {convertAmount(item?.vk1Price?.amount)}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })
                      }
                    </div>
                  </div>
                </>
              )}
              {(!thisChannel && otherChannels && Object.keys(otherChannels).length === 0) &&
                <div className="flex w-full flex-col items-center space-y-4">
                  <NoItems />
                </div>
              }
            </>
          )}
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-between">
          <Button size="sm" onClick={props.toggle} variant="white">
            <Icon className="mr-2" icon={"times"} />
            <Text>global.buttons.close</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
