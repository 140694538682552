const Locations = {
  GET_LOCATIONS_URL: "productservice/api/locations",
  POST_LOCATIONS_URL: "productservice/api/locations",
  GET_DETAILS_LOCATIONS_URL: (id) => `productservice/api/locations/${id}`,
  DELETE_LOCATIONS_URL: (id) => `productservice/api/locations/${id}`,
  PUT_DETAILS_UPDATE_LOCATIONS_URL: (id) =>
    `productservice/api/locations/${id}`,
};

export default Locations;
