import {NotificationManager} from "common/notifications";
import {
  Breadcrumb,
  Button,
  DeleteModal,
  Footer,
  Form,
  HandleName,
  Icon,
  Skeleton,
  Tabs,
  Text,
  WithPermission,
  Wrapper,
} from "components";
import {
  useDataState,
  useSelector,
  useTitleTranslations,
  useToggleState,
} from "hooks";
import {
  Fragment,
  createContext,
  createElement,
  useEffect,
  useState,
} from "react";
import {withPermission} from "hoc";
import {useParams} from "react-router";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {ServiceApi, URLS} from "services";
import {ObjectUtils} from "utils";
//..... tabs
import {AttributesTypes} from "utils";
import AttributesType from "./tabs/attributes-type";
import AttributesTypeDate from "./tabs/attributes-type-date";
import AttributesTypeMeasurement from "./tabs/attributes-type-measurement";
import AttributesTypeSelect from "./tabs/attributes-type-select";
import AttributesTypeYesNo from "./tabs/attributes-type-yesno";
import Channels from "./tabs/channels";
import General from "./tabs/general";

export const AttributesContext = createContext({});

function Details() {
  const controller = new AbortController();
  const {attributeId} = useParams();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [langItems, setLangItems] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [data, setData, setBaseData, isChanged] = useDataState<any>({});
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const companySetting = useSelector(s => s.companySetting);
  const navigate = useNavigate();
  const [selected, setSelected] = useState<any>();
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState(false);
  const [langSelected, setLangSelected] = useState("de");
  const {checkTranslations} = useTitleTranslations();

  const tabType = (e: any) => {
    switch (e) {
      case 20:
        return AttributesTypeYesNo;
      case 30:
        return AttributesTypeSelect;
      case 40:
        return AttributesTypeDate;
      case 50:
        return AttributesTypeDate;
      case 60:
        return AttributesTypeDate;
      case 70:
        return AttributesTypeMeasurement;
      case 80:
        return AttributesType;
      default:
        return AttributesType;
    }
  };

  const tabs = [
    {
      label: "productManagement.masterData.attributes.general",
      component: General,
    },
    {
      label: "productManagement.masterData.attributes.attributeType",
      component: tabType(data?.type),
    },
    {
      label: "productManagement.masterData.attributes.channels",
      component: Channels,
    },
  ];

  const getData = () => {
    setLoading(true);
    let arr: any = [];
    companySetting?.contentLanguages?.forEach((item: any) => {
      arr.push({id: item?.toLocaleLowerCase(), name: item});
    });
    const url = URLS.GET_ATTRIBUTES_DETAILS_URLS(attributeId);
    let newData: any;
    ServiceApi.get(url, {signal: controller.signal})
      .then(({data}) => {
        newData = JSON.parse(JSON.stringify(data));
        newData &&
          newData.translates?.forEach((i: any, index: number) => {
            if (
              i?.language?.toLocaleLowerCase() ===
              companySetting?.defaultContentLanguage?.toLocaleLowerCase()
            ) {
              setName(i.labelTitle);
              setDescription(i.description);
            }
          });
        if (newData?.translates?.length !== arr?.length) {
          var props = ["id"];
          var result = arr
            ?.filter(function (i: any) {
              return !newData?.translates?.some(function (a: any) {
                return (
                  i?.id?.toLocaleLowerCase() ===
                  a?.language?.toLocaleLowerCase()
                );
              });
            })
            .map(function (o: any) {
              return props?.reduce(function (newo: any, language: any) {
                newo["id"] = o[language];
                return newo;
              }, {});
            });

          result?.forEach((item: any) => {
            newData?.translates.push({
              language: item.id,
              labelTitle: "",
              description: "",
            });
          });
        }
        // }

        if (newData?.type === 20 && newData?.items?.length === 0) {
          let translatesNew: any = [];
          companySetting?.contentLanguages?.forEach((item: any) => {
            translatesNew.push({labelTitle: "", language: item});
          });
          let bodyTrue = {
            code: "true",
            icon: "",
            setAsDefault: newData?.defaultValue === "true" ? true : false,
            translates: translatesNew,
          };
          let bodyFalse = {
            code: "false",
            icon: "",
            setAsDefault: newData?.defaultValue === "false" ? true : false,
            translates: translatesNew,
          };
          newData.items = [bodyTrue, bodyFalse];
        } else if (newData?.type === 20 && newData?.items?.length !== 0) {
          let arr: any = [];
          arr = [
            {id: "true", name: "Positive"},
            {id: "false", name: "Negative"},
          ];
          if (newData?.items?.length !== arr?.length) {
            var props = ["id"];
            var result = arr
              ?.filter(function (i: any) {
                return !newData?.items?.some(function (a: any) {
                  return (
                    i?.id?.toLocaleLowerCase() === a?.code?.toLocaleLowerCase()
                  );
                });
              })
              .map(function (o: any) {
                return props?.reduce(function (newo: any, code: any) {
                  newo["id"] = o[code];
                  return newo;
                }, {});
              });

            result?.forEach((res: any) => {
              newData?.items?.push({
                code: res.id,
                icon: "",
                translates: [],
              });
            });
          }

          let arrLang: any = [];
          companySetting?.dashboardLanguages?.forEach((item: any) => {
            arrLang.push({id: item?.toLocaleLowerCase(), name: item});
          });

          newData?.items?.forEach((item: any) => {
            if (item?.translates?.length !== arrLang?.length) {
              var props = ["id"];
              var result = arrLang
                ?.filter(function (i: any) {
                  return !item?.translates?.some(function (a: any) {
                    return (
                      i?.id?.toLocaleLowerCase() ===
                      a?.language?.toLocaleLowerCase()
                    );
                  });
                })
                .map(function (o: any) {
                  return props?.reduce(function (newo: any, language: any) {
                    newo["id"] = o[language];
                    return newo;
                  }, {});
                });

              result?.forEach((res: any) => {
                item?.translates?.push({
                  language: res.id,
                  labelTitle: "",
                });
              });
            }
          });
        }
        setBaseData(newData);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
    setLangSelected(
      companySetting?.defaultContentLanguage?.toLocaleLowerCase(),
    );
    setLangItems(arr);
  };

  const submit = () => {
    setSubmitLoading(true);
    if (data.type === 20) {
      let url = URLS.ADD_ITEM_SELECT_ATTRIBUTES_URLS(attributeId);
      data?.items?.forEach((item: any) => {
        const isThereTransalte =
          item?.translates?.filter((item: any) => item?.labelTitle).length > 0;
        const translations = isThereTransalte
          ? checkTranslations(item?.translates)
          : [];
        if (item?.code?.toLocaleLowerCase() === "true") {
          let bodyTrue = {
            attributeItemId: item?.id,
            code: item?.code,
            icon: "",
            setAsDefault: data.defaultValue === "true" ? true : false,
            translates: translations || [],
          };
          if (bodyTrue?.translates?.length !== 0) {
            url = item?.id
              ? URLS.UPDATE_ITEM_YESNO_ATTRIBUTES_URLS(attributeId)
              : URLS.ADD_ITEM_SELECT_ATTRIBUTES_URLS(attributeId);
            item?.id
              ? ServiceApi.put(url, bodyTrue)
              : ServiceApi.post(url, bodyTrue);
          }
        } else {
          let bodyFalse = {
            attributeItemId: item?.id,
            code: item?.code,
            icon: "",
            setAsDefault: data.defaultValue !== "true" ? true : false,
            translates: translations || [],
          };
          if (bodyFalse?.translates?.length !== 0) {
            url = item?.id
              ? URLS.UPDATE_ITEM_YESNO_ATTRIBUTES_URLS(attributeId)
              : URLS.ADD_ITEM_SELECT_ATTRIBUTES_URLS(attributeId);
            item?.id
              ? ServiceApi.put(url, bodyFalse)
              : ServiceApi.post(url, bodyFalse);
          }
        }
      });
    }
    if (data.type === 70) {
      data.measurementFamilyUnitCode = data.defaultValue;
    }
    const url = URLS.UPDATE_ATTRIBUTES_URLS(attributeId);
    const validTranslates = checkTranslations(data.translates);
    if (validTranslates) {
      data.translates = validTranslates;
      const body = {...data};
      ServiceApi.put(url, body)
        .then(() => {
          getData();
          NotificationManager.success(
            "global.toast.edit-msg",
            "global.toast.edit-title",
          );
          getData();
        })
        .catch(() => {})
        .finally(() => {
          setSubmitLoading(false);
        });
    } else setSubmitLoading(false);
  };
  useEffect(() => {
    getData();
    return () => {
      controller.abort();
    };
  }, [companySetting]); // eslint-disable-line react-hooks/exhaustive-deps

  const submitDelete = (id: any) => {
    setLoadingButton(true);
    const url = URLS.DELETE_ATTRIBUTES_URLS(id);
    ServiceApi.delete(url)
      .then(async () => {
        setLoadingButton(false);
        toggleDeleteModal();
        navigate(-1);
      })
      .catch(() => setLoadingButton(false));
  };

  return (
    <Tabs activeKey={tabs[0]?.label}>
      <Form onSubmit={submit} className="relative space-y-6">
        <div className="flex items-center">
          <div className="flex-1">
            <Breadcrumb />
          </div>
          <WithPermission permissions={["PS_UpdateAttributeTemplate"]}>
            {loading ? (
              <Skeleton.Button />
            ) : (
              <>
                {!(data?.provider?.name?.toLowerCase() === "built-in") ? (
                  <Button
                    type="button"
                    variant="white"
                    textColor="danger"
                    onClick={() => {
                      setSelected({
                        id: attributeId,
                        name: (
                          <HandleName
                            translations={data?.translates}
                            code={data?.code}
                            keyName="labelTitle"
                          />
                        ),
                      });
                      toggleDeleteModal();
                    }}
                  >
                    <Icon className="mr-2" icon={"trash"} />
                    <Text>global.buttons.delete</Text>
                  </Button>
                ) : (
                  <></>
                )}
              </>
            )}
          </WithPermission>
        </div>
        <Wrapper>
          <Wrapper.Body className="flex flex-col items-start gap-5 lg:flex-row">
            <div className="space-y-4 lg:flex-1">
              {loading ? (
                <Fragment>
                  <Skeleton.Input />
                  <Skeleton.Input />
                </Fragment>
              ) : (
                <Fragment>
                  <div>
                    <h5 className="text-heading-5 font-semibold text-gray-800">
                      {
                        <HandleName
                          translations={data?.translates}
                          code={data?.code}
                          keyName="labelTitle"
                        />
                      }
                    </h5>
                  </div>
                  <div>
                    <span className="pr-2 text-body-2 font-medium text-gray-500">
                      {data.code}
                    </span>
                    <span className="border-l-2 border-gray-300 px-2 text-body-2  text-gray-500">
                      {AttributesTypes(data.type)}
                    </span>
                  </div>
                </Fragment>
              )}
            </div>
          </Wrapper.Body>
          <Wrapper.Footer className="!py-0">
            <Tabs.ButtonGroup>
              {tabs.map(e => (
                <Tabs.Button key={e.label} eventKey={e.label}>
                  <Text>{e.label}</Text>
                </Tabs.Button>
              ))}
            </Tabs.ButtonGroup>
          </Wrapper.Footer>
        </Wrapper>
        {loading && (
          <Wrapper>
            <Wrapper.Body className="grid grid-cols-1 gap-4 lg:grid-cols-2">
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Wrapper.Body>
          </Wrapper>
        )}
        {tabs.map(e => (
          <Tabs.Item key={e.label} eventKey={e.label}>
            <AttributesContext.Provider
              value={{
                attributeId: attributeId,
                attributesData: data,
                description: description,
                name: name,
                langItems: langItems,
                loading: loading,
                setLangItems: setLangItems,
                setName: setName,
                setDescription: setDescription,
                setAttributesData: setData,
                getData: getData,
                setLoading: setLoading,
                langSelected: langSelected,
                setLangSelected: setLangSelected,
              }}
            >
              {!ObjectUtils.isEmpty(data) && createElement(e.component)}
            </AttributesContext.Provider>
          </Tabs.Item>
        ))}
        <WithPermission permissions={["PS_UpdateAttributeTemplate"]}>
          <Footer show={!isChanged}>
            <Button
              as={Link}
              to={"/productManagement/master-data/attributes"}
              variant="white"
            >
              <Text>global.buttons.discard</Text>
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={!isChanged}
              loading={submitLoading}
            >
              <Text>global.buttons.saveChanges</Text>
            </Button>
          </Footer>
        </WithPermission>
      </Form>
      <DeleteModal
        isOpen={isOpenDeleteModal}
        toggle={toggleDeleteModal}
        selected={selected}
        loading={loadingButton}
        onConfirm={submitDelete}
      />
    </Tabs>
  );
}

export default withPermission(Details, ["PS_GetAttributeTemplate"]);
