export const commissionReports = {
  createDate: "Created Date",
  sortBy: "Sort By",
  newest: "Newest",
  oldest: "Oldest",
  employeeCode: "Employee Code",
  customerCode: "Customer Number",
  channelCode: "ChannelCode",
  orderNumber: "Order Number",
  supplierCode: "Supplier Code",
  lineItemType: "Lineitem Type",
  saleCommissionAmountType: "Sale Commission Amount/Type",
  secondEmployeeCode: "Second Employee Code",
  totalSalePrice: "Totla Sale Price",
  quantity: "Quantity",
  details: "Details",
  customerNumber: "customerNumber",
  orderDate: "orderDate",
  orderVersion: "orderVersion",
  pimArticleNumber: "pimArticleNumber",
  productGroupCode: "productGroupCode",
  commissionsource: "commissionsource",
  description: "description",
};
