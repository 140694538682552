import {NotificationManager} from "common/notifications";
import {
  AddressViewer,
  Breadcrumb,
  Button,
  Footer,
  Form,
  Icon,
  ImageUploader,
  LazyImage,
  Skeleton,
  Tabs,
  Text,
  Toggler,
  WithPermission,
  Wrapper,
} from "components";
import {useDataState, useToggleState, usePermission} from "hooks";
import isEmpty from "lodash/isEmpty";
import {
  Fragment,
  createContext,
  createElement,
  useEffect,
  useState,
} from "react";
import {useParams} from "react-router";
import {Link} from "react-router-dom";
import {ServiceApi} from "services";
import STORE from "services/urls/store";
import General from "./General";
import Location from "./Location";
import OpeningHours from "./OpeningHours";
import Departments from "./Departments";
import Setting from "./Setting";
import {assetTypes} from "constant";
import {ValidateAddress} from "utils";
import {useTranslate} from "hooks";
import AddDepartment from "./departments-drawer/Add";

export const StoreContext = createContext({});

export default function StoreDetails() {
  const updatePermission = usePermission("AS_UpdateStore");
  const controller = new AbortController();
  const [isOpen, toggle] = useToggleState(false);
  const {storeId} = useParams();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [headerData, setHeaderData] = useState({});
  const [data, setData, setBaseData, isChanged] = useDataState({});
  const [addressErrorMsg, setAddressErrorMasg] = useState("");
  const translate = useTranslate();
  const [isOpenAdd, toggleAdd] = useToggleState();

  const tabs = [
    {
      label: "company.stores.general",
      component: General,
    },
    {
      label: "company.stores.location",
      component: Location,
    },
    {
      label: "company.stores.openingHours",
      component: OpeningHours,
    },
    {
      label: "company.stores.Departments",
      component: Departments,
      permission: ["AS_GetDepartment"],
    },
    {
      label: "company.stores.setting",
      component: Setting,
    },
  ];
  const getData = () => {
    const url = STORE.GET_STORE_URL(storeId);
    ServiceApi.get(url, {signal: controller.signal})
      .then(res => {
        if (res) {
          const result = {...res?.data};
          result.legalEntityId = res?.data.legalEntityId ?? null;
          result.openingHours ??= [];
          result.salesSettings ??= {
            iwofurnSalesCalculationFactor: 0,
            iwofurnRoundingMethod: "",
          };
          setBaseData(result);
          setHeaderData(result);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submit = image => {
    setAddressErrorMasg("");
    setSubmitLoading(true);
    if (!data.address) submitRequest(image);
    else
      ValidateAddress(data.address).then(res => {
        if (!res?.selected && !res.suggested) {
          setAddressErrorMasg(translate("global.locations.invalidAddress"));
          setSubmitLoading(false);
          return;
        }
        if (res.selected) {
          setData({
            ...data,
            address: {
              ...res.selected,
              name: data.address.name,
              number: data.address.number,
            },
          });
        }
        if (res.suggested) {
          setData({
            ...data,
            address: {
              ...res.suggested,
              name: data.address.name,
              number: data.address.number,
            },
          });
          setAddressErrorMasg(translate("global.locations.changedAddress"));
          setSubmitLoading(false);
          return NotificationManager.error(
            translate("global.locations.changedAddress"),
            translate("addressValidation"),
          );
        }
        submitRequest(image);
      });
  };

  const submitRequest = image => {
    const url = STORE.UPDATE_STORE_URL(storeId);
    let body;
    if (image) {
      body = {...data, mainPhotoUrl: image};
    } else {
      body = {...data};
    }
    ServiceApi.put(url, body)
      .then(() => {
        setBaseData(body);
        setHeaderData(body);
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  useEffect(() => {
    getData();
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <Tabs activeKey={tabs[0].label}>
      <Form onSubmit={submit} className="relative space-y-6">
        <Breadcrumb />
        <Wrapper>
          <Wrapper.Body className="flex flex-col items-start gap-5 lg:flex-row">
            <ImageUploader
              isOpen={isOpen}
              toggle={toggle}
              image={headerData.mainPhotoUrl}
              onUpload={data => {
                submit(data.url);
              }}
              type={assetTypes.Company}
            />
            <LazyImage
              onClick={() => {
                if (updatePermission) {
                  toggle();
                }
              }}
              src={headerData.mainPhotoUrl}
              alt={headerData.title}
              editor
              isDynamic
              className="aspect-image w-full cursor-pointer lg:aspect-auto lg:h-40 lg:w-80"
            />
            <div className="space-y-4 lg:flex-1">
              {loading ? (
                <Fragment>
                  <Skeleton.Input />
                  <Skeleton.Input />
                </Fragment>
              ) : (
                <Fragment>
                  <h6 className="text-heading-6 text-gray-800">
                    {headerData.title}
                  </h6>
                  <div className="flex items-center gap-2 pr-2 text-body-2 font-medium text-gray-500">
                    <div>
                      <Text>company.stores.code</Text>:
                    </div>
                    <div>{headerData.code}</div>
                  </div>
                  <AddressViewer address={headerData.address} />
                  {[headerData.openingHours?.at(0)].filter(Boolean).map(e => (
                    <p key={e.code} className="text-body-2 text-gray-500">
                      <Icon icon="clock" /> {e.code} {e.value}
                    </p>
                  ))}
                </Fragment>
              )}
            </div>
            <div>
              <Toggler
                label="company.stores.active"
                value={data.isActive}
                setValue={isActive => setData(p => ({...p, isActive}))}
                disabled={!updatePermission}
              />
            </div>
          </Wrapper.Body>
          <Wrapper.Footer className="!py-0">
            <Tabs.ButtonGroup>
              {tabs.map(e => (
                <Tabs.Button key={e.label} eventKey={e.label}>
                  <Text>{e.label}</Text>
                </Tabs.Button>
              ))}
            </Tabs.ButtonGroup>
          </Wrapper.Footer>
        </Wrapper>
        {loading && (
          <Wrapper>
            <Wrapper.Body className="grid grid-cols-1 gap-4 lg:grid-cols-2">
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Wrapper.Body>
          </Wrapper>
        )}
        {tabs.map(e => (
          <Tabs.Item key={e.label} eventKey={e.label}>
            <StoreContext.Provider
              value={{
                storeData: data,
                setStoreData: setData,
                addressErrorMsg,
                toggleAdd,
              }}
            >
              {!isEmpty(data) && createElement(e.component)}
            </StoreContext.Provider>
          </Tabs.Item>
        ))}
        <WithPermission permissions={["AS_UpdateStore"]}>
          <Footer show={!isChanged}>
            <Button as={Link} to={-1} variant="white">
              <Text>global.buttons.discard</Text>
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={!isChanged}
              loading={submitLoading}
            >
              <Text>global.buttons.saveChanges</Text>
            </Button>
          </Footer>
        </WithPermission>
      </Form>
    </Tabs>
  );
}
