import {dateFormats} from "constant";
import {useTranslation} from "react-i18next";
type convertAmountType = (val: string | number | null | undefined) => string;
type convertDateType = (
  val: string | number | null | undefined | Date,
  opt?: Intl.DateTimeFormatOptions,
) => string;
export default function useConverter() {
  const {i18n} = useTranslation();
  const locale = i18n.language;
  const convertAmount: convertAmountType = value => {
    const num = Number(value);
    if (isNaN(num)) return "";
    return Number(num).toLocaleString(locale, {
      style: "currency",
      currency: "EUR",
    });
  };
  const convertDate: convertDateType = (value, opt = dateFormats.full) => {
    const langs: any = {
      de: "de-DE",
      en: "en-US",
    };
    if (!value || value.toString().includes("0001")) return "";
    const date = new Date(value);
    return date.toLocaleString(langs[i18n.language], opt);
  };
  return {convertAmount, convertDate};
}
