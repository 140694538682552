import {NotificationManager} from "common/notifications";
import {
  Breadcrumb,
  Button,
  DeleteModal,
  Footer,
  Form,
  Icon,
  InputGroup,
  Select,
  Skeleton,
  Text,
  WithPermission,
  Wrapper,
} from "components";
import {useDataState, usePermission, useToggleState} from "hooks";
import {Fragment, useEffect, useState} from "react";

import {config, rules} from "constant";
import {useParams} from "react-router";
import {Link, useNavigate} from "react-router-dom";
import {ServiceApi, URLS} from "services";

export default function Details() {
  const updatePermission = usePermission("PS_UpdateLocation");
  const {placeId} = useParams();
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [storeItems, setStoreItems] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [data, setData, setBaseData, isChanged] = useDataState<any>({});
  const [selected, setSelected] = useState<any>();
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState(false);
  const navigate = useNavigate();

  const [params] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  });

  const submitDelete = (id: any) => {
    setLoadingButton(true);
    const url = URLS.DELETE_LOCATIONS_URL(id);
    ServiceApi.delete(url)
      .then(({data}) => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title",
        );
        setLoadingButton(false);
        toggleDeleteModal();
        navigate(-1);
      })
      .catch(() => setLoadingButton(false));
  };

  const getData = () => {
    setLoading(true);
    const url = URLS.GET_DETAILS_LOCATIONS_URL(placeId);
    ServiceApi.get(url)
      .then(({data}) => {
        setBaseData(data);
      })
      .finally(() => {
        setLoading(false);
      });
    const urlStore = URLS.ADD_STORE_URL;
    const config = {params};
    ServiceApi.get(urlStore, config)
      .then(({data}) => {
        let items: any = [];
        data?.items?.forEach((item: any) => {
          items.push({id: item.code, name: item.title});
        });
        setStoreItems(items);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submit = () => {
    setSubmitLoading(true);
    const url = URLS.PUT_DETAILS_UPDATE_LOCATIONS_URL(placeId);
    const body = {...data};
    ServiceApi.put(url, body)
      .then(() => {
        setBaseData(data);
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };
  useEffect(getData, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSetData = (key: any) => {
    return (value: any) => setData((p: any) => ({...p, [key]: value}));
  };

  return (
    <Fragment>
      <Form onSubmit={submit} className="relative space-y-6">
        <div className="flex items-center">
          <div className="flex-1">
            <Breadcrumb />
          </div>
          <WithPermission permissions={["PS_UpdateLocation"]}>
            {loading ? (
              <Skeleton.Button />
            ) : (
              <Button
                type="button"
                variant="white"
                textColor="danger"
                onClick={() => {
                  setSelected(data);
                  toggleDeleteModal();
                }}
              >
                <Icon className="mr-2" icon={"trash"} />
                <Text>global.buttons.delete</Text>
              </Button>
            )}
          </WithPermission>
        </div>
        {loading ? (
          <Wrapper>
            <Wrapper.Body className="grid grid-cols-1 gap-4 lg:grid-cols-2">
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Wrapper.Body>
          </Wrapper>
        ) : (
          <Wrapper>
            <Wrapper.Header>
              <h2 className="text-heading-2 font-semibold text-gray-800">
                <Text>salesManagment.salesArea.locations.location</Text>
              </h2>
            </Wrapper.Header>
            <Wrapper.Body className="grid grid-cols-1 gap-4 lg:grid-cols-2">
              <div className="space-y-8">
                <Select
                  label="salesManagment.salesArea.locations.store"
                  value={data.storeCode}
                  items={storeItems}
                  disabled
                  key="readOnly"
                />
                <InputGroup
                  label="salesManagment.salesArea.locations.code"
                  value={data.code}
                  disabled
                />
              </div>
              <div className="space-y-8">
                <InputGroup
                  label="salesManagment.salesArea.locations.name"
                  value={data.name}
                  setValue={handleSetData("name")}
                  rules={rules.required}
                  disabled={!updatePermission}
                />
              </div>
            </Wrapper.Body>
          </Wrapper>
        )}
        <WithPermission permissions={["PS_UpdateLocation"]}>
          <Footer show={!isChanged}>
            <Button as={Link} to={-1} variant="white">
              <Text>global.buttons.discard</Text>
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={!isChanged}
              loading={submitLoading}
            >
              <Text>global.buttons.saveChanges</Text>
            </Button>
          </Footer>
        </WithPermission>
      </Form>
      <DeleteModal
        isOpen={isOpenDeleteModal}
        toggle={toggleDeleteModal}
        selected={{id: placeId, name: selected?.name}}
        loading={loadingButton}
        onConfirm={submitDelete}
      />
    </Fragment>
  );
}
