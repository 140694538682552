import {createElement} from "react";
import {Breadcrumb, Text, Tabs} from "components";
import {Tenant, Brand, Product, Program, Supplier, Channel} from "./levels";

export default function CalculationLevel() {
  const calculationLevel = [
    {label: "priceEngine.calculationLevel.tenant", value: 1, component: Tenant},
    {
      label: "priceEngine.calculationLevel.channel",
      value: 8,
      component: Channel,
    },
    {
      label: "priceEngine.calculationLevel.supplier",
      value: 16,
      component: Supplier,
    },
    {label: "priceEngine.calculationLevel.brand", value: 21, component: Brand},
    {
      label: "priceEngine.calculationLevel.program",
      value: 25,
      component: Program,
    },
    {
      label: "priceEngine.calculationLevel.product",
      value: 28,
      component: Product,
    },
  ];

  return (
    <div className="space-y-8">
      <Breadcrumb />
      <Tabs activeKey={calculationLevel[0].label}>
        <div className="rounded-xl bg-white px-4">
          <Tabs.ButtonGroup>
            {calculationLevel.map((e: any) => (
              <Tabs.Button
                key={e.label}
                eventKey={e.label}
                permission={e.permission}
              >
                <Text>{e.label}</Text>
              </Tabs.Button>
            ))}
          </Tabs.ButtonGroup>
        </div>
        {calculationLevel.map((e: any) => (
          <Tabs.Item key={e.label} eventKey={e.label}>
            {createElement(e.component)}
          </Tabs.Item>
        ))}
      </Tabs>
    </div>
  );
}
