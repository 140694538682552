import {
  Breadcrumb,
  Button,
  Icon,
  Pagination,
  Skeleton,
  Text,
  WithPermission,
} from "components";
import {withPermission} from "hoc";
import {config} from "constant";
import {useEffect, useState} from "react";
import {ServiceApi} from "services";
import Position from "./Position";
import PositionForm from "./PositionForm";
import {useToggleState, usePermission} from "hooks";
import {cloneDeep} from "lodash";

export type productType = {
  productId: string;
  price: {
    currency: "euro";
    amount: number;
    currencySymbol: "€";
  };
  articleNumber: string;
  url: string;
  title: string;
  discountAmount: string;
  discountPercent: string;
  tag: string;
};
export type componentItemType = {
  id: string;
  title: string;
  subTitle: string;
  url: string;
  link: null | string;
  order: null | number;
  isVisible: boolean;
  linkType: number;
  products: productType[];
};
export type componentType = {
  id: string;
  title: string;
  items: null | componentItemType[];
};
export type positionType = {
  id: string;
  tenantId: string;
  title: string;
  description: null | string;
  code: string;
  isActive: boolean;
  componentType: number;
  components: componentType[];
};
type dataType = {
  items: positionType[];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
};
function Positions() {
  const addPermission = usePermission("PS_CreatePosition");
  const controller = new AbortController();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({} as dataType);
  const [params, setParams] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  });
  const [isAddPositionOpen, toggleAddPosition] = useToggleState(false);
  const handleRemovePosition = (id: string) => {
    setData(p => {
      const data = cloneDeep(p);
      const index = data.items.findIndex(e => e.id === id);
      data.items.splice(index, 1);
      return data;
    });
  };
  const getData = () => {
    setLoading(true);
    const url = "/productservice/api/positions";
    const config = {params};
    ServiceApi.get(url, {...config, signal: controller.signal})
      .then(res => {
        if (res) {
          setData(res?.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getData();
    return () => {
      controller.abort();
    };
  }, [params]);
  return (
    <section className="space-y-6">
      <div className="flex items-center">
        <div className="flex-1">
          <Breadcrumb />
        </div>
        {addPermission && (
          <Button onClick={toggleAddPosition}>
            <Icon icon="plus" />{" "}
            <Text>applications.positions.addPositionButton</Text>
          </Button>
        )}
      </div>
      {loading ? (
        <div className="space-y-4">
          <Skeleton.ImageList />
          <Skeleton.ImageList />
          <Skeleton.ImageList />
        </div>
      ) : (
        <div className="space-y-4">
          {data.items?.map(e => (
            <Position
              key={e.id}
              position={e}
              onRemoved={handleRemovePosition}
            />
          ))}
        </div>
      )}
      {addPermission && (
        <button
          type="button"
          className="h4 block w-full rounded border border-dashed border-primary bg-primary-light p-6"
          onClick={toggleAddPosition}
        >
          <Icon icon="plus" className="text-primary" />{" "}
          <span className="text-gray-800">
            <Text>applications.positions.addPositionButton</Text>
          </span>
        </button>
      )}
      <Pagination
        pageNumber={params.pageNumber}
        pageSize={params.pageSize}
        totalItems={data.totalItems}
        totalPages={data.totalPages}
        setActivePage={page => setParams(p => ({...p, pageNumber: +page}))}
        onPageSizeChange={(pageSize: number) =>
          setParams(p => ({...p, pageSize}))
        }
      />
      <PositionForm
        isOpen={isAddPositionOpen}
        toggle={toggleAddPosition}
        onSubmitted={getData}
      />
    </section>
  );
}

export default withPermission(Positions, ["PS_GetPosition"]);
