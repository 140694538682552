import {
  Breadcrumb,
  Button,
  Icon,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Table,
  Text,
  WithPermission,
  Status,
} from "components";
import {config} from "constant";
import {withPermission} from "hoc";
import {usePermission} from "hooks";
import {useEffect, useState} from "react";
import {Link, useSearchParams} from "react-router-dom";
import {ServiceApi, URLS} from "services";

type SupplierItems = {
  code: string;
  createdBy: string;
  createdDate: string;
  description: string;
  isRemoved: boolean;
  supplierId: string;
  name: string;
  iln: string;
  tenantId: string;
  phoneNumber: string;
  logo: any;
  supplierType: number;
};

type ListType = {
  items?: SupplierItems[];
  pageNumber?: number;
  pageSize?: number;
  totalItems?: number;
  totalPages?: number;
};

function SuppliersList() {
  const supplierType = [
    {
      label: "All",
      value: "",
    },
    {
      label: "TradingGoods",
      value: 0,
    },
    {
      label: "ConsumableGoods",
      value: 1,
    },
  ];

  const controller = new AbortController();
  const [data, setData] = useState<ListType>({});
  const [loadingList, setLoadingList] = useState(true);
  const [searchParams] = useSearchParams();
  //original filter data for reset
  const originalFilterData: any = {
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
    supplierType: null,
  };

  const [params, setParams] = useSearchParams();
  const [filterType, setType] = useState("");

  const handleSetParams = (key: string) => {
    return (value: any) => {
      setParams(prev => {
        value !== null && value !== ""
          ? key === "pageNumber" && value === 1
            ? prev.delete(key)
            : prev.set(key, value)
          : prev.delete(key);
        key !== "pageNumber" && prev.delete("pageNumber");
        return prev;
      });
    };
  };

  const getData = () => {
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url = URLS.GET_SUPPLIERS;
    const config = {params};
    setLoadingList(true);
    ServiceApi.get(url, {...config, signal: controller.signal})
      .then(({data}) => {
        setData(data);
      })
      .catch(() => {
        setData({});
      })
      .finally(() => {
        setLoadingList(false);
      });
  };
  useEffect(() => {
    getData();
    return () => {
      controller.abort();
    };
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDetailsPermission = usePermission("PS_GetSupplier");

  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
        <WithPermission permissions={["PS_CreateSupplier"]}>
          {loadingList ? (
            <Skeleton.Button />
          ) : (
            <Button as={Link} to="new" aria-label="cy-create-supplier">
              <Icon className="mr-2" icon={"plus"} />
              <Text>productManagement.masterData.suppliers.addSupplier</Text>
            </Button>
          )}
        </WithPermission>
      </div>
      <SearchBox
        value={params?.get("keyword") || ""}
        onSubmit={handleSetParams("keyword")}
        disabled={loadingList}
        totalItems={data?.totalItems}
        onReload={getData}
        loading={loadingList}
      />
      <div className="flex items-center gap-4">
        {supplierType?.map((type: any, index) => (
          <span
            key={index}
            className={`min-w-14 cursor-pointer rounded-full p-4 text-center text-heading-6 font-semibold ${
              filterType === type.value
                ? "bg-primary text-white"
                : "bg-white text-gray-500"
            }`}
            onClick={() => {
              setParams(p => ({...p, supplierType: type.value}));
              setType(type.value);
            }}
          >
            <Text>{type.label}</Text>
          </span>
        ))}
      </div>
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data &&
          Object.keys(data).length !== 0 &&
          data?.items?.length !== 0 ? (
            <>
              <Table>
                {data?.items?.map(item => {
                  return (
                    <tr key={item.supplierId}>
                      <td className="w-[500px]">
                        <Link
                          to={getDetailsPermission ? item.supplierId : ""}
                          className="flex-center inline-flex w-fit gap-2"
                          replace={!getDetailsPermission}
                        >
                          <Table.Image
                            src={item?.logo?.thumbnailUrl}
                            alt={item.name}
                          />
                          <div className="space-y-2">
                            <h6 className="text-heading-6">{item.name}</h6>
                            {item.phoneNumber ? (
                              <p className="text-body-2 font-medium text-gray-500">
                                <Icon
                                  icon="phone-flip"
                                  className="fa-rotate-90 text-[14px] text-gray-700"
                                  size="xl"
                                />{" "}
                                {item.phoneNumber}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </Link>
                      </td>
                      <td>
                        <div className="flex flex-wrap items-center gap-1 text-body-base font-medium text-gray-700">
                          <div>
                            <Text>
                              productManagement.masterData.suppliers.code
                            </Text>
                            :<span className="ml-1">{item.code}</span>
                          </div>
                          <div className="lg:hidden">
                            <Text>
                              productManagement.masterData.suppliers.iln
                            </Text>
                            :<span className="ml-1">{item.iln}</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <Status.SupplierType id={item?.supplierType} />
                      </td>
                      <td className="hidden lg:table-cell">
                        <div className="text-body-base font-medium text-gray-700">
                          <Text>
                            productManagement.masterData.suppliers.iln
                          </Text>
                          :<span className="ml-1">{item.iln}</span>
                        </div>
                      </td>
                      <td>
                        <WithPermission permissions={["PS_GetSupplier"]}>
                          <Button
                            as={Link}
                            to={item.supplierId}
                            variant="light"
                            size="sm"
                          >
                            <Text>global.buttons.details</Text>
                          </Button>
                        </WithPermission>
                      </td>
                    </tr>
                  );
                })}
              </Table>

              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages}
                pageNumber={
                  params?.get("pageNumber")
                    ? parseInt(params?.get("pageNumber") as string)
                    : 1
                }
                pageSize={
                  params?.get("pageSize")
                    ? parseInt(params?.get("pageSize") as string)
                    : config.pageSize
                }
                setActivePage={handleSetParams("pageNumber")}
                onPageSizeChange={(e: any) =>
                  setParams(p => ({...p, pageSize: e}))
                }
              />
            </>
          ) : (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
            </div>
          )}
        </>
      )}
    </div>
  );
}
export default withPermission(SuppliersList, ["PS_GetSupplier"]);
