import {createElement, createContext, useState} from "react";
import {Tabs, Wrapper, Text} from "components";
import Units from "./tabs/units";
import Configurations from "./tabs/configurations";

type Props = {
  refId: string;
  level?: number;
  refCode?: string;
  refTitle?: string;
  levelData?: any;
  setLevelData?: any;
  toggleInfo?: any;
};

export const InfoSectionContext = createContext({} as Props);

export default function InfoSection({refId, level, refCode, refTitle}: Props) {
  const [levelData, setLevelData] = useState<any>();

  const tabs = [
    {
      label: "priceEngine.calculationLevel.units",
      component: Units,
    },
    {
      label: "priceEngine.calculationLevel.configurations",
      component: Configurations,
    },
  ];

  return (
    <Tabs activeKey={tabs[0].label}>
      <Wrapper>
        <Wrapper.Body className="flex flex-col gap-4">
          <>
            <span className="text-heading-5 font-semibold text-gray-800">
              {refTitle}
            </span>
            {refCode && <p className="text-body-2">#{refCode}</p>}
          </>
        </Wrapper.Body>
        <Wrapper.Footer className="!py-0">
          <Tabs.ButtonGroup>
            {tabs.map((e: any) => (
              <Tabs.Button
                key={e.label}
                eventKey={e.label}
                permission={e.permission}
              >
                <Text>{e.label}</Text>
              </Tabs.Button>
            ))}
          </Tabs.ButtonGroup>
        </Wrapper.Footer>
      </Wrapper>
      {tabs.map((e: any) => (
        <Tabs.Item key={e.label} eventKey={e.label}>
          <InfoSectionContext.Provider
            value={{
              refId,
              level,
              refCode,
              refTitle,
              levelData,
              setLevelData,
            }}
          >
            {createElement(e.component)}
          </InfoSectionContext.Provider>
        </Tabs.Item>
      ))}
    </Tabs>
  );
}
