import { NotificationManager } from "common/notifications";
import {
    Button,
    Footer,
    Icon,
    InputGroup,
    Toggler,
    WithPermission,
    Wrapper,
    Text
} from "components";
import { useContext } from "react";
import { rules } from "constant";
import { usePermission } from "hooks";
import { BrandContext } from "../details";
import { Link } from "react-router-dom";

export default function General() {
    const { data, setData, isChanged, submitLoading } = useContext<any>(BrandContext);
    const updatePermission = usePermission("PS_UpdateBrand");

    const handleSetData = (key: any) => {
        return (value: any) => setData((p: any) => ({ ...p, [key]: value }));
    };

    return (

        <>
            <Wrapper>
                <Wrapper.Body className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                    <div className="space-y-8">
                        <InputGroup
                            label="productManagement.masterData.brand.code"
                            value={data.code}
                            disabled
                            append={
                                <span
                                    className="input-group-text"
                                    onClick={() => {
                                        window.navigator.clipboard.writeText(data.code);
                                        NotificationManager.success(
                                            "global.toast.copy-msg",
                                            "global.toast.copy-title",
                                        );
                                    }}
                                >
                                    <Icon
                                        className="ml-6 cursor-pointer text-primary"
                                        icon={"files"}
                                    />
                                </span>
                            }
                        />
                        <InputGroup
                            label="productManagement.masterData.brand.name"
                            value={data.name}
                            setValue={handleSetData("name")}
                            rules={rules.required}
                            disabled={!updatePermission}
                        />

                        <Toggler
                            label={
                                "productManagement.masterData.brand.showBrandLogoInProductList"
                            }
                            value={data.showBrandLogoInProductList}
                            setValue={handleSetData("showBrandLogoInProductList")}
                            disabled={!updatePermission}
                        />
                    </div>
                    <div className="[&_.form-control]:h-full [&_.input-box]:h-[calc(100%-17px-1rem)] [&_.input-group]:h-full">
                        <InputGroup
                            as="textarea"
                            label="productManagement.masterData.brand.shortDescription"
                            value={data.description}
                            setValue={handleSetData("description")}
                            disabled={!updatePermission}
                        />
                    </div>
                </Wrapper.Body>
            </Wrapper>
            <WithPermission permissions={["PS_UpdateBrand"]}>
                <Footer show={!isChanged}>
                    <Button as={Link} to={-1} variant="white">
                        <Text>global.buttons.discard</Text>
                    </Button>
                    <Button
                        type="submit"
                        variant="primary"
                        disabled={!isChanged}
                        loading={submitLoading}
                    >
                        <Text>global.buttons.saveChanges</Text>
                    </Button>
                </Footer>
            </WithPermission>
        </>

    );
}
