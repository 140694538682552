export const brand = {
  addBrand: "Add Brand",
  code: "Code (Optional)",
  automaticGenerateBySystem: "if empty , Automatic Generate By System",
  name: "Name",
  title: "Title",
  description: "Description",
  shortDescription: "Short Description",
  showBrandLogoInProductList: "Show Brand Logo in Product List",
  generalTab: "General",
};
