import {Button, Drawer, Icon, RadioButton, SearchBox, Text} from "components";
import {useSearchParams} from "react-router-dom";
import {TemplateParams} from "../../../../types/template";

type drawerProps = {
  params: URLSearchParams;
  isOpen: boolean;
  toggle: () => void;
  resetFilter: () => void;
  onChange: (key: keyof TemplateParams) => (value: any) => void;
  setParams: (key: any) => void;
};

type filterListProp = {
  label: string;
  value: string;
};

const FilterDrawer = ({
  isOpen,
  params,
  toggle,
  onChange,
  resetFilter,
  setParams,
}: drawerProps) => {
  const [searchParams] = useSearchParams();

  const generateRadios = (
    label: string,
    param: string,
    list: filterListProp[],
  ) => {
    return (
      <div className="space-y-4">
        <p className="col-span-2 text-sm text-gray-800">
          <Text>{`configurations.globalSettings.templates.${label}`}</Text>
        </p>
        <div className="flex gap-4">
          {list.map((item: {label: string; value: string}) => (
            <div key={item.label}>
              <RadioButton
                label={item.label}
                value={
                  item.value
                    ? params?.get(param) === item.value
                    : !params?.get(param)
                }
                setValue={() =>
                  setParams((prev: any) => {
                    if (item.value) prev.set(param, item.value);
                    else prev.delete(param);
                    return prev;
                  })
                }
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pr-4 pt-6">
          <div className="flex items-center justify-between">
            <span className="text-heading-2 font-semibold">
              <Text>configurations.globalSettings.templates.filters</Text>
            </span>
            <div className="flex gap-x-2">
              {searchParams?.size !== 0 && (
                <Button
                  size="sm"
                  variant={"danger"}
                  light
                  onClick={() => resetFilter()}
                >
                  <Icon icon="times-circle" className="mr-1" />
                  <Text>global.buttons.resetFilters</Text>
                </Button>
              )}
              <Button size="sm" variant={"light"} onClick={toggle}>
                <Icon icon="times" className="mr-1" />
                <Text>global.buttons.close</Text>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-5 pr-4">
          <span className="col-span-2 text-sm text-gray-800">
            <Text>configurations.globalSettings.templates.keyword</Text>
          </span>
          <SearchBox
            value={params.get("keyword")}
            onSubmit={onChange("keyword")}
            className="w-full [&>*]:w-full"
            formClassName="md:w-full lg:w-full"
            variant="gray"
          />
          <div className="space-y-8">
            {generateRadios("templateType", "isCustomTemplate", [
              {label: "configurations.globalSettings.templates.all", value: ""},
              {
                label: "configurations.globalSettings.templates.default",
                value: "false",
              },
              {
                label: "configurations.globalSettings.templates.custom",
                value: "true",
              },
            ])}
            {generateRadios("createdFor", "documentTemplateType", [
              {label: "configurations.globalSettings.templates.all", value: ""},
              {
                label: "configurations.globalSettings.templates.offers",
                value: "0",
              },
              {
                label: "configurations.globalSettings.templates.orders",
                value: "1",
              },
            ])}
            {generateRadios("requiredToBeSigned", "signMandatory", [
              {label: "configurations.globalSettings.templates.all", value: ""},
              {
                label: "configurations.globalSettings.templates.active",
                value: "true",
              },
              {
                label: "configurations.globalSettings.templates.notActive",
                value: "false",
              },
            ])}
            {generateRadios("erp", "needSendToErp", [
              {label: "configurations.globalSettings.templates.all", value: ""},
              {
                label: "configurations.globalSettings.templates.active",
                value: "true",
              },
              {
                label: "configurations.globalSettings.templates.notActive",
                value: "false",
              },
            ])}
            {generateRadios("crm", "needSendToCrm", [
              {label: "configurations.globalSettings.templates.all", value: ""},
              {
                label: "configurations.globalSettings.templates.active",
                value: "true",
              },
              {
                label: "configurations.globalSettings.templates.notActive",
                value: "false",
              },
            ])}
          </div>
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
};

export default FilterDrawer;
