import {createClassNames} from "./classname";
import {useMenu, useSelector} from "hooks";
import {Icon, LazyImage, Text} from "components";
import Logo from "assets/logos/logo.svg";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {appPermissions} from "constant";

const Sidebar = ({isActive, setIsActive}) => {
  const profile = useSelector(s => s.profile);
  const [selectedMenu, setSelectedMenu] = useState("");
  const {sidebar, logo, menuHeader, drawer} = createClassNames(isActive);

  const [model, dispatchModel] = useState([]);
  const permissions = useSelector(s => s.permissions);

  const hasPermission = (...permissionsKey) => {
    if (permissionsKey.includes("withoutPermission")) return true;
    if (!permissionsKey?.length) return false;
    return !!permissionsKey?.some(permissionKey =>
      permissions?.includes(appPermissions[permissionKey]),
    );
  };

  const {menu} = useMenu();

  function addActiveKey(data) {
    return data.map(item => {
      if (item.pathname && location.pathname.includes(item.path)) {
        setSelectedMenu(item.label);
      }
      const newItem = {...item, active: location.pathname.includes(item.path)};

      if (newItem.items) {
        newItem.items = addActiveKey(newItem.items);
      }

      return newItem;
    });
  }

  function setActiveKey(data, path) {
    return data.map(item => {
      const newItem =
        item?.path === path ? {...item, active: !item.active} : item;

      if (newItem.items) {
        newItem.items = setActiveKey(newItem.items, path);
      }

      return newItem;
    });
  }

  const createPanel = item => {
    const icon = <Icon icon={item.icon} className="mr-2 h-8 flex-auto" />;
    const label = item.label && (
      <span className="menuitem-text flex h-8 w-11/12 items-center">
        <Text>{item.label}</Text>
      </span>
    );

    const activeClassName =
      item.active && !item.pathname
        ? "text-gray-200 font-medium"
        : "text-gray-500 font-normal";

    let content = (
      <Link
        to={item.pathname}
        className={` h-8 w-full pl-2 text-body-base hover:text-gray-200 ${activeClassName} ${selectedMenu === item.label && "rounded bg-[#ffffff1a] !text-gray-200"} `}
        onClick={() => {
          if (item.pathname) {
            setSelectedMenu(item.label);
          }
          const clone = setActiveKey(model, item.path);
          if (!isActive && !item.active) {
            setIsActive(true);
          }
          dispatchModel(clone);
        }}
      >
        <div className="flex items-center">
          {icon}
          {isActive && label}
          {!!item.items?.length && isActive && (
            <Icon
              icon={`chevron-right`}
              size="xs"
              className={`ml-auto text-gray-200 transition-transform ${activeClassName} ${
                item.active ? "rotate-90" : "rotate-0"
              }`}
            />
          )}
        </div>
      </Link>
    );
    return content;
  };

  useEffect(() => {
    const updatedData = addActiveKey(menu);
    dispatchModel(updatedData);
  }, []);

  const Menu = ({menu}) => {
    const renderMenu = items => {
      return (
        <>
          <ul className={`space-y-2 p-2`}>
            {items?.map((item, index) => {
              return (
                <>
                  {hasPermission(item.permission) && (
                    <li key={index} className={`flex flex-col`}>
                      {createPanel(item)}
                      {!!item.items?.length && item?.active && isActive && (
                        <div className={``}>{renderMenu(item.items)}</div>
                      )}
                    </li>
                  )}
                </>
              );
            })}
          </ul>
        </>
      );
    };

    return <nav>{renderMenu(menu)}</nav>;
  };

  return (
    <div className={sidebar} id="sidbar-b2b">
      <div className={menuHeader}>
        {isActive && profile?.companyAvatarThumbnailUrl ? (
          <LazyImage
            isDynamic
            className={`${logo} max-h-[40px]`}
            src={true ? profile?.companyAvatarThumbnailUrl : Logo}
            alt="moebelhof"
          />
        ) : (
          isActive && (
            <div
              className={`text-white ${
                profile?.companyAvatarThumbnailUrl ? "ml-1" : "ml-4"
              }`}
            >
              {profile?.companyName}
            </div>
          )
        )}
        <button
          className={drawer}
          onClick={() => {
            setIsActive(!isActive);
          }}
        >
          <Icon icon={"chevrons-left"} size="sm" />
        </button>
      </div>
      <aside className="overflow-auto ">
        <div className="mt-2">
          <Menu menu={model} />
        </div>
      </aside>
      <div className="absolute bottom-0 w-full bg-primary-active p-2 text-center text-sm text-gray-600">
        {`Version ${process.env.REACT_APP_BUILD_NUMBER}`}
      </div>
    </div>
  );
};
export default Sidebar;
