import {NotificationManager} from "common/notifications";
import {
  Button,
  Icon,
  LoadingSvg,
  NoItems,
  Skeleton,
  Status,
  Table,
  Text,
  WithPermission,
} from "components";
import {useSelector, useToggleState} from "hooks";
import {Fragment, useEffect, useRef, useState} from "react";
import {ServiceApi, URLS} from "services";
import {convertDate} from "utils";
import ChannelDetails from "./drawers/channels-details";
//Types
type Props = {
  id: string;
  loading?: boolean;
  publishUrl: (id: string) => string;
  unpublishUrl: (id: string) => string;
};

const Channels = ({
  id,
  publishUrl,
  unpublishUrl,
  loading: parentLoading,
}: Props) => {
  const controller = new AbortController();
  const channelIntervalRef = useRef<any>(null);
  const channels = useSelector(s => s.channels);
  const [loading, setLoading] = useToggleState();
  const [loadingChannel, toggleLoadingChannel] = useToggleState();
  const [publishLoading, setPublishLoading] = useToggleState();
  const [publishedList, setPublishedList] = useState<any>([]);
  const [isOpenDetails, toggleDetails] = useToggleState();
  const [channelDetails, setChannelDetails] = useState<any>({});
  const [selectedChannelCode, setSelectedChannelCode] = useState<any>({
    code: "",
    action: "",
  });
  const [currentPublishedChannel, setCurrentPublished] = useState<string[]>([]);
  const [loadingChannelStatus, setLoadingChannelStatus] = useState<string[]>(
    [],
  );

  const getChannelDetails = (channel: any) => {
    setChannelDetails(channel);
  };

  const getPublishedChannels = () => {
    const clone: any = [];
    setLoading();
    const urls = channels?.map((channel: any) =>
      ServiceApi.get(URLS.GET_CHANNEL_IS_PUBLISHED(id, channel.code), {
        signal: controller.signal,
      }),
    );
    Promise.all(urls)
      .then(([...responses]) => {
        responses.map((res: any) => clone.push(res?.data));
      })
      .finally(() => {
        setPublishedList([...clone]);
        setLoading();
      });
  };
  const removeUnpublishChannel = async (channel: any) => {
    setPublishedList((p: any) =>
      p?.filter((info: any) => info.channelCode !== channel?.code),
    );
  };

  useEffect(() => {
    return () => {
      controller.abort();
      if (channelIntervalRef.current) {
        clearInterval(channelIntervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (id) {
      if (!!channels?.length) {
        getPublishedChannels();
      }
    }
  }, [channels, id]);

  const unPublishHandler = (channel: any) => {
    setPublishLoading();
    setSelectedChannelCode({code: channel.code, action: "unpublish"});
    const url = unpublishUrl(id);
    const body = {channelCode: channel.code};
    ServiceApi.post(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.unpublish-msg",
          "global.toast.unpublish-title",
        );
        setPublishLoading();
        removeUnpublishChannel(channel);
      })
      .catch(() => setPublishLoading());
  };

  const publishHandler = (channel: any) => {
    const code = channel.code;
    setSelectedChannelCode({code, action: "publish"});
    publishToChannel({
      channelCode: code,
      legalEntityCodes: [channel?.legalEntity?.code],
    });
  };

  const createIntervalForChannels = (code: string) => {
    setCurrentPublished(p => [...p, code]);
    channelIntervalRef.current = setInterval(() => {
      ServiceApi.get(URLS.GET_CHANNEL_IS_PUBLISHED(id, code), {
        signal: controller.signal,
      }).then(({data, status}) => {
        if (status === 200) {
          setCurrentPublished(p => p?.filter((ls: any) => ls !== code));
          setPublishedList((p: any) => [...p, data]);
          clearInterval(channelIntervalRef.current);
        }
      });
    }, 3000);
  };

  const publishToChannel = (body: {
    channelCode: string;
    legalEntityCodes: string[];
  }) => {
    setPublishLoading();
    setLoadingChannelStatus(p => [...p, body?.channelCode]);
    const url = publishUrl(id);
    ServiceApi.post(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.publish-msg",
          "global.toast.publish-title",
        );
        createIntervalForChannels(body.channelCode);
      })
      .finally(() => {
        setPublishLoading();
        setLoadingChannelStatus(p =>
          p?.filter((ls: any) => ls !== body.channelCode),
        );
      });
  };

  return (
    <div className="space-y-4">
      {loadingChannel || parentLoading ? (
        [1, 2, 3, 4].map(() => <Skeleton.List />)
      ) : (
        <Table>
          {channels?.length !== 0 ? (
            channels?.map((channel: any) => {
              return (
                <tr
                  className="text-heading-6 font-semibold"
                  key={channel?.code}
                >
                  <td className="space-y-2">
                    <span>{channel?.title}</span>
                    <p className="text-body-2 font-medium">
                      <Text>productManagement.products.Details.code</Text> :{" "}
                      {channel.code}
                    </p>
                    <p className="text-body-2 font-medium">
                      <Text>
                        productManagement.products.Details.legalEntity
                      </Text>{" "}
                      : {channel?.legalEntity?.code}
                    </p>
                    {loading ||
                    currentPublishedChannel.includes(channel?.code) ? (
                      <div className="flex">
                        <LoadingSvg size="sm" />
                      </div>
                    ) : (
                      <>
                        {publishedList?.filter(
                          (pub: any) => pub?.channelCode === channel?.code,
                        ) && (
                          <p className="text-body-base font-normal">
                            {publishedList.find(
                              (info: any) =>
                                info?.channelCode === channel?.code,
                            ) && (
                              <>
                                <Status.PimChannelStatus
                                  id={
                                    publishedList.find(
                                      (info: any) =>
                                        info?.channelCode === channel?.code,
                                    )?.status
                                  }
                                />
                                <span className="ml-2">
                                  <Text>
                                    productManagement.products.Details.at
                                  </Text>
                                </span>
                              </>
                            )}

                            <span className="ml-2">
                              {convertDate(
                                publishedList.find(
                                  (info: any) =>
                                    channel?.code === info?.channelCode,
                                )?.updatedAt,
                              )}
                            </span>
                          </p>
                        )}
                      </>
                    )}
                  </td>
                  <WithPermission permissions={["PS_PublishSupplierToChannel"]}>
                    <Fragment>
                      <td className="flex flex-col  items-end justify-center gap-4  space-x-2 xl:table-cell">
                        {publishedList.find(
                          (info: any) => channel?.code === info?.channelCode,
                        ) ? (
                          <>
                            <Button
                              variant={"light"}
                              size="sm"
                              className="w-fit flex-none"
                              type="button"
                              onClick={() => {
                                toggleDetails();
                                getChannelDetails(
                                  publishedList.find(
                                    (pub: any) =>
                                      pub?.channelCode === channel?.code,
                                  ),
                                );
                              }}
                            >
                              <Text>
                                productManagement.masterData.suppliers.details
                              </Text>
                            </Button>
                            <Button
                              light
                              variant="danger"
                              size="sm"
                              onClick={() => unPublishHandler(channel)}
                              type="button"
                              disabled={
                                loading ||
                                currentPublishedChannel === channel?.code
                              }
                              className="w-fit flex-none"
                            >
                              <Text>
                                productManagement.masterData.suppliers.unPublish
                              </Text>
                            </Button>
                            <Button
                              light
                              size="sm"
                              onClick={() => publishHandler(channel)}
                              type="button"
                              loading={loadingChannelStatus?.includes(
                                channel?.code,
                              )}
                              className="w-fit flex-none"
                            >
                              <Text>
                                productManagement.masterData.suppliers.rePublish
                              </Text>
                            </Button>
                          </>
                        ) : (
                          <Button
                            light
                            size="sm"
                            type="button"
                            onClick={() => publishHandler(channel)}
                            loading={loadingChannelStatus?.includes(
                              channel?.code,
                            )}
                            disabled={
                              loading ||
                              currentPublishedChannel === channel?.code
                            }
                          >
                            <Text>
                              productManagement.masterData.suppliers.publish
                            </Text>
                          </Button>
                        )}
                      </td>
                    </Fragment>
                  </WithPermission>
                </tr>
              );
            })
          ) : (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
              <Text>
                productManagement.masterData.suppliers.thereIsNoChannel
              </Text>
              !
            </div>
          )}
        </Table>
      )}

      <ChannelDetails
        isOpen={isOpenDetails}
        toggle={toggleDetails}
        details={channelDetails}
      />
    </div>
  );
};

export default Channels;
