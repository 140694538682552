import { NotificationManager } from "common/notifications";
import { Button, Icon, Modal, Text } from "components";
import QRCode from "qrcode.react";
import ProductFamilyPath from "../../helpers/product-family-path";
import { convertDate } from "utils";

type InfoType = {
  isOpen: boolean;
  toggle: () => void;
  data?: any;
};

export default function Info({ isOpen, toggle, data }: InfoType) {
  return (
    <Modal isOpen={isOpen} toggle={toggle} modalClassName="z-40">
      <Modal.Header className="!text-left text-heading-3 text-gray-800 font-bold">
        <Text>productManagement.products.Details.infoDetails</Text>
      </Modal.Header>
      <Modal.Body className="overflow-auto min-h-[300px] space-y-4 flex-col text-sm">
        <div className="flex space-x-2 justify-between">
          <span>
            <Text>productManagement.products.allProducts.sku</Text>
          </span>{" "}
        </div>
        <h2>{data?.originalName}</h2>
        <div className="flex flex-col *:p-2 *:rounded-md *:justify-between :*space-x-2 space-y-4">
          <div className="flex  bg-gray-100">
            <Text>productManagement.products.Details.articleNumber</Text>
            <span className=" font-medium">
              {data?.articleNumber ? `#${data?.articleNumber}` : "--"}
            </span>
          </div>
          <div className="flex ">
            <Text>
              productManagement.products.Details.supplierArticleNumber
            </Text>
            <span className="text-gray-700 font-medium">
              {" "}
              {data?.supplierArticleNumber ? data?.supplierArticleNumber : "--"}
            </span>
          </div>
          <div className="flex  bg-gray-100">
            <Text>
              productManagement.products.Details.currentProductVersion
            </Text>
            <span className="text-gray-700 font-medium">
              {" "}
              {data?.version ? data?.version : "--"}
            </span>
          </div>
          <div className="flex ">
            <Text>productManagement.products.Details.productFamily</Text>
            <span className="text-gray-700 font-medium">
              <ProductFamilyPath
                productData={data}
                pimProductFamilyId={data?.pimProductFamilyId}
              />
            </span>
          </div>
          <div className="flex  bg-gray-100">
            <Text>productManagement.products.Details.creation</Text>
            <span className="text-gray-700 font-medium">
              {data?.createdByName} {convertDate(data?.createdAt)}
            </span>
          </div>
          <div className="flex ">
            <Text>productManagement.products.Details.modify</Text>
            <span className="text-gray-700 font-medium">
              {data?.lastModifierName} {convertDate(data?.lastModifiedAt)}
            </span>
          </div>
          <div className="flex  bg-gray-100">
            <Text>productManagement.products.Details.category</Text>:{" "}
            <span className="text-gray-700 font-medium">
              {data?.detailCategories?.map((detail: any, index: number) => {
                return (
                  detail.translates?.[0].name +
                  (index !== data?.detailCategories?.length - 1 ? " | " : "")
                );
              })}
            </span>
          </div>
          <div className="flex ">
            <Text>productManagement.products.allProducts.referenceKey</Text>:
            <span className="ml-1">{data?.referenceKey}</span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-between">
        <Button size="sm" onClick={toggle} variant="white">
          <Icon className="mr-2" icon={"times"} />
          <Text>global.buttons.close</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
