import i18n from "langs/i18n";
import {
  Button,
  CheckBox,
  Icon,
  InputGroup,
  NoItems,
  Skeleton,
  Text,
  Wrapper,
} from "components";
import {useToggleState, usePermission} from "hooks";
import {Fragment, useCallback, useContext, useEffect, useState} from "react";
import {ServiceApi, URLS} from "services";
import {UserContext} from "..";

import PermissionsDependencyModal from "./permissions-dependency-modal";

export default function General() {
  const updatePermission = usePermission("AS_UpdateRole");
  const {rolesData, setRolesData} = useContext<any>(UserContext);

  const [listTotal, setListTotal] = useState<any>([]);
  const [listSelect, setListSelect] = useState<any>([]);
  const [listRole, setListRole] = useState<any>([]);

  const [search, setSearch] = useState<any>("");
  const [permissions, setPermissions] = useState<any>([]);
  const [labelPermissions, setLabelPermissions] = useState<any>([]);
  const [checkedItem, setCheckedItem] = useState<any>([]);
  const [graph, setGraph] = useState<any>([]);
  const [isOpen, toggle] = useToggleState(false);
  const [loading, setLoading] = useState(false);
  const [loadingGraph, setLoadingGraph] = useState(false);

  const getGraph = () => {
    setLoadingGraph(true);
    const urlGraph = URLS.GET_PERMISSIONS_GRAPH_URL;
    ServiceApi.get(urlGraph).then(({data}) => {
      setGraph(data);
      setLoadingGraph(false);
    });
  };

  const getPermissions = () => {
    setLoading(true);
    const url = URLS.GET_PERMISSIONS_URL(i18n.language);
    ServiceApi.get(url).then(({data}) => {
      let result = JSON.parse(JSON.stringify(data));
      rolesData?.permissions?.forEach((i: any) => {
        result.sections.forEach((b: any) => {
          b.permissions.forEach((c: any) => {
            if (c.key === i.code) {
              c.clicked = i.clicked;
              c.disabled = i.disabled;
            }
          });
        });
      });
      setListRole(result.sections);
      setListTotal(result.sections);
      setLoading(false);
    });
  };

  const handleLanguageChanged = useCallback(() => {
    getPermissions();
  }, []);

  useEffect(() => {
    i18n.on("languageChanged", handleLanguageChanged);
    return () => {
      i18n.off("languageChanged", handleLanguageChanged);
    };
  }, [handleLanguageChanged]);

  useEffect(() => {
    getGraph();
    getPermissions();
  }, []);

  const onChangeCheckBox = (checked: any, item: any, type: string) => {
    setLoading(true);
    let selected: any = [];
    graph?.forEach((i: any) => {
      if (i.permission === item.key) {
        selected = i;
      }
    });
    let newlabelPermissions: any = [];
    let newlist = JSON.parse(JSON.stringify(listTotal));
    newlist?.forEach((a: any) => {
      a.permissions?.forEach((b: any) => {
        selected.connectedPermission.forEach((c: any) => {
          if (b.key === item.key) {
            b.clicked = checked;
            b.disabled = false;
          }
          if (b.key === c) {
            if (checked) {
              newlabelPermissions.push({
                clicked: false,
                disabled: true,
                code: b.key,
                label: b.label,
              });
              b.clicked = true;
              b.disabled = true;
            } else {
              newlabelPermissions.push({
                clicked: false,
                disabled: false,
                code: b.key,
                label: b.label,
              });
              if (type === "all") {
                b.clicked = false;
              }
              b.disabled = false;
            }
          }
        });
      });
    });
    let newPermissions: any = [];
    newlist?.forEach((i: any) => {
      i.permissions?.forEach((b: any) => {
        if (b.clicked === true || b.disabled === true) {
          newPermissions.push({
            clicked: b.clicked,
            disabled: b.disabled,
            code: b.key,
          });
        }
      });
    });
    setListSelect(newlist);
    setPermissions(newPermissions);
    setLabelPermissions(newlabelPermissions);
    setCheckedItem(checked);
    setLoading(false);

    if (checked) {
      if (newlabelPermissions.length !== 0) {
        toggle();
      } else {
        setListRole(newlist);
        setListTotal(newlist);
        setRolesData((p: any) => ({...p, permissions: newPermissions}));
        if (search !== "") {
          handleSearch(newlist);
        }
      }
    } else {
      if (type === "all") {
        toggle();
      } else {
        setListRole(newlist);
        setListTotal(newlist);
        setRolesData((p: any) => ({...p, permissions: newPermissions}));
        if (search !== "") {
          handleSearch(newlist);
        }
      }
    }
  };

  const selectAll = (checked: any, item: any) => {
    setLoading(true);
    let select = "";
    listRole.forEach((i: any) => {
      if (i.key === item.key) {
        i.permissions.forEach((b: any) => {
          if (b.key.includes("FullAccess")) {
            select = b;
          }
        });
      }
    });
    onChangeCheckBox(checked, select, "all");
  };

  const handleSearch = (list: any = []) => {
    setLoading(true);
    let totallist: any = JSON.parse(JSON.stringify(list));
    let newList: any = [];
    let flag = true;
    if (search !== "") {
      totallist.forEach((i: any) => {
        flag = true;
        i.permissions.forEach((b: any) => {
          if (flag) {
            if (b.label.toLowerCase().includes(search.toLowerCase())) {
              newList.push(i);
              flag = false;
            }
          }
        });
      });
    }
    setListTotal(totallist);
    setListRole(search !== "" ? newList : totallist);
    setLoading(false);
  };

  return (
    <Fragment>
      <div className="flex space-x-4">
        <div className="w-[230px]">
          <InputGroup
            placeholder="Search"
            value={search}
            setValue={e => setSearch(e)}
          />
        </div>
        <div
          className="flex w-[44px] cursor-pointer items-center justify-center rounded-md bg-white p-2 "
          onClick={() => handleSearch(listTotal)}
        >
          <Icon className="h-5 w-5 !leading-5 text-primary" icon={"search"} />
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 lg:grid-cols-1">
        {!loadingGraph && !loading ? (
          listRole.length === 0 ? (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
            </div>
          ) : (
            listRole?.map((item: any) => {
              return (
                <Wrapper key={item.key}>
                  <Wrapper.Header>
                    <div className="flex items-center justify-between">
                      <div>
                        <h2 className="text-heading-2 font-semibold text-gray-800">
                          {item.title}
                        </h2>
                      </div>
                      {updatePermission && (
                        <div className="flex justify-between gap-4">
                          <div>
                            <Button
                              key={item.key}
                              type="button"
                              variant="light"
                              textColor="primary"
                              onClick={() => {
                                selectAll(false, item);
                              }}
                            >
                              <Icon className="mr-2" icon={"square"} />
                              <Text>global.buttons.deselectAll</Text>
                            </Button>
                          </div>
                          <div>
                            <Button
                              key={item.key}
                              type="button"
                              variant="light"
                              textColor="primary"
                              onClick={() => {
                                selectAll(true, item);
                              }}
                            >
                              <Icon className="mr-2" icon={"square-check"} />
                              <Text>global.buttons.selectAll</Text>
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </Wrapper.Header>
                  <Wrapper.Body>
                    <div className="grid grid-cols-2 gap-8 lg:grid-cols-4">
                      {item.permissions?.map((i: any) => {
                        return (
                          <div key={i.key}>
                            <CheckBox
                              key={i.key}
                              label={i.label}
                              value={i?.clicked ? true : false}
                              disabled={
                                i?.disabled
                                  ? i.disabled
                                  : !updatePermission
                                    ? true
                                    : false
                              }
                              setValue={value => {
                                onChangeCheckBox(value, i, "");
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </Wrapper.Body>
                </Wrapper>
              );
            })
          )
        ) : (
          <>
            <Skeleton.List />
            <Skeleton.List />
            <Skeleton.List />
            <Skeleton.List />
          </>
        )}
        <PermissionsDependencyModal
          isOpen={isOpen}
          toggle={toggle}
          search={search}
          handleSearch={handleSearch}
          checkedItem={checkedItem}
          rolesData={rolesData}
          list={listSelect}
          setList={setListRole}
          setListTotal={setListTotal}
          labelPermissions={labelPermissions}
          permissions={permissions}
          setRolesData={setRolesData}
        />
      </div>
    </Fragment>
  );
}
