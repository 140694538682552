import {useEffect, useState} from "react";
import {Button, Drawer, Icon, InputGroup, Text, Skeleton} from "components";
import {ServiceApi, URLS} from "services";
import {Departments} from "types";
import {useToggleState} from "hooks";

interface AddTeamLeadProps {
  isOpen: boolean;
  toggle: () => void;
  setTeamLead: any;
}
type Params = {
  keyword?: string | null;
  pageSize: number;
  pageNumber: number;
};

export default function SelectTeamLead({
  isOpen,
  toggle,
  setTeamLead,
}: AddTeamLeadProps) {
  const [data, setData] = useState<Departments.EmployeeList>();
  const [params, setParams] = useState<Params>({
    keyword: "",
    pageNumber: 1,
    pageSize: 10,
  });

  const [loading, setLoading] = useToggleState();

  const onSelectTeamLeadHandler = (teamLead: Departments.EmployeeItem) => {
    setTeamLead(teamLead);
    toggle();
  };

  const getAllEmployee = () => {
    setLoading();
    const url = URLS.GET_EMPLOYEES_LIST_URL;
    const config = {params};
    ServiceApi.get(url, config)
      .then(({data}: any) => {
        setData(data);
        setLoading();
      })
      .catch(() => setLoading());
  };

  useEffect(() => {
    if (isOpen) {
      const timeoutId = setTimeout(() => getAllEmployee(), 500);
      return () => clearTimeout(timeoutId);
    }
  }, [params, isOpen]);

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header className="flex items-center justify-between">
          <span className="text-heading-2 font-semibold text-gray-800">
            <Text>company.stores.selectTEamLead</Text>
          </span>
          <Button size="sm" variant={"light"} onClick={toggle}>
            <Icon icon="close" /> <Text>global.buttons.close</Text>
          </Button>
        </Drawer.Header>
        <Drawer.Body className="space-y-6">
          {loading ? (
            [1, 2, 3, 4].map(() => <Skeleton.List />)
          ) : (
            <>
              <InputGroup
                value={params?.keyword}
                label={"company.stores.search"}
                setValue={keyword =>
                  setParams(p => ({...p, keyword, pageNumber: 1}))
                }
                placeholder="company.stores.searchPlaceHolder"
              />
              {data?.items?.map((teamLead: Departments.EmployeeItem) => {
                return (
                  <div
                    onClick={() => onSelectTeamLeadHandler(teamLead)}
                    className="flex cursor-pointer justify-between rounded-lg bg-white p-4 shadow-card hover:bg-primary-light"
                  >
                    <div className="flex flex-col gap-2">
                      <span className="text-heading-6 font-semibold">
                        {teamLead?.firstName} {teamLead?.lastName}
                      </span>
                      <span className="text-body-2 text-gray-500">
                        # {teamLead?.employeeNumber}
                      </span>
                      <span className="text-body-2 text-gray-500">
                        <Icon icon="envelope" /> {teamLead?.emailAddress}
                      </span>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
}
