import React, {useContext, useEffect, useState} from "react";
import {
  Wrapper,
  Text,
  Select,
  InputGroup,
  Skeleton,
  Footer,
  Button,
} from "components";
import {ProfileContext} from ".";
import {ServiceApi, URLS} from "services";
import {useToggleState, usePermission} from "hooks";

const Language: React.FunctionComponent = () => {
  const {
    settingData,
    setSettingData,
    setBaseSettingData,
    isChangedSetting,
    submitLoading,
  } = useContext<any>(ProfileContext);
  const [loading, setLoading] = useToggleState();
  const updatePermissions = usePermission("AS_UpdateMyCompany");

  const [defaultDashboardLanguage, setDefaultDashboardLanguage] = useState([]);

  const [defaultContentLanguage, setDefaultContentLanguage] = useState([]);

  useEffect(() => {
    let arrayDashboard: any = [];
    let arrayContent: any = [];
    setLoading();
    const url = URLS.GET_COMPANY_SETTING;
    ServiceApi.get(url)
      .then(({data}) => {
        data?.dashboardLanguages?.map((item: any) => {
          arrayDashboard.push({id: item, name: item});
        });
        data?.contentLanguages?.map((item: any) => {
          arrayContent.push({id: item, name: item});
        });
        setDefaultDashboardLanguage(arrayDashboard);
        setDefaultContentLanguage(arrayContent);
        setBaseSettingData(data);
        setLoading();
      })
      .catch(() => {
        setLoading();
      });
  }, []);

  return (
    <>
      <div className="space-y-8">
        <Wrapper>
          <Wrapper.Header>
            <h1 className="text-heading-2 font-semibold">
              <Text>company.profile.customerSettings</Text>
            </h1>
          </Wrapper.Header>
          <Wrapper.Body>
            <div className="s-full flex flex-col space-y-4 lg:w-1/2">
              {loading ? (
                <>
                  <Skeleton.Input />
                  <Skeleton.Input />
                </>
              ) : (
                <>
                  <InputGroup
                    label="company.profile.customerNumberPrefixChar"
                    value={settingData?.customerNumberPrefix}
                    setValue={value =>
                      setSettingData((p: any) => ({
                        ...p,
                        customerNumberPrefix: value,
                      }))
                    }
                    disabled={!updatePermissions}
                  />
                </>
              )}
            </div>
          </Wrapper.Body>
        </Wrapper>
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-x-8 lg:gap-y-16">
          <Wrapper>
            <Wrapper.Header>
              <h1 className="text-heading-2 font-semibold">
                <Text>company.profile.dashboardLanguage</Text>
              </h1>
            </Wrapper.Header>
            <Wrapper.Body className="grid grid-cols-1 gap-8 lg:gap-16">
              <div className="space-y-8">
                <Select
                  label="company.profile.defaultDashboardLanguage"
                  value={settingData?.defaultDashboardLanguage}
                  items={defaultDashboardLanguage}
                  setValue={value =>
                    setSettingData((p: any) => ({
                      ...p,
                      defaultDashboardLanguage: value,
                    }))
                  }
                  disabled={!updatePermissions}
                />
              </div>
            </Wrapper.Body>
          </Wrapper>
          <Wrapper>
            <Wrapper.Header>
              <h1 className="text-heading-2 font-semibold">
                <Text>company.profile.contentLanguage</Text>
              </h1>
            </Wrapper.Header>
            <Wrapper.Body className="grid grid-cols-1 gap-8 lg:gap-16">
              <div className="space-y-8">
                <Select
                  label="company.profile.defaultContentLanguage"
                  value={settingData?.defaultContentLanguage}
                  items={defaultContentLanguage}
                  setValue={value =>
                    setSettingData((p: any) => ({
                      ...p,
                      defaultContentLanguage: value,
                    }))
                  }
                  disabled={!updatePermissions}
                />
              </div>
            </Wrapper.Body>
          </Wrapper>
        </div>
      </div>
      <Footer show={!isChangedSetting}>
        <Button type="submit" variant="primary" loading={submitLoading}>
          <Text>global.buttons.saveChanges</Text>
        </Button>
      </Footer>
    </>
  );
};

export default Language;
