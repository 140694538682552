import { ItemType } from "types/conversation";
import ConversationCard from "./conversation-card";
import { useState } from "react";
import { Button, Icon, Skeleton, Text } from "components";
import AddOrReply from "./add-reply";

type Props = {
  isOpen: boolean;
  toggle: () => void;
  item: ItemType;
  setItemToReply: (i: ItemType) => void;
  loading: boolean;
}

export default function Replies({ isOpen, toggle, item, setItemToReply, loading }: Props) {

  const Replies = (comment: ItemType, parent: ItemType): any => {
    return (
      <>
        <ConversationCard
          item={comment}
          handleReply={(comment: ItemType) => setItemToReply(comment)}
          parentReply={comment.replyOfId !== item.id ? parent : undefined} />
        <>
          {(comment.repliedDiscussionCount > 0) &&
            comment.repliedDiscussion.map((reply: ItemType) =>
              Replies(reply, comment)
            )
          }
        </>
      </>
    )
  }

  return (
    <div className={`absolute left-0 bottom-0 right-0 bg-white space-y-3 ${isOpen ? 'top-0' : 'top-full'}`}>
      <div className="flex items-center justify-between mb-3">
        <p className="text-gray-800 font-semibold text-heading-6">
          <Text>productManagement.products.completeness.repliesTo</Text>:
        </p>
        <Button variant={"danger"} className="!bg-white !text-danger !border-none" onClick={toggle}>
          <Icon icon="close" />
          <Text>productManagement.products.completeness.closeReplies</Text>
        </Button>
      </div>
      {loading ?
        [1, 2, 3].map((i) =>
          <Skeleton.TextArea key={i} />
        ) :
        <>
          <ConversationCard item={item} handleReply={(item: ItemType) => setItemToReply(item)} />
          <div className={`space-y-2 pb-24 ${item.repliedDiscussionCount > 0 && 'border-t pl-4 pt-3'}`}>
            {item.repliedDiscussionCount > 0 && item.repliedDiscussion.map((reply: ItemType) => Replies(reply, item))}
          </div>
        </>
      }
    </div>
  );
}
