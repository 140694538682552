import {Accordion, Button, HandleName, Icon, Text} from "components";
import {PimContext} from "..";
import {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {useConverter} from "hooks";
import {dateFormats} from "constant";

export default function Attribute() {
  const {details, loading} = useContext(PimContext);
  const [groupFilter, setGroupfilter] = useState<string>("all");
  const {i18n} = useTranslation();
  const {convertDate} = useConverter();

  const getTranslateValue = (
    keyName: string,
    translations: any,
    type?: number,
  ) => {
    if (translations) {
      let newArray = translations?.filter(function (item: any) {
        return (
          i18n.language?.toLocaleLowerCase() ===
            item.language?.toLocaleLowerCase() && item
        );
      });
      if (newArray.length === 0) {
        newArray = translations?.filter(function (item: any) {
          return !item.language && item;
        });
      }
      if (newArray.length !== 0 && newArray[0][keyName]) {
        if (type === 70) {
          return [
            newArray[0][keyName].replace(/(<([^>]+)>)/gi, ""),
            newArray[0].measurementFamilyUnitInfo,
          ];
        }
        return newArray[0][keyName].replace(/(<([^>]+)>)/gi, "");
      }
    }
  };

  const findAttributeValue = (attr: any) => {
    const value = getTranslateValue(
      "value",
      attr?.pimProductAttribute?.pimProductAttributeValue,
      attr?.type,
    );
    if (attr?.type === 30) {
      const valueObject = attr?.items?.find((item: any) => item.id === value);
      const valueData = getTranslateValue(
        "labelTitle",
        valueObject?.translates,
      );
      return valueData ? valueData : "------------";
    }
    if (attr?.type === 60 || attr?.type === 50 || attr?.type === 40) {
      const type = attr?.type;
      const format =
        type === 60
          ? dateFormats.full
          : type === 50
            ? dateFormats.onlyTime
            : dateFormats?.onlyDate;
      return convertDate(value, format);
    }
    if (attr?.type === 70) {
      const [val, measurementFamilyUnitInfo] = value;
      return val + ` ${measurementFamilyUnitInfo?.symbol}`;
    }
    return (
      <HandleName
        translations={attr?.pimProductAttribute?.pimProductAttributeValue || []}
        code={attr?.code}
        keyName="value"
      />
    );
  };

  return (
    <>
      <div className="flex items-center gap-2">
        <Button
          size="sm"
          variant={groupFilter === "all" ? "primary" : "white"}
          className="!rounded-full"
          onClick={() => setGroupfilter("all")}
        >
          <Text>productManagement.products.Details.allInGroupFilter</Text>
        </Button>

        {details?.attributeGroups?.map((group: any) => (
          <Button
            size="sm"
            className="!rounded-full"
            variant={groupFilter === group.code ? "primary" : "white"}
            onClick={() => setGroupfilter(group.code)}
          >
            {
              <HandleName
                translations={group?.translates}
                code={group?.code}
                keyName="labelTitle"
              />
            }
          </Button>
        ))}
      </div>
      <div className="space-y-6">
        {details?.attributeGroups?.map((attribute: any) => {
          return (
            <>
              {(groupFilter === "all" || groupFilter === attribute?.code) && (
                <Accordion key={"about-product"}>
                  <Accordion.Item
                    active
                    className={`mb-4 rounded-2xl border bg-white px-4 shadow-nested`}
                  >
                    <Accordion.Toggle className="flex items-center justify-between">
                      <div className="flex items-center gap-[10px]">
                        <div>
                          <Icon
                            icon={"chevron-down"}
                            size="sm"
                            className="transition-transform group-[.accordion-item.active]:rotate-180"
                          />
                        </div>
                        <div>
                          <h3 className="text-gray-600">
                            <HandleName
                              translations={attribute?.translates}
                              code={attribute?.code}
                              keyName="labelTitle"
                            />
                          </h3>
                        </div>
                      </div>
                    </Accordion.Toggle>
                    <>
                      <Accordion.Body className={`px-4`}>
                        <div className={`${loading && "space-y-2"}`}>
                          {attribute?.attributes?.map((attr: any) => {
                            return (
                              <>
                                <div className="border-b border-gray-200 py-4 last:border-none">
                                  <div className=" grid grid-cols-3 gap-4">
                                    <span className="col-span-1 text-body-base font-medium text-gray-800">
                                      <HandleName
                                        translations={attr?.translates}
                                        code={attr?.code}
                                        keyName="labelTitle"
                                      />
                                    </span>
                                    <span className="text-heading-5 text-gray-700">
                                      {attr?.pimProductAttribute
                                        ?.pimProductAttributeValue?.length
                                        ? findAttributeValue(attr)
                                        : "------------"}
                                    </span>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </Accordion.Body>
                    </>
                  </Accordion.Item>
                </Accordion>
              )}
            </>
          );
        })}
      </div>
    </>
  );
}
