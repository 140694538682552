import { NotificationManager } from "common/notifications";
import {
  Drawer,
  Text,
  Icon,
  Button,
  InputGroup,
  DeleteModal,
  CheckBox,
  LoadingSvg,
  Select,
} from "components";
import { useConverter, useDataState, useToggleState } from "hooks";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { ServiceApi, URLS } from "services";
import { PimContext } from "..";
import { rules, types } from "constant";

type Props = {
  isOpen: boolean;
  toggle: () => void;
  item: any;
  setItem: any;
};

type Vks = {
  vk1?: number;
  vk2?: number;
};

type DataModel = {
  quantity?: number;
  salesPersonCommission?: {
    amount?: number | string;
    calculationType?: number;
  }
}

export default function EditItem({ isOpen, toggle, item }: Props) {
  const [data, setData, setBaseData, isChanged] = useDataState<DataModel>({});
  const { productId } = useParams();
  const { convertAmount } = useConverter();
  const [status, toggleStatus] = useToggleState();
  const [loading, toggleLoading] = useToggleState();
  const [isOpenDelete, toggleDelete] = useToggleState();
  const [deleteLoading, toggleDeleteLoading] = useToggleState();
  const { getPIMProduct } = useContext(PimContext);
  const [salePrice, setSalePrice, setBaseDPrice, isChangedPrice] = useDataState(
    {
      currency: "EUR",
      amount: null,
      currencySymbol: "€",
    },
  );
  const [isChecked, toggleCheck] = useState(!!item?.overwrittenSalePrice);
  const [salesStatus, setSalesStatus] = useState<Vks>({});

  const getOverwrittenPrices = () => {
    toggleStatus();
    const body = {
      productId: item?.id,
      supplierId: item.supplierId,
      programId: item.supplierProgramId,
      brandId: item.brandId,
      basePrice: item.purchasePrice?.amount,
      purchasePriceType: item.purchasePriceType,
    };
    ServiceApi.post(URLS.CALC_PIM_SALE_PRICE, body)
      .then(({ data }) => {
        setSalesStatus(data);
        toggleStatus();
      })
      .catch(() => {
        toggleStatus();
      });
  };

  useEffect(() => {
    if (isOpen) {
      getOverwrittenPrices();
      toggleCheck(!!item?.overwrittenSalePrice);
    }
    setBaseDPrice(item?.overwrittenSalePrice);
    setBaseData({
      quantity: item?.quantity,
      salesPersonCommission: item?.salesPersonCommission ??
      {
        amount: '',
        calculationType: 0
      }
    });
  }, [isOpen, item]);

  const onSubmitHandler = () => {
    toggleLoading();
    const url = URLS.UPDATE_BUNDLE_ITEM(productId, item?.id);
    const body = {
      pimProductBundleItemId: item.id,
      quantity: data.quantity,
      overwrittenSalePrice: isChecked
        ? {
          ...salePrice,
          amount: salePrice.amount,
        }
        : null,
      salesPersonCommission: data.salesPersonCommission?.amount ? data.salesPersonCommission : null
    };
    ServiceApi.put(url, body)
      .then(() => {
        toggleLoading();
        getPIMProduct();
        toggle();
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
      })
      .catch(() => toggleLoading());
  };

  const onDeleteHandler = () => {
    toggleDeleteLoading();
    const url = URLS.DELETE_BUNDLE_ITEM(productId, item?.id);
    ServiceApi.delete(url)
      .then(() => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title",
        );
        toggleDeleteLoading();
        toggleDelete();
        toggle();
        getPIMProduct();
      })
      .catch(() => toggleDeleteLoading());
  };
  return (
    <>
      <Drawer isOpen={isOpen} toggle={toggle}>
        <Drawer.Menu>
          <Drawer.Header className="flex items-center justify-between text-heading-2 font-semibold">
            <span>
              <Text>productManagement.products.allProducts.editBundleItem</Text>
            </span>
            <div className="space-x-2">
              <Button
                variant={"danger"}
                light
                size="sm"
                onClick={() => {
                  toggleDelete();
                }}
              >
                <Icon icon="trash" />
              </Button>
              <Button
                variant={"light"}
                size="sm"
                className="flex items-center"
                onClick={toggle}
              >
                <span>
                  <Text>global.buttons.close</Text>
                </span>
                <Icon icon="close" className="ml-2" />
              </Button>
            </div>
          </Drawer.Header>
          <Drawer.Body className="space-y-6">
            <div
              key={item.id}
              className="grid grid-cols-3 items-center justify-between rounded-lg p-2 shadow-card"
            >
              <div className="col-span-2 ">
                <div className="space-y-2 text-wrap py-2">
                  <h6 className="text-heading-6 font-semibold leading-6 text-gray-800">
                    {item.originalName}
                  </h6>
                  <div className="text-sm text-gray-500">
                    <span className="ml-1">#{item.articleNumber}</span>
                  </div>
                </div>
              </div>
              {status ? (
                <LoadingSvg size="sm" />
              ) : (
                <div className="col-span-1 flex flex-col space-y-2 *:text-sm">
                  <p>vk2 : {convertAmount(salesStatus?.vk2)}</p>
                  <p>vk1 : {convertAmount(salesStatus?.vk1)}</p>
                </div>
              )}
            </div>
            <div className="grid grid-cols-2">
              <InputGroup
                label={
                  <Text>productManagement.products.allProducts.quantity</Text>
                }
                value={data.quantity}
                setValue={(value) => setData({ ...data, quantity: value })}
                type="number"
                rules={rules.required}
              ></InputGroup>
            </div>
            <CheckBox
              value={isChecked}
              setValue={e => {
                if (!e && !!item?.overwrittenSalePrice) {
                  setSalePrice({
                    currency: "EUR",
                    amount: null,
                    currencySymbol: "€",
                  });
                } else if (!!item?.overwrittenSalePrice) {
                  setBaseDPrice(item?.overwrittenSalePrice);
                }
                toggleCheck(e);
              }}
              label="productManagement.products.allProducts.addOverwrittenSalePrice"
            />
            {isChecked && (
              <div className="grid grid-cols-2 gap-4">
                <InputGroup
                  label={
                    <Text>
                      productManagement.products.allProducts.overwrittenSalePrice
                    </Text>
                  }
                  value={salePrice?.amount}
                  setValue={e => setSalePrice(p => ({ ...p, amount: e }))}
                  disabled={!isChecked}
                  type="price"
                ></InputGroup>
              </div>
            )}
            <div className="flex items-center gap-3">
              <label className="whitespace-nowrap text-heading-5 font-semibold text-gray-800">
                <Text>productManagement.products.Details.salesPersonCommission</Text>
              </label>
              <hr className="flex-1 bg-gray-200" />
            </div>
            <div className="grid grid-cols-2 gap-3">
              <div className="col-span-2 lg:col-span-1">
                <InputGroup
                  label="productManagement.products.Details.amount"
                  value={data?.salesPersonCommission?.amount}
                  setValue={(value) => {
                    if (data?.salesPersonCommission?.calculationType == 1 || (value >= 0 && value <= 100)) {
                      setData(
                        {
                          ...data, salesPersonCommission:
                            { ...data.salesPersonCommission, amount: value }
                        })
                    }
                  }}
                  keyfilter="pnum"
                />
              </div>
              <div className="col-span-2 lg:col-span-1">
                <Select
                  label="productManagement.products.Details.type"
                  placeholder="global.select"
                  value={data?.salesPersonCommission?.calculationType}
                  items={types.amountCalculationType}
                  setValue={(value) => setData(
                    {
                      ...data, salesPersonCommission:
                        { ...data.salesPersonCommission, calculationType: value, amount: '' }
                    })}
                />
              </div>
            </div>
          </Drawer.Body>
          <Drawer.Footer className="flex justify-between">
            <Button type="button" variant={"light"} onClick={toggle}>
              <Text>global.buttons.cancel</Text>
            </Button>
            <Button
              type="button"
              onClick={onSubmitHandler}
              loading={loading}
              disabled={!isChanged && !isChangedPrice}
            >
              <Text>submit Changes</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Drawer>
      <DeleteModal
        isOpen={isOpenDelete}
        toggle={toggleDelete}
        selected={{ name: item?.id }}
        loading={deleteLoading}
        onConfirm={onDeleteHandler}
      />
    </>
  );
}
