import {useContext} from "react";
import {Wrapper, RadioButton, Text} from "components";
import {Brand, Product, Program, Supplier, SalesChannel} from "./sections";
import {LineItemType, CalculationProcessType} from "constant/priceEngine";
import {SimulationContext} from "../..";

export default function Config() {
  const {setSimulateData, simulateData} = useContext(SimulationContext);
  return (
    <Wrapper>
      <Wrapper.Body className="space-y-8 *:text-gray-800">
        <div className="grid grid-cols-2 gap-8">
          <Supplier />
          <Brand />
          <Program />
          <Product />
          <SalesChannel />
        </div>
        <div className="space-y-8 border-t pt-8 *:!text-gray-800">
          <span className="text-sm text-gray-800">
            <Text>priceEngine.simulator.lineItemType</Text>
          </span>
          <div className="flex w-3/4 gap-8">
            {LineItemType.map(type => {
              return (
                <RadioButton
                  key={type.name}
                  label={type.name}
                  value={simulateData?.lineItemType === type.id}
                  setValue={() =>
                    setSimulateData((p: any) => ({...p, lineItemType: type.id}))
                  }
                />
              );
            })}
          </div>
        </div>
        <div className="space-y-8 border-t pt-8 *:!text-gray-800">
          <span className="text-sm text-gray-800">
            <Text>priceEngine.simulator.CalculationProcessType</Text>
          </span>
          <div className="flex w-3/4 gap-8">
            {CalculationProcessType.map(type => {
              return (
                <RadioButton
                  key={type.name}
                  label={type.name}
                  value={simulateData?.calculationProcessType === type.id}
                  setValue={() =>
                    setSimulateData((p: any) => ({
                      ...p,
                      calculationProcessType: type.id,
                    }))
                  }
                />
              );
            })}
          </div>
        </div>
      </Wrapper.Body>
    </Wrapper>
  );
}
