import CalculationLevel from "./calculation-level";
import CalculationUnits from "./calculation-units";
import PriceTemplates from "./price-templates";
import PriceSimulatorRoutes from "./price-simulator";

const priceEngine = [
  {
    path: "price-engine/calculation-level",
    children: [...CalculationLevel],
  },
  {
    path: "price-engine/calculation-units",
    children: [...CalculationUnits],
  },
  {
    path: "price-engine/price-templates",
    children: [...PriceTemplates],
  },
  {
    path: "price-engine/price-simulator",
    children: [...PriceSimulatorRoutes],
  },
];

export default priceEngine;
