import {
  Button,
  CheckBox,
  DatePicker,
  Drawer,
  Form,
  Icon,
  Select,
  Text,
} from "components";
import {rules, status} from "constant";
import {AvailabilityStatus} from "enum";
import {Dispatch, Fragment, useContext, useRef, useState} from "react";
import toast from "react-hot-toast";
import {ServiceApi} from "services";
import {Supplier} from "types";
import {ProgramContext} from "../details";

type Props = {
  isOpen: boolean;
  toggle: () => void;
  availableStatus?: Supplier.AvailableStatusItem | null;
  onSubmitted: Dispatch<string>;
};

export default function AvailabilityStatusForm({
  isOpen,
  toggle,
  availableStatus = null,
  onSubmitted,
}: Props) {
  const isNew = !availableStatus;
  const isDefault = !!availableStatus?.isDefault;
  const initData: Supplier.CreateAvailableStatus = {
    availabilityStatus:
      availableStatus?.availabilityStatus ?? AvailabilityStatus.Active,
    fromDate: availableStatus?.fromDate ?? null,
    toDate: availableStatus?.toDate ?? null,
    validationPeriod: !!availableStatus?.validationPeriod,
  };
  const formRef = useRef<any>(null);
  const programContext = useContext(ProgramContext);
  const program = programContext.data;
  const [data, setData] = useState(initData);
  const [loading, setLoading] = useState(false);
  const handleSetValue = (key: keyof typeof data) => {
    return (value: any) => {
      setData(p => ({...p, [key]: value}));
    };
  };
  const create = (data: any) => {
    if (!program.id) return;
    const url = `/productservice/api/supplierprograms/${program.id}/availability-status`;
    const body = {...data};
    setLoading(true);
    ServiceApi.post(url, body)
      .then(({data: id}) => {
        const message =
          "productManagement.masterData.suppliers.availabilityStatus.addSuccessMessage";
        toast.success(message);
        setData(initData);
        onSubmitted(id);
        toggle();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const update = (data: any) => {
    if (!availableStatus?.id) return;
    const url = `/productservice/api/supplierprograms/${program.id}/availability-status/${availableStatus?.id}`;
    const body = {...data};
    setLoading(true);
    ServiceApi.put(url, body)
      .then(() => {
        const message =
          "productManagement.masterData.suppliers.availabilityStatus.editSuccessMessage";
        toast.success(message);
        onSubmitted(availableStatus?.id);
        toggle();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleSubmit = (e: any) => {
    formRef.current?.submitHandler(e, data);
  };
  const submit = isNew ? create : update;
  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header className="flex items-center gap-4">
          <h6 className="flex-1 text-start text-heading-2 font-semibold text-gray-800">
            <Text>
              {isNew
                ? "productManagement.masterData.suppliers.availabilityStatus.addStatusTitle"
                : "productManagement.masterData.suppliers.availabilityStatus.editStatusTitle"}
            </Text>
          </h6>
          <Button type="button" variant="light" onClick={toggle}>
            <Icon icon="close" />{" "}
            <Text>
              productManagement.masterData.suppliers.availabilityStatus.closeButton
            </Text>
          </Button>
        </Drawer.Header>
        <Drawer.Body>
          <Form
            ref={formRef}
            onSubmit={submit}
            className="grid grid-cols-2 items-start gap-6"
          >
            <div className="col-span-full">
              <Select
                label="productManagement.masterData.suppliers.availabilityStatus.status"
                value={data.availabilityStatus}
                setValue={handleSetValue("availabilityStatus")}
                items={status.availability}
                rules={rules.required}
              />
            </div>
            {!isDefault && (
              <Fragment>
                <div className="col-span-full">
                  <CheckBox
                    label="productManagement.masterData.suppliers.availabilityStatus.addValidationPeriod"
                    value={data.validationPeriod}
                    setValue={handleSetValue("validationPeriod")}
                  />
                </div>
                <DatePicker
                  label="productManagement.masterData.suppliers.availabilityStatus.fromDate"
                  value={data.fromDate}
                  onChange={handleSetValue("fromDate")}
                  disabled={!data.validationPeriod}
                  maxDate={data.toDate}
                  rules={data.validationPeriod ? rules.required : []}
                />
                <DatePicker
                  label="productManagement.masterData.suppliers.availabilityStatus.toDate"
                  value={data.toDate}
                  onChange={handleSetValue("toDate")}
                  disabled={!data.validationPeriod}
                  minDate={data.fromDate}
                />
              </Fragment>
            )}
          </Form>
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-between gap-2">
          <Button
            type="button"
            variant="light"
            onClick={toggle}
            disabled={loading}
          >
            <Icon icon="close" />{" "}
            <Text>
              productManagement.masterData.suppliers.availabilityStatus.cancelButton
            </Text>
          </Button>
          <Button
            type="button"
            variant="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            <Text>
              {isNew
                ? "productManagement.masterData.suppliers.availabilityStatus.addButton"
                : "productManagement.masterData.suppliers.availabilityStatus.updateButton"}
            </Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
