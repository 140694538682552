import {CheckBox, Wrapper, Button, Text} from "components";
import {InfoSectionContext} from "../info-section";
import {useContext, useEffect} from "react";
import {useDataState, useToggleState} from "hooks";
import {ServiceApi, URLS} from "services";
import {NotificationManager} from "common/notifications";

export default function Configurations() {
  const {levelData, refId, level, refCode, refTitle} =
    useContext(InfoSectionContext);
  const [loading, toggleLoading] = useToggleState();
  const [data, setData, setBaseData, isChanged] = useDataState({
    title: "",
    level,
    refIdentifier: refId,
    refCode,
    refTitle,
    excludeAutoDiscount: false,
    excludeOptionalDiscount: false,
    excludeVoucherDiscount: false,
  });

  useEffect(() => {
    setBaseData({
      title: refTitle || "",
      level,
      refIdentifier: refId,
      refCode,
      refTitle,
      excludeAutoDiscount: levelData?.excludeAutoDiscount || false,
      excludeOptionalDiscount: levelData?.excludeOptionalDiscount || false,
      excludeVoucherDiscount: levelData?.excludeVoucherDiscount || false,
    });
  }, [levelData || refId]);

  useEffect(() => {
    return () => {
      setBaseData({
        title: refTitle || "",
        level,
        refIdentifier: refId,
        refCode,
        refTitle,
        excludeAutoDiscount: levelData?.excludeAutoDiscount,
        excludeOptionalDiscount: levelData?.excludeOptionalDiscount,
        excludeVoucherDiscount: levelData?.excludeVoucherDiscount,
      });
    };
  }, []);

  const onChangeHandler = (key: any) => {
    return (value: any) => setData((p: any) => ({...p, [key]: value}));
  };

  const onSubmitHandler = () => {
    toggleLoading();
    const url = URLS.UPDATE_CALCULATION_UNIT_CUSTOMIZATION_CONFIG;
    const body = {...data};
    ServiceApi.post(url, body)
      .then(() => {
        toggleLoading();
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
      })
      .catch(() => toggleLoading());
  };

  return (
    <Wrapper>
      <Wrapper.Body className="space-y-8">
        <div className="grid grid-cols-2 gap-8">
          <div className="flex flex-col gap-8">
            <CheckBox
              label="priceEngine.calculationLevel.excludeAllAutoDiscount"
              value={data?.excludeAutoDiscount}
              setValue={onChangeHandler("excludeAutoDiscount")}
              disabled={loading}
            />
            <CheckBox
              label="priceEngine.calculationLevel.excludeAllOptionalDiscount"
              value={data?.excludeOptionalDiscount}
              setValue={onChangeHandler("excludeOptionalDiscount")}
              disabled={loading}
            />
            <CheckBox
              label="priceEngine.calculationLevel.excludeAllVoucherDiscount"
              value={data?.excludeVoucherDiscount}
              setValue={onChangeHandler("excludeVoucherDiscount")}
              disabled={loading}
            />
          </div>
        </div>
        <div className="flex justify-end">
          <Button
            disabled={!isChanged}
            onClick={onSubmitHandler}
            loading={loading}
          >
            <Text>global.buttons.saveChanges</Text>
          </Button>
        </div>
      </Wrapper.Body>
    </Wrapper>
  );
}
