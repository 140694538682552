import {
  Breadcrumb,
  Button,
  Form,
  Icon,
  InputGroup,
  NoItems,
  Skeleton,
  Table,
  Text,
} from "components";
import {withPermission} from "hoc";
import {useToggleState} from "hooks";
import {Fragment, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {ServiceApi, URLS} from "services";
import InviteDrawwer from "./invite-drawer";

function Invite() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState<any>([]);
  const [loadingList, setLoadingList] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [selected, setSelected] = useState("");
  const [isOpenDrawerInvite, toggleDrawerInvite] = useToggleState(false);
  const [inputValue, setInputValue] = useState(searchParams.get("search"));

  const getData = () => {
    const url = URLS.SEARCH_AD_USERS(inputValue);
    setLoadingList(true);
    ServiceApi.get(url)
      .then(({data}) => {
        setData(data);
      })
      .finally(() => {
        setLoadingList(false);
        setLoadingButton(false);
      });
  };
  useEffect(getData, []); // eslint-disable-line react-hooks/exhaustive-deps

  const submit = () => {
    if (inputValue !== "") {
      searchParams.set("search", inputValue as "");
      setSearchParams(searchParams);
      setLoadingButton(true);
      getData();
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
      </div>
      <>
        <div>
          <Form onSubmit={submit}>
            <div className="flex items-center gap-4 rounded-xl bg-white p-4">
              <div className="w-[443px]">
                <InputGroup
                  value={inputValue}
                  className="border-white"
                  placeholder="Search User Name, Email ..."
                  setValue={value => setInputValue(value)}
                />
              </div>
              <div>
                <Button
                  type="submit"
                  loading={loadingButton}
                  disabled={inputValue === "" ? true : false}
                >
                  <Fragment>
                    <Icon icon="search" className="mr-2" />
                    <Text>
                      configurations.userManagment.userList.searchUser
                    </Text>
                  </Fragment>
                </Button>
              </div>
            </div>
          </Form>
        </div>
        {!loadingList ? (
          <>
            <Table>
              {data?.length === 0 ? (
                <div className="flex w-full flex-col items-center space-y-4">
                  <NoItems />
                </div>
              ) : (
                data?.map((item: any) => {
                  return (
                    <tr key={item.userId}>
                      <td className="space-y-2">
                        <div>
                          <h6 className="text-heading-6 font-semibold text-gray-800">
                            {item.userPrincipalName}
                          </h6>
                        </div>
                        {item.email && (
                          <div>
                            <Icon
                              className="mr-2 font-black text-gray-500"
                              icon="envelope"
                            />
                            <span className="text-body-2 font-medium text-gray-500">
                              {item.email}
                            </span>
                          </div>
                        )}
                      </td>
                      <td>
                        <span className="text-body-base font-normal text-gray-600">
                          {item.displayName}
                        </span>
                      </td>
                      <td>
                        <Button
                          variant="light"
                          size="sm"
                          onClick={() => {
                            toggleDrawerInvite();
                            setSelected(item);
                          }}
                        >
                          <Text>global.buttons.invite</Text>
                        </Button>
                      </td>
                    </tr>
                  );
                })
              )}
            </Table>
          </>
        ) : (
          <>
            <Skeleton.List />
            <Skeleton.List />
            <Skeleton.List />
            <Skeleton.List />
          </>
        )}
      </>
      <InviteDrawwer
        isOpen={isOpenDrawerInvite}
        toggle={toggleDrawerInvite}
        selected={selected}
      />
    </div>
  );
}
export default withPermission(Invite, ["AS_InviteUserIdP"]);
