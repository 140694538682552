import {Skeleton, Text, Wrapper} from "components";
import {isEmpty} from "lodash";
import {createContext, useContext, useEffect, useState} from "react";
import {ServiceApi, URLS} from "services";
import {SellOffProductDetailsContext} from "..";
import CurrentPhase from "./currentPhase";
import PreparationSalesTemplate from "./preparaationSalesTemplate";
import PriceTemplate from "./priceTemplate";
import SaleProccessTimeline from "./saleProccessTimeline";
import SalesStatus from "./salesStatus";
import {usePermission, useToggleState} from "hooks";
import {SalesService} from "types";

export const saleProccessContext = createContext<any>({});

const SaleProccessTab = () => {
  const phasePermission = usePermission("SC_GetPhase");
  const [loading, toggle] = useToggleState();
  const [timeLine, setTimeLine] = useState<
    SalesService.SellOffProductTimelineItemDto[]
  >([]);

  const {data, setChangePhase, setStartPrePhase, setClosePhase} = useContext(
    SellOffProductDetailsContext,
  );

  useEffect(() => {
    if (!isEmpty(data)) {
      toggle();
      const url = URLS.GET_SUBPRODUCT_TIMELINE_URL(
        data.sellOffProductId,
        data?.channelCode,
      );
      ServiceApi.get(url)
        .then(({data}) => {
          setTimeLine(data.sellOffProductTimelineItems);
          toggle();
        })
        .catch(() => toggle());
    }
  }, [data]);

  const isSold = !!data?.isSold;
  const active = !!data?.currentPhaseDay;

  return (
    <>
      <div className="grid grid-cols-2 gap-6 xl:grid-cols-3">
        {isSold || active ? (
          <saleProccessContext.Provider
            value={{data, setChangePhase, setStartPrePhase, setClosePhase}}
          >
            <CurrentPhase />
            <PriceTemplate />
            <SalesStatus />
          </saleProccessContext.Provider>
        ) : (
          <PreparationSalesTemplate data={data} />
        )}
      </div>
      {phasePermission && (
        <Wrapper className="col-span-3">
          <Wrapper.Header className="text-heading-2 font-semibold">
            <Text>
              salesManagment.sellOffSales.sellOffProduct.salesTimeline
            </Text>
          </Wrapper.Header>
          <Wrapper.Body>
            {loading ? (
              <div className="flex  gap-4">
                {[1, 2, 3, 4].map(s => (
                  <Skeleton.TextArea key={s} />
                ))}
              </div>
            ) : (
              <SaleProccessTimeline timeLine={timeLine} />
            )}
          </Wrapper.Body>
        </Wrapper>
      )}
    </>
  );
};

export default SaleProccessTab;
