import {useState} from "react";
import "./TimeLine.scss";
import {classNames} from "utils";
import {useEffect} from "react";
import Text from "components/Text";

type TimeLineItemsProps = {
  title?: string;
  description?: string | null;
  label?: string;
  color?: string;
  details?: React.ReactNode;
};

type TimeLineProps = {
  items?: TimeLineItemsProps[];
  className?: string;
};

const TimeLine = ({items, className}: TimeLineProps) => {
  const [correntPhase, setCurrentPhase] = useState(0);

  useEffect(() => {
    const labels = items?.map(item => item.label);
    let lastIndex = 0;
    labels?.map((label, index) => {
      if (label?.length) {
        lastIndex = index;
      }
    });
    setCurrentPhase(lastIndex);
  }, [items]);

  return (
    <div className={`inline-block w-full overflow-y-auto`}>
      <ul className={`timeline timeline-horizontal m-1 ${className}`}>
        {items?.map((item: any, index: number) => {
          const badgeClassName = classNames({
            "left-1/2": index !== 0 && index !== items.length - 1,
            "left-[43px]": index === 0 || index === items.length - 1,
          });

          const panelClassName = classNames({
            "opacity-40": item?.color === "gray-500",
          });
          return (
            <li className="timeline-item">
              <div
                className={`timeline-badge bg-${
                  item?.color === "gray-500" ? "gray-300" : item?.color
                } ${badgeClassName}`}
              ></div>
              <div
                className={`timeline-panel bg-${item?.color} bg-opacity-10 ${panelClassName}`}
              >
                <div className="timeline-heading">
                  <h6 className="mb-[16px] text-heading-6 text-gray-800">
                    <Text>{item?.title}</Text>
                  </h6>
                  <p className="text-body-2 text-gray-700">
                    {item?.description}
                  </p>
                </div>
                <div className="timeline-body mt-[8px] text-body-2 text-gray-600">
                  {item?.details}
                </div>
              </div>
              <div
                className={`timeline-label absolute my-2 text-body-base text-${item?.color}`}
              >
                <span>{correntPhase === index ? "Current" : item?.label}</span>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default TimeLine;
