export const commissionReports = {
  createDate: "Erstellt am",
  sortBy: "Sortieren nach",
  newest: "Neueste",
  oldest: "Älteste",
  employeeCode: "Mitarbeiter Nummer",
  customerCode: "Kundennummen",
  channelCode: "Kanal Code",
  orderNumber: "Auftragsnummer",
  supplierCode: "Lieferanten Code",
  lineItemType: "Artikel Typ",
  saleCommissionAmountType: "Provisions Betrag",
  secondEmployeeCode: "Zweitverkäufer",
  totalSalePrice: "Verkaufspreis",
  quantity: "Menge",
  details: "Details",
  customerNumber: "Kundenummer",
  orderDate: "Auftragsdatum",
  orderVersion: "Auftragsversion",
  pimArticleNumber: "Artikelnummer",
  productGroupCode: "Warengruppe",
  commissionsource: "Provisionsquelle",
  description: "Beschreibung",
};
