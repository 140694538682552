import {
  Button,
  CheckBox,
  Form,
  InputGroup,
  Skeleton,
  Text,
  WithPermission,
  Wrapper,
} from "components";
import {rules} from "constant";
import {useDataState} from "hooks";
import {Fragment, useEffect, useMemo, useState} from "react";
import toast from "react-hot-toast";
import {ServiceApi, URLS} from "services";

type Data = {
  eloConfigId?: string;
  password?: string | null;
  userName?: string | null;
  url?: string | null;

  isEloFileManagement?: boolean;
};

export default function LocalStorage() {
  const [data, setData, setBaseData, isChanged] = useDataState<Data>({});
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const isEloFileManagement = !!data.isEloFileManagement;
  const disabled = !isEloFileManagement;
  const canRemove = !!data.eloConfigId;

  const isNew = !data.eloConfigId;

  const handleRules = useMemo(() => {
    if (disabled) return [];
    return rules.required;
  }, [disabled]);

  const setIsEloFileManagement = (isEloFileManagement: boolean) => {
    setData(p => ({...p, isEloFileManagement}));
  };
  const handleValue = (key: keyof Data) => {
    if (!isEloFileManagement) return "";
    return data[key];
  };
  const handleSetValue = (key: keyof Data) => {
    return (value: string) => {
      setData(p => ({...p, [key]: value}));
    };
  };

  const create = () => {
    setLoadingButton(true);
    const url = URLS.POST_ELO_CONFIGS;
    const body = {...data};
    ServiceApi.post(url, body)
      .then(({data: eloConfigId}) => {
        toast.success(
          "configurations.globalSettings.dataStorage.localStorageUpdateMessage",
        );
        setBaseData({...body, eloConfigId});
      })
      .finally(() => {
        setLoadingButton(false);
      });
  };
  const update = () => {
    setLoadingButton(true);
    const url = URLS.PUT_ELO_CONFIGS(data.eloConfigId);
    const body = {...data};
    ServiceApi.put(url, body)
      .then(() => {
        toast.success(
          "configurations.globalSettings.dataStorage.localStorageUpdateMessage",
        );
        setBaseData(body);
      })
      .finally(() => {
        setLoadingButton(false);
      });
  };
  const remove = () => {
    if (!canRemove)
      return toast.error(
        "configurations.globalSettings.dataStorage.localStorageRemoveErrorMessage",
      );
    setLoadingButton(true);
    const url = URLS.DELETE_ELO_CONFIGS(data.eloConfigId);
    ServiceApi.delete(url)
      .then(() => {
        toast.success(
          "configurations.globalSettings.dataStorage.localStorageUpdateMessage",
        );
        setBaseData({});
      })
      .finally(() => {
        setLoadingButton(false);
      });
  };
  const submit = !isEloFileManagement ? remove : isNew ? create : update;

  const getData = () => {
    setLoadingPage(true);
    ServiceApi.get(URLS.GET_ELO_CONFIGS)
      .then(({data}) => {
        const isEloFileManagement = !!data.eloConfigId;
        setBaseData({isEloFileManagement, ...data});
      })
      .finally(() => {
        setLoadingPage(false);
      });
  };

  useEffect(getData, []);
  return (
    <Form onSubmit={submit}>
      <Wrapper>
        <Wrapper.Header>
          <h2 className="text-heading-2 text-gray-800">
            <Text>configurations.globalSettings.dataStorage.localStorage</Text>
          </h2>
        </Wrapper.Header>
        <Wrapper.Body className="space-y-8">
          {loadingPage ? (
            <Fragment>
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Fragment>
          ) : (
            <Fragment>
              <CheckBox
                value={isEloFileManagement}
                setValue={setIsEloFileManagement}
                label="configurations.globalSettings.dataStorage.eloFileManagement"
              />
              <InputGroup
                label="configurations.globalSettings.dataStorage.url"
                value={handleValue("url")}
                setValue={handleSetValue("url")}
                rules={handleRules}
                disabled={disabled}
              />
              <InputGroup
                label="configurations.globalSettings.dataStorage.username"
                value={handleValue("userName")}
                setValue={handleSetValue("userName")}
                rules={handleRules}
                disabled={disabled}
              />
              <InputGroup
                label="configurations.globalSettings.dataStorage.password"
                value={handleValue("password")}
                setValue={handleSetValue("password")}
                rules={handleRules}
                disabled={disabled}
              />
            </Fragment>
          )}
        </Wrapper.Body>
        <WithPermission permissions={["AsS_UpsertBlobConfig"]}>
          <Wrapper.Footer className="flex items-center justify-end gap-4">
            <Button type="button" onClick={getData} variant="white" size="sm">
              <Text>global.buttons.discard</Text>
            </Button>
            <Button
              type="submit"
              variant="primary"
              size="sm"
              loading={loadingButton}
              disabled={!isChanged}
            >
              <Text>global.buttons.saveChanges</Text>
            </Button>
          </Wrapper.Footer>
        </WithPermission>
      </Wrapper>
    </Form>
  );
}
