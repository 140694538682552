import PriceSimulator from "pages/price-engine/price-simulator";

const PriceSimulatorRoutes = [
  {
    path: "",
    element: <PriceSimulator />,
  },
];

export default PriceSimulatorRoutes;
