import AllProducts from "./all-products";
import ImportProducts from "./import-product";
import Published from "./published-products";
import CheckList from "./check-list";

const Products = [
  {
    path: "all-products",
    children: [...AllProducts],
  },
  {
    path: "import-product",
    children: [...ImportProducts],
  },
  {
    path: "published-products",
    children: [...Published],
  },
  {
    path: "check-list",
    children: [...CheckList],
  },
];

export default Products;
