import {Accordion, HandleName, Icon, Skeleton, Wrapper} from "components";
import {useContext, useMemo} from "react";
import {PimContext} from "..";

export default function General() {
  const {details, loading} = useContext(PimContext);
  const aboutRows = useMemo(
    () => [
      {title: "Product Title", key: "originalName"},
      {title: "Article number", key: "articleNumber"},
      {title: "Supplier article number:", key: "supplierArticleNumber"},
      {title: "Supplier", key: "supplier"},
      {title: "Product family", key: "productFamily"},
      {title: "Program", key: "program"},
      {title: "Product group", key: "pimProductGroup"},
      {title: "Brand", key: "brand"},
    ],
    [],
  );

  const generateContent = (key: string) => {
    const rootContent = [
      "originalName",
      "articleNumber",
      "supplierArticleNumber",
    ].some(item => item === key);
    const isSupplier = key === "supplier";
    const isProgram = key === "program";
    const isBrand = key === "brand";
    const isPimProductGroup = key === "pimProductGroup";
    const isProductFamily = key === "productFamily";
    if (rootContent) {
      return details[key];
    }
    if (isSupplier) {
      return details[key]?.name;
    }
    if (isProgram) {
      return details?.supplier?.program?.name;
    }
    if (isBrand) {
      return details?.supplier?.program?.brand?.name;
    }
    if (isPimProductGroup) {
      return details[key]?.name;
    }
    if (isProductFamily) {
      return (
        <HandleName
          translations={details[key]?.translates}
          code={details[key]?.code}
          keyName="labelTitle"
        />
      );
    }
    return "";
  };

  return (
    <div className="space-y-6">
      <Accordion key={"about-product"}>
        <Accordion.Item
          active
          className={`mb-4 rounded-2xl border bg-white px-4 shadow-nested`}
        >
          <Accordion.Toggle className="flex items-center justify-between">
            <div className="flex items-center gap-[10px]">
              <div>
                <Icon
                  icon={"chevron-down"}
                  size="sm"
                  className="transition-transform group-[.accordion-item.active]:rotate-180"
                />
              </div>
              <div>
                <h3 className="text-gray-600">About Product</h3>
              </div>
            </div>
          </Accordion.Toggle>
          <>
            <Accordion.Body className={`px-4`}>
              <div className={`${loading && "space-y-2"}`}>
                {aboutRows?.map(row => {
                  return (
                    <>
                      {loading ? (
                        <Skeleton.Input />
                      ) : (
                        <>
                          {row.key ? (
                            <div className="border-b border-gray-200 py-4 last:border-none">
                              <div className=" grid grid-cols-3 gap-4">
                                <span className="col-span-1 text-body-base font-medium text-gray-800">
                                  {row.title}
                                </span>
                                <span className="text-heading-5 text-gray-700">
                                  {generateContent(row.key) || "-------------"}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </>
                  );
                })}
              </div>
            </Accordion.Body>
          </>
        </Accordion.Item>
      </Accordion>
      {/* <Accordion key={"test1"}>
        <Accordion.Item
          active
          className={`mb-4 rounded-2xl border bg-white px-4 shadow-nested`}
        >
          <Accordion.Toggle className="flex items-center justify-between">
            <div className="flex items-center gap-[10px]">
              <div>
                <Icon
                  icon={"chevron-down"}
                  size="sm"
                  className="transition-transform group-[.accordion-item.active]:rotate-180"
                />
              </div>
              <div>
                <h3 className="text-gray-600">Other info</h3>
              </div>
            </div>
          </Accordion.Toggle>
          <>
            <Accordion.Body className="px-4">
              <Accordion.Body className="px-4">
                {infoRows?.map(row => (
                  <div className=" border-b border-gray-200 py-4 last:border-none">
                    <div className=" grid grid-cols-3 gap-4">
                      <span className="col-span-1 text-body-base font-medium text-gray-800">
                        {row.title}
                      </span>
                      <span className="text-heading-5 text-gray-700">
                        {row.title}
                      </span>
                    </div>
                  </div>
                ))}
              </Accordion.Body>
            </Accordion.Body>
          </>
        </Accordion.Item>
      </Accordion> */}
    </div>
  );
}
