import {useEffect, useState} from "react";
import {ServiceApi, URLS} from "services";
import PurchasePrice from "./purchase-price";
import SalesPrice from "./sales-price";
import {usePermission} from "hooks";

type Props = {
  salePriceTemplateId?: string;
  purchasePriceTemplateId?: string;
  supplierId: string;
  programId: string;
  brandId: string;
  productId: string;
  basePrice: number;
  optionalDiscountCalculationUnitIds?: string[];
  priceType: string;
  baseData: any;
  simulateFrom?: string;
};

const PriceSimulation = ({
  salePriceTemplateId,
  purchasePriceTemplateId,
  supplierId,
  programId,
  brandId,
  productId,
  basePrice,
  optionalDiscountCalculationUnitIds,
  priceType,
  baseData,
  simulateFrom,
}: Props) => {
  const [salesPrice, setSalesPrice] = useState<any>({});
  const [purchasePrice, setPurchasePrice] = useState<any>({});
  const [loadingPurchasePrice, setLoadingPurchasePrice] = useState(true);
  const [loadingSalesPrice, setLoadingSalesPrice] = useState(true);

  const salePriceSimulationPermission = usePermission(
    "PE_GetSalePriceSimulation",
  );
  const purchasePriceSimulationPermission = usePermission(
    "PE_GetPurchasePriceSimulation",
  );

  const getPurchaseData = () => {
    const body = {
      purchasePriceTemplateId: purchasePriceTemplateId,
      supplierId: supplierId,
      programId: programId,
      brandId: brandId,
      productId: productId,
      basePrice: basePrice || 0,
      purchasePriceType: priceType,
      lineItemType: 0,
      optionalDiscountCalculationUnitIds:
        optionalDiscountCalculationUnitIds || null,
    };
    setLoadingPurchasePrice(true);
    const url = purchasePriceSimulationPermission
      ? URLS.PURCHASE_PRICE_SIMULATION
      : URLS.PURCHASE_PRICE_CALCULATION;
    ServiceApi.post(url, body)
      .then(({data}) => {
        setPurchasePrice(data);
        getSalesData({basePrice: data?.nNetPrice, salePriceType: 0});
      })
      .finally(() => setLoadingPurchasePrice(false));
  };

  const getSalesData = (price?: any) => {
    const body = {
      salePriceTemplateId: salePriceTemplateId,
      supplierId: supplierId,
      programId: programId,
      brandId: brandId,
      productId: productId,
      basePrice: price.basePrice,
      salePriceType: price.salePriceType,
      lineItemType: 0,
      saleChannelId: null,
      calculationProcessType: 1,
      optionalDiscountCalculationUnitIds:
        optionalDiscountCalculationUnitIds || null,
    };
    const url = salePriceSimulationPermission
      ? URLS.SALES_PRICE_FULL_PERMISSION
      : URLS.SALES_PRICE_NOT_FULL_PERMISSION;
    setLoadingSalesPrice(true);
    ServiceApi.post(url, body)
      .then(({data}) => {
        setSalesPrice(data);
      })
      .finally(() => setLoadingSalesPrice(false));
  };

  useEffect(() => {
    getPurchaseData();
  }, [baseData]);

  return (
    <>
      <PurchasePrice
        price={purchasePrice}
        loading={loadingPurchasePrice}
        getData={getPurchaseData}
      />
      <SalesPrice
        price={salesPrice}
        loading={loadingSalesPrice}
        getData={getSalesData}
        purchasePrice={purchasePrice?.nNetPrice}
        initialPrice={purchasePrice?.nNetPrice}
        showPriceSection
        simulateFrom={simulateFrom}
      />
    </>
  );
};

export default PriceSimulation;
