import { useContext, useState } from "react";
import { cloneDeep } from "lodash";
import { ProgramContext } from "../details";
import { InputGroup, RadioButton, Skeleton, Toggler, Wrapper, Text, Dropdown } from "components";

export default function Configuration() {
    const { loading, data, setData, supplierData } = useContext(ProgramContext);
    const [selectedItem, setSelectedItem] = useState<any>("");
    const [items] = useState([
        {
            name: "workDay",
            id: 1,
        },
        {
            name: "calendarDay",
            id: 2,
        },
    ]);
    const shippingType = (e: any) => {
        switch (e) {
            case 1:
                return "workDay";
            case 2:
                return "calendarDay";
            default:
                return "";
        }
    };

    const selectedShippingDayType = (selected: any) => {
        return items?.find((item: any) => item.id === selected)?.name || items[0].name;
    };

    const onChangeHandler = (e: any) => {
        if (!data.hasShippingInfo) {
            setData((p: any) => {
                const data = cloneDeep(p);
                if (data.shipping !== null) {
                    data.shipping.durationUnit = e;
                } else {
                    data.shipping = { minimumShippingDuration: null, durationUnit: null };
                    data.shipping.durationUnit = e;
                }
                return data;
            });
        }
    };

    const handleSetValue = (key: any) => {
        if (!data.hasShippingInfo) {
            return (value: any) =>
                setData((p: any) => {
                    const data = cloneDeep(p);
                    if (data.shipping !== null) {
                        data.shipping[key] = value;
                    } else {
                        data.shipping = { minimumShippingDuration: null, durationUnit: null };
                        data.shipping[key] = value;
                    }
                    return data;
                });
        }
    };

    const handleSetValueBank = (key: any) => {
        if (!data?.hasBankInfo) {
            return (value: any) =>
                setData((p: any) => {
                    const data = cloneDeep(p);
                    if (data.bankInfo !== null) {
                        data.bankInfo[key] = value;
                    } else {
                        data.bankInfo = {
                            bankName: null,
                            iban: null,
                            accountOwnerName: null,
                            bic: null,
                        };
                        data.bankInfo[key] = value;
                    }
                    return data;
                });
        }
    };

    return (
        <Wrapper>
            <Wrapper.Body className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-8">
                {loading ? (
                    <Skeleton.Input />
                ) : (
                    <div className="space-y-6">
                        <label
                            data-lang-map={"productManagement.masterData.suppliers.brand"}
                            className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*'] mb-3"
                        >
                            <Text>{"productManagement.masterData.suppliers.programType"}</Text>
                        </label>
                        <div className="flex space-x-2">
                            <RadioButton
                                label="productManagement.masterData.suppliers.configurable"
                                value={data.programType === 2 ? true : false}
                                setValue={() =>
                                    setData((p: any) => ({ ...p, ["programType"]: 2 }))
                                }
                            />
                            <RadioButton
                                label="productManagement.masterData.suppliers.manual"
                                value={data.programType === 1 ? true : false}
                                setValue={() =>
                                    setData((p: any) => ({ ...p, ["programType"]: 1 }))
                                }
                            />
                        </div>
                    </div>
                )}
                <div className="col-span-2 space-y-8">
                    {loading ? (
                        <Skeleton.Input />
                    ) : (
                        <Toggler
                            label={
                                "productManagement.masterData.suppliers.useDefaultMinimumShippingTime"
                            }
                            value={data.hasShippingInfo}
                            disabled={supplierData?.shipping?.minimumShippingDuration ? false : true}
                            setValue={hasShippingInfo => {
                                setData((p: any) => ({ ...p, hasShippingInfo }));
                            }}
                        />
                    )}

                    {loading ? (
                        <Skeleton.Input />
                    ) : data.hasShippingInfo && supplierData?.shipping ? (
                        <Wrapper.Section className="grid grid-cols-3 items-start gap-4 text-gray-800 [&>div:nth-child(even)]:col-span-2 [&>div:nth-child(even)]:text-body-base [&>div:nth-child(odd)]:col-span-3 [&>div:nth-child(odd)]:text-heading-6 [&>div:nth-child(odd)]:font-medium">
                            <div className="flex items-center">
                                <span>
                                    {supplierData?.shipping?.minimumShippingDuration}
                                </span>
                                <span className="ml-2">
                                    <Text>{`global.dayTypes.${shippingType(supplierData?.shipping?.durationUnit)}`}</Text>
                                </span>
                            </div>
                        </Wrapper.Section>
                    ) : (
                        <div>
                            <div className="mb-2 flex items-center justify-between space-x-4">
                                <h6 className="h6 block truncate font-normal text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']">
                                    <Text>
                                        productManagement.masterData.suppliers.defaultMinimumShippingTime
                                    </Text>
                                </h6>
                            </div>
                            <div className="mt-4" style={{ display: "flex" }}>
                                <InputGroup
                                    type="number"
                                    value={data?.shipping?.minimumShippingDuration}
                                    setValue={handleSetValue("minimumShippingDuration")}
                                    className="rounded-r-none"
                                />
                                <Dropdown>
                                    <Dropdown.Toggle
                                        as="button"
                                        type="button"
                                        className="flex h-full items-center rounded-md rounded-l-none bg-primary-light p-3 align-middle text-body-base font-medium text-primary"
                                    >
                                        <Text>{`global.dayTypes.${selectedShippingDayType(data?.shipping?.durationUnit)}`}</Text>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="mt-2 min-w-fit p-2">
                                        <ul className="w-[104px] text-gray-700">
                                            {items?.map((e: any) => (
                                                <li
                                                    key={e.id}
                                                    onClick={() => {
                                                        setSelectedItem(e.id);
                                                        onChangeHandler(e.id);
                                                    }}
                                                    className={`${e.id === selectedItem &&
                                                        "bg-primary-light text-primary "
                                                        } h6 cursor-pointer rounded-lg px-4 py-2  text-body-base font-medium first:mb-1 hover:bg-primary-light hover:text-primary`}
                                                >
                                                    <Text>{`global.dayTypes.${e?.name}`}</Text>
                                                </li>
                                            ))}
                                        </ul>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    )}
                    {loading ? (
                        <Skeleton.Input />
                    ) : (
                        <Toggler
                            label={
                                "productManagement.masterData.suppliers.useDefaultBankInfo"
                            }
                            value={data?.hasBankInfo}
                            disabled={supplierData?.bankInfo ? false : true}
                            setValue={hasBankInfo => {
                                setData((p: any) => ({ ...p, hasBankInfo }));
                            }}
                        />
                    )}
                </div>
                {loading ? (
                    <>
                        <Skeleton.Input />
                        <Skeleton.Input />
                        <Skeleton.Input />
                        <Skeleton.Input />
                    </>
                ) : data?.hasBankInfo && supplierData?.bankInfo ? (
                    <Wrapper.Section className="grid grid-cols-3 items-start gap-4 text-gray-800 col-span-2 [&>div:nth-child(even)]:text-body-base [&>div:nth-child(odd)]:text-heading-6 [&>div:nth-child(odd)]:font-medium">
                        <div className="space-y-2">
                            <div>
                                <span className="font-normal text-gray-800">
                                    <Text>
                                        productManagement.masterData.suppliers.bankName
                                    </Text>
                                    :
                                </span>
                                <span className="ml-2 text-body-base text-gray-700">
                                    {supplierData?.bankInfo?.bankName}
                                </span>
                            </div>
                            <div>
                                <span className="font-normal text-gray-800">
                                    <Text>
                                        productManagement.masterData.suppliers.accountOwnerName
                                    </Text>
                                    :
                                </span>
                                <span className="ml-2 text-body-base text-gray-700">
                                    {supplierData?.bankInfo?.accountOwnerName}
                                </span>
                            </div>
                            <div>
                                <span className="font-normal text-gray-800">
                                    <Text>productManagement.masterData.suppliers.iban</Text>:
                                </span>
                                <span className="ml-2 text-body-base text-gray-700">
                                    {supplierData?.bankInfo?.iban}
                                </span>
                            </div>
                            <div>
                                <span className="font-normal text-gray-800">
                                    <Text>productManagement.masterData.suppliers.bic</Text>:
                                </span>
                                <span className="ml-2 text-body-base text-gray-700">
                                    {supplierData?.bankInfo?.bic}
                                </span>
                            </div>
                        </div>
                    </Wrapper.Section>
                ) : (
                    <>
                        <InputGroup
                            label="productManagement.masterData.suppliers.bankName"
                            value={data?.bankInfo?.bankName}
                            setValue={handleSetValueBank("bankName")}
                            disabled={data?.hasBankInfo}
                        />
                        <InputGroup
                            label="productManagement.masterData.suppliers.accountOwnerName"
                            value={data?.bankInfo?.accountOwnerName}
                            setValue={handleSetValueBank("accountOwnerName")}
                            disabled={data?.hasBankInfo}
                        />
                        <InputGroup
                            label="productManagement.masterData.suppliers.iban"
                            value={data?.bankInfo?.iban}
                            setValue={handleSetValueBank("iban")}
                            disabled={data?.hasBankInfo}
                        />
                        <InputGroup
                            label="productManagement.masterData.suppliers.bic"
                            value={data?.bankInfo?.bic}
                            setValue={handleSetValueBank("bic")}
                            disabled={data?.hasBankInfo}
                        />
                    </>
                )}
            </Wrapper.Body>
        </Wrapper>
    );
}
