export const templates = {
  code: "Code",
  customTemplate: "Custom template",
  requiredToBeSigned: "Required to be signed",
  edit: "Edit",
  templateName: "Template Name",
  downloadDefaultTemplate: "Download default template",
  uploadWarning:
    "You can overwrite the default template and upload your customized here:",
  dragDrop: "Drag & Drop files or ",
  clickHere: "Click here",
  keyword: "Keyword",
  templateType: "Template Type",
  default: "Default",
  custom: "Custom",
  all: "All",
  active: "Active",
  notActive: "not Active",
  erp: "ERP",
  crm: "CRM",
  createdFor: "Created For",
  offers: "offers",
  orders: "orders",
  filters: "Filters",
  addNewTemplate: "Add New Template",
};
