import {
  Breadcrumb,
  Button,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Table,
  Text,
  WithPermission,
} from "components";
import {config} from "constant";
import {withPermission} from "hoc";
import {useConverter, usePermission, useToggleState} from "hooks";
import {createContext, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {ServiceApi, URLS} from "services";
import ConfigurationsDrawer from "./ConfigDrawer";
import FilterDrawer from "./FilterDrawer";
import DetailsDrawer from "./details/index";

export const ProductsContext = createContext<any>({id: ""});

function ImportProducts() {
  const controller = new AbortController();
  const [data, setData] = useState<any>();
  const [loadingList, setLoadingList] = useState(true);
  const [filterModal, setFilterModal] = useToggleState(false);
  const [configurationsModal, setConfigurationsModal] = useToggleState(false);
  const [searchParams] = useSearchParams();
  //original filter data for reset
  const originalFilterData: any = {
    keyword: "",
    language: null,
    pageNumber: 1,
    pageSize: config.pageSize,
    priceFrom: 0,
    priceTo: null,
    productFamily: null,
    providerName: null,
    sortType: null,
    version: null,
  };
  const [params, setParams] = useSearchParams();
  const [selectedID, setSelectedID] = useState([]);
  const [isOpenDrawerDetails, toggleDrawerDetails] = useToggleState(false);
  const [keyword, setKeyword] = useState("");
  const [configurationProviders, setConfigurationProviders] = useState<any>([]);
  const [isConfigurationImported, setIsConfigurationImported] = useState(true);
  const {convertDate} = useConverter();
  useEffect(() => {
    ServiceApi.get(URLS.GET_B2B_CONFIG_PROVIDERS).then(({data}) => {
      const providers = data?.map((item: any) => ({
        ...item,
        label: data?.[0].providerName,
        value: data?.[0].b2BConfigurationProviderId,
      }));
      setConfigurationProviders(providers);
      if (data?.length > 0) {
        const {providerName, version, language} = data[0];
        ServiceApi.get(
          URLS.CHECK_CONFIGURATION_PROVIDER(providerName, version, language),
        ).then(({data}) => {
          setIsConfigurationImported(data?.isImported);
        });
      }
    });
  }, []);

  const getData = () => {
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url = URLS.POST_SEARCH_PRODUCT_IMPORT_LIST;
    const config = params;
    setLoadingList(true);
    ServiceApi.post(url, {...config, signal: controller.signal})
      .then(({data}) => {
        let newData = data;
        newData?.searchResult?.forEach((item: any) => {
          item["checked"] = false;
          item?.translations?.forEach((i: any, index: number) => {
            if (index === 0) {
              item["title"] = i.title;
            }
          });
        });
        setKeyword(params.keyword);
        setData(newData);
      })
      .catch(() => {
        setData([]);
      })
      .finally(() => {
        setLoadingList(false);
      });
  };

  useEffect(() => {
    getData();
    return () => {
      controller.abort();
    };
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  const resetFilter = () => {
    setParams({});
    setKeyword("");
  };

  const handleSetFilterData = (key: string) => {
    return (value: any) => {
      setParams(prev => {
        value !== null && value !== ""
          ? key === "pageNumber" && value === 1
            ? prev.delete(key)
            : prev.set(key, value)
          : prev.delete(key);
        key !== "pageNumber" && prev.delete("pageNumber");
        return prev;
      });
    };
  };

  const getDetailsPermission = usePermission("PS_ImportPimProduct");

  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
      </div>
      <SearchBox
        onSubmit={handleSetFilterData("keyword")}
        value={keyword}
        disabled={loadingList}
        totalItems={data?.totalItems}
        onFilter={() => setFilterModal()}
        resetFilter={searchParams?.size === 0 ? undefined : resetFilter}
      />
      {!isConfigurationImported && (
        <div className="flex items-center justify-between rounded bg-warning-light p-4">
          <span className="text-body text-warning-active">
            please import provider configurations
          </span>
          <Button size="sm" light onClick={setConfigurationsModal}>
            import
          </Button>
        </div>
      )}
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data && data.length !== 0 && data?.searchResult?.length !== 0 ? (
            <>
              <Table>
                {data?.searchResult?.map((item: any) => {
                  return (
                    <tr key={item.providerProductImportId}>
                      <td>
                        <button
                          type="button"
                          onClick={() => {
                            if (!getDetailsPermission) return;
                            setSelectedID(item.providerProductImportId);
                            toggleDrawerDetails();
                          }}
                          className="inline-flex w-fit gap-2"
                        >
                          <div className="space-y-2">
                            <h6 className="text-heading-6 font-semibold text-gray-800">
                              {item.title}
                            </h6>
                            <div className="text-body-2 font-medium text-gray-500">
                              <Text>
                                productManagement.products.importProduct.article
                              </Text>
                              <span className="ml-1">#{item.productKey}</span>
                            </div>
                            <div className="text-body-2 font-medium text-gray-500">
                              <Text>
                                productManagement.products.importProduct.bestellnrLieferantArtikelVersion
                              </Text>
                              :
                              <span className="ml-1">
                                {item.bestellnrLieferantArtikelVersion}
                              </span>
                            </div>
                          </div>
                        </button>
                      </td>
                      <td className="space-y-[2px] text-left text-body-2 font-medium text-gray-600">
                        <div>
                          <Text>
                            productManagement.products.importProduct.productFamily
                          </Text>
                          :<span className="ml-1">{item.productFamily}</span>
                        </div>
                        <div>
                          <Text>
                            productManagement.products.importProduct.imported
                          </Text>
                          :
                          <span className="ml-1">
                            {convertDate(item.importedDate)}
                          </span>
                          <span className="ml-1">{item.importedBy}</span>
                        </div>
                        <div>
                          <Text>
                            productManagement.products.importProduct.version
                          </Text>
                          :<span className="ml-1">{item.version}</span>
                        </div>
                      </td>
                      <td>
                        <WithPermission permissions={["PS_ImportPimProduct"]}>
                          <Button
                            onClick={() => {
                              setSelectedID(item.providerProductImportId);
                              toggleDrawerDetails();
                            }}
                            variant="light"
                            size="sm"
                          >
                            <Text>global.buttons.details</Text>
                          </Button>
                        </WithPermission>
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages}
                pageNumber={
                  params?.get("pageNumber")
                    ? parseInt(params?.get("pageNumber") as string)
                    : 1
                }
                pageSize={
                  params?.get("pageSize")
                    ? parseInt(params?.get("pageSize") as string)
                    : config.pageSize
                }
                setActivePage={handleSetFilterData("pageNumber")}
                onPageSizeChange={handleSetFilterData("pageSize")}
              />
            </>
          ) : (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
            </div>
          )}
        </>
      )}
      <ProductsContext.Provider value={{id: selectedID}}>
        <FilterDrawer
          isOpen={filterModal}
          data={data}
          keyword={keyword}
          setKeyword={setKeyword}
          toggle={setFilterModal}
          onChange={handleSetFilterData}
          resetFilter={resetFilter}
          setParams={setParams}
          params={params}
        />
        <ConfigurationsDrawer
          isOpen={configurationsModal}
          toggle={setConfigurationsModal}
          configurationProviders={configurationProviders}
          setIsConfigurationImported={setIsConfigurationImported}
        />
        <DetailsDrawer
          isOpen={isOpenDrawerDetails}
          toggle={toggleDrawerDetails}
          getDataList={getData}
          selectedID={selectedID}
        />
      </ProductsContext.Provider>
    </div>
  );
}
export default withPermission(ImportProducts, ["PS_ImportPimProduct"]);
