import { NoItems, SearchBox, Skeleton, WithPermission } from "components";
import { useTranslate } from "hooks";
import { useContext, useEffect, useState } from "react";
import { ServiceApi, URLS } from "services";
import { config } from "constant";
import ConversationCard from "./conversation-card";
import { CompletenessContext } from "../..";
import { CheckListItem, ItemType } from "types/conversation";
import AddOrReply from "./add-reply";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { UserItem } from "types/user";
import Replies from "./replies";

export default function Conversations() {
  const {
    checkList,
    conversationData,
    loadingConversation,
    getConversationData,
    conversationParams,
    setConversationParams } = useContext(CompletenessContext);
  const [checkListOptions, setCheckListOptions] = useState<[]>();
  const [itemToReply, setItemToReply] = useState<ItemType | undefined>();
  const [users, setUsers] = useState<any>([]);
  const translate = useTranslate();
  const [selectedDiscussion, setSelectedDiscussion] = useState<ItemType>();
  const orderOption = [
    { value: 0, label: translate("productManagement.products.completeness.oldest") },
    { value: 1, label: translate("productManagement.products.completeness.newest") }
  ]

  const handleSearchUser = async (filter: string) => {
    const url = URLS.GET_B2B_USERS_URL;
    const params = {
      pageNumber: 1,
      pageSize: 1000,
      keyword: filter,
    };
    return ServiceApi.get(url, { params }).then(({ data }) => {
      const users = data.items.map((user: UserItem) => ({
        value: user.userId,
        label: `${user.firstName} ${user.lastName}`,
      }));
      setUsers(users);
      return users;
    });
  };

  const promiseOptionsUser = (inputValue: string) =>
    new Promise<any[]>(resolve => {
      setTimeout(() => {
        resolve(handleSearchUser(inputValue));
      }, 200);
    });

  const updateReplies = (data: ItemType[]) => {
    const updatedObject = data.filter((i) => i.id === selectedDiscussion?.id)[0];
    setSelectedDiscussion(updatedObject);
  }

  const createCheckListOptions = () => {
    const clone = checkList?.map((item: CheckListItem) => ({
      value: item.checkListId,
      label: item.title,
    }));
    clone?.unshift({ value: null, label: translate("global.all") });
    setCheckListOptions(clone);
    return clone;
  };

  const getSelectedCheckList = () => {
    const selected = checkListOptions?.filter(
      (item: any) => item?.value === conversationParams?.checkListId,
    )?.[0];
    if (selected)
      return {
        label: `${translate('productManagement.products.completeness.checkListItem')}: ${selected?.['label']}`,
        value: selected?.['value'],
      }
  };

  const getSelectedSortType = () => {
    const selected = orderOption?.filter(
      (item: any) => item?.value === conversationParams?.sortType,
    )?.[0];
    if (selected)
      return {
        label: `${translate('productManagement.products.completeness.sortBy')}: ${selected?.['label']}`,
        value: selected?.['value'],
      }
  };

  const getSelectedPerson = () => {
    const selected = users?.filter(
      (item: any) => item?.value === conversationParams?.createdUserId,
    )?.[0];
    if (selected)
      return {
        label: `${translate('productManagement.products.completeness.person')}: ${selected?.['label']}`,
        value: selected?.['value'],
      }
  };

  useEffect(
    () => {
      if (selectedDiscussion)
        updateReplies(conversationData);
    }, [conversationData]);

  useEffect(
    () => {
      createCheckListOptions();
    }, []);

  return (
    <div className="relative">
      <div className="space-y-3">
        <SearchBox
          value={conversationParams?.keyword || ""}
          onSubmit={(e) => { setConversationParams({ ...conversationParams, keyword: e }) }}
          disabled={loadingConversation}
          loading={loadingConversation}
          variant="gray"
          className="[&_input]:!h-8 [&_button.search-btn]:!h-9 [&>div]:!w-full [&_.input-wrapper]:!flex-1"
        />
        <div className="flex gap-3 flex-wrap">
          <Select
            styles={config.styleSelectReactInconversation}
            value={getSelectedCheckList()}
            isLoading={!checkList}
            isSearchable={true}
            options={checkListOptions}
            onChange={(e: any) => {
              setConversationParams({ ...conversationParams, checkListId: e.value })
            }}
            placeholder={<p className="truncate">{translate('productManagement.products.completeness.checkListItem')}: {translate('global.select')}...</p>}
          />
          <AsyncSelect
            value={getSelectedPerson()}
            cacheOptions
            loadOptions={promiseOptionsUser}
            defaultOptions
            styles={config.styleSelectReactInconversation}
            onChange={(e: any) => {
              setConversationParams({ ...conversationParams, createdUserId: e?.value || null })
            }}
            isClearable
            placeholder={<p className="truncate">{translate('productManagement.products.completeness.person')}: {translate('global.select')}...</p>}
          />
          <Select
            value={getSelectedSortType()}
            styles={config.styleSelectReactInconversation}
            options={orderOption}
            onChange={(e: any) => {
              setConversationParams({ ...conversationParams, sortType: e.value })
            }}
            placeholder={<p className="truncate">{translate('productManagement.products.completeness.sortBy')}: {translate('global.select')}...</p>}
          />
        </div>
        <div className="space-y-3 pb-24">
          {loadingConversation &&
            [1, 2, 3].map((i) =>
              <Skeleton.TextArea key={i} />
            )}
          {!loadingConversation && conversationData?.length !== 0 &&
            conversationData?.map((item: ItemType) => <>
              <ConversationCard item={item} handleReply={(item: ItemType) => setItemToReply(item)} showReplies={() => setSelectedDiscussion(item)} />
            </>)
          }
        </div>
        {!loadingConversation && conversationData?.length === 0 &&
          <div className="flex w-full flex-col items-center space-y-4">
            <NoItems />
          </div>
        }
        <WithPermission permissions={["PS_CreateDiscussion"]}>
          <AddOrReply
            updateConversations={() => { getConversationData(); setItemToReply(undefined); }}
            type={!!selectedDiscussion?.id ? "reply" : "add"}
            itemToReply={!!selectedDiscussion?.id ? selectedDiscussion : itemToReply} />
        </WithPermission>
        {itemToReply?.id &&
          <AddOrReply
            type="reply"
            itemToReply={itemToReply}
            updateConversations={() => { getConversationData(); setItemToReply(undefined); }}
            removeItemToReply={() => setItemToReply(undefined)} />
        }
      </div>
      {!!selectedDiscussion?.id &&
        <Replies
          isOpen={!!selectedDiscussion?.id}
          toggle={() => setSelectedDiscussion(undefined)}
          item={selectedDiscussion}
          setItemToReply={(i) => setItemToReply(i)}
          loading={loadingConversation}
        />}
    </div>
  );
}
