import {NotificationManager} from "common/notifications";
import {
  Accordion,
  Button,
  Drawer,
  Icon,
  Image,
  LazyImage,
  Skeleton,
  Text,
  Wrapper,
} from "components";
import {useConverter, useToggleState} from "hooks";
import {isEmpty} from "lodash";
import {Fragment, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {ServiceApi, URLS} from "services";
import Lightbox from "yet-another-react-lightbox";
import Share from "yet-another-react-lightbox/plugins/share";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import {AxiosRequestConfig} from "axios";

type drawerProps = {
  isOpen: boolean;
  toggle: () => void;
  getDataList: () => void;
  selectedID: any;
};

export default function Details({
  isOpen,
  toggle,
  getDataList,
  selectedID,
}: drawerProps) {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const {convertDate} = useConverter();
  const [toggler, setToggler] = useToggleState();
  const [selectedImage, setSelectedImage] = useState<any>();
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [productId, setProductId] = useState<any>("");
  const [imageUrls, setImageUrls] = useState([]);

  const getData = () => {
    if (isOpen) {
      setShowMessage(false);
      const url = URLS.GET_PRODUCT_IMPORT_DETAILS(selectedID);
      setLoading(true);
      ServiceApi.get(url)
        .then(({data}) => {
          setData(data);
          const clone: any = [];
          if (!isEmpty(data)) {
            fetchImages(data?.media);
            data?.media?.map((image: any) => {
              if (image.thumbnailUrl !== "") {
                clone.push({src: image.thumbnailUrl});
              }
            });
          }
          setImageUrls(clone);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(getData, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClickImport = () => {
    const url = URLS.POST_PRODUCT_IMPORT;
    setLoading(true);
    ServiceApi.post(url, {
      providerProductImportId: data?.providerProductImportId,
    }).then(data => {
      NotificationManager.success(
        "global.toast.import-msg",
        "global.toast.import-title",
      );
      setShowMessage(true);
      setProductId(data?.data);
      getDataList();
    });
  };

  const imageSrcHandler = async (src: string) => {
    //url
    let url;
    if (src?.includes("/get-file")) {
      const config: AxiosRequestConfig = {
        responseType: "blob",
        params: {withoutSubscriptionKey: true},
      };
      await ServiceApi.get(src, config).then(({data}) => {
        url = URL.createObjectURL(data);
      });
    } else {
      url = src;
    }
    return url;
  };

  async function fetchImages(data: any) {
    let urlImg: any = [];
    await data?.forEach(async (image: any) => {
      if (image.thumbnailUrl !== "") {
        const test = await imageSrcHandler(image.thumbnailUrl);
        urlImg.push({src: test});
      } else {
        urlImg.push({src: ""});
      }
    });
    setImageUrls(urlImg);
  }

  return (
    <Drawer isOpen={isOpen} toggle={toggle} size="xl">
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pr-4 pt-6">
          <div className="flex items-center justify-between">
            <h2 className="text-heading-2 text-gray-800">
              <Text>
                productManagement.products.importProduct.productDetails
              </Text>
            </h2>
            <Button size="sm" variant={"light"} onClick={() => toggle()}>
              <Icon icon="times" className="mr-1" />
              <Text>global.buttons.close</Text>
            </Button>
          </div>
        </Drawer.Header>
        <Drawer.Body
          className={`p-none space-y-6  ${
            showMessage && "flex items-center justify-center"
          }  `}
        >
          {showMessage ? (
            <div className="space-y-8 ">
              <div className="flex items-center justify-center">
                <Icon
                  icon={"file-check"}
                  size={"4x"}
                  className="text-success"
                />
              </div>
              <div className="text-center">
                <h6 className="font-normal text-gray-800">
                  <Text>
                    productManagement.products.importProduct.productAddedSuccessfully
                  </Text>
                </h6>
              </div>
            </div>
          ) : loading ? (
            <Fragment>
              <Skeleton.List />
              <Skeleton.List />
              <Skeleton.List />
              <Skeleton.List />
            </Fragment>
          ) : (
            <Fragment>
              <Wrapper.Section className="grid grid-cols-1 items-start !py-3 text-gray-800 [&>div:nth-child(even)]:col-span-2 [&>div:nth-child(even)]:text-body-base [&>div:nth-child(odd)]:col-span-3 [&>div:nth-child(odd)]:text-heading-6 [&>div:nth-child(odd)]:font-medium">
                <Accordion>
                  <Accordion.Item active className="border-none">
                    <Accordion.Toggle>
                      <div className="flex items-center gap-[10px]">
                        <div>
                          <Icon
                            icon={"chevron-down"}
                            className="transition-transform group-[.accordion-item.active]:rotate-180"
                          />
                        </div>
                        <div>
                          <h3 className="text-gray-600">
                            <Text>
                              productManagement.products.importProduct.basicInfo
                            </Text>
                          </h3>
                        </div>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Body className="grid grid-cols-1">
                      <div className="grid grid-cols-6 p-2">
                        <div className="col-start-1 col-end-4 text-body-base font-medium text-gray-800">
                          <Text>
                            productManagement.products.importProduct.productTitle
                          </Text>
                        </div>
                        <div className="col-start-4 col-end-7 ">
                          <h5 className="text-body-base font-normal text-gray-700">
                            {data?.title}
                          </h5>
                        </div>
                      </div>
                      <div className="grid grid-cols-6 bg-gray-100 p-2">
                        <div className="col-start-1 col-end-4 text-body-base font-medium text-gray-800">
                          <Text>
                            productManagement.products.importProduct.language
                          </Text>
                        </div>
                        <div className="col-start-4 col-end-7">
                          <h5 className="text-body-base font-normal text-gray-700">
                            {data?.language}
                          </h5>
                        </div>
                      </div>
                      <div className="grid grid-cols-6 p-2">
                        <div className="col-start-1 col-end-4 text-body-base font-medium text-gray-800">
                          <Text>
                            productManagement.products.importProduct.articleNumber
                          </Text>
                        </div>
                        <div className="col-start-4 col-end-7">
                          <h5 className="text-body-base font-normal text-gray-700">
                            {data?.articleNumber}
                          </h5>
                        </div>
                      </div>
                      <div className="grid grid-cols-6 bg-gray-100 p-2">
                        <div className="col-start-1 col-end-4 text-body-base font-medium text-gray-800">
                          <Text>
                            productManagement.products.importProduct.price
                          </Text>
                        </div>
                        <div className="col-start-4 col-end-7">
                          <h5 className="text-body-base font-normal text-gray-700">
                            {data?.price}
                          </h5>
                        </div>
                      </div>
                      <div className="grid grid-cols-6 p-2">
                        <div className="col-start-1 col-end-4 text-body-base font-medium text-gray-800">
                          <Text>
                            productManagement.products.importProduct.productFamily
                          </Text>
                        </div>
                        <div className="col-start-4 col-end-7">
                          <h5 className="text-body-base font-normal text-gray-700">
                            {data?.productFamily}
                          </h5>
                        </div>
                      </div>
                      <div className="grid grid-cols-6 bg-gray-100 p-2">
                        <div className="col-start-1 col-end-4 text-body-base font-medium text-gray-800">
                          <Text>
                            productManagement.products.importProduct.shortDescription
                          </Text>
                        </div>
                        <div className="col-start-4 col-end-7">
                          <h5 className="text-body-base font-normal text-gray-700">
                            {data?.shortDescription}
                          </h5>
                        </div>
                      </div>
                      <div className="grid grid-cols-6 p-2">
                        <div className="col-start-1 col-end-4 text-body-base font-medium text-gray-800">
                          <Text>
                            productManagement.products.importProduct.description
                          </Text>
                        </div>
                        <div className="col-start-4 col-end-7">
                          <h5 className="text-body-base font-normal text-gray-700">
                            {data?.description}
                          </h5>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Wrapper.Section>
              {data?.attributeGroupsInfos?.map((item: any, indexItem: any) => {
                return (
                  <Wrapper.Section
                    key={indexItem}
                    className="grid grid-cols-2 items-start !py-3 text-gray-800 [&>div:nth-child(even)]:col-span-2 [&>div:nth-child(even)]:text-body-base [&>div:nth-child(odd)]:col-span-3 [&>div:nth-child(odd)]:text-heading-6 [&>div:nth-child(odd)]:font-medium"
                  >
                    <Accordion>
                      <Accordion.Item className="border-none">
                        <Accordion.Toggle>
                          <div className="flex items-center gap-[10px]">
                            <div>
                              <Icon
                                icon={"chevron-down"}
                                className="transition-transform group-[.accordion-item.active]:rotate-180"
                              />
                            </div>
                            <div>
                              <h3 className="text-gray-600">{item.name}</h3>
                            </div>
                          </div>
                        </Accordion.Toggle>
                        <Accordion.Body className="grid grid-cols-1 px-4">
                          {item?.attributes?.map(
                            (attribute: any, index: any) => {
                              return (
                                <div
                                  key={attribute?.code}
                                  className={`grid grid-cols-6 p-2 ${
                                    index % 2 ? "bg-gray-100" : ""
                                  }`}
                                >
                                  <div className="col-start-1 col-end-4 text-body-base font-medium text-gray-800">
                                    {attribute?.name}
                                  </div>
                                  <div className="col-start-4 col-end-7">
                                    <h5 className="text-body-base font-normal text-gray-700">
                                      {attribute?.value}
                                    </h5>
                                  </div>
                                </div>
                              );
                            },
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Wrapper.Section>
                );
              })}
              <Wrapper.Section className="grid grid-cols-2 items-start !py-3 text-gray-800 [&>div:nth-child(even)]:col-span-2 [&>div:nth-child(even)]:text-body-base [&>div:nth-child(odd)]:col-span-3 [&>div:nth-child(odd)]:text-heading-6 [&>div:nth-child(odd)]:font-medium">
                <Accordion>
                  <Accordion.Item className="border-none">
                    <Accordion.Toggle>
                      <div className="flex items-center gap-[10px]">
                        <div>
                          <Icon
                            icon={"chevron-down"}
                            className="transition-transform group-[.accordion-item.active]:rotate-180"
                          />
                        </div>
                        <div>
                          <h3 className="text-gray-600">
                            <Text>
                              productManagement.products.importProduct.media
                            </Text>
                          </h3>
                        </div>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Body className="grid grid-cols-1 px-4">
                      <div className="flex flex-wrap gap-4">
                        {data?.media?.map((image: any, index: any) => {
                          if (image?.thumbnailUrl) {
                            return (
                              <LazyImage
                                isDynamic
                                key={image?.thumbnailUrl}
                                src={image?.thumbnailUrl}
                                className="w-[275px] max-w-lg flex-none cursor-pointer bg-gray-100"
                                imageClassName=" w-72 object-contain"
                                onClick={() => {
                                  setSelectedImage(index);
                                  setToggler();
                                }}
                              />
                            );
                          }
                          return <></>;
                        })}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Wrapper.Section>
            </Fragment>
          )}
        </Drawer.Body>
        <Drawer.Footer>
          {showMessage ? (
            <div className="flex flex-row-reverse">
              <Button
                type="button"
                onClick={() =>
                  navigate(
                    `/productManagement/products/all-products/${productId}`,
                  )
                }
              >
                <Text>
                  productManagement.products.importProduct.viewProduct
                </Text>
              </Button>
            </div>
          ) : (
            <div
              className={`${
                data?.isImportedToPim
                  ? "flex flex-wrap items-center justify-between gap-2"
                  : "flex flex-row-reverse"
              } `}
            >
              {data?.isImportedToPim && (
                <div className="flex items-center">
                  <div className="mr-4 flex h-[44px] items-center gap-1 rounded-xl bg-warning-light p-2">
                    <Icon
                      icon="circle-exclamation"
                      className="text-warning-active"
                    />
                    <h5 className="font-normal text-warning-active">
                      <Text>
                        productManagement.products.importProduct.thisProductWasImportedAt
                      </Text>
                    </h5>
                    <h5 className="font-normal text-warning-active">
                      {convertDate(data?.lastImportToPimDate)}
                    </h5>
                  </div>
                  <Button
                    type="button"
                    light
                    onClick={() =>
                      navigate(
                        `/productManagement/products/all-products/${data?.lastImportToPimId}`,
                      )
                    }
                  >
                    <Text>
                      productManagement.products.importProduct.viewProduct
                    </Text>
                  </Button>
                </div>
              )}
              <div>
                <Button type="button" onClick={onClickImport} loading={loading}>
                  <Icon className="mr-2" icon={"file-import"} />
                  {!data?.isImportedToPim ? (
                    <Text>
                      productManagement.products.importProduct.importProduct
                    </Text>
                  ) : (
                    <Text>
                      productManagement.products.importProduct.importAgain
                    </Text>
                  )}
                </Button>
              </div>
            </div>
          )}
        </Drawer.Footer>
      </Drawer.Menu>
      <Lightbox
        open={toggler}
        index={selectedImage}
        close={() => {
          document.body.style.overflow = "visible";
          setToggler();
        }}
        slides={imageUrls}
        plugins={[Zoom, Slideshow, Share]}
      />
    </Drawer>
  );
}
