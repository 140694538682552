import {
  Badge,
  Breadcrumb,
  Button,
  Icon,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Table,
  Text,
  WithPermission,
} from "components";
import {variantType} from "types";
import {config} from "constant";
import {withPermission} from "hoc";
import {usePermission} from "hooks";
import {useEffect, useState} from "react";
import {Link, useSearchParams} from "react-router-dom";
import {ServiceApi, URLS} from "services";

type MobileNumberItems = {
  countryCode: Number;
  number: Number;
};

type CustomerListItems = {
  id: string;
  address: any;
  avatar: null | {
    id: string;
    thumbnailUrl: string;
    type: string;
    url: string;
  };
  code: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  mobileNumber: MobileNumberItems;
  phoneNumber: MobileNumberItems;
  cimNumber: string;
  isErpCustomer: string;
  customerType: number;
  approvalStatus: number;
};

type ListType = {
  items?: CustomerListItems[];
  pageNumber?: number;
  pageSize?: number;
  totalItems?: number;
  totalPages?: number;
};

//Customer List

function CustomerList() {
  const controller = new AbortController();
  const [data, setData] = useState<ListType>();
  const [loadingList, setLoadingList] = useState(true);
  const [searchParams] = useSearchParams();
  //original filter data for reset
  const originalFilterData: any = {
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  };

  const customerType = [
    {
      text: "customers.customerList.individual",
      icon: "user",
      variant: "info",
      placeHolder: "user",
    },
    {
      text: "customers.customerList.companyType",
      icon: "building",
      variant: "primary",
      placeHolder: "company",
    },
  ];
  const approvalStatus = [
    {
      text: "customers.customerList.none",
      variant: "secondary",
    },
    {
      text: "customers.customerList.pending",
      variant: "warning",
    },
    {
      text: "customers.customerList.approved",
      variant: "success",
    },
    {
      text: "customers.customerList.suspended",
      variant: "danger",
    },
  ];

  const [params, setParams] = useSearchParams();

  const handleSetParams = (key: string) => {
    return (value: any) => {
      setParams(prev => {
        value !== null && value !== ""
          ? key === "pageNumber" && value === 1
            ? prev.delete(key)
            : prev.set(key, value)
          : prev.delete(key);
        key !== "pageNumber" && prev.delete("pageNumber");
        return prev;
      });
    };
  };

  const getData = () => {
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url = URLS.GET_CUSTOMERS_URL;
    const config = {params};
    setLoadingList(true);
    ServiceApi.get(url, {...config, signal: controller.signal})
      .then(({data}) => {
        setData(data);
      })
      .catch(() => {
        setData({});
      })
      .finally(() => {
        setLoadingList(false);
      });
  };
  useEffect(() => {
    getData();
    return () => {
      controller.abort();
    };
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDetailsPermission = usePermission("AS_GetCustomer");

  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
      </div>
      <SearchBox
        value={params?.get("keyword") || ""}
        onSubmit={handleSetParams("keyword")}
        disabled={loadingList}
        totalItems={data?.totalItems}
        onReload={getData}
        loading={loadingList}
      />
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data &&
          Object.keys(data).length !== 0 &&
          data?.items?.length !== 0 ? (
            <>
              <Table>
                {data?.items?.map(item => {
                  const type = customerType[item.customerType];
                  const status = approvalStatus[item.approvalStatus];
                  return (
                    <tr key={item.id}>
                      <td>
                        <Link
                          to={
                            getDetailsPermission
                              ? `${item.id}?type=${item.customerType}`
                              : ""
                          }
                          className="flex-center flex w-fit gap-2"
                          replace={!getDetailsPermission}
                        >
                          <Table.Image
                            placeholder={type.placeHolder}
                            src={item.avatar?.thumbnailUrl}
                            alt={item.firstName}
                            className="h-[78px] w-[78px] [&_img]:object-cover"
                          />
                          <div className="space-y-2">
                            {item?.firstName && (
                              <div>
                                <h6 className="text-heading-6 font-semibold text-gray-800">{`${item?.firstName} ${item?.lastName}`}</h6>
                              </div>
                            )}
                            {item?.cimNumber && (
                              <div>
                                <Icon
                                  className="mr-2 font-black text-gray-500"
                                  size="1x"
                                  icon="user-tag"
                                />
                                <span className="text-body-2 font-medium text-gray-600">
                                  <Text>customers.customerList.cimNumber</Text>:
                                </span>
                                <span className="text-body-2 font-medium text-gray-500">
                                  {` ${item?.cimNumber}`}
                                </span>
                              </div>
                            )}
                            {item.customerType === 0 ? (
                              item?.emailAddress && (
                                <div>
                                  <Icon
                                    className="mr-2 font-black text-gray-500"
                                    size="1x"
                                    icon="envelope"
                                  />
                                  <span className="text-body-2 font-medium text-gray-500">
                                    {item?.emailAddress}
                                  </span>
                                </div>
                              )
                            ) : (
                              <Badge variant={status.variant as variantType}>
                                <Text>{status.text}</Text>
                              </Badge>
                            )}
                            <div className="space-y-2 text-left xl:hidden">
                              {item?.mobileNumber && (
                                <div>
                                  <Icon
                                    className="mr-2 font-black text-gray-500"
                                    size="1x"
                                    icon="mobile"
                                  />
                                  <span className="text-body-2 font-medium text-gray-500">
                                    {`${item?.mobileNumber?.countryCode} ${item?.mobileNumber?.number}`}
                                  </span>
                                </div>
                              )}
                              {item?.customerType === 1 ? (
                                <>
                                  {item?.address && (
                                    <>
                                      <Icon
                                        className="mr-2 font-black text-gray-500"
                                        size="1x"
                                        icon="location"
                                      />
                                      <span className="text-body-2 font-medium text-gray-500">
                                        {`${item?.address?.city}`}
                                      </span>
                                    </>
                                  )}
                                </>
                              ) : (
                                item?.phoneNumber && (
                                  <div>
                                    <Icon
                                      className="mr-2 font-black text-gray-500"
                                      size="1x"
                                      icon="phone"
                                    />
                                    <span className="text-body-2 font-medium text-gray-500">
                                      {`${item?.phoneNumber?.countryCode} ${item?.phoneNumber?.number}`}
                                    </span>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </Link>
                      </td>
                      <td className="hidden xl:table-cell">
                        <div className="space-y-2 text-left">
                          {item?.mobileNumber && (
                            <div>
                              <Icon
                                className="mr-2 font-black text-gray-500"
                                size="1x"
                                icon="mobile"
                              />
                              <span className="text-body-2 font-medium text-gray-500">
                                {`${item?.mobileNumber?.countryCode} ${item?.mobileNumber?.number}`}
                              </span>
                            </div>
                          )}
                          {item?.customerType === 1 ? (
                            <>
                              {item?.address && (
                                <>
                                  <Icon
                                    className="mr-2 font-black text-gray-500"
                                    size="1x"
                                    icon="location"
                                  />
                                  <span className="text-body-2 font-medium text-gray-500">
                                    {`${item?.address?.city}`}
                                  </span>
                                </>
                              )}
                            </>
                          ) : (
                            item?.phoneNumber && (
                              <div>
                                <Icon
                                  className="mr-2 font-black text-gray-500"
                                  size="1x"
                                  icon="phone"
                                />
                                <span className="text-body-2 font-medium text-gray-500">
                                  {`${item?.phoneNumber?.countryCode} ${item?.phoneNumber?.number}`}
                                </span>
                              </div>
                            )
                          )}
                        </div>
                      </td>
                      <td>
                        <Badge variant={type.variant as variantType}>
                          <Icon icon={type.icon} className="mr-2" />
                          <Text>{type.text}</Text>
                        </Badge>
                      </td>
                      <td>
                        <WithPermission permissions={["AS_GetCustomer"]}>
                          <Button
                            as={Link}
                            to={`${item.id}?type=${item.customerType}`}
                            variant="light"
                            size="sm"
                          >
                            <Text>global.buttons.details</Text>
                          </Button>
                        </WithPermission>
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages}
                pageNumber={
                  params?.get("pageNumber")
                    ? parseInt(params?.get("pageNumber") as string)
                    : 1
                }
                pageSize={
                  params?.get("pageSize")
                    ? parseInt(params?.get("pageSize") as string)
                    : config.pageSize
                }
                setActivePage={handleSetParams("pageNumber")}
                onPageSizeChange={(e: any) =>
                  setParams(p => ({...p, pageSize: e}))
                }
              />
            </>
          ) : (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
            </div>
          )}
        </>
      )}
    </div>
  );
}
export default withPermission(CustomerList, ["AS_GetCustomer"]);
