import { Icon, Text } from "components";
import { useEffect, useState } from "react";
import { unset } from "lodash";
import { useConverter } from "hooks";
import { purchaseTemplatesSteps, salesTemplatesSteps } from "constant";

type FullPermissionSalesPriceProps = {
  data: any;
  type: "sales" | "purchase";
  simulateType?: number;
  simulateFrom?: string;
};

export default function FullPermissionSalesPrice({
  data,
  type,
  simulateType,
  simulateFrom,
}: FullPermissionSalesPriceProps) {
  const { convertAmount } = useConverter();
  const [customData, SetCustomData] = useState<any>({});
  const [simulation, setSimulation] = useState<any>([]);
  const [simulateTypeState, setSimulateTypeState] = useState(0);
  const [simulateTypeProps, setSimulateTypeProps] = useState(0);

  const stepArray = type === "sales" ? [0, 1, 2, 3, 4, 5] : [0, 1];

  const PurchasePriceType = [
    {
      name: "Gross",
      simulate: "grossSimulationItems",
      id: 0,
    },
    {
      name: "Net",
      simulate: "netSimulationItems",
      id: 1,
    },
    {
      name: "NNet",
      simulate: "nNetSimulationItems",
      id: 2,
    },
  ];
  const SalesPriceType = [
    {
      name: "NNet",
      simulate: "nNetSimulationItems",
      id: 0,
    },
    {
      name: "Vk0",
      simulate: "vk0SimulationItems",
      id: 1,
    },
    {
      name: "Vk1",
      simulate: "vk1SimulationItems",
      id: 2,
    },
    {
      name: "Vk2",
      simulate: "vk2SimulationItems",
      id: 3,
    },
    {
      name: "Vk3",
      simulate: "vk3SimulationItems",
      id: 4,
    },
  ];
  const SalesPriceTypeFromPim = [
    {
      name: "NNet",
      simulate: "nNetSimulationItems",
      id: 0,
    },
    {
      name: "Vk0",
      simulate: "vk0SimulationItems",
      id: 1,
    },
    {
      name: "Vk1",
      simulate: "vk1SimulationItems",
      id: 2,
    },
    {
      name: "Vk2",
      simulate: "vk2SimulationItems",
      id: 3,
    },
  ];

  const priceType =
    type === "sales"
      ? simulateFrom === "pim"
        ? SalesPriceTypeFromPim
        : SalesPriceType
      : PurchasePriceType;

  useEffect(() => {
    const clone = { ...data };
    unset(clone, "simulationResult");
    SetCustomData(clone);
    setSimulateTypeProps(simulateType as number);
    const showPurchasePriceArray = priceType.filter(
      item => item.id !== simulateType,
    );
    setSimulateTypeState(
      showPurchasePriceArray[showPurchasePriceArray?.length - 1].id,
    );
  }, [data]);

  useEffect(() => {
    setSimulation(data?.[priceType[simulateTypeState]?.simulate]);
  }, [simulateTypeState]);

  return (
    <>
      <div className="sticky z-10 top-0 mb-8 mt-4 flex items-center gap-4 bg-white py-2">
        {priceType?.map(type => {
          return (
            <>
              {simulateTypeProps !== type.id && (
                <div
                  onClick={() => setSimulateTypeState(type.id)}
                  className={`cursor-pointer rounded-full p-4 ${simulateTypeState === type.id ? "bg-primary text-white" : "bg-gray-200"}`}
                >
                  {type.name}
                </div>
              )}
            </>
          );
        })}
      </div>
      <div className="space-y-4 px-4">
        {Object.keys(customData).some((key: string) => customData[key]) ? (
          <div className="relative space-y-4 border-l-4 border-gray-300 p-4 pt-0 ">
            {stepArray?.map((steps: any, index: number) => {
              const currentIndex = type === "sales" ? index + 1 : index;
              let indexStep = simulation?.filter(
                (item: any) => item.step === currentIndex,
              );
              return (
                <>
                  <div className="space-y-4">
                    <span className="absolute left-[-10px] h-4 w-4 rounded bg-primary"></span>
                    <div className="flex flex-col space-y-2">
                      <span className="text-primary">
                        <Text>priceEngine.calculationLevel.step</Text>
                        {index + 1} -{" "}
                        {type === "sales" ? (
                          <Text>{salesTemplatesSteps[index].label}</Text>
                        ) : (
                          <Text>{purchaseTemplatesSteps[index].label}</Text>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4 rounded-md bg-gray-100 p-4">
                    {indexStep?.length === 0 ? (
                      <p className=" text-center text-xs">
                        <Text>global.noData</Text>
                      </p>
                    ) : (
                      indexStep?.map((step: any, index: number) => {
                        return (
                          <div
                            className={`flex flex-col gap-2 ${index > 0 && "border-t-2 border-t-gray-300 pt-2"
                              } *:text-body-2`}
                          >
                            <span className="font-medium">{step?.title}</span>
                            <span className="font-normal">
                              {step?.operationText}
                            </span>
                          </div>
                        );
                      })
                    )}
                  </div>
                </>
              );
            })}
          </div>
        ) : (
          <div className="flex flex-col gap-8 border-b p-4 pb-5">
            <div className="flex min-h-14 items-center gap-2 rounded-lg bg-gray-100 p-4">
              <Icon icon="circle-info" className="text-warning-active" />
              <span className="text-body-2 font-medium text-gray-600">
                <Text>
                  productManagement.products.Details.NoAssignedPriceTemplate
                </Text>
              </span>
            </div>
            <span>
              <Text>productManagement.products.Details.salesPrice</Text> (VK2):{" "}
              <span className="ml-4 text-heading-1 font-normal text-gray-600 line-through	">
                {convertAmount(data?.simulationResult?.salePrice2)}
              </span>
            </span>
          </div>
        )}
      </div>
    </>
  );
}
