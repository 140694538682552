import {useContext, useState} from "react";
import {PimContext} from "..";
import {Icon, LazyImage} from "components";
import {useToggleState} from "hooks";
import {AxiosRequestConfig} from "axios";
import {ServiceApi} from "services";
import imageLoadingGif from "assets/icons/image Loading.gif";

export default function Media() {
  const {details} = useContext(PimContext);
  const [selectedImage, setSelectedImage] = useState<any>();
  const [loadingFile, toggleLoadingFile] = useToggleState();
  const [selectedFileIndex, setSelectedFileIndex] = useState<number>();

  const mediaTypeChecker = (type: string) => {
    if (type?.toLowerCase()?.includes("image")) {
      return {type: "image", icon: ""};
    }
    if (type?.toLowerCase()?.includes("excel")) {
      return {type, icon: "file-excel"};
    }
    if (type?.toLowerCase()?.includes("word")) {
      return {type, icon: "file-word"};
    }
    if (type?.toLowerCase()?.includes("pdf")) {
      return {type, icon: "file-pdf"};
    }
    if (type?.toLowerCase()?.includes("text")) {
      return {type, icon: "file-lines"};
    }
    if (
      type?.toLowerCase()?.includes("zip") ||
      type?.toLowerCase()?.includes("rar") ||
      type?.toLowerCase()?.includes("compressed")
    ) {
      return {type, icon: "file-zipper"};
    }
    return {type, icon: "file"};
  };

  const imageSrcHandler = async (src: string) => {
    //url
    toggleLoadingFile();
    let dataUrl = src;
    if (dataUrl?.includes("/get-file")) {
      const config: AxiosRequestConfig = {
        responseType: "blob",
        params: {withoutSubscriptionKey: true},
      };
      await ServiceApi.get(dataUrl, config).then(({data}) => {
        dataUrl = URL.createObjectURL(data);
      });
    }
    toggleLoadingFile();
    return dataUrl;
  };

  return (
    <div className="flex flex-wrap items-center gap-2">
      {details?.mediaList?.map((image: any, index: number) => {
        const mediaType = mediaTypeChecker(image.type);
        return (
          <>
            {
              <>
                {mediaType.type === "image" ? (
                  <LazyImage
                    isDynamic
                    key={image?.url}
                    src={image?.url}
                    className="aspect-square h-[270px] max-w-lg flex-none cursor-pointer bg-gray-100"
                    imageClassName="h-[270px] w-full"
                    onClick={() => {
                      setSelectedImage(image);
                    }}
                  />
                ) : (
                  <div className="relative flex h-[276px] max-w-lg flex-none items-center justify-center rounded-lg bg-gray-100">
                    {loadingFile && selectedFileIndex === index ? (
                      <img src={imageLoadingGif} alt="Loading File" />
                    ) : (
                      <div
                        className="flex-center flex cursor-pointer flex-col space-y-4"
                        onClick={async () => {
                          setSelectedFileIndex(index);
                          const url = await imageSrcHandler(image.url);
                          setSelectedImage({...image, url, isFile: true});
                        }}
                      >
                        <Icon
                          icon={mediaType.icon}
                          className="text-primary"
                          size="4x"
                        />
                        <span className="text-primary">View</span>
                      </div>
                    )}
                  </div>
                )}
              </>
            }
          </>
        );
      })}
    </div>
  );
}
