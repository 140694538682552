import { AxiosRequestConfig } from "axios";
import { InputGroup, SelectCountry, Wrapper } from "components";
import SearchAddress from "components/addressForm/SearchAddress";
import i18n from "langs/i18n";
import cloneDeep from "lodash/cloneDeep";
import { useEffect, useState } from "react";
import { ServiceApi, URLS } from "services";
import { Country, CountrySelectOption } from "types/country";
import { AddressType } from "utils/convertAddress";

type Data = {
  address: AddressType;
};

type Props = {
  data: Data;
  setData: (name: any) => ({ }: {}) => {};
  errorMsg: string;
};

export default function AddressForm({ data, setData, errorMsg }: Props) {
  const [countryList, setCountryList] = useState();
  const handleSetValue = (key: any) => {
    return (value: any) =>
      setData((p: any) => {
        const data = cloneDeep(p);
        if (!data.address) data.address = { [key]: value };
        else {
          data.address[key] = value;
        }
        let isAddressNull = true;
        if (data?.address) {
          for (let key in data?.address) {
            if (data?.address[key]) isAddressNull = false;
          }
        }
        if (isAddressNull) data.address = null;
        return data;
      });
  };

  const handleSetAddress = (value: any) => {
    setData((p: any) => {
      const data = cloneDeep(p);
      data.address = value;
      return data;
    });
  };

  const handleSetCountry = (c: CountrySelectOption) => {
    if (c.country?.countryIso2 !== data.address?.countryCode)
      setData({
        ...data,
        address: {
          ...data.address,
          country: c.label,
          countryCode: c.country?.countryIso2,
          countryCodeISO3: c.country?.countryIso3,
        },
      });
  };

  const getCountries = () => {
    const url = URLS.GET_POST_SELECTED_COUNTRIES_URL;
    const config: AxiosRequestConfig = {
      params: {
        cache: true,
        keyword: '',
      },
    };
    ServiceApi.get(url, config).then(({ data }) => {
      const countries = data.map((country: Country) => ({
        value: country.countryIso2,
        label: country.translates.find(
          (item: any) => item.language === i18n.language,
        )?.labelTitle,
        country: country,
      }));
      setCountryList(countries);
    });
  };

  useEffect(getCountries, []);

  return (
    <>
      <SearchAddress
        setValue={handleSetAddress}
        defaultCountry={data.address?.countryCode}
        countryList={countryList}
      />
      <Wrapper.Section className="grid grid-cols-1 items-stretch gap-x-6 gap-y-8 lg:grid-cols-1">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-4 lg:gap-8">
          <div className="col-span-4">
            <InputGroup
              ariaLabel="cy-location-name"
              label="global.locations.name"
              value={data.address?.name}
              setValue={handleSetValue("name")}
            />
          </div>
          <div className="col-span-3">
            <InputGroup
              ariaLabel="cy-location-street"
              label="global.locations.street"
              value={data.address?.street}
              setValue={handleSetValue("street")}
            />
          </div>
          <div>
            <InputGroup
              ariaLabel="cy-location-houseNumber"
              label="global.locations.houseNumber"
              value={data.address?.number}
              setValue={handleSetValue("number")}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-8">
          <InputGroup
            ariaLabel="cy-location-postalCode"
            label="global.locations.postalCode"
            value={data.address?.postalCode}
            setValue={handleSetValue("postalCode")}
          />
          <InputGroup
            ariaLabel="cy-location-city"
            label="global.locations.city"
            value={data.address?.city}
            setValue={handleSetValue("city")}
          />
          <SelectCountry
            value={data?.address?.countryCode}
            setValue={c => {
              handleSetCountry(c);
            }}
            countryList={countryList}
          />
          <InputGroup
            ariaLabel="cy-location-state"
            value={data.address?.state}
            setValue={handleSetValue("state")}
            label="global.locations.state"
          />
        </div>
        <p className="text-danger">{errorMsg}</p>
      </Wrapper.Section>
    </>
  );
}
