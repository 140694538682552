import {DragEvent, Fragment, useContext} from "react";
import {InfoPageContext} from "..";
import {Icon, Table, Text} from "components";
import Layout from "./Layout";
import {useToggleState, usePermission} from "hooks";
import AddLayoutForm from "./AddLayoutForm";
import {ServiceApi} from "services";
import {toast} from "react-hot-toast";
import {debounce} from "utils";
type getDragAfterElementType = (
  container: HTMLTableElement,
  y: number,
) => {
  offset: number;
  element?: Element;
  index?: number;
};
export default function Layouts() {
  const updatePermission = usePermission("PS_UpdateInfoPage");
  const {infoPageData} = useContext(InfoPageContext);
  const [isAddFormOpen, toggleAddForm] = useToggleState();
  const handleDragOver = (e: DragEvent<HTMLTableElement>) => {
    const table = e.currentTarget;
    const tbody = table.querySelector("tbody");
    const dragElement = table.querySelector("tr[draggable].dragging");
    const nextElement = getDragAfterElement(table, e.clientY).element;
    if (!dragElement || !tbody) return;
    if (!nextElement) return tbody.appendChild(dragElement);
    tbody.insertBefore(dragElement, nextElement);
  };
  const getDragAfterElement: getDragAfterElementType = (container, y) => {
    const draggableElements = [
      ...container.querySelectorAll("tr[draggable]:not(.dragging)"),
    ];
    return draggableElements.reduce(
      (closest, child, index) => {
        const box = child.getBoundingClientRect();
        const offset = y - box.top - box.height / 2;
        if (offset < 0 && offset > closest.offset) {
          return {offset, element: child, index};
        } else {
          return closest;
        }
      },
      {offset: Number.NEGATIVE_INFINITY},
    );
  };
  const submitReorder = (e: DragEvent<HTMLTableElement>) => {
    const url = `/productservice/api/infopages/${infoPageData.id}/reorder-layout`;
    const layouts = [...e.currentTarget.querySelectorAll("tr")].map(
      (e, index) => ({id: e.dataset.id, order: index}),
    );
    const config = {data: {layouts}};
    debounce(() => {
      ServiceApi.delete(url, config).then(() => {
        toast.success("applications.infoPage.reorderLayoutsToast");
      });
    });
  };
  return (
    <Fragment>
      <Table onDragOver={handleDragOver} onDragEnd={submitReorder}>
        {infoPageData.infoPageLayouts?.map(e => <Layout key={e.id} {...e} />)}
      </Table>
      {updatePermission && (
        <button
          type="button"
          className="h4 block w-full rounded border border-dashed border-primary bg-primary-light p-6"
          onClick={toggleAddForm}
        >
          <Icon icon="plus" className="text-primary" />{" "}
          <span className="text-gray-800">
            <Text>applications.infoPage.addLayoutButton</Text>
          </span>
        </button>
      )}
      <AddLayoutForm isOpen={isAddFormOpen} toggle={toggleAddForm} />
    </Fragment>
  );
}
