import {Breadcrumb, Skeleton, Tabs, Text, Wrapper} from "components";
import SellFactor from "./SellFactor";
import Tolerance from "./Tolerance";
import {
  Dispatch,
  SetStateAction,
  createContext,
  createElement,
  useEffect,
  useState,
} from "react";
import {ServiceApi} from "services";

type dataType = {
  id: string;
  description: string;
  name: string;
  sellFactor: number;
  tolerance: {min: number; max: number};
  avatar: null;
  contactInfo: {
    street: string;
    houseNumber: string;
    postalCode: string;
    city: string;
    state: string;
    country: string;
    mobileNumber: string;
    phoneNumber: string;
  };
};
type contextType = {
  data: dataType;
  setData: Dispatch<SetStateAction<dataType>>;
};
export const ConfigurationsContext = createContext({} as contextType);
export default function Configurations() {
  const controller = new AbortController();
  const tabs = [
    {
      label: "salesManagment.kitchenSales.configuration.sellFactorTab",
      component: SellFactor,
    },
    {
      label: "salesManagment.kitchenSales.configuration.toleranceTab",
      component: Tolerance,
    },
  ];
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({} as dataType);
  const getData = () => {
    setLoading(true);
    const url = "/statisticsservice/api/companies";
    ServiceApi.get(url, {signal: controller.signal})
      .then(res => {
        if (res) {
          setData(res?.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
    return () => {
      controller.abort();
    };
  }, []);
  return (
    <section className="space-y-6">
      <Breadcrumb />
      <Tabs activeKey={tabs[0].label}>
        <Wrapper>
          <Wrapper.Header className="!py-0">
            <Tabs.ButtonGroup>
              {tabs.map(e => (
                <Tabs.Button key={e.label} eventKey={e.label}>
                  <Text>{e.label}</Text>
                </Tabs.Button>
              ))}
            </Tabs.ButtonGroup>
          </Wrapper.Header>
          {loading ? (
            <Wrapper.Body className="grid grid-cols-1 gap-6 lg:grid-cols-2">
              <Skeleton.Input />
              <Skeleton.Input />
            </Wrapper.Body>
          ) : (
            <ConfigurationsContext.Provider value={{data, setData}}>
              {tabs.map(e => (
                <Tabs.Item key={e.label} eventKey={e.label}>
                  {createElement(e.component)}
                </Tabs.Item>
              ))}
            </ConfigurationsContext.Provider>
          )}
        </Wrapper>
      </Tabs>
    </section>
  );
}
