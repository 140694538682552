import React, { useContext } from "react";
import TemplateList from "../../../../compositions/templates";
import { SalesChannelcontext } from ".";

const Templates: React.FunctionComponent = () => {
  const { data } =
    useContext<any>(SalesChannelcontext);
  return (
    <TemplateList page="salesChannel" channelCode={data.code} />
  );
};

export default Templates;
