import {useState, useEffect} from "react";
import {config, dateFormats} from "constant";
import {
  Breadcrumb,
  Icon,
  Skeleton,
  Button,
  Text,
  SearchBox,
  Pagination,
  Table,
  NoItems,
  Status,
  WithPermission,
} from "components";
import {useToggleState, useConverter} from "hooks";
import {CreateSalesTemplate} from "./create";
import {PriceEngin} from "types";
import {ServiceApi, URLS} from "services";
import {Link} from "react-router-dom";
import {withPermission} from "hoc";

function SalesPrice() {
  const controller = new AbortController();
  const {convertDate} = useConverter();
  const [isOpen, toggle] = useToggleState();
  const [loading, toggleLoading] = useToggleState();
  const [params, setParams] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  });
  const [data, setData] = useState<PriceEngin.SalesPriceList>();

  useEffect(() => {
    getData();
    return () => {
      controller.abort();
    };
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = () => {
    toggleLoading();
    const url = URLS.GET_SALES_PRICE_TEMPLATES;
    const config = {params};
    ServiceApi.get(url, config)
      .then(({data}) => {
        toggleLoading();
        setData(data);
      })
      .catch(() => toggleLoading());
  };
  return (
    <>
      <div className="space-y-4">
        <div className="flex items-center">
          <div className="flex-1">
            <h3 className="text-heading-3">
              <Breadcrumb />
            </h3>
          </div>
          <WithPermission permissions={["PE_ModifySalePriceTemplate"]}>
            {loading ? (
              <Skeleton.Button />
            ) : (
              <Button onClick={toggle}>
                <Icon className="mr-2" icon={"plus"} />
                <Text>priceEngine.salesPrice.addSalesTemplate</Text>
              </Button>
            )}
          </WithPermission>
        </div>
        <SearchBox
          value={params?.keyword}
          onSubmit={keyword =>
            setParams(p => ({...p, keyword: keyword as string}))
          }
          disabled={loading}
          loading={loading}
          totalItems={data?.totalItems}
          onReload={getData}
        />
        {loading ? (
          <>
            {[1, 2, 3, 4].map(() => (
              <Skeleton.List />
            ))}
          </>
        ) : (
          <>
            {data?.items?.length ? (
              <>
                <Table>
                  {data?.items?.map(
                    (item: PriceEngin.SalesPriceTemplateItem) => {
                      return (
                        <tr className="*:text-gray-800" key={item.id}>
                          <td className="text-heading-6">
                            <h6>{item?.title}</h6>
                          </td>
                          <td className="space-y-2 text-body-base *:text-left">
                            <p>
                              <Text>priceEngine.purchasePrice.from</Text> :{" "}
                              {item?.validFromDate
                                ? convertDate(
                                    item?.validFromDate,
                                    dateFormats.onlyDate,
                                  )
                                : "--"}
                            </p>
                            <p>
                              <Text>priceEngine.purchasePrice.to</Text> :{" "}
                              {item?.validToDate
                                ? convertDate(
                                    item?.validToDate,
                                    dateFormats.onlyDate,
                                  )
                                : "--"}
                            </p>
                          </td>
                          <td>
                            <Status.PurchasePrice id={item?.active} />
                          </td>

                          <td>
                            <Button
                              size="sm"
                              variant="light"
                              as={Link}
                              to={item?.id}
                            >
                              <Text>priceEngine.calculationUnits.details</Text>
                            </Button>
                          </td>
                        </tr>
                      );
                    },
                  )}
                </Table>
                <Pagination
                  totalItems={data?.totalItems}
                  totalPages={data?.totalPages || 1}
                  pageNumber={params.pageNumber}
                  pageSize={params.pageSize}
                  setActivePage={page =>
                    setParams(p => ({...p, pageNumber: +page}))
                  }
                  onPageSizeChange={(pageSize: number) =>
                    setParams(p => ({...p, pageSize}))
                  }
                />
              </>
            ) : (
              <div className="flex w-full flex-col items-center space-y-4">
                <NoItems />
              </div>
            )}
          </>
        )}
      </div>
      <CreateSalesTemplate isOpen={isOpen} toggle={toggle} getData={getData} />
    </>
  );
}

export default withPermission(SalesPrice, ["PE_GetSalePriceTemplate"]);
