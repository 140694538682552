import {Button, HandleName, Icon, Skeleton, Text, Wrapper} from "components";
import {useToggleState} from "hooks";
import {useTranslation} from "react-i18next";
import {CSSTransition} from "react-transition-group";
import {classNames} from "utils";
import AddCategory from "./drawers/add-category";
import {usePermission} from "hooks";

type Props = {
  roots: any;
  setRoots: any;
  loading: boolean;
  selectedId: any;
  setSelectedId: any;
  getCategories: any;
};

const LeftSide = ({
  roots,
  selectedId,
  setSelectedId,
  loading,
  getCategories,
}: Props) => {
  const addPermission = usePermission("PS_CreateProductCategory");
  const [isActive, setIsActive] = useToggleState(true);
  const [isOpen, toggle] = useToggleState();
  const {i18n} = useTranslation();

  const drawer = classNames(
    "flex flex-center rounded overflow-hidden p-[5px] w-8 h-8 transition-transform absolute -right-[.9rem] bg-white shadow top-1/2",
    {
      "rotate-180": !isActive,
    },
  ); //rotate-180

  const panel = classNames(`flex-none relative transition-[width] h-[641px]`, {
    "!w-[374px]": isActive,
    "!w-[66px]": !isActive,
  });

  return (
    <Wrapper className={panel}>
      <div
        className={`flex h-[70px] border-b transition-transform ${
          isActive ? "justify-between p-4" : "justify-center px-1 py-4"
        }`}
      >
        <div className="flex items-center gap-2">
          <Icon
            icon={"list-tree"}
            size="1x"
            className="flex-none text-primary"
          />
          <CSSTransition
            in={isActive}
            timeout={50}
            unmountOnExit
            classNames="alert"
          >
            <span className="text-heading-2 font-semibold text-gray-800">
              <Text>
                productManagement.masterData.productCategory.primaryCategories
              </Text>
            </span>
          </CSSTransition>
        </div>
        {addPermission && (
          <CSSTransition
            in={isActive}
            timeout={50}
            unmountOnExit
            classNames="alert"
          >
            {
              <Button
                size="sm"
                onClick={toggle}
                aria-label="cy-product-add-button"
              >
                <Icon icon={"plus"} className="mr-2" />
                <Text>
                  productManagement.masterData.productCategory.addButton
                </Text>
              </Button>
            }
          </CSSTransition>
        )}
      </div>
      <div className="flex flex-col space-y-4 rounded bg-white p-3">
        {loading &&
          [1, 2, 3, 4, 5].map((item: any) => <Skeleton.Input key={item} />)}
        {!loading &&
          roots?.map((category: any, index: number) => {
            return (
              <div
                key={category.productCategoryId}
                className={` h-12 min-w-[50px] cursor-pointer rounded p-1 text-body-base shadow-sm ${
                  category.productCategoryId === selectedId
                    ? "bg-primary-light font-semibold text-primary"
                    : "bg-gray-100 font-normal text-gray-800"
                }`}
                aria-label="cy-category-item"
                onClick={() => setSelectedId(category.productCategoryId)}
              >
                <CSSTransition
                  in={true}
                  timeout={200}
                  unmountOnExit
                  classNames="alert"
                >
                  <span className="flex h-full w-full items-center px-2">
                    {
                      <HandleName
                        translations={category?.translates}
                        code={category?.code}
                        keyName="labelTitle"
                        sliceText={isActive ? false : true}
                      />
                    }
                  </span>
                </CSSTransition>
              </div>
            );
          })}
      </div>
      <button
        className={drawer}
        onClick={() => {
          setIsActive();
        }}
      >
        <Icon icon={"chevrons-left"} size="1x" className="text-primary" />
      </button>
      {/* {isOpen && ( */}
      {addPermission && (
        <AddCategory
          isOpen={isOpen}
          toggle={toggle}
          getCategories={getCategories}
        />
      )}
      {/* )} */}
    </Wrapper>
  );
};

export default LeftSide;
