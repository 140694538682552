import { NotificationManager } from "common/notifications";
import {
  Breadcrumb,
  Button,
  DeleteModal,
  HandleName,
  Icon,
  Skeleton,
  Tabs,
  Text,
  Wrapper,
} from "components";
import { useSelector, useToggleState } from "hooks";
import { isEmpty } from "lodash";
import { createContext, createElement, useEffect } from "react";
import { useNavigate } from "react-router";
import { ServiceApi, URLS } from "services";
import AttributeGroups from "./AttributeGroups";
import General from "./General";

export const EditProductFamiliesContext = createContext<any>({ id: "" });

const EditProductFamilies = () => {
  const navigate = useNavigate();
  const productFamily = useSelector((s) => s.productFmilies);
  const [loading, setLoading] = useToggleState();
  const [deleteModal, setDeleteModal] = useToggleState();
  const [deleteLoading, setDeleteLoading] = useToggleState();

  useEffect(() => {
    if (isEmpty(productFamily)) {
      navigate(-1);
    }
  }, [productFamily]);

  const tabs = [
    {
      label: "productManagement.masterData.productFamilies.general",
      component: General,
    },
    {
      label: "productManagement.masterData.productFamilies.attributeGroups",
      component: AttributeGroups,
    },
  ];

  const onDeleteHandler = () => {
    setDeleteLoading();
    const url = URLS.DELETE_PRODUCT_FAMILY(productFamily?.pimProductFamilyId);
    ServiceApi.delete(url)
      .then(() => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title"
        );
        setDeleteLoading();
        setDeleteModal();
        navigate(-1);
      })
      .catch((err) => setLoading());
  };

  return (
    <>
      <Tabs activeKey={tabs[0].label}>
        {/* BreadCrumb */}
        <div className="space-y-4">
          <div className="flex items-center">
            <div className="flex-1">
              <Breadcrumb />
            </div>
          </div>
          {loading && (
            <>
              <Wrapper>
                {/* <Wrapper.Body> */}
                <Skeleton.List />
                {/* </Wrapper.Body> */}
              </Wrapper>
              <Wrapper>
                <Wrapper.Body>
                  <Skeleton.TextArea />
                </Wrapper.Body>
              </Wrapper>
            </>
          )}
          {!loading && (
            <>
              <Wrapper>
                <Wrapper.Body className="flex justify-between items-center">
                  <div className="space-y-4">
                    <span className="text-heading-5 font-semibold text-gray-800">
                      {productFamily && (
                        <HandleName
                          translations={productFamily?.translates}
                          code={productFamily?.code}
                          keyName="labelTitle"
                        />
                      )}
                    </span>
                    <p className="text-body-2 font-medium">
                      {productFamily.code}
                    </p>
                  </div>
                  <Button
                    variant={"danger"}
                    light
                    size="sm"
                    onClick={() => {
                      setDeleteModal();
                    }}
                  >
                    <Icon icon={"trash"} className="mr-2" />
                    <Text>
                      productManagement.masterData.productFamilies.deleteSubFamily
                    </Text>
                  </Button>
                </Wrapper.Body>
                <Wrapper.Footer className="!py-0">
                  <Tabs.ButtonGroup>
                    {tabs.map((e) => (
                      <Tabs.Button key={e.label} eventKey={e.label}>
                        <Text>{e.label}</Text>
                      </Tabs.Button>
                    ))}
                  </Tabs.ButtonGroup>
                </Wrapper.Footer>
              </Wrapper>
              {tabs.map((e) => (
                <Tabs.Item key={e.label} eventKey={e.label}>
                  {createElement(e.component)}
                </Tabs.Item>
              ))}
            </>
          )}
        </div>
      </Tabs>
      <DeleteModal
        isOpen={deleteModal}
        loading={deleteLoading}
        toggle={setDeleteModal}
        onConfirm={onDeleteHandler}
        selected={{ name: productFamily.code }}
      />
    </>
  );
};

export default EditProductFamilies;
