import {omitBy, isNil, compact} from "lodash";
export interface AddressType {
  street?: string | null;
  number?: string | number;
  postalCode?: string | number;
  city?: string;
  state?: string;
  country?: string;
  name?: string | null;
  location?: {longitude: string | number; latitude: string | number};
  countryCode?: string;
  countryCodeISO3?: string;
  countrySubdivision?: string;
  countrySubdivisionName?: string;
}

export default function convertAddress(address: AddressType) {
  const result = omitBy(address, isNil);
  if (!address) return "";
  const converted = [
    ["street", "number"],
    ["postalCode", "city"],
    ["state", "country"],
  ].map(arr =>
    arr
      .map(key => {
        return result[key as keyof AddressType] || "";
      })
      .join(" "),
  );

  const convertedAddress = compact(converted).join(", ");
  return convertedAddress;
}
