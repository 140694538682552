import {
  Button,
  CheckBox,
  DatePicker,
  Drawer,
  Form,
  Icon,
  Select,
  Text,
} from "components";
import { rules, status } from "constant";
import { AvailabilityStatus } from "enum";
import { Dispatch, Fragment, useContext, useRef, useState } from "react";
import toast from "react-hot-toast";
import { ServiceApi, URLS } from "services";
import { PimProduct } from "types";
import { PimContext } from "../..";

type Props = {
  isOpen: boolean;
  toggle: () => void;
  availableStatus?: PimProduct.AvailableStatusItem | null;
  onSubmitted: Dispatch<string>;
  statusList: PimProduct.AvailableStatusItem[];
};

export default function AvailabilityStatusForm({
  isOpen,
  toggle,
  availableStatus = null,
  onSubmitted,
  statusList
}: Props) {
  const isNew = !availableStatus;
  const isDefault = !!availableStatus?.isDefault;
  const initData: PimProduct.CreateAvailableStatus = {
    availabilityStatus:
      availableStatus?.availabilityStatus ?? AvailabilityStatus.Active,
    fromDate: availableStatus?.fromDate ?? null,
    toDate: availableStatus?.toDate ?? null,
    validationPeriod: !!availableStatus?.validationPeriod,
  };
  const formRef = useRef<any>(null);
  const { productData } = useContext(PimContext);
  const [data, setData] = useState(initData);
  const [loading, setLoading] = useState(false);
  const maxDate = new Date(8640000000000000);
  const minDate = new Date(-8640000000000000);

  const handleSetValue = (key: keyof typeof data) => {
    return (value: any) => {
      setData(p => ({ ...p, [key]: value }));
    };
  };

  const isBetweenDate = (now: Date, fromDate: Date, toDate: Date) => {
    return (!fromDate || now >= fromDate) && (!toDate || now <= toDate);
  }

  const checkTwoDatesConflict = (range1Satrt: string | null, range1End: string | null, range2Satrt: string | null, range2end: string | null) => {
    const start1 = range1Satrt ? new Date(range1Satrt) : minDate;
    const end1 = range1End ? new Date(range1End) : maxDate;
    const start2 = range2Satrt ? new Date(range2Satrt) : minDate;
    const end2 = range2end ? new Date(range2end) : maxDate;

    const range1StartInRange2 = isBetweenDate(start1, start2, end2);
    const range1EndInRange2 = isBetweenDate(end1, start2, end2);
    const range2StartInRange1 = isBetweenDate(start2, start1, end1);
    const range2EndInRange1 = isBetweenDate(end2, start1, end1);
    return range1StartInRange2 || range1EndInRange2 || range2StartInRange1 || range2EndInRange1;
  }

  const dateHasConflict = () => {
    let hasConflict = false;
    statusList?.map((item) => {
      if (item.id !== availableStatus?.id &&
        !item.isDefault &&
        checkTwoDatesConflict(data.fromDate, data.toDate, item.fromDate, item?.toDate))
        hasConflict = true;
    });
    return hasConflict;
  };

  const create = (data: any) => {
    if (!productData.id) return;
    const url = URLS.ADD_PIM_AVAILABILITY_STATUS(productData?.id);
    const body = { ...data };
    setLoading(true);
    ServiceApi.post(url, body)
      .then(({ data: id }) => {
        const message =
          "productManagement.products.Details.availabilityStatus.addSuccessMessage";
        toast.success(message);
        setData(initData);
        onSubmitted(id);
        toggle();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const update = (data: any) => {
    if (!availableStatus?.id) return;
    const url = URLS.UPDATE_PIM_AVAILABILITY_STATUS(productData?.id, availableStatus?.id);
    const body = { ...data };
    setLoading(true);
    ServiceApi.put(url, body)
      .then(() => {
        const message =
          "productManagement.products.Details.availabilityStatus.editSuccessMessage";
        toast.success(message);
        onSubmitted(availableStatus?.id);
        toggle();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleSubmit = (e: any) => {
    formRef.current?.submitHandler(e, data);
  };
  const submit = isNew ? create : update;
  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header className="flex items-center gap-4">
          <h6 className="flex-1 text-start text-heading-2 font-semibold text-gray-800">
            <Text>
              {isNew
                ? "productManagement.products.Details.availabilityStatus.addStatusTitle"
                : "productManagement.products.Details.availabilityStatus.editStatusTitle"}
            </Text>
          </h6>
          <Button type="button" variant="light" onClick={toggle}>
            <Icon icon="close" />{" "}
            <Text>
              productManagement.products.Details.availabilityStatus.closeButton
            </Text>
          </Button>
        </Drawer.Header>
        <Drawer.Body>
          <Form
            ref={formRef}
            onSubmit={submit}
            className="grid grid-cols-2 items-start gap-6"
          >
            <div className="col-span-full">
              <Select
                label="productManagement.products.Details.availabilityStatus.status"
                value={data.availabilityStatus}
                setValue={handleSetValue("availabilityStatus")}
                items={status.availability}
                rules={rules.required}
              />
            </div>
            {!isDefault && (
              <Fragment>
                <div className="col-span-full">
                  <CheckBox
                    label="productManagement.products.Details.availabilityStatus.addValidationPeriod"
                    value={data.validationPeriod}
                    setValue={handleSetValue("validationPeriod")}
                  />
                </div>
                <DatePicker
                  label="productManagement.products.Details.availabilityStatus.fromDate"
                  value={data.fromDate}
                  onChange={handleSetValue("fromDate")}
                  disabled={!data.validationPeriod}
                  maxDate={data.toDate}
                  rules={data.validationPeriod && !data.toDate ? rules.required : []}
                />
                <DatePicker
                  label="productManagement.products.Details.availabilityStatus.toDate"
                  value={data.toDate}
                  onChange={handleSetValue("toDate")}
                  disabled={!data.validationPeriod}
                  minDate={data.fromDate}
                />
                {dateHasConflict() && (
                  <p className="mt-1 text-xs text-danger">
                    <i className="bi bi-info-circle mr-1" />
                    <Text>productManagement.products.Details.availabilityStatus.dateConflict</Text>
                  </p>
                )}
              </Fragment>
            )}
          </Form>
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-between gap-2">
          <Button
            type="button"
            variant="light"
            onClick={toggle}
            disabled={loading}
          >
            <Icon icon="close" />{" "}
            <Text>
              productManagement.products.Details.availabilityStatus.cancelButton
            </Text>
          </Button>
          <Button
            type="button"
            variant="primary"
            onClick={handleSubmit}
            loading={loading}
            disabled={loading || dateHasConflict()}
          >
            <Text>
              {isNew
                ? "productManagement.products.Details.availabilityStatus.addButton"
                : "productManagement.products.Details.availabilityStatus.updateButton"}
            </Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
