import {useTranslate} from "hooks";
import {ComponentProps, useRef} from "react";
import Dropdown from "./Dropdown";
import Icon from "./Icon";
import InputGroup from "./InputGroup";
import Skeleton from "./Skeleton";
import Text from "./Text";

type itemType = {
  name: string;
  id: number | string;
  code?: string;
  country?: string;
};
type defaultSelectProps = {
  items: itemType[];
  id?: string;
  className?: string;
  disabled?: boolean;
  size?: string;
  hint?: any;
  flag?: string;
  clear?: boolean;
  loading?: boolean;
  actions?: any;
};
type selectProps = defaultSelectProps &
  Omit<
    ComponentProps<typeof InputGroup>,
    keyof "onClick" | "append" | "readOnly" | "type" | "size"
  >;

export default function Select({
  label,
  value,
  items,
  rules,
  prepend,
  placeholder,
  className,
  disabled,
  size,
  flag,
  hint,
  actions,
  clear,
  loading,
  ariaLabel,
  setValue = () => {},
}: selectProps) {
  const ref = useRef<any>(null);
  const selectRef = useRef<any>(null);
  const translate = useTranslate();

  const activeItemName = items?.find(e => `${e.id}` === `${value}`)?.name;

  return (
    <>
      {loading ? (
        <>
          <label
            data-lang-map={label}
            className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
          >
            <Text>{label}</Text>
          </label>
          <Skeleton.Input />
        </>
      ) : (
        <div ref={selectRef} key={label} aria-label={ariaLabel}>
          <Dropdown
            className={`block w-full cursor-pointer ${className}  ${
              disabled ? "pointer-events-none" : ""
            }`}
            onSelect={setValue}
          >
            <Dropdown.Toggle
              ref={ref}
              key={label}
              as={InputGroup}
              size={size}
              label={label}
              value={translate(activeItemName ?? "")}
              rules={rules}
              prepend={prepend}
              placeholder={placeholder}
              disabled={disabled}
              flag={flag}
              hint={hint}
              actions={actions}
              append={
                <div className="flex gap-1">
                  {clear && value !== undefined && value && (
                    <i
                      onClick={() => {
                        setValue(null);
                      }}
                    >
                      <Icon className="mr-2" icon={"times"} />
                    </i>
                  )}
                  <i
                    // onClick={() => ref.current?.toggleShow()}
                    className="bi bi-chevron-down input-group-text px-0 text-primary transition-transform group-data-active:rotate-180"
                  />
                </div>
              }
            />
            <Dropdown.Menu className="max-h-[13rem] overflow-auto">
              {!!items?.length
                ? items?.map((e: any, index: any) => {
                    // const name = translate(e.name);
                    const code = e.code;
                    const hasCode = !!code;
                    const label = hasCode ? [e.name, code].join(" ") : e.name;
                    return (
                      <Dropdown.Item
                        key={e.id + e.name + index}
                        as="button"
                        type="button"
                        eventKey={e.id}
                        isActive={value === e.id}
                      >
                        <Text>{label}</Text>
                      </Dropdown.Item>
                    );
                  })
                : translate("global.noItems")}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
    </>
  );
}
