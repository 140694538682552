export const purchasePrice = {
    addPurchaseTemplate: 'Add Purchase Template',
    updateAt: 'Updated at',
    title: 'Title',
    code: 'Code',
    shortDescription: 'Short Description',
    addTemplate: 'Add Template',
    validationPeriods: 'Validation Periods',
    from: 'From',
    to: 'To',
    selectDate: 'Select Date',
    general: 'General',
    calculationSections: 'Calculation Sections',
    active: 'Active',
    basicInfo: 'Basic Info',
    addNewCalculationUnit: 'Add New Calculation Unit',
    step1: "Step 1 - Brutto > Netto",
    step2: "Step 2 - Netto > NettoNetto",
    step3: "Step 3 - Kalkulationsfaktor",
    step4: "Step 4",
    step5: "Step 5",
    addSelectedUnits: 'Add Selected Units',
    searchCalcUitsPlacholder: 'search calc. units by id or name',
    grossToNet: "Brutto zu Netto",
    netToNNet: "Netto zu NettoNetto",
    nNetToPurchase: "NettoNetto zu Basisverkaufspreis"
};