import {Icon, Image, ImageUploader, LazyImage, Text, Wrapper} from "components";
import {assetTypes} from "constant";
import {useSelector, useToggleState} from "hooks";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {ServiceApi, URLS} from "services";
import {setproductCategories} from "store/productCategories";
import {Media as MediaType} from "types";

const Media = () => {
  const dispatch = useDispatch();
  const [isOpen, toggle] = useToggleState(false);
  const [isOpenThumbnailUrl, toggleThumbnailUrl] = useToggleState(false);
  const productCategory = useSelector(s => s.productCategories);
  const [data, setData] = useState<any>();

  useEffect(() => {
    setData(productCategory);
  }, [productCategory]);

  const onUploadImage = (image: any) => {
    const url = URLS.EDIT_CATEGORY_TRANSLATES(data.productCategoryId);
    const body = {
      code: data.code,
      pictureThumbnailUrl: data.thumbnailUrl,
      pictureUrl: image.url,
      translates: data.translates,
    };
    ServiceApi.put(url, body).then(() => {});
    dispatch(setproductCategories({...data, pictureUrl: image.url}));
  };

  const onUploadImageThumbnailUrl = (image: any) => {
    const url = URLS.EDIT_CATEGORY_TRANSLATES(data.productCategoryId);
    const body = {
      code: data.code,
      pictureThumbnailUrl: image.thumbnailUrl,
      pictureUrl: data.pictureUrl,
      translates: data.translates,
    };
    ServiceApi.put(url, body).then(() => {});
    dispatch(setproductCategories({...data, thumbnailUrl: image.thumbnailUrl}));
  };
  return (
    <Wrapper>
      <Wrapper.Header className="text-heading-2 font-semibold">
        <Text>productManagement.masterData.productCategory.images</Text>
      </Wrapper.Header>
      <Wrapper.Body className="flex space-x-6">
        <div className="space-y-4">
          <span className="text-heading-6 font-normal text-gray-800">
            <Text>productManagement.masterData.productCategory.mainImage</Text>
          </span>
          <div
            className="flex-center flex h-[220px] w-[176px] cursor-pointer rounded-xl bg-gray-200"
            onClick={() => {
              toggle();
            }}
          >
            {data?.pictureUrl ? (
              <LazyImage
                isDynamic
                src={data?.pictureUrl}
                alt={""}
                editor
                imageClassName="h-full"
                className="aspect-image h-[220px] w-[176px] cursor-pointer lg:aspect-auto"
              />
            ) : (
              <div className="flex flex-col space-y-4">
                <Icon
                  icon="file-arrow-up"
                  size="3x"
                  className="text-gray-500"
                />
                <span className="text-body-base font-medium text-gray-600">
                  <Text>
                    productManagement.masterData.productCategory.uploadImage
                  </Text>
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="space-y-4">
          <span className="text-heading-6 font-normal text-gray-800">
            <Text>productManagement.masterData.productCategory.thumbnail</Text>
          </span>
          <div
            className="flex-center flex h-[220px] w-[176px] cursor-pointer rounded-xl bg-gray-200"
            onClick={() => {
              toggleThumbnailUrl();
            }}
          >
            {data?.thumbnailUrl ? (
              <LazyImage
                isDynamic
                src={data?.thumbnailUrl}
                alt={""}
                editor
                imageClassName="h-full"
                className="aspect-image h-[220px] w-[176px] cursor-pointer lg:aspect-auto"
              />
            ) : (
              <div className="flex flex-col space-y-4">
                <Icon
                  icon="file-arrow-up"
                  size="3x"
                  className="text-gray-500"
                />
                <span className="text-body-base font-medium text-gray-600">
                  <Text>
                    productManagement.masterData.productCategory.uploadImage
                  </Text>
                </span>
              </div>
            )}
          </div>
        </div>
      </Wrapper.Body>
      <ImageUploader
        isOpen={isOpen}
        toggle={toggle}
        image={data?.pictureUrl}
        onUpload={(data: MediaType) => onUploadImage(data)}
        type={assetTypes.Company}
      />
      <ImageUploader
        isOpen={isOpenThumbnailUrl}
        toggle={toggleThumbnailUrl}
        image={data?.pictureUrlThumbnailUrl}
        onUpload={(data: MediaType) => onUploadImageThumbnailUrl(data)}
        type={assetTypes.Company}
      />
    </Wrapper>
  );
};

export default Media;
