import { Button, Drawer, Icon, InputGroup, Text, DatePicker } from "components";
import { useSearchParams } from "react-router-dom";

type drawerProps = {
  isOpen: boolean;
  toggle: () => void;
  resetFilter: () => void;
  setParams: (key: any) => void;
  setKeyword: (key: any) => void;
  onChange: (key: any) => any;
  data: any;
  params: any;
  keyword: any;
};

const FilterDrawer = ({
  isOpen,
  params,
  toggle,
  onChange,
  resetFilter,
  setParams,
  setKeyword,
  keyword,
}: drawerProps) => {
  const [searchParams] = useSearchParams();

  const submit = (e: any) => {
    e.preventDefault();
    if (keyword !== "") {
      setParams((prev: any) => {
        prev.set("keyword", keyword);
        return prev;
      });
    } else {
      setParams((prev: any) => {
        prev.delete("keyword");
        return prev;
      });
    }
  };
  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pr-4 pt-6">
          <div className="flex items-center justify-between">
            <span className="text-heading-2 font-semibold">
              <Text>company.employees.filters</Text>
            </span>
            <div className="flex gap-x-2">
              {searchParams?.size !== 0 && (
                <Button
                  size="sm"
                  variant={"danger"}
                  light
                  onClick={() => resetFilter()}
                >
                  <Icon icon="times-circle" className="mr-1" />
                  <Text>company.employees.resetFilters</Text>
                </Button>
              )}
              <Button size="sm" variant={"light"} onClick={toggle}>
                <Icon icon="times" className="mr-1" />
                <Text>global.buttons.close</Text>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-4 px-6 py-8">
          <div className="flex-center flex gap-2">
            <form onSubmit={submit} className="w-full">
              <InputGroup
                label="company.employees.keyword"
                value={keyword}
                placeholder="global.placeholders.search"
                setValue={value => setKeyword(value)}
              />
            </form>
            <div
              className="mt-8 flex h-[46px] w-[46px] cursor-pointer items-center justify-center rounded-md bg-gray-100 p-3"
              onClick={() => {
                if (keyword !== "") {
                  setParams((prev: any) => {
                    prev.set("keyword", keyword);
                    return prev;
                  });
                } else {
                  setParams((prev: any) => {
                    prev.delete("keyword");
                    return prev;
                  });
                }
              }}
            >
              <Icon className="h-5 w-5 text-primary" icon={"search"} />
            </div>
          </div>
          {/* <div className="grid grid-cols-2 gap-2 gap-y-4">
            <span className="col-span-2 text-sm text-gray-800">
              <Text>company.employees.creationDate</Text>
            </span>
            <DatePicker
              placeholderText="company.employees.from"
              maxDate={
                params?.get("userCreatedToDate")
                  ? new Date(params?.get("userCreatedToDate"))
                  : new Date()
              }
              value={
                params?.get("userCreatedFromDate")
                  ? new Date(params?.get("userCreatedFromDate"))
                  : null
              }
              onChange={onChange("userCreatedFromDate")}
            />
            <DatePicker
              placeholderText="company.employees.to"
              minDate={
                params?.get("userCreatedFromDate") &&
                new Date(params?.get("userCreatedFromDate"))
              }
              value={
                params?.get("userCreatedToDate")
                  ? new Date(params?.get("userCreatedToDate"))
                  : null
              }
              maxDate={new Date()}
              onChange={onChange("userCreatedToDate")}
            />
          </div> */}
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
};

export default FilterDrawer;
