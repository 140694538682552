import {
  Accordion,
  Button,
  DropdownButton,
  HandleName,
  Icon,
  Skeleton,
  Text,
} from "components";
import {useToggleState, usePermission} from "hooks";
import {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router";
import {setproductCategories} from "store/productCategories";
import {CategoryContext} from "..";
import AddCategory from "../drawers/add-sub-category";

const SubCategories = () => {
  const addPermission = usePermission("PS_CreateProductCategory");
  const editPermission = usePermission("PS_UpdateProductCategory");
  const {i18n} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {selectedCategory, categories, getCategories} =
    useContext(CategoryContext);
  const [subCategories, setSubCategories] = useState<any>([]);
  const [loading, setLoading] = useToggleState();
  const [isOpen, toggle] = useToggleState();
  const [parentId, setParentId] = useState<any>("");

  const getSubCategories = () => {
    const subCategories = categories?.filter(
      (subCategory: any) =>
        subCategory.parentId === selectedCategory?.productCategoryId,
    );
    setSubCategories(subCategories);
  };

  useEffect(() => {
    getSubCategories();
  }, [selectedCategory || categories]);

  const getchildren = (item: any) => {
    return categories.filter(
      (sub: any) => sub.parentId === item.productCategoryId,
    );
  };

  const createChilds = (sub?: any, index?: number, inner?: boolean) => {
    return (
      <Accordion>
        <Accordion.Item
          className={`rounded-2xl border bg-white px-4 shadow-nested`}
        >
          <div className="flex items-center">
            <Accordion.Toggle>
              <div className="flex items-center space-x-4 font-medium">
                {getchildren(sub).length > 0 && (
                  <Icon
                    icon={"caret-right"}
                    className="transition-transform group-[.accordion-item.active]:rotate-90"
                  />
                )}
                <div className="cursor-pointer select-none space-y-2 text-left">
                  <span className="text-heading-6 font-semibold">
                    {sub && (
                      <HandleName
                        translations={sub?.translates}
                        code={sub?.code}
                        keyName="labelTitle"
                      />
                    )}
                  </span>
                  <p className="text-body-2 font-medium">{sub.code}</p>
                </div>
              </div>
            </Accordion.Toggle>
            <DropdownButton
              iconOnly
              variant={"light"}
              items={[
                {
                  id: "edit",
                  title:
                    "productManagement.masterData.productCategory.editSubCategory",
                  permission: editPermission,
                  onClick: () => {
                    dispatch(setproductCategories(sub));
                    navigate(sub.productCategoryId);
                  },
                },
                {
                  id: "addBranch",
                  title:
                    "productManagement.masterData.productCategory.addBranch",
                  permission: addPermission,
                  onClick: (e: any) => {
                    setParentId(sub.productCategoryId);
                    toggle();
                  },
                },
              ]}
            >
              <Icon icon={"ellipsis-vertical"} className="text-primary" />
            </DropdownButton>
          </div>
          {getchildren(sub).length > 0 && (
            <Accordion.Body className="px-4">
              {createSubFamilies(sub, true)}
            </Accordion.Body>
          )}
        </Accordion.Item>
      </Accordion>
    );
  };

  const createSubFamilies = (sub: any, inner = false) => {
    return (
      <div className="space-y-2 ">
        {getchildren(sub).map((child: any, index: number) =>
          createChilds(child, index, inner),
        )}
      </div>
    );
  };

  return (
    <>
      <div className="flex justify-end">
        {addPermission && (
          <Button
            aria-label="cy-product-category-add-subCategory"
            onClick={() => {
              setParentId(selectedCategory.productCategoryId);
              toggle();
            }}
          >
            <Icon icon="plus" className="mr-2" />
            <Text>
              productManagement.masterData.productCategory.addSubCategory
            </Text>
          </Button>
        )}
      </div>
      <div
        id="category-container"
        className="h-[500px] space-y-4 overflow-auto rounded"
      >
        {loading ? (
          [1, 2, 3, 4].map(item => <Skeleton.List key={item} />)
        ) : (
          <Accordion>
            {subCategories?.map((sub: any) => {
              if (sub.parentId !== selectedCategory.productCategoryId)
                return null;
              return (
                <Accordion.Item
                  key={sub.productCategoryId}
                  className="rounded-2xl bg-white px-4"
                >
                  <div className="flex items-center">
                    <Accordion.Toggle>
                      <div className="flex items-center space-x-4 font-medium">
                        {getchildren(sub).length > 0 && (
                          <Icon
                            icon={"caret-right"}
                            className="transition-transform group-[.accordion-item.active]:rotate-90"
                          />
                        )}
                        <div
                          className={`cursor-pointer select-none space-y-2 text-left`}
                        >
                          <span className="text-heading-6 font-semibold">
                            {
                              <HandleName
                                translations={sub?.translates}
                                code={sub?.code}
                                keyName="labelTitle"
                              />
                            }
                          </span>
                          <p className="text-body-2 font-medium">{sub.code}</p>
                        </div>
                      </div>
                    </Accordion.Toggle>
                    <DropdownButton
                      iconOnly
                      variant={"light"}
                      containerId="category-container"
                      items={[
                        {
                          id: "edit",
                          title:
                            "productManagement.masterData.productCategory.editSubCategory",
                          permission: editPermission,
                          onClick: () => {
                            dispatch(setproductCategories(sub));
                            navigate(sub.productCategoryId);
                          },
                        },
                        {
                          id: "addBranch",
                          title:
                            "productManagement.masterData.productCategory.addBranch",
                          permission: addPermission,
                          onClick: (e: any) => {
                            setParentId(sub.productCategoryId);
                            toggle();
                          },
                        },
                      ]}
                    >
                      <Icon
                        icon={"ellipsis-vertical"}
                        className="text-primary"
                      />
                    </DropdownButton>
                  </div>
                  {getchildren(sub).length > 0 && (
                    <Accordion.Body className="px-4">
                      {createSubFamilies(sub)}
                    </Accordion.Body>
                  )}
                </Accordion.Item>
              );
            })}
          </Accordion>
        )}
      </div>
      {addPermission && (
        <AddCategory
          isOpen={isOpen}
          toggle={toggle}
          getCategories={getCategories}
          parentId={parentId}
        />
      )}
    </>
  );
};

export default SubCategories;
