import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useId,
  useRef,
  useState,
} from "react";
import AnimateHeight from "react-animate-height";
import {twMerge} from "tailwind-merge";

type EventKeyType = string | null;
type AccordionProps = {
  children: ReactNode;
  defaultActiveKey?: EventKeyType;
};
type AccordionItemProps = {
  className?: string;
  eventKey?: EventKeyType;
  children: ReactNode;
  active?: boolean;
};
type AccordionToggleProps = {
  className?: string;
  children: ReactNode;
};
type AccordionBodyProps = {
  className?: string;
  itemClassName?: string;
  children: ReactNode;
};
type AccordionContextType = {
  activeKey: EventKeyType;
  setActiveKey: (val: EventKeyType) => void;
};
type AccordionItemContextType = {
  eventKey: EventKeyType;
  isActive: boolean;
  setIsActive: (val: boolean) => void;
  id: string;
};
const AccordionContext = createContext({} as AccordionContextType);
const AccordionItemContext = createContext({} as AccordionItemContextType);

function Accordion({children = null, defaultActiveKey = null}: AccordionProps) {
  const [activeKey, setActiveKey] = useState<EventKeyType>(defaultActiveKey);
  return (
    <AccordionContext.Provider value={{activeKey, setActiveKey}}>
      {children}
    </AccordionContext.Provider>
  );
}
function AccordionItem({
  className = "",
  eventKey = null,
  active = false,
  children,
}: AccordionItemProps) {
  const id = useId();
  const ref = useRef<HTMLDivElement>(null);
  const key = eventKey ?? id;
  const {activeKey} = useContext(AccordionContext);
  const [isActive, setIsActive] = useState<boolean>(active);
  // let isActive: boolean = active ? active : activeKey === key;

  useEffect(() => {
    setIsActive(active ? active : activeKey === key);
  }, [active]);

  useEffect(() => {
    ref.current?.classList.toggle("active", isActive);
    ref.current?.classList.toggle("notactive", !isActive);
  }, [isActive]);
  return (
    <AccordionItemContext.Provider
      value={{eventKey: key, isActive, id, setIsActive}}
    >
      <div
        ref={ref}
        id={id}
        className={`accordion-item group w-full border-b border-gray-100 ${className}`}
      >
        {children}
      </div>
    </AccordionItemContext.Provider>
  );
}
function AccordionToggle({
  className = "",
  children = null,
}: AccordionToggleProps) {
  const {setActiveKey} = useContext(AccordionContext);
  const {eventKey, isActive, setIsActive} = useContext(AccordionItemContext);
  const handleSetActiveKey = () => {
    setIsActive(!isActive);
    if (isActive) return setActiveKey(null);
    setActiveKey(eventKey);
  };
  return (
    <button
      type="button"
      onClick={handleSetActiveKey}
      className={`flex w-full items-center gap-4 py-5 font-bold ${className}`}
    >
      {children}
      {/* <i className="bi bi-chevron-down transition-transform ml-auto group-[.accordion-item.active]:rotate-180" /> */}
    </button>
  );
}
function AccordionBody({
  className = "",
  itemClassName = "",
  children = null,
}: AccordionBodyProps) {
  const {isActive} = useContext(AccordionItemContext);
  return (
    <AnimateHeight
      duration={300}
      height={isActive ? "auto" : 0}
      className={twMerge("w-full", className)}
      animationStateClasses={{
        animating: "",
        animatingUp: "",
        animatingDown: "",
        static: "",
        animatingToHeightZero: "",
        animatingToHeightAuto: "",
        animatingToHeightSpecific: "",
        staticHeightZero: "",
        staticHeightAuto: "",
        staticHeightSpecific: "",
      }}
    >
      <div className={twMerge("h-fit pb-4 pt-2", itemClassName)}>
        {children}
      </div>
    </AnimateHeight>
  );
}

Accordion.Item = AccordionItem;
Accordion.Toggle = AccordionToggle;
Accordion.Body = AccordionBody;

export default Accordion;
