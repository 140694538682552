import {ReactNode, useRef} from "react";
import ReactDOM from "react-dom";
type portalProps = {
  children: ReactNode;
};
export default function Portal({children}: portalProps) {
  const {current: div} = useRef(
    document.querySelector<HTMLDivElement>("#portal")!,
  );
  return ReactDOM.createPortal(children, div);
}
