import {
  Button,
  InputGroup,
  Skeleton,
  Text,
  WithPermission,
  Wrapper,
  Form,
} from "components";
import {rules} from "constant";
import {useEffect, useState} from "react";
import {ServiceApi, URLS} from "services";
import {NotificationManager} from "common/notifications";
import {useDataState, usePermission} from "hooks";
import {withPermission} from "hoc";

function Dynamics365() {
  const controller = new AbortController();
  const updatePermission = usePermission("CMS_UpdateErpConfig");
  const [loadingButton, setLoadingButton] = useState(false);
  const [dataFirst, setDataFirst] = useState(false);
  const [data, setData, setBaseData, isChanged] = useDataState<any>({});
  const [loadingPage, setLoadingPage] = useState(true);

  const submit = () => {
    setLoadingButton(true);
    const url = URLS.EDI_ERPT_DYNAMICS_365;
    data["inboundQueue"] = data.inboundQueueName;
    data["outboundQueue"] = data.outboundQueueName;
    if (dataFirst) {
      ServiceApi.put(url, data)
        .then(() => {
          NotificationManager.success(
            "global.toast.edit-msg",
            "global.toast.edit-title",
          );
        })
        .finally(() => {
          setLoadingButton(false);
          getData();
        });
    } else {
      ServiceApi.post(url, data)
        .then(() => {
          NotificationManager.success(
            "global.toast.add-msg",
            "global.toast.add-title",
          );
        })
        .finally(() => {
          setLoadingButton(false);
          getData();
        });
    }
  };

  useEffect(() => {
    getData();
    return () => {
      controller.abort();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    setLoadingPage(true);
    await ServiceApi.get(URLS.GET_ERP_DYNAMICS_365, {signal: controller.signal})
      .then(({data}) => {
        setBaseData(data);
        if (data.length === 0) {
          setDataFirst(false);
        } else {
          setDataFirst(true);
        }
      })
      .catch(() => {
        setBaseData({});
      })
      .finally(() => {
        setLoadingPage(false);
      });
  };

  return (
    <Form onSubmit={submit} className="relative space-y-6">
      <Wrapper>
        <Wrapper.Body className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-8">
          <div className="space-y-8">
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="thirdParty.erpConfiguration.name"
                value={data.name}
                setValue={name => setData((p: any) => ({...p, name}))}
                rules={rules.required}
                disabled={!updatePermission}
              />
            )}
            {loadingPage ? (
              <Skeleton.TextArea />
            ) : (
              <InputGroup
                as="short-textarea"
                label="thirdParty.erpConfiguration.inboundConnectionString"
                value={data.inboundConnectionString}
                setValue={inboundConnectionString =>
                  setData((p: any) => ({...p, inboundConnectionString}))
                }
                rules={rules.required}
                disabled={!updatePermission}
              />
            )}
            {loadingPage ? (
              <Skeleton.TextArea />
            ) : (
              <InputGroup
                as="short-textarea"
                label="thirdParty.erpConfiguration.outboundConnectionString"
                value={data.outboundConnectionString}
                setValue={outboundConnectionString =>
                  setData((p: any) => ({...p, outboundConnectionString}))
                }
                rules={rules.required}
                disabled={!updatePermission}
              />
            )}
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="thirdParty.erpConfiguration.inboundQueueName"
                value={data.inboundQueueName}
                setValue={inboundQueueName =>
                  setData((p: any) => ({...p, inboundQueueName}))
                }
                rules={rules.required}
                disabled={!updatePermission}
              />
            )}
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="thirdParty.erpConfiguration.outboundQueueName"
                value={data.outboundQueueName}
                setValue={outboundQueueName =>
                  setData((p: any) => ({...p, outboundQueueName}))
                }
                rules={rules.required}
                disabled={!updatePermission}
              />
            )}
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="thirdParty.erpConfiguration.outboundQueueSubscriptionName"
                value={data.outboundQueueSubscriptionName}
                setValue={outboundQueueSubscriptionName =>
                  setData((p: any) => ({...p, outboundQueueSubscriptionName}))
                }
                rules={rules.required}
                disabled={!updatePermission}
              />
            )}
          </div>
        </Wrapper.Body>
        <WithPermission permissions={["CMS_UpdateErpConfig"]}>
          <Wrapper.Footer className="flex items-center justify-end gap-4">
            <Button type="button" onClick={getData} variant="white">
              <Text>global.buttons.discard</Text>
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={!isChanged}
              loading={loadingButton}
            >
              <Text>global.buttons.saveChanges</Text>
            </Button>
          </Wrapper.Footer>
        </WithPermission>
      </Wrapper>
    </Form>
  );
}
export default withPermission(Dynamics365, ["CMS_GetErpConfig"]);
