import {Accordion, DropdownButton, HandleName, Icon} from "components";
import {usePermission, useToggleState} from "hooks";
import {useContext, useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router";
import {setProductFamilies} from "store/productFmilies";
import {ProductFamiliesContext} from ".";
import AddModal from "./AddModal";
import EditDrawer from "./EditDrawer";

const SubFamily = () => {
  const addPermission = usePermission("PS_CreatePimProductFamily");
  const editPermission = usePermission("PS_UpdatePimProductFamily");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addModal, setAddModal] = useToggleState();
  const [editDrawer, toggleEdit] = useToggleState();
  const [addLoading, setAddLoading] = useToggleState();
  const [isOpen, setIsOpen] = useToggleState();
  const [selectedFamily, SetSelectedFamily] = useState("");
  const {subFamily, getSubFamily, productFamilies, root, getProductFamilies} =
    useContext(ProductFamiliesContext);
  const ref = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current?.classList.toggle("active", isOpen);
    if (ref.current?.style) {
      bodyRef.current!.style.height = isOpen ? "auto" : "0";
    }
  }, [isOpen]);

  const createChilds = (sub: any, index: number, inner: boolean) => {
    return (
      <Accordion>
        <Accordion.Item
          className={`rounded-2xl border bg-white px-4 shadow-nested`}
        >
          <div className="flex items-center">
            <Accordion.Toggle>
              <div className="flex items-center space-x-4 font-medium">
                {getSubFamily(productFamilies, sub.pimProductFamilyId).length >
                  0 && (
                  <Icon
                    icon={"caret-right"}
                    className="transition-transform group-[.accordion-item.active]:rotate-90"
                  />
                )}
                <div className="cursor-pointer select-none space-y-2 text-left">
                  <span className="text-heading-6 font-semibold">
                    {sub && (
                      <HandleName
                        translations={sub?.translates}
                        code={sub?.code}
                        keyName="labelTitle"
                      />
                    )}
                  </span>
                  <p className="text-body-2 font-medium">{sub.code}</p>
                </div>
              </div>
            </Accordion.Toggle>
            <DropdownButton
              iconOnly
              variant={"light"}
              items={[
                {
                  id: "edit",
                  title: "productManagement.masterData.productFamilies.edit",
                  permission: editPermission,
                  onClick: () => {
                    dispatch(setProductFamilies(sub));
                    SetSelectedFamily(sub.pimProductFamilyId);
                    toggleEdit();
                    // navigate(sub.pimProductFamilyId);
                  },
                },
                {
                  id: "addBranch",
                  title:
                    "productManagement.masterData.productFamilies.addBranch",
                  permission: addPermission,
                  onClick: (e: any) => {
                    SetSelectedFamily(sub.pimProductFamilyId);
                    setAddModal();
                  },
                },
              ]}
            >
              <Icon icon={"ellipsis-vertical"} className="text-primary" />
            </DropdownButton>
          </div>
          <Accordion.Body className="px-4">
            {getSubFamily(productFamilies, sub.pimProductFamilyId).length > 0 &&
              createSubFamilies(sub, true)}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const createSubFamilies = (sub: any, inner = false) => {
    return (
      <div className="space-y-2 ">
        {getSubFamily(productFamilies, sub.pimProductFamilyId).map(
          (child: any, index: number) => createChilds(child, index, inner),
        )}
      </div>
    );
  };

  const onAddBranch = () => {
    getProductFamilies();
  };

  return (
    <>
      {/* <div className="flex justify-end">
        <Button
          onClick={() => {
            SetSelectedFamily(root.pimProductFamilyId);
            setAddModal();
          }}
        >
          <Icon icon="plus" className="mr-2" />
          <Text>
            productManagement.masterData.productFamilies.addSubFamiliy
          </Text>
        </Button>
      </div> */}
      <Accordion>
        {subFamily.map((sub: any) => {
          return (
            <Accordion.Item className="rounded-2xl bg-white px-4">
              <div className="flex items-center">
                <Accordion.Toggle>
                  <div className="flex items-center space-x-4 font-medium">
                    <Icon
                      icon={"caret-right"}
                      className="transition-transform group-[.accordion-item.active]:rotate-90"
                    />
                    <div
                      className={`cursor-pointer select-none space-y-2 text-left`}
                      onClick={() => setIsOpen()}
                    >
                      <span className="text-heading-6 font-semibold">
                        {
                          <HandleName
                            translations={sub?.translates}
                            code={sub?.code}
                            keyName="labelTitle"
                          />
                        }
                      </span>
                      <p className="text-body-2 font-medium">{sub.code}</p>
                    </div>
                  </div>
                </Accordion.Toggle>
                <DropdownButton
                  iconOnly
                  variant={"light"}
                  items={[
                    {
                      id: "edit",
                      title:
                        "productManagement.masterData.productFamilies.edit",
                      permission: editPermission,
                      onClick: () => {
                        dispatch(setProductFamilies(sub));
                        SetSelectedFamily(sub.pimProductFamilyId);
                        toggleEdit();
                        // navigate(sub.pimProductFamilyId);
                      },
                    },
                    {
                      id: "addBranch",
                      title:
                        "productManagement.masterData.productFamilies.addBranch",
                      permission: addPermission,
                      onClick: (e: any) => {
                        SetSelectedFamily(sub.pimProductFamilyId);
                        setAddModal();
                      },
                    },
                  ]}
                >
                  <Icon icon={"ellipsis-vertical"} className="text-primary" />
                </DropdownButton>
              </div>
              <Accordion.Body className="px-4">
                {createSubFamilies(sub)}
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
      <AddModal
        isOpen={addModal}
        loading={addLoading}
        toggle={setAddModal}
        onConfirm={onAddBranch}
        selected={selectedFamily}
      />
      <EditDrawer
        isOpen={editDrawer}
        toggle={toggleEdit}
        getProductFamilies={getProductFamilies}
        selected={selectedFamily}
      />
    </>
  );
};

export default SubFamily;
