import {NotificationManager} from "common/notifications";
import {
  Button,
  Icon,
  ImageUploader,
  LazyImage,
  Text,
  Wrapper,
} from "components";
import {useToggleState, usePermission} from "hooks";
import {isEmpty} from "lodash";
import {useContext, useEffect, useState} from "react";
import {ServiceApi, URLS} from "services";
import Lightbox from "yet-another-react-lightbox";
import Share from "yet-another-react-lightbox/plugins/share";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import {SellOffProductDetailsContext} from ".";
import {assetTypes} from "constant";
import {AxiosRequestConfig} from "axios";
import {useSearchParams} from "react-router-dom";

const Gallery = () => {
  //Galery
  const [serchParams] = useSearchParams();
  const updatePermission = usePermission("SC_UpdateSellOffProduct");
  const [toggler, setToggler] = useToggleState();
  const [imageUrls, setImageUrls] = useState([]);
  const [selectedImage, setSelectedImage] = useState<number>();

  const {data, editImageToggle, getData} = useContext(
    SellOffProductDetailsContext,
  );

  useEffect(() => {
    const clone: any = [];
    if (!isEmpty(data)) {
      fetchImages(data?.assets);
      data?.assets?.map((image: any) => {
        clone.push({src: image.url});
      });
    }
    setImageUrls(clone);
  }, [data]);

  const [isOpenUploader, setUploaderToggle] = useToggleState();

  const addAssetsHandler = (image: any) => {
    const url = URLS.UPDATE_DELETE_ASSETS(
      data?.sellOffProductId,
      data?.channelCode,
    );
    const body = {
      assets: [
        {
          id: null,
          url: image.url,
          type: image.type,
          thumbnailUrl: image.thumbnailUrl,
        },
      ],
    };

    ServiceApi.patch(url, body).then(() => {
      NotificationManager.success(
        "global.toast.add-msg",
        "global.toast.add-title",
      );
      getData();
    });
  };

  const hiddenButtons = data?.isClosed || data?.isSold || !updatePermission;

  const imageSrcHandler = async (src: string) => {
    //url
    let url;
    if (src?.includes("/get-file")) {
      const config: AxiosRequestConfig = {
        responseType: "blob",
        params: {withoutSubscriptionKey: true},
      };
      await ServiceApi.get(src, config).then(({data}) => {
        url = URL.createObjectURL(data);
      });
    } else {
      url = src;
    }
    return url;
  };

  async function fetchImages(data: any) {
    let urlImg: any = [];
    await data?.forEach(async (image: any) => {
      const test = await imageSrcHandler(image.url);
      urlImg.push({src: test});
    });
    setImageUrls(urlImg);
  }

  return (
    <>
      <Wrapper>
        <Wrapper.Header className="flex items-center justify-between">
          <h2 className="text-gray-800">
            <Text>salesManagment.sellOffSales.sellOffProduct.gallery</Text>
          </h2>
          {!hiddenButtons && (
            <div>
              <Button
                variant={"light"}
                size="sm"
                className="mr-4"
                onClick={setUploaderToggle}
              >
                <Icon icon="plus" className="mr-2" />
                <span>
                  <Text>
                    salesManagment.sellOffSales.sellOffProduct.addPhoto
                  </Text>
                </span>
              </Button>

              <Button variant={"light"} size="sm" onClick={editImageToggle}>
                <Icon icon="edit" className="mr-2" />
                <span>
                  <Text>salesManagment.sellOffSales.sellOffProduct.edit</Text>
                </span>
              </Button>
            </div>
          )}
        </Wrapper.Header>
        <Wrapper.Body>
          <>
            <div className="space flex flex-wrap items-center gap-4">
              {!!data?.assets?.length &&
                data?.assets?.map((image: any, index: number) => (
                  <>
                    <LazyImage
                      isDynamic
                      key={image?.url}
                      src={image?.url}
                      className="aspect-square h-[270px] max-w-lg flex-none cursor-pointer bg-gray-100"
                      imageClassName="h-[270px] w-full"
                      onClick={() => {
                        setSelectedImage(index);
                        setToggler();
                        document.body.style.overflow = "hidden";
                      }}
                    />
                  </>
                ))}
            </div>
            <Lightbox
              open={toggler}
              index={selectedImage}
              close={() => {
                document.body.style.overflow = "visible";
                setToggler();
              }}
              slides={imageUrls}
              plugins={[Zoom, Slideshow, Share]}
            />
          </>
        </Wrapper.Body>
      </Wrapper>
      <ImageUploader
        isOpen={isOpenUploader}
        toggle={setUploaderToggle}
        image={""}
        onUpload={image => addAssetsHandler(image)}
        type={assetTypes.SellOfProduct}
      />
    </>
  );
};

export default Gallery;
