export const employees = {
  general: "Allgemein",
  technicalInfo: "Technische Informationen",
  employeeNumber: "Mitarbeiternummer",
  salesPersonNumber: "Verkäufernummer",
  creationDate: "Erstellungsdatum",
  displayName: "Anzeigename",
  firstName: "Vorname",
  lastName: "Nachname",
  nickName: "Spitzname",
  basicInfo: "Grundlegende Informationen",
  employmentInfo: "Beschäftigungsinformationen",
  contactInfo: "Kontaktinformationen",
  jobTitle: "Berufsbezeichnung",
  store: "Geschäft",
  departments: "Abteilungen",
  mobilePhone: "Mobiltelefon",
  businessPhone: "Geschäftstelefon",
  faxNumber: "Faxnummer",
  emailAddress: "E-Mail-Adresse",
  address: "Adresse",
  userPrincipalName: "Nutzername",
  userObjectId: "Benutzer-Objekt-ID",
  filters: "Filter",
  resetFilters: "Filter zurücksetzen",
  from: "Von",
  to: "Bis",
  keyword: "Stichwort",
  department: "Abteilung",
  addDepartment: "Abteilung hinzufügen",
  chooseDepartment: "Abteilung auswählen",
  active: "Aktiv",
};
