import {NotificationManager} from "common/notifications";
import {Button, Modal, Text} from "components";
import {useToggleState} from "hooks";
import {useContext} from "react";
import {ServiceApi, URLS} from "services";
import {SellOffProductsContext} from "..";
import {useSearchParams} from "react-router-dom";

type Props = {
  isOpen: boolean;
  toggle: (value?: boolean) => void;
  getData: CallableFunction;
  id?: string;
};
const ClosePhaseModal = ({isOpen, toggle, getData, id}: Props) => {
  const [searchParams] = useSearchParams();
  const {item: sellOffProductITem} = useContext(SellOffProductsContext);
  const [loading, setLoading] = useToggleState();

  const channelCode =
    sellOffProductITem?.channelCode || searchParams.get("channelCode");

  const closePhaseHandler = () => {
    if (id) {
      setLoading();
      const url = URLS.CLOSE_PHASE_URL(id, channelCode);
      ServiceApi.post(url)
        .then(() => {
          NotificationManager.success(
            "global.toast.close-phase-msg",
            "global.toast.close-phase-title",
          );
          getData();
          toggle();
        })
        .finally(() => {
          setLoading();
        });
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="z-40">
      <Modal.Header className="flex flex-row">
        <h6 className="text-heading-2 text-gray-800">
          <Text>salesManagment.sellOffSales.sellOffProduct.closePhase</Text>
        </h6>
      </Modal.Header>
      <Modal.Body className="max-h-100 space-y-10 overflow-auto *:text-gray-800">
        <div className="flex flex-col space-y-4">
          <span>
            <Text>salesManagment.sellOffSales.sellOffProduct.areUSure</Text>
          </span>
          <span>
            <Text>salesManagment.sellOffSales.sellOffProduct.afterClosing</Text>
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-between">
        <Button variant={"light"} size="sm" onClick={toggle}>
          <Text>salesManagment.sellOffSales.sellOffProduct.cancel</Text>
        </Button>
        <Button
          variant={"danger"}
          size="sm"
          loading={loading}
          onClick={closePhaseHandler}
        >
          <Text>salesManagment.sellOffSales.sellOffProduct.closePhase</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ClosePhaseModal;
