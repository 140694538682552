import {TimeLine, Skeleton, NoItems} from "components";
import {checkPriceAvailability} from "utils";
import {useConverter} from "hooks";
import {SalesService} from "types";
import {sortBy} from "lodash";

const SaleProccessTimeline = ({
  timeLine,
}: {
  timeLine: SalesService.SellOffProductTimelineItemDto[];
}) => {
  const {convertDate} = useConverter();

  const checkActionType = (type: number) => {
    switch (type) {
      case 0:
        return "salesManagment.sellOffSales.sellOffProduct.noAction";
      case 1:
        return "salesManagment.sellOffSales.sellOffProduct.createProduct";
      case 2:
        return "salesManagment.sellOffSales.sellOffProduct.startPhase";
      case 3:
        return "salesManagment.sellOffSales.sellOffProduct.changePhase";
      case 4:
        return "salesManagment.sellOffSales.sellOffProduct.sold";
      case 5:
        return "salesManagment.sellOffSales.sellOffProduct.closePhase";
      case 6:
        return "salesManagment.sellOffSales.sellOffProduct.withdraw";
    }
  };

  const timelineBackground = (type: number) => {
    switch (type) {
      case 0:
        return "gray-500";
      case 1:
        return "primary";
      case 2:
        return "success";
      case 3:
        return "warning";
      case 4:
        return "info";
      case 5:
        return "danger";
      case 6:
        return "warning";
    }
  };

  const formattedValue = (t: any) => {
    switch (t.key) {
      case "Duration":
        return t.value + " Days";
      case "Discount":
      case "SellerCommission":
        return t.value + "%";
      case "OriginalPrice":
      case "PriceWithDiscount":
      case "SoldPrice":
      case "OriginalPrice":
      case "RealSoldPrice":
        return checkPriceAvailability(t.value);
      case "ExpectedStartDate":
        return convertDate(t.value);

      default:
        return t.value;
    }
  };

  const createItemsDetails = (item: any, date: any) => {
    return (
      <div className="space-y-2">
        <p className="text-gray-600">
          {date.performedBy
            ? date.performedBy + " at " + convertDate(date.performedAt)
            : convertDate(date.performedAt)}{" "}
        </p>
        {item?.map((t: any) => {
          return (
            <div className="space-x-2">
              <span>{t.key.match(/[A-Z][a-z]+/g).join(" ")}:</span>
              <span>{formattedValue(t)}</span>
            </div>
          );
        })}
      </div>
    );
  };

  const createTimelineItems = (
    timeline: SalesService.SellOffProductTimelineItemDto[],
  ) => {
    const items = timeline?.map(
      (item: SalesService.SellOffProductTimelineItemDto) => {
        return {
          actionType: item.actionType,
          title: checkActionType(item.actionType as number),
          description: item.title,
          label: item.createdItemAt
            ? convertDate(item.createdItemAt).split(",")[0]
            : "",
          color: timelineBackground(item.actionType as number),
          details: createItemsDetails(item.dynamicFields, {
            performedAt: item.performedAt,
            performedBy: item.performedBy,
          }),
        };
      },
    );
    const actionTypeZero = items?.filter((item: any) => !item.actionType);
    const withActionType = items?.filter((item: any) => item.actionType);
    let sortedTimeline = sortBy(withActionType, ["actionType"]);
    if (actionTypeZero) {
      sortedTimeline = sortedTimeline.concat(actionTypeZero);
    }
    return sortedTimeline;
  };

  return (
    <>
      {!timeLine?.length ? (
        <NoItems />
      ) : (
        <TimeLine
          items={createTimelineItems(timeLine)}
          className="parent overflow-x-auto"
        />
      )}
    </>
  );
};

export default SaleProccessTimeline;
