import {useState, useContext} from "react";
import {Wrapper, Text, Button, Icon, Select} from "components";
import Chooser from "./common/chooser";
import Info from "./common/info";
import {useToggleState} from "hooks";
import {config} from "constant";
import {ServiceApi, URLS} from "services";
import {SimulationContext} from "../..";

type dataType = {
  name: string;
  code?: string;
  id: string;
  active: boolean;
};

export default function PurchasePrice() {
  const {setSimulateData, simulateData} = useContext(SimulationContext);
  const [isOpen, toggle] = useToggleState();
  const [loading, setLoading] = useToggleState();
  const [params, setParams] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  });
  const [templates, setTemplates] = useState<dataType[]>([
    {
      name: "",
      code: "",
      id: "",
      active: false,
    },
  ]);

  const getTemplates = () => {
    setLoading();
    const url = URLS.GET_PURCHASE_PRICE_TEMPLATES;
    const config = {params};
    ServiceApi.get(url, config)
      .then(({data}) => {
        const clone = data?.items?.map((template: any) => ({
          id: template.id,
          name: template.title,
          code: template.code,
          active: template.active,
        }));
        setTemplates(clone);
        setLoading();
      })
      .catch(() => setLoading());
  };

  return (
    <>
      <Wrapper>
        <Wrapper.Header>
          <Text>priceEngine.simulator.purchasePriceTemplate</Text>
        </Wrapper.Header>
        <Wrapper.Body>
          {simulateData?.["purchasePriceTemplate"] ? (
            <Info dataKey="purchasePriceTemplate" />
          ) : (
            <Button light size="sm" onClick={toggle}>
              <Icon icon="plus" className="mr-2" />
              <Text>priceEngine.simulator.assignPurchasePriceTemplate</Text>
            </Button>
          )}
        </Wrapper.Body>
      </Wrapper>
      <Chooser
        isOpen={isOpen}
        toggle={toggle}
        title="priceEngine.simulator.purchasePriceTemplate"
        data={templates}
        getData={getTemplates}
        loading={loading}
        setParams={setParams}
        params={params}
        dataKey="purchasePriceTemplate"
        setSimulateData={setSimulateData}
      />
    </>
  );
}
