import {ImageUploader, LazyImage, Text} from "components";
import {useToggleState} from "hooks";
import {Fragment, useContext} from "react";
import {AddStoreContext} from ".";
import {assetTypes} from "constant";

export default function Image() {
  const {data, setData} = useContext(AddStoreContext);
  const [isOpen, toggle] = useToggleState(false);

  return (
    <Fragment>
      <h2 className="text-heading-2 text-gray-800">
        <Text>company.stores.image</Text>
      </h2>
      <div className="grid grid-cols-1 gap-8">
        <LazyImage
          onClick={() => toggle()}
          src={data.mainPhotoUrl}
          alt={""}
          isDynamic
          className="flex-center cursor-pointer overflow-hidden rounded"
        />
        <ImageUploader
          isOpen={isOpen}
          toggle={toggle}
          image={data.mainPhotoUrl}
          onUpload={image => setData(p => ({...p, mainPhotoUrl: image.url}))}
          type={assetTypes.Company}
        />
      </div>
    </Fragment>
  );
}
