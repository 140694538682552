import { useEffect } from "react";
import {
  Button,
  Modal,
  Form,
  InputGroup,
  Text,
  Icon,
  Skeleton,
  Toggler,
  Select,
} from "components";
import { ServiceApi } from "services";
import { URLS } from "services";
import { useDataState, usePermission, useToggleState } from "hooks";
import { rules, types } from "constant";
import { NotificationManager } from "common/notifications";

type detailsModalType = {
  isOpen: boolean;
  toggle: (value?: boolean) => void;
  getData: () => void;
  selected?: string;
};

export default function DetailsModal({
  isOpen,
  toggle,
  getData,
  selected,
}: detailsModalType) {
  const updatePermission = usePermission("PS_UpdateCheckList");
  const [data, setData, setBaseData, isChanged] = useDataState<{
    title?: string,
    description?: string,
    isActive?: boolean,
    objectType?: null
  }>({});
  const [loading, toggleLoading] = useToggleState();
  const [loadingButton, toggleLoadingButton] = useToggleState();

  useEffect(() => {
    if (isOpen) {
      getDataDetails();
    }
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDataDetails = () => {
    toggleLoading()
    const url = URLS.PIM_CHECK_LIST_DETAILS_URL(selected);
    ServiceApi.get(url)
      .then(({ data }) => {
        setBaseData(data);
      })
      .finally(() => {
        toggleLoading();
      });
  };

  const submit = () => {
    toggleLoadingButton();
    const url = URLS.PIM_CHECK_LIST_DETAILS_URL(selected);
    ServiceApi.put(url, data)
      .then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
        getData();
        setBaseData(data);
      })
      .finally(() => {
        toggle();
        toggleLoadingButton();
      });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} modalClassName="z-40">
      <Form onSubmit={submit}>
        <Modal.Header className="flex flex-row">
          <h6 className="text-heading-2 text-gray-800">
            <Text>
              productManagement.products.completeness.checkListDetails
            </Text>
          </h6>
        </Modal.Header>
        <Modal.Body className="max-h-100 overflow-auto">
          <div className="space-y-6">
            <Select
              label="productManagement.products.completeness.type"
              setValue={(objectType) =>
                setData((p) => ({ ...p, objectType }))
              }
              items={types.checkListObjectType}
              value={loading ? null : data?.objectType}
              disabled
            />
            {loading ?
              <>
                <label className="truncate font-[400] text-gray-800 h6 block mt-3">
                  <Text>productManagement.products.completeness.title</Text>
                </label>
                <Skeleton.Input />
              </> :
              <InputGroup
                label="productManagement.products.completeness.title"
                value={data?.title}
                className="border-white"
                setValue={title => setData(p => ({ ...p, title }))}
                rules={rules.required}
                disabled={!updatePermission}
              />
            }
            {loading ?
              <>
                <label className="truncate font-[400] text-gray-800 h6 block mt-3">
                  <Text>productManagement.products.completeness.shortDescription</Text>
                </label>
                <Skeleton.TextArea size="sm" />
              </> :
              <InputGroup
                as="short-textarea"
                label="productManagement.products.completeness.shortDescription"
                value={data?.description}
                className="border-white"
                setValue={description => setData(p => ({ ...p, description }))}
                disabled={!updatePermission}
              />
            }
            <div>
              <Toggler
                className="!w-fit"
                label={
                  "productManagement.products.completeness.active"
                }
                value={data?.isActive || false}
                setValue={isActive => setData(p => ({ ...p, isActive }))}
                disabled={!updatePermission || loading}
              />
            </div>
          </div>
        </Modal.Body>
        {updatePermission && (
          <Modal.Footer className="flex items-center justify-between">
            <Button type="button" onClick={toggle} variant="white">
              <Icon className="mr-2" icon={"times"} />
              <Text>global.buttons.close</Text>
            </Button>
            <Button type="submit" loading={loadingButton} disabled={!isChanged}>
              <Text>global.buttons.saveChanges</Text>
            </Button>
          </Modal.Footer>
        )}
      </Form>
    </Modal>
  );
}
