import {Button, Drawer, HandleName, Icon, List, Text} from "components";
import {
  Dispatch,
  Fragment,
  SetStateAction,
  createContext,
  useMemo,
  useState,
} from "react";
import toast from "react-hot-toast";
import {ServiceApi} from "services";
import {ProductFamily, Program, Store, Supplier, toggleProps} from "types";
import ChooseProductFamily from "./ChooseProductFamily";
import Iwofurn from "./Iwofurn";
import Programs from "./Programs";
import Stores from "./Stores";
import Suppliers from "./Suppliers";

type ConfigProductProps = toggleProps & {
  getData: () => void;
};
type Context = {
  supplier: Supplier.Item | null;
  setSupplier: Dispatch<SetStateAction<Supplier.Item | null>>;
  program: Program.Item | null;
  setProgram: Dispatch<SetStateAction<Program.Item | null>>;
  store: Store.Item | null;
  setStore: Dispatch<SetStateAction<Store.Item | null>>;
  productFamily: ProductFamily.Item | null;
  setProductFamily: Dispatch<SetStateAction<ProductFamily.Item | null>>;
  internalTag: string;
};
export const ConfigProductContext = createContext({} as Context);
export default function ConfigProduct({
  isOpen,
  toggle,
  getData,
}: ConfigProductProps) {
  const [loading, setLoading] = useState(false);
  const [supplier, setSupplier] = useState<Supplier.Item | null>(null);
  const [program, setProgram] = useState<Program.Item | null>(null);
  const [store, setStore] = useState<Store.Item | null>(null);
  const [productFamily, setProductFamily] = useState<ProductFamily.Item | null>(
    null,
  );
  const hasSupplier = !!supplier;
  const hasProgram = !!program;
  const hasStore = !!store;
  const hasProductFamily = !!productFamily;
  const hasDataArr = [hasSupplier, hasProgram, hasStore, hasProductFamily];
  const hasData = hasDataArr.some(Boolean);
  const isLastTab = hasDataArr.every(Boolean);
  const handleMenuSize = isLastTab ? "xl" : "lg";

  const internalTag = useMemo(() => {
    if (!isOpen) return "";
    const time = new Date().getTime();
    const randomNumber = Math.floor(Math.random() * 10 ** 5);
    return [randomNumber, time].join("");
  }, [isOpen]);

  const ActiveTab = useMemo(() => {
    if (!supplier) return Suppliers;
    if (!program) return Programs;
    if (!store) return Stores;
    if (!productFamily) return ChooseProductFamily;
    return Iwofurn;
  }, [supplier, program, store, productFamily]);

  const handleToggle = () => {
    setSupplier(null);
    setProgram(null);
    setStore(null);
    setProductFamily(null);
    toggle();
  };
  const onSucceeded = () => {
    handleToggle();
    getData();
  };
  const clearSupplier = () => {
    setProgram(null);
    setSupplier(null);
  };
  const clearProgram = () => {
    setProgram(null);
  };
  const clearStore = () => {
    setStore(null);
  };
  const clearProductFamily = () => {
    setProductFamily(null);
  };
  const submit = () => {
    setLoading(true);
    const url = `/productservice/api/pimproducts/importfromiwofurn/${internalTag}`;
    const body = {pimProductFamilyId: productFamily?.pimProductFamilyId};
    ServiceApi.post(url, body)
      .then(() => {
        toast.success("productManagement.products.allProducts.successMessage");
        onSucceeded();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Fragment>
      <Drawer isOpen={isOpen} toggle={handleToggle} size={handleMenuSize}>
        <Drawer.Menu>
          <Drawer.Header className="flex items-center gap-4">
            <h6 className="flex-1 text-start text-heading-2 text-gray-800">
              <Text>productManagement.products.allProducts.importIwofurn</Text>
            </h6>
            {isLastTab && (
              <Button
                type="button"
                size="sm"
                variant="primary"
                loading={loading}
                onClick={submit}
              >
                <Text>
                  productManagement.products.allProducts.addProductBtn
                </Text>
              </Button>
            )}
            <Button
              type="button"
              variant="light"
              size="sm"
              onClick={handleToggle}
            >
              <Icon icon="close" />{" "}
              <Text>productManagement.products.allProducts.closeBtn</Text>
            </Button>
          </Drawer.Header>
          {hasData && (
            <Drawer.Header className="grid grid-cols-2 gap-4">
              {hasSupplier && (
                <div className="space-y-4">
                  <fieldset className="text-start">
                    <legend>
                      <Text>
                        productManagement.products.allProducts.supplier
                      </Text>
                    </legend>
                  </fieldset>
                  <List>
                    <List.Item className="flex items-center gap-4">
                      <div className="flex-1 space-y-2">
                        <h6 className="block w-full text-heading-6 font-semibold text-gray-800">
                          {supplier.name}
                        </h6>
                        <p className="block w-full text-body-2 font-medium text-gray-500">
                          #{supplier.iln}
                        </p>
                      </div>
                      <button
                        type="button"
                        className="flex-center h-10 w-10 cursor-pointer rounded-md bg-gray-100"
                        onClick={clearSupplier}
                      >
                        <Icon
                          size="1x"
                          className="text-primary"
                          icon={"refresh"}
                        />
                      </button>
                    </List.Item>
                  </List>
                </div>
              )}
              {hasProgram && (
                <div className="space-y-4">
                  <fieldset className="text-start">
                    <legend>
                      <Text>
                        productManagement.products.allProducts.program
                      </Text>
                    </legend>
                  </fieldset>
                  <List>
                    <List.Item className="flex items-center gap-4">
                      <h6 className="block flex-1 truncate text-heading-6 font-semibold text-gray-800">
                        {program.title}
                      </h6>
                      <button
                        type="button"
                        className="flex-center h-10 w-10 cursor-pointer rounded-md bg-gray-100"
                        onClick={clearProgram}
                      >
                        <Icon
                          size="1x"
                          className="text-primary"
                          icon={"refresh"}
                        />
                      </button>
                    </List.Item>
                  </List>
                </div>
              )}
              {hasStore && (
                <div className="space-y-4">
                  <fieldset className="text-start">
                    <legend>
                      <Text>productManagement.products.allProducts.store</Text>
                    </legend>
                  </fieldset>
                  <List>
                    <List.Item className="flex items-center gap-4">
                      <div className="flex-1 space-y-2">
                        <h6 className="block w-full text-heading-6 font-semibold text-gray-800">
                          {store.title}
                        </h6>
                        {/* <AddressViewer
                          address={store.address}
                          className="truncate"
                        /> */}
                      </div>
                      <button
                        type="button"
                        className="flex-center h-10 w-10 cursor-pointer rounded-md bg-gray-100"
                        onClick={clearStore}
                      >
                        <Icon
                          size="1x"
                          className="text-primary"
                          icon={"refresh"}
                        />
                      </button>
                    </List.Item>
                  </List>
                </div>
              )}
              {hasProductFamily && (
                <div className="space-y-4">
                  <fieldset className="text-start">
                    <legend>
                      <Text>
                        productManagement.products.allProducts.productFamily
                      </Text>
                    </legend>
                  </fieldset>
                  <List>
                    <List.Item className="flex items-center gap-4">
                      <div className="flex-1 space-y-2">
                        <h6 className="block w-full text-heading-6 font-semibold text-gray-800">
                          <HandleName
                            translations={productFamily.translates}
                            code={productFamily.code}
                            keyName="labelTitle"
                          />
                        </h6>
                      </div>
                      <button
                        type="button"
                        className="flex-center h-10 w-10 cursor-pointer rounded-md bg-gray-100"
                        onClick={clearProductFamily}
                      >
                        <Icon
                          size="1x"
                          className="text-primary"
                          icon={"refresh"}
                        />
                      </button>
                    </List.Item>
                  </List>
                </div>
              )}
            </Drawer.Header>
          )}
          {isOpen && (
            <ConfigProductContext.Provider
              value={{
                supplier,
                setSupplier,
                program,
                setProgram,
                store,
                setStore,
                productFamily,
                setProductFamily,
                internalTag,
              }}
            >
              <ActiveTab />
            </ConfigProductContext.Provider>
          )}
        </Drawer.Menu>
      </Drawer>
    </Fragment>
  );
}
