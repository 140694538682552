import { NotificationManager } from "common/notifications";
import {
  AddressForm,
  Button,
  DeleteModal,
  Drawer,
  Form,
  Icon,
  InputGroup,
  Select,
  Skeleton,
  Text,
} from "components";
import { rules } from "constant";
import { useToggleState, useTranslate, usePermission } from "hooks";
import { isEmpty } from "lodash";
import { FormEvent, useEffect, useRef, useState } from "react";
import { ServiceApi, URLS } from "services";
import { ValidateAddress } from "utils";

type ModalType = {
  isOpen: boolean;
  toggle: (value?: boolean) => void;
  person: any;
  id: string;
  getData: any;
};

export default function ContactPersonDrawer({
  toggle,
  isOpen,
  person,
  id,
  getData,
}: ModalType) {
  const updatePermission = usePermission("PS_UpdateSupplier");
  const [data, setData] = useState<any>({});
  const formRef = useRef<any>();
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [deleteLoading, setDeleteLoading] = useToggleState();
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState(false);
  const [addressErrorMsg, setAddressErrorMasg] = useState("");
  const translate = useTranslate();

  useEffect(() => {
    if (isOpen && Object.keys(person).length !== 0) {
      getDataContact();
    } else {
      setData({});
    }
  }, [isOpen]);

  const getDataContact = () => {
    setLoading(true);
    const url = URLS.EDIT_DELETE_CONTACT_PERSON(person.id);
    ServiceApi.get(url)
      .then(({ data }) => {
        setData(data);
      })
      .catch(() => {
        setData(person);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChangeHandler = (key: any) => {
    return (value: any) => setData((p: any) => ({ ...p, [key]: value }));
  };

  const updateContactPerson = async (formData: any) => {
    const url = URLS.EDIT_DELETE_CONTACT_PERSON(formData.id);
    ServiceApi.put(url, formData)
      .then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
        toggle();
        getData();
        setLoadingButton(false);
      })
      .catch(() => {
        setLoadingButton(false);
      });
  };

  const createContactPerson = (formData: any) => {
    const url = URLS.ADD_CONTACT_PERSON;
    formData["supplierId"] = id;
    ServiceApi.post(url, formData).then(() => {
      NotificationManager.success(
        "global.toast.edit-msg",
        "global.toast.edit-title",
      );
      toggle();
      getData();
      setLoadingButton(false);
    });
  };

  const onDeleteHandler = () => {
    setDeleteLoading();
    const url = URLS.EDIT_DELETE_CONTACT_PERSON(data.id);
    ServiceApi.delete(url, data)
      .then(() => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title",
        );
        toggleDeleteModal();
        toggle();
        getData();
        setDeleteLoading();
      })
      .catch(() => {
        setDeleteLoading();
      });
  };

  const departmentItems = [
    {
      id: 0,
      name: "General",
    },
    {
      id: 1,
      name: "Sales",
    },
    {
      id: 2,
      name: "Logistics",
    },
    {
      id: 3,
      name: "Marketing",
    },
    {
      id: 4,
      name: "Support",
    },
    {
      id: 10,
      name: "Other",
    },
  ];

  const onSubmitForm = (formData: any, condition?: boolean) => {
    if (!formData.address)
      if (condition) {
        createContactPerson(formData);
      } else {
        updateContactPerson(formData);
      }
    else {
      setLoadingButton(true);
      setAddressErrorMasg("");
      ValidateAddress(formData.address).then(res => {
        if (!res?.selected && !res.suggested) {
          setAddressErrorMasg(
            translate("global.locations.invalidAddress") as string,
          );
          setLoadingButton(false);
          return;
        }
        if (res.selected) {
          setData({
            ...formData,
            address: {
              ...res.selected,
              name: formData.address?.name,
              number: formData.address?.number,
            },
          });
        }
        if (res.suggested) {
          setData({
            ...formData,
            address: {
              ...res.suggested,
              name: formData.address?.name,
              number: formData.address?.number,
            },
          });
          setAddressErrorMasg(
            translate("global.locations.changedAddress") as string,
          );
          setLoadingButton(false);
          return;
        }
        if (condition) {
          createContactPerson(formData);
        } else {
          updateContactPerson(formData);
        }
      });
    }
  };

  const submit = (e?: FormEvent) => {
    formRef.current.submitHandler(e, data, isEmpty(person));
  };

  return (
    <>
      <Drawer isOpen={isOpen} toggle={toggle} size={"xl"}>
        <Drawer.Menu className="pr-0">
          <Drawer.Header className="pr-4 pt-6">
            <div className="flex items-center justify-between">
              <h2 className="text-heading-2 text-gray-800">
                <Text>
                  productManagement.masterData.suppliers.contactPerson
                </Text>
              </h2>
              <div>
                {!isEmpty(person) && updatePermission && (
                  <Button
                    type="button"
                    variant="danger"
                    size="sm"
                    light
                    className="ml-2"
                    onClick={() => toggleDeleteModal()}
                  >
                    <Icon className="mr-2" icon={"trash"} />
                    <Text>global.buttons.delete</Text>
                  </Button>
                )}
                <Button
                  type="button"
                  variant="light"
                  size="sm"
                  className="ml-2"
                  onClick={() => toggle()}
                >
                  <Icon className="mr-2" icon={"times"} />
                  <Text>global.buttons.close</Text>
                </Button>
              </div>
            </div>
          </Drawer.Header>
          <Drawer.Body className="space-y-4 pr-4">
            <div className="grid grid-cols-2 gap-4">
              {loading ? (
                <>
                  {/* loading */}
                  <div className="space-y-4">
                    {[1, 2, 3, 4, 5, 6, 7, 8].map((item: number) => (
                      <Skeleton.Input key={item} />
                    ))}
                  </div>
                  <div className="space-y-4">
                    {[1, 2, 3, 4].map((item: number) => (
                      <Skeleton.Input key={item} />
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <Form
                    ref={formRef}
                    onSubmit={onSubmitForm}
                    id={"create-modal"}
                  >
                    <div className="space-y-4">
                      <InputGroup
                        label="productManagement.masterData.suppliers.firstName"
                        value={data?.firstName}
                        setValue={onChangeHandler("firstName")}
                        rules={rules.required}
                        disabled={!updatePermission}
                      />
                      <InputGroup
                        label="productManagement.masterData.suppliers.lastName"
                        value={data?.lastName}
                        setValue={onChangeHandler("lastName")}
                        rules={rules.required}
                        disabled={!updatePermission}
                      />
                      <Select
                        label="productManagement.masterData.suppliers.department"
                        items={departmentItems}
                        value={data?.department}
                        setValue={onChangeHandler("department")}
                        rules={rules.required}
                        disabled={!updatePermission}
                      />
                      <InputGroup
                        label="productManagement.masterData.suppliers.emailAddress1"
                        value={data?.emailAddress1}
                        setValue={onChangeHandler("emailAddress1")}
                        rules={rules.emailAddress}
                        disabled={!updatePermission}
                      />
                      <InputGroup
                        label="productManagement.masterData.suppliers.emailAddress2"
                        value={data?.emailAddress2}
                        setValue={onChangeHandler("emailAddress2")}
                        rules={rules.emailAddress}
                        disabled={!updatePermission}
                      />
                      <InputGroup
                        label="productManagement.masterData.suppliers.phone1"
                        value={data?.phoneNumber1}
                        setValue={onChangeHandler("phoneNumber1")}
                        disabled={!updatePermission}
                      />
                      <InputGroup
                        label="productManagement.masterData.suppliers.phone2"
                        value={data?.phoneNumber2}
                        setValue={onChangeHandler("phoneNumber2")}
                        disabled={!updatePermission}
                      />
                      <InputGroup
                        as={"short-textarea"}
                        label="productManagement.masterData.suppliers.description"
                        value={data?.description}
                        setValue={onChangeHandler("description")}
                        disabled={!updatePermission}
                      />
                    </div>
                  </Form>
                  <section className="space-y-8">
                    <AddressForm
                      data={data}
                      setData={setData as any}
                      errorMsg={addressErrorMsg}
                    />
                  </section>
                </>
              )}
            </div>
          </Drawer.Body>
          <Drawer.Footer className="flex items-center justify-end">
            {updatePermission && (
              <Button
                type="button"
                loading={loadingButton}
                onClick={() => {
                  submit();
                }}
              >
                <Text>global.buttons.submit</Text>
              </Button>
            )}
          </Drawer.Footer>
        </Drawer.Menu>
      </Drawer>
      <DeleteModal
        isOpen={isOpenDeleteModal}
        toggle={toggleDeleteModal}
        selected={{ name: data?.name }}
        loading={deleteLoading}
        onConfirm={onDeleteHandler}
      />
    </>
  );
}
