import {Icon, InputGroup, Text as TextComponent, HandleName} from "components";
import {useContext} from "react";
import {AttributeContext} from ".";
import {PimContext} from "..";
import {findValue} from "../../helpers/find-attribute.value";
import {updateValue} from "../../helpers/update-attribute-value";
import {KeyFilter} from "utils";

const Integer = () => {
  const {channels} = useContext(PimContext);
  const {
    attribute,
    values,
    channel,
    language,
    id,
    setValues,
    flag,
    toggle,
    setAttribute,
    onChangeValue,
    showInChannel,
    attributeGroupIndex,
    attributeTemplateId,
    disabled,
    isDefault,
    channelTitle,
  } = useContext(AttributeContext);
  const label = attribute.isRequired ? (
    <>
      {showInChannel && (
        <Icon icon="star" size="sm" className="mr-1 text-primary" />
      )}
      <HandleName
        translations={attribute?.translates}
        code={attribute?.code}
        keyName="labelTitle"
      />
      <Icon icon="star" size="sm" className="ml-1 text-danger" />{" "}
      {attribute?.hasChanged && channel !== "CH000D" && (
        <div className="inline-block rounded-md bg-warning-light p-2 text-warning-active">
          <TextComponent>
            productManagement.products.Details.warning
          </TextComponent>
          <Icon icon="star" size="sm" className="ml-1 text-warning " />
        </div>
      )}
    </>
  ) : (
    <>
      {showInChannel && (
        <Icon icon="eye-slash" size="sm" className="mr-1 text-primary" />
      )}
      <HandleName
        translations={attribute?.translates}
        code={attribute?.code}
        keyName="labelTitle"
      />
      {attribute?.hasChanged && channel !== "CH000D" && (
        <div className="ml-2 inline-block rounded-md bg-warning-light p-2 text-warning-active">
          <TextComponent>
            productManagement.products.Details.warning
          </TextComponent>
          <Icon icon="star" size="sm" className="ml-1 text-warning " />
        </div>
      )}
    </>
  );
  const object = findValue(
    attribute,
    values?.[attributeGroupIndex],
    attributeTemplateId,
    channel,
    language,
    isDefault,
  );

  const onChangeHandler = (e: any) => {
    const {data, changedAttribute} = updateValue(
      attribute,
      values?.[attributeGroupIndex],
      attributeTemplateId,
      e,
      channel,
      language,
      undefined,
      isDefault,
    );
    const changedValues = [...values];
    const changedIndex = changedValues[attributeGroupIndex]?.findIndex(
      (item: any) => item.attributeTemplateId === attributeTemplateId,
    );
    changedValues[attributeGroupIndex][changedIndex] = changedAttribute;
    setValues(changedValues);
    onChangeValue(data, attribute.code, attribute.isRequired);
  };

  const perLanguage = (value: any) => {
    return (
      <>
        {["de", "en"].map((lang: any) => {
          const changedData = updateValue(
            attribute,
            values,
            id,
            value,
            channel,
            lang,
          );
          setValues(changedData);
        })}
      </>
    );
  };

  const perChannelTemplate = (value: any) => {
    channels?.map((channel: any) => {
      const {data, changedAttribute} = updateValue(
        attribute,
        values?.[attributeGroupIndex],
        attributeTemplateId,
        value,
        channel.code,
        language,
        undefined,
        isDefault,
      );
      const changedValues = [...values];
      const changedIndex = changedValues[attributeGroupIndex]?.findIndex(
        (item: any) => item.attributeTemplateId === attributeTemplateId,
      );
      changedValues[attributeGroupIndex][changedIndex] = changedAttribute;
      setValues(changedValues);
      onChangeValue(data, attribute.code, attribute.isRequired);
    });
  };

  const perBothTemplate = (value: any) => {
    return (
      <>
        {channels.map((channel: any) => {
          return ["de", "en"].map((lang: any) => {
            const changedData = updateValue(
              attribute,
              values,
              id,
              value,
              channel.code,
              lang,
            );
            setValues(changedData);
          });
        })}
      </>
    );
  };

  let items = [
    {
      title: (
        <TextComponent>
          productManagement.products.Details.compareValues
        </TextComponent>
      ),
      id: "compare",
      onClick: () => {
        setAttribute(attribute);
        toggle();
      },
    },
  ];

  if (attribute.perChannel && !disabled) {
    items.push(
      attribute.perChannel && {
        title: (
          <TextComponent>
            productManagement.products.Details.setValueForAllChannel
          </TextComponent>
        ),
        id: "setValue",
        onClick: () => {
          perChannelTemplate(object.value);
        },
      },
    );
  }

  return (
    <>
      <InputGroup
        code={attribute?.translates?.[0]?.labelTitle}
        label={label}
        placeholder={attribute?.translations?.[0]?.placeHolder}
        value={object.value}
        setValue={onChangeHandler}
        flag={attribute?.perLanguage ? flag[language] : null}
        hint={
          attribute?.perChannel && channel !== "CH000D"
            ? {label: channelTitle}
            : null
        }
        actions={channel !== "CH000D" ? items : null}
        disabled={disabled}
        type="number"
        keyfilter={"int"}
      />
    </>
  );
};

export default Integer;
