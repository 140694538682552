import {NotificationManager} from "common/notifications";
import {Button, CheckBox, InputGroup, Modal, Text} from "components";
import {useToggleState} from "hooks";
import {useEffect, useState} from "react";
import {ServiceApi, URLS} from "services";
import {SalesService, SellOffProduct} from "types";

type Details = SalesService.SellOffProductBasicInfoDto &
  SalesService.SellOffProductStatusInfoDto;

type Props = {
  isOpen: boolean;
  toggle: (value?: boolean) => void;
  getData: CallableFunction;
  id?: string;
  data?: Details | null;
};
const SoldModal = ({isOpen, toggle, getData, id, data}: Props) => {
  const [loading, setLoading] = useToggleState();
  const [confirm, setConfirm] = useState(false);
  const [soldData, setSoldData] = useState({
    commissionNumber: "",
    realSoldPrice: "",
    orderId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  });

  useEffect(() => {
    setSoldData({
      orderId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      commissionNumber: String(data?.commissionNumber ?? ""),
      realSoldPrice: String(data?.currentPrice ?? ""),
    });
  }, [data]);

  const onChangeHandler = (key: string) => {
    return (value: string) => {
      setSoldData(p => ({...p, [key]: value}));
    };
  };

  const soldProductHandler = () => {
    if (id) {
      setLoading();
      const url = URLS.SOLD_URL(id, data?.channelCode);
      ServiceApi.post(url, soldData)
        .then(() => {
          NotificationManager.success(
            "global.toast.sold-msg",
            "global.toast.sold-title",
          );
          setLoading();
          getData();
          toggle();
        })
        .catch(() => {
          setLoading();
        });
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="z-40">
      <Modal.Header className="flex flex-row">
        <h6 className="text-heading-2 text-gray-800">
          <Text>salesManagment.sellOffSales.sellOffProduct.soldProduct</Text>
        </h6>
      </Modal.Header>
      <Modal.Body className="max-h-100 space-y-6 overflow-auto">
        <div className="flex flex-col space-y-4 text-gray-800">
          <span>
            <Text>salesManagment.sellOffSales.sellOffProduct.areUSureSold</Text>{" "}
            ?{" "}
          </span>
          <span className="leading-6">
            <Text>salesManagment.sellOffSales.sellOffProduct.contractDesc</Text>
          </span>
        </div>
        <InputGroup
          label="salesManagment.sellOffSales.sellOffProduct.contractNumber"
          value={soldData.commissionNumber}
          setValue={onChangeHandler("commissionNumber")}
        />
        <InputGroup
          label="salesManagment.sellOffSales.sellOffProduct.soldPrice"
          value={soldData.realSoldPrice}
          setValue={onChangeHandler("realSoldPrice")}
          type="number"
          append={
            <h5 className="border-l border-gray-300 px-4 py-3 text-heading-5 text-gray-400">
              €
            </h5>
          }
        />
        <CheckBox
          value={confirm}
          setValue={e => setConfirm(e)}
          label="salesManagment.sellOffSales.sellOffProduct.confirmSoldPrice"
        />
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-between">
        <Button variant={"light"} size="sm" onClick={toggle}>
          <Text>salesManagment.sellOffSales.sellOffProduct.cancel</Text>
        </Button>
        <Button
          size="sm"
          loading={loading}
          onClick={soldProductHandler}
          disabled={!confirm}
        >
          <Text>salesManagment.sellOffSales.sellOffProduct.sold</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SoldModal;
