import { useEffect, useState } from "react";
import {
  Drawer,
  Text,
  Button,
  Icon,
  Wrapper,
  InputGroup,
  DatePicker,
  Form,
  CheckBox,
} from "components";
import { AddNewCalculationUnit } from "compositions";
import { useToggleState } from "hooks";
import { PriceEngin } from "types";
import { rules, unitTypes } from "constant";
import { ServiceApi, URLS } from "services";
import { NotificationManager } from "common/notifications";

type Props = {
  isOpen: boolean;
  toggle: () => void;
  refId: string;
  level?: number;
  refCode?: string;
  refTitle?: string;
  test: any;
};

type AddProps = {
  validToDate?: string;
  validFromDate?: string;
};

export default function AssignCustomUnit({
  isOpen,
  toggle,
  level,
  refId,
  refCode,
  refTitle,
  test,
}: Props) {
  const [isOpenAdd, toggleAdd] = useToggleState();
  const [submitLoading, toggleSubmit] = useToggleState();
  const [unit, setUnit] = useState<PriceEngin.CalculationUnitItem & AddProps>();
  const [isChecked, toggleCheck] = useToggleState();
  useEffect(() => {
    if (!isOpen) {
      setUnit(undefined);
    }
  }, [isOpen]);

  const onChangeHandler = (key: string) => {
    return (value: any) => setUnit((p: any) => ({ ...p, [key]: value }));
  };

  const OnSubmitHandler = () => {
    toggleSubmit();
    const {
      title,
      id,
      code,
      itemValue,
      active,
      operationType,
      valueType,
      validFromDate,
      validToDate,
    } = unit!;
    const url = URLS.ADD_CALCULATION_UNIT_CUSTOMIZATION_ITEM;
    const body = {
      title,
      level,
      refIdentifier: refId,
      refCode,
      refTitle,
      calculationUnitId: id,
      calculationUnitCode: code,
      calculationUnitTitle: title,
      operationType,
      valueType,
      itemValue,
      active,
      validFromDate: isChecked ? validFromDate : "",
      validToDate: isChecked ? validToDate : "",
    };
    ServiceApi.post(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title",
        );
        toggleSubmit();
        toggle();
        test && test.current.getData();
      })
      .catch(() => toggleSubmit());
  };
  return (
    <>
      <Drawer isOpen={isOpen} toggle={toggle}>
        <Form onSubmit={OnSubmitHandler}>
          <Drawer.Menu>
            <Drawer.Header className="flex items-center justify-between">
              <span className="text-heading-2 font-semibold">
                <Text>priceEngine.purchasePrice.addNewCalculationUnit</Text>
              </span>
              <Button
                variant={"light"}
                type="button"
                onClick={toggle}
                size="sm"
              >
                <Icon icon="times" className="mr-2" />
                <Text>global.buttons.close</Text>
              </Button>
            </Drawer.Header>
            <Drawer.Body className="flex flex-col gap-4 space-y-4">
              <span>
                <Text>priceEngine.calculationLevel.calculationUnit</Text>
              </span>
              {unit ? (
                <>
                  <Wrapper className="shadow-card">
                    <Wrapper.Body className="flex flex-col space-y-4 !p-4">
                      <div
                        className="flex justify-between gap-2"
                      // onClick={() => onSelectUnitHandler(unit)}
                      >
                        <div className="flex flex-col space-y-2">
                          <span className="text-heading-5 font-semibold text-gray-800">
                            {unit.title}
                          </span>
                          <p className="text-body-base font-medium text-gray-500">
                            #{unit?.code}
                          </p>
                        </div>
                      </div>
                      <p className="truncate text-body-base font-medium text-gray-500">
                        {unit.description}
                      </p>
                    </Wrapper.Body>
                  </Wrapper>
                  {!unit?.hasManualValue && (
                    <>
                      <InputGroup
                        label={"priceEngine.calculationLevel.value"}
                        value={unit?.itemValue}
                        setValue={(value) => {
                          if (unit?.valueType !== 0 || (value >= 0 && value <= 100))
                            onChangeHandler("itemValue")(value)
                        }
                        }
                        rules={rules.required}
                        type="number"
                      />
                      <p className="!mt-0 text-sm">
                        <Icon icon="info-circle" className="mr-1" />
                        <Text>priceEngine.calculationUnits.typeWarning</Text>:{' '}
                        <Text>{unitTypes.filter(i => i.value === unit?.valueType)[0]?.label}</Text>
                      </p>
                    </>
                  )}
                  {/* <InputGroup
                  label={"priceEngine.calculationUnits.shortDescription"}
                  value={onChangeHandler("itemValue")}
                  as={"short-textarea"}
                /> */}
                  <CheckBox
                    value={isChecked}
                    setValue={toggleCheck}
                    label="priceEngine.calculationLevel.addValidationPeriod"
                  />
                  <div className="grid grid-cols-2 gap-4">
                    <DatePicker
                      minDate={new Date()}
                      maxDate={unit?.validToDate ? unit?.validToDate : null}
                      placeholderText="priceEngine.purchasePrice.selectDate"
                      label="priceEngine.purchasePrice.from"
                      value={unit?.validFromDate}
                      onChange={onChangeHandler("validFromDate")}
                      disabled={!isChecked}
                    />
                    <DatePicker
                      minDate={
                        unit?.validFromDate ? unit?.validFromDate : new Date()
                      }
                      placeholderText="priceEngine.purchasePrice.selectDate"
                      label="priceEngine.purchasePrice.to"
                      value={unit?.validToDate}
                      onChange={onChangeHandler("validToDate")}
                      disabled={!isChecked}
                    />
                  </div>
                </>
              ) : (
                <div>
                  <Button light onClick={toggleAdd} type="button">
                    <Icon icon="plus" className="mr-2" />
                    <Text>priceEngine.calculationLevel.addCalculationUnit</Text>
                  </Button>
                </div>
              )}
            </Drawer.Body>
            <Drawer.Footer className="flex justify-between">
              <Button
                size="sm"
                variant={"light"}
                onClick={toggle}
                type="button"
              >
                <Text>global.cancel</Text>
              </Button>
              <Button
                size="sm"
                type="submit"
                loading={submitLoading}
                disabled={!unit}
              >
                <Text>priceEngine.purchasePrice.addSelectedUnits</Text>
              </Button>
            </Drawer.Footer>
          </Drawer.Menu>
        </Form>
      </Drawer>
      <AddNewCalculationUnit
        isOpen={isOpenAdd}
        toggle={toggleAdd}
        onAddUnits={(list: PriceEngin.CalculationUnitItem) => {
          setUnit(list);
          toggleAdd();
        }}
      />
    </>
  );
}
