import {useContext, useMemo} from "react";
import {Accordion, HandleName, Icon, Skeleton} from "components";
import Gallery from "./Gallery";
import Specification from "./Specification";
import SubProducts from "./SubProducts";
import {SellOffProductDetailsContext} from "..";
import {SalesService} from "types";

type Details = SalesService.SellOffProductBasicInfoDto &
  SalesService.SellOffProductStatusInfoDto;

const OverViewTab = () => {
  const {data} = useContext(SellOffProductDetailsContext);
  const aboutRows = useMemo(
    () => [
      {title: "Article number", key: "articleNumber"},
      {title: "Supplier", key: "supplier"},
      {title: "Supplier article number:", key: "supplierArticleNumber"},
      {title: "Product family", key: "productFamily"},
    ],
    [],
  );

  const generateContent = (key: string) => {
    const details: any = data?.pimProduct;
    const rootContent = ["articleNumber", "supplierArticleNumber"].some(
      item => item === key,
    );
    const isSupplier = key === "supplier";
    const isProductFamily = key === "productFamily";
    if (rootContent) {
      return details?.[key];
    }
    if (isSupplier) {
      return details?.[key]?.name;
    }
    if (isProductFamily) {
      return (
        <HandleName
          translations={details?.[key]?.translates}
          code={details?.[key]?.code as string}
          keyName="labelTitle"
        />
      );
    }
    return "";
  };

  return (
    <div className="space-y-6">
      <Accordion key={"about-product"}>
        <Accordion.Item
          active
          className={`mb-4 rounded-2xl border bg-white px-4 shadow-nested`}
        >
          <Accordion.Toggle className="flex items-center justify-between">
            <div className="flex items-center gap-[10px]">
              <div>
                <Icon
                  icon={"chevron-down"}
                  size="sm"
                  className="transition-transform group-[.accordion-item.active]:rotate-180"
                />
              </div>
              <div>
                <h3>General info</h3>
              </div>
            </div>
          </Accordion.Toggle>
          <>
            <Accordion.Body className={`px-4`}>
              <div className={`"space-y-2" *:text-gray-500`}>
                {aboutRows?.map(row => {
                  return (
                    <>
                      {false ? (
                        <Skeleton.Input />
                      ) : (
                        <>
                          {row.key ? (
                            <div className="border-b border-gray-200 py-4 last:border-none">
                              <div className=" grid grid-cols-3 gap-4">
                                <span className="col-span-1 text-body-base font-medium ">
                                  {row.title}
                                </span>
                                <span className="text-heading-5 ">
                                  {generateContent(row.key) || "-------------"}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </>
                  );
                })}
              </div>
            </Accordion.Body>
          </>
        </Accordion.Item>
      </Accordion>
      {/* <Specification />
      <Gallery />
      <SubProducts /> */}
    </div>
  );
};

export default OverViewTab;
