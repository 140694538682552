import {
  createElement,
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import {
  Breadcrumb,
  Wrapper,
  Tabs,
  Text,
  Icon,
  Toggler,
  Footer,
  Button,
  Skeleton,
  Form,
} from "components";
import {useParams} from "react-router";
import General from "./tabs/general";
import CalculationSections from "./tabs/calculation-sections";
import {PriceEngineService} from "types";
import {useToggleState, useDataState, useConverter} from "hooks";
import {ServiceApi, URLS} from "services";
import {NotificationManager} from "common/notifications";

type ContextTypes = {
  data: PriceEngineService.CatalogSalePriceTemplateQueryDto;
  setData: Dispatch<
    SetStateAction<PriceEngineService.CatalogSalePriceTemplateQueryDto>
  >;
  loading: boolean;
};

export const SalesPriceContext = createContext<ContextTypes>({
  data: {
    id: "",
    title: "",
    code: "",
    description: "",
    active: false,
    validFromDate: "",
    validToDate: "",
    createdBy: "",
    createdByName: "",
    createdAt: "",
    lastModifiedBy: "",
    lastModifiedByName: "",
    lastModifiedAt: "",
    isValidNow: false,
  },
  setData: () => {},
  loading: false,
});

export function CatalogSalesTemplateDetails() {
  const {salesTemplateId} = useParams();
  const {convertDate} = useConverter();
  const [loading, toggleLoading] = useToggleState();
  const [submitLoading, toggleSubmit] = useToggleState();
  const [data, setData, setBaseData, isChanged] =
    useDataState<PriceEngineService.CatalogSalePriceTemplateQueryDto>({
      id: "",
      title: "",
      code: "",
      description: "",
      active: false,
      validFromDate: "",
      validToDate: "",
      createdBy: "",
      createdByName: "",
      createdAt: "",
      lastModifiedBy: "",
      lastModifiedByName: "",
      lastModifiedAt: "",
      isValidNow: false,
    });
  const tabs = [
    {
      label: "priceEngine.salesPrice.general",
      component: General,
    },
    {
      label: "priceEngine.salesPrice.calculationSections",
      component: CalculationSections,
      permission: ["PE_GetSalePriceTemplateItem"],
    },
  ];

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = () => {
    toggleLoading();
    const url = URLS.GET_CATALOG_SALE_PRICE_TEMPLATES_DETAILS(salesTemplateId);
    ServiceApi.get(url)
      .then(({data}) => {
        toggleLoading();
        setBaseData(data);
      })
      .catch(() => toggleLoading());
  };

  const onChangeHandler = (key: string) => {
    return (value: any) => setData((p: any) => ({...p, [key]: value}));
  };

  const onSubmitHandler = () => {
    toggleSubmit();
    const url = URLS.UPDATE_CATALOG_SALE_PRICE_TEMPLATES(salesTemplateId);
    const body: PriceEngineService.UpdateCatalogSalePriceTemplateRequest = {
      description: data?.description,
      isActive: data.active,
      title: data.title,
      validFromDate: data.validFromDate,
      validToDate: data.validToDate,
    };
    ServiceApi.put(url, body)
      .then(() => {
        toggleSubmit();
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
        getDetails();
      })
      .catch(() => toggleSubmit());
  };
  return (
    <Form onSubmit={onSubmitHandler}>
      <div className="space-y-4">
        <Tabs activeKey={tabs[0].label}>
          <Breadcrumb />
          <Wrapper>
            <Wrapper.Body className="space-y-4">
              {loading ? (
                <>
                  <div className="w-96 animate-pulse space-y-4">
                    <div className="col-span-1 h-2 rounded bg-slate-200"></div>
                    <div className="col-span-2 h-2 rounded bg-slate-200"></div>
                  </div>
                  <Skeleton.Button />
                </>
              ) : (
                <>
                  <div className="flex items-center justify-between gap-4">
                    <span className="text-heading-5 font-semibold text-gray-800">
                      {data?.title}
                    </span>
                    <Toggler
                      className="!w-fit place-items-start"
                      size="sm"
                      label="priceEngine.salesPrice.active"
                      value={data?.active as boolean}
                      setValue={onChangeHandler("active")}
                    />
                  </div>
                  <div className="flex flex-col gap-4">
                    <span className="flex items-center space-x-1">
                      <Icon icon="hashtag" size="sm" />{" "}
                      <span className="text-body-2 text-gray-500">
                        {data?.code}
                      </span>
                    </span>
                    <p className="text-body-2">
                      <Text>
                        productManagement.masterData.suppliers.lastModifiedAt
                      </Text>{" "}
                      : {convertDate(data?.lastModifiedAt)}
                    </p>
                    <p className="text-body-2">
                      <Text>
                        productManagement.masterData.suppliers.lastModifiedBy
                      </Text>{" "}
                      : {data?.lastModifiedByName}
                    </p>
                  </div>
                </>
              )}
            </Wrapper.Body>
            <Wrapper.Footer className="!py-0">
              <Tabs.ButtonGroup>
                {tabs.map((e: any) => (
                  <Tabs.Button
                    key={e.label}
                    eventKey={e.label}
                    permission={e.permission}
                    disabled={loading}
                  >
                    <Text>{e.label}</Text>
                  </Tabs.Button>
                ))}
              </Tabs.ButtonGroup>
            </Wrapper.Footer>
          </Wrapper>
          {tabs.map((e: any) => (
            <Tabs.Item key={e.label} eventKey={e.label}>
              <SalesPriceContext.Provider value={{data, setData, loading}}>
                {createElement(e.component)}
              </SalesPriceContext.Provider>
            </Tabs.Item>
          ))}
          <Footer show={!isChanged}>
            <Button type="submit" variant="primary" loading={submitLoading}>
              <Text>global.buttons.saveChanges</Text>
            </Button>
          </Footer>
        </Tabs>
      </div>
    </Form>
  );
}
