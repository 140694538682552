import ProductLabelTemplateList from "pages/product-managment/master-data/product-label-templates";

const ProductLabelTemplates = [
  {
    path: "",
    element: <ProductLabelTemplateList />,
  },
];

export default ProductLabelTemplates;
