export const productGroup = {
  addProductGroup: "Add Product group",
  editProductGroup: "Edit Product group",
  code: "Code",
  name: "Name",
  title: "Titel",
  description: "Description",
  shortDescription: "Short Description",
  tax: "Tax",
};
