import {ComponentProps} from "react";
import {twMerge} from "tailwind-merge";
// ComponentProps<'ul'>
type ListProps = ComponentProps<"ul">;
type ItemProps = ComponentProps<"li">;
type disable = {
  code?: any;
};
function List({children, className, ...props}: ListProps) {
  return (
    <ul className={twMerge("w-full space-y-4", className)} {...props}>
      {children}
    </ul>
  );
}
function Item({
  children,
  className,
  onClick,
  code = true,
  ...props
}: ItemProps & disable) {
  const clickable = !!onClick && code;
  return (
    <li
      onClick={e => {
        clickable && onClick(e);
      }}
      className={twMerge(
        "w-full cursor-not-allowed rounded bg-white p-4 text-start shadow-[0px_4px_20px_0px_rgba(63,66,84,0.10)]",
        clickable && "cursor-pointer ",
        className,
      )}
      {...props}
    >
      {children}
    </li>
  );
}
List.Item = Item;
export default List;
