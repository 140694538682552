export const productFamilies = {
  general: "General",
  subFamily: "Sub Family",
  basicInfo: "Basic Info",
  addSubFamiliy: "Add Sub Family",
  addBranch: "Add Branch",
  addToFamily: "Add To Family",
  cancelAddBranch: "Cancel",
  edit: "Edit",
  attributeGroups: "Attribute Groups",
  deleteSubFamily: "Delete",
  attributes: "Attributes",
  close: "Close",
  required: "Required",
  perLanguage: "Per Language",
  perChannel: "Per Channel",
  inherit: "inherit",
  familyName: "Family Name",
  familyCode: "Family Code",
  availableForSelect: "Available for select",
  inheritFromParent: "Inherit from parent",
  exists: "Exist",
  usedInChild: "Used In Child",
};
