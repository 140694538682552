import {NotificationManager} from "common/notifications";
import {
  Breadcrumb,
  Button,
  Form,
  Icon,
  Stepper,
  Text,
  WithPermission,
  Wrapper,
} from "components";
import {Fragment, createContext, createElement, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ServiceApi, URLS} from "services";

//... tabs
import General from "./tabs/general";
import Location from "./tabs/location";
import Review from "./tabs/review";
import UserGroup from "./tabs/users-Group";
import Password from "./tabs/password";
import {ValidateAddress} from "utils";
import {useTranslate, useToggleState} from "hooks";

export const AddUserListContext = createContext({});

export default function Create() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [addressErrorMsg, setAddressErrorMasg] = useState("");
  const [checked, toggle] = useToggleState();
  const [data, setData] = useState<any>({
    firstName: "",
    lastName: "",
    emailAddress: "",
    gender: 0,
    dateOfBirth: null,
    phoneNumber: "",
    mobileNumber: "",
    userGroupId: "",
    userType: 8,
    address: null,
    password: null,
    confirmPassword: null,
  });
  const [dataImg, setDataImg] = useState<any>({
    thumbnailUrl: "",
    type: "",
    url: "",
  });
  const navigate = useNavigate();
  const tabs = [
    {
      label: "configurations.userManagment.userList.general",
      desc: "configurations.userManagment.userList.generalDesc",
      component: General,
    },
    {
      label: "configurations.userManagment.userList.userGroup",
      desc: "configurations.userManagment.userList.userGroupDesc",
      component: UserGroup,
    },
    {
      label: "configurations.userManagment.userList.location",
      desc: "configurations.userManagment.userList.locationDesc",
      component: Location,
    },
    {
      label: "configurations.userManagment.userList.password",
      desc: "configurations.userManagment.userList.passwordDesc",
      component: Password,
    },
    {
      label: "configurations.userManagment.userList.review",
      desc: "configurations.userManagment.userList.reviewDesc",
      component: Review,
    },
  ];
  const [loading, setLoading] = useState(false);
  const activeTab = tabs[activeTabIndex];
  const isFirstTab = activeTabIndex === 0;
  const isLastTab = activeTabIndex === tabs.length - 1;
  const isAddressTab = activeTabIndex === 2;
  const translate = useTranslate();

  const goNext = () => {
    if (isLastTab) return submit();
    if (!isAddressTab || !data.address) setActiveTabIndex(p => p + 1);
    else {
      setAddressErrorMasg("");
      setLoading(true);
      ValidateAddress(data.address)
        .then(res => {
          if (!res?.selected && !res.suggested) {
            setAddressErrorMasg(
              translate("global.locations.invalidAddress") as string,
            );
            setLoading(false);
            return;
          }
          if (res.selected) {
            setData({
              ...data,
              address: {
                ...res.selected,
                name: data.address.name,
                number: data.address.number,
              },
            });
          }
          if (res.suggested) {
            setData({
              ...data,
              address: {
                ...res.suggested,
                name: data.address.name,
                number: data.address.number,
              },
            });
            setAddressErrorMasg(
              translate("global.locations.changedAddress") as string,
            );
            setLoading(false);
            return;
          }
          setActiveTabIndex(p => p + 1);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const goPrev = () => {
    if (isFirstTab) return;
    setActiveTabIndex(p => p - 1);
  };

  const submit = () => {
    setLoading(true);
    const url = URLS.ADD_B2B_USERS_URL;
    const body = checked
      ? data
      : {...data, password: null, confirmPassword: null};
    ServiceApi.post(url, data)
      .then(() => {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title",
        );
        navigate(-1);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="space-y-6">
      <Breadcrumb />
      <div className="grid grid-cols-1 items-start gap-6 lg:grid-cols-12">
        <Stepper tabs={tabs as []} activeTab={activeTabIndex} />
        <Wrapper
          as={Form}
          onSubmit={goNext}
          className="col-span-full lg:col-span-8"
        >
          <Wrapper.Body className="mx-auto w-full space-y-8 lg:w-[calc(100%-9rem)]">
            <AddUserListContext.Provider
              value={{
                dataImg,
                setDataImg,
                data,
                setData,
                addressErrorMsg,
                checked,
                toggle,
              }}
            >
              {createElement(activeTab.component)}
            </AddUserListContext.Provider>
            <div className="flex items-center justify-between">
              <Button
                type="button"
                variant="light"
                disabled={isFirstTab}
                onClick={goPrev}
              >
                <Icon icon="arrow-left" /> <Text>global.buttons.back</Text>
              </Button>
              <WithPermission permissions={["AS_CreateUser"]}>
                <Button type="submit" variant="primary" loading={loading}>
                  {isLastTab ? (
                    <Fragment>
                      <Icon icon="check" /> <Text>global.buttons.submit</Text>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Text>global.buttons.next</Text>{" "}
                      <Icon icon="arrow-right" />
                    </Fragment>
                  )}
                </Button>
              </WithPermission>
            </div>
          </Wrapper.Body>
        </Wrapper>
      </div>
    </div>
  );
}
