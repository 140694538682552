import {NotificationManager} from "common/notifications";
import {
  Breadcrumb,
  Button,
  HandleName,
  Icon,
  LazyImage,
  Skeleton,
  Status,
  Tabs,
  Text,
  Wrapper,
} from "components";
import {useToggleState, usePermission} from "hooks";
import {isEmpty} from "lodash";
import {createContext, createElement, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {ServiceApi, URLS} from "services";
import {SalesService} from "types";
import {classNames} from "utils";
import PriceTemplate from "../List/PriceTemplate";
import ChangePhaseModal from "../sell-off-drawers/ChangePhase";
import ClosePhaseModal from "../sell-off-drawers/ClosePhase";
import EditImageDrawer from "../sell-off-drawers/EditImageDrawer";
import StartPreparationPhaseModal from "../sell-off-drawers/StartPrearationPhase";
import WithdrawModal from "../sell-off-drawers/Withdraw";
import SoldModal from "../sell-off-drawers/sold";
import OverViewTab from "./Overview";
import SaleProccessTab from "./SaleProccess";
import {useSearchParams} from "react-router-dom";
import Gallery from "./Gallery";
import Attribute from "./attribute";
import BundleItems from "./bundle-items";

type Details = SalesService.SellOffProductBasicInfoDto &
  SalesService.SellOffProductStatusInfoDto;
type Context = {
  data: Details | null;
  channelCode?: string;
  editImageToggle: () => void;
  getData: () => void;
  setChangePhase: () => void;
  setStartPrePhase: () => void;
  setClosePhase: () => void;
};

export const SellOffProductDetailsContext = createContext({} as Context);

const SellOffProductDetails = () => {
  const [serchParams] = useSearchParams();
  const updatePermission = usePermission("SC_UpdateSellOffProduct");
  const [startPrePhase, setStartPrePhase] = useToggleState();
  const [changePhase, setChangePhase] = useToggleState();
  const [closePhase, setClosePhase] = useToggleState();
  const [soldProduct, setSoldProduct] = useToggleState();
  const [withdraw, setWithdraw] = useToggleState();
  const [confirmDelet, setConfirmDelete] = useToggleState();
  const [deleteLoading, setDeleteLoading] = useToggleState();

  const navigate = useNavigate();

  const {productId} = useParams();
  const [editImages, editImageToggle] = useToggleState();
  const [editProductOpen, editProductToggle] = useToggleState();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Details | null>(null);

  const tabs = [
    {
      label: "salesManagment.sellOffSales.sellOffProduct.overview",
      component: OverViewTab,
    },
    {
      label: "salesManagment.sellOffSales.sellOffProduct.gallery",
      component: Gallery,
    },
    ...(data?.pimProduct?.pimProductType
      ? [
          {
            label: "items",
            component: BundleItems,
          },
        ]
      : []),
    {
      label: "salesManagment.sellOffSales.sellOffProduct.attributes",
      component: Attribute,
    },
    {
      label: "salesManagment.sellOffSales.sellOffProduct.saleProccess",
      component: SaleProccessTab,
    },
  ];

  const hasData = !!data;
  const channelCode = serchParams.get("channelCode");

  const getInfoData = () => {
    setLoading(true);
    const infoUrl = URLS.GET_DETAILS_BASIC_INFO(productId, channelCode);
    const statusUrl = URLS.GET_DETAILS_STATUS_INFO(productId, channelCode);
    Promise.all([statusUrl, infoUrl].map(url => ServiceApi.get(url)))
      .then(([statusResponse, infoResponse]) => {
        const data = {...statusResponse.data, ...infoResponse.data};
        setData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getPhaseState = (item: Details | null) => {
    const isSold = item?.isSold;
    const active = item?.currentPhaseDay;

    return (
      <div className="text-heading-6">
        {item?.isClosed ? (
          <Status.SellOff id="salesManagment.sellOffSales.sellOffProduct.closed" />
        ) : isSold ? (
          <Status.SellOff id="salesManagment.sellOffSales.sellOffProduct.sold" />
        ) : active ? (
          <>
            <Status.SellOff
              id={"salesManagment.sellOffSales.sellOffProduct.active"}
            />{" "}
            {item?.changePhaseRequired && (
              <Status.SellOff
                id={
                  "salesManagment.sellOffSales.sellOffProduct.needPhaseChange"
                }
              />
            )}
            {item?.closePhaseRequired && (
              <Status.SellOff
                id={"salesManagment.sellOffSales.sellOffProduct.needClose"}
              />
            )}
          </>
        ) : (
          <Status.SellOff id="salesManagment.sellOffSales.sellOffProduct.preparation" />
        )}
      </div>
    );
  };
  const hiddenClassName = classNames({
    hidden: data?.isClosed || data?.isSold,
  });

  const onDelete = (id: string) => {
    setDeleteLoading();
    const url = URLS.DELETE_PRODUCT_URL(id);
    ServiceApi.delete(url)
      .then(() => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title",
        );
        setDeleteLoading();
        setConfirmDelete();
        navigate(-1);
      })
      .catch(() => setDeleteLoading());
  };

  useEffect(getInfoData, [productId]);

  return (
    <>
      <Tabs activeKey={tabs[0].label}>
        <div className="space-y-6">
          <div className="flex items-center justify-between">
            <div>
              <Breadcrumb />
            </div>
            {updatePermission && (
              <>
                {
                  // data?.isSold || data?.isClosed ? (
                  //   <Button
                  //     size="sm"
                  //     variant={"white"}
                  //     textColor="danger"
                  //     disabled={loading}
                  //     onClick={() => setConfirmDelete()}
                  //   >
                  //     <Icon icon={"trash"} className="mr-2" />
                  //     <Text>
                  //       salesManagment.sellOffSales.sellOffProduct.deleteProduct
                  //     </Text>
                  //   </Button>
                  // ) :
                  !isEmpty(data) && !data?.currentPhaseDay ? (
                    <Button size="sm" onClick={() => setStartPrePhase()}>
                      <Text>
                        salesManagment.sellOffSales.sellOffProduct.startProccess
                      </Text>
                    </Button>
                  ) : data?.changePhaseRequired ? (
                    <Button
                      size="sm"
                      variant={"warning"}
                      onClick={() => setChangePhase()}
                    >
                      <Text>
                        salesManagment.sellOffSales.sellOffProduct.changePhase
                      </Text>
                    </Button>
                  ) : data?.closePhaseRequired ? (
                    <Button
                      size="sm"
                      variant={"danger"}
                      onClick={() => setClosePhase()}
                    >
                      <Text>
                        salesManagment.sellOffSales.sellOffProduct.closeProccess
                      </Text>
                    </Button>
                  ) : null
                }
              </>
            )}
          </div>
          <Wrapper>
            <Wrapper.Body className="flex flex-col items-start gap-5 lg:flex-row">
              <LazyImage
                isDynamic
                src={data?.avatar?.url}
                alt={""}
                className="h-[191px] w-[349px] cursor-pointer bg-gray-100"
              />
              {!loading && (
                <div className="flex h-full w-full justify-between ">
                  <div className="flex flex-col gap-y-2 space-y-2 text-body-2 font-medium text-gray-500">
                    <h6 className="flex items-center space-x-2 text-heading-5">
                      {updatePermission && (
                        <Icon
                          icon="edit"
                          className={`mr-2 cursor-pointer text-primary ${hiddenClassName}`}
                          onClick={() => {
                            editProductToggle();
                          }}
                        />
                      )}
                      {data?.pimProduct?.originalName}
                      <Status.PimProductType
                        id={data?.pimProduct?.pimProductType as number}
                      />
                      {getPhaseState(data)}
                    </h6>
                    <p>
                      <Text>
                        salesManagment.sellOffSales.sellOffProduct.articleNumber
                      </Text>
                      : #{data?.pimProduct?.articleNumber}
                    </p>
                    <p>
                      <Text>
                        salesManagment.sellOffSales.sellOffProduct.program
                      </Text>
                      : #{data?.pimProduct?.supplier?.program?.name}
                    </p>
                    <p>
                      <Text>
                        salesManagment.sellOffSales.sellOffProduct.productFamily
                      </Text>{" "}
                      :{" "}
                      <HandleName
                        translations={
                          data?.pimProduct?.productFamily?.translates
                        }
                        keyName="labelTitle"
                      />
                    </p>
                    <p>
                      <Text>
                        salesManagment.sellOffSales.sellOffProduct.productGroup
                      </Text>{" "}
                      : {data?.pimProduct?.pimProductGroup?.name}
                    </p>
                    <div className="flex gap-2">
                      {/* <p className="flex items-center gap-2">
                        {data?.pimProduct?.supplier?.program?.brand?.logo
                          ?.thumbnailUrl && (
                          <LazyImage
                            isDynamic
                            src={
                              data?.pimProduct?.supplier?.program?.brand?.logo
                                ?.thumbnailUrl
                            }
                            className="h-8 w-14 border-none"
                          />
                        )}
                        {data?.pimProduct?.supplier?.program?.brand?.name}
                      </p> */}
                      <p className="flex items-center gap-2">
                        {/* {data?.supplierLogoUrl && (
                          <Image
                            src={data?.supplierLogoUrl}
                            className="w-14 h-6 border-none"
                          />
                        )} */}
                        {data?.pimProduct?.supplier?.name}
                      </p>
                    </div>
                  </div>
                  <div className=" flex-grow-1 h-fit min-w-44 gap-4 space-y-6 rounded bg-gray-100 p-4">
                    <div className="rounded-[20px] bg-[#38476D17] bg-opacity-90 p-2 text-center text-body-2 font-medium text-primary">
                      {data?.channelTitle}
                    </div>
                    {hasData && (
                      <PriceTemplate
                        inDetails
                        item={data}
                        className="space-y-2"
                      />
                    )}
                    {/* {updatePermission && (
                      <>
                        {data?.isClosed ? (
                          <Button
                            className="w-full cursor-default"
                            variant={"white"}
                            textColor="gray"
                          >
                            <Text>
                              salesManagment.sellOffSales.sellOffProduct.closed
                            </Text>
                          </Button>
                        ) : data?.isSold ? (
                          <Button
                            className="w-full"
                            variant={"warning"}
                            onClick={setWithdraw}
                          >
                            <Text>
                              salesManagment.sellOffSales.sellOffProduct.withdraw
                            </Text>
                          </Button>
                        ) : null}
                      </>
                    )} */}
                  </div>
                </div>
              )}
              {loading && (
                <div className="w-96 animate-pulse space-y-4">
                  <div className="col-span-1 h-2 rounded bg-slate-200"></div>
                  <div className="col-span-2 h-2 rounded bg-slate-200"></div>
                  <div className="col-span-1 h-2 rounded bg-slate-200"></div>
                  <div className="col-span-2 h-2 rounded bg-slate-200"></div>
                  <div className="col-span-1 h-2 rounded bg-slate-200"></div>
                </div>
              )}
            </Wrapper.Body>
            <Wrapper.Footer className="!py-0">
              {loading ? (
                <div className="flex gap-2 p-4">
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </div>
              ) : (
                <Tabs.ButtonGroup>
                  {tabs.map(e => (
                    <Tabs.Button key={e.label} eventKey={e.label}>
                      <Text>{e.label}</Text>
                    </Tabs.Button>
                  ))}
                </Tabs.ButtonGroup>
              )}
            </Wrapper.Footer>
          </Wrapper>
          <SellOffProductDetailsContext.Provider
            value={{
              data,
              editImageToggle,
              getData: getInfoData,
              setChangePhase,
              setStartPrePhase,
              setClosePhase,
            }}
          >
            {loading
              ? [1, 2, 3, 4].map(() => <Skeleton.List />)
              : tabs.map(e => (
                  <Tabs.Item key={e.label} eventKey={e.label}>
                    {createElement(e.component)}
                  </Tabs.Item>
                ))}
          </SellOffProductDetailsContext.Provider>
        </div>
      </Tabs>
      <EditImageDrawer
        isOpen={editImages}
        toggle={editImageToggle}
        data={data}
        onComplete={getInfoData}
      />
      {/* <EditProductDrawer
        isOpen={editProductOpen}
        data={data}
        toggle={editProductToggle}
        getData={getInfoData}
      />  */}
      <StartPreparationPhaseModal
        isOpen={startPrePhase}
        toggle={setStartPrePhase}
        getData={getInfoData}
        productId={data?.sellOffProductId}
      />
      <ChangePhaseModal
        isOpen={changePhase}
        toggle={setChangePhase}
        getData={getInfoData}
        productId={data?.sellOffProductId}
      />
      <ClosePhaseModal
        isOpen={closePhase}
        toggle={setClosePhase}
        getData={getInfoData}
        id={data?.sellOffProductId}
      />
      <SoldModal
        isOpen={soldProduct}
        toggle={setSoldProduct}
        getData={getInfoData}
        id={data?.sellOffProductId}
        data={data}
      />
      <WithdrawModal
        isOpen={withdraw}
        toggle={setWithdraw}
        getData={getInfoData}
        id={data?.sellOffProductId}
        channelCode={data?.channelCode as string}
      />
      {/* {hasData && (
        <DeleteModal
          isOpen={confirmDelet}
          loading={deleteLoading}
          toggle={setConfirmDelete}
          id={data?.sellOffProductId}
          selectedName={data?.title}
          onConfirm={onDelete}
        />
      )} */}
    </>
  );
};

export default SellOffProductDetails;
