import {
  Breadcrumb,
  Button,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Status,
  Table,
  Text,
} from "components";
import {config} from "constant";
import {useConverter, useToggleState} from "hooks";
import {useEffect, useState} from "react";
import {Link, useSearchParams} from "react-router-dom";
import {ServiceApi, URLS} from "services";
import AverageFactor from "./AverageFactor";
import FilterDrawer from "./FilterDrawer";

export default function SalesList() {
  const controller = new AbortController();
  const [data, setData] = useState<any>();
  const [loadingList, setLoadingList] = useState(true);
  const [filterModal, setFilterModal] = useToggleState(false);
  const [searchParams] = useSearchParams();
  //original filter data for reset
  const originalFilterData = {
    advisorFullName: null,
    currentStatus: null,
    dateFrom: null,
    dateTo: null,
    hasContractNumber: null,
    keyword: "",
    location: null,
    pageNumber: "1",
    pageSize: config.pageSize,
    sellFactorHasWarning: null,
    shippingCostHasWarning: null,
    sortType: null,
    topBuy: null,
    totalAmountFrom: null,
    totalAmountTo: null,
    totalTargetAmountFrom: null,
    totalTargetAmountTo: null,
  };

  const [keyword, setKeyword] = useState("");
  const [thisDate, setThisDate] = useState("");
  const [topBuy, setTopBuy] = useState("");
  const {convertDate} = useConverter();

  const [filterData, setFilterData] = useState(originalFilterData);
  const [params, setParams] = useSearchParams();

  const getData = () => {
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url = URLS.KITCHEN_CALCULATIONS_URL;
    const config = params;
    setLoadingList(true);
    ServiceApi.post(url, {...config, signal: controller.signal})
      .then(({data}) => {
        setData(data);
      })
      .catch(() => {
        setData([]);
      })
      .finally(() => {
        setLoadingList(false);
      });
  };

  useEffect(() => {
    getData();
    return () => {
      controller.abort();
    };
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  const resetFilter = () => {
    setParams({});
    setKeyword("");
    setThisDate("");
    setTopBuy("");
  };

  const handleSetFilterData = (key: string) => {
    return (value: any) => {
      if (key === "dateFrom" || key === "dateTo") {
        value = value.toDateString();
      }
      setParams(prev => {
        value !== null && value !== ""
          ? key === "pageNumber" && value === 1
            ? prev.delete(key)
            : prev.set(key, value)
          : prev.delete(key);
        key !== "pageNumber" && prev.delete("pageNumber");
        return prev;
      });
    };
  };
  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
      </div>
      <SearchBox
        value={keyword}
        onSubmit={handleSetFilterData("keyword")}
        disabled={loadingList}
        totalItems={data?.totalItems}
        onFilter={() => setFilterModal()}
        resetFilter={searchParams?.size === 0 ? undefined : resetFilter}
        onReload={getData}
        loading={loadingList}
      />
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data && data.length !== 0 && data?.searchResult?.length !== 0 ? (
            <>
              <Table>
                {data?.searchResult?.map((item: any) => {
                  return (
                    <tr key={item.projectId}>
                      <td>
                        <Link to={item.projectId} className="flex w-fit gap-2">
                          <div className="space-y-2">
                            <div className="flex items-center gap-1">
                              <span className="text-body-base font-normal text-gray-800">
                                <Text>
                                  salesManagment.kitchenSales.salesList.projectNumber
                                </Text>
                                :
                              </span>
                              <h6 className="text-heading-6 font-semibold text-gray-800">
                                {item.projectId}
                              </h6>
                            </div>
                            <div className="flex items-center gap-1">
                              <span className="text-body-base font-normal text-gray-800">
                                <Text>
                                  salesManagment.kitchenSales.salesList.projectName
                                </Text>
                                :
                              </span>
                              <h6 className="text-heading-6 font-semibold text-gray-800">
                                {item.projectName}
                              </h6>
                            </div>
                            <div className="flex items-center gap-1">
                              <span className="text-body-base font-normal text-gray-600">
                                <Text>
                                  salesManagment.kitchenSales.salesList.contract
                                </Text>
                                :
                              </span>
                              <span className="text-body-base font-normal text-gray-800">
                                {item.updates[0].contractNumber}
                              </span>
                            </div>
                          </div>
                        </Link>
                      </td>
                      <td className="text-left">
                        <div className="space-y-2">
                          <div className="flex items-center gap-1">
                            <span className="text-body-base font-normal text-gray-600">
                              <Text>
                                salesManagment.kitchenSales.salesList.advisor
                              </Text>
                              :
                            </span>
                            <span className="text-body-base font-normal text-gray-800">
                              {item?.updates[0]?.advisorFullName}
                            </span>
                          </div>
                          <div className="flex items-center gap-1">
                            <span className="text-body-base font-normal text-gray-600">
                              <Text>
                                salesManagment.kitchenSales.salesList.createdAt
                              </Text>
                              :
                            </span>
                            <span className="text-body-base font-normal text-gray-800">
                              {convertDate(item?.updates[0]?.createdAt)}
                            </span>
                          </div>
                          <div className="flex items-center gap-1">
                            <span className="text-body-base font-normal text-gray-600">
                              <Text>
                                salesManagment.kitchenSales.salesList.lastUpdated
                              </Text>
                              :
                            </span>
                            <span className="text-body-base font-normal text-gray-800">
                              {convertDate(item?.updates[0]?.updatedAt)}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="text-left">
                        <AverageFactor
                          average={item?.updates[0]?.sellFactor}
                          target={item?.updates[0]?.targetSellFactor}
                          warning={item?.sellFactorHasWarning}
                        />
                      </td>
                      <td className="space-y-[10px]">
                        {(item?.currentStatus === 3 ||
                          item?.currentStatus === 4) && (
                          <div>
                            <Status.salesListStatus id={item?.currentStatus} />
                          </div>
                        )}
                        {item?.currentStatus !== 4 && (
                          <>
                            {item?.sellFactorHasWarning && (
                              <div>
                                <Status.sellFactorHasWarning
                                  id={item?.sellFactorHasWarning}
                                />
                              </div>
                            )}
                            {item?.shippingCostHasWarning && (
                              <div>
                                <Status.shippingCostHasWarning
                                  id={item?.shippingCostHasWarning}
                                />
                              </div>
                            )}
                          </>
                        )}
                      </td>
                      <td>
                        <Button
                          as={Link}
                          to={item.projectId}
                          variant="light"
                          size="sm"
                        >
                          <Text>global.buttons.details</Text>
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages}
                pageNumber={
                  params?.get("pageNumber")
                    ? parseInt(params?.get("pageNumber") as string)
                    : 1
                }
                pageSize={
                  params?.get("pageSize")
                    ? parseInt(params?.get("pageSize") as string)
                    : config.pageSize
                }
                setActivePage={handleSetFilterData("pageNumber")}
                onPageSizeChange={handleSetFilterData("pageSize")}
              />
            </>
          ) : (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
            </div>
          )}
        </>
      )}
      <FilterDrawer
        isOpen={filterModal}
        data={data}
        keyword={keyword}
        setKeyword={setKeyword}
        thisDate={thisDate}
        setThisDate={setThisDate}
        toggle={setFilterModal}
        onChange={handleSetFilterData}
        resetFilter={resetFilter}
        topBuy={topBuy}
        setTopBuy={setTopBuy}
        setParams={setParams}
        params={params}
      />
    </div>
  );
}
