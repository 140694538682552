import CalculationUnits from "pages/price-engine/calculation-units";
import {CalculationUnitDetails} from "pages/price-engine/calculation-units/details";
const CalculationUnitsRoutes = [
  {
    path: "",
    element: <CalculationUnits />,
  },
  {
    path: ":calculationUnitId",
    element: <CalculationUnitDetails />,
  },
];

export default CalculationUnitsRoutes;
