import { NotificationManager } from "common/notifications";
import {
  Button,
  Form,
  Icon,
  InputGroup,
  Modal,
  Select,
  Text,
  Toggler,
} from "components";
import { rules } from "constant";
import { useDataState } from "hooks";
import { FormEvent, useEffect, useRef, useState } from "react";
import { ServiceApi, URLS } from "services";

type createModalType = {
  isOpen: boolean;
  supplierId: string;
  titleItems: [];
  totalItems: any;
  toggle: (value?: boolean) => void;
  getData?: () => void;
};

export default function CreateModal(props: createModalType) {
  const [loading, setLoading] = useState<boolean>(false);
  const [showTextInput, setShowTextInput] = useState<boolean>(false);
  const formRef = useRef<any>();
  const [data, setData] = useDataState({
    titleSelect: "",
    title: "",
    text: "",
    amount: "",
    order: "",
    isActive: true,
  });

  useEffect(() => {
    if (props.isOpen) {
      setShowTextInput(false);
      setData({
        titleSelect: "",
        title: "",
        text: "",
        amount: "",
        order: "",
        isActive: true,
      });
    }
  }, [props.isOpen]);

  const onSubmitForm = (formData: any) => {
    setLoading(true);
    const url = URLS.ADD_DISCOUNT_SUPPLIERS(props.supplierId);
    if (formData.text !== "") {
      formData.title = formData.text;
      // delete formData.text;
    } else {
      formData.title = formData.titleSelect;
    }
    ServiceApi.post(url, formData)
      .then(({ }) => {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title",
        );
        props.toggle(false);
        props.getData && props.getData();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submit = (e?: FormEvent) => {
    formRef.current.submitHandler(e, data);
  };

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} modalClassName="z-40">
      <Modal.Header className="!text-left">
        <h2>
          <Text>productManagement.masterData.suppliers.adddiscount</Text>
        </h2>
      </Modal.Header>
      <Form ref={formRef} onSubmit={onSubmitForm} id={"create-modal-discount"}>
        <Modal.Body className="grid grid-cols-1 px-8 lg:grid-cols-3">
          <div className="col-start-1 col-end-4 mb-8">
            <Select
              label="productManagement.masterData.suppliers.title"
              setValue={titleSelect => {
                setShowTextInput(titleSelect === "customText" ? true : false);
                if (titleSelect === "customText") {
                  setData(p => ({ ...p, titleSelect, text: "" }));
                } else {
                  setData(p => ({ ...p, titleSelect }));
                }
              }}
              items={props?.titleItems}
              value={data?.titleSelect}
              rules={rules?.required}
            />
          </div>
          {showTextInput ? (
            <div className="col-start-1 col-end-4 mb-8">
              <InputGroup
                label="productManagement.masterData.suppliers.customTitle"
                value={data?.text}
                setValue={text => setData(p => ({ ...p, text }))}
              />
            </div>
          ) : (
            ""
          )}
          <div className="col-start-1 col-end-4 mb-8">
            <InputGroup
              label="productManagement.masterData.suppliers.amount"
              value={data?.amount}
              setValue={amount => {
                if (amount >= 0 && amount <= 100)
                  setData(p => ({ ...p, amount }));
              }}
              rules={rules?.discount}
              keyfilter="pnum"
              append={
                <h5 className="border-l border-gray-300 px-4 py-3 text-heading-5 text-gray-400">
                  %
                </h5>
              }
            />
          </div>
          <div className="col-start-1 col-end-4 mb-8">
            <InputGroup
              type="number"
              label="productManagement.masterData.suppliers.sortOrder"
              value={data?.order}
              setValue={order => setData(p => ({ ...p, order }))}
              rules={rules?.required}
              keyfilter="onlyNum"
            />
          </div>
          <div className="col-start-1 col-end-1 mb-2">
            <Toggler
              label="productManagement.masterData.suppliers.active"
              value={data?.isActive}
              setValue={isActive => setData(p => ({ ...p, isActive }))}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-between">
          <Button
            type="button"
            className="mr-2"
            onClick={props.toggle}
            variant="white"
          >
            <Icon className="mr-2" icon={"times"} />
            <Text>global.buttons.close</Text>
          </Button>
          <Button type="button" onClick={submit} loading={loading}>
            <Text>global.buttons.submit</Text>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
