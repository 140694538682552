import {InputGroup, Text, Wrapper} from "components";
import {useContext} from "react";
import {CustomerContext} from "..";

export default function Location() {
  const {userData} = useContext<any>(CustomerContext);

  return (
    <>
      <Wrapper>
        <Wrapper.Header>
          <h1 className="text-heading-2 font-semibold">
            <Text>customers.customerList.location</Text>
          </h1>
        </Wrapper.Header>
        <Wrapper.Body className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
          <div className="space-y-8">
            <div className="grid grid-cols-1 items-stretch gap-x-6 gap-y-8 lg:grid-cols-1">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-4 lg:gap-8">
                <div className="col-span-4">
                  <InputGroup
                    label="global.locations.name"
                    value={userData.address?.name}
                    disabled
                  />
                </div>
                <div className="col-span-3">
                  <InputGroup
                    label="global.locations.street"
                    value={userData.address?.street}
                    disabled
                  />
                </div>
                <div>
                  <InputGroup
                    label="global.locations.houseNumber"
                    value={userData.address?.number}
                    disabled
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-8">
                <InputGroup
                  label="global.locations.postalCode"
                  value={userData.address?.postalCode}
                  disabled
                />
                <InputGroup
                  label="global.locations.city"
                  value={userData.address?.city}
                  disabled
                />
                <InputGroup
                  label="global.locations.country"
                  value={userData.address?.country}
                  disabled
                />
                <InputGroup
                  label="global.locations.state"
                  value={userData.address?.state}
                  disabled
                />
              </div>
            </div>
          </div>
        </Wrapper.Body>
      </Wrapper>
    </>
  );
}
