import Dropdown from "./Dropdown";
import {flagCountry} from "constant";

type HandleNameProps = {
  value: any;
  items?: any;
  className?: string;
  onChangeLanguage?: any;
};

const SelectLang = ({
  value,
  items,
  onChangeLanguage,
  className,
}: HandleNameProps) => {
  const langCountry: any = {
    de: "German",
    en: "English",
    tr: "Turkish",
    fr: "French",
  };

  return (
    <Dropdown onSelect={onChangeLanguage}>
      <Dropdown.Toggle
        as="button"
        type="button"
        className={`flex space-x-2 rounded-md bg-gray-200 p-2 text-body-base font-medium uppercase text-gray-600 ${className}`}
      >
        <img src={flagCountry[value]} />
        <span>{value}</span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="mt-2 min-w-fit p-2">
        <ul className="w-[104px] text-gray-700">
          {items?.map((e: any) => (
            <li
              key={e.id}
              onClick={() => onChangeLanguage(e.id)}
              className={`h6 px-4 py-2 font-medium first:mb-1 ${
                e.id === value && "bg-primary-light text-primary "
              } cursor-pointer rounded-lg text-body-base hover:bg-primary-light hover:text-primary`}
            >
              {langCountry[e.name]}
            </li>
          ))}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SelectLang;
