const PUBLISHEDPRODUCTS = {
  GET_PUBLISHED_PRODUCT_LIST: `salesservice/api/salestoolspimproduct/search`,
  GET_PUBLISHED_PRODUCT_DETAILS: id =>
    `salesservice/api/salestoolspimproduct/${id}`,
  GET_PUBLISHED_PRODUCT_PRICE_PER_CHANNEL: id =>
    `salesservice/api/salestoolspimproduct/${id}/price-per-channel`,
  PRINT_REQUEST: id =>
    `salesservice/api/salestoolspimproduct/${id}/print-requests`,
  DELETE_PRINT_REQUEST: (id, requestId) =>
    `salesservice/api/salestoolspimproduct/${id}/print-requests/${requestId}`,
  GET_PUBLISHED_PRODUCT_INVENTORY: id =>
    `salesservice/api/salestoolspimproduct/${id}/product-inventory`,
  OVERWRITE_PUBLISHED_PRODUCT_PRICE: id =>
    `salesservice/api/salestoolspimproduct/${id}/overwrite-price`,
  OVERWRITE_PUBLISHED_PRODUCT_UVP_PRICE: id =>
    `salesservice/api/salestoolspimproduct/${id}/overwrite-uvp-price`,
  PUSH_TO_SELL_OFF: (id, channelCode) =>
    `salesservice/api/salestoolspimproduct/${id}/push-to-sell-off?channelCode=${channelCode}`,
  // -------------------- Published PIM Product Sales person commission --------------
  UPDATE_SALES_PERSON_COMMISSION: id =>
    `salesservice/api/salesToolsPimProduct/${id}/update-sales-person-commission`,
  UPDATE_BUNDLE_ITEMS_SALES_PERSON_COMMISSION: (id, bundleItemId) =>
    `salesservice/api/salesToolsPimProduct/${id}/bundle-item/${bundleItemId}`,
};

export default PUBLISHEDPRODUCTS;
