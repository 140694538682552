import {Button, Drawer, Icon, Text} from "components";
import {useConverter} from "hooks";
import {SalesService} from "types";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  commission?: SalesService.SaleCommissionQueryDto;
}

interface Commission {
  channelCode: string;
  createdAt: string;
  employeeCode: string;
  secondEmployeeCode: string;
  customerNumber: string;
  orderNumber: string;
  orderDate: string;
  orderVersion: number;
  lineItemType: string;
  pimArticleNumber: string;
  totalSalePrice: number;
  quantity: number;
  supplierCode: string;
  productGroupCode: string;
  description: string;
  commissionAmountType: string;
  commissionsource: string;
}

const SaleCommissionSource = ["PimProduct", "SupplierProgram"];

const LineItemType = [
  "PimProduct",
  "IwofurnProduct",
  "ManualProduct",
  "ProductService",
  "ManualBundle",
  "PimBundle",
  "PimBundleItem",
  "SellOffProduct",
  "SellOffBundle",
  "SellOffBundleItem",
];

export default function DetailsDrawer({isOpen, toggle, commission}: Props) {
  const {convertAmount, convertDate} = useConverter();
  const rows = [
    {
      key: "channelCode",
      translate: "salesManagment.commissionReports.channelCode",
    },
    {
      key: "createdAt",
      translate: "salesManagment.commissionReports.createDate",
    },
    {
      key: "employeeCode",
      translate: "salesManagment.commissionReports.employeeCode",
    },
    {
      key: "secondEmployeeCode",
      translate: "salesManagment.commissionReports.secondEmployeeCode",
    },
    {
      key: "customerNumber",
      translate: "salesManagment.commissionReports.customerNumber",
    },
    {
      key: "orderNumber",
      translate: "salesManagment.commissionReports.orderNumber",
    },
    {
      key: "orderDate",
      translate: "salesManagment.commissionReports.orderDate",
    },
    {
      key: "orderVersion",
      translate: "salesManagment.commissionReports.orderVersion",
    },
    {
      key: "lineItemType",
      translate: "salesManagment.commissionReports.lineItemType",
    },
    {
      key: "pimArticleNumber",
      translate: "salesManagment.commissionReports.pimArticleNumber",
    },
    {
      key: "totalSalePrice",
      translate: "salesManagment.commissionReports.totalSalePrice",
    },
    {
      key: "quantity",
      translate: "salesManagment.commissionReports.quantity",
    },
    {
      key: "supplierCode",
      translate: "salesManagment.commissionReports.supplierCode",
    },
    {
      key: "productGroupCode",
      translate: "salesManagment.commissionReports.productGroupCode",
    },
    {
      key: "commissionAmountType",
      translate: "salesManagment.commissionReports.saleCommissionAmountType",
    },
    {
      key: "commissionsource",
      translate: "salesManagment.commissionReports.commissionsource",
    },
    {
      key: "description",
      translate: "salesManagment.commissionReports.description",
    },
  ];

  const createCommissionRow = (row: keyof Commission) => {
    switch (row) {
      case "createdAt":
      case "orderDate":
        return convertDate(commission?.[row]);
      case "totalSalePrice":
        return convertAmount(commission?.[row]);
      case "pimArticleNumber":
        return `#${commission?.[row]}`;
      case "commissionAmountType":
        return createSalesCommissionAmountType(commission);
      case "commissionsource":
        return SaleCommissionSource[
          commission?.saleCommissionInfo?.commissionSource as number
        ];
      case "lineItemType":
        return LineItemType[commission?.lineItemType as number];
      default:
        return commission?.[row];
    }
  };

  const createSalesCommissionAmountType = (commission: any) => {
    const value = commission?.saleCommissionInfo?.calculationType
      ? convertAmount(commission?.saleCommissionInfo?.amount)
      : commission?.saleCommissionInfo?.amount + "%";
    return value;
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header>
          <div className="flex items-center justify-between">
            <span className="text-heading-2 font-semibold">
              <Text>salesManagment.commissionReports.details</Text>
            </span>
            <div className="flex gap-x-2">
              <Button size="sm" variant={"light"} onClick={toggle}>
                <Icon icon="times" className="mr-1" />
                <Text>salesManagment.sellOffSales.sellOffProduct.close</Text>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-4 *:text-gray-800">
          <p className="rounded-xl bg-gray-100 px-4 py-8 text-heading-4 font-semibold">
            {convertDate(commission?.orderDate)}
          </p>
          <div className="rounded-xl border border-dashed border-gray-600 px-4 py-6">
            <p className="mb-4">Card Info</p>
            <div className="space-y-2">
              {rows?.map((row: any) => {
                return (
                  <div className="grid grid-cols-2 *:p-2 even:bg-gray-100">
                    <p className=" text-body-base font-medium text-gray-800">
                      <Text>{row.translate}</Text>
                    </p>
                    <p className=" text-heading-5 text-gray-700">
                      {createCommissionRow(row.key)}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
}
