import {masterData} from "./master-data";
import {products} from "./products";
import {settings} from "./settings";
import {publishedProduct} from "./published-product";

export const productManagement = {
  masterData: masterData,
  products: products,
  settings: settings,
  publishedProduct: publishedProduct,
};
