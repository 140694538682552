import {useContext, useEffect, useState} from "react";
import {PimContext} from "..";
import {config, flagCountry} from "constant";
import {ServiceApi, URLS} from "services";
import {PimProduct} from "types";
import {
  Button,
  Icon,
  LoadingSvg,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Status,
  Table,
  Text,
} from "components";
import {useConverter, useToggleState} from "hooks";
import {PrintRequestStatus} from "enum";
import {convertDate} from "utils";
import {AxiosRequestConfig} from "axios";
import PrintHistoryDetail from "../drawers/print-history-detail";

export default function PrintHistory() {
  const controller = new AbortController();
  const {details, updatePrintHistory} = useContext(PimContext);
  const [loadingList, setLoadingList] = useState(true);
  const [isOpenEdit, toggleEdit] = useToggleState(false);
  const [data, setData] = useState<PimProduct.PrintHistoryListType>();
  const [selected, setSelected] = useState<PimProduct.PrintHistoryItem>();
  const [loadingFile, setLoadingFile] = useState("");
  const {convertAmount} = useConverter();

  const originalFilterData: any = {
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
    channelCode: details?.channelCode,
    status: null,
  };
  const [params, setParams] = useState<any>(originalFilterData);
  const statusList = [
    {
      label: "global.all",
      value: null,
    },
    {
      label: "status.printRequest.completed",
      value: PrintRequestStatus.Completed,
    },
    {
      label: "status.printRequest.onProcess",
      value: PrintRequestStatus.OnProcess,
    },
    {
      label: "status.printRequest.failed",
      value: PrintRequestStatus.Faild,
    },
  ];

  const handleSetParams = (key: string) => {
    return (value: any) => {
      setParams((p: any) => ({
        ...p,
        pageNumber: 1,
        [key]: value,
      }));
    };
  };

  const handleOpenEditDrawer = (item: PimProduct.PrintHistoryItem) => {
    setSelected(item);
    toggleEdit();
  };

  const getData = () => {
    const url = URLS.PRINT_REQUEST(details.id);
    const config = {params};
    setLoadingList(true);
    ServiceApi.get(url, {...config})
      .then(({data}) => {
        setData(data);
      })
      .catch(() => {
        setData({});
      })
      .finally(() => {
        setLoadingList(false);
      });
  };

  const downloadFileHandler = async (url: string) => {
    let dataUrl = url;
    setLoadingFile(url);
    if (dataUrl?.includes("/get-file")) {
      const config: AxiosRequestConfig = {
        responseType: "blob",
        params: {withoutSubscriptionKey: true},
      };
      await ServiceApi.get(dataUrl, config).then(({data}) => {
        dataUrl = URL.createObjectURL(data);
      });
    }
    setLoadingFile("");
    return dataUrl;
  };

  useEffect(() => {
    getData();
    return () => {
      controller.abort();
    };
  }, [params, updatePrintHistory]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <SearchBox
        value={params?.keyword || ""}
        onSubmit={handleSetParams("keyword")}
        disabled={loadingList}
        totalItems={data?.totalItems}
        onReload={getData}
      />
      <div className="flex gap-3">
        {statusList.map(item => (
          <Button
            size="sm"
            variant={params?.status === item.value ? "primary" : "white"}
            onClick={() =>
              setParams({
                ...params,
                pageNumber: 1,
                status: item.value,
              })
            }
          >
            <Text>{item.label}</Text>
          </Button>
        ))}
      </div>
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data &&
          Object.keys(data).length !== 0 &&
          data?.items?.length !== 0 ? (
            <>
              <Table>
                {data?.items?.map(item => {
                  return (
                    <tr key={item.id}>
                      <td className="space-y-5">
                        <h6
                          className="cursor-pointer text-heading-6"
                          onClick={() => handleOpenEditDrawer(item)}
                        >
                          {item.title}{" "}
                          <span className="inline-flex items-center gap-2 text-heading-6 font-semibold text-gray-500">
                            ({" "}
                            {item?.language && (
                              <img src={flagCountry[item?.language]} />
                            )}
                            {item?.language.toUpperCase()} )
                          </span>
                        </h6>
                        <p className="text-body-2">
                          {convertDate(item.createdAt)}
                        </p>
                      </td>
                      <td>
                        <Status.PrintRequestStatus id={item.status} />
                      </td>
                      <td className="space-y-2 text-start text-body-2">
                        <p>
                          <span className="font-medium">VK 2: </span>
                          {convertAmount(item?.pimProductVk2Price.amount)}
                        </p>
                        <p>
                          <span className="font-medium">VK 1: </span>
                          {convertAmount(item?.pimProductVk1Price.amount)}
                        </p>
                      </td>
                      <td className="space-x-3">
                        <Button
                          size="sm"
                          variant="primary"
                          light
                          disabled={
                            !item?.documentFile?.fileUrl ||
                            item.status !== PrintRequestStatus.Completed
                          }
                          onClick={async () => {
                            const url = await downloadFileHandler(
                              item?.documentFile?.fileUrl || "",
                            );
                            window.open(url, "_blank");
                          }}
                        >
                          {loadingFile === item?.documentFile?.fileUrl ? (
                            <LoadingSvg size="sm" />
                          ) : (
                            <Icon icon="download" className="mr-2" size="xl" />
                          )}
                          <Text>
                            productManagement.publishedProduct.Details.download
                          </Text>
                        </Button>
                        <Button
                          size="sm"
                          variant="light"
                          onClick={() => handleOpenEditDrawer(item)}
                        >
                          <Text>global.buttons.details</Text>
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages}
                pageNumber={parseInt(params?.pageNumber) || 1}
                pageSize={params?.pageSize}
                setActivePage={handleSetParams("pageNumber")}
                onPageSizeChange={handleSetParams("pageSize")}
              />
            </>
          ) : (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
            </div>
          )}
        </>
      )}
      <PrintHistoryDetail
        isOpen={isOpenEdit}
        toggle={toggleEdit}
        item={selected}
        getData={getData}
      />
    </>
  );
}
