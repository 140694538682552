export const calculationLevel = {
  none: "None",
  tenant: "Tenant",
  legalEntity: "LegalEntity",
  channel: "Channel",
  customer: "Customer",
  supplier: "Supplier",
  suppliers: "Suppliers",
  program: "Program",
  brand: "Brand",
  brands: "Brands",
  product: "Product",
  products: "Products",
  configurations: "Configurations",
  units: "Units",
  excludeAllAutoDiscount: "Exclude All Auto discount",
  excludeAllOptionalDiscount: "Exclude All Optional discount",
  excludeAllVoucherDiscount: "Exclude all voucher discount",
  activeForPim: "activeForPim",
  activeForIwofurn: "activeForIwofurn",
  activeForManual: "activeForManual",
  calculationUnit: "Calculation Unit",
  value: "Value",
  details: "Details",
  addCalculationUnit: "Add Calculation Unit",
  addValidationPeriod: "Add validation period",
  priceCalculationUnits: "Price Calculation Units",
  step: "Step",
};
