import {useState, useContext} from "react";
import {Button, Icon, Text} from "components";
import {useToggleState} from "hooks";
import {ServiceApi, URLS} from "services";
import {config} from "constant";
import Chooser from "../common/chooser";
import {SimulationContext} from "pages/price-engine/price-simulator";
import Info from "../common/info";

export default function Supplier() {
  const {setSimulateData, simulateData} = useContext(SimulationContext);
  const [loading, setLoading] = useToggleState();
  const [isOpen, toggle] = useToggleState();
  const [suppliers, setSuppliers] = useState<any>([]);
  const [params, setParams] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
    suppliertype: 0,
  });

  const getSuppliers = () => {
    setLoading();
    const url = URLS.GET_SUPPLIERS;
    const config = {params};
    ServiceApi.get(url, config)
      .then(({data}) => {
        const clone = data?.items?.map((supplier: any) => ({
          id: supplier.supplierId,
          name: supplier.name,
          code: supplier.code,
        }));
        setSuppliers(clone);
        setLoading();
      })
      .catch(() => setLoading());
  };

  return (
    <>
      <div className="space-y-4">
        <div>
          <span className="text-body-base text-gray-800">
            <Text>priceEngine.simulator.supplier</Text> :
          </span>
        </div>
        {simulateData["supplier"] ? (
          <Info dataKey="supplier" />
        ) : (
          <Button light size="sm" onClick={toggle}>
            <Icon icon="plus" className="mr-2" />
            <Text>priceEngine.simulator.addSupplier</Text>
          </Button>
        )}
      </div>
      <Chooser
        isOpen={isOpen}
        toggle={toggle}
        title="priceEngine.simulator.suppliers"
        data={suppliers}
        getData={getSuppliers}
        loading={loading}
        setParams={setParams}
        params={params}
        dataKey="supplier"
        setSimulateData={setSimulateData}
      />
    </>
  );
}
