const MEASUREMENT = {
  // ---------------------- MeasurementFamily --------------------
  GET_MEASUREMENT_FAMILY_URL: "productservice/api/measurementfamilies",
  ADD_MEASUREMENT_FAMILY_URL: "productservice/api/measurementfamilies",
  GET_DETAIL_MEASUREMENT_FAMILY_URL: (id) =>
    `productservice/api/measurementfamilies/${id}`,
  PUT_DETAIL_MEASUREMENT_FAMILY_URL: (id) =>
    `productservice/api/measurementfamilies/${id}`,
  DELETE_DETAIL_MEASUREMENT_FAMILY_URL: (id) =>
    `productservice/api/measurementfamilies/${id}`,


  //------------------ unit ---------------------------------- 
  UPDATE_MEASUREMENT_FAMILY_UNIT_URL: (id, unitId) =>
    `productservice/api/measurementfamilies/${id}/measurementfamilyunit/${unitId}`,
  DELETE_MEASUREMENT_FAMILY_UNIT_URL: (id, unitId) =>
    `productservice/api/measurementfamilies/${id}/measurementfamilyunit/${unitId}`,
  ADD_MEASUREMENT_FAMILY_UNIT_URL: (id) =>
    `productservice/api/measurementfamilies/${id}/measurementfamilyunit`,
};

export default MEASUREMENT;
